import React, { FC } from 'react';
import { RootStackParamList } from '../screens/screens';
import {
  useNavigation,
  useRoute,
  RouteProp,
  NavigationProp,
} from '@react-navigation/native';

export interface L2NavigationProviderProps {}

interface L2NavigationContextState {
  activeIndex: number | undefined;
  setActiveIndex: (index: number | undefined) => void;
}

export const L2NavigationContext = React.createContext<
  L2NavigationContextState
>({} as L2NavigationContextState);

export const L2NavigationProvider: FC<L2NavigationProviderProps> = (props) => {
  const route = useRoute<RouteProp<RootStackParamList, 'L2'>>();
  const navigation = useNavigation<NavigationProp<RootStackParamList, 'L2'>>();

  const setActiveIndex = (index: number | undefined) => {
    navigation.setParams({
      activeIndex: index != undefined ? index.toString() : '',
    });
  };

  return (
    <L2NavigationContext.Provider
      value={{
        activeIndex: route.params.activeIndex
          ? Number(route.params.activeIndex)
          : undefined,
        setActiveIndex: setActiveIndex,
      }}
    >
      {props.children}
    </L2NavigationContext.Provider>
  );
};

export default L2NavigationProvider;
