import { ModificationCategory, Modification, ModificationId } from './types';

export const categories: ModificationCategory[] = [
  {
    title: 'Tempo',
    id: 1,
    modifications: [2, 3, 4, 5, 6],
    description:
      'Explore the whole spectrum of tempo by choosing various ranges in each practice session. Remember that slow is just as important as fast and sometimes even more challenging. If you find yourself gravitating toward a certain range, be sure to branch out of the comfort zone as often as possible for exercises you know relatively well.',
    vimeoId: '452261270',
  },
  {
    title: 'Harmonic',
    id: 2,
    modifications: [
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31,
    ],
    vimeoId: '452261270',
    description:
      'This is how you take your knowledge of the Major Scale to the next level. Work on your ability to identify scale tones and switch them on the spot to create all the various modes and scales. Chose a mode or scale to adapt your exercise to. Work on 1 tonality through many exercises for a longer-term study, or jump around each day if you want to test your ability to improvise and adjust.',
  },
  {
    title: 'Progressions',
    id: 3,
    modifications: [32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43],
    vimeoId: '452261270',
    description:
      'Music is ultimately the play of combining patterns. These common cycles or progressions will show you how to think in patterns and learn to identify logical movements through keys or chords. Practicing any exercise using all progressions will help you more immediately access any chord change or key change in real playing situations.',
  },
  {
    title: 'Voice Leading',
    id: 4,
    modifications: [44, 45],
    vimeoId: '452261270',
    description:
      'Work on your ability to swiftly move through chords by exploring the various inversion options. With the power of choice, you are able to make artistic decisions in your music that create an intended result. Learning how to travel through inversions in an elegant way is one of the most sophisticated and effective techniques for chordal instrumentalists to express songs with a personal touch.',
  },
  {
    title: 'Rhythm',
    id: 5,
    modifications: [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
    vimeoId: '452261270',
    description:
      'Deepen your understanding of the beat by attempting a solid and steady execution of you exercises through any subdivision or rhythmic value. Use each of these modifications to challenge your familiarity with the exercise, as well as to strengthen your sense of time (which is the quality of our playing that will reach listeners above anything else including note choices).',
  },
  {
    title: 'Time Feel',
    id: 6,
    modifications: [57, 58, 59, 60, 61],
    vimeoId: '452261270',
    description:
      'Work on your sensitivity to Time Feel by exploring how you express your exercises. Using your exercises to work on more subtle aspects of time will help introduce you to concepts that you can chose to make a part of your playing. Even if you gravitate toward a default time feel, see if you can expand that sensitivity to develop control over your own time, and therefore a deeper appreciation for other players.',
  },
  {
    title: 'Melodic',
    id: 7,
    modifications: [62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74],
    vimeoId: '452261270',
    description:
      'Get more out of your exercises by exploring some of the infinite melodic variations. See if you can figure out how to play them in different ways, get creative, and develop your mind’s ability to change things up. This will help you strengthen your sense of the Root, and also challenge your coordination and ear training.',
  },
  {
    title: 'Voicing',
    id: 8,
    modifications: [75, 76, 77],
    vimeoId: '452261270',
    description:
      'Expand your chord vocabulary by applying variations to your exercises. Work on your ability to invert chords on the spot, or to notice the difference between spread and close voicings. Practicing chords in a structured container will give you the vision and skill to use chords in ways you wouldn’t have imagined, you never know what these modifications may inspire in you!',
  },
  {
    title: 'Directional',
    id: 9,
    modifications: [78, 79, 80, 81],
    vimeoId: '452261270',
    description:
      'Work on your visual fretboard reference by altering exercises directionally. Sometimes playing from the highest note to the lowest will be enough to trip us up, but by exploring these possibilities we can prepare for anything in musical situations. This will also strengthen your relationship with the root, and help you navigate the correct notes in more ways than one.',
  },
  {
    title: 'Right Hand',
    id: 10,
    modifications: [82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92],
    vimeoId: '452261270',
    description:
      'Dissect the possibilities of your right hand articulation to find the techniques that most suit your personal voice. So much of our sound is determined by how we use the right hand. Developing right hand control in ways that are most relevant to your style and genres is one of the keys to expressiveness.',
  },
  {
    title: 'Picking Pattern',
    id: 11,
    modifications: [93, 94, 95],
    vimeoId: '452261270',
    description:
      'Challenge your coordination and right hand control by getting creative with your picking patterns. Sometimes by switching the order in which you play the notes of a chord, you can completely shift the feeling of the chords being played. You will also notice that your brain works quite hard to learn new patterns and expand its rhythmic sensibility. Experiment as much as possible here, and see what kinds of games you can play with the right hand, and how the notes line up with the beats.',
  },
  {
    title: '',
    id: 12,
    modifications: [],
    vimeoId: '452261270',
    description: '',
  },
];

export const modifications = [
  {
    title: 'Default',
    id: 2,
    category: 'Tempo',
    isDefault: true,
    description: 'This is the tempo Ila plays in the video',
  },
  {
    title: '60-80bpm',
    id: 3,
    category: 'Tempo',
    isDefault: false,
    description: 'Larghetto, Adagio',
  },
  {
    title: '80-100bpm',
    id: 4,
    category: 'Tempo',
    isDefault: false,
    description: 'Andante, Andante Moderato',
  },
  {
    title: '100-120bpm',
    id: 5,
    category: 'Tempo',
    isDefault: false,
    description: 'Moderato, Allegreto',
  },
  {
    title: '>120bpm',
    id: 6,
    category: 'Tempo',
    isDefault: false,
    description: 'Allegro, Vivace, Presto',
  },
  {
    title: 'Major',
    id: 7,
    category: 'Harmonic',
    isDefault: false,
    description: 'Scale Tones: R, 2, 3, 4, 5, 6, 7',
  },
  {
    title: 'Harmonic Minor',
    id: 8,
    category: 'Harmonic',
    isDefault: false,
    description: 'Scale Tones: R, 2, b3, 4, 5, b6, 7',
  },
  {
    title: 'Harmonic Major',
    id: 9,
    category: 'Harmonic',
    isDefault: false,
    description: 'Scale Tones: R, 2, 3, 4, 5, b6, 7',
  },
  {
    title: 'Melodic Minor',
    id: 10,
    category: 'Harmonic',
    isDefault: false,
    description: 'Scale Tones: R, 2, b3, 4, 5, 6, 7',
  },
  {
    title: 'Ionian (Default)',
    id: 11,
    category: 'Harmonic',
    isDefault: true,
    description: 'Scale Tones: R, 2, 3, 4, 5, 6, 7',
  },
  {
    title: 'Dorian',
    id: 12,
    category: 'Harmonic',
    isDefault: false,
    description: 'Scale Tones: R, 2, b3, 4, 5, 6, b7',
  },
  {
    title: 'Phrygian',
    id: 13,
    category: 'Harmonic',
    isDefault: false,
    description: 'Scale Tones: R, b2, b3, 4, 5, b6, b7',
  },
  {
    title: 'Lydian',
    id: 14,
    category: 'Harmonic',
    isDefault: false,
    description: 'Scale Tones: R, 2, 3, #4, 5, 6, 7',
  },
  {
    title: 'Mixolydian',
    id: 15,
    category: 'Harmonic',
    isDefault: false,
    description: 'Scale Tones: R, 2, 3, 4, 5, 6, b7',
  },
  {
    title: 'Aeolian',
    id: 16,
    category: 'Harmonic',
    isDefault: false,
    description: 'Scale Tones: R, 2, b3, 4, 5, b6, 7',
  },
  {
    title: 'Locrian',
    id: 17,
    category: 'Harmonic',
    isDefault: false,
    description: 'Scale Tones: R, b2, b3, 4, b5, b6, b7',
  },
  {
    title: 'Make the Triads Sus 4',
    id: 18,
    category: 'Harmonic',
    isDefault: false,
    description: 'Exchange the 3rd for the 4th in any voicing',
  },
  {
    title: 'Make the Triads Sus2',
    id: 19,
    category: 'Harmonic',
    isDefault: false,
    description: 'Exchange the 3rd for the 2nd in any voicing',
  },
  {
    title: 'Harmonic Minor (Chords)',
    id: 20,
    category: 'Harmonic',
    isDefault: false,
    description:
      'Move through diatonic harmony within the Harmonic Minor Scale',
  },
  {
    title: 'Harmonic Major (Chords)',
    id: 21,
    category: 'Harmonic',
    isDefault: false,
    description:
      'Move through diatonic harmony within the Harmonic Major Scale',
  },
  {
    title: 'Melodic Minor (Chords)',
    id: 22,
    category: 'Harmonic',
    isDefault: false,
    description: 'Move through diatonic harmony within the Melodic Minor Scale',
  },
  {
    title: 'Harmonic Minor (7th Chords)',
    id: 23,
    category: 'Harmonic',
    isDefault: false,
    description:
      'Move through diatonic harmony within the Harmonic Minor Scale',
  },
  {
    title: 'Melodic Minor (7th Chords)',
    id: 24,
    category: 'Harmonic',
    isDefault: false,
    description: 'Move through diatonic harmony within the Melodic Minor Scale',
  },
  {
    title: '#5',
    id: 25,
    category: 'Harmonic',
    isDefault: false,
    description: 'Sharp the 5th for an augmented sound',
  },
  {
    title: '#11',
    id: 26,
    category: 'Harmonic',
    isDefault: false,
    description: 'Turn the 5th into a #11',
  },
  {
    title: '7th down to the 6th',
    id: 27,
    category: 'Harmonic',
    isDefault: false,
    description: 'Drop the 7th to the 6th for a mellow 6 chord',
  },
  {
    title: '5th brought up to the 13th',
    id: 28,
    category: 'Harmonic',
    isDefault: false,
    description: 'Raise the 5th to a 13 for a useful extension',
  },
  {
    title: 'b5',
    id: 29,
    category: 'Harmonic',
    isDefault: false,
    description: 'Flat the 5 to create movement and color',
  },
  {
    title: 'Minor/Major 7',
    id: 30,
    category: 'Harmonic',
    isDefault: false,
    description:
      'Raise the b7 to a natural 7, this is the first chord in the Harmonic & Melodic minor scales',
  },
  {
    title: 'b7th goes to the 6th',
    id: 31,
    category: 'Harmonic',
    isDefault: false,
    description: 'Drop the b7 down to a 6 for movement and variation',
  },
  {
    title: 'Cycle of 4ths/5ths',
    id: 32,
    category: 'Progressions',
    isDefault: false,
    isLocked: true,
    description: 'Play your exercises through all Keys using this cycle',
  },
  {
    title: 'Cycle of Major 3rds/Minor 6ths',
    id: 33,
    category: 'Progressions',
    isDefault: false,
    isLocked: true,
    description:
      'Play your exercises through 3 keys using this cycle, start in various keys to move through all 12',
  },
  {
    title: 'Cycle of Minor 3rds/Major 6ths',
    id: 34,
    category: 'Progressions',
    isDefault: false,
    description:
      'Play your exercises through 4 keys using this cycle, start in various keys to move through all 12',
  },
  {
    title: 'Cycle of Whole Steps',
    id: 35,
    category: 'Progressions',
    isDefault: false,
    description:
      'Play your exercises through 6 keys using this cycle, then start a half step up to get the next 6',
  },
  {
    title: 'Random Keys',
    id: 36,
    category: 'Progressions',
    isDefault: false,
    description: 'This is the best way to test your fluency of roots!',
  },
  {
    title: 'Chromatically',
    id: 37,
    category: 'Progressions',
    isDefault: false,
    description:
      'This is the best place to start when playing something new in all keys',
  },
  {
    title: 'Cycle of 5ths/4ths (Triads)',
    id: 38,
    category: 'Progressions',
    isDefault: false,
    description: 'Discover how triads move through the cycle of 4ths & 5ths',
  },
  {
    title: 'Cycle of Maj 3rds/ Min 6th (Triads)',
    id: 39,
    category: 'Progressions',
    isDefault: false,
    description:
      'Discover how triads move through the cycle of min3rds & Maj6ths',
  },
  {
    title: 'Cycle of Min 3rds/ Maj 6th (Triads)',
    id: 40,
    category: 'Progressions',
    isDefault: false,
    description:
      'Discover how triads move through the cycle of Maj3rds & min6ths',
  },
  {
    title: 'Whole Steps (Triads)',
    id: 41,
    category: 'Progressions',
    isDefault: false,
    description: 'Discover how triads move through a Whole Tone Scale',
  },
  {
    title: 'Chromatically (Triads)',
    id: 42,
    category: 'Progressions',
    isDefault: false,
    description:
      'Integrate any triad shape by playing it chromatically though all keys',
  },
  {
    title: 'Chromatically (7th Chords)',
    id: 43,
    category: 'Progressions',
    isDefault: false,
    description:
      'Integrate any 7th Chord shape by playing it chromatically though all keys',
  },
  {
    title: 'Voice Leading (Triads)',
    id: 44,
    category: 'Voice Leading',
    isDefault: false,
    description:
      'Find the closest and most fluid sounding inversions to move through progressions',
  },
  {
    title: 'Voice Leading (7th Chords)',
    id: 45,
    category: 'Voice Leading',
    isDefault: false,
    description:
      'Find the closest and most fluid sounding inversions to move through progressions',
  },
  {
    title: 'Whole Notes',
    id: 46,
    category: 'Rhythm',
    isDefault: false,
    description: 'Test your focus and play exercises in perfect whole notes',
  },
  {
    title: 'Half Notes',
    id: 47,
    category: 'Rhythm',
    isDefault: false,
    description: 'Test your focus and play exercises in perfect whole notes',
  },
  {
    title: 'Quarter Notes',
    id: 48,
    category: 'Rhythm',
    isDefault: false,
    description:
      'Play any exercise in quarter notes (on each click of the metronome)',
  },
  {
    title: '1/8th Notes',
    id: 49,
    category: 'Rhythm',
    isDefault: false,
    description: 'Play any exercise in 8th notes (2 notes per click)',
  },
  {
    title: 'Triplets',
    id: 50,
    category: 'Rhythm',
    isDefault: false,
    description: 'Play any exercise in triplets (3 notes per click)',
  },
  {
    title: '1/16th Notes',
    id: 51,
    category: 'Rhythm',
    isDefault: false,
    description: 'Play any exercise in 16th notes (4 notes per click)',
  },
  {
    title: 'Quintuplets',
    id: 52,
    category: 'Rhythm',
    isDefault: false,
    description: 'Play any exercise in quintuplets (5 notes per click)',
  },
  {
    title: 'Sextuplets',
    id: 53,
    category: 'Rhythm',
    isDefault: false,
    description: 'Play any exercise in sextuplets (6 notes per click)',
  },
  {
    title: 'Septuplets',
    id: 54,
    category: 'Rhythm',
    isDefault: false,
    description: 'Play any exercise in sextuplets (7 notes per click)',
  },
  {
    title: '1/32nd Notes',
    id: 55,
    category: 'Rhythm',
    isDefault: false,
    description: 'Play any exercise in 32nd notes (8 notes per click)',
  },
  {
    title: 'Rhythmic Patterns',
    id: 56,
    category: 'Rhythm',
    isDefault: false,
    description:
      'Create unique rhythmic patterns to move through the exercises',
  },
  {
    title: 'Swing',
    id: 57,
    category: 'Time Feel',
    isDefault: false,
    description: 'Explore the bounce of a swing feel',
  },
  {
    title: 'Straight',
    id: 58,
    category: 'Time Feel',
    isDefault: false,
    description: 'Play a steady straight feel',
  },
  {
    title: '6/8 Feel',
    id: 59,
    category: 'Time Feel',
    isDefault: false,
    description: 'Explore the polyrhythmic nauture of 6/8',
  },
  {
    title: '9/8 Feel',
    id: 60,
    category: 'Time Feel',
    isDefault: false,
    description: 'Explore the triplet feel of 9/8',
  },
  {
    title: 'Styles (?)',
    id: 61,
    category: 'Time Feel',
    isDefault: false,
    description: '',
  },
  {
    title: 'Continue the pattern as high and as low as you can',
    id: 62,
    category: 'Melodic',
    isDefault: false,
    description: 'Find notes in the exercise as high as possible',
  },
  {
    title: 'Invert every other pattern',
    id: 63,
    category: 'Melodic',
    isDefault: false,
    description:
      'Switch up the pattern exercises by playing up one and down the other',
  },
  {
    title: 'Invert every other interval',
    id: 64,
    category: 'Melodic',
    isDefault: false,
    description:
      'Switch up the interval exercises by playing up one and down the other',
  },
  {
    title: 'Play Harmonically',
    id: 65,
    category: 'Melodic',
    isDefault: false,
    description: '?',
  },
  {
    title: 'Double Stops',
    id: 66,
    category: 'Melodic',
    isDefault: false,
    description: 'Play both notes at once',
  },
  {
    title: 'Play each triad as a chord',
    id: 67,
    category: 'Melodic',
    isDefault: false,
    description: 'Strum instead of pick each 3 note triad',
  },
  {
    title: 'Play as 4 Note Triads',
    id: 68,
    category: 'Melodic',
    isDefault: false,
    description: 'Find the 3 sets of 4 note triads within these shapes',
  },
  {
    title: 'Play the scale in first inversion',
    id: 69,
    category: 'Melodic',
    isDefault: false,
    description: 'Use your 1st inversion shapes to move through the scale',
  },
  {
    title: 'Play the scale chords in second Inversion',
    id: 70,
    category: 'Melodic',
    isDefault: false,
    description: 'Use your 2nd inversion shapes to move through the scale',
  },
  {
    title: 'Invert every other inversion',
    id: 71,
    category: 'Melodic',
    isDefault: false,
    description:
      'Notice the difference in sound when you play notes ascending vs descending',
  },
  {
    title: 'Invert every other triad',
    id: 72,
    category: 'Melodic',
    isDefault: false,
    description:
      'Notice the difference in sound when you play notes ascending vs descending',
  },
  {
    title: 'Only the top octave',
    id: 73,
    category: 'Melodic',
    isDefault: false,
    description:
      'Play through progressions using just the top octave of arpeggios',
  },
  {
    title: 'Only the Bottom Octave',
    id: 74,
    category: 'Melodic',
    isDefault: false,
    description:
      'Play through progressions using just the bottom octave of arpeggios',
  },
  {
    title: 'Convert to Open Voicings',
    id: 75,
    category: 'Voicing',
    isDefault: false,
    description: 'Find the beautiful open voicing of each triad',
  },
  {
    title: 'Play the Scale Chords in First Inversion',
    id: 76,
    category: 'Voicing',
    isDefault: false,
    description: 'Use your 1st inversion shapes to move through the scale',
  },
  {
    title: 'Play the Scale Chords in Second Inversion',
    id: 77,
    category: 'Voicing',
    isDefault: false,
    description: 'Use your 2nd inversion shapes to move through the scale',
  },
  {
    title: 'Ascending and Descending',
    id: 78,
    category: 'Directional',
    isDefault: false,
    description: 'This is how most exercises are demonstrated',
  },
  {
    title: 'Ascending only',
    id: 79,
    category: 'Directional',
    isDefault: false,
    description: 'Try playing only ascending',
  },
  {
    title: 'Descending only',
    id: 80,
    category: 'Directional',
    isDefault: false,
    description:
      'Test your knowledge of roots by starting at the highest note first',
  },
  {
    title: 'Descending and Ascending',
    id: 81,
    category: 'Directional',
    isDefault: false,
    description:
      'Find creative possibilities by descending the scale first before ascending',
  },
  {
    title: 'Play triplets on each note',
    id: 82,
    category: 'Right Hand',
    isDefault: false,
    description: 'Develop right hand control by playing each note 3 times',
  },
  {
    title: 'Tremolo on each note',
    id: 83,
    category: 'Right Hand',
    isDefault: false,
    description: 'Practice this useful and expressive technique',
  },
  {
    title: 'Slurred (Hammer on’s an pull offs)',
    id: 84,
    category: 'Right Hand',
    isDefault: false,
    description:
      'Work on fluidly blending notes together for speed playing and expressive variation',
  },
  {
    title: 'Hybrid',
    id: 85,
    category: 'Right Hand',
    isDefault: false,
    description: 'Play with a pick and fingers',
  },
  {
    title: 'Finger picking',
    id: 86,
    category: 'Right Hand',
    isDefault: false,
    description: 'Use only your fingers to find picking patterns',
  },
  {
    title: 'Economy',
    id: 87,
    category: 'Right Hand',
    isDefault: false,
    description: 'Economy picking',
  },
  {
    title: 'Alternate',
    id: 88,
    category: 'Right Hand',
    isDefault: false,
    description:
      'Alternate picking is a great place to start developing a solid right hand',
  },
  {
    title: '4x 16th Notes on Each Note',
    id: 89,
    category: 'Right Hand',
    isDefault: false,
    description: 'Work on your alternate picking by playing each note 4 times',
  },
  {
    title: 'Picked',
    id: 90,
    category: 'Right Hand',
    isDefault: false,
    description: 'Use only the pick',
  },
  {
    title: 'Using Fingers',
    id: 91,
    category: 'Right Hand',
    isDefault: false,
    description: 'Use only the fingers',
  },
  {
    title: 'Strummed',
    id: 92,
    category: 'Right Hand',
    isDefault: false,
    description: 'Strum each chord',
  },
  {
    title: 'Arpeggiate the Chord',
    id: 93,
    category: 'Picking Pattern',
    isDefault: false,
    description: 'Pick each note separately to create texture',
  },
  {
    title: 'Make your own picking pattern',
    id: 94,
    category: 'Picking Pattern',
    isDefault: false,
    description: 'Get creative and find new sounds',
  },
  {
    title: 'Invert every other pattern',
    id: 95,
    category: 'Picking Pattern',
    isDefault: false,
    description:
      'Test your coordination by switching the pattern up on each chord',
  },
];

export const makeModifications = (): Modification[] =>
  modifications.map((m) => ({
    ...m,
    vimeoId: '452261270',
  }));

export const activeModifications: ModificationId[] = [6, 8, 10, 16, 24];
