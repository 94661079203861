import React, { FC, useContext } from 'react';
import { StyleSheet } from 'react-native';
import { createDrawerNavigator } from '@react-navigation/drawer';
const Drawer = createDrawerNavigator();

import { L1NavigationContext, UserContext } from 'sonora-containers/index';
import L1SidebarContent from './L1SidebarContent';
import { colors } from 'sonora-design-system';
import { L1Screens, MentorL1Screens } from '../levels/L1Screen';

export interface L1SidebarNavigatorProps {}

const L1SidebarNavigator: FC<L1SidebarNavigatorProps> = (props) => {
  const { userType, payment } = useContext(UserContext);
  const { activeIndex } = useContext(L1NavigationContext);
  const updateL1Screens = payment
    ? payment.coreSubscription?.cancelled
      ? L1Screens.filter((screen) => screen.name === 'Account')
      : L1Screens
    : L1Screens.filter((screen) => screen.name !== 'Account');
  let screens = userType === 'mentor' ? MentorL1Screens : updateL1Screens;

  return (
    <Drawer.Navigator
      initialRouteName={screens[activeIndex].name}
      drawerType="permanent"
      drawerStyle={styles.sidebar}
      drawerContent={(props) => (
        <L1SidebarContent {...props} screens={screens} />
      )}
    >
      {updateL1Screens.map((screen) => (
        <Drawer.Screen
          key={screen.name}
          name={screen.name}
          component={screen.component}
          options={{ title: screen.label }}
        />
      ))}
    </Drawer.Navigator>
  );
};

const styles = StyleSheet.create({
  sidebar: {
    width: '16%',
    borderRightWidth: 0,
    backgroundColor: colors.opal,
  },
});

export default L1SidebarNavigator;
