import React from 'react';
import { Image } from 'react-native';
import liveImage from 'sonora-design-system/assets/images/live.png';

interface LiveBadgeProps {
  isLandscape?: boolean;
}

export const LiveBadge: React.FC<LiveBadgeProps> = ({ isLandscape }) => {
  // TODO different size for portrait/landscape?
  return <Image source={liveImage} style={{ width: 63, height: 29 }} />;
};
