// author: @fullstackdevs14
import React from 'react';
import { storiesOf } from '@storybook/react-native';

import { Calendar } from '.';
import { Box } from '../styled';
import { CalendarViewMode } from './types';

const calendarStories = storiesOf('Calendar', module);

const makeCalendarStory = (
  view: CalendarViewMode,
  highlightedDays: string[]
) => () => {
  return (
    <Box padding={16}>
      <Calendar
        streakDayCount={3}
        view={view}
        highlightedDays={highlightedDays}
      />
    </Box>
  );
};

calendarStories.add(
  'MonthView',
  makeCalendarStory('month', ['2020-08-28', '2020-08-26', '2020-07-27'])
);
calendarStories.add(
  'WeeklyView',
  makeCalendarStory('week', ['2020-08-27', '2020-08-26'])
);
