import React, { FC } from 'react';
import { View, Platform, StyleSheet } from 'react-native';
import { WebView as WebViewMobile } from 'react-native-webview';
import { default as WebViewWeb } from 'react-native-web-webview';

const WebView = Platform.OS === 'web' ? WebViewWeb : WebViewMobile;

export interface MetronomeSidebarProps {}

const MetronomeSidebar: FC<MetronomeSidebarProps> = (props) => {
  return (
    <View style={styles.metronomeContainer}>
      <WebView
        source={{
          uri: 'https://sonora-goat-metronome.herokuapp.com/metronome',
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  metronomeContainer: {
    height: 272,
  },
});

export default MetronomeSidebar;
