import React from 'react';
import { RootNavScreen } from '../screens';
import { ForgotPasswordSuccess } from 'sonora-design-system';

export interface ForgotPasswordSuccessProps {}

const ForgotPasswordSuccessScreen: RootNavScreen<'ForgotPasswordSuccess'> = ({
  navigation,
  route,
}) => {
  const { email } = route.params;

  return (
    <ForgotPasswordSuccess
      email={email}
      onBackToLogin={() => navigation.navigate('Signin')}
    />
  );
};

export default ForgotPasswordSuccessScreen;
