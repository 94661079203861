import { useEffect, useContext } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { WelcomeModal } from 'sonora-components/WelcomeModal';
import { ModalPresenterContext } from 'sonora-design-system';
import { useIsMobileWidth } from 'sonora-design-system/util';

export const useWelcomeModal = (shouldShow:boolean) => {
  const { presentComponentAsModal } = useContext(ModalPresenterContext);
  const isMobile = useIsMobileWidth()
  useEffect(() => {
    async function showModal() {
      const value = await AsyncStorage.getItem('welcomeHidden');
      if ( shouldShow && !value && presentComponentAsModal) {
        if(isMobile){
          presentComponentAsModal(WelcomeModal, {
            width: 490,
            height: 482,
          });
        } else {
          presentComponentAsModal(WelcomeModal, {
            width: 660,
            height: 582,
          });
        }
        await AsyncStorage.setItem('welcomeHidden', 'true');
      }
    }

    showModal();
  }, [shouldShow]);
};
