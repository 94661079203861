import React from 'react';
import { storiesOf } from '@storybook/react-native';

import { Box } from '../styled';
import { ModuleUnlock } from '.';
import { colors } from '../../constants/theme';

const moduleUnlockStories = storiesOf('ModuleUnlock', module);

const ModuleUnlockStory = () => (
  <Box padding={24} backgroundColor={colors.agate}>
    <ModuleUnlock moduleName="Intervals" onGo={() => console.log('go')} />
  </Box>
);

moduleUnlockStories.add('default', ModuleUnlockStory);
