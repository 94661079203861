import React, { useContext } from 'react';

import { LayoutProviderContext, LayoutType } from 'sonora-design-system';

import { RootNavScreen } from '../screens/screens';
import L1PortraitTabNavigator from '../tabs/L1PortraitTabNavigator';
import L1SidebarNavigator from '../sidebar/L1SidebarNavigator';
import { L1FrameLeftLandscapeContainer } from '../frame/L1FrameLeft';

import { Account } from '../screens/Account';
import { Overview } from '../screens/Overview';
import { MentorChat } from '../screens/MentorChat';
import { CourseMap } from '../screens/CourseMap';
import { L1FrameLeftHOC } from '../../router/frame/L1FrameLeft';
import { ModalProviderHOC } from 'sonora-design-system';

export interface Screen {
  name: string;
  component: RootNavScreen<any>;
  iconName: string;
  label: string;
  iconType?: 'stroke' | 'filled';
  hasDivider?: boolean;
  requiresActiveMentor?: boolean;
  requiresHighTicket?: boolean;
}

export const L1Screens: Screen[] = [
  {
    name: 'Account',
    component: ModalProviderHOC<any>(Account),
    iconName: 'account',
    iconType: 'stroke',
    label: 'Account',
    hasDivider: true,
  },
  {
    name: 'Overview',
    component: ModalProviderHOC<any>(Overview),
    iconName: 'home',
    label: 'Home',
  },
  {
    name: 'CourseMap',
    component: ModalProviderHOC<any>(CourseMap),
    iconName: 'lessonMap',
    label: 'Course Map',
  },
  {
    name: 'MentorChat',
    component: L1FrameLeftHOC<any>(ModalProviderHOC<any>(MentorChat)),
    iconName: 'mentor',
    label: 'Mentor',
    requiresHighTicket: true,
  },
];

export const MentorL1Screens: Screen[] = [
  {
    name: 'Overview',
    component: ModalProviderHOC<any>(Overview),
    iconName: 'home',
    label: 'Mentor Home',
  },
  {
    name: 'CourseMap',
    component: ModalProviderHOC<any>(CourseMap),
    iconName: 'lessonMap',
    label: 'Course Map',
  },
];

export interface L1ScreenProps {}

const L1Screen: RootNavScreen<'L1'> = (props) => {
  const { activeLayout } = useContext(LayoutProviderContext);
  const navigator =
    activeLayout === LayoutType.Landscape ? (
      <L1FrameLeftLandscapeContainer>
        <L1SidebarNavigator />
      </L1FrameLeftLandscapeContainer>
    ) : (
      <L1PortraitTabNavigator />
    );

  return navigator;
};

export default L1Screen;
