import { TaskListScreen } from './TaskList';
import { Modifications } from './Modifications';
// import { L2MentorChat } from './L2MentorChat';
// import { History } from './History';

export const L2Screens = [
  {
    name: 'TaskList',
    component: TaskListScreen,
    iconName: 'lessonOverview',
    label: 'Practice list',
  },
  {
    name: 'Modifications',
    component: Modifications,
    iconName: 'modifications',
    label: 'Modifications',
  },
  // {
  //   name: 'History',
  //   component: History,
  //   iconName: 'history',
  //   label: 'History',
  // },
  // {
  //   name: 'L2MentorChat',
  //   component: L2MentorChat,
  //   iconName: 'mentor',
  //   label: 'Mentor',
  // },
];
