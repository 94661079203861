import React from 'react';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

import { Box, Row, Text } from '../styled';
import { Button } from '../Button';
import { FillIcon } from '../Icon';
import { fonts, colors, theme } from '../../constants/theme';
import { StudentListItemProps } from './types';

const StudentListItemWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  padding: 12px 20px;
  border-radius: ${theme.common.borderRadius};
  background-color: ${colors.white};
  margin-bottom: 12px;
`;

const Badge = styled.View`
  position: absolute;
  width: 10px;
  height: 10px;
  right: 0px;
  top: 0px;
  border-radius: 4px;
  background-color: ${colors.validGreen};
  border-radius: 5px;
  border-width: 2px;
  border-color: ${colors.white};
`;

export const StudentListItem: React.FC<StudentListItemProps> = ({
  fullName,
  startDate,
  finishDate,
  lastPracticed,
  lastPracticedColor,
  onOpen,
  onChatOpen,
  hasBadge,
  isNew,
}) => {
  const dateString = `(${startDate} - ${finishDate})`;

  return (
    <StudentListItemWrapper>
      <Box flex={1} overflow="hidden">
        <Text size={12}>
          <Text font={fonts.thin} marginRight={6}>
            {dateString}
          </Text>
          {isNew && <Text color={colors.validGreen}>NEW STUDENT</Text>}
        </Text>
        <Text size={18}>{fullName}</Text>
        <Text>
          <Text font={fonts.thin} marginRight={6}>
            Last Practiced:
          </Text>
          <Text font={fonts.thin} color={lastPracticedColor}>
            {lastPracticed}
          </Text>
        </Text>
      </Box>
      <Row marginLeft={20} flex="none">
        <Box marginRight={12} position="relative">
          <TouchableOpacity onPress={onChatOpen}>
            <FillIcon name="mentor" />
          </TouchableOpacity>
          {hasBadge ? <Badge /> : null}
        </Box>
        <Box>
          <Button type="Primary" text="View" onPress={onOpen} />
        </Box>
      </Row>
    </StudentListItemWrapper>
  );
};
