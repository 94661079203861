import React, { FC, useContext, useState } from 'react';
import { View, Image, StyleSheet, TouchableOpacity } from 'react-native';

import { FillIcon, colors, LayoutProviderContext } from 'sonora-design-system';
import wordmarkSrc from 'sonora-design-system/assets/images/Wordmark.png';
import L1HamburgerMenu from './L1HamburgerMenu';

import { L1HeaderProps } from './L1Header';

const headerPadding = 25;

const L1HeaderPortrait: FC<L1HeaderProps> = (props) => {
  const [hamburgerOpen, setHamburgerOpen] = useState<boolean>(false);

  // TODO (andrew): May be a better way to do this with resizable image libraries
  // The ratio of the height to width for the logo png, needed for resizing behavior
  const logoRatio = 100 / 602;

  const { screenWidth } = useContext(LayoutProviderContext);

  const width = screenWidth * 0.33;
  const height = Math.floor(screenWidth * 0.33 * logoRatio);
  const headerHeight = height + headerPadding * 2;

  const iconButton = hamburgerOpen ? (
    <TouchableOpacity onPress={() => setHamburgerOpen(false)}>
      <FillIcon name="x" />
    </TouchableOpacity>
  ) : (
    <TouchableOpacity onPress={() => setHamburgerOpen(true)}>
      <FillIcon name="menu" />
    </TouchableOpacity>
  );

  return (
    <View style={styles.header}>
      {iconButton}
      <Image source={wordmarkSrc} style={{ width, height }} />
      <View />
      {hamburgerOpen ? (
        <L1HamburgerMenu
          onClose={() => setHamburgerOpen(false)}
          headerHeight={headerHeight}
        />
      ) : undefined}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    backgroundColor: colors.opal,
    flexDirection: 'row',
    padding: headerPadding,
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 1, // Needed for raising hamburger menu over page
  },
});

export default L1HeaderPortrait;
