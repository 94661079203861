import React, { FC, useRef, useState, useContext } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { Video, AVPlaybackStatus } from 'expo-av';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { colors } from 'sonora-design-system';
import { useNavigation } from '@react-navigation/native';
import { Feather } from '@expo/vector-icons';
import { Foundation } from '@expo/vector-icons';
import { LayoutProviderContext } from 'sonora-design-system';
import { useSharedVideoRecorderLogic } from 'sonora-hooks/useVideoCreationLogic';
import { MessageThreadContext } from 'sonora-containers/index';
import { s3uploadVideo } from 'sonora-services/videoSubmission';
import { trackEvent } from 'sonora-services/analytics';

export type UploadVideoFn = (
  videoUri: string,
  onProgress?: (event: ProgressEvent) => void
) => Promise<any>;

export interface VideoReviewProps {
  uri: string;
}

interface PlayPauseButtonProps {
  isPlaying: boolean;
  onPress: () => void;
}

const PlayPauseButton = (props: PlayPauseButtonProps) => (
  <TouchableOpacity
    onPress={props.onPress}
    hitSlop={{ top: 20, left: 20, bottom: 20, right: 20 }}
  >
    {props.isPlaying ? (
      <Foundation name="pause" size={96} color={colors.white} />
    ) : (
      <Feather name="play" size={96} color={colors.white} />
    )}
  </TouchableOpacity>
);

const VideoReview: FC<VideoReviewProps> = (props) => {
  const navigation = useNavigation();
  const insets = useSafeAreaInsets();
  const video = useRef<Video>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const { screenWidth } = useContext(LayoutProviderContext);
  const { messageThreadId } = useContext(MessageThreadContext);

  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const showSuccess = () => {
    navigation.navigate('VideoSubmissionDone');
  };

  const {
    creationResult,
    finalizeSubmissionThenNavigate,
  } = useSharedVideoRecorderLogic({
    isZiggeo: false,
    messageThreadId,
    onFinish: showSuccess,
  });

  /** Uploads to s3 then finalizeSubmissionAndNavigate */
  const onProgress = ({ total, loaded }: ProgressEvent) => {
    setUploadProgress(loaded / total);
  };

  const s3UploadAndFinalize: UploadVideoFn = (videoUri, onProgress) => {
    setIsUploading(true);
    setUploadProgress(0);
    if (
      !creationResult ||
      !creationResult.attachment ||
      !creationResult.attachment.uploadData
    ) {
      throw new Error('s3 upload initiated without required upload data');
    }
    return s3uploadVideo(
      videoUri,
      creationResult.attachment.uploadData,
      finalizeSubmissionThenNavigate,
      onProgress
    );
  };

  const onPressSend = () => {
    trackEvent('s3-upload-finalize');
    s3UploadAndFinalize(props.uri, onProgress);
  };

  const isSendEnabled =
    !!creationResult && creationResult.attachment.id && !isUploading;

  const handlePlaybackStatusUpdate = (status: AVPlaybackStatus) => {
    if (!isPlaying && status.isLoaded && status.isPlaying) {
      setIsPlaying(true);
    } else if (isPlaying && (!status.isLoaded || !status.isPlaying)) {
      setIsPlaying(false);
    }
  };

  const handlePlayPausePress = () => {
    if (!video.current) return;
    if (isPlaying) {
      video.current.pauseAsync();
    } else {
      video.current.playAsync();
    }
  };

  return (
    <View
      style={{
        flex: 1,
        paddingTop: insets.top,
        backgroundColor: colors.black,
        paddingBottom: insets.bottom,
      }}
    >
      <Video
        style={{ height: screenWidth, flex: 1 }}
        ref={video}
        isLooping
        shouldPlay
        resizeMode={Video.RESIZE_MODE_CONTAIN}
        source={{ uri: props.uri }}
        useNativeControls={false}
        onPlaybackStatusUpdate={handlePlaybackStatusUpdate}
      />
      <View style={styles.buttonsContainer}>
        <PlayPauseButton isPlaying={isPlaying} onPress={handlePlayPausePress} />
        <TouchableOpacity
          style={styles.sendButtonContainer}
          onPress={onPressSend}
          disabled={!isSendEnabled}
        >
          <Text style={{ marginRight: 6 }}>Send</Text>
          {isUploading && <Text>{Math.round(uploadProgress * 100)}%</Text>}
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  sendButtonContainer: {
    top: 30,
    right: 50,
    position: 'absolute',
    flexDirection: 'row',
    zIndex: 1,
    paddingHorizontal: 24,
    paddingVertical: 8,
    backgroundColor: 'white',
  },
  buttonsContainer: {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default VideoReview;
