import React from 'react';
import styled from 'styled-components/native';
import { colors, fonts } from '../../constants/theme';

export interface CardContainerProps {
  isInvalid?: boolean;
}

export const CardContainer: React.FC<CardContainerProps> = styled.View`
  background-color: ${colors.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${(props: CardContainerProps) =>
    props.isInvalid ? colors.alertRed : colors.dividerGray};
  border-radius: 4px;
  padding: 24px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 12px;
`;

export interface InstallmentBarProps {
  width: number;
  color?: string;
}

export const InstallmentBar: React.FC<InstallmentBarProps> = styled.View`
  width: ${(props: InstallmentBarProps) => props.width}px;
  height: 5px;
  border-radius: 4px;
  background-color: ${(props: InstallmentBarProps) =>
    props.color || colors.purpley};
  margin-right: 12px;
`;

export interface ButtonTextProps {
  color: string;
}

export const ButtonText = styled.Text`
  font-family: ${fonts.normal};
  font-size: 14px;
  color: ${(props: ButtonTextProps) => props.color};
  text-transform: uppercase;
`;

export interface PaymentPlanCardContainerProps {
  zIndex: number;
}

export const PaymentPlanCardContainer: React.FC<PaymentPlanCardContainerProps> = styled.View`
  width: 250px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 0px 40px rgba(81, 39, 174, 0.1);
  z-index: ${(props: PaymentPlanCardContainerProps) => props.zIndex};
`;
