import React from 'react';
import { Linking } from 'react-native';
import { Box, Row, Text } from '../styled';
import { colors, fonts } from '../../constants/theme';
import { IStripeSubscription } from 'sonora-types';
import { shortYearDatestring } from 'sonora-util/dateUtils';
import { Button } from '../Button';
import { TouchableOpacity } from 'react-native';

export interface PremiumPauseViewProps {
  details: IStripeSubscription;
  onCancel?: () => void;
  onPause?: () => void;
}

export const PremiumPauseView: React.FC<PremiumPauseViewProps> = ({
  details,
  onCancel,
  onPause,
}) => {
  const dateString = shortYearDatestring(details.startDate);

  const handleContactSupport = () => {
    Linking.openURL('mailto:contact@guitarmasteryintensive.com');
  };

  return (
    <Box width="100%" padding={60} backgroundColor={colors.white}>
      <Text font={fonts.headline} size={24} textAlign="center">
        You're about to pause Fellowship
      </Text>
      <Text font={fonts.thin} size={16} textAlign="center" marginBottom={24}>
        This will temporarily pause access to your preferred mentor, and take
        effect on the last day of the billing cycle: {dateString}
      </Text>
      <Box width="100%" backgroundColor={colors.opal} padding={36}>
        <Box maxWidth={242} width="100%" marginX="auto">
          <Text
            font={fonts.thin}
            size={16}
            textAlign="center"
            marginBottom={12}
          >
            (the last day of this billing cycle)
          </Text>
          <Text size={18} textAlign="center" marginBottom={12}>
            {dateString}
          </Text>
          <Text textAlign="center">
            Note: You can only pause your subscription once per calendar year.
          </Text>
        </Box>
      </Box>
      <Row justifyContent="center" marginY={24}>
        <Button type="Secondary" text="Cancel" onPress={onCancel} />
        <Box width="12px" />
        <Button type="Primary" text="Pause" onPress={onPause} />
      </Row>
      <Row justifyContent="center">
        <Text size={16} marginRight={4}>
          Need help?
        </Text>
        <Text font={fonts.thin} size={16} marginRight={4}>
          Contact
        </Text>
        <TouchableOpacity onPress={handleContactSupport}>
          <Text font={fonts.thin} size={16} color={colors.neutralBlue}>
            support
          </Text>
        </TouchableOpacity>
        <Text>.</Text>
      </Row>
    </Box>
  );
};
