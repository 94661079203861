import React from 'react';
import { View } from 'react-native';
import { fonts, colors } from 'sonora-design-system';
import { LearningActivity } from 'sonora-types';
// import styled from 'styled-components/native';
import { Text } from 'sonora-design-system';
interface L3ActivityDetailsProps {
  activity: LearningActivity;
}

// const StyledDetailTitle = styled.Text`
//   font-family: ${fonts.bold};
//   font-size: 16px;
//   color: ${colors.tanzanite};
//   margin-bottom: 4px;
// `;

// const StyledDetailText = styled.Text`
//   font-family: ${fonts.normal};
//   font-size: 16px;
//   color: ${colors.tanzanite};
//   margin-bottom: 4px;
// `;

export const L3ActivityDetails: React.FC<L3ActivityDetailsProps> = (props) => {
  return (
    <View>
      <Text
        color={colors.tanzanite}
        font={fonts.headline}
        size={20}
        marginBottom={16}
      >
        {props.activity.name}
      </Text>
      <Text
        color={colors.tanzanite}
        font={fonts.thin}
        size={16}
        lineHeight={'18px'}
      >
        {props.activity.description}
      </Text>
    </View>
  );
};
