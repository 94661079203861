import React from 'react';
import { Image, ImageSourcePropType } from 'react-native';

interface WordmarkProps {
  variant?: 'primary' | 'ruby' | 'amethyst' | 'white' | 'black';
  size?: 'default' | 'lg' | 'frame' | 'auth';
  // todo: s/m/l props that set Svg width and height
}

const sizes: { [key: string]: object } = {
  default: { width: 602, height: 85 },
  frame: { width: 120, height: 20 },
  auth: { width: 233, height: 39 },
};

export const sources: { [key: string]: ImageSourcePropType } = {
  primary: require('../../assets/wordmark/primary.png'),
  ruby: require('../../assets/wordmark/ruby.png'),
  amethyst: require('../../assets/wordmark/amethyst.png'),
  white: require('../../assets/wordmark/white.png'),
  black: require('../../assets/wordmark/black.png'),
};

export const Wordmark: React.FC<WordmarkProps> = ({
  variant = 'primary',
  size = 'lg',
}) => <Image style={sizes[size]} source={sources[variant]} />;
