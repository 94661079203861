import { Platform } from 'react-native';
import Constants from 'expo-constants';
import * as Sentry from 'sentry-expo';

export const versionNumber = Constants.manifest.version;
export const appVersionString = () => `Sonora v${versionNumber}`;

export const isWebProd = () =>
  window &&
  window.location &&
  window.location.host.includes('guitarmasteryintensive.com');

export const isMobileProd = () =>
  Constants.manifest &&
  Constants.manifest.releaseChannel &&
  Constants.manifest.releaseChannel.indexOf('prod') !== -1;

export const isProduction = () => isMobileProd() || isWebProd();
export const isDevelopment = () => !isProduction();

export function ziggeoApiKey() {
  return isDevelopment()
    ? '99fd5a7f38098600a7c726b2f65b411d'
    : '2fa0caaf0be27dcb7927377b14df8f8e';
}

export const STORE_PREFIX = '@SonoraApp';
export const AUTH_TOKEN_KEY = `${STORE_PREFIX}/AuthToken`;

export function graphQLUrl() {
  const apiUrl = isMobileProd()
    ? 'https://api.guitarmasteryintensive.com/graphql'
    : isWebProd()
    ? '/graphql'
    : 'https://dev.guitarmasteryintensive.com/graphql';

  return apiUrl;
}

export function amplitudeApiKey() {
  return isDevelopment()
    ? '694023a0763212f5c73af4d10260e203'
    : 'e288ff1b8e175c4e145832afa0a72167';
}

export function initializeSentry() {
  const sentryDsn = isProduction()
    ? 'https://7241431f5e994dafa777169c2fcc730a@o312289.ingest.sentry.io/5414006'
    : 'https://7dfdf0474b3745d196f5a33678cb8ff7@o312289.ingest.sentry.io/5435300';
  if (Platform.OS === 'web') {
    Sentry.init({
      dsn: sentryDsn,
      release: Constants.manifest.revisionId,
    });
    console.log(appVersionString());
  } else {
    Sentry.init({
      dsn: sentryDsn,
      enableInExpoDevelopment: true,
    });
    if (Sentry.setRelease) {
      Sentry.setRelease(Constants.manifest.revisionId || 'noRevisionId');
    }
  }
}

export function stripeCoreAccessKey() {
  return isDevelopment() ? 'prod_IfTJ943yrXTIX8' : 'prod_IG58yfCJgrY5dK';
}

export function stripeFellowshipKeys() {
  return isDevelopment()
    ? ['prod_IfTTMDva94IY1g', 'prod_IfTWyHuNzTef1D']
    : ['prod_IiuSnL806TlzQE', 'prod_If8zt1V9P2Qebu'];
}

export function installmentsPriceId() {
  return isDevelopment()
    ? 'price_1I48MiGRXKJXDykyzPHJvD34'
    : 'price_1I3oxGGRXKJXDykybvM6H8tb';
}

export function upfrontPriceId() {
  return isDevelopment()
    ? 'price_1I48MiGRXKJXDykyfVeGgwRq'
    : 'price_1HfYy5GRXKJXDykyVLP5mYhM';
}
