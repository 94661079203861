import React, { FC } from 'react';

export interface MentorStudentProviderProps {
  relationshipId: number;
}

interface MentorStudentContextState {
  relationshipId: number;
}

export const MentorStudentContext = React.createContext(
  {} as MentorStudentContextState
);

export const MentorStudentProvider: FC<MentorStudentProviderProps> = (
  props
) => {
  return (
    <MentorStudentContext.Provider
      value={{ relationshipId: props.relationshipId }}
    >
      {props.children}
    </MentorStudentContext.Provider>
  );
};
