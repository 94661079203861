import React, { FC, useState } from 'react';
import { View, StyleSheet } from 'react-native';

import L2PortraitTabs from './L2PortraitTabs';
import L2PortraitDrawer from './L2PortraitDrawer';
import L2PortraitDroneDrawer from './L2PortraitDroneDrawer';
import MetronomePortraitButton from 'sonora-components/metronome/MetronomePortraitButton';
import { L2Content } from './L2Content';
import { L2ScreenProps } from '../screens/screens';

const L2PortraitNavigator: FC<L2ScreenProps> = (props) => {
  const [isDroneDrawerOpen, setDroneDrawerOpen] = useState<boolean>(false);

  return (
    <View style={styles.navigator}>
      <View style={styles.contentContainer}>
        <L2Content {...props} />
        <View style={styles.metronomeButtonContainer}>
          <MetronomePortraitButton setDroneDrawerOpen={setDroneDrawerOpen} />
        </View>
      </View>
      <View>
        <L2PortraitDrawer />
        <L2PortraitDroneDrawer
          isDrawerOpen={isDroneDrawerOpen}
          setDroneDrawerOpen={setDroneDrawerOpen}
        />
        <L2PortraitTabs />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  navigator: { flex: 1 },
  contentContainer: { flex: 1 },
  metronomeButtonContainer: {
    position: 'absolute',
    bottom: 24,
    right: 24,
  },
});

export default L2PortraitNavigator;
