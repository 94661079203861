import React, { forwardRef } from 'react';
import { Platform } from 'react-native';
import { WebView as WebViewMobile } from 'react-native-webview';
// @ts-ignore ReactNativeWebWebview has no types at the moment
import { default as WebViewWeb } from 'react-native-web-webview';
const WebView = Platform.OS === 'web' ? WebViewWeb : WebViewMobile;

import { Box } from '../styled';
import { ButtonRow } from '../ButtonRow';
import { ButtonProps } from '../Button';
import { LinkNodeViewProps } from './types';
import { useIsPortrait } from '../../util';
import { NodeViewTabs } from './NodeViewTabs';

export const LinkNodeView = forwardRef<JSX.Element, LinkNodeViewProps>(
  (props, ref) => {
    const { linkUrl, primaryCta, secondaryCta, tabs } = props;

    const secondaryCtaButton = secondaryCta && {
      ...secondaryCta,
      type: 'Secondary',
    };
    const primaryCtaButton = primaryCta && { ...primaryCta, type: 'Primary' };
    const isPortrait = useIsPortrait();

    let content = (
      <Box flex={1}>
        <WebView source={{ uri: linkUrl }} />
      </Box>
    );

    const buttons = [secondaryCtaButton, primaryCtaButton].filter(
      Boolean
    ) as ButtonProps[];

    const formattedTabs = <NodeViewTabs tabs={tabs} />;

    const formattedButtons = <ButtonRow buttons={buttons} />;
    return (
      <Box height="100%" flex={1}>
        {isPortrait ? (
          <>
            {formattedButtons}
            {content}
            {formattedTabs}
          </>
        ) : (
          <>
            {content}
            {formattedButtons}
            {formattedTabs}
          </>
        )}
      </Box>
    );
  }
);
