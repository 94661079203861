import React from 'react';
import { Platform, TextInputProps } from 'react-native';
import styled from 'styled-components/native';

import { Box, Text } from '../styled';
import { colors, fonts } from '../../constants/theme';

const StyledTextInput = styled.TextInput`
  padding: 14px 20px 16px;
  border-width: 1px;
  font-family: ${fonts.thin};
  font-size: 20px;
  color: ${colors.tanzanite};
  width: 100%;
  margin-bottom: 4px;
  z-index: 1;
  ${Platform.OS === 'web' ? 'outline-width: 0;' : ''}
  ${(props: { borderColor?: string }) =>
    `
  border-color: ${props.borderColor || colors.tanzanite};
  `}
`;

interface InputFieldProps extends TextInputProps {
  value: string;
  label: string;
  error?: string;
  hasExternalError?: boolean;
}

export const InputField: React.FC<InputFieldProps> = ({
  value,
  label,
  error,
  hasExternalError,
  onBlur,
  ...otherProps
}) => {
  const ref = React.useRef<any>();
  const [isFocused, setIsFocused] = React.useState(false);
  const isFloating = isFocused || value;
  const top = isFloating ? '-10px' : '16px';
  const size = isFloating ? 14 : 20;
  const errorColor =
    hasExternalError || !!error ? colors.alertRed : colors.tanzanite;

  return (
    <Box position="relative" width="100%" marginBottom={24}>
      <Box
        position="absolute"
        left="12px"
        zIndex={isFloating ? 2 : 0}
        top={top}
        backgroundColor={colors.white}
        paddingX={10}
      >
        <Text font={fonts.thin} size={size} color={errorColor}>
          {label}
        </Text>
      </Box>
      <StyledTextInput
        onFocus={() => setIsFocused(true)}
        onBlur={(e: any) => {
          setIsFocused(false);
          if (onBlur) {
            onBlur(e);
          }
        }}
        ref={ref}
        borderColor={errorColor}
        {...otherProps}
      />
      {error ? (
        <Box paddingLeft={22}>
          <Text color={errorColor} font={fonts.thin}>
            {error}
          </Text>
        </Box>
      ) : null}
    </Box>
  );
};
