import React, { FC, useEffect, useRef } from 'react';
import { View, StyleSheet, TouchableOpacity, Animated } from 'react-native';

import { FillIcon } from 'sonora-design-system';
import { colors } from 'sonora-design-system';

export interface L2TabMenuItemProps {
  iconName: string;
  label: string;
  onPress: () => void;
  focused: boolean;
  disabled?: boolean;
}

const L2TabMenuItem: FC<L2TabMenuItemProps> = (props) => {
  let color = colors.tanzanite;
  if (props.focused) {
    color = colors.amethyst;
  }
  if (props.disabled) {
    color = colors.purpley;
  }

  const height = props.focused ? 15 : 0;
  let heightAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(heightAnim, {
      toValue: height,
      duration: 100,
      useNativeDriver: false,
    }).start();
  }, [props.focused]);

  // Need opacity for animation on web, height 0 is still visible
  const label = (
    <Animated.Text
      style={[styles.label, { color, height: heightAnim, opacity: heightAnim }]}
    >
      {props.label}
    </Animated.Text>
  );

  const menuItem = (
    <View style={styles.menuItem}>
      <FillIcon name={props.iconName} color={color} size="nav" />
      {label}
    </View>
  );

  return props.disabled ? (
    menuItem
  ) : (
    <TouchableOpacity onPress={props.onPress}>{menuItem}</TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  menuItem: {
    backgroundColor: colors.opal,
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    fontSize: 12,
  },
});

export default L2TabMenuItem;
