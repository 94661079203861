import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { BillingDetails } from './BillingDetails';
import { BillingOverview } from './BillingOverview';
import { PaymentDetails } from './PaymentDetails';

const Stack = createStackNavigator();

export const Billing = () => {
  return (
    <Stack.Navigator
      initialRouteName="BillingOverview"
      headerMode="screen"
      screenOptions={{ animationEnabled: false, cardShadowEnabled: false }}
    >
      <Stack.Screen name="BillingOverview" component={BillingOverview} />
      <Stack.Screen name="BillingDetails" component={BillingDetails} />
      <Stack.Screen name="PaymentDetails" component={PaymentDetails} />
    </Stack.Navigator>
  );
};
