import React, { forwardRef } from 'react';
import { View, LayoutChangeEvent } from 'react-native';
import styled from 'styled-components/native';
import { WebView } from 'react-native-webview';
import { colors } from '../../constants/theme';
import { VimeoPlayerProps } from './types';

const StyledWebview = styled(WebView)`
  background-color: ${colors.gray36};
`;

export const VimeoMobile = forwardRef<WebView, VimeoPlayerProps>(
  ({ vimeoVideoID, maxHeight = 0 }, ref) => {
    const [webviewWidth, setWebviewWidth] = React.useState(0);
    const [webviewHeight, setWebviewHeight] = React.useState(0);
    const sourceLink = `https://player.vimeo.com/video/${vimeoVideoID}?playsinline=1&autoplay=0`;

    return (
      <View
        style={
          webviewHeight > 0
            ? { width: webviewWidth, height: webviewHeight }
            : {}
        }
        onLayout={(ev: LayoutChangeEvent) => {
          const { width } = ev.nativeEvent.layout;

          const height = (width * 9) / 16;
          if (maxHeight > 0 && maxHeight < height) {
            setWebviewHeight(maxHeight);
            setWebviewWidth((maxHeight * 16) / 9);
          } else {
            setWebviewHeight(height);
            setWebviewWidth(width);
          }
        }}
      >
        <StyledWebview
          style={{ width: webviewWidth, height: webviewHeight }}
          ref={ref}
          source={{ uri: sourceLink }}
          scalesPageToFit={true}
          bounces={false}
          javaScriptEnabled
          allowsFullscreenVideo={true}
          automaticallyAdjustContentInsets={false}
        />
      </View>
    );
  }
);
