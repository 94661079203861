import React, { forwardRef } from 'react';
import styled from 'styled-components/native';
import { colors } from '../../constants/theme';
import { VimeoPlayerProps } from './types';

const VideoContainer = styled.View`
  flex: none;
  background-color: ${colors.gray36};
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const VimeoWeb = forwardRef<HTMLIFrameElement, VimeoPlayerProps>(
  ({ vimeoVideoID, maxHeight = 0, autoplay }, ref) => {
    const [iframeWidth, setIFrameWidth] = React.useState(0);
    const [iframeHeight, setIFrameHeight] = React.useState(0);
    const sourceLink = `https://player.vimeo.com/video/${vimeoVideoID}?playsinline=1${
      autoplay ? '&autoplay=1' : ''
    }`;

    return (
      <VideoContainer
        onLayout={(ev: any) => {
          const { width } = ev.nativeEvent.layout;
          const height = (width * 9) / 16;
          if (maxHeight > 0 && maxHeight < height) {
            setIFrameHeight(maxHeight);
            setIFrameWidth((maxHeight * 16) / 9);
          } else {
            setIFrameHeight(height);
            setIFrameWidth(width);
          }
        }}
      >
        <iframe
          ref={ref}
          src={sourceLink}
          style={{ width: iframeWidth, height: iframeHeight }}
          scrolling="no"
          frameBorder="0"
          allowFullScreen
          allow="autoplay;fullscreen"
        ></iframe>
      </VideoContainer>
    );
  }
);
