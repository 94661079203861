import React from 'react';

import { Box } from '../styled';
import { StudentListProps } from './types';
import { relationshipToStudentListItem } from './utils';
import { StudentListItem } from './StudentListItem';

export const StudentList: React.FC<StudentListProps> = ({
  currentStudents,
  navigateChat,
}) => {
  return (
    <Box width="100%">
      {currentStudents.map((student) => (
        <StudentListItem
          key={student.id}
          hasBadge={student.unreadCount > 0}
          {...relationshipToStudentListItem(student, navigateChat)}
          lastPracticed={student.lastPracticed}
          lastPracticedColor={student.lastPracticedColor}
        />
      ))}
    </Box>
  );
};
