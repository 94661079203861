import React, { FC, useState } from 'react';

interface L1NavigationContextState {
  activeIndex: number;
  setActiveIndex: (index: number) => void;
}

export const L1NavigationContext = React.createContext<
  L1NavigationContextState
>({} as L1NavigationContextState);

export interface L1NavigationProviderProps {}

export const L1NavigationProvider: FC<L1NavigationProviderProps> = (props) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  return (
    <L1NavigationContext.Provider value={{ activeIndex, setActiveIndex }}>
      {props.children}
    </L1NavigationContext.Provider>
  );
};
