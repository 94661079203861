import React, { useEffect } from 'react';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import { useHover, useActive } from 'react-native-web-hooks';

import { Box, Row, Text } from '../styled';
import { Divider } from '../Divider';
import { ProgressBar } from '../ProgressBar';
import { fonts, colors, theme } from '../../constants/theme';
import { useIsLandscape } from '../../util';
import {
  CourseMapMainFolderProps,
  CourseMapMainFolderBorderProps,
  CourseMapMainFolderStudentInfo,
} from './types';

const StyledBorder = styled.View`
  position: relative;
  border-radius: ${theme.common.borderRadius};
  padding: 24px 20px;
  background-color: ${(props: { backgroundColor: string }) =>
    props.backgroundColor};
`;

const StyledBorderInner = styled.View`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  border-radius: ${theme.common.borderRadius};
  border-width: 1px;
  border-color: ${(props: { color: string }) => props.color};
`;

const CourseMapMainFolderBorder: React.FC<CourseMapMainFolderBorderProps> = ({
  backgroundColor,
  children,
  isHovered,
  isPressed,
}) => {
  let color = colors.purpley;
  if (isHovered) {
    color = colors.tanzanite;
  }
  if (isPressed) {
    color = colors.amethyst;
    backgroundColor = colors.agate;
  }

  return (
    <StyledBorder backgroundColor={backgroundColor}>
      <StyledBorderInner color={color} />
      {children}
    </StyledBorder>
  );
};

const lessonCompleteText = ({
  numCompletedLessons,
  numAllLessons,
}: CourseMapMainFolderStudentInfo): string =>
  `${numCompletedLessons}/${numAllLessons}`;
export const CourseMapMainFolderLandscape: React.FC<CourseMapMainFolderProps> = ({
  title,
  description,
  isMentor,
  studentInfo,
  backgroundColor,
  isPressed,
  isHovered,
  setHoveredFolder,
}) => {
  const ref = React.useRef(null);
  const isHoveredHook = useHover(ref);

  useEffect(() => {
    if (isHoveredHook) {
      setHoveredFolder(title);
    }
  }, [isHoveredHook]);

  const isActive = useActive(ref) || isPressed;

  return (
    <Box width="100%" marginBottom={16} ref={ref}>
      <CourseMapMainFolderBorder
        isHovered={isHovered}
        isPressed={isActive}
        backgroundColor={backgroundColor}
      >
        <Text font={fonts.headline} size={20} marginBottom={12}>
          {title}
        </Text>
        <Text font={fonts.thin} size={16}>
          {description}
        </Text>
        {!isMentor && studentInfo && (
          <>
            <Divider marginTop={12} marginBottom={24} />
            <Row>
              <Text font={fonts.bold} size={16} marginRight={4}>
                {lessonCompleteText(studentInfo)}
              </Text>
              <Text font={fonts.thin} size={16} marginRight={12}>
                activities complete
              </Text>
              <Text font={fonts.bold} size={16} marginRight={4}>
                {studentInfo.completePercent}%
              </Text>
              <Text font={fonts.thin} size={16}>
                of subject complete
              </Text>
            </Row>
            <Box width="48px" position="absolute" top="32px" right="24px">
              <ProgressBar percentage={studentInfo.completePercent} />
            </Box>
          </>
        )}
      </CourseMapMainFolderBorder>
    </Box>
  );
};

export const CourseMapMainFolderPortrait: React.FC<CourseMapMainFolderProps> = ({
  title,
  description,
  isMentor,
  studentInfo,
  backgroundColor,
  isPressed,
  isHovered,
  setHoveredFolder,
}) => {
  const ref = React.useRef(null);
  const isHoveredHook = useHover(ref);

  useEffect(() => {
    if (isHoveredHook) {
      setHoveredFolder(title);
    } else {
      setHoveredFolder(undefined);
    }
  }, [isHoveredHook]);

  const isActive = useActive(ref) || isPressed;

  return (
    <Box maxWidth={500} width="100%" marginBottom={16} ref={ref}>
      <CourseMapMainFolderBorder
        isHovered={isHovered}
        isPressed={isActive}
        backgroundColor={backgroundColor}
      >
        <Text font={fonts.headline} size={20} marginBottom={12}>
          {title}
        </Text>
        <Text font={fonts.thin} size={16}>
          {description}
        </Text>
        {!isMentor && studentInfo && (
          <>
            <Divider marginTop={12} marginBottom={12} />
            <Row>
              <Text>
                <Text font={fonts.bold} size={16}>
                  {lessonCompleteText(studentInfo)}
                </Text>{' '}
                <Text font={fonts.thin} size={16}>
                  lessons complete
                </Text>
                {'   '}
                <Text font={fonts.bold} size={16}>
                  {studentInfo.completePercent}%
                </Text>{' '}
                <Text font={fonts.thin} size={16}>
                  of subject complete
                </Text>
              </Text>
            </Row>
            <Box width="48px" position="absolute" top="32px" right="24px">
              <ProgressBar percentage={studentInfo.completePercent} />
            </Box>
          </>
        )}
      </CourseMapMainFolderBorder>
    </Box>
  );
};

export const CourseMapMainFolder: React.FC<CourseMapMainFolderProps> = (
  props: CourseMapMainFolderProps
) => {
  const isLandscape = useIsLandscape();
  const [isPressed, setIsPressed] = React.useState(false);

  return (
    <TouchableOpacity
      activeOpacity={1}
      onPressIn={() => {
        setIsPressed(true);
      }}
      onPressOut={() => {
        setIsPressed(false);
      }}
      onPress={props.onPress}
    >
      {isLandscape ? (
        <CourseMapMainFolderLandscape {...props} isPressed={isPressed} />
      ) : (
        <CourseMapMainFolderPortrait {...props} isPressed={isPressed} />
      )}
    </TouchableOpacity>
  );
};
