import React from 'react';
import { Box, Row, Text } from '../styled';
import { PaymentPlanCardContainer } from './styled';
import { Divider } from '../Divider';
import { Button } from '../Button';
import { colors, fonts } from '../../constants/theme';
import { shortYearDatestring } from 'sonora-util/dateUtils';
import { GradientBackground } from '../GradientBorder/GradientBackground';

export interface PaymentPlanCardProps {
  date: Date;
  selected: boolean;
  onSelect: () => void;
}

export const PaymentPlanInstallment: React.FC<PaymentPlanCardProps> = ({
  date,
  selected,
  onSelect,
}) => {
  const strDate = shortYearDatestring(date);

  return (
    <PaymentPlanCardContainer zIndex={selected ? 1 : 0}>
      <Box backgroundColor={colors.tanzanite} padding={6}>
        <Text color={colors.white} size={12} textAlign="center">
          MOST POPULAR
        </Text>
      </Box>
      <Box backgroundColor={colors.white} paddingX={40} paddingY={24}>
        <Text size={22} marginBottom={12} textAlign="center">
          5 installments
        </Text>
        <Row justifyContent="center" alignItems="flex-end" marginBottom={20}>
          <Text
            font={fonts.thin}
            color={colors.purpley}
            textAlign="center"
            size={34}
            marginRight={6}
          >
            $
          </Text>
          <Text
            color={colors.amethyst}
            size={42}
            marginRight={6}
            lineHeight="46px"
          >
            595
          </Text>
          <Text
            font={fonts.thin}
            color={colors.purpley}
            size={16}
            marginBottom={4}
          >
            x 5
          </Text>
        </Row>
        <Divider marginBottom={20} />
        <Text size={18} marginBottom={20} textAlign="center">
          Free 7-day trial
        </Text>
        <Text font={fonts.thin} textAlign="center" marginBottom={6}>
          then 5 payments of $595 in monthly installments*
        </Text>
        <Text font={fonts.thin} textAlign="center" marginBottom={12}>
          *starting {strDate}
        </Text>
        <Box alignItems="center">
          {selected ? (
            <Button type="Primary" text="Select" onPress={onSelect} />
          ) : (
            <Button type="Secondary" text="Select" onPress={onSelect} />
          )}
        </Box>
      </Box>
    </PaymentPlanCardContainer>
  );
};

export const PaymentPlanUpfront: React.FC<PaymentPlanCardProps> = ({
  date,
  selected,
  onSelect,
}) => {
  const strDate = shortYearDatestring(date);

  return (
    <PaymentPlanCardContainer zIndex={selected ? 1 : 0}>
      <Box backgroundColor={colors.tanzanite} padding={6} position="relative">
        <GradientBackground
          colors={[colors.tanzanite, colors.ruby]}
          start={{ x: 0, y: 1 }}
          end={{ x: 1, y: 1 }}
        />
        <Box zIndex={1}>
          <Text color={colors.white} size={12} textAlign="center">
            SAVE $1000
          </Text>
        </Box>
      </Box>
      <Box backgroundColor={colors.white} paddingX={40} paddingY={24} flex={1}>
        <Text size={22} marginBottom={12} textAlign="center">
          Upfront
        </Text>
        <Row justifyContent="center" alignItems="flex-end" marginBottom={20}>
          <Text
            font={fonts.thin}
            color={colors.purpley}
            textAlign="center"
            size={34}
            marginRight={6}
          >
            $
          </Text>
          <Text
            color={colors.amethyst}
            size={42}
            marginRight={6}
            lineHeight="46px"
          >
            1975
          </Text>
        </Row>
        <Divider marginBottom={20} />
        <Text size={18} marginBottom={20} textAlign="center">
          Free 7-day trial
        </Text>
        <Text font={fonts.thin} textAlign="center" marginBottom={6}>
          then $1975 paid once*
        </Text>
        <Text font={fonts.thin} textAlign="center" marginBottom={12}>
          *billed {strDate}
        </Text>
        <Box alignItems="center" flex={1} justifyContent="flex-end">
          {selected ? (
            <Button type="Primary" text="Select" onPress={onSelect} />
          ) : (
            <Button type="Secondary" text="Select" onPress={onSelect} />
          )}
        </Box>
      </Box>
    </PaymentPlanCardContainer>
  );
};
