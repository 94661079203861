import React, { useContext } from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { UserContext } from 'sonora-containers/UserProvider';
import {
  Box,
  colors,
  FillIcon,
  Row,
  Text,
  MembershipDetailsCard,
  TransactionHistory,
  ModalPresenterContext,
} from 'sonora-design-system';
import { PremiumSubscriptionDetailsCard } from 'sonora-design-system/components/Billing/PremiumSubscriptionDetailsCard';
import {
  useCancelStripeSubscriptionAtPeriodEndMutation,
  useCancelStripeSubscriptionNowMutation,
  useExtendTrialOnStripeSubscriptionScheduleMutation
} from 'sonora-graphql/types';
import { ITransactionHistory } from 'sonora-types';
import { distanceFromToday } from 'sonora-util/dateUtils';
import { handleMock } from 'sonora-util/helpers';
import { PaymentCancelModal } from './PaymentCancelModal';
import { PaymentEditModal, PaymentEditModalData } from './PaymentEditModal';
import { PremiumCancelModal } from './PremiumCancelModal';
import { PremiumPauseModal } from './PremiumPauseModal';
import { BillingDetailsProps } from './types';

export const BillingDetails: React.FC<BillingDetailsProps> = ({
  navigation,
  route,
}) => {
  const { type: cardType } = route.params;
  const { presentComponentAsModal } = useContext(ModalPresenterContext);
  const { payment } = useContext(UserContext);
  const today = new Date()
  const eightDays = 8 * 24 * 60 * 60 * 1000
  let showExtend = false
  if (payment) {
    showExtend = today.getTime() - payment?.stripeCreateDate.getTime() < eightDays
  } 
  const [
    cancelStripeSubscriptionNowMutation,
  ] = useCancelStripeSubscriptionNowMutation();
  const [
    cancelStripeSubscriptionAtPeriodEndMutation,
  ] = useCancelStripeSubscriptionAtPeriodEndMutation();
  const [
    extendTrialOnStripeSubscriptionScheduleMutation,
  ] = useExtendTrialOnStripeSubscriptionScheduleMutation();


  const handleCancelPremium = (subscriptionId: string) => {
    presentComponentAsModal(PremiumCancelModal, {
      width: 566,
      height: 551,
      onDismiss: (success, action) => {
        if (success === 'Success') {
          if (action === 'cancel') {
            cancelStripeSubscriptionAtPeriodEndMutation({
              variables: { subscriptionId },
              refetchQueries: ['CurrentUser'],
            }).then(({ data }) => {
              if (data?.cancelStripeSubscriptionAtPeriodEnd?.ok) {
                console.log('Premium Subscription Cancelled');
              }
            });
          }
        }
      },
    });
  };

  const handlePausePremium = () => {
    presentComponentAsModal(PremiumPauseModal, {
      width: 566,
      height: 551,
      onDismiss: (success, shouldPause) => {
        if (success === 'Success') {
          if (shouldPause) {
            console.log('Pause');
          } else {
            console.log('Cancel');
          }
        }
      },
    });
  };

  const handleEditTrial = () => {
    presentComponentAsModal(PaymentEditModal, {
      width: 572,
      height: 611,
      onDismiss: (success, data) => {
        if (success === 'Success') {
          const typedData = data as PaymentEditModalData;
          console.log('Plan updated: ', typedData.selectedPlan);
        }
      },
    });
  };

  const handleCancelTrial = (
    subscriptionId: string,
    subscriptionScheduleId: string,
    priceId: string,
    isInstallments: boolean, 
  ) => {
    presentComponentAsModal(PaymentCancelModal, {
      width: 499,
      height: 433,
      extraData: {showExtend},
      onDismiss: (success, action) => {
        if (success === 'Success') {
          if (action === 'cancel') {
            cancelStripeSubscriptionNowMutation({
              variables: { subscriptionId },
              refetchQueries: ['CurrentUser'],
            }).then(({ data }) => {
              if (data?.cancelStripeSubscriptionNow?.ok) {
                console.log('Premium Subscription Cancelled');
              }
            });
          }
        }
        if (action === 'extend') {
          console.log("iteration count", isInstallments)
          extendTrialOnStripeSubscriptionScheduleMutation({
            variables: {
              subscriptionScheduleId,
              additionalTrialDays: 7,
              priceId: priceId,
              iterationCount: isInstallments ? 5 : 1
            },
            refetchQueries: ['CurrentUser'],
          }).then(({ data }) => {
            if (data?.extendTrialOnStripeSubscriptionSchedule?.ok) {
              console.log('Trial Extended');
            }
          });
        }
      },
    });
  };

  const goBack = () => {
    navigation.goBack();
  };

  return (
    <Box height="100%">
      <Box
        height="72px"
        backgroundColor={colors.grayHover}
        justifyContent="center"
      >
        <Box maxWidth={480} width="100%" marginX="auto">
          <TouchableOpacity onPress={goBack}>
            <Row>
              <FillIcon name="pageLeft" />
              <Text marginLeft={8}>Back to Overview</Text>
            </Row>
          </TouchableOpacity>
        </Box>
      </Box>
      <Box flex={1} overflow="auto">
        <Box
          maxWidth={504}
          paddingY={36}
          paddingX={12}
          width="100%"
          marginX="auto"
        >
          {cardType === 'premium' ? (
            <PremiumSubscriptionDetailsCard
              details={payment?.coreSubscription}
              onCancel={() =>
                handleCancelPremium(payment?.fellowshipSubscription?.id || '')
              }
              onEditBillingCycle={handleMock('Premium Edit Billing Cycle')}
              onPause={handlePausePremium}
            />
          ) : (
            <MembershipDetailsCard
              details={payment?.coreSubscription}
              isTrial={payment?.isInTrial}
              trialDaysRemaining={Math.abs(distanceFromToday(
                payment?.trialEndDate || new Date()
              ))+1}
              onBuyTrial={handleMock('Buy Trial')}
              onEdit={handleEditTrial}
              onCancel={() =>{
                handleCancelTrial(
                  payment?.coreSubscription?.id || '',
                  payment?.coreSubscription?.subScheduleId || '',
                  payment?.coreSubscription?.priceId || '',
                  payment?.coreSubscription?.type == 'installments'
                )
              }}
            />
          )}

          <Text size={18} marginTop={12} marginBottom={24}>
            Transaction history
          </Text>
          {payment?.history.map((transaction: ITransactionHistory) => (
            <TransactionHistory key={transaction.id} details={transaction} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
