import React, { FC, useState } from 'react';

export interface DateProviderProps {}

interface DateContextState {
  todayDate: Date;
  setDate: (d: Date) => void;
}

export const DateContext = React.createContext<DateContextState>(
  {} as DateContextState
);

export const DateProvider: FC<DateProviderProps> = (props) => {
  const [today, setToday] = useState(new Date());

  return (
    <DateContext.Provider
      value={{
        todayDate: today,
        setDate: setToday,
      }}
    >
      {props.children}
    </DateContext.Provider>
  );
};
