import React from 'react';
import styled from 'styled-components/native';
import { LinearGradient } from 'expo-linear-gradient';

import { colors, fonts, theme } from '../../../constants/theme';

import { GiftedMessage, AvatarProps } from '../types';

const AvatarWrapper = styled.View`
  background-color: ${colors.amethyst};
  width: 30px;
  height: 30px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 3px;
`;

const AvatarText = styled.Text`
  font-family: ${fonts.bold}
  font-size: 10px;
  color: ${colors.white};
  text-transform: uppercase;
  z-index: 1;
`;

const GradientBackground = styled(LinearGradient)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 15px;
  z-index: 0;
`;

export const RenderAvatar: React.FC<Readonly<AvatarProps<GiftedMessage>>> = ({
  currentMessage,
  position,
}) => {
  const words = ((currentMessage && currentMessage.user.name) || '').split(' ');
  const initials = words[0][0] + words[words.length - 1][0];

  return (
    <AvatarWrapper>
      {position === 'right' ? (
        <GradientBackground {...theme.chat.avatarGradient} />
      ) : null}
      <AvatarText>{initials}</AvatarText>
    </AvatarWrapper>
  );
};
