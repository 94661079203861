// author: @fullstackdevs14
import { TaskListProps } from './types';
export const fiveFormsTasks: TaskListProps = {
  completedTasks: [
    {
      practiceNodeId: '2',
      taskType: 'watch',
      title: 'Health & Wellness',
      currStars: 1,
      maxStars: 1,
    },
    {
      practiceNodeId: '3',
      taskType: 'watch',
      title: "The 3 T's",
      currStars: 5,
      maxStars: 5,
    },
    {
      practiceNodeId: '4',
      taskType: 'watch',
      title: 'Right Hand Techniques',
      currStars: 1,
      maxStars: 1,
    },
    {
      practiceNodeId: '5',
      taskType: 'watch',
      title: 'How to Interact with Your Mentor',
      currStars: 1,
      maxStars: 1,
    },
  ],
  currentTaskId: '22',
  totalReviewCount: 11,
  currentTasks: [
    {
      practiceNodeId: '21',
      taskType: 'practice',
      title: '6th String Front Form - Chromatically Up the Neck',
      currStars: 1,
      maxStars: 5,
    },
    {
      practiceNodeId: '22',
      taskType: 'practice',
      title: '6th String Front Form - Key of C',
      currStars: 0,
      maxStars: 5,
    },
    {
      practiceNodeId: '32',
      taskType: 'practice',
      title: 'Filling the notes in Position: 6th String Front Form',
      currStars: 2,
      maxStars: 5,
      headerType: 'Optional',
      onSkip: () => console.log('skip'),
    },
    {
      practiceNodeId: '17',
      taskType: 'practice',
      title: '5th String Front Form',
      currStars: 3,
      maxStars: 5,
      headerType: 'Review',
      lastPracticedString: '9-15-20',
    },
    {
      practiceNodeId: '18',
      taskType: 'practice',
      title: '6th String Front Form',
      currStars: 4,
      maxStars: 5,
      headerType: 'Review',
      lastPracticedString: '9-15-20',
    },
  ],
  lockedTasks: [
    {
      practiceNodeId: '23',
      taskType: 'practice',
      title: '5th String Front Form - Chromaticaly Up The Neck',
      currStars: 0,
      maxStars: 5,
    },
    {
      practiceNodeId: '24',
      taskType: 'practice',
      title: '5th String Front Form - Key of C',
      currStars: 0,
      maxStars: 5,
    },
    {
      practiceNodeId: '33',
      taskType: 'practice',
      title: 'Filling the notes in Position: 5th String Front Form',
      currStars: 0,
      maxStars: 5,
    },
    {
      practiceNodeId: '34',
      taskType: 'practice',
      title: 'Filling the notes in Position: 5th String Front Form',
      currStars: 0,
      maxStars: 5,
    },
  ],
};
