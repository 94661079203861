import React, { FC, useContext } from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import { NodeId } from 'sonora-types';

import CourseMapLock from 'sonora-design-system/assets/images/CourseMapLock.png';
import {
  colors,
  PrimaryButton,
  ModalPresenterContext,
  fonts,
} from 'sonora-design-system';
import { ProgressContext, StudentContext } from 'sonora-containers/index';

export interface LockModalProps {}

export const LockModal: FC<LockModalProps> = () => {
  const { dismissModal, extraData } = useContext(ModalPresenterContext);
  const { completedNodes } = useContext(ProgressContext);
  const { curriculumMap } = useContext(StudentContext);
  const node = curriculumMap[extraData.nodeId];

  if (!node)
    throw new Error(
      `tried to access node ${extraData.nodeId} not in curriculum`
    );

  if (node.type === 'Folder')
    throw new Error(
      `Node ${extraData.nodeId} is a folder and cannot be accessed here`
    );

  if (!node.requiredNodeIds)
    throw new Error(
      `Node ${node.id} has no requiredNodes, so lockmodal is impossible `
    );

  const isNotCompleted = (id: NodeId) => !completedNodes.includes(id);
  const firstLockedNodeIndex = node.requiredNodeIds.findIndex(isNotCompleted);
  if (firstLockedNodeIndex < 0)
    throw new Error(`No incomplete requirements for node ${node.id}`);
  const firstDependency = node.requiredNodeIds[firstLockedNodeIndex];
  const dependencyName = curriculumMap[firstDependency].name;

  return (
    <View style={styles.screen}>
      <View style={styles.screenInner}>
        <Text style={styles.headline}>{node.name} is locked</Text>
        <Image source={CourseMapLock} style={styles.image} />
        <View style={styles.textBlock}>
          <Text style={styles.text}>
            To unlock, make progress on &nbsp;
            <Text style={styles.bold}>"{dependencyName}"</Text>
            &nbsp;
          </Text>
        </View>
        <PrimaryButton text="Dismiss" onPress={() => dismissModal('Cancel')} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  screenInner: {
    maxWidth: 366,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 183,
    height: 192,
    marginBottom: 26,
  },
  headline: {
    fontFamily: fonts.headline,
    fontSize: 32,
    lineHeight: 36,
    color: colors.tanzanite,
    textAlign: 'center',
    marginBottom: 33,
  },
  bold: {
    fontWeight: '600',
  },
  textBlock: {
    marginBottom: 24,
  },
  text: {
    fontFamily: fonts.thin,
    color: colors.tanzanite,
    fontSize: 20,
    lineHeight: 26,
    textAlign: 'center',
  },
});
