import React, { useEffect, useContext, useState } from 'react';
import { ActiveModificationsMap } from 'sonora-design-system/components/ModificationDetailView/types';
import { useFinalizeSubmissionMutation } from './useFinalizeSubmissionMutation';
import {
  useCreateMessageAttachmentOnExistingThread,
  MessageAttachmentCreationResult,
} from './useCreateMessageAttachmentOnExistingThread';
import { useCreateMessageOnExistingThread } from './useCreateMessageOnExistingThread';
import { modificationsToMessage } from 'sonora-util/curriculaUtil';
import { ModificationsProviderContext } from '../router/screens/Modifications/ModificationsProvider';
import { StudentContext } from 'sonora-containers/index';
import { usePublishMessageMutation } from 'sonora-graphql/types';

interface SharedLogicArgs {
  messageThreadId: number;
  exerciseId?: number;
  onFinish: () => void;
  isZiggeo: boolean;
}

export const useSharedVideoRecorderLogic = ({
  messageThreadId,
  exerciseId,
  onFinish,
  isZiggeo,
}: SharedLogicArgs) => {
  const [modificationMessageId, setModificationMessageId] = useState<
    number | null
  >(null);
  const { curriculum } = useContext(StudentContext);
  const { activeModificationsMap } = useContext(ModificationsProviderContext);

  const [publishMessageMutation] = usePublishMessageMutation();
  const publishMessage = (messageId: number) =>
    publishMessageMutation({
      variables: {
        messageId,
      },
      // refetchQueries: ['ChatThread'],
    });

  /**
   * Result of mutations creating new message & attachment
   */
  const [
    creationResult,
    setCreationResult,
  ] = React.useState<MessageAttachmentCreationResult | null>(null);

  /**
   * To finalize video submission, we must publish the message and
   * (for non-ziggeo videos) mark the attachment complete.
   */
  const finalizeSubmissionMutation = useFinalizeSubmissionMutation({
    options: { isZiggeoSubmission: isZiggeo },
  });

  /**
   * Starts a new message on the thread, creates an attachment on the
   * new message - pass an onCompleted handler to `startNewMessageAttachment`
   */
  const startNewMessageAttachment = useCreateMessageAttachmentOnExistingThread({
    messageThreadId,
    exerciseId,
  });

  const startNewMessage = useCreateMessageOnExistingThread({
    messageThreadId,
  });

  const createNewMessageWithModifications = (
    modifications: ActiveModificationsMap
  ) => {
    const message = modificationsToMessage(modifications, curriculum);
    startNewMessage({
      onCompleted: (result) => {
        setModificationMessageId(result.messageId);
      },
      contents: message,
    });
  };

  /**
   * On component mount, run startNewMessageAttachment mutation
   */
  useEffect(() => {
    // Begin new message, onComplete will set creationResult
    // console.log('creating new message attachment');
    startNewMessageAttachment({
      onCompleted: (result) => {
        setCreationResult(result);
        if (
          activeModificationsMap &&
          Object.keys(activeModificationsMap).length > 0
        ) {
          createNewMessageWithModifications(activeModificationsMap);
        }
      },
    });
  }, []);

  /** Runs the finalization mutation, then navigates away */
  const finalizeSubmissionThenNavigate = () => {
    if (
      !creationResult ||
      !creationResult.attachment ||
      !creationResult.attachment.id ||
      !creationResult.messageId
    )
      throw new Error(
        'attempt to publish message attachment without creationResult'
      );

    finalizeSubmissionMutation({
      messageId: creationResult.messageId,
      attachmentId: parseInt(creationResult.attachment.id),
    }).then(() => {
      if (modificationMessageId != null) {
        publishMessage(modificationMessageId).then(onFinish);
      } else {
        onFinish();
      }
    });
  };

  return { creationResult, finalizeSubmissionThenNavigate };
};
