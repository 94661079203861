import React from 'react';
import styled from 'styled-components/native';

import { InlineVideoPlayer } from '../InlineVideoPlayer';
import { FillIcon } from '../Icon';
import { Box, Row, Text } from '../styled';
import { Scrollable } from '../Scrollable';
import { colors, fonts } from '../../constants/theme';
import { useIsLandscape } from '../../util';

const HorizontalLine = styled.View`
  background-color: ${colors.purpley};
  position: absolute;
  left: 0;
  right: 0;
  top: 14px;
  height: 1px;
`;

interface ModificationInfo {
  categoryName: string;
  modificationName: string;
}

interface PracticeModificationsProps {
  videoUrl: string | undefined;
  posterImage: string | undefined;
  modifications: ModificationInfo[];
  showModifications?: boolean;
}

export const PracticeModifications: React.FC<PracticeModificationsProps> = ({
  videoUrl,
  posterImage,
  modifications,
  showModifications,
}) => {
  const isLandscape = useIsLandscape();

  return (
    <Scrollable>
      {isLandscape && !!videoUrl && !!posterImage ? (
        <InlineVideoPlayer source={videoUrl} posterImage={posterImage} />
      ) : null}
      {showModifications ? (
        <Box paddingY={36} paddingX={24}>
          <Row position="relative" marginBottom={24}>
            <HorizontalLine />
            <Row
              backgroundColor={colors.agate}
              paddingRight={12}
              position="relative"
              flex="none"
            >
              <FillIcon name="modifications" />
              <Text size={18} marginLeft={12}>
                Active Modifications
              </Text>
            </Row>
          </Row>
          <Box paddingLeft={36}>
            {modifications.map((modification, i) => (
              <Text key={i} marginBottom={6}>
                • {modification.categoryName}:{' '}
                <Text font={fonts.thin}>{modification.modificationName}</Text>
              </Text>
            ))}
          </Box>
        </Box>
      ) : null}
    </Scrollable>
  );
};
