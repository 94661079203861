import React from 'react';
import { storiesOf } from '@storybook/react-native';

import { Box } from '../styled';
import { colors } from '../../constants/theme';
import { LayoutProvider } from '../../util';

import { LoginForm } from './LoginForm';
import { ResetPasswordForm } from './ResetPasswordForm';
import { ResetPasswordSuccess } from './ResetPasswordSuccess';
import { KeyValueStore } from './types';

const authFormsStories = storiesOf('AuthForms', module);

const LoginFormStory = () => (
  <LayoutProvider>
    <Box backgroundColor={colors.agate} padding={24}>
      <Box maxWidth={700}>
        <LoginForm
          onSubmit={(formData: KeyValueStore) => {
            console.log(formData);
          }}
          onPressForgot={() => {
            console.log('Go to forgot password');
          }}
        />
      </Box>
    </Box>
  </LayoutProvider>
);

const ResetPasswordFormStory = () => (
  <LayoutProvider>
    <ResetPasswordForm error="Network Error" onSubmit={console.log} />
  </LayoutProvider>
);

const ResetPasswordSuccessStory = () => (
  <LayoutProvider>
    <ResetPasswordSuccess onBackToLogin={() => console.log('Go to login')} />
  </LayoutProvider>
);

authFormsStories.add('Login', LoginFormStory);
authFormsStories.add('ResetPassword', ResetPasswordFormStory);
authFormsStories.add('ResetPasswordSuccess', ResetPasswordSuccessStory);
