// author: @fullstackdevs14
import React from 'react';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import {
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  format,
  isThisWeek,
} from 'date-fns';

import { isDevelopment } from '../../../src/util/environment';
import { colors, theme } from '../../constants/theme';
import { convertHexToRGBA } from '../../util/colors';
import { Text, Row, Box } from '../styled';
import { FillIcon } from '../Icon';
import { CalendarViewMode } from './types';

const StreakContainer = styled.View`
  flex-direction: row;
  align-items: center;
  border-radius: ${theme.common.borderRadius};
  padding: 4px 10px;
  background-color: ${convertHexToRGBA(colors.purpley, 20)};
`;

interface Props {
  date: Date;
  streakDayCount: number;
  view?: CalendarViewMode;
  onPrevious?: () => void;
  onNext?: () => void;
}

export const CalendarHeader: React.FC<Props> = ({
  date,
  streakDayCount,
  view,
  onPrevious,
  onNext,
}) => {
  const start = startOfWeek(date);
  const end = endOfWeek(date);
  const shortWeekdays = eachDayOfInterval({
    start,
    end,
  }).map((d) => format(d, 'eee'));

  const disabledNext = isDevelopment()
    ? false
    : view === 'month'
    ? date.getMonth() >= new Date().getMonth()
    : start.getTime() >= startOfWeek(new Date()).getTime();

  const title =
    view === 'month'
      ? format(date, 'MMMM Y')
      : isThisWeek(date)
      ? 'This Week'
      : `${format(start, 'MMM d')} - ${format(end, 'MMM d')}`;

  return (
    <Box>
      <Row justifyContent="space-between">
        <Row>
          <TouchableOpacity onPress={onPrevious}>
            <FillIcon name="pageLeft" />
          </TouchableOpacity>
          <Text size={16} marginX={5} marginBottom={2}>
            {title}
          </Text>
          <TouchableOpacity onPress={onNext} disabled={disabledNext}>
            <FillIcon
              name="pageRight"
              color={disabledNext ? colors.purpley : colors.tanzanite}
            />
          </TouchableOpacity>
        </Row>
        {streakDayCount > 0 && (
          <StreakContainer>
            <FillIcon name="streak" color={colors.alertRed} />
            <Box marginLeft={10}>
              <Text>{streakDayCount}-day streak!</Text>
            </Box>
          </StreakContainer>
        )}
      </Row>
      <Row height="42px" justifyContent="space-between">
        {shortWeekdays.map((d) => (
          <Box key={d} width="36px">
            <Text
              color={colors.purpley}
              textTransform="uppercase"
              textAlign="center"
            >
              {d}
            </Text>
          </Box>
        ))}
      </Row>
    </Box>
  );
};
