import { LinearGradient } from 'expo-linear-gradient';
import styled from 'styled-components/native';
import { GradientBackgroundProps } from './types';

export const GradientBackground = styled(LinearGradient)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  ${(props: GradientBackgroundProps) =>
    props.borderRadius ? `border-radius: ${props.borderRadius}px;` : ''}
  z-index: 0;
`;
