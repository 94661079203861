import React, { useContext, useState } from 'react';

import {
  CourseMapHeader,
  CourseMapMainFolder,
  Box,
  colors,
  Scrollable,
} from 'sonora-design-system';

import { MentorContext } from 'sonora-containers/index';
import { RootNavScreen } from '../screens';
import { useTrackEventOnMount } from 'sonora-hooks/useTrackEventOnMount';

export const MentorCourseMapOverview: RootNavScreen<'CourseMapOverview'> = ({
  route,
  navigation,
}) => {
  const { curriculum } = useContext(MentorContext);
  useTrackEventOnMount('mount-course-map-overview');
  const [hoveredFolder, setHoveredFolder] = useState<string | undefined>();

  return (
    <Box backgroundColor={colors.white} flex={1} alignItems="center">
      <CourseMapHeader
        title="Guitar Mastery Intensive"
        isMentor={true}
        hasBorder={true}
        titleColor={colors.amethyst}
        isMainHeader
      />
      <Box width="100%" flex={1}>
        <Scrollable padding={24} alignItems="center">
          <Box maxWidth={548} width="100%">
            {curriculum.contents.map((folder, i) => (
              <CourseMapMainFolder
                isMentor={true}
                key={folder.name}
                title={folder.name}
                isHovered={hoveredFolder === folder.name}
                setHoveredFolder={setHoveredFolder}
                description={folder.description}
                backgroundColor={colors.white}
                onPress={() => {
                  navigation.navigate('CourseMapDetails', {
                    folderId: folder.id,
                  });
                }}
              />
            ))}
          </Box>
        </Scrollable>
      </Box>
    </Box>
  );
};
