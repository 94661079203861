import React from 'react';
import { storiesOf } from '@storybook/react-native';
import { Button } from '../Button';
import { Tooltip } from './Tooltip';
import { Box } from '../styled';

const tooltipStories = storiesOf('Tooltip', module);

const TooltipStory = () => {
  return (
    <Box padding={30} alignItems="center">
      <Box width="200px" marginY={50}>
        <Tooltip width={280}>
          <Button text="Primary CTA" />
        </Tooltip>
      </Box>
      <Box width="200px" marginY={50}>
        <Tooltip placement="right" width={280}>
          <Button text="Primary CTA" />
        </Tooltip>
      </Box>
      <Box width="200px" marginY={50}>
        <Tooltip placement="left" width={280}>
          <Button text="Primary CTA" />
        </Tooltip>
      </Box>
      <Box width="200px" marginY={50}>
        <Tooltip placement="top" width={280}>
          <Button text="Primary CTA" />
        </Tooltip>
      </Box>
    </Box>
  );
};

tooltipStories.add('default', () => <TooltipStory />);
