import React, { useContext } from 'react';

import { RootNavScreen } from '../screens';
import { UserContext } from 'sonora-containers/index';
import { createStackNavigator } from '@react-navigation/stack';

import { StudentOverviewScreen } from './StudentOverviewScreen';
import { MentorStudents } from './MentorStudents';
import { StudentChat } from './StudentChat';
import { L1FrameLeftHOC } from '../../frame/L1FrameLeft';

const Stack = createStackNavigator();

interface OverviewScreen {
  name: string;
  component: RootNavScreen<any>;
}

const MentorScreens: OverviewScreen[] = [
  {
    name: 'MentorStudents',
    component: L1FrameLeftHOC<any>(MentorStudents),
  },
  {
    name: 'StudentChat',
    component: L1FrameLeftHOC<any>(StudentChat),
  },
];

export const Overview: RootNavScreen<'Overview'> = (props) => {
  const { userType } = useContext(UserContext);

  if (userType === 'student') {
    return <StudentOverviewScreen />;
  }

  return (
    <Stack.Navigator initialRouteName="MentorStudents">
      {MentorScreens.map((screen: OverviewScreen) => (
        <Stack.Screen
          key={screen.name}
          name={screen.name}
          component={screen.component}
        />
      ))}
    </Stack.Navigator>
  );
};
