import React, { FC } from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { FillIcon, colors } from 'sonora-design-system';
import wordmarkSrc from 'sonora-design-system/assets/images/Wordmark.png';

export interface L2HeaderProps {}

const L2Header: FC<L2HeaderProps> = (props) => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();

  return (
    <View>
      <View style={[styles.header, { paddingTop: insets.top + 16 }]}>
        <TouchableOpacity
          style={styles.row}
          onPress={() => navigation.goBack()}
        >
          <View style={styles.icon}>
            <FillIcon name="x" color={colors.white} />
          </View>
          <Text style={styles.exitLabel}>Exit</Text>
        </TouchableOpacity>
        <Image source={wordmarkSrc} style={styles.logo} />
        <View style={{ width: 48 }} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    padding: 16,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.tourmaline,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: 5,
  },
  exitLabel: {
    color: colors.white,
    textTransform: 'uppercase',
  },
  logo: {
    width: 120,
    height: 20,
  },
});

export default L2Header;
