import {
  MessageAttachmentType,
  useCreateMessageAttachmentMutation,
  useStartMessageMutation,
} from 'sonora-graphql/types';
import { createErrorCatcher } from 'sonora-services/analytics';

export interface MessageAttachmentCreationResult {
  messageId: number;
  attachment: Pick<MessageAttachmentType, 'id' | 'uploadData'>;
}

type StartNewMessageMutationFunction = (_: {
  onCompleted: (result: MessageAttachmentCreationResult) => void;
}) => void;

interface CreateNewMessageAttachmentParams {
  messageThreadId: number;
  exerciseId?: number;
}

type UseCreateMessageAttachmentFunction = (
  _: CreateNewMessageAttachmentParams
) => StartNewMessageMutationFunction;

/**
 *
 * 1. create message thread (or use student/mentor main thread)
 * 2. startMessage (or more realistically, startMessage), using thread id
 * 3. createMessageAttachment, using messageId
 *  (at this point ziggeo requires a messageAttachmentId to render)
 * 4. completeMessageAttachment
 * 5. publishMessage (or completeMessage)
 */

export const useCreateMessageAttachmentOnExistingThread: UseCreateMessageAttachmentFunction = ({
  messageThreadId,
  exerciseId,
}) => {
  const [startMessage] = useStartMessageMutation({
    onError: createErrorCatcher('start message'),
  });

  const [createMessageAttachment] = useCreateMessageAttachmentMutation({
    onError: createErrorCatcher('create attachment'),
  });

  return ({ onCompleted }) =>
    startMessage({
      variables: {
        messageThreadId,
        exerciseId,
      },
    }).then((result) => {
      // Got the message's id, now create an associated attachment
      if (
        !result ||
        !result.data ||
        !result.data.startMessage ||
        !result.data.startMessage.message
      ) {
        throw new Error('Expected well-formed new message');
      }
      const messageId = parseInt(result.data.startMessage.message.id);
      createMessageAttachment({
        variables: {
          messageId,
        },
      }).then((result) => {
        // Attachment was created and we have its info, return all info to consumer
        if (
          !result ||
          !result.data ||
          !result.data.createMessageAttachment ||
          !result.data.createMessageAttachment.messageAttachment
        ) {
          throw new Error('Expected well-formed new message');
        }
        const attachment =
          result.data.createMessageAttachment.messageAttachment;
        onCompleted({
          attachment,
          messageId,
        });
      });
    });
};
