import React, { useCallback } from 'react';
import { View, AsyncStorage } from 'react-native';
import { colors } from 'sonora-design-system';
import { useFocusEffect } from '@react-navigation/native';

import { RootNavScreen } from '../screens/screens';
import { AUTH_TOKEN_KEY } from 'sonora-util/environment';

export interface AuthLoadingScreenProps {}

export const AuthLoadingScreen: RootNavScreen<'AuthLoadingScreen'> = ({
  navigation,
}) => {
  const checkLoggedIn = async () => {
    AsyncStorage.getItem(AUTH_TOKEN_KEY).then((authToken) => {
      if (authToken) {
        navigation.navigate('LoggedIn');
      } else {
        navigation.navigate('LoggedOut');
      }
    });
  };

  useFocusEffect(
    useCallback(() => {
      checkLoggedIn();
    }, [])
  );

  return <View style={{ flex: 1, backgroundColor: colors.opal }} />;
};
