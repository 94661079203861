import React, { FC, useContext } from 'react';
import { Linking } from 'expo';
import { View, StyleSheet, Text } from 'react-native';
import { LayoutProviderContext, colors } from 'sonora-design-system';
import L1SidebarMenuItem from '../sidebar/L1SidebarMenuItem';
import { appVersionString } from 'sonora-util/environment';
import { useNavigation } from '@react-navigation/native';
import { UserContext } from 'sonora-containers/UserProvider';

import { useSignOut } from 'sonora-graphql/signInHooks';

export interface L1HamburgerMenuProps {
  onClose: () => void;
  headerHeight: number;
}

const L1HamburgerMenu: FC<L1HamburgerMenuProps> = (props) => {
  const { user } = useContext(UserContext);
  const { screenWidth, screenHeight } = useContext(LayoutProviderContext);
  const navigation = useNavigation();
  const signOut = useSignOut();

  const handleSignOut = () => {
    signOut().then(() => navigation.navigate('AuthLoading'));
  };

  const handleContactUs = () => {
    Linking.openURL(`mailto:contact@guitarmasteryintensive.com`);
  };

  const gotoReferral = () => {
    Linking.openURL('https://api.guitarmasteryintensive.com/referral_redirect/' + user.email);
  };

  return (
    <View
      style={[
        styles.hamburgerMenu,
        { top: props.headerHeight, width: screenWidth, height: screenHeight },
      ]}
    >
      <View style={styles.hamburgerMenuContent}>
        {/* <L1SidebarMenuItem
          iconName="settings"
          label="Settings"
          handlePress={() => undefined}
          focused={true}
        /> */}
        { user.userType == "student" &&
          <L1SidebarMenuItem
            iconName="send"
            afterIconName="launch"
            isAfterIconFilled={true}
            label="Refer a Friend"
            handlePress={gotoReferral}
          />
        }
        <L1SidebarMenuItem
          iconName="logOut"
          label="Sign out"
          handlePress={handleSignOut}
          focused={true}
        />
        <L1SidebarMenuItem
          iconName="support"
          label="Contact Us"
          handlePress={handleContactUs}
          focused={true}
        />
        {/* <TouchableOpacity onPress={() => undefined}>
          <Text style={styles.link}>Privacy Policy</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => undefined}>
          <Text style={styles.link}>Terms & Conditions</Text>
        </TouchableOpacity> */}
        <Text style={styles.versionNumber}>{appVersionString()}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  hamburgerMenu: {
    backgroundColor: colors.opal,
    position: 'absolute',
    left: 0,
  },
  hamburgerMenuContent: {
    borderColor: colors.borderline,
    borderTopWidth: 1,
    marginHorizontal: 30,
    paddingVertical: 24,
  },
  link: {
    textTransform: 'uppercase',
    color: colors.tanzanite,
    paddingVertical: 15,
    paddingHorizontal: 48,
    textDecorationLine: 'underline',
  },
  versionNumber: {
    color: colors.tanzanite,
    paddingVertical: 30,
    paddingHorizontal: 48,
  },
});

export default L1HamburgerMenu;
