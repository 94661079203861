import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useIsFocused } from '@react-navigation/native';
import { Linking } from 'react-native';
import { ChatContext, UserContext, StudentContext } from 'sonora-containers/index';
import { RootNavScreen } from '../screens';
import { ChatThreadWrapper } from 'sonora-components/Chat/ChatThreadWrapper';
import {
  Box,
  Row,
  Loading,
  colors,
  PracticePostThreadHeader,
  PracticeModifications,
  useIsLandscape,
  MentorshipExpired,
} from 'sonora-design-system';
import {
  shortYearDatestring,
  timeStringNoSeconds,
} from 'sonora-util/dateUtils';
import { earliestMessageFirst } from 'sonora-util/chat';

import { ChatMessageType } from 'sonora-types';
import {
  hashChatMessage,
  hashChatThread,
} from 'sonora-util/icanhashcheezeburger';
import { useTrackEventOnMount } from 'sonora-hooks/useTrackEventOnMount';

export interface PracticePostThreadProps {}

const submissionDateString = (message: ChatMessageType | undefined) => {
  if (!message) return '';
  const submissionDate = message.createdAt;
  const dateString = shortYearDatestring(submissionDate);
  const timeString = timeStringNoSeconds(submissionDate);
  return `${dateString} - ${timeString}`;
};

export const PracticePostThread: RootNavScreen<'PracticePostThread'> = ({
  navigation,
  route,
}) => {
  const isLandscape = useIsLandscape();
  const isFocused = useIsFocused();
  const threadId = route.params.id;
  useTrackEventOnMount('mount-practice-post-thread', { threadId });
  const { payment, userType } = useContext(UserContext);
  const { relationshipStatus } = useContext(StudentContext);

  const relationshipExpired = relationshipStatus != "ActiveMentor" 

  const hasFellowshipAccess = (!payment && !relationshipExpired) || (payment && !payment.hasFellowshipAccess && !relationshipExpired) || payment?.hasFellowshipAccess || payment?.isInTrial;

  const [firstMessage, setFirstMessage] = useState<ChatMessageType | undefined>(
    undefined
  );

  const {
    currentThreadId,
    setCurrentThreadId,
    chatUser,
    messageThread,
    onSend,
    loading,
  } = useContext(ChatContext);

  useEffect(() => {
    if (isFocused && currentThreadId !== threadId) {
      setCurrentThreadId(threadId);
    }
  }, [threadId, isFocused]);

  /**
   * Keep firstMessage updated based on chat thread, but only when it changes
   * (use hashing function to prevent unnecessary state change/re-renders)
   */
  const threadHash = messageThread ? hashChatThread(messageThread) : null;
  useEffect(() => {
    if (messageThread && messageThread.messages) {
      const sortedMessages = messageThread.messages.sort(earliestMessageFirst);
      setFirstMessage(sortedMessages[0]);
    }
  }, [threadHash]);

  // Because messageThread might not be defined yet
  const threadSubject = (messageThread && messageThread.subject) || '';
  const goBack = navigation.goBack;

  // if not ready to display, we return empty components
  const readyToDisplay = !loading && !!messageThread.messages;

  // use this instead of firstMessage in the deps array for useMemo,
  // since useMemo will treat equal objects as different due to shallow compare
  const firstMessageHash = hashChatMessage(firstMessage);

  /**
   * A memoized version of PracticePostThreadHeader that only
   * changes when the actual props change
   */
  const MemoizedHeader = useMemo(() => {
    if (!readyToDisplay) return () => null;
    const dateString = submissionDateString(firstMessage);

    return () => (
      <PracticePostThreadHeader
        onNavigate={
          messageThread.exerciseId
            ? () => console.log('ID: ', messageThread.exerciseId)
            : undefined
        }
        postTitle={threadSubject}
        submissionDateString={dateString}
        onExit={goBack}
      />
    );
  }, [readyToDisplay, firstMessageHash, threadSubject, goBack]);

  /**
   * A memoized version of Modifications pane that only changes
   * when actual data changes
   */
  const MemoizedModifications = useMemo(() => {
    if (!readyToDisplay || !firstMessage || !firstMessage.attachment)
      return () => null;

    return () => {
      const attachmentUri =
        firstMessage.attachment && firstMessage.attachment.s3Url;
      const attachmentThumbnail =
        firstMessage.attachment && firstMessage.attachment.s3ThumbnailUrl;

      return (
        <PracticeModifications
          modifications={[]}
          posterImage={attachmentThumbnail}
          videoUrl={attachmentUri}
          showModifications={false}
        />
      );
    };
  }, [readyToDisplay, firstMessageHash]);

  if (!isFocused) return null;
  if (loading) return <Loading />;

  if (!messageThread.messages)
    throw new Error(
      'got messagethread without messages, should have at least one'
    );

  return (
    <Box flex={1}>
      <MemoizedHeader />
      <Row alignItems="stretch" flex={1}>
        {isLandscape ? (
          <Box flex={1} backgroundColor={colors.grayHover}>
            <MemoizedModifications />
          </Box>
        ) : null}
        <Box backgroundColor={colors.white} flex={1}>
          <ChatThreadWrapper
            chatUser={chatUser}
            messageThread={messageThread}
            onSend={onSend}
            isMessagingDisabled={!hasFellowshipAccess && userType != 'mentor' }
            threadId={currentThreadId}
          />
          
        </Box>
      </Row>
      {!hasFellowshipAccess && userType != 'mentor' && (
          <Box position="absolute" bottom="0px" left="0px" width="100%">
            <MentorshipExpired
              onSchedule={() =>
                Linking.openURL(
                  'https://calendly.com/guitar-mastery-intensive/skill-assessment'
                )
              }
            />
          </Box>
        )}
    </Box>
  );
};
