import React, { FC, createContext, useState, useEffect, useMemo } from 'react';

import { handleApolloError, handleError } from 'sonora-services/analytics';
import { ChatThreadType, ChatUserRole, ChatUserType } from 'sonora-types';
import {
  CarouselThreadData,
  GiftedMessage,
} from 'sonora-design-system/components/Chat/types';
import { createOnSend, dbThreadToChatThread } from 'sonora-util/chat';
import { useForceRerender } from 'sonora-hooks/useForceRerender';
import {
  ChatActivityQuery,
  MessageWithAttachmentsFragment,
  useChatActivityQuery,
  useChatAllThreadsQuery,
  useChatThreadQuery,
  useCreateMessageMutation,
} from 'sonora-graphql/types';
import { useReadMessages } from 'sonora-hooks/useReadMessages';
import { hashMessageThread } from 'sonora-util/icanhashcheezeburger';
import { useMemoizedCarouselData } from 'sonora-hooks/useMemoizedCarouselData';

export interface ChatProviderProps {
  relationshipId: number;
  isRelationshipActive: boolean;
  mainThreadId: number;
  chatUser: ChatUserType;
  userRole: ChatUserRole;
}
type ChatUpdateStatus = 'Fail' | 'Success';

interface ChatContextState {
  loading: boolean;
  currentThreadId: number;
  videoCarousel: CarouselThreadData[];
  messageThread: ChatThreadType;
  chatUser: ChatUserType;
  mainThreadId: number;
  isRelationshipActive: boolean;
  onSend: (messages: GiftedMessage[]) => void;
  setCurrentThreadId: (id: number) => void;
  lastFetchedTimeForThread: (threadId: number) => Date;
  updateFetchedTimeForThread: (threadId: number, time?: Date) => void;
}

export const ChatContext = createContext({} as ChatContextState);

export const ChatProvider: FC<ChatProviderProps> = ({
  relationshipId,
  isRelationshipActive,
  mainThreadId,
  chatUser,
  userRole,
  children,
}) => {
  /** track Current Thread */
  const [currentThreadId, setCurrentThreadId] = useState(mainThreadId);
  // console.log('current thread', currentThreadId);
  /** track last fetched timestamps for each thread */
  const [fetchedDates, setFetchedDates] = useState<{
    [threadId: number]: Date;
  }>({});
  const lastFetchedTimeForThread = (threadId: number): Date => {
    const lastFetched = fetchedDates[threadId];
    if (!lastFetched) {
      const initial = new Date();
      updateFetchedTimeForThread(threadId, initial);
      return initial;
    } else return lastFetched;
  };

  const updateFetchedTimeForThread = (threadId: number, time?: Date) => {
    setFetchedDates((curr) => ({ ...curr, [threadId]: time || new Date() }));
  };

  /** Get the data */

  const lastFetched = lastFetchedTimeForThread(currentThreadId);

  const isMainThread = currentThreadId === mainThreadId;

  const forceRerender = useForceRerender();

  const [createMessage] = useCreateMessageMutation({ ignoreResults: true });
  const chatQuery = useChatActivityQuery({
    variables: { since: lastFetched },
  });

  function processIncoming(data: ChatActivityQuery): ChatUpdateStatus {
    // console.log('PROCESSING data on chatactivity', data);
    let errorCount = 0;
    if (!currThread.updateQuery) {
      handleError(new Error('MessageType currThread not ready to update'));
      return 'Fail';
    }

    if (data.chats && data.chats.chats) {
      data.chats.chats.forEach((incoming) => {
        if (incoming === null || incoming === undefined || !incoming.__typename)
          return;
        switch (incoming.__typename) {
          case 'MessageThreadType':
            //  DON'T EVEN WORRY ABOUT THIS, BECAUSE THE MESSAGETHREAD
            // WON'T BE VALID UNTIL A NEW ATTACHMENT COMES THRU/MESSAGE COMPLETE
            // WHICH WILL COME IN ON THE MESSAGE TYPE :) WE WILL RUN
            // REFETCH ON ALLTHREADS AT THAT POINT TO GET THE GOOD STUFF.
            break;
          case 'MessageType':
            // if message is in this thread, update/add it.
            if (incoming.messageThreadId === currentThreadId) {
              currThread.updateQuery((prevData) => {
                if (
                  !prevData ||
                  !prevData.messageThread ||
                  !prevData.messageThread.messages
                ) {
                  handleError(new Error('currThread prevData missing shape'));
                  errorCount++;
                  return prevData;
                }

                /**
                 * See if we can find the exact message already, with same id,
                 * for updating it.
                 */
                const idMatchIndex = prevData.messageThread.messages.findIndex(
                  (m) => m && m.id == incoming.id
                );

                /**
                 * See if this message matches a pending message on contents only,
                 * because sometimes these chat results come in before `sent` query
                 * is done processing.
                 */
                const contentMatchIndex = prevData.messageThread.messages.findIndex(
                  (m) =>
                    m && isNaN(Number(m.id)) && m.contents === incoming.contents
                );

                /**
                 * Defer to the id match if available
                 * But if the message matches on content, update it based on that
                 * Otherwise we'll create a new one
                 */
                const existingIndex =
                  idMatchIndex >= 0
                    ? idMatchIndex
                    : contentMatchIndex >= 0
                    ? contentMatchIndex
                    : -1;

                if (existingIndex >= 0) {
                  const existingMessage = prevData.messageThread.messages[
                    existingIndex
                  ] as MessageWithAttachmentsFragment;
                  prevData.messageThread.messages[existingIndex] = {
                    ...existingMessage,
                    // the only two fields that would change
                    complete: incoming.complete,
                    read: incoming.read,
                  };
                } else {
                  const newMessage: MessageWithAttachmentsFragment = {
                    __typename: 'MessageType',
                    id: incoming.id,
                    complete: incoming.complete,
                    contents: incoming.contents,
                    createdAt: incoming.createdAt,
                    read: incoming.read,
                    sender: incoming.sender,
                    attachments: incoming.attachments, // ??
                    // exercise leave out
                  } as MessageWithAttachmentsFragment;
                  prevData.messageThread.messages.push(newMessage);
                }
                return prevData;
              });
            } else {
              // if message is not in this thread, and IS complete, run allthreads
              allThreads.refetch();
            }

            break;
          case 'MessageAttachmentType':
            if (!currThread.data || !currThread.data.messageThread) {
              handleError(
                new Error('Attachment: no currThread data to update')
              );
              errorCount++;
              return;
            }

            const existingIndex =
              currThread.data.messageThread.messages &&
              currThread.data.messageThread.messages.findIndex(
                (m) => m && parseInt(m.id) === incoming.messageId
              );

            if (!!existingIndex && existingIndex >= 0) {
              // attachment is in this thread, update/add it.
              currThread.updateQuery((prevData) => {
                if (
                  !prevData ||
                  !prevData.messageThread ||
                  !prevData.messageThread.messages
                ) {
                  handleError(
                    new Error(
                      'Attachment updateQuery: no currThread data to update'
                    )
                  );
                  errorCount++;
                  return prevData;
                }
                const message = prevData.messageThread.messages[
                  existingIndex
                ] as MessageWithAttachmentsFragment;
                // const attachments = message.attachments;
                // replace attachment or add it

                prevData.messageThread.messages[existingIndex] = {
                  ...message,
                  attachments: [incoming],
                };
                return prevData;
              });
            } else {
              // if NOT in this thread, time to run allthreads
              // console.log('refetching allthreads cuz attachemtn changed');
              if (!!allThreads.refetch) allThreads.refetch();
            }
            break;
        }
      });
    }
    if (errorCount > 0) {
      handleError(new Error(`processIncoming had ${errorCount} errors`));
    }
    return errorCount === 0 ? 'Success' : 'Fail';
  }

  const startChatPolling = () => {
    // console.log('refetch & start chat polling');
    chatQuery.refetch({ relationshipId, since: lastFetched });
    chatQuery.startPolling(1.9 * 1000); // double check this polls on correct since
  };

  useEffect(() => {
    if (lastFetched) {
      startChatPolling();
    }
  }, [lastFetched]);

  useEffect(() => {
    // console.log('chatactivity change', chatQuery);
    if (chatQuery.data && chatQuery.data.chats && chatQuery.data.chats.since) {
      const result = processIncoming(chatQuery.data);
      if (result === 'Success')
        updateFetchedTimeForThread(currentThreadId, chatQuery.data.chats.since);
    } else {
      // console.log('no new data');
    }
  }, [chatQuery]);

  const currThread = useChatThreadQuery({
    variables: { threadId: currentThreadId },
  });
  const allThreads = useChatAllThreadsQuery({
    variables: {
      relationshipId,
      hasAttachments: true, // only include threads with attachments
    },
  });

  useEffect(() => {
    if (
      currThread.data &&
      !currThread.loading &&
      allThreads.data &&
      !allThreads.loading
    ) {
      // console.log('*** curthread all threads effect starting polling');
      startChatPolling();
    } else {
      chatQuery.stopPolling();
    }
  }, [currThread, allThreads]);

  // useOnFocus(forceRerender);

  /**
   * Marks any unread messages in this thread as read
   */
  useReadMessages(currThread.data, userRole, [
    'ChatAllThreads',
    userRole === 'student' ? 'StudentTab' : 'MentorHome',
  ]);

  /**
   * Actual carousel data
   */

  const carouselData = useMemoizedCarouselData(allThreads.data, mainThreadId);
  // console.log('allthreads', JSON.stringify(allThreads.data));

  /**
   * For memoization of chat thread
   */
  const currThreadHash =
    currThread &&
    currThread.data &&
    currThread.data.messageThread &&
    hashMessageThread(currThread.data.messageThread);

  const memoizedThread = useMemo(() => {
    const simplified =
      (currThread &&
        currThread.data &&
        currThread.data.messageThread &&
        dbThreadToChatThread(
          currThread.data.messageThread,
          relationshipId,
          isMainThread
        )) ||
      ({} as ChatThreadType);

    return simplified;
  }, [currThreadHash]);

  /**
   * Sends message, adds to "pending", & forces re-render after send mutation is done
   */
  const onSend = useMemo(() => {
    // console.log('creating on send function');
    return createOnSend(
      createMessage,
      currThread,
      currentThreadId,
      chatUser,
      forceRerender
    );
  }, [createMessage, currentThreadId, forceRerender]);

  /** Avoid expensive re-renders if not in view */
  // if (!isFocused()) return null;

  /**
   * Check data is valid & ready for processing
   */
  if (currThread.error) handleApolloError(currThread.error);

  return (
    <ChatContext.Provider
      value={{
        loading: currThread.loading,
        mainThreadId,
        currentThreadId,
        videoCarousel: carouselData,
        messageThread: memoizedThread,
        chatUser,
        onSend,
        isRelationshipActive,
        setCurrentThreadId,
        lastFetchedTimeForThread,
        updateFetchedTimeForThread,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
