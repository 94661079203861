import React from 'react';
import {
  Box,
  colors,
  ModalFunctionComponent,
  PremiumPauseView,
} from 'sonora-design-system';
import { mockPremiumSubscription } from 'sonora-util/testData';

export const PremiumPauseModal: ModalFunctionComponent = ({ dismissModal }) => {
  return (
    <Box
      flex={1}
      backgroundColor={colors.white}
      justifyContent="center"
      alignItems="center"
    >
      <PremiumPauseView
        details={mockPremiumSubscription}
        onCancel={() => dismissModal('Success', false)}
        onPause={() => dismissModal('Success', true)}
      />
    </Box>
  );
};
