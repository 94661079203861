import React, { useState, useContext, useEffect, FC } from 'react';
import gql from 'graphql-tag';
import {
  Curriculum,
  CurriculumFlatDic,
  RelationshipStatus,
} from 'sonora-types';

import {
  useStudentInfoQuery,
  UserType,
  useUnreadPollQuery,
} from 'sonora-graphql/types';
import { Loading } from 'sonora-design-system';

import {
  handleApolloError,
  handleError,
  identifyUser,
  trackEvent,
} from 'sonora-services/analytics';
import { View, Text, TouchableOpacity } from 'react-native';
import { useSignOut } from 'sonora-graphql/signInHooks';
import { UserContext } from './UserProvider';
import { UNREAD_POLL_INTERVAL } from 'sonora-util/chat';
import { curriculum } from 'sonora-graphql/fragments';
import { dbEnrollmentToCurriculum } from 'sonora-util/curriculaUtil';

export const STUDENT_INFO_QUERY = gql`
  query StudentInfo {
    user {
      ...User
    }

    enrollments {
      id
      student {
        id
        studentType
      }
      curriculum {
        ...Curriculum
      }
    }

    studentMentorRelationships {
      id
      startedAt
      endedAt
      messageThread {
        id
      }
      mentor {
        id
        user {
          ...User
        }
      }
    }
  }
  ${curriculum}
`;

export interface StudentProviderProps {}
interface MentorRelationshipInfo {
  relationshipId: number;
  startedAt: Date;
  endedAt: Date;
  mainThreadId: number;
  mentorName: string;
}

interface StudentContextState {
  curriculum: Curriculum;
  curriculumMap: CurriculumFlatDic;
  user: UserType;
  studentId: string;
  hasUnreadMessages: boolean;
  studentType: 'HT' | 'LT';
  mentorRelationship?: MentorRelationshipInfo;
  relationshipStatus: RelationshipStatus;
  curricTotalActivities: number;
  curricTotalStars: number;
}

export const StudentContext = React.createContext<StudentContextState>(
  {} as StudentContextState
);

export const StudentProvider: FC<StudentProviderProps> = (props) => {
  const { userType } = useContext(UserContext);
  if (userType === 'mentor') return <>{props.children}</>;

  const [unreadCount, setUnreadCount] = useState(0);
  const [mentorRelationship, setMentorRelationship] = useState<
    MentorRelationshipInfo | undefined
  >(undefined);

  const { data, loading, error } = useStudentInfoQuery();
  const unreadPoll = useUnreadPollQuery({
    pollInterval: UNREAD_POLL_INTERVAL,
  });

  useEffect(() => {
    if (data && data.user) {
      identifyUser(data.user);
      trackEvent('student-log-in', { user: data.user });
    }
    if (
      data &&
      data.studentMentorRelationships &&
      data.studentMentorRelationships.length > 0
    ) {
      const activeRelationship = data.studentMentorRelationships[0];
      if (!activeRelationship || !activeRelationship.messageThread)
        throw new Error('bad relationship data');

      setMentorRelationship({
        relationshipId: Number(activeRelationship.id),
        startedAt: new Date(activeRelationship.startedAt),
        endedAt: new Date(activeRelationship.endedAt),
        mentorName: activeRelationship.mentor.user.fullName,
        mainThreadId: Number(activeRelationship.messageThread.id),
      });
      unreadPoll.startPolling(UNREAD_POLL_INTERVAL);
      trackEvent('chat-unread-start-polling');
    } else {
      unreadPoll.stopPolling();
      trackEvent('chat-unread-stop-polling');
    }
  }, [data]);

  useEffect(() => {
    if (
      unreadPoll.data &&
      unreadPoll.data.studentMentorRelationships &&
      unreadPoll.data.studentMentorRelationships[0]
    ) {
      const unread = unreadPoll.data.studentMentorRelationships[0].unreadCount;
      if (unread !== null && unread !== undefined) setUnreadCount(unread);
    }
  }, [unreadPoll.data]);
  const signOut = useSignOut();
  if (error) {
    handleError('StudentProvider special graphql Error - signing out');
    handleApolloError(error);
    signOut();
  }
  if (loading) return <Loading text="StudentProvider" />;
  if (
    !data ||
    !data.user ||
    !data.enrollments ||
    !data.enrollments[0] ||
    !data.enrollments[0].curriculum ||
    !data.studentMentorRelationships
  )
    throw new Error('student provider query bad data');

  if (data.enrollments[0].curriculum.title == 'guitar') {
    handleError('v1 user tried to log in');
    return (
      <View>
        <Text style={{ padding: 10, margin: 10 }}>
          You are a guitar mastery student, but not enrolled in our new v2
          curriculum, which this app requires. Get in touch with
          contact@guitarmasteryintensive.com if you want to be part of the new
          curriculum
        </Text>
        <TouchableOpacity onPress={signOut} style={{ padding: 10, margin: 10 }}>
          <Text>Sign Out</Text>
        </TouchableOpacity>
      </View>
    );
  }

  /* TEST: LINK NODE VIEW WITH 6TH STRING FRONT FORM KEY OF C */
  // console.log('CURRIC: ', data.enrollments[0].curriculum);
  // let sixthStringFrontFormKeyOfC =
  //   data.enrollments[0].curriculum.body.curriculum[1].children[2].children[1];

  // sixthStringFrontFormKeyOfC.media.type = 'link';
  // sixthStringFrontFormKeyOfC.media.embedString =
  //   'https://www.nextlevelguitar.com/guitar_cheat_sheets/files/nlg_beginner_guitar_cheat_sheet.pdf';
  // sixthStringFrontFormKeyOfC.supplementary = {
  //   pageLink:
  //     'https://www.guitarmasteryintensive.com/major-scale-6th-string-front-form-supplement',
  // };

  /* TEST: TEXT NODE VIEW WITH 6TH STRING FRONT FORM KEY OF C */
  // let sixthStringFrontFormKeyOfC =
  //   data.enrollments[0].curriculum.body.curriculum[1].children[2].children[1];

  // sixthStringFrontFormKeyOfC.media.type = 'text';
  // sixthStringFrontFormKeyOfC.supplementary = {
  //   pageLink:
  //     'https://www.guitarmasteryintensive.com/major-scale-6th-string-front-form-supplement',
  // };

  const { curriculum, curriculumMap } = dbEnrollmentToCurriculum(
    data.enrollments[0].curriculum
  );

  const user = data.user as UserType;
  const studentInfo = data.enrollments[0].student;
  const studentId = studentInfo.id;
  const studentType = studentInfo.studentType;

  function determineRelationshipStatus(): RelationshipStatus {
    if (studentType === 'LT') return 'NoMentor';
    // 'Error' might happen for 1 render cycle before useEffect causes re-render w/ correct info?
    else if (!mentorRelationship) return 'Error';

    if (mentorRelationship.endedAt < new Date()) return 'Expired';
    if (mentorRelationship.startedAt > new Date()) return 'NotStarted';

    return 'ActiveMentor';
  }

  const relationshipStatus = determineRelationshipStatus();
  const hasUnreadMessages = unreadCount > 0;

  let curricTotalStars = 0;
  let curricTotalActivities = 0;
  Object.values(curriculumMap).forEach((node) => {
    if (node.type === 'Folder') return;
    curricTotalStars += node.maxStars;
    curricTotalActivities += 1;
  });

  return (
    <StudentContext.Provider
      value={{
        user,
        studentId,
        studentType,
        curriculum,
        curriculumMap,
        mentorRelationship,
        relationshipStatus,
        hasUnreadMessages,
        curricTotalActivities,
        curricTotalStars,
      }}
    >
      {props.children}
    </StudentContext.Provider>
  );
};
