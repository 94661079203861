import React, { FC, useContext } from 'react';
import { TouchableOpacity } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import styled from 'styled-components/native';
import {
  FillIcon,
  colors,
  Box,
  Text,
  HorizontalDivider,
  ModalPresenterContext,
  theme,
} from 'sonora-design-system';

import {
  VideoSubmissionStack,
  VideoSubmissionStackExtraData,
} from '../video-submission/VideoSubmissionStack';
import { CarouselThreadData } from 'sonora-design-system/components/Chat/types';
import { ThreadCarouselCard } from './ThreadCarouselCard';
import { trackEvent } from 'sonora-services/analytics';

const CarouselContainer = styled.View`
  background-color: ${colors.agate};
  position: relative;
  min-height: 4px;
`;

const AddIconContainer = styled.View`
  background-color: ${colors.white};
  border-width: 1px;
  border-color: ${colors.purpley};
  border-style: dashed;
  width: 72px;
  height: 72px;
  justify-content: center;
  align-items: center;
`;

const GradientBorder = styled(LinearGradient)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 4px;
  z-index: 0;
`;

const StyledScrollView = styled.ScrollView`
  padding: 17px 24px 13px;
`;

export interface ThreadCarouselProps {
  carouselData: CarouselThreadData[];
  isAddPostEnabled: boolean;
}

export const ThreadCarousel: FC<ThreadCarouselProps> = (props) => {
  const { presentComponentAsModal } = useContext(ModalPresenterContext);

  const startNewVideo = () => {
    trackEvent('initiate-student-submission-video');
    const extraData: VideoSubmissionStackExtraData = {
      isLogProgressFlow: false,
      startOnRatingScreen: false,
      videoSubject: 'Student Submission',
      messageThreadId: undefined,
      activityId: 'unused', //because this video has no associated activity
    };
    presentComponentAsModal(VideoSubmissionStack, {
      extraData,
    });
  };

  const addPost = (
    <Box flex={1} alignItems="center" justifyContent="flex-start">
      <TouchableOpacity onPress={startNewVideo}>
        <AddIconContainer>
          <FillIcon name="plusCircle" color={colors.tanzanite} />
        </AddIconContainer>
        <Text size={12} marginTop={18}>
          Add new Post
        </Text>
      </TouchableOpacity>
    </Box>
  );

  return (
    <CarouselContainer>
      <GradientBorder {...theme.common.horizontalGradient} />
      {props.carouselData.length === 0 &&
      !props.isAddPostEnabled ? undefined : (
        <StyledScrollView horizontal={true}>
          {props.isAddPostEnabled ? addPost : undefined}

          {props.isAddPostEnabled ? (
            <HorizontalDivider marginLeft={24} marginRight={24} />
          ) : null}
          {props.carouselData.map((thread) => (
            <ThreadCarouselCard
              key={thread.id}
              thread={thread}
              hasBorder={true}
            />
          ))}
        </StyledScrollView>
      )}
    </CarouselContainer>
  );
};
