import React from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import styled from 'styled-components/native';

import { Box, Row, Text } from '../styled';
import { Button } from '../Button';
import { theme, colors } from '../../constants/theme';
import { HeaderContainerProps, AnnouncementHeaderProps } from './types';
import { SecondaryCta } from './SecondaryCta';
import { SquiggleImage } from './SquiggleImage';
import { AnnouncementHeadline, AnnouncementDescription } from './shared';

const HeaderContainer: React.FC<HeaderContainerProps> = styled.View`
  padding: 12px 24px;
  border-top-left-radius: ${theme.common.borderRadius};
  border-bottom-left-radius: ${theme.common.borderRadius};
  background-color: ${(props: HeaderContainerProps) => props.backgroundColor};
`;

export const AnnouncementHeaderMobile: React.FC<AnnouncementHeaderProps> = ({
  headerStyle,
  headerLargeText,
  headerSmallText,
  ctaButtonText,
  specialComponent,
  onPress,
}) => {
  const backgroundColor =
    headerStyle === 'ruby' ? colors.ruby : colors.amethyst;

  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <HeaderContainer backgroundColor={backgroundColor}>
        <Row justifyContent="space-between">
          <Box>
            <Row marginBottom={9}>
              <Text size={12} marginRight={12} color={colors.thulite}>
                Announcements
              </Text>
              <SquiggleImage headerStyle={headerStyle} />
            </Row>
            <AnnouncementHeadline size="mobile">
              {headerLargeText}
            </AnnouncementHeadline>
            <AnnouncementDescription size="mobile">
              {headerSmallText}
            </AnnouncementDescription>
          </Box>
          <Box alignItems="flex-end">
            {specialComponent || null}
            {headerStyle === 'ruby' ? (
              <Button type="Special" onPress={onPress} text={ctaButtonText} />
            ) : (
              <SecondaryCta onPress={onPress} text={ctaButtonText} />
            )}
          </Box>
        </Row>
      </HeaderContainer>
    </TouchableWithoutFeedback>
  );
};
