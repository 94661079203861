import React, { FC, useState, useContext, useEffect } from 'react';
import {
  CurriculumProgress,
  NodeId,
  CurriculumFlatDic,
  ModificationId,
  DbModification,
  DbModificationCategory,
  Curriculum,
} from 'sonora-types';

import {
  useCurriculumProgressQuery,
  useLogActivityProgressMutation,
} from 'sonora-graphql/types';
import { StudentContext } from './StudentProvider';
import { Loading } from 'sonora-design-system';
import { handleApolloError } from 'sonora-services/analytics';
import { streakFromDatesArray } from 'sonora-util/dateUtils';
import { DateContext } from './DateProvider';
import { UserContext } from './UserProvider';

export interface ProgressProviderProps {}

interface LogProgressResult {
  newUnlockedModifications: UnlockedModificationsMap;
}

type LogProgressArgs = { nodeId: NodeId; stars: number };
export type LogProgressFn = ({
  nodeId,
  stars,
}: LogProgressArgs) => Promise<LogProgressResult>;

export interface UnlockedModificationsMap {
  modificationIds: ModificationId[];
  categoryIds: number[];
}

interface ProgressContextState {
  progress: CurriculumProgress;
  completedNodes: NodeId[];
  unlockedNodes: NodeId[];
  unlockedModificationsMap: UnlockedModificationsMap;
  streakCount: number;
  /**
   * Array of YYYY-MM-DD strings
   */
  practicedDates: string[];
  logProgress: LogProgressFn;
}

export const ProgressContext = React.createContext<ProgressContextState>(
  {} as ProgressContextState
);

type NodeListReturn = {
  completedNodes: NodeId[];
  unlockedNodes: NodeId[];
};

const createUnlockedNodeList = (
  curriculumMap: CurriculumFlatDic,
  studentProgress: CurriculumProgress
): NodeListReturn => {
  const hasBeenPracticed = (nodeId: NodeId) =>
    studentProgress[nodeId] && studentProgress[nodeId].currentStars >= 0;
  const completedNodes: NodeId[] = [];
  const unlockedNodes: NodeId[] = [];
  const allCurricNodes = Object.values(curriculumMap);

  allCurricNodes.forEach((node) => {
    if (hasBeenPracticed(node.id)) {
      completedNodes.push(node.id);
    }

    const requiredNodes = node.requiredNodeIds || [];
    if (requiredNodes.every(hasBeenPracticed)) unlockedNodes.push(node.id);
  });

  completedNodes.sort((a, b) =>
    studentProgress[a].lastPracticed < studentProgress[b].lastPracticed ? -1 : 1
  );

  return { completedNodes, unlockedNodes };
};

const getUnlockedModifications = (
  completedNodes: NodeId[],
  curriculum: Curriculum
) => {
  const unlockedModificationIds = curriculum.modifications
    .filter((modification: DbModification) => {
      return (
        !modification.requiredNodeId ||
        completedNodes.includes(modification.requiredNodeId)
      );
    })
    .map((modification: DbModification) => modification.id);

  const unlockedCategoryIds = curriculum.modificationCategories
    .filter((category: DbModificationCategory) => {
      return (
        !category.requiredNodeId ||
        completedNodes.includes(category.requiredNodeId.toString())
      );
    })
    .map((category: DbModificationCategory) => category.id);

  return {
    modificationIds: unlockedModificationIds,
    categoryIds: unlockedCategoryIds,
  };
};

const isString = (s: string | undefined | null): s is string => !!s;

export const ProgressProvider: FC<ProgressProviderProps> = (props) => {
  const { userType } = useContext(UserContext);
  if (userType === 'mentor') return <>{props.children}</>;

  const [progressMap, setProgressMap] = useState<CurriculumProgress>({});
  const [unlockedNodes, setUnlockedNodes] = useState<NodeId[]>([]);
  const [completedNodes, setCompletedNodes] = useState<NodeId[]>([]);
  const { curriculum, curriculumMap } = useContext(StudentContext);
  const [practicedDates, setPracticedDates] = useState<string[]>([]);
  const [unlockedModificationsMap, setUnlockedModificationsMap] = useState<
    UnlockedModificationsMap
  >({ modificationIds: [], categoryIds: [] });

  const { todayDate } = useContext(DateContext);
  /**
   * When the progress map changes, recalculate the unlocked & completed nodes
   */
  useEffect(() => {
    const { unlockedNodes, completedNodes } = createUnlockedNodeList(
      curriculumMap,
      progressMap
    );

    setUnlockedNodes(unlockedNodes);
    setCompletedNodes(completedNodes);

    let unlockedModifications = getUnlockedModifications(
      completedNodes,
      curriculum
    );

    setUnlockedModificationsMap(unlockedModifications);
  }, [progressMap]);

  const [logProgressMutation] = useLogActivityProgressMutation();

  const checkForNewModifications = (nodeId: NodeId) => {
    if (unlockedNodes.includes(nodeId)) {
      return { categoryIds: [], modificationIds: [] };
    }

    const newCategoryIds = curriculum.modificationCategories
      .filter(
        (category) =>
          category.requiredNodeId &&
          category.requiredNodeId.toString() === nodeId
      )
      .map((category) => category.id);

    let distinctCategoryIds = [
      ...unlockedModificationsMap.categoryIds,
      ...newCategoryIds,
    ];

    distinctCategoryIds = distinctCategoryIds.filter(
      (item, index) => distinctCategoryIds.indexOf(item) === index
    );

    const newModificationIds = curriculum.modifications
      .filter(
        (modification) =>
          modification.requiredNodeId &&
          modification.requiredNodeId.toString() === nodeId
      )
      .map((modification) => modification.id);

    let distinctModificationIds = [
      ...unlockedModificationsMap.modificationIds,
      ...newModificationIds,
    ];
    distinctModificationIds = distinctModificationIds.filter(
      (item, index) => distinctModificationIds.indexOf(item) === index
    );

    setUnlockedModificationsMap({
      categoryIds: distinctCategoryIds,
      modificationIds: distinctModificationIds,
    });

    return { categoryIds: newCategoryIds, modificationIds: newModificationIds };
  };

  /**
   * Log progress in database, update local info,
   */
  const logProgress: LogProgressFn = ({ nodeId, stars }) => {
    const node = curriculumMap[nodeId];
    if (node.type === 'Folder')
      throw new Error('cannot log progress on Folder');
    const practiceDate = todayDate;
    if(progressMap[nodeId]?.currentStars >= 5 && stars == 5) stars = progressMap[nodeId].currentStars + 1
    return new Promise((resolve, reject) => {
      logProgressMutation({
        variables: {
          activityId: nodeId,
          stars,
          curriculumId: curriculum.id,
          timeStamp: practiceDate,
          isPractice: node.assignmentType === 'practice',
          datePracticed: new Date(practiceDate.getTime() - (practiceDate.getTimezoneOffset() * 60000)).toISOString().split("T")[0],
        },
        refetchQueries: ['CurriculumProgress'],
      })
        .then((result) => {
          // Needs to happen before setting progress to determine if modifications
          // are new or not.
          let newUnlockedModifications = checkForNewModifications(nodeId);
          // set progress locally
          progressMap[nodeId] = {
            currentStars: stars,
            lastPracticed: practiceDate,
          };
          setProgressMap(progressMap);
          resolve({ newUnlockedModifications });
        })
        .catch((err) => {
          handleApolloError(err);
          reject();
        });
    });
  };

  const { data, loading, error } = useCurriculumProgressQuery({
    variables: {
      curriculumId: curriculum.id,
    },
  });

  useEffect(() => {
    if (data && data.latestExerciseProgresses) {
      console.log('curricProgress query changed');

      const newMap: CurriculumProgress = {};

      data.latestExerciseProgresses.forEach((progress) => {
        if (progress) {
          newMap[progress.exerciseId] = {
            currentStars: progress.rating || 0,
            lastPracticed: new Date(progress.startedAt),
          };
        }
      });
      setProgressMap(newMap);
      if (data.datesPracticed) {
        const strings = data.datesPracticed.filter(isString);
        setPracticedDates(strings);
      }
    }
  }, [data]);

  if (error) handleApolloError(error);
  if (loading) return <Loading text="ProgressProvider" />;
  if (!data || !data.latestExerciseProgresses)
    throw new Error('malformed progress data');

  const streakCount = streakFromDatesArray(practicedDates);
  console.log('unlocked mods map', unlockedModificationsMap);

  return (
    <ProgressContext.Provider
      value={{
        progress: progressMap,
        unlockedNodes,
        completedNodes,
        logProgress,
        streakCount,
        practicedDates,
        unlockedModificationsMap,
      }}
    >
      {props.children}
    </ProgressContext.Provider>
  );
};
