import React from 'react';
import { Day } from 'react-native-gifted-chat';

export const RenderDay: React.FC = (props) => (
  <Day
    {...props}
    textStyle={
      {
        //   ...fonts.regular,
        //   fontSize: fontSize[0],
      }
    }
  />
);
