import React from 'react';
import { View, Text } from 'react-native';
import { RelationshipStatus } from 'sonora-types';

interface MessagingDisabledAlertProps {
  relationshipStatus: RelationshipStatus;
}

export const MessagingDisabledAlert: React.FC<MessagingDisabledAlertProps> = ({
  relationshipStatus,
}) => {
  return (
    <View>
      <Text>
        Messaging is disabled, relationship status is ${relationshipStatus}
      </Text>
    </View>
  );
};
