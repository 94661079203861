import React, { forwardRef } from 'react';
import styled from 'styled-components/native';
import { pushStyle } from './utils';

export interface BoxProps {
  children?: React.ReactNode;
  direction?: 'row' | 'column';
  justifyContent?: string;
  alignItems?: string;
  marginLeft?: number | 'auto';
  marginTop?: number | 'auto';
  marginRight?: number | 'auto';
  marginBottom?: number | 'auto';
  marginX?: number | 'auto';
  marginY?: number | 'auto';
  margin?: number | 'auto';
  paddingLeft?: number;
  paddingTop?: number;
  paddingRight?: number;
  paddingBottom?: number;
  paddingX?: number;
  paddingY?: number;
  padding?: number;
  width?: string;
  height?: string;
  backgroundColor?: string;
  borderWidth?: number;
  borderTopWidth?: number;
  borderLeftWidth?: number;
  borderRightWidth?: number;
  borderBottomWidth?: number;
  borderRadius?: string;
  borderColor?: string;
  borderStyle?: string;
  opacity?: number;
  maxWidth?: number;
  minHeight?: number;
  flex?: number | 'none';
  flexWrap?: string;
  position?: 'relative' | 'fixed' | 'absolute' | 'static';
  zIndex?: number;
  cursor?: string;
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
  overflow?: string;
}

interface StyledViewProps {
  cssStyle: string;
  ref?: any;
}
const StyledView: React.FC<StyledViewProps> = styled.View`
  ${(props: { cssStyle: string }) => props.cssStyle}
`;

export const Box = forwardRef<JSX.Element, BoxProps>(
  (
    {
      children,
      direction,
      justifyContent,
      alignItems,
      marginLeft,
      marginRight,
      marginTop,
      marginBottom,
      marginX,
      marginY,
      margin,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      paddingX,
      paddingY,
      padding,
      width,
      height,
      backgroundColor,
      borderWidth,
      borderBottomWidth,
      borderTopWidth,
      borderLeftWidth,
      borderRightWidth,
      borderColor,
      borderStyle,
      opacity,
      maxWidth,
      minHeight,
      flex,
      flexWrap,
      position,
      zIndex,
      borderRadius,
      cursor,
      top,
      left,
      bottom,
      right,
      overflow,
    },
    ref
  ) => {
    const styles: string[] = [];

    pushStyle(styles, 'flex-direction', direction);
    pushStyle(styles, 'justify-content', justifyContent);
    pushStyle(styles, 'align-items', alignItems);
    pushStyle(styles, 'margin-left', marginLeft || marginX || margin, 'px');
    pushStyle(styles, 'margin-right', marginRight || marginX || margin, 'px');
    pushStyle(styles, 'margin-top', marginTop || marginY || margin, 'px');
    pushStyle(styles, 'margin-bottom', marginBottom || marginY || margin, 'px');
    pushStyle(styles, 'padding-left', paddingLeft || paddingX || padding, 'px');
    pushStyle(
      styles,
      'padding-right',
      paddingRight || paddingX || padding,
      'px'
    );
    pushStyle(styles, 'padding-top', paddingTop || paddingY || padding, 'px');
    pushStyle(
      styles,
      'padding-bottom',
      paddingBottom || paddingY || padding,
      'px'
    );
    pushStyle(styles, 'width', width);
    pushStyle(styles, 'height', height);
    pushStyle(styles, 'background-color', backgroundColor);
    pushStyle(
      styles,
      'border-left-width',
      borderLeftWidth || borderWidth,
      'px'
    );
    pushStyle(styles, 'border-top-width', borderTopWidth || borderWidth, 'px');
    pushStyle(
      styles,
      'border-right-width',
      borderRightWidth || borderWidth,
      'px'
    );
    pushStyle(
      styles,
      'border-bottom-width',
      borderBottomWidth || borderWidth,
      'px'
    );
    pushStyle(styles, 'border-color', borderColor);
    pushStyle(styles, 'border-style', borderStyle);
    pushStyle(styles, 'opacity', opacity);
    pushStyle(styles, 'flex', flex);
    pushStyle(styles, 'max-width', maxWidth, 'px');
    pushStyle(styles, 'min-height', minHeight, 'px');
    pushStyle(styles, 'flex-wrap', flexWrap);
    pushStyle(styles, 'position', position);
    pushStyle(styles, 'z-index', zIndex);
    pushStyle(styles, 'border-radius', borderRadius);
    pushStyle(styles, 'cursor', cursor);
    pushStyle(styles, 'top', top);
    pushStyle(styles, 'left', left);
    pushStyle(styles, 'bottom', bottom);
    pushStyle(styles, 'right', right);
    pushStyle(styles, 'overflow', overflow);

    return (
      <StyledView cssStyle={styles.join('')} ref={ref}>
        {children}
      </StyledView>
    );
  }
);

export const Row = forwardRef<JSX.Element, BoxProps>((props: BoxProps, ref) => (
  <Box ref={ref} direction="row" alignItems="center" {...props} />
));
