// author: @fullstackdevs14
import React from 'react';
import styled from 'styled-components/native';
import { storiesOf } from '@storybook/react-native';

import {
  PrimaryButton,
  SecondaryButton,
  SecondaryCtaWhiteButton,
  TertiaryButton,
  SpecialButton,
  Button,
} from '.';
import { colors } from '../../constants/theme';

const buttonStories = storiesOf('Button', module);

const ButtonStoryContainer = styled.View`
  flex: 1;
  justify-content: center;
  background-color: #ecf0f1;
  padding: 8px;
`;

const InputContainer = styled.View`
  flex: 0.2;
  padding-bottom: 25px;
`;

const ButtonsContainer = styled.View`
  flex: 0.8;
  background-color: ${colors.white};
  padding: 20px;
  align-items: center;
`;

const ButtonsRow = styled.View`
  flex-direction: row;
  margin-bottom: 8px;
  padding: 10px;
  background-color: ${(props: { backgroundColor?: string }) =>
    props.backgroundColor || colors.white};
`;

const Padding = styled.View`
  padding-right: 8px;
`;

const Label = styled.Text`
  padding: 14px 0;
  color: ${colors.tanzanite};
`;

const InputField = styled.TextInput`
  color: ${colors.tanzanite};
  background-color: ${colors.opal};
  border-color: ${colors.tanzanite};
  border-width: 1px;
  padding-left: 20px;
  padding-top: 14px;
  padding-bottom: 16px;
  padding-right: 20px;
`;

const ButtonStory = () => {
  const [btnText, setBtnText] = React.useState('Button Text');

  return (
    <ButtonStoryContainer>
      <InputContainer>
        <Label>Enter the text for button:</Label>
        <InputField value={btnText} onChangeText={setBtnText} />
      </InputContainer>
      <ButtonsContainer>
        <ButtonsRow>
          <Padding>
            <PrimaryButton
              text={btnText}
              icon="check"
              iconStyle="stroke"
              onPress={() => {
                console.log('Button Pressed');
              }}
            />
          </Padding>
          <Padding>
            <Button text={btnText} disabled={true} />
          </Padding>
        </ButtonsRow>
        <ButtonsRow>
          <Padding>
            <SecondaryButton
              text={btnText}
              onPress={() => {
                console.log('Button Pressed');
              }}
              size="small"
            />
          </Padding>
          <Padding>
            <Button
              type="Secondary"
              text={btnText}
              disabled={true}
              size="small"
            />
          </Padding>
        </ButtonsRow>
        <ButtonsRow>
          <Padding>
            <TertiaryButton
              text={btnText}
              onPress={() => {
                console.log('Button Pressed');
              }}
            />
          </Padding>
          <Padding>
            <Button type="Tertiary" text={btnText} disabled={true} />
          </Padding>
        </ButtonsRow>
        <ButtonsRow>
          <Padding>
            <SpecialButton
              text={btnText}
              onPress={() => {
                console.log('Button Pressed');
              }}
            />
          </Padding>
          <Padding>
            <Button type="Special" text={btnText} disabled={true} />
          </Padding>
        </ButtonsRow>
        <ButtonsRow backgroundColor={colors.amethyst}>
          <Padding>
            <SecondaryCtaWhiteButton
              type="SecondaryCtaWhite"
              text={btnText}
              onPress={() => {
                console.log('Button Pressed');
              }}
            />
          </Padding>
          <Padding>
            <Button type="SecondaryCtaWhite" text={btnText} disabled={true} />
          </Padding>
        </ButtonsRow>
      </ButtonsContainer>
    </ButtonStoryContainer>
  );
};

buttonStories.add('default', () => <ButtonStory />);
