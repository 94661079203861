import React, { useContext } from 'react';
import { LayoutProviderContext } from 'sonora-design-system';
import { LayoutType } from 'sonora-design-system/util';
import { colors, fonts } from '../../constants/theme';
import { Button } from '../Button';
import { FillIcon } from '../Icon';
import { Box, Text } from '../styled';

export interface MentorshipExpiredProps {
  onSchedule: () => void;
}

export const MentorshipExpired: React.FC<MentorshipExpiredProps> = ({
  onSchedule,
}) => {
  const { activeLayout } = useContext(LayoutProviderContext);
  const isLandscape = activeLayout === LayoutType.Landscape;

  return (
    <Box
      backgroundColor={colors.grayHover}
      direction={isLandscape ? 'row' : 'column'}
      justifyContent="center"
      alignItems="center"
      width="100%"
      paddingY={20}
    >
      <Box
        alignItems="center"
        marginRight={isLandscape ? 40 : 0}
        marginBottom={isLandscape ? 0 : 20}
        direction={isLandscape ? 'row' : 'column'}
      >
        <FillIcon name="alert" color={colors.alertRed} />
        <Box
          marginLeft={isLandscape ? 16 : 0}
          marginTop={isLandscape ? 0 : 8}
          maxWidth={300}
        >
          <Text
            size={16}
            marginBottom={6}
            textAlign={isLandscape ? 'left' : 'center'}
          >
            Fellowship subscription expired
          </Text>
          <Text font={fonts.thin} textAlign={isLandscape ? 'left' : 'center'}>
            To continue access to your dedicated mentor, schedule a call with
            our admissions team.
          </Text>
        </Box>
      </Box>
      <Button type="Special" text="Schedule a call" onPress={onSchedule} />
    </Box>
  );
};
