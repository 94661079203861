import React, { FC, useContext, useEffect, useMemo, useState } from 'react';

import { useMentorHomeQuery, useUnreadPollQuery } from 'sonora-graphql/types';
import { handleApolloError, handleError } from 'sonora-services/analytics';
import { Loading } from 'sonora-design-system';
import {
  ChatRelationshipType,
  Curriculum,
  CurriculumFlatDic,
} from 'sonora-types';
import gql from 'graphql-tag';
import {
  curriculum,
  mentorHomeRelationshipInfo,
} from 'sonora-graphql/fragments';
import { UserContext } from './UserProvider';
import {
  dbMentorRelationshipToChatRelationship,
  UNREAD_POLL_INTERVAL,
} from 'sonora-util/chat';
import { isRelationshipActive } from 'sonora-util/dateUtils';
import { dbEnrollmentToCurriculum } from 'sonora-util/curriculaUtil';

export const MENTOR_HOME_QUERY = gql`
  query MentorHome {
    studentMentorRelationships {
      ...MentorHomeRelationshipInfo
    }
    enrollments {
      id
      curriculum {
        ...Curriculum
      }
    }
  }
  ${curriculum}
  ${mentorHomeRelationshipInfo}
`;

interface MentorContextState {
  /** The active student-mentor-relationships */
  activeRelationships: ChatRelationshipType[];
  /** Total unread messages this mentor has */
  unreadCount: number;
  /** Curriculum that this mentor is "enrolled" in */
  curriculum: Curriculum;
  /** Node-id indexed dictionary of curric */
  curriculumMap: CurriculumFlatDic;
}

export const MentorContext = React.createContext({} as MentorContextState);

export const MentorProvider: FC = (props) => {
  const { userType } = useContext(UserContext);
  if (userType === 'student') return <>{props.children}</>;

  const [unreadCount, setUnreadCount] = useState(0);
  const unreadPoll = useUnreadPollQuery({
    pollInterval: UNREAD_POLL_INTERVAL,
  });

  const { data, loading, error } = useMentorHomeQuery();

  // Get relationships
  const relationships: ChatRelationshipType[] = useMemo(() => {
    if (!data || !data.studentMentorRelationships) return [];
    return data.studentMentorRelationships.reduce(
      (acc, curr) =>
        curr &&
        curr.messageThread &&
        curr.messageThread.student.studentType === 'HT'
          ? acc.concat(dbMentorRelationshipToChatRelationship(curr))
          : acc,
      [] as ChatRelationshipType[]
    );
  }, [data]);

  // Calculate unread count
  useEffect(() => {
    if (unreadPoll.data && unreadPoll.data.studentMentorRelationships) {
      const unread = unreadPoll.data.studentMentorRelationships.reduce(
        (unread, rel) => unread + ((rel && rel.unreadCount) || 0),
        0
      );
      setUnreadCount(unread);
    }
  }, [unreadPoll.data]);

  if (loading) return <Loading text="MentorProvider" />;
  if (!data || !data.studentMentorRelationships)
    throw new Error('incorrect data from mentor query');
  if (!data.enrollments)
    throw new Error('need enrollment on mentor for v2 to function');

  if (error) handleApolloError(error);

  // Process enrollment for course map
  if (!data.enrollments[0] || !data.enrollments[0].curriculum) {
    handleError('Mentor is missing curriculum enrollment -- add it in django', {
      enrollments: data.enrollments,
    });
    return null;
  }

  const { curriculum, curriculumMap } = dbEnrollmentToCurriculum(
    data.enrollments[0].curriculum
  );

  const activeRelationships = relationships.filter(isRelationshipActive);

  if (loading) return <Loading text="MentorProvider" />;
  return (
    <MentorContext.Provider
      value={{
        activeRelationships,
        unreadCount,
        curriculum,
        curriculumMap,
      }}
    >
      {props.children}
    </MentorContext.Provider>
  );
};
