import React from 'react';
import { StatusBar } from 'react-native';
import { storiesOf } from '@storybook/react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import { Box } from '../styled';
import { CategoryView } from './CategoryView';
import { DetailsView } from './DetailView';

const modificationNavigationStories = storiesOf(
  'ModificationNavigation',
  module
);

const Stack = createStackNavigator();

const ModificationNavigationStory = () => {
  return (
    <NavigationContainer>
      <SafeAreaProvider>
        <StatusBar barStyle="light-content" />
        <Stack.Navigator initialRouteName="Categories" headerMode="screen">
          <Stack.Screen
            name="Categories"
            component={CategoryView}
            options={{
              header: () => <Box />,
            }}
          />
          <Stack.Screen
            name="Details"
            component={DetailsView}
            options={{
              header: () => <Box />,
            }}
          />
        </Stack.Navigator>
      </SafeAreaProvider>
    </NavigationContainer>
  );
};

modificationNavigationStories.add('default', ModificationNavigationStory);
