// author: @fullstackdevs14
import { colors } from '../../constants/theme';
import { convertHexToRGBA } from '../../util/colors';
import { ColorKey, ButtonColors } from './types';

const colorTemplate1: ColorKey = {
  disabled: colors.purpley,
  enabled: colors.tanzanite,
  hover: colors.amethyst,
  pressed: colors.tourmaline,
};
const allWhite: ColorKey = {
  disabled: colors.white,
  enabled: colors.white,
  hover: colors.white,
  pressed: colors.white,
};
const allTransparent: ColorKey = {
  disabled: 'transparent',
  enabled: 'transparent',
  hover: 'transparent',
  pressed: 'transparent',
};
const allEmpty: ColorKey = {
  disabled: '',
  enabled: '',
  hover: '',
  pressed: '',
};

export const ctaBackgroundColors: ButtonColors = {
  Primary: colorTemplate1,
  Secondary: {
    disabled: colors.white,
    enabled: colors.white,
    hover: colors.agate,
    pressed: colors.agate,
  },
  SecondaryCtaWhite: {
    ...allTransparent,
  },
  Tertiary: {
    ...allTransparent,
    pressed: colors.agate,
  },
  Special: colorTemplate1,
};

export const ctaTextColors: ButtonColors = {
  Primary: allWhite,
  Secondary: colorTemplate1,
  SecondaryCtaWhite: {
    ...allWhite,
    disabled: convertHexToRGBA(colors.white, 40),
    pressed: convertHexToRGBA(colors.white, 70),
  },
  Tertiary: colorTemplate1,
  Special: allWhite,
};

export const ctaBorderColors: ButtonColors = {
  Primary: allEmpty,
  Secondary: colorTemplate1,
  SecondaryCtaWhite: {
    ...allWhite,
    disabled: convertHexToRGBA(colors.white, 40),
    pressed: convertHexToRGBA(colors.white, 70),
  },
  Tertiary: allEmpty,
  Special: allEmpty,
};

export const ctaTextUnderlineColors: ButtonColors = {
  Primary: allEmpty,
  Secondary: allEmpty,
  SecondaryCtaWhite: allEmpty,
  Tertiary: colorTemplate1,
  Special: allEmpty,
};

export const ctaShadowColors: ButtonColors = {
  Primary: allEmpty,
  Secondary: allEmpty,
  SecondaryCtaWhite: allEmpty,
  Tertiary: allEmpty,
  Special: {
    ...colorTemplate1,
    enabled: colors.tourmaline,
    hover: colors.tanzanite,
  },
};
