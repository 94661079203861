import React, { useRef, useEffect, useContext } from 'react';
import styled from 'styled-components/native';
import { default as WebView } from 'react-native-webview';
import { NodeViewTabs } from './NodeViewTabs';

import { ModalPresenterContext } from '../../util/ModalProvider';
import { colors } from '../../constants/theme';
import { ButtonRow } from '../ButtonRow';
import { ButtonProps } from '../Button';
import { VimeoPlayer, stopAudioVideo } from '../VimeoPlayer';
import { VideoNodeViewProps } from './types';
import { Scrollable } from '../Scrollable';

const VideoContainer = styled.View`
  background-color: ${colors.gray36};
`;

export const VideoNodeView: React.FC<VideoNodeViewProps> = ({
  vimeoVideoId,
  title,
  primaryCta,
  secondaryCta,
  tabs,
}) => {
  const vimeoRef = useRef<HTMLIFrameElement | WebView>(null);
  const { modalIsVisible } = useContext(ModalPresenterContext);

  useEffect(() => {
    if (modalIsVisible) {
      stopAudioVideo(vimeoRef);
    }
  }, [modalIsVisible]);

  const secondaryCtaButton = secondaryCta && {
    ...secondaryCta,
    type: 'Secondary',
  };
  const primaryCtaButton = primaryCta && { ...primaryCta, type: 'Primary' };

  const buttons = [secondaryCtaButton, primaryCtaButton].filter(
    Boolean
  ) as ButtonProps[];

  // Typing VimeoPlayer ref as any due to changing ref based on Platform
  return (
    <Scrollable>
      <VideoContainer>
        <VimeoPlayer
          ref={vimeoRef as any}
          vimeoVideoID={vimeoVideoId}
          maxHeight={560}
        />
      </VideoContainer>
      <ButtonRow buttons={buttons} />
      <NodeViewTabs tabs={tabs} />
    </Scrollable>
  );
};
