import React from 'react';
import {
  Box,
  Text,
  colors,
  fonts,
  ModalFunctionComponent,
  VimeoPlayer,
} from 'sonora-design-system';

export const WelcomeModal: ModalFunctionComponent = () => {
  return (
    <Box
      flex={1}
      backgroundColor={colors.white}
      justifyContent="center"
      alignItems="center"
      paddingX={90}
      paddingY={80}
    >
      <Text font={fonts.headline} size={24} textAlign="center">
        Welcome to Sonora
      </Text>
      <Text font={fonts.thin} size={20} marginBottom={28} textAlign="center">
        Get ready for fretboard fluency
      </Text>
      <VimeoPlayer vimeoVideoID="498540661" maxHeight={270} autoplay />

      <Text font={fonts.thin} size={18} marginTop={28} textAlign="center">
        We’re here to help walk you through our accelerated learning software.
      </Text>
    </Box>
  );
};
