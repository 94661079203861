import { useLayoutEffect } from 'react';
import { useNavigation } from '@react-navigation/native';

export const useOnBlur = (fn: () => void) => {
  const { addListener } = useNavigation();
  useLayoutEffect(() => {
    const unsubscribe = addListener('blur', () => {
      return fn();
    });
    return unsubscribe;
  }, [fn]);
};
