import React, { FC, useRef, useEffect, useContext } from 'react';
import { View, StyleSheet, Animated } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';

import { L2Screens } from '../screens/L2Screens';
import { colors } from 'sonora-design-system';
import { L2NavigationContext } from './L2NavigationProvider';

export interface L2LandscapeDrawerProps {}

const L2LandscapeDrawer: FC<L2LandscapeDrawerProps> = (props) => {
  const { activeIndex } = useContext(L2NavigationContext);
  const navigation = useNavigation();
  const route = useRoute();

  let width = activeIndex == undefined ? 0 : 314;
  let widthAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(widthAnim, {
      toValue: width,
      duration: 400,
      useNativeDriver: false,
    }).start();
  }, [activeIndex]);

  let screenComponents = L2Screens.map((screen) =>
    // @ts-ignore FOR NOW AS AN EXAMPLE DO NOT LET THIS 💩 GET INTO PRODUCTION
    screen.component({ navigation, route })
  );

  let activeComponent;
  if (activeIndex != undefined) {
    activeComponent = screenComponents[activeIndex];
  }

  const content =
    activeIndex != undefined ? (
      <View style={{ flex: 1 }}>{activeComponent}</View>
    ) : undefined;

  return (
    <Animated.View style={{ width: widthAnim }}>
      <View style={styles.drawer}>{content}</View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  drawer: { backgroundColor: colors.agate, padding: 12, flex: 1 },
});

export default L2LandscapeDrawer;
