import React, { useContext } from 'react';
import { View, StyleSheet } from 'react-native';

import { colors } from 'sonora-design-system';

import {
  parseDbCategories,
  parseDbModifications,
} from 'sonora-util/curriculaUtil';
import {
  ModificationDetailView,
  ModificationCategory,
  Modification,
} from 'sonora-design-system/components/ModificationDetailView';
import { ModificationId } from 'sonora-design-system/components/ModificationDetailView/types';
import { DetailsViewProps } from 'sonora-design-system/components/ModificationNavigation/types';
import { ModificationsProviderContext } from './ModificationsProvider';
import { StudentContext, ProgressContext } from 'sonora-containers/index';
import { LearningActivity } from 'sonora-types';
import { ModalPresenterContext } from 'sonora-design-system/util/ModalProvider';
import { VimeoModal } from 'sonora-design-system/components/VimeoModal';

export const DetailsView: React.FC<DetailsViewProps> = ({
  route,
  navigation,
}) => {
  const { categoryId } = route.params;
  const {
    activeModificationsMap,
    setActiveModificationsMap,
    nodeId,
  } = useContext(ModificationsProviderContext);
  const { curriculum, curriculumMap } = useContext(StudentContext);
  const { presentComponentAsModal } = useContext(ModalPresenterContext);
  const { unlockedModificationsMap } = useContext(ProgressContext);

  if (curriculumMap[nodeId].type === 'Folder') {
    throw new Error('Node folder should never render category');
  }

  let node: LearningActivity = curriculumMap[nodeId] as LearningActivity;

  const allModifications = parseDbModifications(curriculum.modifications);
  const allCategories = parseDbCategories(curriculum.modificationCategories);

  // Make the not applicable for node modifications invisible
  let modifications = allModifications.filter((modification) =>
    Object.values(node.modifications!).flat().includes(modification.id)
  );

  // Lock the modifications if by progress
  modifications.forEach((modification) => {
    if (unlockedModificationsMap.modificationIds.includes(modification.id)) {
      modification.isLocked = false;
    } else {
      modification.isLocked = true;
    }
  });

  const category = allCategories.find(
    (c: ModificationCategory) => c.id === categoryId
  );

  if (!category) {
    return null;
  }

  // Get the modifications for the specific category
  modifications = category
    ? modifications.filter((m) => category.modifications.indexOf(m.id) >= 0)
    : [];

  // Put the unlocked modifications at the top
  modifications = [
    ...modifications.filter((m) => !m.isLocked),
    ...modifications.filter((m) => m.isLocked),
  ];

  const goBack = () => {
    navigation.pop();
  };

  const showVimeoModal = (m: Modification) => {
    if (m.vimeoId) {
      presentComponentAsModal(VimeoModal, {
        extraData: {
          vimeoId: m.vimeoId,
        },
        height: 'auto',
        minHeight: 'auto',
      });
    }
  };

  const handleSelectModification = (modificationId: ModificationId) => {
    let newMap = { ...activeModificationsMap, [categoryId]: modificationId };
    setActiveModificationsMap(newMap);
  };

  return (
    <View style={styles.container}>
      <ModificationDetailView
        selectedModificationId={activeModificationsMap[categoryId]}
        onSelectModification={handleSelectModification}
        onPlayModificationVideo={showVimeoModal}
        categoryLabel={category.title}
        vimeoId={category.vimeoId}
        description={category.description}
        modifications={modifications}
        onBack={goBack}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.agate,
    flex: 1,
  },
});
