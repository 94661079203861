import React from 'react';
import {
  IMessage,
  MessageText,
  MessageTextProps,
} from 'react-native-gifted-chat';
import { colors } from '../../../constants/theme';

const linkStyle = {
  color: colors.tanzanite,
};

export const RenderMessageText = (props: MessageTextProps<IMessage>) => (
  <MessageText
    {...props}
    customTextStyle={{
      marginLeft: 0,
      marginRight: 0,
    }}
    linkStyle={{
      left: linkStyle,
      right: linkStyle,
    }}
  />
);
