import React, { FC, useContext } from 'react';
import { View, Text, StyleSheet } from 'react-native';
// Must import from react-native-gesture-handler or it will break on Android
import { TouchableOpacity } from 'react-native-gesture-handler';

import { FillIcon } from '../Icon';
import { colors } from '../../constants/theme';
import { LayoutType, LayoutProviderContext } from '../../util/LayoutProvider';
import { ModalCardProps } from './types';

const ModalCard: FC<ModalCardProps> = (props) => {
  const { activeLayout } = useContext(LayoutProviderContext);
  const { width, height, minHeight, minWidth } = props;

  const dimensionStyle =
    activeLayout === LayoutType.Portrait
      ? styles.portraitDimensions
      : styles.landscapeDimensions;

  const widthStyle = width ? { width } : undefined;
  const heightStyle = height ? { height } : undefined;
  const minHeightStyle = minHeight ? { minHeight } : undefined;
  const minWidthStyle = minWidth ? { minWidth } : undefined;

  return (
    <View
      style={[
        styles.modalCard,
        dimensionStyle,
        widthStyle,
        heightStyle,
        minHeightStyle,
        minWidthStyle,
      ]}
    >
      {props.children}
      <View style={styles.floatTopRight}>
        <TouchableOpacity
          style={styles.row}
          onPress={() => props.dismissModal('Cancel')}
        >
          <Text style={styles.cancelText}>Cancel</Text>
          <FillIcon name="x" color={colors.white} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  cancelText: {
    color: colors.white,
    textTransform: 'uppercase',
    marginRight: 8,
  },
  modalCard: {
    position: 'relative',
    backgroundColor: colors.white,
  },
  portraitDimensions: {
    width: '95%',
    height: '90%',
  },
  landscapeDimensions: {
    width: '58%',
    height: '80%',
    minHeight: 500,
  },
  floatTopRight: {
    position: 'absolute',
    top: -32,
    right: 0,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default ModalCard;
