import React, { FC, useContext } from 'react';
import { Linking } from 'expo';
import { View, StyleSheet, Text, Image } from 'react-native';
import { DrawerContentComponentProps } from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import wordmarkSrc from 'sonora-design-system/assets/images/Wordmark.png';
import { UserContext } from 'sonora-containers/UserProvider';

import L1SidebarMenuItem from './L1SidebarMenuItem';
import { colors } from 'sonora-design-system';
import { useSignOut } from 'sonora-graphql/signInHooks';
import { appVersionString } from 'sonora-util/environment';
import { StudentContext } from 'sonora-containers/index';
import { Screen } from '../levels/L1Screen';

export interface L1SidebarContentProps extends DrawerContentComponentProps {
  screens: Screen[];
}

const L1SidebarContent: FC<L1SidebarContentProps> = (props) => {
  var isWeb = window &&
    window.location
  const { payment, user } = useContext(UserContext);
  const navigation = useNavigation();
  const signOut = useSignOut();
  const { hasUnreadMessages, studentType } = useContext(StudentContext);

  const handleSignOut = () => {
    signOut().then(() => navigation.navigate('AuthLoading'));
  };

  const gotoCommunity = () => {
    Linking.openURL('https://web.facebook.com/groups/316989302560703/');
  };

  const goToDiscord = () => {
    Linking.openURL('https://discord.gg/23cE3HfHDu');
  };

  const gotoReferral = () => {
    Linking.openURL('https://api.guitarmasteryintensive.com/referral_redirect/' + user.email);
  };

  const handleContactUs = () => {
    Linking.openURL(`mailto:contact@guitarmasteryintensive.com`);
  };

  let screens = props.screens;
  if (studentType == "LT") {
    screens = screens.filter((screen) => !screen.requiresHighTicket);
  }
  const hasFullAccess = !payment || (payment.hasCoreAccess && !payment.isInTrial)
  return (
    <View style={styles.sidebarContent}>
      <View>
        <View style={styles.logo}>
          <Image source={wordmarkSrc} style={{ width: 120, height: 20 }} />
        </View>
        {screens.map((screen, index) => {
          let iconName = screen.iconName;
          let hasBadge = Boolean(
            screen.name === 'MentorChat' && hasUnreadMessages
          );
          return (
            <View key={screen.name}>
              <L1SidebarMenuItem
                iconName={iconName}
                isStrokeIcon={screen.iconType === 'stroke'}
                hasBadge={hasBadge}
                label={screen.label}
                handlePress={() => props.navigation.navigate(screen.name)}
                afterIconName="caret"
                focused={props.state.index === index}
              />
              {screen.hasDivider && (
                <View style={styles.dividerContainer}>
                  <View style={styles.divider} />
                </View>
              )}
            </View>
          );
        })}
        {/* <L1SidebarMenuItem
          iconName="settings"
          label="Settings"
          handlePress={() => undefined}
        /> */}
      </View>
      <View style={styles.bottomMenu}>
        { hasFullAccess && (
          <>
            <L1SidebarMenuItem
              iconName="facebook"
              afterIconName="launch"
              isAfterIconFilled={true}
              label="Community"
              handlePress={gotoCommunity}
            />
            <L1SidebarMenuItem
              iconName="discord"
              afterIconName="launch"
              isAfterIconFilled={true}
              label="Discord"
              handlePress={goToDiscord}
            />
          </>
        )}

        {user.userType == "student" &&
          <L1SidebarMenuItem
            iconName="send"
            afterIconName="launch"
            isAfterIconFilled={true}
            label="Refer a Friend"
            handlePress={gotoReferral}
          />
        }

        <View style={styles.dividerContainer}>
          <View style={styles.divider} />
        </View>
        <L1SidebarMenuItem
          iconName="logOut"
          label="Sign out"
          handlePress={handleSignOut}
        />
        {!isWeb &&
          <L1SidebarMenuItem
            iconName="support"
            label="Contact Us"
            handlePress={handleContactUs}
          />
        }
        {/* <TouchableOpacity onPress={() => undefined}>
          <Text style={styles.link}>Privacy Policy</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => undefined}>
          <Text style={styles.link}>Terms & Conditions</Text>
        </TouchableOpacity> */}
        <Text style={styles.version}>{appVersionString()}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  dividerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 24,
  },
  divider: {
    // TODO (andrew): Check if this is a style guide color
    borderBottomColor: '#E3DCF1',
    borderBottomWidth: 1,
    width: '80%',
  },
  logo: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 36,
    marginBottom: 36,
  },
  sidebarContent: {
    flex: 1,
    justifyContent: 'space-between',
  },
  bottomMenu: { paddingBottom: 24 },
  link: {
    textTransform: 'uppercase',
    color: colors.tanzanite,
    paddingVertical: 12,
    paddingHorizontal: 24,
    textDecorationLine: 'underline',
  },
  version: {
    color: colors.tanzanite,
    paddingVertical: 24,
    paddingHorizontal: 24,
  },
});

export default L1SidebarContent;
