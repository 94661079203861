import React from 'react';
import { storiesOf } from '@storybook/react-native';
import { Image } from 'react-native';

import { Box } from '../styled';
import { LayoutProvider } from '../../util/LayoutProvider';
import { AnnouncementHeader } from '.';
import liveImage from '../../assets/images/live.png';

const announcementHeaderStories = storiesOf('AnnouncementHeader', module);

const AnnouncementHeaderAmethystStory = () => (
  <LayoutProvider>
    <Box padding={24}>
      <AnnouncementHeader
        {...{
          headerStyle: 'amethyst',
          headerLargeText: 'Live Group Class',
          headerSmallText: 'Wednesday’s @ 5:30pm PST',
          ctaButtonText: 'Watch Play',
          onPress: () => {
            console.log('Watch Play clicked');
          },
        }}
      />
    </Box>
  </LayoutProvider>
);

const AnnouncementHeaderRubyStory = () => (
  <LayoutProvider>
    <Box padding={24}>
      <AnnouncementHeader
        {...{
          headerStyle: 'ruby',
          headerLargeText: 'Live Group Class',
          headerSmallText: 'Wednesday’s @ 5:30pm PST',
          ctaButtonText: 'Watch',
          onPress: () => {
            console.log('Watch Play clicked');
          },
          specialComponent: (
            <Image source={liveImage} style={{ width: 63, height: 29 }} />
          ),
        }}
      />
    </Box>
  </LayoutProvider>
);

announcementHeaderStories.add('Amethyst', () => (
  <AnnouncementHeaderAmethystStory />
));
announcementHeaderStories.add('Ruby', () => <AnnouncementHeaderRubyStory />);
