import React from 'react';
import styled from 'styled-components/native';

import { Box } from '../styled';
import { sources } from '../Wordmark';
import { colors } from '../../constants/theme';
import { useIsMobileWidth } from '../../util';
import { AuthFormWrapperProps } from './types';

const Logo = styled.Image`
  ${(props: { isMobile?: boolean }) =>
    props.isMobile
      ? `
  width: 138px;
  height: 23px;
  `
      : `
  width: 233px;
  height: 39px;
  `}
`;

export const AuthFormWrapper: React.FC<AuthFormWrapperProps> = ({
  children,
}) => {
  const isMobile = useIsMobileWidth();
  const headerHeight = isMobile ? '84px' : '144px';
  const paddingX = isMobile ? 22 : 102;
  const paddingY = isMobile ? 36 : 62.5;

  return (
    <Box width="100%">
      <Box
        backgroundColor={colors.opal}
        height={headerHeight}
        alignItems="center"
        justifyContent="center"
      >
        <Logo
          source={sources['primary']}
          resizeMode="contain"
          isMobile={isMobile}
        />
      </Box>
      <Box
        alignItems="center"
        backgroundColor={colors.white}
        paddingX={paddingX}
        paddingY={paddingY}
      >
        {children}
      </Box>
    </Box>
  );
};
