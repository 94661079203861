import React, { useState } from 'react';
import { Image, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import { Video } from 'expo-av';

import { FillIcon } from '../Icon';
import { colors } from '../../constants/theme';

import { InlineVideoPlayerProps } from './types';

const InlineVideoPlayerContainer = styled.View`
  ${(props: Partial<InlineVideoPlayerProps>) =>
    props.width ? `width: ${props.width}px;` : ''}
  ${(props: Partial<InlineVideoPlayerProps>) =>
    props.height ? `height: ${props.height}px;` : ''}
  ${(
    props: Partial<InlineVideoPlayerProps>
  ) =>
    props.backgroundColor ? `background-color: ${props.backgroundColor};` : ''}
`;

const FloatingPlayIconContainer = styled.View`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
`;

const FloatingPlayIcon = () => (
  <FloatingPlayIconContainer>
    <FillIcon name="video" size="xl" color={colors.white} />
  </FloatingPlayIconContainer>
);

export const InlineVideoPlayer: React.FC<InlineVideoPlayerProps> = ({
  source,
  posterImage,
  width,
  height,
  backgroundColor,
}) => {
  const [doLoadVideo, setDoLoadVideo] = useState(false);
  const [dimension, setDimension] = useState<any>({ width: 0, height: 0 });

  const handleLayout = (ev: any) => {
    const { width, height } = ev.nativeEvent.layout;
    if (width <= 0) {
      return;
    }

    const actualHeight = height || (width * 9) / 16;
    setDimension({
      width,
      height: actualHeight,
    });
  };

  return (
    <InlineVideoPlayerContainer
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      onLayout={handleLayout}
    >
      {doLoadVideo ? (
        <Video
          source={{ uri: source }}
          isLooping={false}
          useNativeControls
          onError={(e) => console.error(e)}
          style={dimension}
          resizeMode="contain"
          shouldPlay
        />
      ) : (
        <TouchableOpacity onPress={() => setDoLoadVideo(true)}>
          <FloatingPlayIcon />
          <Image source={{ uri: posterImage }} style={dimension} />
        </TouchableOpacity>
      )}
    </InlineVideoPlayerContainer>
  );
};
