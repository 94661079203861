import styled from 'styled-components/native';

import { colors } from '../../constants/theme';

export const BottomBorder = styled.View`
  position: absolute;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${colors.borderline};
`;
