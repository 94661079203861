import React, { FC, useContext } from 'react';
import { Platform } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
const Stack = createStackNavigator();

import MobileSubmissionStart from '../MobileSubmissionStart';
import VideoSubmissionRating from '../VideoSubmissionRating';
import { ExerciseComplete } from '../ExerciseComplete';
import { WebVideoRecorder } from '../WebVideoRecorder';
import MobileSubmissionVideo from '../MobileSubmissionVideo';
import {
  ModalPresenterContext,
  DismissFunction,
} from 'sonora-design-system/util/ModalProvider';
import { MessageThreadProvider } from 'sonora-containers/index';
import { NodeId } from 'sonora-types';
import { VideoSubmissionDone } from './VideoSubmissionDone';

export interface VideoSubmissionStackProps {
  dismissModal: DismissFunction;
}
export interface VideoSubmissionStackExtraData {
  isLogProgressFlow: boolean;
  isPromptFlow?: boolean;
  startOnRatingScreen?: boolean;
  activityId: NodeId;
  videoSubject?: string;
  messageThreadId?: number;
}

export interface VideoSubmissionStackReturnData {
  starRating?: number;
  practicePost?: {
    messageThreadId: number;
    messageId: number;
    messageAttachmentId: number;
  };
}

export const VideoSubmissionStack: FC<VideoSubmissionStackProps> = (props) => {
  const { extraData } = useContext(ModalPresenterContext);
  const typedExtraData = extraData as VideoSubmissionStackExtraData;

  let initialRoute;
  if (typedExtraData.startOnRatingScreen) {
    initialRoute = 'VideoSubmissionRating';
  }

  const WebVideoSubmissionStack = (
    <Stack.Navigator
      initialRouteName={initialRoute}
      screenOptions={{ animationEnabled: false }}
    >
      <Stack.Screen name="WebVideoUpload" component={WebVideoRecorder} />
      <Stack.Screen
        name="VideoSubmissionRating"
        component={VideoSubmissionRating}
      />
      <Stack.Screen name="ExerciseComplete" component={ExerciseComplete} />
    </Stack.Navigator>
  );

  const MobileVideoSubmissionStack = (
    <Stack.Navigator
      initialRouteName={initialRoute}
      screenOptions={{ animationEnabled: false }}
    >
      <Stack.Screen
        name="MobileSubmissionStart"
        component={MobileSubmissionStart}
      />
      <Stack.Screen
        name="MobileSubmissionVideo"
        component={MobileSubmissionVideo}
      />
      <Stack.Screen
        name="VideoSubmissionRating"
        component={VideoSubmissionRating}
      />
      <Stack.Screen
        name="VideoSubmissionDone"
        component={VideoSubmissionDone}
      />
    </Stack.Navigator>
  );

  const SubmissionStack =
    Platform.OS === 'web'
      ? WebVideoSubmissionStack
      : MobileVideoSubmissionStack;

  return (
    <MessageThreadProvider
      messageThreadId={typedExtraData.messageThreadId}
      videoSubject={typedExtraData.videoSubject}
    >
      <NavigationContainer
        independent={true}
        documentTitle={{ formatter: () => 'Sonora' }}
      >
        {SubmissionStack}
      </NavigationContainer>
    </MessageThreadProvider>
  );
};
