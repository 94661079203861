import React, { FC, useContext, useEffect, useState } from 'react';

import { useCurrentUserQuery, UserFragment } from 'sonora-graphql/types';
import { handleApolloError } from 'sonora-services/analytics';
import { Loading } from 'sonora-design-system';
import {
  stripeFellowshipKeys,
  stripeCoreAccessKey,
  installmentsPriceId,
} from 'sonora-util/environment';
import {
  IStripePaymentMethod,
  IStripeSubscription,
  ITransactionHistory,
} from 'sonora-types';
import { L1NavigationContext } from './L1NavigationProvider';

export type AppUserType = 'mentor' | 'student';

declare var _hsq: any;

interface UserPaymentDetails {
  isInTrial: boolean;
  trialEndDate: Date | null;
  hasCoreAccess: boolean;
  hasFellowshipAccess: boolean;
  isFellowshipPaused: boolean;
  isPaymentFailed: boolean;
  fellowshipSubscription: IStripeSubscription | null;
  coreSubscription: IStripeSubscription | null;
  history: ITransactionHistory[];
  currentInstallment: number;
  paymentMethods: IStripePaymentMethod[];
  stripeCreateDate: Date;
}

interface UserContextState {
  user: UserFragment;
  userType: AppUserType;
  payment: UserPaymentDetails | null;
}

export const UserContext = React.createContext({} as UserContextState);

export const UserProvider: FC = (props) => {
  const { data, loading, error } = useCurrentUserQuery();
  const [payment, setPayment] = useState<UserPaymentDetails | null>(null);
  const { setActiveIndex } = useContext(L1NavigationContext);

  useEffect(() => {
    if(data?.user?.email){
      _hsq.push([
        'identify',
        {
          email: data?.user?.email,
          sonora_id: data?.user?.id
        },
      ]);
      _hsq.push(["trackEvent", {
        id: "Loaded App",
      }]);
    }
    if (!user?.stripeUserState) {
      return;
    }


    const stripeUserData = JSON.parse(
      user.stripeUserState.stripeUserData || '{}'
    );

    let isInTrial: boolean = false;
    let trialEndDate: Date | null = null;
    let hasCoreAccess: boolean = false;
    let hasFellowshipAccess: boolean = false;
    let isFellowshipPaused: boolean = false;
    let isPaymentFailed: boolean = false;
    let fellowshipSubscription: IStripeSubscription | null = null;
    let coreSubscription: IStripeSubscription | null = null;
    let stripeCreateDate: Date = new Date();

    let coreSubStartDate: Number = 0
    if(stripeUserData[0]?.created){
      stripeCreateDate = new Date(stripeUserData[0].created*1000)
    }
    stripeUserData[2].data.forEach((subscription: any) => {
      if (subscription.plan.product === stripeCoreAccessKey()) {
        if(subscription.created < coreSubStartDate) {
          return;
        }
        coreSubStartDate = subscription.created
        if (
          subscription.status === 'active' ||
          subscription.schedule?.status === 'completed'
        ) {
          hasCoreAccess = true;
        }
        if (subscription.status === 'trialing') {
          hasCoreAccess = true;
          isInTrial = true;
          trialEndDate = new Date(subscription.trial_end * 1000);
        }

        coreSubscription = {
          id: subscription.id,
          type:
            subscription.plan.id === installmentsPriceId()
              ? 'installments'
              : 'upfront',
          startDate: new Date(subscription.current_period_start * 1000),
          endDate: new Date(subscription.current_period_end * 1000),
          paused: false,
          cancelled: subscription.schedule?.status === 'cancelled',
          billingCycle: subscription.plan.interval_count,
          status: subscription.status,
          subScheduleId: subscription.schedule?.id || null,
          priceId: subscription.plan?.id,
          description: subscription.plan.nickname,
          billingAmount: subscription.plan.amount,
        };
      } else if (stripeFellowshipKeys().includes(subscription.plan.product)) {
        if (subscription.status === 'active') {
          hasFellowshipAccess = true;
        }
        if (subscription.pause_collection !== null) {
          isFellowshipPaused = true;
        }

        fellowshipSubscription = {
          id: subscription.id,
          type: 'premium',
          startDate: new Date(subscription.current_period_start * 1000),
          endDate: new Date(subscription.current_period_end * 1000),
          paused: false,
          cancelled: subscription.status === 'cancelled',
          billingCycle: subscription.plan.interval_count,
          status: subscription.status,
          subScheduleId: subscription.schedule?.id || null,
          priceId: subscription.plan?.id,
          description: subscription.plan.nickname,
          billingAmount: subscription.plan.amount,
        };
      }
      if (
        subscription.status === 'past_due' ||
        subscription.status === 'incomplete'
      ) {
        isPaymentFailed = true;
      }
    });

    const failedCardIds: string[] = [];
    const history: ITransactionHistory[] = stripeUserData[1].data
      .filter((charge: any) => charge.invoice)
      .map((charge: any) => {
        const subscription = stripeUserData[2].data.find(
          (s: any) => s.id === charge.invoice.subscription
        );

        if (charge.status === 'failed') {
          failedCardIds.push(charge.payment_method);
        }

        return {
          id: charge.id,
          type: stripeFellowshipKeys().includes(subscription.plan.product)
            ? 'premium'
            : subscription.plan.id === installmentsPriceId()
            ? 'installments'
            : 'upfront',
          date: new Date(charge.created * 1000),
          billingCycle: subscription.plan.interval_count,
          billingAmount: subscription.plan.amount,
          status: charge.status,
          failure_message: charge.failure_message || ''

        };
      }
    );
    history.sort((a, b) => b.date.getTime() - a.date.getTime());
    const installmentsHistory = history.filter(
      (t) => t.type === 'installments'
    );
    installmentsHistory.forEach((t, i) => {
      t.currentInstallment = installmentsHistory.length - i;
    });

    const paymentMethods: IStripePaymentMethod[] = stripeUserData[0].sources.data.map(
      (card: any) => ({
        id: card.id,
        last4: card.last4,
        brand: card.brand,
        expYear: card.exp_year,
        expMonth: card.exp_month,
        failed: failedCardIds.includes(card.id),
        isDefault: card.id === stripeUserData[0].default_source,
      })
    );

    if (hasCoreAccess && userType != "mentor") {
      setActiveIndex(1);
    }

    setPayment({
      isInTrial,
      trialEndDate,
      hasCoreAccess,
      hasFellowshipAccess,
      isFellowshipPaused,
      isPaymentFailed,
      fellowshipSubscription,
      coreSubscription,
      history,
      currentInstallment: installmentsHistory.length,
      paymentMethods,
      stripeCreateDate,
    });
  }, [data?.user]);

  if (loading) return <Loading text="UserProvider" />;
  if (!data || !data.user) throw new Error('user provider query bad data');
  if (error) handleApolloError(error);

  const user = data.user;
  const userType = data.user.userType === 'mentor' ? 'mentor' : 'student';

  return (
    <UserContext.Provider
      value={{
        user,
        userType,
        payment,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
