import React, { useContext } from 'react';

import {
  Box,
  CourseMapHeader,
  CourseMapList,
  Scrollable,
  colors,
  theme,
  useIsLandscape,
  ModalPresenterContext,
} from 'sonora-design-system';
import { LearningFolder, LearningActivity, NodeId } from 'sonora-types';
import {
  courseMapFolderProps,
  allActivitiesInFolder,
  isNodeCompleted,
} from 'sonora-util/curriculaUtil';
import { RootNavScreen } from '../screens';
import { LockModal } from './LockModal';
import { ProgressContext, StudentContext } from 'sonora-containers/index';
import { useTrackEventOnMount } from 'sonora-hooks/useTrackEventOnMount';

export const StudentCourseMapDetails: RootNavScreen<'CourseMapDetails'> = ({
  route,
  navigation,
}) => {
  const { presentComponentAsModal } = useContext(ModalPresenterContext);
  const { progress: studentProgress, unlockedNodes } = useContext(
    ProgressContext
  );
  const { folderId } = route.params;
  const { curriculum } = useContext(StudentContext);
  useTrackEventOnMount('mount-course-map-details', { folderId });
  const folder: LearningFolder = curriculum.contents.find(
    (folder: LearningFolder) => folder.id === folderId
  ) as LearningFolder;

  const allActivities = allActivitiesInFolder(folder);
  const completedNodeIds = allActivities
    .filter(isNodeCompleted(studentProgress))
    .map((activity: LearningActivity) => activity.id);
  const allStars = allActivities.reduce(
    (sum, activity) => sum + activity.maxStars,
    0
  );
  const unlockedStars = allActivities.reduce((sum, activity) => {
    const activityProgress = studentProgress[activity.id] || {
      currentStars: 0,
    };
    return sum + (activityProgress.currentStars > 5 ? 5 : activityProgress.currentStars);
  }, 0);

  const folderProps = courseMapFolderProps(
    folder,
    studentProgress,
    completedNodeIds
  );

  const isLandscape = useIsLandscape();
  const levelPadding = isLandscape ? theme.coursemap.levelPadding : 0;
  const loadActivity = (id: NodeId) => {
    if (unlockedNodes.includes(id)) {
      navigation.navigate('L2', { id });
    } else {
      presentComponentAsModal(LockModal, { extraData: { nodeId: id } });
    }
  };

  return (
    <Box backgroundColor={colors.white} flex={1} alignItems="center">
      <CourseMapHeader
        title={folder.name}
        studentInfo={{
          numCompletedModules: folderProps.numCompletedLessons,
          numAllModules: folderProps.numAllLessons,
          numUnlockedStars: unlockedStars,
          numAllStars: allStars,
          completePercent: folderProps.completePercent,
        }}
        backgroundColor={colors.white}
        progressBackgroundColor={colors.purpley}
        textColor={colors.tanzanite}
        hasBorder={true}
        titleColor={colors.tanzanite}
        hasBackButton
        onBack={() => {
          navigation.goBack();
        }}
      />
      <Box flex={1} width="100%" maxWidth={804} alignItems="center">
        <Box maxWidth={548} width="100%" flex={1}>
          <Scrollable padding={24}>
            <CourseMapList
              items={folder.children}
              unlockedNodeIds={unlockedNodes}
              progress={studentProgress}
              levelPadding={levelPadding}
              onItemPress={loadActivity}
            />
          </Scrollable>
        </Box>
      </Box>
    </Box>
  );
};
