import React from 'react';
import styled from 'styled-components/native';
import { LinearGradient } from 'expo-linear-gradient';
import { Box, Text } from '../styled';
import { colors } from '../../constants/theme';
import { GradientCircleProps } from './types';

const GradientBackground = styled(LinearGradient)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: ${(props: { size: number }) => props.size / 2}px;
  z-index: 0;
`;

export const GradientCircle: React.FC<GradientCircleProps> = ({
  letter,
  size,
}) => (
  <Box position="relative" width={size + 'px'} height={size + 'px'}>
    <GradientBackground
      size={size}
      colors={[colors.tanzanite, colors.ruby]}
      start={{ x: 0, y: 1 }}
      end={{ x: 1, y: 1 }}
    />
    <Box
      zIndex={1}
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
    >
      <Text color={colors.white} size={24}>
        {letter}
      </Text>
    </Box>
  </Box>
);
