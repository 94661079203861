import React from 'react';
import { storiesOf } from '@storybook/react-native';
import { Loading } from '.';
import { View } from 'react-native';

const loadingStories = storiesOf('Loading', module);

loadingStories.add('default', () => (
  <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
    <Loading />
  </View>
));

loadingStories.add('custom text', () => (
  <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
    <Loading text="something custom" />
  </View>
));
