import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { Billing } from './Billing';
// import { Profile } from './Profile';
import { colors } from 'sonora-design-system';

const Tab = createMaterialTopTabNavigator();

const AccountScreens = [
  // {
  //   name: 'Profile',
  //   component: Profile,
  // },
  {
    name: 'Account',
    component: Billing,
  },
];

export const AccountTabNavigator = () => {
  return (
    <Tab.Navigator
      tabBarOptions={{
        activeTintColor: colors.amethyst,
        inactiveTintColor: colors.tanzanite,
        indicatorStyle: {
          backgroundColor: colors.amethyst,
        },
        indicatorContainerStyle: {
          marginHorizontal: 'auto',
        },
        tabStyle: {
          width: 150,
        },
        labelStyle: {
          fontSize: 14,
          fontWeight: '500',
        },
        contentContainerStyle: {
          marginHorizontal: 'auto',
          width: 300,
          flexBasis: 'auto',
          flexGrow: 0,
        },
      }}
    >
      {AccountScreens.map((screen) => (
        <Tab.Screen
          key={screen.name}
          name={screen.name}
          component={screen.component}
        />
      ))}
    </Tab.Navigator>
  );
};
