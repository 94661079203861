import React, { useContext } from 'react';
import { RootNavScreen } from './screens';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { LayoutProviderContext, LayoutType } from 'sonora-design-system';
import { TaskList } from 'sonora-design-system/components/TaskList';
import { Task } from 'sonora-types';

import { L2NavigationContext } from '../levels/L2NavigationProvider';
import { TaskListContext } from 'sonora-containers/TaskListProvider';

export const TaskListScreen: RootNavScreen<'TaskList'> = ({ route }) => {
  const navigation = useNavigation();

  // const numCompletedToShow = 5;
  // const numUpcomingToShow = 5;

  const {
    completedTasks,
    availableTasks,
    lockedTasks,
    currentTaskId,
    totalReviewCount
  } = useContext(TaskListContext);

  const { setActiveIndex } = useContext(L2NavigationContext);
  const { activeLayout } = useContext(LayoutProviderContext);
  const hideIconDirection =
    activeLayout === LayoutType.Portrait ? 'down' : 'left';

  const handleSelectItem = (task: Task) => {
    if (activeLayout === LayoutType.Portrait) {
      navigation.setParams({ id: task.practiceNodeId, activeIndex: '' });
    } else {
      navigation.setParams({ id: task.practiceNodeId });
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <TaskList
        completedTasks={completedTasks}
        lockedTasks={lockedTasks}
        currentTasks={availableTasks}
        hasScroll={true}
        totalReviewCount={totalReviewCount}
        currentTaskId={currentTaskId}
        hideIconDirection={hideIconDirection}
        onSelectItem={handleSelectItem}
        onHide={() => setActiveIndex(undefined)}
      />
    </View>
  );
};
