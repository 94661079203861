import { RefObject } from 'react';
import { Platform } from 'react-native';
import { default as WebView } from 'react-native-webview';

import { VimeoWeb } from './Vimeo';
import { VimeoMobile } from './VimeoMobile';

export const stopAudioVideo = (
  vimeoRef: RefObject<HTMLIFrameElement | WebView>
) => {
  const data = { method: 'pause' };
  if (vimeoRef && vimeoRef.current) {
    if (Platform.OS === 'web') {
      const current = vimeoRef.current as HTMLIFrameElement;
      if (current.contentWindow) {
        current.contentWindow.postMessage(
          JSON.stringify(data),
          'https://player.vimeo.com'
        );
      }
    } else {
      const current = vimeoRef.current as WebView;
      const generateOnMessageFunction = (data: any) =>
        `(function() {
  window.dispatchEvent(new MessageEvent('message', {data: ${JSON.stringify(
    data
  )}}));
})()`;
      current.injectJavaScript(generateOnMessageFunction(data));
    }
  }
};

export const VimeoPlayer = Platform.OS === 'web' ? VimeoWeb : VimeoMobile;
