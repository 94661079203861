import React from 'react';

import { Text, Box } from '../styled';
import { Button } from '../Button';
import { FillIcon } from '../Icon';
import { fonts, colors } from '../../constants/theme';
import { AuthFormWrapper } from './AuthFormWrapper';
import { ForgotPasswordSuccessProps } from './types';

export const ForgotPasswordSuccess: React.FC<ForgotPasswordSuccessProps> = ({
  email,
  onBackToLogin,
}) => {
  return (
    <AuthFormWrapper>
      <Text font={fonts.bold} size={20} marginBottom={12}>
        Success! Check your inbox
      </Text>
      <Text font={fonts.thin} size={20} marginBottom={4} textAlign="center">
        Reset instructions were sent to:
      </Text>
      <Text font={fonts.thin} size={20} marginBottom={36} textAlign="center">
        {email}
      </Text>
      <Box alignItems="center" marginBottom={36}>
        <FillIcon name="checkCircle" color={colors.validGreen} size="xl" />
      </Box>
      <Box marginBottom={36}>
        <Button type="Primary" text="Back to Sign In" onPress={onBackToLogin} />
      </Box>
    </AuthFormWrapper>
  );
};
