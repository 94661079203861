import React from 'react';
import { View, Platform } from 'react-native';
import { WebView as WebViewMobile } from 'react-native-webview';
import { default as WebViewWeb } from 'react-native-web-webview';

const WebView = Platform.OS === 'web' ? WebViewWeb : WebViewMobile;

interface L3SupplementaryMaterialsProps {
  url: string;
}

export const L3SupplementaryMaterials: React.FC<L3SupplementaryMaterialsProps> = (
  props
) => {
  /*
  This should render a webview with the URL
  */

  return (
    <View style={{ flex: 1, height: 500 }}>
      <WebView
        style={{ flex: 1 }}
        source={{
          uri: props.url,
        }}
      />
    </View>
  );
};
