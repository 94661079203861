import React, { useContext, useState } from 'react';
import { LayoutProviderContext, LayoutType } from 'sonora-design-system';
import { OverviewLandscape } from './OverviewLandscape';
import { OverviewPortrait } from './OverviewPortrait';

import { useNavigation, useFocusEffect } from '@react-navigation/native';

import { Task, LearningActivity, LearningFolder } from 'sonora-types';
import { updatedAnnouncementHeaderProps } from 'sonora-services/livestreamAnnouncement';
import {
  TaskListContext,
  StudentContext,
  ProgressContext,
  DateContext,
} from 'sonora-containers/index';
import { UserContext } from 'sonora-containers/UserProvider';
import {
  allActivitiesInFolder,
  allCurriculumActivities,
  isNodeCompleted,
} from 'sonora-util/curriculaUtil';
import { useTrackEventOnMount } from 'sonora-hooks/useTrackEventOnMount';
import { useWelcomeModal } from 'sonora-hooks/useWelcomeModal';

const MINUTES = 60 * 1000;

export const StudentOverviewScreen: React.FC = () => {
  const { todayDate } = useContext(DateContext);

  const { streakCount, practicedDates, progress: studentProgress } = useContext(
    ProgressContext
  );
  const { payment } = useContext(UserContext);
  useTrackEventOnMount('mount-L1-overview', { todayDate, streakCount });
  const numCompletedToShow = 5;
  const numUpcomingToShow = 5;

  const {
    completedTasks,
    availableTasks,
    totalReviewCount,
    lockedTasks,
    currentTaskId,
  } = useContext(TaskListContext);

  const navigation = useNavigation();
  const { activeLayout } = useContext(LayoutProviderContext);
  const { curriculum } = useContext(StudentContext);
  
  useFocusEffect(() => {
    if(payment && !payment?.hasCoreAccess){
      navigation.navigate('Account');
    }
  })

  const isLandscape = activeLayout === LayoutType.Landscape;
  
  const OverviewScreen = isLandscape ? OverviewLandscape : OverviewPortrait;

  const onSelectItem = (task: Task) => {
    navigation.navigate('L2', { id: task.practiceNodeId });
  };

  const allActivities = allCurriculumActivities(curriculum);

  const completedNodeIds = allActivities
    .filter(isNodeCompleted(studentProgress))
    .map((activity: LearningActivity) => activity.id);

  const completedFolders = curriculum.contents.filter(
    (folder: LearningFolder) =>
      allActivitiesInFolder(folder).every((activity) =>
        completedNodeIds.includes(activity.id)
      )
  );

  const completePercent = Math.round(
    (completedNodeIds.length * 100) / allActivities.length
  );

  const allStars = allActivities.reduce(
    (sum, activity) => sum + activity.maxStars,
    0
  );

  const unlockedStars = allActivities.reduce((sum, activity) => {
    const activityProgress = studentProgress[activity.id] || {
      currentStars: 0,
    };
    return sum + (activityProgress.currentStars > 5 ? 5 : activityProgress.currentStars);
  }, 0);

  const [announcementHeaderProps, setAnnouncementHeaderProps] = useState(
    updatedAnnouncementHeaderProps(isLandscape)
  );
  const shouldShow = completedTasks.length == 0
  useWelcomeModal(shouldShow);

  setInterval(() => {
    setAnnouncementHeaderProps(
      updatedAnnouncementHeaderProps(isLandscape)
    );
  }, 10 * MINUTES);
  
  

  return (
    <>
      <OverviewScreen
        announcementHeaderProps={announcementHeaderProps}
        progressProps={{
          title: 'Your progress',
          numAllModules: curriculum.contents.length,
          numCompletedModules: completedFolders.length,
          numAllStars: allStars,
          numUnlockedStars: unlockedStars,
          completePercent,
        }}
        calendarProps={{
          streakDayCount: streakCount,
          highlightedDays: practicedDates,
        }}
        taskListProps={{
          numCompletedToShow,
          numUpcomingToShow,
          completedTasks,
          totalReviewCount,
          currentTasks: availableTasks,
          currentTaskId,
          lockedTasks,
          onSelectItem,
        }}
      />
    </>
  );
};
