import { trackEvent } from 'sonora-services/analytics';

export interface File {
  uri: string;
  name: string;
  type: string;
}

export type ProgressEventHandler = (ev: ProgressEvent<EventTarget>) => void;

export interface UploadFileOptions {
  presignedUrl: string;
  fields: any;
  file: File;
  onProgress?: ProgressEventHandler;
}
export async function uploadFileToS3({
  presignedUrl,
  fields,
  file,
  onProgress,
}: UploadFileOptions) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    Object.keys(fields).forEach((key) => {
      formData.append(key, fields[key]);
    });

    formData.append('file', file as any);

    const xhr = new XMLHttpRequest();

    if (onProgress) {
      xhr.upload.addEventListener('progress', onProgress);
    }

    xhr.open('POST', presignedUrl, true);
    trackEvent('s3-initiate-upload', { presignedUrl });
    xhr.onloadend = () => {
      trackEvent('s3-load-end', {
        status: xhr.status,
        responseText: xhr.responseText,
      });
      if (xhr.status === 200 || xhr.status === 204) {
        resolve(xhr.responseText);
      }
      reject(
        new Error(
          `Request failed. Status: ${xhr.status}. Content: ${xhr.responseText}`
        )
      );
    };

    xhr.send(formData);
  });
}
