import React, { FC, useContext, useEffect, useState } from 'react';
import {
  Task,
  TaskId,
  Curriculum,
  NodeId,
  Node,
  LearningActivity,
  LearningFolder,
} from 'sonora-types';
import { StudentContext } from './StudentProvider';
import { LogProgressFn, ProgressContext } from './ProgressProvider';
import { DateContext } from './DateProvider';

import { generateReviewItems } from 'sonora-util/curriculaUtil';
import { shortYearDatestring } from 'sonora-util/dateUtils';
import { UserContext } from './UserProvider';

export interface TaskListProviderProps {}
type AddReviewItemsFn = (maxItems: number) => number;

interface TaskListContextState {
  completedTasks: Task[];
  availableTasks: Task[];
  lockedTasks: Task[];
  totalReviewCount: number;
  currentTaskId: TaskId | undefined;
  setCurrentTaskId: (_: TaskId | undefined) => void;
  /**
   * Adds review items to task list and returns number of items added
   */
  addReviewItems: AddReviewItemsFn;
  smartLogProgress: LogProgressFn;
}

export const TaskListContext = React.createContext<TaskListContextState>(
  {} as TaskListContextState
);

export const TaskListProvider: FC<TaskListProviderProps> = (props) => {
  const { userType } = useContext(UserContext);
  if (userType === 'mentor') return <>{props.children}</>;

  const { curriculum, curriculumMap } = useContext(StudentContext);
  const { completedNodes, logProgress, progress } = useContext(ProgressContext);
  const { todayDate } = useContext(DateContext);
  const [currentTaskId, setCurrentTaskId] = useState<NodeId | undefined>(
    undefined
  );
  const [reviewDate, setReviewDate] = useState<Date | null>(null);
  const [reviewItems, setReviewItems] = useState<Task[]>([]);
  const [currentTasks, setCurrentTasks] = useState<Task[]>([]);

  /**
   * takes current progressMap (practiced nodes), and generates review items,
   * then prioritizes and adds to queue
   * @param maxItems  maximum number of review items to add to
   */
  const addReviewItems: AddReviewItemsFn = (maxItems) => {
    const reviewItems = generateReviewItems({
      progress,
      todayDate,
      curriculumMap,
    }).slice(0, maxItems - 1);

    let addedCount = 0;
    let reviewTasks: Task[] = [];
    reviewItems.forEach((item) => {
      if (addedCount >= maxItems) return;

      reviewTasks.push(
        activityToTask({
          node: curriculumMap[item.activityId],
          isReview: true,
          currentStars: item.currentStars,
          lastPracticed: item.lastPracticed,
        })
      );
      addedCount++;
    });
    setReviewItems(reviewTasks);
    return addedCount;
  };

  useEffect(() => {
    const hasProgress = Object.entries(progress).length > 0;
    if (hasProgress && (!reviewDate || reviewDate !== todayDate)) {
      addReviewItems(5);
      setReviewDate(todayDate);
    }
  }, [progress, todayDate, reviewDate]);

  const availableNodeIds = unlockedTasks(curriculum, completedNodes);
  const lockedNodeIds = upcomingLockedTasks(curriculum, completedNodes);

  // when new available nodes come in, push them to the end of the task list
  // if they aren't already there
  const availableHash = availableNodeIds.join('');
  const reviewHash = reviewItems.reduce(
    (acc, item) => acc + item.practiceNodeId,
    ''
  );

  const onlyAvailableTasks = (task: Task) =>
    availableNodeIds.includes(task.practiceNodeId);

  useEffect(() => {
    const copyCurrent = reviewItems.concat(
      currentTasks.filter(onlyAvailableTasks)
    );

    availableNodeIds.forEach((id) => {
      const alreadyInList = copyCurrent.some(
        (task) => task.practiceNodeId === id
      );
      if (!alreadyInList) {
        const newTask = activityToTask({
          node: curriculumMap[id],
          isCompleted: false,
          currentStars: progress[id] ? progress[id].currentStars : 0,
        });

        copyCurrent.push(newTask);
      }
    });

    setCurrentTasks(copyCurrent);
  }, [availableHash, reviewHash]);

  const completedTasks: Task[] = completedNodes.map((id) => {
    if (!curriculumMap[id]) throw new Error(`node ${id} not in curriculum`);
    if (!progress[id])
      throw new Error(`completed task ${id} is missing progress data`);

    return activityToTask({
      node: curriculumMap[id],
      isCompleted: true,
      currentStars: progress[id].currentStars,
    });
  });
  const completedTasksSorted = completedTasks;

  const skipTask = (nodeId: NodeId) => {
    const index = availableNodeIds.indexOf(nodeId);
    if (index < 0) return;
    // availableNodeIds.splice(index);
    console.log('skipping ide', nodeId);

    smartLogProgress({ nodeId, stars: 0 });
  };

  const smartLogProgress: LogProgressFn = ({ nodeId, stars }) => {
    return new Promise((resolve, reject) => {
      logProgress({ nodeId, stars })
        .then((result) => {
          // remove practiced node from review items
          const updatedReview = reviewItems.filter(
            (task) => task.practiceNodeId !== nodeId
          );
          setReviewItems(updatedReview);
          // move node in completed list to most recent
          const index = completedNodes.findIndex((id) => id === nodeId);
          if (index >= 0) {
            completedNodes.splice(index);
            completedNodes.push(nodeId);
          }
          resolve(result);
        })
        .catch((err) => reject(err));
    });
  };

  // const availableTasks: Task[] = availableNodeIds
  //   .map((id) =>
  //     activityToTask({
  //       node: curriculumMap[id],
  //       isReview: false,
  //       currentStars: !!progress[id] ? progress[id].currentStars : 0,
  //     })
  //   )
  // .map((task) => ({
  //   ...task,
  //   onSkip:
  //     task.headerType === 'Optional'
  //       ? () => skipTask(task.practiceNodeId)
  //       : undefined,
  // }));

  const availableTasks = currentTasks.map((task) => ({
    ...task,
    onSkip:
      task.headerType === 'Optional'
        ? () => skipTask(task.practiceNodeId)
        : undefined,
  }));
  const totalReviewCount = generateReviewItems({
    progress,
    todayDate,
    curriculumMap,
  }).length

  const lockedTasks: Task[] = lockedNodeIds.map((id) =>
    activityToTask({ node: curriculumMap[id] })
  );

  return (
    <TaskListContext.Provider
      value={{
        completedTasks: completedTasksSorted,
        availableTasks,
        lockedTasks,
        totalReviewCount,
        currentTaskId,
        setCurrentTaskId,
        addReviewItems,
        smartLogProgress,
      }}
    >
      {props.children}
    </TaskListContext.Provider>
  );
};

// TODO: @MARC MOVE ME SOMEWHERE USEFUL
type ActivityToTaskArgs = {
  node: LearningActivity | LearningFolder;
  currentStars?: number;
  lastPracticed?: Date;
  isReview?: boolean;
  isCompleted?: boolean;
};
const activityToTask = ({
  node,
  lastPracticed,
  currentStars = 0,
  isReview = false,
  isCompleted = false,
}: ActivityToTaskArgs): Task => {
  if (node.type === 'Folder') throw new Error('cannot be called on Folder');

  return {
    practiceNodeId: node.id,
    taskType: node.assignmentType,
    headerType: isReview
      ? 'Review'
      : node.optional && !isCompleted
      ? 'Optional'
      : 'None',
    title: node.name,
    currStars: currentStars,
    maxStars: node.maxStars,
    lastPracticedString: lastPracticed
      ? shortYearDatestring(lastPracticed)
      : undefined,
  };
};

interface NextPossibleTasksArgs {
  curriculum: Curriculum;
  completedNodes: NodeId[];
  taskAvailability: 'unlocked' | 'locked' | 'all';
  maxTaskCount?: number;
}
export const nextPossibleTasks = ({
  curriculum,
  completedNodes,
  maxTaskCount = 9999,
  taskAvailability,
}: NextPossibleTasksArgs): NodeId[] => {
  const lockedTasks: NodeId[] = [];

  /**
   * Recursively add tasks (push to lockedTasks)
   */
  const addTasks = (node: Node) => {
    if (lockedTasks.length >= maxTaskCount) return;
    // now check out the children
    if (node.type === 'Folder' && node.children)
      node.children.forEach(addTasks);
    if (node.type === 'Activity') {
      if (!completedNodes.includes(node.id)) {
        // this node has not been practiced yet
        const required_nodes = node.requiredNodeIds || [];
        const isUnlocked = required_nodes.every((prerequisiteId) =>
          completedNodes.includes(prerequisiteId)
        );
        const isLocked = !isUnlocked;
        if (
          taskAvailability === 'all' ||
          (taskAvailability === 'unlocked' && isUnlocked) ||
          (taskAvailability === 'locked' && isLocked)
        ) {
          // and every pre-requisite has been completed.
          // so add it to the tasks queue
          //   console.log(`pushing ${node.title} (id ${node.id})`);
          lockedTasks.push(node.id);
        }
      }
    }
  };
  curriculum.contents.forEach(addTasks);

  return lockedTasks;
};

export const unlockedTasks = (
  curriculum: Curriculum,
  completedNodes: NodeId[]
) =>
  nextPossibleTasks({
    curriculum,
    completedNodes,
    taskAvailability: 'unlocked',
    maxTaskCount: 5,
  });

export const upcomingLockedTasks = (
  curriculum: Curriculum,
  completedNodes: NodeId[]
) =>
  nextPossibleTasks({
    curriculum,
    completedNodes,
    taskAvailability: 'locked',
    maxTaskCount: 20,
  });
