import React from 'react';
import { storiesOf } from '@storybook/react-native';

import { Box } from '../styled';
import { NiceWork } from '.';
import { colors } from '../../constants/theme';
import { fiveFormsTasks } from '../TaskList/mock-data';

const niceWorkStories = storiesOf('NiceWork', module);

const NiceWorkStory = () => (
  <Box padding={24} backgroundColor={colors.agate}>
    <NiceWork
      studentName="Micah"
      onHomePress={() => console.log('pressed home')}
      nextActivity={fiveFormsTasks.completedTasks[0]}
      noTaskMessage="Celebrate! Nothing remaining to practice today"
      onCtaPress={() => {
        console.log("let's go!");
      }}
    />
  </Box>
);

niceWorkStories.add('default', NiceWorkStory);
