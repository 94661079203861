import { Notifications } from 'expo';
import * as Permissions from 'expo-permissions';
import { useSetPushTokenMutation } from '../graphql/types';
import { Platform } from '@unimodules/core';
import { trackEvent } from 'sonora-services/analytics';

export const PRACTICE_REMINDER_CATEGORY = 'practiceReminder';
export const PRACTICE_REMINDER = 'practiceReminder';

if (Platform.OS !== 'web')
  // Notifications disabled in web for now.
  Notifications.createCategoryAsync(PRACTICE_REMINDER_CATEGORY, [
    {
      actionId: PRACTICE_REMINDER,
      buttonTitle: 'Start a practice session',
    },
  ]);

export const useRegisterForPushNotifications = () => {
  // 2020-03-11 Web push not yet implemented in Expo
  // https://github.com/expo/expo-cli/issues/1316
  if (Platform.OS === 'web') return () => void 0;

  const [setPushToken] = useSetPushTokenMutation();

  const registerForPushNotifications = async (): Promise<
    string | undefined
  > => {
    const { status: existingStatus } = await Permissions.getAsync(
      Permissions.NOTIFICATIONS
    );
    let finalStatus = existingStatus;

    // only ask if permissions have not already been determined, because
    // iOS won't necessarily prompt the user a second time.
    if (existingStatus !== 'granted') {
      // Android remote notification permissions are granted during the app
      // install, so this will only ask on iOS
      const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
      finalStatus = status;
    }

    // Stop here if the user did not grant permissions
    if (finalStatus !== 'granted') {
      trackEvent('push-notification-refused');
      return undefined;
    }

    // Get the token that uniquely identifies this device
    let token = await Notifications.getExpoPushTokenAsync();
    trackEvent('push-notification-token-created');
    setPushToken({ variables: { token } });

    return token;
  };

  return registerForPushNotifications;
};
