import React from 'react';
import { Image, ImageSourcePropType } from 'react-native';

interface LogoProps {
  variant?: 'primary' | 'ruby' | 'amethyst' | 'white' | 'black';
  // todo: s/m/l props that set Svg width and height
}

const sources: { [key: string]: ImageSourcePropType } = {
  primary: require('../../assets/logo/primary.png'),
  ruby: require('../../assets/logo/ruby.png'),
  amethyst: require('../../assets/logo/amethyst.png'),
  white: require('../../assets/logo/white.png'),
  black: require('../../assets/logo/black.png'),
};

export const Logo: React.FC<LogoProps> = ({ variant = 'primary' }) => (
  <Image style={{ width: 100, height: 100 }} source={sources[variant]} />
);
