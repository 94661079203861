// author: @fullstackdevs14
import React from 'react';
import { TouchableWithoutFeedback, Platform } from 'react-native';
import { useHover, useActive } from 'react-native-web-hooks';
import styled from 'styled-components/native';
import { trackButtonPress } from '../../../src/services/analytics';

import { theme, colors } from '../../constants/theme';
import {
  ButtonProps,
  ButtonWrapperProps,
  ButtonTextWrapperProps,
  ButtonTextProps,
  ButtonState,
  ButtonType,
} from './types';
import {
  ctaBackgroundColors,
  ctaBorderColors,
  ctaShadowColors,
  ctaTextColors,
} from './consts';
import { FillIcon, StrokeIcon } from '../Icon';

const ButtonWrapper: React.FC<ButtonWrapperProps> = styled.View`
  border-radius: ${theme.common.borderRadius};
  padding-horizontal: ${(props: ButtonWrapperProps) =>
    props.borderColor
      ? theme.button.paddingHorizontal - 1
      : theme.button.paddingHorizontal}px;
  padding-vertical: ${(props: ButtonWrapperProps) =>
    props.borderColor
      ? theme.button.paddingVertical - 1
      : theme.button.paddingVertical};
  justify-content: center;
  background-color: ${(props: ButtonWrapperProps) => props.backgroundColor};
  ${(props: ButtonWrapperProps) =>
    props.borderColor ? `border: 1px solid ${props.borderColor};` : ''}
  ${(props: ButtonWrapperProps) =>
    props.shadowColor
      ? `
    shadow-opacity: 1;
    shadow-radius: 0;
    shadow-offset: -3px 3px;
    shadow-color: ${props.shadowColor};
    `
      : ''}
    ${(props: ButtonWrapperProps) =>
    props.disabled && Platform.OS === 'web' ? 'cursor: not-allowed;' : ''}
`;

const ButtonBorderOverlay: React.FC<{ color?: string }> = styled.View`
  position: absolute;
  z-index: 3;
  border-radius: 4px;
  border-width: 2px;
  border-color: ${(props: { color?: string }) => props.color};
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const ButtonImageOverlay = styled.Image`
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  width: null;
  height: null;
`;

// For Text underline
const ButtonTextWrapper: React.FC<ButtonTextWrapperProps> = styled.View`
  margin-top: -1px;
  ${(props: ButtonTextWrapperProps) =>
    props.underline ? 'border-bottom-width: 1px;' : ''}
  border-bottom-color: ${(props: ButtonTextWrapperProps) =>
    props.color || 'white'};
  z-index: 2;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ButtonText = styled.Text`
  font-size: ${(props: ButtonTextProps) =>
    props.size === 'small'
      ? theme.button.fontSizeSmall
      : theme.button.fontSize};
  line-height: ${theme.button.lineHeight};
  font-family: ${theme.button.fontFamily};
  letter-spacing: 1.5px;
  color: ${(props: ButtonTextProps) => props.color || 'white'};
  text-transform: uppercase;
`;

export const Button: React.FC<ButtonProps> = ({
  disabled,
  type = 'Primary',
  text,
  size = 'default',
  onPress,
  icon,
  iconColor,
  iconStyle = 'filled',
  iconSize,
  iconPosition = 'start',
}) => {
  const [isPressed, setPressed] = React.useState(false);

  const ref = React.useRef(null);
  const isHovered = useHover(ref);
  const isActive = useActive(ref) || isPressed;

  const btnState: ButtonState = disabled
    ? 'disabled'
    : isActive
    ? 'pressed'
    : isHovered
    ? 'hover'
    : 'enabled';

  const underlined = type === 'Tertiary';

  if (icon) {
    console.log(iconColor || ctaTextColors[type][btnState]);
  }

  const buttonIcon = icon ? (
    iconStyle === 'filled' ? (
      <FillIcon
        name={icon}
        size={iconSize}
        color={iconColor || ctaTextColors[type][btnState]}
      />
    ) : (
      <StrokeIcon
        name={icon}
        size={iconSize}
        color={iconColor || ctaTextColors[type][btnState]}
      />
    )
  ) : null;

  return (
    <TouchableWithoutFeedback
      disabled={disabled}
      onPressIn={() => setPressed(true)}
      onPressOut={() => {
        setPressed(false);

        if (onPress) {
          trackButtonPress(text);
          onPress();
        }
      }}
    >
      <ButtonWrapper
        backgroundColor={ctaBackgroundColors[type][btnState]}
        borderColor={ctaBorderColors[type][btnState]}
        shadowColor={ctaShadowColors[type][btnState]}
        disabled={disabled}
        ref={ref}
      >
        {type === 'Special' && !disabled && isActive && (
          <ButtonBorderOverlay color={colors.tourmaline} />
        )}
        {type === 'Special' && (
          <ButtonImageOverlay
            source={require('../../assets/images/btn-overlay.png')}
          />
        )}
        <ButtonTextWrapper
          color={ctaTextColors[type][btnState]}
          underline={underlined}
        >
          {iconPosition === 'start' && buttonIcon}
          <ButtonText color={ctaTextColors[type][btnState]} size={size}>
            {text}
          </ButtonText>
          {iconPosition === 'end' && buttonIcon}
        </ButtonTextWrapper>
      </ButtonWrapper>
    </TouchableWithoutFeedback>
  );
};

export const withType = (type: ButtonType) => {
  return (props: ButtonProps) => <Button {...props} type={type} />;
};

export const PrimaryButton = withType('Primary');
export const SecondaryButton = withType('Secondary');
export const SecondaryCtaWhiteButton = withType('SecondaryCtaWhite');
export const TertiaryButton = withType('Tertiary');
export const SpecialButton = withType('Special');
export { ButtonProps } from './types';
