import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   * 
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
};

export type AuthUserResetPassword = {
  __typename?: 'AuthUserResetPassword';
  ok: Scalars['Boolean'];
};

export type CancelStripeSubscriptionAtPeriodEnd = {
  __typename?: 'CancelStripeSubscriptionAtPeriodEnd';
  ok: Scalars['Boolean'];
};

export type CancelStripeSubscriptionNow = {
  __typename?: 'CancelStripeSubscriptionNow';
  ok: Scalars['Boolean'];
};

export type ChangeMentor = {
  __typename?: 'ChangeMentor';
  ok: Scalars['Boolean'];
};

export type ChatType = MessageType | MessageThreadType | MessageAttachmentType;

export type CompleteMessageAttachment = {
  __typename?: 'CompleteMessageAttachment';
  ok: Scalars['Boolean'];
};

export type ConsumeLoginToken = {
  __typename?: 'ConsumeLoginToken';
  token?: Maybe<Scalars['String']>;
  expired: Scalars['Boolean'];
};

export type CreateAndBindPaymentMethod = {
  __typename?: 'CreateAndBindPaymentMethod';
  ok: Scalars['Boolean'];
};

export type CreateMessage = {
  __typename?: 'CreateMessage';
  message: MessageType;
};

export type CreateMessageAttachment = {
  __typename?: 'CreateMessageAttachment';
  messageAttachment: MessageAttachmentType;
};

export type CreateMessageThread = {
  __typename?: 'CreateMessageThread';
  messageThread: MessageThreadType;
};

export type CreateNextSegment = {
  __typename?: 'CreateNextSegment';
  segment: SegmentType;
};

export type CreateStripeSubscription = {
  __typename?: 'CreateStripeSubscription';
  ok: Scalars['Boolean'];
};

export type CreateStripeSubscriptionSchedule = {
  __typename?: 'CreateStripeSubscriptionSchedule';
  ok: Scalars['Boolean'];
};

export type CreateUser = {
  __typename?: 'CreateUser';
  ok: Scalars['Boolean'];
};

export type CreateUserHook = {
  __typename?: 'CreateUserHook';
  ok: Scalars['Boolean'];
};

export type CurriculumModuleType = {
  __typename?: 'CurriculumModuleType';
  id: Scalars['ID'];
  curriculum: CurriculumType;
  title: Scalars['String'];
  description: Scalars['String'];
  lessonGroups?: Maybe<Array<Maybe<LessonGroupType>>>;
};

export type CurriculumType = {
  __typename?: 'CurriculumType';
  body: Scalars['JSONString'];
  completion: Scalars['JSONString'];
  curriculumModules?: Maybe<Array<Maybe<CurriculumModuleType>>>;
  description: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type CursorChatType = {
  __typename?: 'CursorChatType';
  chats?: Maybe<Array<Maybe<ChatType>>>;
  since?: Maybe<Scalars['DateTime']>;
};

export type CustomerPaymentUpdate = {
  __typename?: 'CustomerPaymentUpdate';
  ok: Scalars['Boolean'];
};



export type DeleteUserHook = {
  __typename?: 'DeleteUserHook';
  ok: Scalars['Boolean'];
};

export type DetachPaymentMethod = {
  __typename?: 'DetachPaymentMethod';
  ok: Scalars['Boolean'];
};

export type EnrollmentType = {
  __typename?: 'EnrollmentType';
  id: Scalars['ID'];
  student: StudentType;
  curriculum?: Maybe<CurriculumType>;
};

export type ExerciseProgressType = {
  __typename?: 'ExerciseProgressType';
  id: Scalars['ID'];
  exerciseId: Scalars['String'];
  repeats: Scalars['Boolean'];
  submissionComplete: Scalars['Boolean'];
  startedAt: Scalars['DateTime'];
  endedAt: Scalars['DateTime'];
  rating?: Maybe<Scalars['Int']>;
  uploadData?: Maybe<UploadDataType>;
  s3Url?: Maybe<Scalars['String']>;
  exercise?: Maybe<ExerciseType>;
  s3ThumbnailUrl?: Maybe<Scalars['String']>;
  previousExerciseProgress?: Maybe<ExerciseProgressType>;
  messageThreads?: Maybe<Array<Maybe<MessageThreadType>>>;
};

export type ExerciseScheduleType = {
  __typename?: 'ExerciseScheduleType';
  id: Scalars['ID'];
  exerciseId: Scalars['String'];
  exerciseCount: Scalars['Int'];
  nextAvailableAt?: Maybe<Scalars['DateTime']>;
  curriculum?: Maybe<CurriculumType>;
  student?: Maybe<StudentType>;
};

export type ExerciseType = {
  __typename?: 'ExerciseType';
  id: Scalars['ID'];
  level: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  soundsliceEmbedUrl: Scalars['String'];
  optional: Scalars['Boolean'];
  lesson?: Maybe<LessonType>;
  lastProgress?: Maybe<ExerciseProgressType>;
};

export type ExtendTrialOnStripeSubscriptionSchedule = {
  __typename?: 'ExtendTrialOnStripeSubscriptionSchedule';
  ok: Scalars['Boolean'];
};

export type GenerateLoginEmail = {
  __typename?: 'GenerateLoginEmail';
  ok: Scalars['Boolean'];
};

export type GeneratePasswordEmail = {
  __typename?: 'GeneratePasswordEmail';
  ok: Scalars['Boolean'];
};



export type LessonGroupType = {
  __typename?: 'LessonGroupType';
  id: Scalars['ID'];
  curriculumModule: CurriculumModuleType;
  title: Scalars['String'];
  lessonGroupVideos?: Maybe<Array<Maybe<LessonGroupVideoType>>>;
  lessons?: Maybe<Array<Maybe<LessonType>>>;
};

export type LessonGroupVideoType = {
  __typename?: 'LessonGroupVideoType';
  id: Scalars['ID'];
  lessonGroup: LessonGroupType;
  description?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
  s3ThumbnailUrl?: Maybe<Scalars['String']>;
};

export type LessonType = {
  __typename?: 'LessonType';
  description: Scalars['String'];
  exercises?: Maybe<Array<Maybe<ExerciseType>>>;
  id: Scalars['ID'];
  lessonGroup?: Maybe<LessonGroupType>;
  s3ThumbnailUrl?: Maybe<Scalars['String']>;
  supplementary: Scalars['JSONString'];
  supplementaryJSON: Scalars['JSONString'];
  title: Scalars['String'];
  videoUrl?: Maybe<Scalars['String']>;
};

export type MentorType = {
  __typename?: 'MentorType';
  id: Scalars['ID'];
  user: UserType;
  schedule: Scalars['String'];
};

export type MessageAttachmentType = {
  __typename?: 'MessageAttachmentType';
  id: Scalars['ID'];
  message?: Maybe<MessageType>;
  name: Scalars['String'];
  complete: Scalars['Boolean'];
  uploadData?: Maybe<UploadDataType>;
  s3Url?: Maybe<Scalars['String']>;
  s3ThumbnailUrl?: Maybe<Scalars['String']>;
  messageId: Scalars['Int'];
};

export type MessageThreadType = {
  __typename?: 'MessageThreadType';
  id: Scalars['ID'];
  student: StudentType;
  mentor: MentorType;
  subject?: Maybe<Scalars['String']>;
  exerciseProgress?: Maybe<ExerciseProgressType>;
  lastMessageAt: Scalars['DateTime'];
  messages?: Maybe<Array<Maybe<MessageType>>>;
  exercises?: Maybe<Array<Maybe<ExerciseType>>>;
  relationship?: Maybe<StudentMentorRelationshipType>;
  lastActivity?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<MessageType>;
  unreadCount: Scalars['Int'];
  s3ThumbnailUrl?: Maybe<Scalars['String']>;
};

export type MessageType = {
  __typename?: 'MessageType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  contents: Scalars['String'];
  complete: Scalars['Boolean'];
  read: Scalars['Boolean'];
  receiver?: Maybe<UserType>;
  sender?: Maybe<UserType>;
  exercise?: Maybe<ExerciseType>;
  attachments?: Maybe<Array<Maybe<MessageAttachmentType>>>;
  messageThread?: Maybe<MessageThreadType>;
  messageThreadId: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  authUserResetPassword?: Maybe<AuthUserResetPassword>;
  cancelStripeSubscriptionAtPeriodEnd?: Maybe<CancelStripeSubscriptionAtPeriodEnd>;
  cancelStripeSubscriptionNow?: Maybe<CancelStripeSubscriptionNow>;
  changeMentor?: Maybe<ChangeMentor>;
  completeMessageAttachment?: Maybe<CompleteMessageAttachment>;
  consumeLoginToken?: Maybe<ConsumeLoginToken>;
  createAndBindPaymentMethod?: Maybe<CreateAndBindPaymentMethod>;
  createMessage?: Maybe<CreateMessage>;
  createMessageAttachment?: Maybe<CreateMessageAttachment>;
  createMessageThread?: Maybe<CreateMessageThread>;
  createNextSegment?: Maybe<CreateNextSegment>;
  createStripeSubscription?: Maybe<CreateStripeSubscription>;
  createStripeSubscriptionSchedule?: Maybe<CreateStripeSubscriptionSchedule>;
  createUser?: Maybe<CreateUser>;
  createUserHook?: Maybe<CreateUserHook>;
  customerPaymentUpdate?: Maybe<CustomerPaymentUpdate>;
  deleteUserHook?: Maybe<DeleteUserHook>;
  detachPaymentMethod?: Maybe<DetachPaymentMethod>;
  extendTrialOnStripeSubscriptionSchedule?: Maybe<ExtendTrialOnStripeSubscriptionSchedule>;
  generateLoginEmail?: Maybe<GenerateLoginEmail>;
  generatePasswordEmail?: Maybe<GeneratePasswordEmail>;
  pauseStripeSubscription?: Maybe<PauseStripeSubscription>;
  publishMessage?: Maybe<PublishMessage>;
  readMessage?: Maybe<ReadMessage>;
  readMessages?: Maybe<ReadMessages>;
  refreshStripeUserState?: Maybe<RefreshStripeUserState>;
  refreshToken?: Maybe<Refresh>;
  reportCompleteSubmission?: Maybe<ReportCompleteSubmission>;
  reportExerciseProgress?: Maybe<ReportExerciseProgress>;
  reportProgress?: Maybe<ReportProgress>;
  setPreferences?: Maybe<SetPreferences>;
  setPushToken?: Maybe<SetPushToken>;
  skipTrial?: Maybe<SkipTrial>;
  startMessage?: Maybe<StartMessage>;
  /** Obtain JSON Web Token mutation */
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  updateMentor?: Maybe<UpdateMentor>;
  updatePracticeReminderSettings?: Maybe<PracticeReminderSettings>;
  updateStripeSubscriptionSchedule?: Maybe<UpdateStripeSubscriptionSchedule>;
  verifyToken?: Maybe<Verify>;
};


export type MutationAuthUserResetPasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type MutationCancelStripeSubscriptionAtPeriodEndArgs = {
  subscriptionId: Scalars['String'];
};


export type MutationCancelStripeSubscriptionNowArgs = {
  subscriptionId: Scalars['String'];
};


export type MutationChangeMentorArgs = {
  email: Scalars['String'];
  mentorEmail: Scalars['String'];
};


export type MutationCompleteMessageAttachmentArgs = {
  id: Scalars['Int'];
};


export type MutationConsumeLoginTokenArgs = {
  password?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};


export type MutationCreateAndBindPaymentMethodArgs = {
  cvc: Scalars['String'];
  expMonth: Scalars['String'];
  expYear: Scalars['String'];
  number: Scalars['String'];
};


export type MutationCreateMessageArgs = {
  contents: Scalars['String'];
  exerciseId?: Maybe<Scalars['Int']>;
  messageThreadId: Scalars['Int'];
};


export type MutationCreateMessageAttachmentArgs = {
  id: Scalars['Int'];
};


export type MutationCreateMessageThreadArgs = {
  contents: Scalars['String'];
  exerciseProgressId?: Maybe<Scalars['Int']>;
  relationshipId: Scalars['Int'];
  subject: Scalars['String'];
};


export type MutationCreateNextSegmentArgs = {
  curriculumId?: Maybe<Scalars['Int']>;
  duration: Scalars['Int'];
};


export type MutationCreateStripeSubscriptionArgs = {
  priceId: Scalars['String'];
  trialDays?: Maybe<Scalars['Int']>;
};


export type MutationCreateStripeSubscriptionScheduleArgs = {
  iterationCount: Scalars['Int'];
  priceId: Scalars['String'];
  trialDays?: Maybe<Scalars['Int']>;
};


export type MutationCreateUserArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};


export type MutationCreateUserHookArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mentorEmail?: Maybe<Scalars['String']>;
  mentorStart?: Maybe<Scalars['DateTime']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationCustomerPaymentUpdateArgs = {
  token: Scalars['String'];
};


export type MutationDeleteUserHookArgs = {
  email: Scalars['String'];
};


export type MutationDetachPaymentMethodArgs = {
  cardId: Scalars['String'];
};


export type MutationExtendTrialOnStripeSubscriptionScheduleArgs = {
  additionalTrialDays?: Maybe<Scalars['Int']>;
  iterationCount?: Maybe<Scalars['Int']>;
  priceId: Scalars['String'];
  subscriptionScheduleId: Scalars['String'];
};


export type MutationGenerateLoginEmailArgs = {
  email: Scalars['String'];
};


export type MutationGeneratePasswordEmailArgs = {
  email: Scalars['String'];
};


export type MutationPauseStripeSubscriptionArgs = {
  subscriptionId: Scalars['String'];
};


export type MutationPublishMessageArgs = {
  exerciseId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
};


export type MutationReadMessageArgs = {
  id: Scalars['Int'];
};


export type MutationReadMessagesArgs = {
  ids: Array<Maybe<Scalars['Int']>>;
};


export type MutationRefreshTokenArgs = {
  token: Scalars['String'];
};


export type MutationReportCompleteSubmissionArgs = {
  id: Scalars['Int'];
};


export type MutationReportExerciseProgressArgs = {
  curriculumId: Scalars['Int'];
  datePracticed?: Maybe<Scalars['Date']>;
  endedAt: Scalars['DateTime'];
  exerciseId: Scalars['String'];
  rating?: Maybe<Scalars['Int']>;
  repeats: Scalars['Boolean'];
  startedAt: Scalars['DateTime'];
};


export type MutationReportProgressArgs = {
  datePracticed?: Maybe<Scalars['Date']>;
  endedAt?: Maybe<Scalars['DateTime']>;
  exerciseId: Scalars['Int'];
  rating?: Maybe<Scalars['Int']>;
  segmentId: Scalars['Int'];
  startedAt?: Maybe<Scalars['DateTime']>;
};


export type MutationSetPreferencesArgs = {
  pushNotificationsEnabled?: Maybe<Scalars['Boolean']>;
};


export type MutationSetPushTokenArgs = {
  token: Scalars['String'];
};


export type MutationSkipTrialArgs = {
  iterationCount: Scalars['String'];
  priceId: Scalars['String'];
  subscriptionScheduleId: Scalars['String'];
};


export type MutationStartMessageArgs = {
  contents: Scalars['String'];
  exerciseId?: Maybe<Scalars['Int']>;
  messageThreadId: Scalars['Int'];
};


export type MutationTokenAuthArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationUpdateMentorArgs = {
  email: Scalars['String'];
  endDate?: Maybe<Scalars['DateTime']>;
  mentorEmail: Scalars['String'];
  startDate: Scalars['DateTime'];
};


export type MutationUpdatePracticeReminderSettingsArgs = {
  enabled?: Maybe<Scalars['Boolean']>;
  notificationId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
};


export type MutationUpdateStripeSubscriptionScheduleArgs = {
  iterationCount: Scalars['Int'];
  newPriceId: Scalars['String'];
  subscriptionScheduleId: Scalars['String'];
};


export type MutationVerifyTokenArgs = {
  token: Scalars['String'];
};

/** Obtain JSON Web Token mutation */
export type ObtainJsonWebToken = {
  __typename?: 'ObtainJSONWebToken';
  token?: Maybe<Scalars['String']>;
};

export type PagedExerciseProgressType = {
  __typename?: 'PagedExerciseProgressType';
  totalCount?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfoType>;
  exerciseProgresses?: Maybe<Array<Maybe<ExerciseProgressType>>>;
};

export type PageInfoType = {
  __typename?: 'PageInfoType';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type PauseStripeSubscription = {
  __typename?: 'PauseStripeSubscription';
  ok: Scalars['Boolean'];
};

export type PracticeReminderSettings = {
  enabled?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['DateTime']>;
  notificationId?: Maybe<Scalars['ID']>;
};

export type PublishMessage = {
  __typename?: 'PublishMessage';
  ok: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  chats?: Maybe<CursorChatType>;
  curriculum?: Maybe<CurriculumType>;
  curriculumModule?: Maybe<CurriculumModuleType>;
  datesPracticed?: Maybe<Array<Maybe<Scalars['String']>>>;
  enrollments?: Maybe<Array<Maybe<EnrollmentType>>>;
  exercise?: Maybe<ExerciseType>;
  exerciseProgresses?: Maybe<PagedExerciseProgressType>;
  exerciseSchedules?: Maybe<Array<Maybe<ExerciseScheduleType>>>;
  exercises?: Maybe<Array<Maybe<ExerciseType>>>;
  lastSegment?: Maybe<SegmentType>;
  latestExerciseProgresses?: Maybe<Array<Maybe<ExerciseProgressType>>>;
  lesson?: Maybe<LessonType>;
  lessonGroup?: Maybe<LessonGroupType>;
  message?: Maybe<MessageType>;
  messageAttachments?: Maybe<Array<Maybe<MessageAttachmentType>>>;
  messageThread?: Maybe<MessageThreadType>;
  messageThreads?: Maybe<Array<Maybe<MessageThreadType>>>;
  studentMentorRelationship?: Maybe<StudentMentorRelationshipType>;
  studentMentorRelationships?: Maybe<Array<Maybe<StudentMentorRelationshipType>>>;
  user?: Maybe<UserType>;
  userSettings?: Maybe<UserSettings>;
};


export type QueryChatsArgs = {
  since?: Maybe<Scalars['DateTime']>;
  relationshipId?: Maybe<Scalars['Int']>;
};


export type QueryCurriculumArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryCurriculumModuleArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryDatesPracticedArgs = {
  studentId?: Maybe<Scalars['Int']>;
};


export type QueryExerciseArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryExerciseProgressesArgs = {
  cursor?: Maybe<Scalars['String']>;
};


export type QueryExerciseSchedulesArgs = {
  curriculumId?: Maybe<Scalars['Int']>;
};


export type QueryExercisesArgs = {
  ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryLatestExerciseProgressesArgs = {
  curriculumId?: Maybe<Scalars['Int']>;
};


export type QueryLessonArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryLessonGroupArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryMessageArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryMessageThreadArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryMessageThreadsArgs = {
  relationshipId?: Maybe<Scalars['Int']>;
  hasAttachments?: Maybe<Scalars['Boolean']>;
  before?: Maybe<Scalars['DateTime']>;
};


export type QueryStudentMentorRelationshipArgs = {
  id?: Maybe<Scalars['Int']>;
};

export type ReadMessage = {
  __typename?: 'ReadMessage';
  ok: Scalars['Boolean'];
};

export type ReadMessages = {
  __typename?: 'ReadMessages';
  ok: Scalars['Boolean'];
};

export type Refresh = {
  __typename?: 'Refresh';
  token?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['GenericScalar']>;
};

export type RefreshStripeUserState = {
  __typename?: 'RefreshStripeUserState';
  ok: Scalars['Boolean'];
};

export type ReportCompleteSubmission = {
  __typename?: 'ReportCompleteSubmission';
  ok: Scalars['Boolean'];
};

export type ReportExerciseProgress = {
  __typename?: 'ReportExerciseProgress';
  progress: ExerciseProgressType;
};

export type ReportProgress = {
  __typename?: 'ReportProgress';
  progress: ExerciseProgressType;
};

export type SegmentType = {
  __typename?: 'SegmentType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  reviewExerciseIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  newExerciseIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  completedExerciseIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type SetPreferences = {
  __typename?: 'SetPreferences';
  ok: Scalars['Boolean'];
};

export type SetPushToken = {
  __typename?: 'SetPushToken';
  ok: Scalars['Boolean'];
};

export type SkipTrial = {
  __typename?: 'SkipTrial';
  ok: Scalars['Boolean'];
};

export type StartMessage = {
  __typename?: 'StartMessage';
  message: MessageType;
};

export type StripeUserStateType = {
  __typename?: 'StripeUserStateType';
  id: Scalars['ID'];
  user: UserType;
  stripeUserData?: Maybe<Scalars['JSONString']>;
};

export type StudentMentorRelationshipType = {
  __typename?: 'StudentMentorRelationshipType';
  id: Scalars['ID'];
  student: StudentType;
  mentor: MentorType;
  startedAt: Scalars['DateTime'];
  endedAt: Scalars['DateTime'];
  messageThread?: Maybe<MessageThreadType>;
  unreadCount?: Maybe<Scalars['Int']>;
  lastDatePracticed?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum StudentStudentType {
  /** low_ticket */
  Lt = 'LT',
  /** high_ticket */
  Ht = 'HT'
}

export type StudentType = {
  __typename?: 'StudentType';
  id: Scalars['ID'];
  user: UserType;
  level: Scalars['Int'];
  studentType: StudentStudentType;
  segments?: Maybe<Array<Maybe<SegmentType>>>;
  datesPracticed?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateMentor = {
  __typename?: 'UpdateMentor';
  ok: Scalars['Boolean'];
};

export type UpdateStripeSubscriptionSchedule = {
  __typename?: 'UpdateStripeSubscriptionSchedule';
  ok: Scalars['Boolean'];
};

export type UploadDataType = {
  __typename?: 'UploadDataType';
  url?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['JSONString']>;
};

export type UserSettings = {
  practiceReminder?: Maybe<PracticeReminderSettings>;
  chatNotifications?: Maybe<Scalars['Boolean']>;
};

export type UserType = {
  __typename?: 'UserType';
  createdAt: Scalars['DateTime'];
  dateJoined: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean'];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean'];
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  mentor?: Maybe<MentorType>;
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  pushNotificationsEnabled: Scalars['Boolean'];
  pushToken?: Maybe<Scalars['String']>;
  receivedMessages: Array<MessageType>;
  sentMessages: Array<MessageType>;
  stripeId: Scalars['String'];
  stripeUserState?: Maybe<StripeUserStateType>;
  student?: Maybe<StudentType>;
  updatedAt: Scalars['DateTime'];
  userType?: Maybe<Scalars['String']>;
};

export type Verify = {
  __typename?: 'Verify';
  payload?: Maybe<Scalars['GenericScalar']>;
};

export type MentorHomeQueryVariables = Exact<{ [key: string]: never; }>;


export type MentorHomeQuery = (
  { __typename?: 'Query' }
  & { studentMentorRelationships?: Maybe<Array<Maybe<(
    { __typename?: 'StudentMentorRelationshipType' }
    & MentorHomeRelationshipInfoFragment
  )>>>, enrollments?: Maybe<Array<Maybe<(
    { __typename?: 'EnrollmentType' }
    & Pick<EnrollmentType, 'id'>
    & { curriculum?: Maybe<(
      { __typename?: 'CurriculumType' }
      & CurriculumFragment
    )> }
  )>>> }
);

export type StudentInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type StudentInfoQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'UserType' }
    & UserFragment
  )>, enrollments?: Maybe<Array<Maybe<(
    { __typename?: 'EnrollmentType' }
    & Pick<EnrollmentType, 'id'>
    & { student: (
      { __typename?: 'StudentType' }
      & Pick<StudentType, 'id' | 'studentType'>
    ), curriculum?: Maybe<(
      { __typename?: 'CurriculumType' }
      & CurriculumFragment
    )> }
  )>>>, studentMentorRelationships?: Maybe<Array<Maybe<(
    { __typename?: 'StudentMentorRelationshipType' }
    & Pick<StudentMentorRelationshipType, 'id' | 'startedAt' | 'endedAt'>
    & { messageThread?: Maybe<(
      { __typename?: 'MessageThreadType' }
      & Pick<MessageThreadType, 'id'>
    )>, mentor: (
      { __typename?: 'MentorType' }
      & Pick<MentorType, 'id'>
      & { user: (
        { __typename?: 'UserType' }
        & UserFragment
      ) }
    ) }
  )>>> }
);

export type UserFragment = (
  { __typename?: 'UserType' }
  & Pick<UserType, 'id' | 'firstName' | 'lastName' | 'fullName' | 'userType' | 'email'>
  & { stripeUserState?: Maybe<(
    { __typename?: 'StripeUserStateType' }
    & Pick<StripeUserStateType, 'stripeUserData'>
  )> }
);

export type CurriculumFragment = (
  { __typename?: 'CurriculumType' }
  & Pick<CurriculumType, 'id' | 'title' | 'body'>
);

export type ExerciseTitleFragment = (
  { __typename?: 'ExerciseType' }
  & Pick<ExerciseType, 'id'>
  & { lesson?: Maybe<(
    { __typename?: 'LessonType' }
    & Pick<LessonType, 'id' | 'title'>
    & { lessonGroup?: Maybe<(
      { __typename?: 'LessonGroupType' }
      & Pick<LessonGroupType, 'id' | 'title'>
    )> }
  )> }
);

export type MessageAttachmentDownloadInfoFragment = (
  { __typename?: 'MessageAttachmentType' }
  & Pick<MessageAttachmentType, 'id' | 'complete' | 's3Url' | 's3ThumbnailUrl'>
);

export type MessageWithAttachmentsFragment = (
  { __typename?: 'MessageType' }
  & Pick<MessageType, 'id' | 'contents' | 'complete' | 'createdAt' | 'read'>
  & { sender?: Maybe<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'userType'>
    & UserFragment
  )>, attachments?: Maybe<Array<Maybe<(
    { __typename?: 'MessageAttachmentType' }
    & MessageAttachmentDownloadInfoFragment
  )>>> }
);

export type MessagesWithAttachmentsFragment = (
  { __typename?: 'MessageThreadType' }
  & Pick<MessageThreadType, 'id'>
  & { messages?: Maybe<Array<Maybe<(
    { __typename?: 'MessageType' }
    & MessageWithAttachmentsFragment
  )>>> }
);

export type MessageFragment = (
  { __typename?: 'MessageType' }
  & Pick<MessageType, 'id' | 'contents' | 'complete' | 'createdAt' | 'read'>
  & { sender?: Maybe<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'id'>
    & UserFragment
  )> }
);

export type MentorHomeMessageThreadFragment = (
  { __typename?: 'MessageThreadType' }
  & Pick<MessageThreadType, 'id' | 'lastActivity'>
  & { lastMessage?: Maybe<(
    { __typename?: 'MessageType' }
    & Pick<MessageType, 'id' | 'contents'>
    & { attachments?: Maybe<Array<Maybe<(
      { __typename?: 'MessageAttachmentType' }
      & MessageAttachmentDownloadInfoFragment
    )>>> }
  )>, mentor: (
    { __typename?: 'MentorType' }
    & Pick<MentorType, 'id'>
    & { user: (
      { __typename?: 'UserType' }
      & Pick<UserType, 'id'>
      & UserFragment
    ) }
  ), student: (
    { __typename?: 'StudentType' }
    & Pick<StudentType, 'id' | 'studentType' | 'level'>
    & { user: (
      { __typename?: 'UserType' }
      & UserFragment
    ) }
  ) }
);

export type MentorHomeRelationshipInfoFragment = (
  { __typename?: 'StudentMentorRelationshipType' }
  & Pick<StudentMentorRelationshipType, 'id' | 'startedAt' | 'endedAt' | 'unreadCount'>
  & { messageThread?: Maybe<(
    { __typename?: 'MessageThreadType' }
    & MentorHomeMessageThreadFragment
  )> }
);

export type ExerciseProgressFragment = (
  { __typename?: 'ExerciseProgressType' }
  & Pick<ExerciseProgressType, 'rating' | 'submissionComplete' | 'startedAt' | 'endedAt' | 's3Url'>
);

export type SignInUserMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type SignInUserMutation = (
  { __typename?: 'Mutation' }
  & { tokenAuth?: Maybe<(
    { __typename?: 'ObtainJSONWebToken' }
    & Pick<ObtainJsonWebToken, 'token'>
  )> }
);

export type GenerateResetPasswordEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type GenerateResetPasswordEmailMutation = (
  { __typename?: 'Mutation' }
  & { generatePasswordEmail?: Maybe<(
    { __typename?: 'GeneratePasswordEmail' }
    & Pick<GeneratePasswordEmail, 'ok'>
  )> }
);

export type SetNewPasswordWithTokenMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type SetNewPasswordWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { consumeLoginToken?: Maybe<(
    { __typename?: 'ConsumeLoginToken' }
    & Pick<ConsumeLoginToken, 'token' | 'expired'>
  )> }
);

export type LogInWithUuidMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type LogInWithUuidMutation = (
  { __typename?: 'Mutation' }
  & { consumeLoginToken?: Maybe<(
    { __typename?: 'ConsumeLoginToken' }
    & Pick<ConsumeLoginToken, 'token' | 'expired'>
  )> }
);

export type LogActivityProgressMutationVariables = Exact<{
  curriculumId: Scalars['Int'];
  activityId: Scalars['String'];
  stars: Scalars['Int'];
  timeStamp: Scalars['DateTime'];
  isPractice?: Maybe<Scalars['Boolean']>;
  datePracticed?: Maybe<Scalars['Date']>;
}>;


export type LogActivityProgressMutation = (
  { __typename?: 'Mutation' }
  & { reportExerciseProgress?: Maybe<(
    { __typename?: 'ReportExerciseProgress' }
    & { progress: (
      { __typename?: 'ExerciseProgressType' }
      & Pick<ExerciseProgressType, 'id'>
    ) }
  )> }
);

export type CreateMessageMutationVariables = Exact<{
  contents: Scalars['String'];
  messageThreadId: Scalars['Int'];
  exerciseId?: Maybe<Scalars['Int']>;
}>;


export type CreateMessageMutation = (
  { __typename?: 'Mutation' }
  & { createMessage?: Maybe<(
    { __typename?: 'CreateMessage' }
    & { message: (
      { __typename?: 'MessageType' }
      & Pick<MessageType, 'id'>
    ) }
  )> }
);

export type ReadMessagesMutationVariables = Exact<{
  ids: Array<Maybe<Scalars['Int']>>;
}>;


export type ReadMessagesMutation = (
  { __typename?: 'Mutation' }
  & { readMessages?: Maybe<(
    { __typename?: 'ReadMessages' }
    & Pick<ReadMessages, 'ok'>
  )> }
);

export type CreateMessageAttachmentThreadMutationVariables = Exact<{
  relationshipId: Scalars['Int'];
  subject?: Maybe<Scalars['String']>;
  exerciseProgressId?: Maybe<Scalars['Int']>;
}>;


export type CreateMessageAttachmentThreadMutation = (
  { __typename?: 'Mutation' }
  & { createMessageThread?: Maybe<(
    { __typename?: 'CreateMessageThread' }
    & { messageThread: (
      { __typename?: 'MessageThreadType' }
      & Pick<MessageThreadType, 'id'>
    ) }
  )> }
);

export type StartMessageMutationVariables = Exact<{
  messageThreadId: Scalars['Int'];
  exerciseId?: Maybe<Scalars['Int']>;
  contents?: Maybe<Scalars['String']>;
}>;


export type StartMessageMutation = (
  { __typename?: 'Mutation' }
  & { startMessage?: Maybe<(
    { __typename?: 'StartMessage' }
    & { message: (
      { __typename?: 'MessageType' }
      & Pick<MessageType, 'id'>
    ) }
  )> }
);

export type CreateMessageAttachmentMutationVariables = Exact<{
  messageId: Scalars['Int'];
}>;


export type CreateMessageAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { createMessageAttachment?: Maybe<(
    { __typename?: 'CreateMessageAttachment' }
    & { messageAttachment: (
      { __typename?: 'MessageAttachmentType' }
      & Pick<MessageAttachmentType, 'id'>
      & { uploadData?: Maybe<(
        { __typename?: 'UploadDataType' }
        & Pick<UploadDataType, 'url' | 'fields'>
      )> }
    ) }
  )> }
);

export type PublishMessageMutationVariables = Exact<{
  messageId: Scalars['Int'];
  exerciseId?: Maybe<Scalars['Int']>;
}>;


export type PublishMessageMutation = (
  { __typename?: 'Mutation' }
  & { publishMessage?: Maybe<(
    { __typename?: 'PublishMessage' }
    & Pick<PublishMessage, 'ok'>
  )> }
);

export type CompleteMessageAttachmentMutationVariables = Exact<{
  attachmentId: Scalars['Int'];
}>;


export type CompleteMessageAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { completeMessageAttachment?: Maybe<(
    { __typename?: 'CompleteMessageAttachment' }
    & Pick<CompleteMessageAttachment, 'ok'>
  )> }
);

export type SetPushTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type SetPushTokenMutation = (
  { __typename?: 'Mutation' }
  & { setPushToken?: Maybe<(
    { __typename?: 'SetPushToken' }
    & Pick<SetPushToken, 'ok'>
  )> }
);

export type CancelStripeSubscriptionNowMutationVariables = Exact<{
  subscriptionId: Scalars['String'];
}>;


export type CancelStripeSubscriptionNowMutation = (
  { __typename?: 'Mutation' }
  & { cancelStripeSubscriptionNow?: Maybe<(
    { __typename?: 'CancelStripeSubscriptionNow' }
    & Pick<CancelStripeSubscriptionNow, 'ok'>
  )> }
);

export type CancelStripeSubscriptionAtPeriodEndMutationVariables = Exact<{
  subscriptionId: Scalars['String'];
}>;


export type CancelStripeSubscriptionAtPeriodEndMutation = (
  { __typename?: 'Mutation' }
  & { cancelStripeSubscriptionAtPeriodEnd?: Maybe<(
    { __typename?: 'CancelStripeSubscriptionAtPeriodEnd' }
    & Pick<CancelStripeSubscriptionAtPeriodEnd, 'ok'>
  )> }
);

export type ExtendTrialOnStripeSubscriptionScheduleMutationVariables = Exact<{
  additionalTrialDays: Scalars['Int'];
  subscriptionScheduleId: Scalars['String'];
  priceId: Scalars['String'];
  iterationCount: Scalars['Int'];
}>;


export type ExtendTrialOnStripeSubscriptionScheduleMutation = (
  { __typename?: 'Mutation' }
  & { extendTrialOnStripeSubscriptionSchedule?: Maybe<(
    { __typename?: 'ExtendTrialOnStripeSubscriptionSchedule' }
    & Pick<ExtendTrialOnStripeSubscriptionSchedule, 'ok'>
  )> }
);

export type PauseStripeSubscriptionMutationVariables = Exact<{
  subscriptionId: Scalars['String'];
}>;


export type PauseStripeSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { pauseStripeSubscription?: Maybe<(
    { __typename?: 'PauseStripeSubscription' }
    & Pick<PauseStripeSubscription, 'ok'>
  )> }
);

export type CreateAndBindPaymentMethodMutationVariables = Exact<{
  cvc: Scalars['String'];
  expMonth: Scalars['String'];
  expYear: Scalars['String'];
  number: Scalars['String'];
}>;


export type CreateAndBindPaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { createAndBindPaymentMethod?: Maybe<(
    { __typename?: 'CreateAndBindPaymentMethod' }
    & Pick<CreateAndBindPaymentMethod, 'ok'>
  )> }
);

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'UserType' }
    & UserFragment
  )> }
);

export type CurriculumProgressQueryVariables = Exact<{
  curriculumId: Scalars['Int'];
}>;


export type CurriculumProgressQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'datesPracticed'>
  & { latestExerciseProgresses?: Maybe<Array<Maybe<(
    { __typename?: 'ExerciseProgressType' }
    & Pick<ExerciseProgressType, 'id' | 'exerciseId' | 'startedAt' | 'repeats' | 'rating'>
  )>>> }
);

export type DatesStudentPracticedQueryVariables = Exact<{
  studentId: Scalars['Int'];
}>;


export type DatesStudentPracticedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'datesPracticed'>
);

export type UnreadPollQueryVariables = Exact<{ [key: string]: never; }>;


export type UnreadPollQuery = (
  { __typename?: 'Query' }
  & { studentMentorRelationships?: Maybe<Array<Maybe<(
    { __typename?: 'StudentMentorRelationshipType' }
    & Pick<StudentMentorRelationshipType, 'id' | 'unreadCount'>
  )>>> }
);

export type StudentChatBasicInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type StudentChatBasicInfoQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'id'>
    & { student?: Maybe<(
      { __typename?: 'StudentType' }
      & Pick<StudentType, 'id' | 'studentType'>
    )> }
  )>, studentMentorRelationships?: Maybe<Array<Maybe<(
    { __typename?: 'StudentMentorRelationshipType' }
    & Pick<StudentMentorRelationshipType, 'id' | 'startedAt' | 'endedAt'>
    & { mentor: (
      { __typename?: 'MentorType' }
      & Pick<MentorType, 'id'>
      & { user: (
        { __typename?: 'UserType' }
        & Pick<UserType, 'id' | 'firstName' | 'lastName' | 'fullName'>
      ) }
    ), student: (
      { __typename?: 'StudentType' }
      & Pick<StudentType, 'id'>
      & { user: (
        { __typename?: 'UserType' }
        & Pick<UserType, 'id' | 'firstName' | 'lastName'>
      ) }
    ), messageThread?: Maybe<(
      { __typename?: 'MessageThreadType' }
      & Pick<MessageThreadType, 'id'>
    )> }
  )>>> }
);

export type ChatThreadQueryVariables = Exact<{
  threadId: Scalars['Int'];
}>;


export type ChatThreadQuery = (
  { __typename?: 'Query' }
  & { messageThread?: Maybe<(
    { __typename?: 'MessageThreadType' }
    & CurrentThreadInfoFragment
  )> }
);

export type CurrentThreadInfoFragment = (
  { __typename?: 'MessageThreadType' }
  & Pick<MessageThreadType, 'id' | 'subject'>
  & { student: (
    { __typename?: 'StudentType' }
    & Pick<StudentType, 'id'>
    & { user: (
      { __typename?: 'UserType' }
      & Pick<UserType, 'id'>
      & UserFragment
    ), segments?: Maybe<Array<Maybe<(
      { __typename?: 'SegmentType' }
      & Pick<SegmentType, 'id' | 'createdAt'>
    )>>> }
  ), mentor: (
    { __typename?: 'MentorType' }
    & Pick<MentorType, 'id'>
    & { user: (
      { __typename?: 'UserType' }
      & Pick<UserType, 'id'>
      & UserFragment
    ) }
  ) }
  & MessagesWithAttachmentsFragment
);

export type ChatAllThreadsQueryVariables = Exact<{
  relationshipId?: Maybe<Scalars['Int']>;
  hasAttachments?: Maybe<Scalars['Boolean']>;
}>;


export type ChatAllThreadsQuery = (
  { __typename?: 'Query' }
  & { messageThreads?: Maybe<Array<Maybe<(
    { __typename?: 'MessageThreadType' }
    & CarouselDataFragment
  )>>> }
);

export type CarouselDataFragment = (
  { __typename?: 'MessageThreadType' }
  & Pick<MessageThreadType, 'id' | 'subject' | 'unreadCount' | 's3ThumbnailUrl' | 'lastMessageAt'>
  & { exercises?: Maybe<Array<Maybe<(
    { __typename?: 'ExerciseType' }
    & Pick<ExerciseType, 'id'>
  )>>> }
);

export type ChatActivityQueryVariables = Exact<{
  relationshipId?: Maybe<Scalars['Int']>;
  since?: Maybe<Scalars['DateTime']>;
}>;


export type ChatActivityQuery = (
  { __typename?: 'Query' }
  & { chats?: Maybe<(
    { __typename?: 'CursorChatType' }
    & Pick<CursorChatType, 'since'>
    & { chats?: Maybe<Array<Maybe<(
      { __typename: 'MessageType' }
      & MessageUpdateFragment
    ) | (
      { __typename: 'MessageThreadType' }
      & MessageThreadUpdateFragment
    ) | (
      { __typename: 'MessageAttachmentType' }
      & MessageAttachmentUpdateFragment
    )>>> }
  )> }
);

export type MessageThreadUpdateFragment = (
  { __typename?: 'MessageThreadType' }
  & Pick<MessageThreadType, 'id' | 'subject' | 's3ThumbnailUrl' | 'unreadCount' | 'lastActivity'>
  & { exercises?: Maybe<Array<Maybe<(
    { __typename?: 'ExerciseType' }
    & Pick<ExerciseType, 'id'>
  )>>> }
);

export type MessageUpdateFragment = (
  { __typename?: 'MessageType' }
  & Pick<MessageType, 'id' | 'contents' | 'read' | 'createdAt' | 'complete' | 'messageThreadId'>
  & { attachments?: Maybe<Array<Maybe<(
    { __typename?: 'MessageAttachmentType' }
    & Pick<MessageAttachmentType, 'id' | 's3Url' | 's3ThumbnailUrl' | 'complete'>
  )>>>, sender?: Maybe<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'userType'>
    & UserFragment
  )> }
);

export type MessageAttachmentUpdateFragment = (
  { __typename?: 'MessageAttachmentType' }
  & Pick<MessageAttachmentType, 'id' | 's3Url' | 's3ThumbnailUrl' | 'messageId' | 'complete'>
);

export const CurriculumFragmentDoc = gql`
    fragment Curriculum on CurriculumType {
  id
  title
  body
}
    `;
export const ExerciseTitleFragmentDoc = gql`
    fragment ExerciseTitle on ExerciseType {
  id
  lesson {
    id
    title
    lessonGroup {
      id
      title
    }
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment User on UserType {
  id
  firstName
  lastName
  fullName @client
  userType
  email
  stripeUserState {
    stripeUserData
  }
}
    `;
export const MessageFragmentDoc = gql`
    fragment Message on MessageType {
  id
  contents
  complete
  createdAt
  read
  sender {
    id
    ...User
  }
}
    ${UserFragmentDoc}`;
export const MessageAttachmentDownloadInfoFragmentDoc = gql`
    fragment MessageAttachmentDownloadInfo on MessageAttachmentType {
  id
  complete
  s3Url
  s3ThumbnailUrl
}
    `;
export const MentorHomeMessageThreadFragmentDoc = gql`
    fragment MentorHomeMessageThread on MessageThreadType {
  id
  lastActivity
  lastMessage {
    id
    contents
    attachments {
      ...MessageAttachmentDownloadInfo
    }
  }
  mentor {
    id
    user {
      id
      ...User
    }
  }
  student {
    id
    studentType
    level
    user {
      ...User
    }
  }
}
    ${MessageAttachmentDownloadInfoFragmentDoc}
${UserFragmentDoc}`;
export const MentorHomeRelationshipInfoFragmentDoc = gql`
    fragment MentorHomeRelationshipInfo on StudentMentorRelationshipType {
  id
  startedAt
  endedAt
  unreadCount
  messageThread {
    ...MentorHomeMessageThread
  }
}
    ${MentorHomeMessageThreadFragmentDoc}`;
export const ExerciseProgressFragmentDoc = gql`
    fragment ExerciseProgress on ExerciseProgressType {
  rating
  submissionComplete
  startedAt
  endedAt
  s3Url
}
    `;
export const MessageWithAttachmentsFragmentDoc = gql`
    fragment MessageWithAttachments on MessageType {
  id
  contents
  complete
  createdAt
  read
  sender {
    id
    userType
    ...User
  }
  attachments {
    ...MessageAttachmentDownloadInfo
  }
}
    ${UserFragmentDoc}
${MessageAttachmentDownloadInfoFragmentDoc}`;
export const MessagesWithAttachmentsFragmentDoc = gql`
    fragment MessagesWithAttachments on MessageThreadType {
  id
  messages {
    ...MessageWithAttachments
  }
}
    ${MessageWithAttachmentsFragmentDoc}`;
export const CurrentThreadInfoFragmentDoc = gql`
    fragment CurrentThreadInfo on MessageThreadType {
  id
  subject
  ...MessagesWithAttachments
  student {
    id
    user {
      id
      ...User
    }
    segments {
      id
      createdAt
    }
  }
  mentor {
    id
    user {
      id
      ...User
    }
  }
}
    ${MessagesWithAttachmentsFragmentDoc}
${UserFragmentDoc}`;
export const CarouselDataFragmentDoc = gql`
    fragment CarouselData on MessageThreadType {
  id
  subject
  unreadCount
  s3ThumbnailUrl
  lastMessageAt
  exercises {
    id
  }
}
    `;
export const MessageThreadUpdateFragmentDoc = gql`
    fragment MessageThreadUpdate on MessageThreadType {
  id
  subject
  s3ThumbnailUrl
  unreadCount
  lastActivity
  exercises {
    id
  }
}
    `;
export const MessageUpdateFragmentDoc = gql`
    fragment MessageUpdate on MessageType {
  id
  contents
  read
  createdAt
  complete
  attachments {
    id
    s3Url
    s3ThumbnailUrl
    complete
  }
  sender {
    id
    userType
    ...User
  }
  messageThreadId
}
    ${UserFragmentDoc}`;
export const MessageAttachmentUpdateFragmentDoc = gql`
    fragment MessageAttachmentUpdate on MessageAttachmentType {
  id
  s3Url
  s3ThumbnailUrl
  messageId
  complete
}
    `;
export const MentorHomeDocument = gql`
    query MentorHome {
  studentMentorRelationships {
    ...MentorHomeRelationshipInfo
  }
  enrollments {
    id
    curriculum {
      ...Curriculum
    }
  }
}
    ${MentorHomeRelationshipInfoFragmentDoc}
${CurriculumFragmentDoc}`;

/**
 * __useMentorHomeQuery__
 *
 * To run a query within a React component, call `useMentorHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMentorHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMentorHomeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMentorHomeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MentorHomeQuery, MentorHomeQueryVariables>) {
        return ApolloReactHooks.useQuery<MentorHomeQuery, MentorHomeQueryVariables>(MentorHomeDocument, baseOptions);
      }
export function useMentorHomeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MentorHomeQuery, MentorHomeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MentorHomeQuery, MentorHomeQueryVariables>(MentorHomeDocument, baseOptions);
        }
export type MentorHomeQueryHookResult = ReturnType<typeof useMentorHomeQuery>;
export type MentorHomeLazyQueryHookResult = ReturnType<typeof useMentorHomeLazyQuery>;
export type MentorHomeQueryResult = ApolloReactCommon.QueryResult<MentorHomeQuery, MentorHomeQueryVariables>;
export const StudentInfoDocument = gql`
    query StudentInfo {
  user {
    ...User
  }
  enrollments {
    id
    student {
      id
      studentType
    }
    curriculum {
      ...Curriculum
    }
  }
  studentMentorRelationships {
    id
    startedAt
    endedAt
    messageThread {
      id
    }
    mentor {
      id
      user {
        ...User
      }
    }
  }
}
    ${UserFragmentDoc}
${CurriculumFragmentDoc}`;

/**
 * __useStudentInfoQuery__
 *
 * To run a query within a React component, call `useStudentInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StudentInfoQuery, StudentInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<StudentInfoQuery, StudentInfoQueryVariables>(StudentInfoDocument, baseOptions);
      }
export function useStudentInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudentInfoQuery, StudentInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StudentInfoQuery, StudentInfoQueryVariables>(StudentInfoDocument, baseOptions);
        }
export type StudentInfoQueryHookResult = ReturnType<typeof useStudentInfoQuery>;
export type StudentInfoLazyQueryHookResult = ReturnType<typeof useStudentInfoLazyQuery>;
export type StudentInfoQueryResult = ApolloReactCommon.QueryResult<StudentInfoQuery, StudentInfoQueryVariables>;
export const SignInUserDocument = gql`
    mutation SignInUser($email: String!, $password: String!) {
  tokenAuth(email: $email, password: $password) {
    token
  }
}
    `;
export type SignInUserMutationFn = ApolloReactCommon.MutationFunction<SignInUserMutation, SignInUserMutationVariables>;

/**
 * __useSignInUserMutation__
 *
 * To run a mutation, you first call `useSignInUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInUserMutation, { data, loading, error }] = useSignInUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignInUserMutation, SignInUserMutationVariables>) {
        return ApolloReactHooks.useMutation<SignInUserMutation, SignInUserMutationVariables>(SignInUserDocument, baseOptions);
      }
export type SignInUserMutationHookResult = ReturnType<typeof useSignInUserMutation>;
export type SignInUserMutationResult = ApolloReactCommon.MutationResult<SignInUserMutation>;
export type SignInUserMutationOptions = ApolloReactCommon.BaseMutationOptions<SignInUserMutation, SignInUserMutationVariables>;
export const GenerateResetPasswordEmailDocument = gql`
    mutation GenerateResetPasswordEmail($email: String!) {
  generatePasswordEmail(email: $email) {
    ok
  }
}
    `;
export type GenerateResetPasswordEmailMutationFn = ApolloReactCommon.MutationFunction<GenerateResetPasswordEmailMutation, GenerateResetPasswordEmailMutationVariables>;

/**
 * __useGenerateResetPasswordEmailMutation__
 *
 * To run a mutation, you first call `useGenerateResetPasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateResetPasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateResetPasswordEmailMutation, { data, loading, error }] = useGenerateResetPasswordEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGenerateResetPasswordEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateResetPasswordEmailMutation, GenerateResetPasswordEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateResetPasswordEmailMutation, GenerateResetPasswordEmailMutationVariables>(GenerateResetPasswordEmailDocument, baseOptions);
      }
export type GenerateResetPasswordEmailMutationHookResult = ReturnType<typeof useGenerateResetPasswordEmailMutation>;
export type GenerateResetPasswordEmailMutationResult = ApolloReactCommon.MutationResult<GenerateResetPasswordEmailMutation>;
export type GenerateResetPasswordEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateResetPasswordEmailMutation, GenerateResetPasswordEmailMutationVariables>;
export const SetNewPasswordWithTokenDocument = gql`
    mutation SetNewPasswordWithToken($password: String!, $token: String!) {
  consumeLoginToken(password: $password, uuid: $token) {
    token
    expired
  }
}
    `;
export type SetNewPasswordWithTokenMutationFn = ApolloReactCommon.MutationFunction<SetNewPasswordWithTokenMutation, SetNewPasswordWithTokenMutationVariables>;

/**
 * __useSetNewPasswordWithTokenMutation__
 *
 * To run a mutation, you first call `useSetNewPasswordWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNewPasswordWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNewPasswordWithTokenMutation, { data, loading, error }] = useSetNewPasswordWithTokenMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSetNewPasswordWithTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetNewPasswordWithTokenMutation, SetNewPasswordWithTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<SetNewPasswordWithTokenMutation, SetNewPasswordWithTokenMutationVariables>(SetNewPasswordWithTokenDocument, baseOptions);
      }
export type SetNewPasswordWithTokenMutationHookResult = ReturnType<typeof useSetNewPasswordWithTokenMutation>;
export type SetNewPasswordWithTokenMutationResult = ApolloReactCommon.MutationResult<SetNewPasswordWithTokenMutation>;
export type SetNewPasswordWithTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<SetNewPasswordWithTokenMutation, SetNewPasswordWithTokenMutationVariables>;
export const LogInWithUuidDocument = gql`
    mutation LogInWithUUID($uuid: String!) {
  consumeLoginToken(uuid: $uuid) {
    token
    expired
  }
}
    `;
export type LogInWithUuidMutationFn = ApolloReactCommon.MutationFunction<LogInWithUuidMutation, LogInWithUuidMutationVariables>;

/**
 * __useLogInWithUuidMutation__
 *
 * To run a mutation, you first call `useLogInWithUuidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogInWithUuidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logInWithUuidMutation, { data, loading, error }] = useLogInWithUuidMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useLogInWithUuidMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogInWithUuidMutation, LogInWithUuidMutationVariables>) {
        return ApolloReactHooks.useMutation<LogInWithUuidMutation, LogInWithUuidMutationVariables>(LogInWithUuidDocument, baseOptions);
      }
export type LogInWithUuidMutationHookResult = ReturnType<typeof useLogInWithUuidMutation>;
export type LogInWithUuidMutationResult = ApolloReactCommon.MutationResult<LogInWithUuidMutation>;
export type LogInWithUuidMutationOptions = ApolloReactCommon.BaseMutationOptions<LogInWithUuidMutation, LogInWithUuidMutationVariables>;
export const LogActivityProgressDocument = gql`
    mutation LogActivityProgress($curriculumId: Int!, $activityId: String!, $stars: Int!, $timeStamp: DateTime!, $isPractice: Boolean = true, $datePracticed: Date) {
  reportExerciseProgress(curriculumId: $curriculumId, exerciseId: $activityId, rating: $stars, startedAt: $timeStamp, endedAt: $timeStamp, repeats: $isPractice, datePracticed: $datePracticed) {
    progress {
      id
    }
  }
}
    `;
export type LogActivityProgressMutationFn = ApolloReactCommon.MutationFunction<LogActivityProgressMutation, LogActivityProgressMutationVariables>;

/**
 * __useLogActivityProgressMutation__
 *
 * To run a mutation, you first call `useLogActivityProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogActivityProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logActivityProgressMutation, { data, loading, error }] = useLogActivityProgressMutation({
 *   variables: {
 *      curriculumId: // value for 'curriculumId'
 *      activityId: // value for 'activityId'
 *      stars: // value for 'stars'
 *      timeStamp: // value for 'timeStamp'
 *      isPractice: // value for 'isPractice'
 *      datePracticed: // value for 'datePracticed'
 *   },
 * });
 */
export function useLogActivityProgressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogActivityProgressMutation, LogActivityProgressMutationVariables>) {
        return ApolloReactHooks.useMutation<LogActivityProgressMutation, LogActivityProgressMutationVariables>(LogActivityProgressDocument, baseOptions);
      }
export type LogActivityProgressMutationHookResult = ReturnType<typeof useLogActivityProgressMutation>;
export type LogActivityProgressMutationResult = ApolloReactCommon.MutationResult<LogActivityProgressMutation>;
export type LogActivityProgressMutationOptions = ApolloReactCommon.BaseMutationOptions<LogActivityProgressMutation, LogActivityProgressMutationVariables>;
export const CreateMessageDocument = gql`
    mutation CreateMessage($contents: String!, $messageThreadId: Int!, $exerciseId: Int) {
  createMessage(contents: $contents, messageThreadId: $messageThreadId, exerciseId: $exerciseId) {
    message {
      id
    }
  }
}
    `;
export type CreateMessageMutationFn = ApolloReactCommon.MutationFunction<CreateMessageMutation, CreateMessageMutationVariables>;

/**
 * __useCreateMessageMutation__
 *
 * To run a mutation, you first call `useCreateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageMutation, { data, loading, error }] = useCreateMessageMutation({
 *   variables: {
 *      contents: // value for 'contents'
 *      messageThreadId: // value for 'messageThreadId'
 *      exerciseId: // value for 'exerciseId'
 *   },
 * });
 */
export function useCreateMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMessageMutation, CreateMessageMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMessageMutation, CreateMessageMutationVariables>(CreateMessageDocument, baseOptions);
      }
export type CreateMessageMutationHookResult = ReturnType<typeof useCreateMessageMutation>;
export type CreateMessageMutationResult = ApolloReactCommon.MutationResult<CreateMessageMutation>;
export type CreateMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMessageMutation, CreateMessageMutationVariables>;
export const ReadMessagesDocument = gql`
    mutation ReadMessages($ids: [Int]!) {
  readMessages(ids: $ids) {
    ok
  }
}
    `;
export type ReadMessagesMutationFn = ApolloReactCommon.MutationFunction<ReadMessagesMutation, ReadMessagesMutationVariables>;

/**
 * __useReadMessagesMutation__
 *
 * To run a mutation, you first call `useReadMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readMessagesMutation, { data, loading, error }] = useReadMessagesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useReadMessagesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReadMessagesMutation, ReadMessagesMutationVariables>) {
        return ApolloReactHooks.useMutation<ReadMessagesMutation, ReadMessagesMutationVariables>(ReadMessagesDocument, baseOptions);
      }
export type ReadMessagesMutationHookResult = ReturnType<typeof useReadMessagesMutation>;
export type ReadMessagesMutationResult = ApolloReactCommon.MutationResult<ReadMessagesMutation>;
export type ReadMessagesMutationOptions = ApolloReactCommon.BaseMutationOptions<ReadMessagesMutation, ReadMessagesMutationVariables>;
export const CreateMessageAttachmentThreadDocument = gql`
    mutation CreateMessageAttachmentThread($relationshipId: Int!, $subject: String = "Attachment", $exerciseProgressId: Int) {
  createMessageThread(contents: "VideoThread", relationshipId: $relationshipId, subject: $subject, exerciseProgressId: $exerciseProgressId) {
    messageThread {
      id
    }
  }
}
    `;
export type CreateMessageAttachmentThreadMutationFn = ApolloReactCommon.MutationFunction<CreateMessageAttachmentThreadMutation, CreateMessageAttachmentThreadMutationVariables>;

/**
 * __useCreateMessageAttachmentThreadMutation__
 *
 * To run a mutation, you first call `useCreateMessageAttachmentThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageAttachmentThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageAttachmentThreadMutation, { data, loading, error }] = useCreateMessageAttachmentThreadMutation({
 *   variables: {
 *      relationshipId: // value for 'relationshipId'
 *      subject: // value for 'subject'
 *      exerciseProgressId: // value for 'exerciseProgressId'
 *   },
 * });
 */
export function useCreateMessageAttachmentThreadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMessageAttachmentThreadMutation, CreateMessageAttachmentThreadMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMessageAttachmentThreadMutation, CreateMessageAttachmentThreadMutationVariables>(CreateMessageAttachmentThreadDocument, baseOptions);
      }
export type CreateMessageAttachmentThreadMutationHookResult = ReturnType<typeof useCreateMessageAttachmentThreadMutation>;
export type CreateMessageAttachmentThreadMutationResult = ApolloReactCommon.MutationResult<CreateMessageAttachmentThreadMutation>;
export type CreateMessageAttachmentThreadMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMessageAttachmentThreadMutation, CreateMessageAttachmentThreadMutationVariables>;
export const StartMessageDocument = gql`
    mutation StartMessage($messageThreadId: Int!, $exerciseId: Int, $contents: String = "") {
  startMessage(contents: $contents, messageThreadId: $messageThreadId, exerciseId: $exerciseId) {
    message {
      id
    }
  }
}
    `;
export type StartMessageMutationFn = ApolloReactCommon.MutationFunction<StartMessageMutation, StartMessageMutationVariables>;

/**
 * __useStartMessageMutation__
 *
 * To run a mutation, you first call `useStartMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startMessageMutation, { data, loading, error }] = useStartMessageMutation({
 *   variables: {
 *      messageThreadId: // value for 'messageThreadId'
 *      exerciseId: // value for 'exerciseId'
 *      contents: // value for 'contents'
 *   },
 * });
 */
export function useStartMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StartMessageMutation, StartMessageMutationVariables>) {
        return ApolloReactHooks.useMutation<StartMessageMutation, StartMessageMutationVariables>(StartMessageDocument, baseOptions);
      }
export type StartMessageMutationHookResult = ReturnType<typeof useStartMessageMutation>;
export type StartMessageMutationResult = ApolloReactCommon.MutationResult<StartMessageMutation>;
export type StartMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<StartMessageMutation, StartMessageMutationVariables>;
export const CreateMessageAttachmentDocument = gql`
    mutation CreateMessageAttachment($messageId: Int!) {
  createMessageAttachment(id: $messageId) {
    messageAttachment {
      id
      uploadData {
        url
        fields
      }
    }
  }
}
    `;
export type CreateMessageAttachmentMutationFn = ApolloReactCommon.MutationFunction<CreateMessageAttachmentMutation, CreateMessageAttachmentMutationVariables>;

/**
 * __useCreateMessageAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateMessageAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageAttachmentMutation, { data, loading, error }] = useCreateMessageAttachmentMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useCreateMessageAttachmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMessageAttachmentMutation, CreateMessageAttachmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMessageAttachmentMutation, CreateMessageAttachmentMutationVariables>(CreateMessageAttachmentDocument, baseOptions);
      }
export type CreateMessageAttachmentMutationHookResult = ReturnType<typeof useCreateMessageAttachmentMutation>;
export type CreateMessageAttachmentMutationResult = ApolloReactCommon.MutationResult<CreateMessageAttachmentMutation>;
export type CreateMessageAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMessageAttachmentMutation, CreateMessageAttachmentMutationVariables>;
export const PublishMessageDocument = gql`
    mutation PublishMessage($messageId: Int!, $exerciseId: Int) {
  publishMessage(id: $messageId, exerciseId: $exerciseId) {
    ok
  }
}
    `;
export type PublishMessageMutationFn = ApolloReactCommon.MutationFunction<PublishMessageMutation, PublishMessageMutationVariables>;

/**
 * __usePublishMessageMutation__
 *
 * To run a mutation, you first call `usePublishMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishMessageMutation, { data, loading, error }] = usePublishMessageMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *      exerciseId: // value for 'exerciseId'
 *   },
 * });
 */
export function usePublishMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PublishMessageMutation, PublishMessageMutationVariables>) {
        return ApolloReactHooks.useMutation<PublishMessageMutation, PublishMessageMutationVariables>(PublishMessageDocument, baseOptions);
      }
export type PublishMessageMutationHookResult = ReturnType<typeof usePublishMessageMutation>;
export type PublishMessageMutationResult = ApolloReactCommon.MutationResult<PublishMessageMutation>;
export type PublishMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<PublishMessageMutation, PublishMessageMutationVariables>;
export const CompleteMessageAttachmentDocument = gql`
    mutation CompleteMessageAttachment($attachmentId: Int!) {
  completeMessageAttachment(id: $attachmentId) {
    ok
  }
}
    `;
export type CompleteMessageAttachmentMutationFn = ApolloReactCommon.MutationFunction<CompleteMessageAttachmentMutation, CompleteMessageAttachmentMutationVariables>;

/**
 * __useCompleteMessageAttachmentMutation__
 *
 * To run a mutation, you first call `useCompleteMessageAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteMessageAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeMessageAttachmentMutation, { data, loading, error }] = useCompleteMessageAttachmentMutation({
 *   variables: {
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useCompleteMessageAttachmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompleteMessageAttachmentMutation, CompleteMessageAttachmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CompleteMessageAttachmentMutation, CompleteMessageAttachmentMutationVariables>(CompleteMessageAttachmentDocument, baseOptions);
      }
export type CompleteMessageAttachmentMutationHookResult = ReturnType<typeof useCompleteMessageAttachmentMutation>;
export type CompleteMessageAttachmentMutationResult = ApolloReactCommon.MutationResult<CompleteMessageAttachmentMutation>;
export type CompleteMessageAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CompleteMessageAttachmentMutation, CompleteMessageAttachmentMutationVariables>;
export const SetPushTokenDocument = gql`
    mutation SetPushToken($token: String!) {
  setPushToken(token: $token) {
    ok
  }
}
    `;
export type SetPushTokenMutationFn = ApolloReactCommon.MutationFunction<SetPushTokenMutation, SetPushTokenMutationVariables>;

/**
 * __useSetPushTokenMutation__
 *
 * To run a mutation, you first call `useSetPushTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPushTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPushTokenMutation, { data, loading, error }] = useSetPushTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSetPushTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetPushTokenMutation, SetPushTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<SetPushTokenMutation, SetPushTokenMutationVariables>(SetPushTokenDocument, baseOptions);
      }
export type SetPushTokenMutationHookResult = ReturnType<typeof useSetPushTokenMutation>;
export type SetPushTokenMutationResult = ApolloReactCommon.MutationResult<SetPushTokenMutation>;
export type SetPushTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<SetPushTokenMutation, SetPushTokenMutationVariables>;
export const CancelStripeSubscriptionNowDocument = gql`
    mutation CancelStripeSubscriptionNow($subscriptionId: String!) {
  cancelStripeSubscriptionNow(subscriptionId: $subscriptionId) {
    ok
  }
}
    `;
export type CancelStripeSubscriptionNowMutationFn = ApolloReactCommon.MutationFunction<CancelStripeSubscriptionNowMutation, CancelStripeSubscriptionNowMutationVariables>;

/**
 * __useCancelStripeSubscriptionNowMutation__
 *
 * To run a mutation, you first call `useCancelStripeSubscriptionNowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelStripeSubscriptionNowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelStripeSubscriptionNowMutation, { data, loading, error }] = useCancelStripeSubscriptionNowMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useCancelStripeSubscriptionNowMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelStripeSubscriptionNowMutation, CancelStripeSubscriptionNowMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelStripeSubscriptionNowMutation, CancelStripeSubscriptionNowMutationVariables>(CancelStripeSubscriptionNowDocument, baseOptions);
      }
export type CancelStripeSubscriptionNowMutationHookResult = ReturnType<typeof useCancelStripeSubscriptionNowMutation>;
export type CancelStripeSubscriptionNowMutationResult = ApolloReactCommon.MutationResult<CancelStripeSubscriptionNowMutation>;
export type CancelStripeSubscriptionNowMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelStripeSubscriptionNowMutation, CancelStripeSubscriptionNowMutationVariables>;
export const CancelStripeSubscriptionAtPeriodEndDocument = gql`
    mutation CancelStripeSubscriptionAtPeriodEnd($subscriptionId: String!) {
  cancelStripeSubscriptionAtPeriodEnd(subscriptionId: $subscriptionId) {
    ok
  }
}
    `;
export type CancelStripeSubscriptionAtPeriodEndMutationFn = ApolloReactCommon.MutationFunction<CancelStripeSubscriptionAtPeriodEndMutation, CancelStripeSubscriptionAtPeriodEndMutationVariables>;

/**
 * __useCancelStripeSubscriptionAtPeriodEndMutation__
 *
 * To run a mutation, you first call `useCancelStripeSubscriptionAtPeriodEndMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelStripeSubscriptionAtPeriodEndMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelStripeSubscriptionAtPeriodEndMutation, { data, loading, error }] = useCancelStripeSubscriptionAtPeriodEndMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useCancelStripeSubscriptionAtPeriodEndMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelStripeSubscriptionAtPeriodEndMutation, CancelStripeSubscriptionAtPeriodEndMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelStripeSubscriptionAtPeriodEndMutation, CancelStripeSubscriptionAtPeriodEndMutationVariables>(CancelStripeSubscriptionAtPeriodEndDocument, baseOptions);
      }
export type CancelStripeSubscriptionAtPeriodEndMutationHookResult = ReturnType<typeof useCancelStripeSubscriptionAtPeriodEndMutation>;
export type CancelStripeSubscriptionAtPeriodEndMutationResult = ApolloReactCommon.MutationResult<CancelStripeSubscriptionAtPeriodEndMutation>;
export type CancelStripeSubscriptionAtPeriodEndMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelStripeSubscriptionAtPeriodEndMutation, CancelStripeSubscriptionAtPeriodEndMutationVariables>;
export const ExtendTrialOnStripeSubscriptionScheduleDocument = gql`
    mutation ExtendTrialOnStripeSubscriptionSchedule($additionalTrialDays: Int!, $subscriptionScheduleId: String!, $priceId: String!, $iterationCount: Int!) {
  extendTrialOnStripeSubscriptionSchedule(subscriptionScheduleId: $subscriptionScheduleId, additionalTrialDays: $additionalTrialDays, priceId: $priceId, iterationCount: $iterationCount) {
    ok
  }
}
    `;
export type ExtendTrialOnStripeSubscriptionScheduleMutationFn = ApolloReactCommon.MutationFunction<ExtendTrialOnStripeSubscriptionScheduleMutation, ExtendTrialOnStripeSubscriptionScheduleMutationVariables>;

/**
 * __useExtendTrialOnStripeSubscriptionScheduleMutation__
 *
 * To run a mutation, you first call `useExtendTrialOnStripeSubscriptionScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtendTrialOnStripeSubscriptionScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extendTrialOnStripeSubscriptionScheduleMutation, { data, loading, error }] = useExtendTrialOnStripeSubscriptionScheduleMutation({
 *   variables: {
 *      additionalTrialDays: // value for 'additionalTrialDays'
 *      subscriptionScheduleId: // value for 'subscriptionScheduleId'
 *      priceId: // value for 'priceId'
 *      iterationCount: // value for 'iterationCount'
 *   },
 * });
 */
export function useExtendTrialOnStripeSubscriptionScheduleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExtendTrialOnStripeSubscriptionScheduleMutation, ExtendTrialOnStripeSubscriptionScheduleMutationVariables>) {
        return ApolloReactHooks.useMutation<ExtendTrialOnStripeSubscriptionScheduleMutation, ExtendTrialOnStripeSubscriptionScheduleMutationVariables>(ExtendTrialOnStripeSubscriptionScheduleDocument, baseOptions);
      }
export type ExtendTrialOnStripeSubscriptionScheduleMutationHookResult = ReturnType<typeof useExtendTrialOnStripeSubscriptionScheduleMutation>;
export type ExtendTrialOnStripeSubscriptionScheduleMutationResult = ApolloReactCommon.MutationResult<ExtendTrialOnStripeSubscriptionScheduleMutation>;
export type ExtendTrialOnStripeSubscriptionScheduleMutationOptions = ApolloReactCommon.BaseMutationOptions<ExtendTrialOnStripeSubscriptionScheduleMutation, ExtendTrialOnStripeSubscriptionScheduleMutationVariables>;
export const PauseStripeSubscriptionDocument = gql`
    mutation PauseStripeSubscription($subscriptionId: String!) {
  pauseStripeSubscription(subscriptionId: $subscriptionId) {
    ok
  }
}
    `;
export type PauseStripeSubscriptionMutationFn = ApolloReactCommon.MutationFunction<PauseStripeSubscriptionMutation, PauseStripeSubscriptionMutationVariables>;

/**
 * __usePauseStripeSubscriptionMutation__
 *
 * To run a mutation, you first call `usePauseStripeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseStripeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseStripeSubscriptionMutation, { data, loading, error }] = usePauseStripeSubscriptionMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function usePauseStripeSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PauseStripeSubscriptionMutation, PauseStripeSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<PauseStripeSubscriptionMutation, PauseStripeSubscriptionMutationVariables>(PauseStripeSubscriptionDocument, baseOptions);
      }
export type PauseStripeSubscriptionMutationHookResult = ReturnType<typeof usePauseStripeSubscriptionMutation>;
export type PauseStripeSubscriptionMutationResult = ApolloReactCommon.MutationResult<PauseStripeSubscriptionMutation>;
export type PauseStripeSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<PauseStripeSubscriptionMutation, PauseStripeSubscriptionMutationVariables>;
export const CreateAndBindPaymentMethodDocument = gql`
    mutation CreateAndBindPaymentMethod($cvc: String!, $expMonth: String!, $expYear: String!, $number: String!) {
  createAndBindPaymentMethod(cvc: $cvc, expMonth: $expMonth, expYear: $expYear, number: $number) {
    ok
  }
}
    `;
export type CreateAndBindPaymentMethodMutationFn = ApolloReactCommon.MutationFunction<CreateAndBindPaymentMethodMutation, CreateAndBindPaymentMethodMutationVariables>;

/**
 * __useCreateAndBindPaymentMethodMutation__
 *
 * To run a mutation, you first call `useCreateAndBindPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAndBindPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAndBindPaymentMethodMutation, { data, loading, error }] = useCreateAndBindPaymentMethodMutation({
 *   variables: {
 *      cvc: // value for 'cvc'
 *      expMonth: // value for 'expMonth'
 *      expYear: // value for 'expYear'
 *      number: // value for 'number'
 *   },
 * });
 */
export function useCreateAndBindPaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAndBindPaymentMethodMutation, CreateAndBindPaymentMethodMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAndBindPaymentMethodMutation, CreateAndBindPaymentMethodMutationVariables>(CreateAndBindPaymentMethodDocument, baseOptions);
      }
export type CreateAndBindPaymentMethodMutationHookResult = ReturnType<typeof useCreateAndBindPaymentMethodMutation>;
export type CreateAndBindPaymentMethodMutationResult = ApolloReactCommon.MutationResult<CreateAndBindPaymentMethodMutation>;
export type CreateAndBindPaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAndBindPaymentMethodMutation, CreateAndBindPaymentMethodMutationVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  user {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
      }
export function useCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = ApolloReactCommon.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const CurriculumProgressDocument = gql`
    query CurriculumProgress($curriculumId: Int!) {
  latestExerciseProgresses(curriculumId: $curriculumId) {
    id
    exerciseId
    startedAt
    repeats
    rating
  }
  datesPracticed
}
    `;

/**
 * __useCurriculumProgressQuery__
 *
 * To run a query within a React component, call `useCurriculumProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurriculumProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurriculumProgressQuery({
 *   variables: {
 *      curriculumId: // value for 'curriculumId'
 *   },
 * });
 */
export function useCurriculumProgressQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurriculumProgressQuery, CurriculumProgressQueryVariables>) {
        return ApolloReactHooks.useQuery<CurriculumProgressQuery, CurriculumProgressQueryVariables>(CurriculumProgressDocument, baseOptions);
      }
export function useCurriculumProgressLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurriculumProgressQuery, CurriculumProgressQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurriculumProgressQuery, CurriculumProgressQueryVariables>(CurriculumProgressDocument, baseOptions);
        }
export type CurriculumProgressQueryHookResult = ReturnType<typeof useCurriculumProgressQuery>;
export type CurriculumProgressLazyQueryHookResult = ReturnType<typeof useCurriculumProgressLazyQuery>;
export type CurriculumProgressQueryResult = ApolloReactCommon.QueryResult<CurriculumProgressQuery, CurriculumProgressQueryVariables>;
export const DatesStudentPracticedDocument = gql`
    query DatesStudentPracticed($studentId: Int!) {
  datesPracticed(studentId: $studentId)
}
    `;

/**
 * __useDatesStudentPracticedQuery__
 *
 * To run a query within a React component, call `useDatesStudentPracticedQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatesStudentPracticedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatesStudentPracticedQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useDatesStudentPracticedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DatesStudentPracticedQuery, DatesStudentPracticedQueryVariables>) {
        return ApolloReactHooks.useQuery<DatesStudentPracticedQuery, DatesStudentPracticedQueryVariables>(DatesStudentPracticedDocument, baseOptions);
      }
export function useDatesStudentPracticedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DatesStudentPracticedQuery, DatesStudentPracticedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DatesStudentPracticedQuery, DatesStudentPracticedQueryVariables>(DatesStudentPracticedDocument, baseOptions);
        }
export type DatesStudentPracticedQueryHookResult = ReturnType<typeof useDatesStudentPracticedQuery>;
export type DatesStudentPracticedLazyQueryHookResult = ReturnType<typeof useDatesStudentPracticedLazyQuery>;
export type DatesStudentPracticedQueryResult = ApolloReactCommon.QueryResult<DatesStudentPracticedQuery, DatesStudentPracticedQueryVariables>;
export const UnreadPollDocument = gql`
    query UnreadPoll {
  studentMentorRelationships {
    id
    unreadCount
  }
}
    `;

/**
 * __useUnreadPollQuery__
 *
 * To run a query within a React component, call `useUnreadPollQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnreadPollQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnreadPollQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnreadPollQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UnreadPollQuery, UnreadPollQueryVariables>) {
        return ApolloReactHooks.useQuery<UnreadPollQuery, UnreadPollQueryVariables>(UnreadPollDocument, baseOptions);
      }
export function useUnreadPollLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnreadPollQuery, UnreadPollQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UnreadPollQuery, UnreadPollQueryVariables>(UnreadPollDocument, baseOptions);
        }
export type UnreadPollQueryHookResult = ReturnType<typeof useUnreadPollQuery>;
export type UnreadPollLazyQueryHookResult = ReturnType<typeof useUnreadPollLazyQuery>;
export type UnreadPollQueryResult = ApolloReactCommon.QueryResult<UnreadPollQuery, UnreadPollQueryVariables>;
export const StudentChatBasicInfoDocument = gql`
    query StudentChatBasicInfo {
  user {
    id
    student {
      id
      studentType
    }
  }
  studentMentorRelationships {
    id
    startedAt
    endedAt
    mentor {
      id
      user {
        id
        firstName
        lastName
        fullName @client
      }
    }
    student {
      id
      user {
        id
        firstName
        lastName
      }
    }
    messageThread {
      id
    }
  }
}
    `;

/**
 * __useStudentChatBasicInfoQuery__
 *
 * To run a query within a React component, call `useStudentChatBasicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentChatBasicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentChatBasicInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentChatBasicInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StudentChatBasicInfoQuery, StudentChatBasicInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<StudentChatBasicInfoQuery, StudentChatBasicInfoQueryVariables>(StudentChatBasicInfoDocument, baseOptions);
      }
export function useStudentChatBasicInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudentChatBasicInfoQuery, StudentChatBasicInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StudentChatBasicInfoQuery, StudentChatBasicInfoQueryVariables>(StudentChatBasicInfoDocument, baseOptions);
        }
export type StudentChatBasicInfoQueryHookResult = ReturnType<typeof useStudentChatBasicInfoQuery>;
export type StudentChatBasicInfoLazyQueryHookResult = ReturnType<typeof useStudentChatBasicInfoLazyQuery>;
export type StudentChatBasicInfoQueryResult = ApolloReactCommon.QueryResult<StudentChatBasicInfoQuery, StudentChatBasicInfoQueryVariables>;
export const ChatThreadDocument = gql`
    query ChatThread($threadId: Int!) {
  messageThread(id: $threadId) {
    ...CurrentThreadInfo
  }
}
    ${CurrentThreadInfoFragmentDoc}`;

/**
 * __useChatThreadQuery__
 *
 * To run a query within a React component, call `useChatThreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatThreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatThreadQuery({
 *   variables: {
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useChatThreadQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ChatThreadQuery, ChatThreadQueryVariables>) {
        return ApolloReactHooks.useQuery<ChatThreadQuery, ChatThreadQueryVariables>(ChatThreadDocument, baseOptions);
      }
export function useChatThreadLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChatThreadQuery, ChatThreadQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ChatThreadQuery, ChatThreadQueryVariables>(ChatThreadDocument, baseOptions);
        }
export type ChatThreadQueryHookResult = ReturnType<typeof useChatThreadQuery>;
export type ChatThreadLazyQueryHookResult = ReturnType<typeof useChatThreadLazyQuery>;
export type ChatThreadQueryResult = ApolloReactCommon.QueryResult<ChatThreadQuery, ChatThreadQueryVariables>;
export const ChatAllThreadsDocument = gql`
    query ChatAllThreads($relationshipId: Int, $hasAttachments: Boolean = false) {
  messageThreads(relationshipId: $relationshipId, hasAttachments: $hasAttachments) {
    ...CarouselData
  }
}
    ${CarouselDataFragmentDoc}`;

/**
 * __useChatAllThreadsQuery__
 *
 * To run a query within a React component, call `useChatAllThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatAllThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatAllThreadsQuery({
 *   variables: {
 *      relationshipId: // value for 'relationshipId'
 *      hasAttachments: // value for 'hasAttachments'
 *   },
 * });
 */
export function useChatAllThreadsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ChatAllThreadsQuery, ChatAllThreadsQueryVariables>) {
        return ApolloReactHooks.useQuery<ChatAllThreadsQuery, ChatAllThreadsQueryVariables>(ChatAllThreadsDocument, baseOptions);
      }
export function useChatAllThreadsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChatAllThreadsQuery, ChatAllThreadsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ChatAllThreadsQuery, ChatAllThreadsQueryVariables>(ChatAllThreadsDocument, baseOptions);
        }
export type ChatAllThreadsQueryHookResult = ReturnType<typeof useChatAllThreadsQuery>;
export type ChatAllThreadsLazyQueryHookResult = ReturnType<typeof useChatAllThreadsLazyQuery>;
export type ChatAllThreadsQueryResult = ApolloReactCommon.QueryResult<ChatAllThreadsQuery, ChatAllThreadsQueryVariables>;
export const ChatActivityDocument = gql`
    query ChatActivity($relationshipId: Int, $since: DateTime) {
  chats(relationshipId: $relationshipId, since: $since) {
    since
    chats {
      __typename
      ... on MessageType {
        ...MessageUpdate
      }
      ... on MessageThreadType {
        ...MessageThreadUpdate
      }
      ... on MessageAttachmentType {
        ...MessageAttachmentUpdate
      }
    }
  }
}
    ${MessageUpdateFragmentDoc}
${MessageThreadUpdateFragmentDoc}
${MessageAttachmentUpdateFragmentDoc}`;

/**
 * __useChatActivityQuery__
 *
 * To run a query within a React component, call `useChatActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatActivityQuery({
 *   variables: {
 *      relationshipId: // value for 'relationshipId'
 *      since: // value for 'since'
 *   },
 * });
 */
export function useChatActivityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ChatActivityQuery, ChatActivityQueryVariables>) {
        return ApolloReactHooks.useQuery<ChatActivityQuery, ChatActivityQueryVariables>(ChatActivityDocument, baseOptions);
      }
export function useChatActivityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChatActivityQuery, ChatActivityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ChatActivityQuery, ChatActivityQueryVariables>(ChatActivityDocument, baseOptions);
        }
export type ChatActivityQueryHookResult = ReturnType<typeof useChatActivityQuery>;
export type ChatActivityLazyQueryHookResult = ReturnType<typeof useChatActivityLazyQuery>;
export type ChatActivityQueryResult = ApolloReactCommon.QueryResult<ChatActivityQuery, ChatActivityQueryVariables>;