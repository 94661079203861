import React from 'react';
import { Box, Row, Text } from '../styled';
import { FillIcon, StrokeIcon } from '../Icon';
import { Divider } from '../Divider';
import { colors, fonts } from '../../constants/theme';
import { shortYearDatestring } from 'sonora-util/dateUtils';
import { CardContainer, InstallmentBar } from './styled';
import { IStripeSubscription } from 'sonora-types';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
// import { Button } from '../Button';
import { TextButton } from './TextButton';

export interface MembershipCardProps {
  details: IStripeSubscription;
  currentInstallment?: number;
  onPress?: () => void;
  onEdit?: () => void;
  onCancel?: () => void;
}

const INSTALLMENTS = Array(5).fill(0);

export const MembershipCard: React.FC<MembershipCardProps> = ({
  details,
  currentInstallment = 1,
  onPress,
}) => {
  const isInstallments = details.type === 'installments';
  const isCancelled = details.status == "canceled";
  const price = details.billingAmount/100;
  const isComplete = details.status === 'completed'

  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <CardContainer>
        <Box>
          <Row marginBottom={12}>
            <Text color={colors.tanzanite} size={16} marginRight={12}>
              Guitar Intensive - Pro access
            </Text>
            {isComplete && (
              <StrokeIcon name="check" color={colors.validGreen} />
            )}
          </Row>
          <Row marginBottom={12}>
            {isInstallments ? (
              INSTALLMENTS.map((_, i) => (
                <InstallmentBar
                  key={i}
                  width={43}
                  color={
                    i < currentInstallment - 1
                      ? colors.amethyst
                      : i === currentInstallment - 1
                      ? colors.tanzanite
                      : colors.purpley
                  }
                />
              ))
            ) : (
              <InstallmentBar width={263} color={colors.amethyst} />
            )}
          </Row>
          <Row>
            <Text marginRight={4} size={16}>
              Payment plan:
            </Text>
            {!isCancelled ? (
              isInstallments ? (
                <Text font={fonts.thin} size={16}>
                  5 installments ({currentInstallment}/ 5)
                </Text>
                ) : (
                  <Text font={fonts.thin} size={16}>
                    upfront
                  </Text>
                )
            ) : (
              <Text font={fonts.thin} size={16}>
                Cancelled
              </Text>
            )}
          </Row>
          <Row>
            <Text marginRight={4} size={16}>
              Next payment:
            </Text>
            {!isCancelled ? (
              <Text font={fonts.thin} size={16}>
                {shortYearDatestring(details.startDate)}
              </Text>
              ) : (
              <Text font={fonts.thin} size={16}>
                none
              </Text>
            )}
          </Row>
        </Box>
        {!isCancelled ? (
          <Row>
            <Text color={colors.purpley} size={24}>
              $
            </Text>
            <Text color={colors.tanzanite} size={24} marginRight={5}>
              {price}
            </Text>
            <FillIcon name="pageRight" />
          </Row>
        ) : (
          <Row>
            <Text color={colors.purpley} size={24}>
              $
            </Text>
            <Text color={colors.tanzanite} textDecoration="line-through" size={24} marginRight={5}>
              {price}
            </Text>
            <FillIcon name="pageRight" />
          </Row>
        )}  
      </CardContainer>
    </TouchableWithoutFeedback>
  );
};

export interface MembershipCardTrialProps {
  details: IStripeSubscription;
  currentInstallment?: number;
  onPress?: () => void;
  onEdit?: () => void;
  onCancel?: () => void;
  onBuyTrial?: () => void;
}

export const MembershipCardTrial: React.FC<MembershipCardTrialProps> = ({
  details,
  currentInstallment = 1,
  onPress,
  // onEdit,
  onCancel,
  // onBuyTrial,
}) => {
  const isInstallments = details.type === 'installments';
  const price = details.billingAmount/100;

  const trialDaysRemaining =
    details?.status === 'trialing' && details?.endDate
      ? Math.round(
          (details?.endDate?.getTime() - new Date().getTime()) / 86400000
        )
      : 0;

  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <CardContainer>
        <Box flex={1}>
          <Row justifyContent="space-between">
            <Box>
              <Row marginBottom={12}>
                <Text color={colors.tanzanite} size={16} marginRight={12}>
                  Guitar Intensive - Pro access
                </Text>
              </Row>
              <Box paddingBottom={24}>
                <Row marginBottom={12}>
                  <FillIcon name="timer" size="s" />
                  <Text marginLeft={10} size={16}>
                    Free 7-day trial:
                  </Text>
                  <Text font={fonts.thin} size={16} marginLeft={4}>
                    {trialDaysRemaining} day remaining
                  </Text>
                </Row>
                <Row>
                  {/* <TextButton
                    text="Edit"
                    color={colors.neutralBlue}
                    onPress={onEdit}
                  />
                  <Box width="12px" /> */}
                  {details?.status === 'trialing' && (
                    <TextButton
                      text="Cancel"
                      color={colors.alertRed}
                      onPress={onCancel}
                    />
                  )}
                </Row>
              </Box>
            </Box>
            {/* <Button type="Special" text="Buy Now" onPress={onBuyTrial} /> */}
          </Row>
          <Divider marginBottom={24} />
          <Row justifyContent="space-between">
            <Box>
              <Row marginBottom={12}>
                {isInstallments ? (
                  INSTALLMENTS.map((_, i) => (
                    <InstallmentBar
                      key={i}
                      width={43}
                      color={
                        i < currentInstallment - 1
                          ? colors.amethyst
                          : i === currentInstallment - 1
                          ? colors.tanzanite
                          : colors.purpley
                      }
                    />
                  ))
                ) : (
                  <InstallmentBar width={263} color={colors.amethyst} />
                )}
              </Row>
              <Row>
                <Text marginRight={4} size={16}>
                  Payment plan:
                </Text>
                {isInstallments ? (
                  <Text font={fonts.thin} size={16}>
                    5 installments ({currentInstallment}/5)
                  </Text>
                ) : (
                  <Text font={fonts.thin} size={16}>
                    upfront
                  </Text>
                )}
              </Row>
              <Row>
                <Text marginRight={4} size={16}>
                  {isInstallments ? 'Next payment:' : 'Payment Due:'}
                </Text>
                <Text font={fonts.thin} size={16}>
                  {shortYearDatestring(details.endDate)}
                </Text>
              </Row>
            </Box>
            <Row>
              <Text color={colors.purpley} size={24}>
                $
              </Text>
              <Text color={colors.tanzanite} size={24} marginRight={5}>
                {price}
              </Text>
              <FillIcon name="pageRight" />
            </Row>
          </Row>
        </Box>
      </CardContainer>
    </TouchableWithoutFeedback>
  );
};
