// author: @fullstackdevs14
import React from 'react';
import { startOfToday, addWeeks, addMonths } from 'date-fns';

import { Box } from '../styled';
import { CalendarHeader } from './CalendarHeader';
import { CalendarBody } from './CalendarBody';
import { CalendarProps } from './types';

export const Calendar: React.FC<CalendarProps> = ({
  streakDayCount,
  view = 'month',
  highlightedDays,
}) => {
  const [date, setDate] = React.useState(startOfToday());

  const goToNextPage = () => {
    if (view === 'month') {
      setDate(addMonths(date, 1));
    } else {
      setDate(addWeeks(date, 1));
    }
  };

  const goToPreviousPage = () => {
    if (view === 'month') {
      setDate(addMonths(date, -1));
    } else {
      setDate(addWeeks(date, -1));
    }
  };

  return (
    <Box>
      <CalendarHeader
        date={date}
        streakDayCount={streakDayCount}
        view={view}
        onNext={goToNextPage}
        onPrevious={goToPreviousPage}
      />
      <CalendarBody
        date={date}
        view={view}
        highlightedDays={highlightedDays || []}
      />
    </Box>
  );
};
