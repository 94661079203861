import React from 'react';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import { LinearGradient } from 'expo-linear-gradient';

import { Box, Text, Row } from '../styled';
import { FillIcon } from '../Icon';
import { ProgressBar } from '../ProgressBar';
import { fonts, colors, theme } from '../../constants/theme';
import { convertHexToRGBA, useIsWidthLessThan } from '../../util';
import { CourseMapHeaderProps, StudentHeaderProps } from './types';
import { GradientCircle } from './GradientCircle';

const BackButtonWrapper = styled.View`
  position: absolute;
  z-index: 1;
  height: 100%;
  justify-content: center;
  ${(props: { isSmall?: boolean }) =>
    props.isSmall
      ? `
    left: 15px;
  `
      : `
    left: 24px;
  `}
`;

const GradientBorder = styled(LinearGradient)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 4px;
  z-index: 0;
`;

export const CourseMapHeader: React.FC<CourseMapHeaderProps> = (
  props: CourseMapHeaderProps
) => {
  const isSmall = useIsWidthLessThan(600);

  return isSmall ? (
    <CourseMapHeaderSmall {...props} />
  ) : (
    <CourseMapHeaderLarge {...props} />
  );
};

type StudentStatsProps = {
  studentInfo: StudentHeaderProps;
  textColor: string | undefined;
  progressBackgroundColor: string | undefined;
  isMainHeader?: boolean;
};

const statsForInfo = (studentInfo: StudentHeaderProps) => {
  const {
    completePercent,
    numCompletedModules,
    numAllStars,
    numUnlockedStars,
    numAllModules,
  } = studentInfo;
  const moduleCompletion = `${numCompletedModules}/${numAllModules}`;
  const starsCompletion = `${numUnlockedStars}/${numAllStars}`;
  return {
    moduleCompletion,
    starsCompletion,
    completePercent,
  };
};

const StudentStatsSmall: React.FC<StudentStatsProps> = ({
  studentInfo,
  textColor,
  progressBackgroundColor,
  isMainHeader,
}) => {
  const { moduleCompletion, starsCompletion, completePercent } = statsForInfo(
    studentInfo
  );

  return (
    <Box>
      <Row
        flexWrap="wrap"
        justifyContent={isMainHeader ? 'center' : 'flex-start'}
      >
        <Row
          marginLeft={isMainHeader ? 12 : 0}
          marginRight={12}
          marginBottom={6}
        >
          <Text marginRight={4} font={fonts.bold} color={textColor}>
            {moduleCompletion}
          </Text>
          <Text font={fonts.thin} color={textColor}>
            activities complete
          </Text>
        </Row>
        <Row
          marginLeft={isMainHeader ? 12 : 0}
          marginRight={12}
          marginBottom={6}
        >
          <Text marginRight={4} font={fonts.bold} color={textColor}>
            {completePercent}%
          </Text>
          <Text font={fonts.thin} color={textColor}>
            of course complete
          </Text>
        </Row>
      </Row>
      <Row>
        <Box flex={1} marginRight={20}>
          <ProgressBar
            percentage={completePercent}
            backgroundColor={progressBackgroundColor}
          />
        </Box>
        <Text marginRight={8} font={fonts.thin} color={textColor}>
          {starsCompletion}
        </Text>
        <FillIcon name="starYes" color={colors.bling} />
      </Row>
    </Box>
  );
};

const StudentStatsLarge: React.FC<StudentStatsProps> = ({
  studentInfo,
  progressBackgroundColor,
  textColor,
}) => {
  const { moduleCompletion, starsCompletion, completePercent } = statsForInfo(
    studentInfo
  );

  return (
    <Box>
      <Row justifyContent="flex-start" marginBottom={6}>
        <Row
          paddingRight={12}
          borderRightWidth={1}
          borderColor={colors.purpley}
        >
          <Text marginRight={4} font={fonts.bold} color={textColor}>
            {moduleCompletion}
          </Text>
          <Text font={fonts.thin} color={textColor}>
            activities complete
          </Text>
        </Row>
        <Row marginLeft={12}>
          <Text marginRight={4} font={fonts.bold} color={textColor}>
            {completePercent}%
          </Text>
          <Text font={fonts.thin} color={textColor}>
            of course complete
          </Text>
        </Row>
      </Row>
      <Row>
        <Box flex={1} marginRight={20}>
          <ProgressBar
            percentage={completePercent}
            backgroundColor={progressBackgroundColor}
          />
        </Box>
        <Text marginRight={8} font={fonts.thin} color={textColor}>
          {starsCompletion}
        </Text>
        <FillIcon name="starYes" color={colors.bling} />
      </Row>
    </Box>
  );
};

export const CourseMapHeaderSmall: React.FC<CourseMapHeaderProps> = ({
  title,
  letter,
  isMentor,
  studentInfo,
  hasBorder,
  backgroundColor,
  progressBackgroundColor,
  titleColor,
  textColor,
  subtitleColor,
  isMainHeader,
  hasBackButton,
  onBack,
}) => {
  return (
    <Box width="100%" backgroundColor={backgroundColor} minHeight={130}>
      {hasBackButton ? (
        <BackButtonWrapper isSmall>
          <TouchableOpacity onPress={onBack}>
            <Row>
              <FillIcon name="pageLeft" color={textColor} size="l" />
            </Row>
          </TouchableOpacity>
        </BackButtonWrapper>
      ) : null}
      <Box
        paddingLeft={isMainHeader ? 20 : 60}
        paddingRight={20}
        paddingY={20}
        flex={isMentor ? 1 : 'none'}
        justifyContent="center"
      >
        {isMentor && (
          <Text
            marginBottom={12}
            color={subtitleColor || convertHexToRGBA(colors.tanzanite, 80)}
            textAlign="center"
          >
            COURSE MAP
          </Text>
        )}
        <Text
          color={titleColor}
          font={fonts.headline}
          size={27}
          textAlign={isMainHeader ? 'center' : 'left'}
          marginBottom={isMentor ? -4 : 6}
        >
          {title}
        </Text>
        {!isMentor && studentInfo && (
          <StudentStatsSmall
            progressBackgroundColor={progressBackgroundColor}
            textColor={textColor}
            studentInfo={studentInfo}
            isMainHeader={isMainHeader}
          />
        )}
      </Box>
      {hasBorder && (
        <Box height="4px" width="100%">
          <GradientBorder {...theme.common.horizontalGradient} />
        </Box>
      )}
    </Box>
  );
};

export const CourseMapHeaderLarge: React.FC<CourseMapHeaderProps> = ({
  title,
  letter,
  isMentor,
  studentInfo,
  hasBorder,
  backgroundColor,
  progressBackgroundColor,
  titleColor,
  subtitleColor,
  textColor,
  hasBackButton,
  onBack,
}) => {
  return (
    <Box
      alignItems="center"
      width="100%"
      backgroundColor={backgroundColor}
      minHeight={140}
    >
      {hasBackButton ? (
        <BackButtonWrapper>
          <TouchableOpacity onPress={onBack}>
            <Row marginTop={10}>
              <FillIcon name="pageLeft" color={textColor} />
              <Text color={textColor}>BACK</Text>
            </Row>
          </TouchableOpacity>
        </BackButtonWrapper>
      ) : null}
      <Box paddingX={24} paddingY={24} flex={1} justifyContent="center">
        {isMentor && (
          <Text
            marginBottom={12}
            color={subtitleColor || convertHexToRGBA(colors.tanzanite, 80)}
            textAlign="center"
          >
            COURSE MAP
          </Text>
        )}
        <Text
          color={titleColor}
          font={fonts.headline}
          size={27}
          textAlign="center"
          marginBottom={8}
        >
          {title}
        </Text>
        {!isMentor && (
          <Row>
            {letter ? (
              <Box marginRight={22}>
                <GradientCircle letter={letter} size={46} />
              </Box>
            ) : null}
            {studentInfo && (
              <StudentStatsLarge
                progressBackgroundColor={progressBackgroundColor}
                textColor={textColor}
                studentInfo={studentInfo}
              />
            )}
          </Row>
        )}
      </Box>
      {hasBorder && (
        <Box height="4px" width="100%">
          <GradientBorder {...theme.common.horizontalGradient} />
        </Box>
      )}
    </Box>
  );
};
