import React from 'react';
import { IMessage, Message, MessageProps } from 'react-native-gifted-chat';

const messageStyles = {
  maxWidth: 482,
  width: '100%',
  marginHorizontal: 'auto',
};

export const RenderMessage: React.FC<MessageProps<IMessage>> = (props) => {
  return (
    <Message
      {...props}
      containerStyle={{
        left: messageStyles,
        right: messageStyles,
      }}
    />
  );
};
