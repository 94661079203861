import React, { useContext, useMemo } from 'react';

import { ChatThreadType, ChatUserType } from 'sonora-types';
import { StudentContext } from 'sonora-containers/index';
import { hashChatThread } from 'sonora-util/icanhashcheezeburger';
import {
  chatMessageToGiftedMessage,
  dbUserToGiftedUser,
  noIncompleteMessage,
} from 'sonora-util/chat';
import { ChatThread } from 'sonora-design-system/components/Chat/ChatThread';
import { GiftedMessage } from 'sonora-design-system/components/Chat/types';
import { MessagingDisabledAlert } from './MessagingDisabledAlert';
import {
  VideoSubmissionStack,
  VideoSubmissionStackExtraData,
} from '../../router/screens/video-submission/VideoSubmissionStack';
import { ModalPresenterContext } from 'sonora-design-system';
interface ChatThreadWrapperProps {
  messageThread: ChatThreadType;
  chatUser: ChatUserType;
  threadId: number;
  isMessagingDisabled?: boolean;
  onSend: (messages: GiftedMessage[]) => void;
}

export const ChatThreadWrapper: React.FC<ChatThreadWrapperProps> = (props) => {
  const { presentComponentAsModal } = useContext(ModalPresenterContext);
  const { relationshipStatus } = useContext(StudentContext);
  const messageThreadHash = hashChatThread(props.messageThread);
  const currentUser = dbUserToGiftedUser(props.chatUser);
  const messages = useMemo(() => {
    let messages = props.messageThread.messages
      .filter(noIncompleteMessage)
      .map(chatMessageToGiftedMessage);

    const sortedMessages = messages.sort((a, b) =>
      new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1
    );

    return sortedMessages;
  }, [messageThreadHash]);

  const startNewVideo = () => {
    const extraData: VideoSubmissionStackExtraData = {
      isLogProgressFlow: false,
      startOnRatingScreen: false,
      videoSubject: undefined, // because it's already on a messagethread
      messageThreadId: props.threadId,
      activityId: 'foooo',
    };
    presentComponentAsModal(VideoSubmissionStack, {
      extraData,
    });
  };

  return (
    <>
      <ChatThread
        messages={messages}
        onSend={props.onSend}
        isMessagingDisabled={props.isMessagingDisabled}
        currentUser={currentUser}
        startNewVideo={startNewVideo}
      />
      {props.isMessagingDisabled && (
        <MessagingDisabledAlert relationshipStatus={relationshipStatus} />
      )}
    </>
  );
};
