import {
  Maybe,
  MessageAttachmentType,
  MessageWithAttachmentsFragment,
  CurrentThreadInfoFragment,
  MentorHomeRelationshipInfoFragment,
} from 'sonora-graphql/types';

import stringHash from 'string-hash';
import {
  ChatThreadType,
  ChatAttachmentType,
  ChatMessageType,
} from 'sonora-types';

const boolToNum = (bool: boolean) => (bool ? 1 : 0);

function hashAttachments(
  attachments: (
    | Maybe<Pick<MessageAttachmentType, 'complete'> | undefined>
    | ChatAttachmentType
  )[]
) {
  return (
    attachments &&
    attachments.reduce(
      (acc, curr, i) => acc + i + boolToNum(!!curr && curr.complete),
      ''
    )
  );
}
/**
 * Returns a single string representing state of a Messages array
 * Deep equal arrays will return same string.
 * Purpose is to detect changes in message count, contents, read status,
 * attachments, etc.
 */
export const hashMessages = (
  messages: Maybe<MessageWithAttachmentsFragment>[]
) =>
  messages &&
  messages.reduce(
    (acc, currMessage, index) =>
      acc +
      index +
      stringHash(currMessage!.contents) +
      boolToNum(currMessage!.read) +
      boolToNum(currMessage!.complete) +
      hashAttachments(currMessage!.attachments || []),
    ''
  );

/**
 * This returns a string reduction of message thread data,
 * for memoization. The hash will stay the same if and only if
 * the messages, message contents, and message read status
 * stay the same.
 * @param thread Maybe<MessageThreadType> - turn it into a string
 */
export const hashMessageThread = (
  thread: Maybe<Pick<CurrentThreadInfoFragment, 'messages'>>
) => thread && thread.messages && hashMessages(thread.messages);

export const hashChatThread = (thread: ChatThreadType) => {
  const messagesHash = thread.messages
    ? thread.messages.reduce(
        (acc, curr, i) =>
          acc +
          i +
          stringHash(curr.contents) +
          boolToNum(curr.read) +
          boolToNum(curr.sent) +
          hashAttachments([curr.attachment]),
        ''
      )
    : '';

  return String(thread.id) + messagesHash;
};

export const hashChatMessage = (message: ChatMessageType | undefined) =>
  !message
    ? 'none'
    : String(message.id) +
      stringHash(message.createdAt.toLocaleString()) +
      stringHash(message.contents) +
      boolToNum(message.read) +
      boolToNum(!!message.attachment) +
      (message.attachment
        ? message.attachment.s3ThumbnailUrl ||
          'no' + message.attachment.s3Url ||
          'no'
        : 'noattach');

export const hashStringArray = (arr: string[]) =>
  arr.reduce((acc, curr, index) => acc + index + stringHash(curr), '');

export const hashMentorRelationships = (
  data: Maybe<MentorHomeRelationshipInfoFragment>[]
) => {
  if (!data) return '';
  data.reduce(
    (acc, curr, i) =>
      acc +
      (curr
        ? i +
          curr.id +
          curr.startedAt +
          curr.endedAt +
          curr.unreadCount +
          (curr.messageThread
            ? curr.messageThread.lastMessage + curr.messageThread.lastActivity
            : '')
        : ''),
    ''
  );
};
