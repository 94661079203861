import React, { useContext } from 'react';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

import { Box, Row, Text } from '../styled';
import { colors, fonts } from '../../constants/theme';
import { FillIcon } from '../Icon';
import { LayoutProviderContext, LayoutType } from '../../util';

interface Props {
  title: string;
  onHide?: () => void;
}

const ActionText = styled.Text`
  font-family: ${fonts.normal};
  text-transform: uppercase;
  color: ${colors.tanzanite};
`;

export const PanelHeader: React.FC<Props> = ({ title, onHide }) => {
  const { activeLayout } = useContext(LayoutProviderContext);

  let hideIconDirection =
    activeLayout === LayoutType.Portrait ? 'down' : 'left';

  return (
    <Row justifyContent="space-between">
      <Text font={fonts.bold} size={18}>
        {title}
      </Text>
      <TouchableOpacity onPress={onHide}>
        <Row>
          <Box>
            <ActionText>Hide</ActionText>
          </Box>
          {hideIconDirection === 'left' ? (
            <FillIcon name="hide" size="s" />
          ) : (
            <FillIcon name="hideDown" size="s" />
          )}
        </Row>
      </TouchableOpacity>
    </Row>
  );
};
