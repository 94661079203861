import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  ViewStyle,
} from 'react-native';
import { fonts, colors } from '../../constants/theme';

interface LoadingProps {
  text?: string;
  customBackgroundColor?: string;
  simpleFullscreen?: boolean;
}

/**
 * Loading indicator - set simpleFullScreen = false
 * to show text and not take up max space.
 */
export const Loading: React.FC<LoadingProps> = ({
  text = '',
  simpleFullscreen = true,
}) => {
  const extraStyle: ViewStyle = simpleFullscreen
    ? { flex: 1, alignSelf: 'center', justifyContent: 'center' }
    : {};
  return (
    <View style={extraStyle}>
      <ActivityIndicator />
      {!simpleFullscreen && <Text style={styles.text}>{text}</Text>}
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    fontFamily: fonts.bold,
    color: colors.amethyst,
  },
});
