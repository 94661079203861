import React, { useContext } from 'react';
import {
  Box,
  colors,
  ModalFunctionComponent,
  ModalPresenterContext,
  TrialCancel,
} from 'sonora-design-system';

export const PaymentCancelModal: ModalFunctionComponent = ({
  dismissModal,
}) => {
  const { extraData } = useContext(ModalPresenterContext);
  return (
    <Box
      flex={1}
      backgroundColor={colors.white}
      justifyContent="center"
      alignItems="center"
    >
      <TrialCancel
        extended={!extraData.showExtend}
        onCancel={() => dismissModal('Success', 'cancel')}
        onExtend={() => dismissModal('Success', 'extend')}
      />
    </Box>
  );
};
