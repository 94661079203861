import React from 'react';

import { Box, Text, Row } from '../styled';
import { ProgressBar } from '../ProgressBar';
import { FillIcon } from '../Icon';
import { fonts, colors } from '../../constants/theme';

export interface ProgressProps {
  title: string;
  numCompletedModules: number;
  numAllModules: number;
  numUnlockedStars: number;
  numAllStars: number;
  completePercent: number;
}

export const Progress: React.FC<ProgressProps> = ({
  title,
  numCompletedModules,
  numAllModules,
  numUnlockedStars,
  numAllStars,
  completePercent,
}) => {
  const moduleCompletion = `${numCompletedModules}/${numAllModules}`;
  const starsCompletion = `${numUnlockedStars}/${numAllStars}`;

  return (
    <Box>
      <Text font={fonts.bold} size={18} marginBottom={12}>
        {title}
      </Text>
      <Row justifyContent="flex-start" marginBottom={6}>
        <Row
          paddingRight={12}
          borderRightWidth={1}
          borderColor={colors.purpley}
        >
          <Text marginRight={4} font={fonts.bold}>
            {moduleCompletion}
          </Text>
          <Text font={fonts.thin}>subjects complete</Text>
        </Row>
        <Row marginLeft={12}>
          <Text marginRight={4} font={fonts.bold}>
            {completePercent}%
          </Text>
          <Text font={fonts.thin}>of course complete</Text>
        </Row>
      </Row>
      <Row>
        <Box flex={1} marginRight={20}>
          <ProgressBar percentage={completePercent} />
        </Box>
        <Text marginRight={8} font={fonts.thin}>
          {starsCompletion}
        </Text>
        <FillIcon name="starYes" color={colors.bling} />
      </Row>
    </Box>
  );
};
