// src/components/button/CustomButton.stories.jsx
import React from 'react';
import { storiesOf } from '@storybook/react-native';
// I import my component here
import { Wordmark } from '.';
import { View } from 'react-native';
import { colors } from '../../constants/theme';

const wordmarkStories = storiesOf('Wordmark', module);

wordmarkStories.add('default', () => (
  <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
    <Wordmark />
  </View>
));

wordmarkStories.add('amethyst', () => (
  <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
    <Wordmark variant="amethyst" />
  </View>
));

wordmarkStories.add('ruby', () => (
  <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
    <Wordmark variant="ruby" />
  </View>
));

wordmarkStories.add('white', () => (
  <View
    style={{
      backgroundColor: colors.tanzanite,
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Wordmark variant="white" />
  </View>
));

wordmarkStories.add('black', () => (
  <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
    <Wordmark variant="black" />
  </View>
));
