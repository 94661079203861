// author: @fullstackdevs14
import React from 'react';
import styled from 'styled-components/native';
import { colors, fonts, theme } from '../../constants/theme';
import { FillIcon } from '../Icon';
import { TouchableOpacity } from 'react-native';

const HeaderContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

const Row = styled.View`
  flex-direction: row;
  align-items: center;
`;

const HeaderText = styled.Text`
  font-family: ${theme.taskList.title.font};
  font-size: 18px;
  color: ${colors.tanzanite};
  flex: 1;
`;

const ActionText = styled.Text`
  font-family: ${fonts.normal};
  text-transform: uppercase;
  color: ${colors.tanzanite};
`;

interface Props {
  title: string;
  hideIconDirection: 'left' | 'down';
  hasHideButton?: boolean;
  onHide?: () => void;
}

const TaskListHeader: React.FC<Props> = ({
  title,
  hideIconDirection,
  hasHideButton,
  onHide,
}) => (
  <HeaderContainer>
    <HeaderText>{title}</HeaderText>
    {hasHideButton ? (
      <TouchableOpacity onPress={onHide}>
        <Row>
          <ActionText>Hide</ActionText>
          {hideIconDirection === 'left' ? (
            <FillIcon name="hide" size="s" />
          ) : (
            <FillIcon name="hideDown" size="s" />
          )}
        </Row>
      </TouchableOpacity>
    ) : null}
  </HeaderContainer>
);

export default TaskListHeader;
