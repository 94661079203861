import React from 'react';
import { Platform, StyleSheet } from 'react-native';
import {
  Composer,
  ComposerProps,
  SendProps,
  IMessage,
} from 'react-native-gifted-chat';

import { colors, theme, fonts } from '../../../constants/theme';

type RenderComposerProps = ComposerProps & {
  onSend: SendProps<IMessage>['onSend'];
  text: SendProps<IMessage>['text'];
};

export const RenderComposer: React.FC<RenderComposerProps> = (
  props: RenderComposerProps
) => (
  <Composer
    {...props}
    textInputStyle={styles.textInput}
    textInputProps={{
      blurOnSubmit: Platform.OS === 'web',
      onSubmitEditing: () => {
        if (props.text && props.onSend) {
          props.onSend({ text: props.text.trim() }, true);
        }
      },
    }}
  />
);

const styles = StyleSheet.create({
  textInput: {
    zIndex: 1,
    backgroundColor: colors.opal,
    fontFamily: fonts.thin,
    fontSize: 14,
    paddingLeft: 12,
    padding: 12,
    paddingTop: 14,
    marginTop: 0,
    marginBottom: 0,
    minHeight: 48,
    borderTopLeftRadius: parseInt(theme.common.borderRadius, 10),
    borderBottomLeftRadius: parseInt(theme.common.borderRadius, 10),
    color: colors.tanzanite,
    ...Platform.select({
      web: {
        outlineWidth: 0,
      },
    }),
  },
});
