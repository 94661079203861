import { FormState, FormAction } from './types';

export const initialState: FormState = {
  values: {},
  errors: {},
};

export const formReducer = (state: FormState, action: FormAction) => {
  switch (action.type) {
    case 'SET_VALUE':
      return {
        ...state,
        values: {
          ...state.values,
          [action.field]: action.payload,
        },
      };

    case 'SET_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.field]: action.payload,
        },
      };

    default:
      return state;
  }
};
