import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import FlashMessage from 'react-native-flash-message';

import { StatusBar } from 'react-native';
import { AppLoading } from 'expo';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { LayoutProvider } from 'sonora-design-system';
import { L1NavigationProvider } from 'sonora-containers/index';

import { getStorybookUI, configure } from '@storybook/react-native';
import { AppNavContainer } from './src/router/AppNavContainer';

import { useApolloClient } from 'sonora-hooks/useApolloClient';
import { useFonts } from 'sonora-hooks/useFonts';
import { usePortraitLockExceptIpad } from 'sonora-hooks/useMobileOrientationLock';
import { initializeSentry } from 'sonora-util/environment';
import { initializeAmplitude } from 'sonora-services/analytics';

initializeSentry();
initializeAmplitude();

const ProductionApp = () => {
  usePortraitLockExceptIpad({ onlyOnMount: true });
  const { client, loading, error } = useApolloClient();

  if (loading) return <AppLoading />;

  if (!client) {
    // TODO get sentry tracking!
    console.error('No client', error);
    throw new Error('Apollo client could not be created');
  }

  return (
    <ApolloProvider client={client}>
      <SafeAreaProvider>
        <LayoutProvider>
          <StatusBar barStyle="light-content" />
          <L1NavigationProvider>
            <AppNavContainer />
          </L1NavigationProvider>
        </LayoutProvider>
      </SafeAreaProvider>
      <FlashMessage />
    </ApolloProvider>
  );
};

const App = () => {
  const fontLoading = useFonts({
    // TODO: add more font styles
    'Tiempos-Headline-Semibold': require('sonora-design-system/assets/fonts/Tiempos-Headline/Tiempos-Headline-Semibold.otf'),
    'Sohne-Mager': require('sonora-design-system/assets/fonts/Sohne/Sohne-Mager.ttf'),
    'Sohne-Kraftig': require('sonora-design-system/assets/fonts/Sohne/Sohne-Kraftig.ttf'),
    'Sohne-Halbfett': require('sonora-design-system/assets/fonts/Sohne/Sohne-Halbfett.ttf'),
  });

  if (fontLoading) {
    return <AppLoading />;
  }

  if (process.env.EXPO_ENV === 'storybook') {
    configure(() => {
      require('./sonora-design-system/stories.js');
    }, module);
    const StorybookUIRoot = getStorybookUI({ tabOpen: -1, onDeviceUI: true });
    return <StorybookUIRoot />;
  }

  return <ProductionApp />;
};

export default App;
