import React, { useEffect, useRef } from 'react';
import { TouchableOpacity } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import styled from 'styled-components/native';
import { colors } from '../../constants/theme';
import { FillIcon, StrokeIcon } from '../Icon';

interface StarIconWrapperProps {
  size: number;
  isHovered?: boolean;
}

const StarIconWrapper = styled.View`
  width: ${(props: StarIconWrapperProps) => props.size}px;
  height: ${(props: StarIconWrapperProps) => props.size}px;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background-color: ${(props: StarIconWrapperProps) =>
    props.isHovered ? colors.opalHover : 'transparent'};
`;

interface StarIconProps {
  color?: string;
  isColored?: boolean;
  isHovered?: boolean;
  size?: string;
  onHover: () => void;
  onPress: () => void;
}

export const StarIcon: React.FC<StarIconProps> = ({
  color,
  isColored,
  isHovered,
  size = 'sr',
  onHover,
  onPress,
}) => {
  const ref = useRef(null);
  const isHoveredHook = useHover(ref);

  useEffect(() => {
    onHover();
  }, [isHoveredHook]);

  return (
    <StarIconWrapper
      size={size === 'sr' ? 50 : 32}
      isHovered={isHoveredHook && isHovered}
      ref={ref}
    >
      <TouchableOpacity activeOpacity={1} onPress={onPress}>
        {isColored ? (
          <FillIcon name="starYes" size={size} color={color} />
        ) : (
          <StrokeIcon name="starNo" size={size} color={color} />
        )}
      </TouchableOpacity>
    </StarIconWrapper>
  );
};
