import React from 'react';

import { Row } from '../styled';
import { Button, ButtonProps } from '../Button';

interface ButtonRowProps {
  buttons: ButtonProps[];
}

export const ButtonRow: React.FC<ButtonRowProps> = ({ buttons }) => {
  return (
    <Row justifyContent="center" alignItems="stretch" padding={6}>
      {buttons.map((btn, i) => (
        <Row key={btn.text} alignItems="stretch" margin={6}>
          <Button {...btn} />
        </Row>
      ))}
    </Row>
  );
};
