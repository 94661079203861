import { useState, useEffect } from 'react';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';

import { createClient } from '../graphql/apolloClient';

export const useApolloClient = () => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    createClient()
      .then(setClient)
      .then((_) => setLoading(false))
      .catch((err) => setError(err));

    return () => {};
  }, []);

  return { client, loading, error };
};
