import React, { useState } from 'react';

import { Box, Text } from '../styled';
import { PanelHeader } from '../PanelHeader';
import { SectionToggle } from '../SectionToggle';
import { Divider } from '../Divider';
import { ActiveModification } from './ActiveModification';
import { CategoryItem } from './CategoryItem';
import { Scrollable } from '../Scrollable';
import { fonts, colors } from '../../constants/theme';

import {
  ModificationCategory,
  ModificationId,
  Modification,
  ActiveModificationsMap,
} from '../ModificationDetailView/types';

export interface ModificationsCategoryProps {
  activeModificationsMap: ActiveModificationsMap;
  categories: ModificationCategory[];
  modifications: Modification[];
  modificationHeaderBackground?: string;
  onHide?: () => void;
  onRemoveActiveModification?: (modificationId: ModificationId) => void;
  onPressActiveModification?: (modificationId: ModificationId) => void;
  onPlayActiveModificationVideo?: (vimeoId: string) => void;
  onPressCategory?: (category: ModificationCategory) => void;
}

export const ModificationsCategory: React.FC<ModificationsCategoryProps> = ({
  activeModificationsMap,
  categories,
  modifications,
  onHide,
  onRemoveActiveModification,
  onPressActiveModification,
  onPlayActiveModificationVideo,
  onPressCategory,
}) => {
  const [showModifications, setShowModifications] = useState(true);
  const activeModifications = modifications.filter(
    (m) => Object.values(activeModificationsMap).indexOf(m.id) >= 0
  );

  return (
    <Box flex={1} padding={12}>
      <Box marginBottom={15}>
        <PanelHeader title="Modifications" onHide={onHide} />
      </Box>
      <SectionToggle
        show={showModifications}
        suffix="modifications"
        onToggle={setShowModifications}
      />
      {showModifications ? (
        <Box marginTop={12}>
          {activeModifications.length === 0 ? (
            <Text textAlign="center" font={fonts.thin}>
              (No active modifications)
            </Text>
          ) : (
            activeModifications.map((modification, i) => (
              <ActiveModification
                key={modification.id}
                title={modification.title}
                vimeoId={modification.vimeoId}
                hasBorder={i < activeModifications.length - 1}
                onRemove={() =>
                  onRemoveActiveModification &&
                  onRemoveActiveModification(modification.id)
                }
                onPress={() =>
                  onPressActiveModification &&
                  onPressActiveModification(modification.id)
                }
                onPlay={() => {
                  if (onPlayActiveModificationVideo && modification.vimeoId) {
                    onPlayActiveModificationVideo(modification.vimeoId);
                  }
                }}
              />
            ))
          )}
          <Divider marginTop={12} color={colors.purpley} />
        </Box>
      ) : null}
      <Box flex={1} marginTop={12}>
        <Scrollable>
          {categories.map((category, i) => (
            <CategoryItem
              key={category.title}
              category={category}
              hasBorder={i < categories.length - 1}
              onPress={() => onPressCategory && onPressCategory(category)}
            />
          ))}
        </Scrollable>
      </Box>
    </Box>
  );
};
