import React from 'react';
import { IMessage } from 'react-native-gifted-chat';
import styled from 'styled-components/native';

import { StrokeIcon } from '../../Icon';
import { Box } from '../../styled';

import loadingImg from '../../../assets/images/loading.png';

const LoadingImage = styled.Image`
  width: 24px;
  height: 24px;
`;

export const RenderTick: React.FC<IMessage> = ({ sent, pending, received }) => (
  <Box flex={1} marginTop={5}>
    {received ? (
      <StrokeIcon name="checkDouble" />
    ) : sent ? (
      <StrokeIcon name="check" />
    ) : pending ? (
      <LoadingImage source={loadingImg} />
    ) : null}
  </Box>
);
