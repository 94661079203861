import React, { useState } from 'react';
import { RootNavScreen } from '../screens';
import { useSignIn } from 'sonora-graphql/signInHooks';
import { LoginForm, KeyValueStore } from 'sonora-design-system';

export interface SigninProps {}

const Signin: RootNavScreen<'Signin'> = ({ navigation, route }) => {
  const [graphQLError, setGraphQLError] = useState<string>('');
  const signIn = useSignIn();

  const handleSubmit = (formData: KeyValueStore) => {
    signIn(formData.email, formData.password)
      .then(() => navigation.navigate('LoggedIn'))
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          setGraphQLError(err.graphQLErrors[0].message);
        }
      });
  };

  return (
    <LoginForm
      error={graphQLError}
      onClearError={() => {
        setGraphQLError('');
      }}
      onPressForgot={() => {
        navigation.navigate('ForgotPassword');
      }}
      onSubmit={handleSubmit}
    />
  );
};

export default Signin;
