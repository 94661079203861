import { useMemo } from 'react';
import { distanceInWordsToNow } from 'sonora-util/dateUtils';

import {
  Maybe,
  ChatAllThreadsQuery,
  CarouselDataFragment,
} from '../graphql/types';

import { CarouselThreadData } from 'sonora-design-system/components/Chat/types';

const hashCard = (c: CarouselThreadData): string =>
  (c.badge ? '1' : '0') + c.imageUrl.split('&')[0] + c.subtitle + c.title;

const hashCards = (c: CarouselThreadData[]): string =>
  c.reduce((acc, curr, i) => acc + i + hashCard(curr), '');

/**
 * Takes the message threads for this relationship and turns
 * them into carousel data for the Submissions Carousel.
 *
 * @param threads messages threads that have attachments
 * @param mainThreadId so we can exclude that from carousel
 * @param setParams for setting up the onPress
 */
export function useMemoizedCarouselData(
  threads: Pick<ChatAllThreadsQuery, 'messageThreads'> | undefined,
  mainThreadId: number
): CarouselThreadData[] {
  const messageThreadArray = (threads && threads.messageThreads) || [];

  /** Exclude the main message thread plus any null/undefined schtuff */
  const isCarouselData = (
    mt: Maybe<CarouselDataFragment> | undefined
  ): mt is CarouselDataFragment => !!mt && parseInt(mt.id) !== mainThreadId;

  const sortedByTime = messageThreadArray
    .filter(isCarouselData)
    .sort((a, b) =>
      new Date(a.lastMessageAt) <= new Date(b.lastMessageAt) ? 1 : -1
    );

  const previewCards: CarouselThreadData[] = sortedByTime.map((thread) => {
    if (!thread) throw new Error('need a real thread here');
    const threadSubject = thread.subject || 'need subject';

    // const exerciseId =
    //   (thread.exercises && thread.exercises[0] && thread.exercises[0].id) || -1;

    return {
      id: Number(thread.id),
      title: threadSubject,
      imageUrl: thread.s3ThumbnailUrl || '',
      subtitle: distanceInWordsToNow(new Date(thread.lastMessageAt)),
      badge: thread.unreadCount > 0,
    };
  });

  const previewHash = hashCards(previewCards);

  return useMemo(() => {
    return previewCards;
  }, [previewHash]);
}
