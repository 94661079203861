import React from 'react';
import { storiesOf } from '@storybook/react-native';
import { LayoutProvider } from '../../util';

import { Box } from '../styled';
import { ModificationsCategory } from '.';

import { colors } from '../../constants/theme';
import {
  ModificationCategory,
  ModificationId,
  ActiveModificationsMap,
} from '../ModificationDetailView/types';
import {
  activeModifications,
  categories as modificationCategories,
  makeModifications,
} from '../ModificationDetailView/mock-data';

const modificationCategoryStories = storiesOf('ModificationCategory', module);

const allModifications = makeModifications();

interface StoryProps {
  categories: ModificationCategory[];
}

const ModificationCategoryStory: React.FC<StoryProps> = ({ categories }) => {
  const [activeModificationIds, setActiveModificationIds] = React.useState(
    activeModifications
  );

  const handleRemoveModication = (modification: ModificationId) => {
    setActiveModificationIds(
      activeModificationIds.filter((m) => m !== modification)
    );
  };

  const getInitialModifications = () => {
    let initialModifications: ActiveModificationsMap = {};

    activeModifications.forEach((modification) => {
      categories.forEach((category) => {
        if (category.modifications.includes(modification)) {
          initialModifications[category.id] = modification;
        }
      });
    });

    return initialModifications;
  };

  return (
    <LayoutProvider>
      <Box padding={16} backgroundColor={colors.agate} height="600px">
        <ModificationsCategory
          activeModificationsMap={getInitialModifications()}
          categories={categories}
          modifications={allModifications}
          onRemoveActiveModification={handleRemoveModication}
          onPressActiveModification={console.log}
          modificationHeaderBackground={colors.agate}
          onHide={() => {
            console.log('Pressed "Hide" button');
          }}
          onPressCategory={console.log}
        />
      </Box>
    </LayoutProvider>
  );
};

const makeModificationCategoryStory = (
  categories: ModificationCategory[]
) => () => <ModificationCategoryStory categories={categories} />;

modificationCategoryStories.add(
  'default',
  makeModificationCategoryStory(modificationCategories)
);
