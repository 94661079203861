import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import {
  ModalFunctionComponent,
  ModalPresenterContext,
  ModuleUnlock,
  VimeoPlayer,
  colors,
} from 'sonora-design-system';
import { StudentContext } from 'sonora-containers/index';

export interface ModificationUnlockProps {}

export const ModificationUnlock: ModalFunctionComponent = ({
  dismissModal,
}) => {
  const { extraData } = useContext(ModalPresenterContext);
  const { curriculum } = useContext(StudentContext);
  const [videoPlaying, setVideoPlaying] = useState<boolean>(false);

  let moduleName, title, onGo, vimeoId;

  if (extraData.modificationIds) {
    const modification = curriculum.modifications.find(
      (m) => m.id === extraData.modificationIds[0]
    );

    if (modification) {
      moduleName = modification.title;
      title = 'New Modification Unlocked';

      if (modification.vimeoId) {
        vimeoId = modification.vimeoId;
        onGo = () => setVideoPlaying(true);
      }
    }
  }

  if (extraData.categoryIds) {
    const category = curriculum.modificationCategories.find(
      (m) => m.id === extraData.categoryIds[0]
    );

    if (category) {
      moduleName = category.name;
      title = 'New Modification Category';
      if (category.unlockVimeoId) {
        vimeoId = category.unlockVimeoId;
        onGo = () => setVideoPlaying(true);
      }
    }
  }

  let content = (
    <ModuleUnlock
      title={title}
      moduleName={moduleName || 'Module'}
      onDismiss={() => dismissModal('Cancel')}
      onGo={onGo}
    />
  );

  if (vimeoId && videoPlaying) {
    content = (
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: colors.black,
        }}
      >
        <VimeoPlayer vimeoVideoID={vimeoId} />
      </View>
    );
  }

  return <View style={{ flex: 1 }}>{content}</View>;
};
