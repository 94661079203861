import React from 'react';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

import { Row, Text } from '../styled';
import { FillIcon } from '../Icon';
import { PlayButton } from '../PlayButton';

import { colors, theme } from '../../constants/theme';

const ActiveModificationWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 12px;
  background-color: ${colors.white};
  border-radius: ${theme.common.borderRadius};
  margin-bottom: 3px;
`;

const Border = styled.View`
  position: absolute;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${colors.borderline};
`;

interface Props {
  title: string;
  vimeoId?: string;
  hasBorder?: boolean;
  onRemove?: () => void;
  onPress?: () => void;
  onPlay?: () => void;
}

export const ActiveModification: React.FC<Props> = ({
  title,
  vimeoId,
  hasBorder,
  onRemove,
  onPress,
  onPlay,
}) => (
  <TouchableOpacity onPress={onPress}>
    <ActiveModificationWrapper>
      <Row flex={1}>
        {vimeoId ? <PlayButton showText={false} onPress={onPlay} /> : null}
        <Text marginLeft={10}>{title}</Text>
      </Row>
      <TouchableOpacity onPress={onRemove}>
        <FillIcon name="x" />
      </TouchableOpacity>
      {hasBorder ? <Border /> : null}
    </ActiveModificationWrapper>
  </TouchableOpacity>
);
