import formatDistance from 'date-fns/formatDistance';
import differenceInDays from 'date-fns/differenceInDays';
import { ChatRelationshipType } from 'sonora-types';

export const yesterdayDate = () =>
  ((d) => new Date(d.setDate(d.getDate() - 1)))(new Date());

export const distanceInWordsToNow = (startDate: Date) =>
  formatDistance(startDate, new Date(), { addSuffix: true });

export const distanceFromToday = (startDate: Date) =>
  differenceInDays(new Date(), startDate);

export const timeStringNoSeconds = (date: Date) =>
  date.toLocaleTimeString(undefined, {
    hour: 'numeric',
    minute: '2-digit',
  });

/**
 * uses build in Date functions to return localized date string w/ 2-digit year
 * e.g. for september 15, 2020 will return 9/15/20 in USA, 15/9/20 in Britain
 */

export const shortYearDatestring = (date: Date) => {
  const formatter = new Intl.DateTimeFormat();
  const parts = formatter.formatToParts(date);
  const dateStr = date.toLocaleDateString();
  const foundYear = parts.find((part) => part.type === 'year');
  if (!foundYear) throw new Error('did not get year from Date function');

  const year = foundYear.value;
  const shortYear = year.substr(2);
  return dateStr.replace(year, shortYear);
};

/**
 * Returns streak count
 * @param dates - array of strings YYYY-MM-DD
 */
export const streakFromDatesArray = (dates: string[]) => {
  const sortedDates = dates
    .map((d) => new Date(d))
    .sort((a, b) => (a < b ? 1 : -1));
  if (differenceInDays(new Date(), sortedDates[0]) > 1) return 0;

  let count = 0;
  for (let i = 0; i < sortedDates.length; i++) {
    const prevDate = i === 0 ? new Date() : sortedDates[i - 1];
    const diff = differenceInDays(prevDate, sortedDates[i]);
    if (diff <= 1) count++;
    else break;
  }

  return count;
};

export const isRelationshipActive = (relationship: ChatRelationshipType) => {
  let now = new Date();
  return (
    relationship.relationshipStartDate <= now &&
    relationship.relationshipEndDate >= now
  );
};
