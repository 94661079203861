export const largeUser = { _id: 339, name: 'Marc Gelfo' };
export const mediumUser = { _id: 500, name: 'v1.11 medium user test' };

export const largeChat = [
  {
    _id: 2673,
    text: 'goooood on safari',
    createdAt: new Date('2020-07-30T18:43:26.569Z'),
    received: false,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 2619,
    text: 'yay!',
    createdAt: new Date('2020-07-28T23:48:47.278Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 2618,
    text: '',
    createdAt: new Date('2020-07-28T23:48:02.270Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/ff0503cfd2dfa451ca2d984b5322c3e8-f3d8fd0840396e9007e0f84f96efbe7c.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165833Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=5853f0b38218a82703422b712fda07dfe52c2d6d75167f9054b7d41d1aef08dd',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/ff0503cfd2dfa451ca2d984b5322c3e8-f3d8fd0840396e9007e0f84f96efbe7c.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165833Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=3ccb1ff8370b08ff650865139f20d16ac4e8419e1911a626ee00bd5cdd6080bd',
  },
  {
    _id: 2617,
    text: 'a video!',
    createdAt: new Date('2020-07-28T23:47:59.201Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 2616,
    text: 'send',
    createdAt: new Date('2020-07-28T23:47:45.455Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 2615,
    text: 'what say you?',
    createdAt: new Date('2020-07-28T23:47:14.393Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 2614,
    text: '',
    createdAt: new Date('2020-07-28T23:46:27.877Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/35e9fb9377b0bd76a6ed1eb14650c643-6d0b8aaaa0adc2ea7df127a115f59851.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165833Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=dd423118385e41cb99fae1c64fb6fdd7eff855ae8d20440410f66f61c8b78217',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/35e9fb9377b0bd76a6ed1eb14650c643-6d0b8aaaa0adc2ea7df127a115f59851.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165833Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=20c976ac7bdbf9e4bddae603547243db8612684e71e3017d8b24139368029b75',
  },
  {
    _id: 2613,
    text: 'Or send a video!',
    createdAt: new Date('2020-07-28T23:46:24.922Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 2612,
    text: 'I can type you a question',
    createdAt: new Date('2020-07-28T23:46:16.400Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 2611,
    text: "Here's another message",
    createdAt: new Date('2020-07-28T23:45:46.898Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 2610,
    text: 'To shred or not to shred, is a cheesy question',
    createdAt: new Date('2020-07-28T23:45:22.607Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 2609,
    text: "But what if I don't want to shred?",
    createdAt: new Date('2020-07-28T23:44:57.850Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 2608,
    text: 'Soon young Padowan you will be a Jedi shredder',
    createdAt: new Date('2020-07-28T23:44:44.479Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 2607,
    text: "I'm learning so much from our conversation",
    createdAt: new Date('2020-07-28T23:44:31.566Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 2606,
    text: 'Chat is good',
    createdAt: new Date('2020-07-28T23:44:19.088Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 2605,
    text: '',
    createdAt: new Date('2020-07-28T23:43:03.040Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/12e9a2e42c616453bf254a93928a91d6-26d3e861b7e277f73f08231e2c174dba.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165833Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=0fc2c871d5b97e282e56ff86d8c1dfc1710e2c9792641a378c054320aac9c51e',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/12e9a2e42c616453bf254a93928a91d6-26d3e861b7e277f73f08231e2c174dba.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165833Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=48be56931e1a4b1764a4c5b39c7414b2957f1606b7d6888afd313db480e3a977',
  },
  {
    _id: 2604,
    text: '',
    createdAt: new Date('2020-07-28T23:39:59.702Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/269046ce07b54d3ae6b89b74ac1b5e43-6a3948904229271529ae939835fab41b.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165833Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=3b03ffa67dc0411ca824c73f3d7c3b6a8ca78cccbf4d82f16119d0c0f86c8fd8',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/269046ce07b54d3ae6b89b74ac1b5e43-6a3948904229271529ae939835fab41b.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165833Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=99341a3015eddb2922d09a45298eb7449fe2feb5457ceeb356d00718201b52fe',
  },
  {
    _id: 2603,
    text: "I'm going to send you a video!",
    createdAt: new Date('2020-07-28T23:39:55.618Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 2602,
    text: 'This new chat feature is pretty great!',
    createdAt: new Date('2020-07-28T23:38:49.784Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 2585,
    text: 'Back out',
    createdAt: new Date('2020-07-28T18:22:59.198Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 2584,
    text: 'Heyyyyyy test',
    createdAt: new Date('2020-07-28T18:22:49.792Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 2583,
    text: 'Hooray',
    createdAt: new Date('2020-07-28T18:22:28.495Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 2579,
    text: 'Hey',
    createdAt: new Date('2020-07-28T18:14:24.349Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 2575,
    text: '',
    createdAt: new Date('2020-07-28T18:12:39.794Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/a478f6e0-2ac1-448a-a4fd-01d1dd5236ca?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165833Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=661abcb7efc2c83c6525552a242f4b788c5d27a6eebac1a098702eda664517a7',
    posterImage:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/a478f6e0-2ac1-448a-a4fd-01d1dd5236ca.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165833Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=d3f3d243bcd3c75842199824fc6fc089763dae3b7d35d7b632912d65dc588db6',
  },
  {
    _id: 2574,
    text: 'Heysudjd',
    createdAt: new Date('2020-07-28T18:12:27.314Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 2573,
    text: 'Got it',
    createdAt: new Date('2020-07-28T18:12:20.301Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 2571,
    text: '',
    createdAt: new Date('2020-07-28T18:11:45.360Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
    video:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/5b41ccfc-a7fc-4336-9828-6c9065aef0f6?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165833Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=ae79a009ae2fcf518f4a342697a72a0aaaee8e9fd0a6c3c48340c911a85a6788',
    posterImage:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/5b41ccfc-a7fc-4336-9828-6c9065aef0f6.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165833Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=db033a52a911946a063afc7b0a5bb3f24b77f9f7cea4cb7dc53d43de0ab70bc8',
  },
  {
    _id: 2569,
    text: 'Dope got it',
    createdAt: new Date('2020-07-28T18:11:25.419Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 2566,
    text: 'Heyyy',
    createdAt: new Date('2020-07-28T18:10:51.951Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 2565,
    text: 'Silly gelfo gelfo this account works now',
    createdAt: new Date('2020-07-28T18:04:52.736Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 2364,
    text: 'Bvhhv',
    createdAt: new Date('2020-06-26T01:13:50.628Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 2363,
    text: 'Ddjdjdd',
    createdAt: new Date('2020-06-26T01:11:42.833Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1976,
    text: 'Ifgfyt',
    createdAt: new Date('2020-04-24T23:52:38.310Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1975,
    text: 'Sending in a thing',
    createdAt: new Date('2020-04-24T23:51:23.172Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1974,
    text: 'Ue',
    createdAt: new Date('2020-04-24T23:47:40.601Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1973,
    text: 'Djdjudjdjd',
    createdAt: new Date('2020-04-24T23:47:38.272Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1972,
    text: 'Hchjjnjjhvghhbvhjjnnjj',
    createdAt: new Date('2020-04-24T23:46:23.544Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1967,
    text: 'Hshshshshjxjdhdhghb',
    createdAt: new Date('2020-04-24T22:56:50.879Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1966,
    text: 'Idjdjd',
    createdAt: new Date('2020-04-24T22:56:29.084Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1961,
    text: 'Cccgg',
    createdAt: new Date('2020-04-24T21:35:17.782Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1960,
    text: 'Ggfggb',
    createdAt: new Date('2020-04-24T21:35:08.167Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1959,
    text: 'Ggfg',
    createdAt: new Date('2020-04-24T21:35:06.487Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1922,
    text: 'Ifndnd',
    createdAt: new Date('2020-04-24T20:55:12.637Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1866,
    text: 'asdfdasfqwer',
    createdAt: new Date('2020-04-23T01:12:37.494Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1801,
    text: 'Hey',
    createdAt: new Date('2020-04-21T02:20:01.122Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1800,
    text: '',
    createdAt: new Date('2020-04-21T02:19:03.344Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/6f9d0458-d38e-4c61-b39c-1f954f0f4104?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165833Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=b3c6cf344eb1b4db2cf5bb8e412e7ba71962d90ab589be270b95873e32878f69',
    posterImage:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/6f9d0458-d38e-4c61-b39c-1f954f0f4104.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165833Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=280a90b8861d9fe36077b5416d22e0cafef160a69e8d4a045b0fca04046ca049',
  },
  {
    _id: 1799,
    text: 'Hey',
    createdAt: new Date('2020-04-21T02:18:57.918Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1796,
    text: 'Jr',
    createdAt: new Date('2020-04-21T02:07:38.404Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1795,
    text: '',
    createdAt: new Date('2020-04-21T02:01:19.229Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/568d7468-77b8-437a-aa27-270b925ab0d0?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165834Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=9b3c3b7a9fc1f3f9c8d6d5b856afee335c282375e360880c36d3c84b27dc4698',
    posterImage:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/568d7468-77b8-437a-aa27-270b925ab0d0.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165834Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=254435ede24814ac955075e66d83c844ee80417e99cc919a42f3e031472562de',
  },
  {
    _id: 1794,
    text: 'adsfadsqwer',
    createdAt: new Date('2020-04-21T00:58:37.241Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1793,
    text: 'asdfadsfadsfdasfadsfasdfdafadsf',
    createdAt: new Date('2020-04-21T00:55:06.605Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1792,
    text: 'sending',
    createdAt: new Date('2020-04-21T00:54:33.852Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1791,
    text: 'Zggy',
    createdAt: new Date('2020-04-20T20:06:28.447Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1790,
    text: 'Xghcctyhvv',
    createdAt: new Date('2020-04-20T20:06:22.468Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1789,
    text: 'Fffhu',
    createdAt: new Date('2020-04-20T20:06:13.360Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1788,
    text: 'Yrffgy',
    createdAt: new Date('2020-04-20T20:06:09.746Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1787,
    text: 'Fffdsd',
    createdAt: new Date('2020-04-20T20:05:07.518Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1786,
    text: 'Chhdjxjdhd',
    createdAt: new Date('2020-04-20T20:04:12.460Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1785,
    text: 'Irunc',
    createdAt: new Date('2020-04-20T20:03:25.041Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1784,
    text: 'Vctybb',
    createdAt: new Date('2020-04-20T20:02:44.709Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1783,
    text: 'Ih',
    createdAt: new Date('2020-04-20T20:02:33.577Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1782,
    text: 'Ih',
    createdAt: new Date('2020-04-20T20:02:26.512Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1781,
    text: 'Ije',
    createdAt: new Date('2020-04-20T20:02:09.262Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1780,
    text: 'Uhd',
    createdAt: new Date('2020-04-20T20:02:04.555Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1779,
    text: 'Iduehccjd',
    createdAt: new Date('2020-04-20T20:01:59.035Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1778,
    text: 'Hdjdjd by',
    createdAt: new Date('2020-04-20T20:00:59.261Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1777,
    text: 'Uehbdd',
    createdAt: new Date('2020-04-20T20:00:14.733Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1776,
    text: 'Jdjd chucf',
    createdAt: new Date('2020-04-20T20:00:05.190Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1775,
    text: 'Xihens',
    createdAt: new Date('2020-04-20T19:58:52.260Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1774,
    text: 'Ciejbxc',
    createdAt: new Date('2020-04-20T19:56:42.286Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1773,
    text: 'Kfjd',
    createdAt: new Date('2020-04-20T19:45:53.504Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1771,
    text: 'Sending',
    createdAt: new Date('2020-04-20T19:45:39.542Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1761,
    text: 'I just sent a video and can’t see it',
    createdAt: new Date('2020-04-20T19:11:27.947Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1760,
    text: '',
    createdAt: new Date('2020-04-20T19:10:31.976Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/65c51dc0-ce10-4195-b4ed-09966dd5eae8?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165834Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=2f69c49ca6e2f6620cda645cb4ca9c800325d479b11f88ff0d19e2088d56ec9f',
    posterImage:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/65c51dc0-ce10-4195-b4ed-09966dd5eae8.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165834Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=e343bdadefcb25aa61c265014685a3682946b36bad3fb996253cc280c1ef837e',
  },
  {
    _id: 1759,
    text: 'eqruieqpuroeqwri',
    createdAt: new Date('2020-04-20T19:09:26.563Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1758,
    text: 'Fooo',
    createdAt: new Date('2020-04-20T19:06:30.029Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1757,
    text: 'Kseonxxjd',
    createdAt: new Date('2020-04-20T19:05:08.597Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1756,
    text: 'sdfgsdfgsdfgsdfgsfdgsfdg',
    createdAt: new Date('2020-04-20T18:42:10.274Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1755,
    text: 'Idjbccc',
    createdAt: new Date('2020-04-20T03:57:55.357Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1754,
    text: 'Jfje',
    createdAt: new Date('2020-04-20T03:57:41.216Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1753,
    text: 'Fiiendx',
    createdAt: new Date('2020-04-20T03:57:33.071Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1752,
    text: 'qwerqewrasdasdf',
    createdAt: new Date('2020-04-20T03:57:18.452Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1751,
    text: 'qwerqwerqwerqewrasdfasf',
    createdAt: new Date('2020-04-20T03:57:14.641Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1750,
    text: 'asdfadsfasdf',
    createdAt: new Date('2020-04-20T03:57:07.228Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1749,
    text: 'qwerqewrasdfqqwer',
    createdAt: new Date('2020-04-20T03:49:36.937Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1748,
    text: 'asdfqwefqweasdfadsf',
    createdAt: new Date('2020-04-20T03:49:25.445Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1747,
    text: 'Cisne con jdjd Cindy',
    createdAt: new Date('2020-04-20T03:48:38.230Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1746,
    text: 'Ufhenc',
    createdAt: new Date('2020-04-20T03:48:08.603Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1745,
    text: 'Fidjd',
    createdAt: new Date('2020-04-20T03:48:06.631Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1744,
    text: 'Sjsnsnxxjd',
    createdAt: new Date('2020-04-20T03:46:42.281Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1743,
    text: 'Cuene',
    createdAt: new Date('2020-04-20T03:45:34.823Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1742,
    text: 'Fjie',
    createdAt: new Date('2020-04-20T03:45:32.754Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1741,
    text: 'Ingrid',
    createdAt: new Date('2020-04-20T03:44:15.117Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1740,
    text: 'Djidjendidjdndnjdjrjcjjdjdnxhhchdjdjdjd',
    createdAt: new Date('2020-04-20T03:43:39.257Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1737,
    text: '',
    createdAt: new Date('2020-04-20T03:33:33.180Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/3903d8d7-7545-4469-8aec-3b5156fb8718?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165834Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=3f23c7c852ee8826621ee2c30209e21338b71de40ce84f1ee3d48f2003671f6f',
    posterImage:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/3903d8d7-7545-4469-8aec-3b5156fb8718.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165834Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=f9cb85a3e6a1db1bd0c96849025677a3eb096eb2ff85ead9f00101ac4739b9c4',
  },
  {
    _id: 1736,
    text: 'Where is my video',
    createdAt: new Date('2020-04-20T03:31:50.575Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1735,
    text: '',
    createdAt: new Date('2020-04-20T03:30:50.019Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
    video:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/13b0f2dc-8b55-47d7-918b-cb9ca79e1e87?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165834Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=24e94791363d31126e0f23fe940c1ea2b8fc9c15d1d699f60ccb8fd3041c40c4',
    posterImage:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/13b0f2dc-8b55-47d7-918b-cb9ca79e1e87.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165834Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=5596aca7c89f5147ba31e79b6eaa802f643d8894f3906c53e627665e19034905',
  },
  {
    _id: 1734,
    text: 'Did you receive?',
    createdAt: new Date('2020-04-20T03:29:09.509Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1733,
    text: 'What is sent?',
    createdAt: new Date('2020-04-20T03:28:57.458Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1731,
    text: 'New message',
    createdAt: new Date('2020-04-20T03:27:01.517Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1730,
    text: 'New message',
    createdAt: new Date('2020-04-20T03:26:13.838Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1726,
    text: 'Are you? I don’t see a send button',
    createdAt: new Date('2020-04-20T03:25:25.876Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1724,
    text: 'Am I on the newest version?',
    createdAt: new Date('2020-04-20T03:24:36.890Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1720,
    text: 'Can you help me understand some things?',
    createdAt: new Date('2020-04-20T03:20:23.336Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1719,
    text: 'Hello sirn',
    createdAt: new Date('2020-04-20T03:20:05.409Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1591,
    text: 'cvcxvzxcv',
    createdAt: new Date('2020-04-18T06:04:05.206Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1590,
    text: 'v vb',
    createdAt: new Date('2020-04-18T06:03:01.740Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1589,
    text: 'asdfadsfasdfsdf',
    createdAt: new Date('2020-04-18T06:01:51.519Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1587,
    text: 'asdfadfsfadsf',
    createdAt: new Date('2020-04-18T05:50:25.032Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1585,
    text: 'asdfasfadsfsdfsdf',
    createdAt: new Date('2020-04-18T05:44:08.580Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1580,
    text: 'zxcvzcxvzcx',
    createdAt: new Date('2020-04-18T05:33:52.255Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1577,
    text: 'asdfasdfasdf',
    createdAt: new Date('2020-04-18T05:30:43.460Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1574,
    text: 'asdfadsfa',
    createdAt: new Date('2020-04-18T05:26:41.269Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1568,
    text: 'Hj',
    createdAt: new Date('2020-04-18T05:00:16.040Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1565,
    text: '',
    createdAt: new Date('2020-04-18T04:57:42.370Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/f67b0817-72da-433d-b8af-3b8522ca7487?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165834Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=3bb9ccb0e93645b55ea0c3e73d53b19d4e0bc2192a353bf7043ecd449e83c5a5',
    posterImage:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/f67b0817-72da-433d-b8af-3b8522ca7487.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165834Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=86386769a10e1ece74d3491a18c9c2d3a42b9a8c705e1cbdd2445d567955ce37',
  },
  {
    _id: 1560,
    text: 'Wow that’s pretty quick',
    createdAt: new Date('2020-04-18T04:55:02.493Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1559,
    text: 'Here’s another test',
    createdAt: new Date('2020-04-18T04:54:54.357Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1558,
    text: '',
    createdAt: new Date('2020-04-18T00:17:10.445Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/8fe7fd76-8d11-43cf-adaf-a8932849f02a?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165834Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=5357e163c344fd048e1356cc729d0f6527755e4667f8d9dbaad3de85d7e579e9',
    posterImage:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/8fe7fd76-8d11-43cf-adaf-a8932849f02a.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165834Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=b8a39a874c21a9b64f74e4e8f64754cc074bc64aab62fe462974c4f11997ca0a',
  },
  {
    _id: 1557,
    text: 'Ytvhi',
    createdAt: new Date('2020-04-18T00:16:41.164Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1551,
    text: 'Fudne',
    createdAt: new Date('2020-04-17T23:58:35.398Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1550,
    text: 'Tired tus a',
    createdAt: new Date('2020-04-17T23:58:29.931Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1545,
    text: 'Tfgg',
    createdAt: new Date('2020-04-17T23:55:36.751Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1544,
    text: 'Gryyt',
    createdAt: new Date('2020-04-17T23:54:55.428Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1543,
    text: 'asdfadsfasdfasd',
    createdAt: new Date('2020-04-17T23:54:23.955Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1534,
    text: 'asdfadsfasdfa',
    createdAt: new Date('2020-04-17T23:33:32.874Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1533,
    text: 'asdfadsfadsasdfdasfqwefeqwrqewreqwrqewrewqrewrqwer',
    createdAt: new Date('2020-04-17T23:30:12.269Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1532,
    text: 'asdfasdfadsfadsf',
    createdAt: new Date('2020-04-17T23:18:08.066Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1529,
    text: '',
    createdAt: new Date('2020-04-17T22:49:55.715Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
    video:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/cc483fb3-679e-4e53-9f30-3204ed82e6cd?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165834Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=6253c967c281908b70318ff0d0b37ce82a28214976c74749542b2092f1e1e218',
    posterImage:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/cc483fb3-679e-4e53-9f30-3204ed82e6cd.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165834Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=8b8a43601b6e6f5b6bd949fcc49f7a9944904941e1bf532eb3281765870edd03',
  },
  {
    _id: 1527,
    text: 'Ifnd',
    createdAt: new Date('2020-04-17T22:48:43.320Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1526,
    text: 'Jggjn',
    createdAt: new Date('2020-04-17T19:19:18.497Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1525,
    text: '',
    createdAt: new Date('2020-04-17T19:17:32.422Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/e14b349c-59ec-4abd-bf2a-72971ee33965?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165834Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=c2d98d10de85f18768dca2f7f1d2ad6420c0663461257a62aa4240827b65318f',
    posterImage:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/e14b349c-59ec-4abd-bf2a-72971ee33965.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165834Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=9137b3509a42e57394882a7e7d02238c30164e0d99a79671e2fe981c1d288e9d',
  },
  {
    _id: 1524,
    text: '',
    createdAt: new Date('2020-04-17T19:16:30.484Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/e19a2aef-f5d3-4271-9ba3-9116f4199646?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165834Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=b9cc3816bee23d02383086eba8443c9b8cc174c6258a63d71dc2ee6db84b2dac',
    posterImage:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/e19a2aef-f5d3-4271-9ba3-9116f4199646.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165834Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=05eaf52408f6ecb949e88d91023ee6b230ad17b94634d077e90762b929945eb2',
  },
  {
    _id: 1523,
    text: '',
    createdAt: new Date('2020-04-17T19:14:45.096Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/b5e32c2a-0aca-4f32-ac0e-9ec7abc8aca0?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165834Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=d6534ec44abe6b4dfa20c4c1f473ee7bef59e6e61c8dd0ed23aa4096f97451d3',
    posterImage:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/b5e32c2a-0aca-4f32-ac0e-9ec7abc8aca0.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165834Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=8be6f7581831c5aea35827dab46877f0f317abebe9e75f091278d8833a8f3875',
  },
  {
    _id: 1508,
    text: 'asdgags',
    createdAt: new Date('2020-04-16T21:42:56.356Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1504,
    text: 'Sending a message',
    createdAt: new Date('2020-04-16T21:20:19.660Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1499,
    text: 'asdfasdf',
    createdAt: new Date('2020-04-16T06:16:29.027Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1497,
    text: '',
    createdAt: new Date('2020-04-16T06:15:21.606Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/d555285155e1082ecd51007c6a59b0f8-b0d3ddfdf5f2103d202b23e84470b7b8.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=17dc89106c67f8fe5c2ec42449037f84a0ebba73fbbadf9ec7447c533c244e37',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/d555285155e1082ecd51007c6a59b0f8-b0d3ddfdf5f2103d202b23e84470b7b8.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=ccc1af915ca0f421d7b2855279e1694c72da66c1d75303b6725e9f0cdc8453ec',
  },
  {
    _id: 1496,
    text: '',
    createdAt: new Date('2020-04-16T06:10:26.816Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/b98c696972bad598c92c1c3788f552d5-2127325f0bf8256e37e6df58efc57204.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=42e40389c7a2df89ea57de492eeea554a0df9127e1ed6fb30448ba35e2b61d64',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/b98c696972bad598c92c1c3788f552d5-2127325f0bf8256e37e6df58efc57204.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=61727c055b6cf8ceda8e4110d56c484603229bae2d014f9fea4ce1e719ad1202',
  },
  {
    _id: 1484,
    text: 'Uuiiuu',
    createdAt: new Date('2020-04-15T22:45:50.754Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1483,
    text: 'Tr',
    createdAt: new Date('2020-04-15T22:43:05.275Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1482,
    text: 'Fgg',
    createdAt: new Date('2020-04-15T22:42:24.046Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1481,
    text: 'Cirnd',
    createdAt: new Date('2020-04-15T22:41:29.431Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1480,
    text: 'Hey',
    createdAt: new Date('2020-04-15T22:38:38.974Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1477,
    text: 'Ffidjjfncirjnciisidijcujfjejjfjjfjfj',
    createdAt: new Date('2020-04-15T22:31:11.637Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1466,
    text: '',
    createdAt: new Date('2020-04-15T21:57:08.163Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/3bc26467-54ce-411c-8a48-f39dc1545dc8?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=a3e58c6e3c0b63112d34d65f70295e2a6b83b1165a42a00e66cea3d4428450a3',
    posterImage:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/3bc26467-54ce-411c-8a48-f39dc1545dc8.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=8121e971fcbea87a8ca1a772033d155726880f50083d8e697dcf3d1612830e84',
  },
  {
    _id: 1465,
    text: '',
    createdAt: new Date('2020-04-15T21:54:49.130Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/595e3990-539a-404e-8ef1-5926f9331eb3?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=9aea53e46b98c4eb22e843459c52ee28900424def1cf60c404091d8187ed6ad2',
    posterImage:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/595e3990-539a-404e-8ef1-5926f9331eb3.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=7ce8bf675c40cc54230c1807bdb24d95b646a24fa009afb06951ea434720fc68',
  },
  {
    _id: 1464,
    text: '',
    createdAt: new Date('2020-04-15T21:51:30.534Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/7012be41-2b81-4550-b00a-ca80b31c1172?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=67eab137fc33eef17b463db85ddee4f35bb6d16ec0db7bad69933873d314015c',
    posterImage:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/7012be41-2b81-4550-b00a-ca80b31c1172.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=a83db41a63633277cef0a78597c116ad7465952ece6b330cc86e8ae15822c7f5',
  },
  {
    _id: 1460,
    text: '',
    createdAt: new Date('2020-04-14T21:13:34.901Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/2c8e6c47-3359-4a5e-90c2-ddb46d32e896?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=0be8aad7303f70172fdb6568bd87a7db61dceac0dd5823b2fab0eef46dc248b4',
    posterImage:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/2c8e6c47-3359-4a5e-90c2-ddb46d32e896.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=322f5d4a999e15a75df168282e0cb644c7ac73de579f7e59053bc24d3fc3e060',
  },
  {
    _id: 1457,
    text: '',
    createdAt: new Date('2020-04-14T20:52:01.931Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/cc67c99cd0d3a31fa1a26969a6a1a25a-703c5e3d569922a9cce37d759f82dc46.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=f19bf2d10352da1b62088ac60c629a7c295116283554aae85bee7631ab2631d1',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/cc67c99cd0d3a31fa1a26969a6a1a25a-703c5e3d569922a9cce37d759f82dc46.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=cd11fb69c02c778d7ba6bbf678df952e9ef69a6d36c3f930c963ab75cfeefc28',
  },
  {
    _id: 1456,
    text: 'asdfadsfadsfasdfasdf',
    createdAt: new Date('2020-04-14T20:51:20.509Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1455,
    text: '',
    createdAt: new Date('2020-04-14T05:04:23.163Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/5294c5169064f53c0a3158087ff00740-ab0d2215f34dd31582253922cf003191.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=3860b881566c747c65ee4ca57436750829904b82affaeaf45a8911abea84b676',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/5294c5169064f53c0a3158087ff00740-ab0d2215f34dd31582253922cf003191.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=6fc2672001f73605cf92672ce6362bfe46833f166afd256bb6a280902292b961',
  },
  {
    _id: 1454,
    text: 'iuhiuh',
    createdAt: new Date('2020-04-14T05:04:19.017Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1453,
    text: '',
    createdAt: new Date('2020-04-14T05:02:45.419Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/6871e155fda6ad3030f1d41e6c780ec1-02c3805e3bcf54c637ac5f40fc7e8188.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=39786986ce287b673cbe4c0f5651ed0de591c31bcc51b46598d680b997d9d16d',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/6871e155fda6ad3030f1d41e6c780ec1-02c3805e3bcf54c637ac5f40fc7e8188.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=3683f7b413952eb58101dc092ad2b7a1fa31b1bb0ca3fa09a8b5b1559011784b',
  },
  {
    _id: 1452,
    text: '',
    createdAt: new Date('2020-04-14T04:54:31.091Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/cff9891d945e3013fad5ca2fac43f541-6b4e8027cb03b100f7038dddccf702d9.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=b136b6f35a60857576f65c87b7d3fb34a3941c75500ab21a9aac64fe81c829b4',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/cff9891d945e3013fad5ca2fac43f541-6b4e8027cb03b100f7038dddccf702d9.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=4669a366820e86abd137f425a00a56de42ec670929bcff9512f21c91f3478a75',
  },
  {
    _id: 1451,
    text: '',
    createdAt: new Date('2020-04-14T04:52:29.642Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/9b46c0c6a3ead24fafb0bff8b400e188-81f0f29f4914834a370ab4cdba742b48.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=5c9bdd7e10cd8799e854d01e030369c60d3d10f5464db9447c3a32923bfb7867',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/9b46c0c6a3ead24fafb0bff8b400e188-81f0f29f4914834a370ab4cdba742b48.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=455c47022996317172ed5f7dfae064c58d469a511c647d5df509be8d2e736499',
  },
  {
    _id: 1450,
    text: '',
    createdAt: new Date('2020-04-14T04:48:36.449Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/1875c846427dcf8ba82cbccea20c1048-fb62bcaa143d6f4bebc62c8c623fb5a6.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=1a8853aa137293f8b307ab27b6dedb126215db5d7a0f8b3b0c5fed1c90db9960',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/1875c846427dcf8ba82cbccea20c1048-fb62bcaa143d6f4bebc62c8c623fb5a6.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=8c2dc8bbb6f74cc490a96d8a8ad46ce41d297136d700dde8b0b61fe1ab07a2e6',
  },
  {
    _id: 1449,
    text: '',
    createdAt: new Date('2020-04-14T04:43:28.071Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/7e5c74da2abb8ad3e72272682120a6eb-d7d9576ee7fa1a146293428cc39a4067.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=1a32e6c1f3db1531140f5c750ef5b69474104c45ab421d3ab240af252ee7eb00',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/7e5c74da2abb8ad3e72272682120a6eb-d7d9576ee7fa1a146293428cc39a4067.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=ce02ecbe0237e1dea7446282051df860a8fc6f0c597b11d172d24d8ac797589b',
  },
  {
    _id: 1448,
    text: '',
    createdAt: new Date('2020-04-14T04:35:35.091Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/0635042d81a0846dff1f40e832f1f3b9-229c741532d3e8226f7d5c2a039b89ac.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=7091952d0ec84b318c5fbec607324de53fe01c7530221230b7c33f465df7974f',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/0635042d81a0846dff1f40e832f1f3b9-229c741532d3e8226f7d5c2a039b89ac.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=73430a03fe828d4f4778c54a14456b9f782e3b9deb4f39712b7f120f312bb0ed',
  },
  {
    _id: 1445,
    text: 'does this send',
    createdAt: new Date('2020-04-14T03:23:25.863Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1334,
    text: '',
    createdAt: new Date('2020-04-08T00:09:01.031Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/701983e693d40870713bbdad511fe0c3-a7315df65e39feda1897b50c68956e5e.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=6d228c0dbbb90abe03ad3b87f409698bc20a95c54d2e59fb09227466c769fcf2',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/701983e693d40870713bbdad511fe0c3-a7315df65e39feda1897b50c68956e5e.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=7c9995f2c60942dfb002520f79af25e59c6ade32826b8f8c891deed3e91b5316',
  },
  {
    _id: 1333,
    text: '',
    createdAt: new Date('2020-04-08T00:06:00.404Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/a85362fb9547c9b6da0536e67d835c90-6da39af2a8d6cfe35507ba2fb859be56.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=d1e8dbc5c5ea0b378e0765f9218acdb8d0c327de3c71d55f770ed762921b7d8d',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/a85362fb9547c9b6da0536e67d835c90-6da39af2a8d6cfe35507ba2fb859be56.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=1cbc3c77705f3a398c516bd8a1195f2b20a12986c82451dbdb9034ef1b9bdd8b',
  },
  {
    _id: 1332,
    text: 'send',
    createdAt: new Date('2020-04-07T21:35:08.412Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1331,
    text: 'sent',
    createdAt: new Date('2020-04-07T21:34:39.969Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1330,
    text: '',
    createdAt: new Date('2020-04-07T21:32:40.904Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/a90669bcab4ed6697344c728067f801a-70284e9c8b7f9e410f1713b8212e5d77.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=42e27ee14a012d9a715cdef713d9fbf1736766a4af1aa02a35eb192660946d7b',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/a90669bcab4ed6697344c728067f801a-70284e9c8b7f9e410f1713b8212e5d77.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=bf78e88fa8e4970c392e63c144e502f87ef244a28dc848e755bda1bebdf40b9a',
  },
  {
    _id: 1329,
    text: '',
    createdAt: new Date('2020-04-07T21:21:57.471Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/4b9fc09990d4f50f644666311226e797-b06a8d320e005db2e5ffa2997f746582.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=76bfc7f360c2c3254ccb89d2ee2b2ae98cd9c5dce42a47871db3644b9240fc78',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/4b9fc09990d4f50f644666311226e797-b06a8d320e005db2e5ffa2997f746582.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=82ffa915205b8ad28f7215cdc23c8efd20471dcb58c3701c7d4dd7815afecdda',
  },
  {
    _id: 1328,
    text: '',
    createdAt: new Date('2020-04-07T21:08:22.874Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/79bfd47fe3f95935ef57507282dc69e5-4e90b63814c41084b320ed7fae0a2748.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=0e42c143ab84ad5990d4de132f18ba8e8f206b053f17d9d3e47bfc31d5414cdb',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/79bfd47fe3f95935ef57507282dc69e5-4e90b63814c41084b320ed7fae0a2748.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=d854b7a6ac2910099127f41f861541a07db553406892726faf9a915062a13257',
  },
  {
    _id: 1320,
    text: 'send',
    createdAt: new Date('2020-04-07T05:33:22.743Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1319,
    text: 'yay',
    createdAt: new Date('2020-04-07T05:31:24.849Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1318,
    text: 'qewrqerqr',
    createdAt: new Date('2020-04-07T05:23:58.681Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1317,
    text: 'asdgadsgadsg',
    createdAt: new Date('2020-04-07T05:23:56.499Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1316,
    text: 'qewrqerqe',
    createdAt: new Date('2020-04-07T05:23:54.437Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1315,
    text: 'qwerqerqasdfasdgag',
    createdAt: new Date('2020-04-07T05:23:52.198Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1314,
    text: 'asdfasdf',
    createdAt: new Date('2020-04-07T05:23:46.272Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1313,
    text: 'hewrw',
    createdAt: new Date('2020-04-07T05:23:43.798Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1312,
    text: 'oh noes',
    createdAt: new Date('2020-04-07T05:23:11.316Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1311,
    text: 'test',
    createdAt: new Date('2020-04-07T05:21:57.112Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1310,
    text: 'this probably works',
    createdAt: new Date('2020-04-07T05:14:40.445Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1309,
    text: 'asdgasdg',
    createdAt: new Date('2020-04-07T05:13:13.167Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1308,
    text: 'asdfasdfadsfadsf',
    createdAt: new Date('2020-04-07T05:11:39.146Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1307,
    text: 'setqwerq',
    createdAt: new Date('2020-04-07T05:11:21.363Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1306,
    text: 'sending',
    createdAt: new Date('2020-04-07T04:19:49.810Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1305,
    text: 'test',
    createdAt: new Date('2020-04-07T04:16:57.162Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1303,
    text: 'sendginasdfsa for woeqir',
    createdAt: new Date('2020-04-07T03:59:50.614Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1302,
    text: 'send1',
    createdAt: new Date('2020-04-07T03:48:18.064Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1301,
    text: 'send again',
    createdAt: new Date('2020-04-07T03:32:20.734Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1300,
    text: 'send',
    createdAt: new Date('2020-04-07T03:30:00.880Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1299,
    text: 'foo',
    createdAt: new Date('2020-04-06T23:02:10.482Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1298,
    text: 'testtested',
    createdAt: new Date('2020-04-06T22:54:20.176Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1297,
    text: 'asdfadsfasdfadsfasdf',
    createdAt: new Date('2020-04-06T22:53:30.181Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1296,
    text: 'sent this',
    createdAt: new Date('2020-04-06T22:51:13.732Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1295,
    text: 'asdfasdfasdfdasfdfs',
    createdAt: new Date('2020-04-06T22:40:10.355Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1294,
    text: 'sent',
    createdAt: new Date('2020-04-06T22:38:44.372Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1293,
    text: 'this si messed up',
    createdAt: new Date('2020-04-06T22:28:29.339Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1292,
    text: 'sending message',
    createdAt: new Date('2020-04-06T22:26:33.691Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1291,
    text: 'sending message',
    createdAt: new Date('2020-04-06T22:26:20.603Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1290,
    text: 'this is part of this issue',
    createdAt: new Date('2020-04-06T22:24:57.165Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1289,
    text: 'asdf',
    createdAt: new Date('2020-04-06T21:21:34.410Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1288,
    text: 'sending new',
    createdAt: new Date('2020-04-06T21:18:19.538Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1282,
    text: 'this is gelf',
    createdAt: new Date('2020-04-05T04:53:06.098Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1281,
    text: 'have fun play now play well',
    createdAt: new Date('2020-04-05T04:32:17.360Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1280,
    text: 'gohf',
    createdAt: new Date('2020-04-05T04:32:06.358Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1279,
    text: 'send',
    createdAt: new Date('2020-04-05T04:28:22.140Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1278,
    text: 'aend',
    createdAt: new Date('2020-04-05T04:28:14.087Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1277,
    text: 'send shit',
    createdAt: new Date('2020-04-05T04:26:29.844Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1276,
    text: 'try to send',
    createdAt: new Date('2020-04-05T04:23:33.662Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1275,
    text: 'werqewrweqrewqrwasdfasdfsdafadfsdafasdfqwerqewrew',
    createdAt: new Date('2020-04-05T04:19:46.463Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1274,
    text: 'STUDENT SENDING',
    createdAt: new Date('2020-04-05T03:58:17.305Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1273,
    text: 'qwer',
    createdAt: new Date('2020-04-05T03:57:17.228Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1272,
    text: 'asdfadsfadsfsfasdfdasfadsfdasf',
    createdAt: new Date('2020-04-05T03:56:49.126Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1271,
    text: 'asdfadsf',
    createdAt: new Date('2020-04-05T03:54:10.421Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1237,
    text: 'great',
    createdAt: new Date('2020-04-05T01:31:37.952Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1236,
    text: 'qwer',
    createdAt: new Date('2020-04-05T00:19:05.894Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1235,
    text: 'asdfasdfdsf',
    createdAt: new Date('2020-04-05T00:17:16.456Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1234,
    text: 'sending',
    createdAt: new Date('2020-04-05T00:14:17.073Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1233,
    text: 'qwerqewrqewr',
    createdAt: new Date('2020-04-05T00:05:06.580Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1231,
    text: 'asfasdfadsfasdf',
    createdAt: new Date('2020-04-05T00:02:55.310Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1230,
    text: 'wy does it render s mcuh',
    createdAt: new Date('2020-04-04T23:54:35.795Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1229,
    text:
      'qwerqewraspdifjasipdofjiqowejifojwqepifojasodipfjpiaodsjfioqwejiorfjqewoijrioafjpdsjfoipajdspfioajdsfpioadjfiojwqeiojrioqjfiaosdjfioasjdfiojdsafoijsadofjasdopfjioqejroiqjwioafsjdiofjadiofjqweiorjioajfsdiofjsaidojfasidojfioqwejrioqewjfioasjdfiosadjfoiadjifjoiqewjrioqewjrqoijfioasjdfopsadifpsjadiofjqwoerjqewirjiowejfoasdjfposjdaifsadpfjqwoiejrqiewjfpiasdjfijpqoewjrioqjafpsdijiofjpqowejrpiqjewiofejaiodspfjioajfskdlafj;lsdkfjkasdjf;sldjfqwjeoirjwiofahuidshjkglhjshlajksdghjskdahgjasdhfjkhjkqewhkrhuoafsdhouifhukqwehjlrafsduihjkqwleafsdhuiljkqwerafsduihljkweqrfausdhjlkasfduhiljkewafsduhiljknweafsdhuljknewafsduhiljknwaefsduhljknwefasdhuiljknewfasdhuljknaewfsdxhuljknafsduhjknafsdulhjknasdfhuljknqweoiurhqewr',
    createdAt: new Date('2020-04-04T23:54:08.404Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1227,
    text: 'asdfqwefeqfwf',
    createdAt: new Date('2020-04-04T05:57:39.919Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1226,
    text: 'asdfasdfadsfads',
    createdAt: new Date('2020-04-04T05:52:26.905Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1223,
    text: '',
    createdAt: new Date('2020-04-04T05:00:03.817Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/e3de19c6afc19015a7076d5c500a2844-974e8a54b599878dc6a4da79455fdb8b.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=53f02b73a4f9525199ccebdfc4917c099532ea55811bdaa14ae8465c096c4ff9',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/e3de19c6afc19015a7076d5c500a2844-974e8a54b599878dc6a4da79455fdb8b.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165835Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=f392962b2a0d4f3149b430548413f52866e14ee937ad09a7622bc296167a2ffa',
  },
  {
    _id: 1217,
    text: 'fuck yeah',
    createdAt: new Date('2020-04-04T00:57:08.795Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1210,
    text: 'qweqrqewrwqr',
    createdAt: new Date('2020-04-03T19:39:04.765Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1207,
    text: 'sadfafsdf',
    createdAt: new Date('2020-04-03T06:39:26.776Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1206,
    text: 'wew ererw good',
    createdAt: new Date('2020-04-03T06:06:37.762Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1205,
    text: 'qwer',
    createdAt: new Date('2020-04-03T06:05:15.045Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1204,
    text: 'sebd',
    createdAt: new Date('2020-04-03T06:05:05.339Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1203,
    text: '44',
    createdAt: new Date('2020-04-03T05:34:47.922Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1202,
    text: '2',
    createdAt: new Date('2020-04-03T05:34:42.267Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1201,
    text: '3',
    createdAt: new Date('2020-04-03T05:34:41.055Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1200,
    text: '1',
    createdAt: new Date('2020-04-03T05:34:36.643Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1199,
    text: 'send it over',
    createdAt: new Date('2020-04-03T02:02:04.374Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1198,
    text: 'asdfasfa',
    createdAt: new Date('2020-04-03T01:16:44.415Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1197,
    text: 'werewr',
    createdAt: new Date('2020-04-03T00:27:43.899Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1196,
    text: 'qewr',
    createdAt: new Date('2020-04-03T00:27:05.945Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1195,
    text: 'saefasdfdf',
    createdAt: new Date('2020-04-03T00:26:31.811Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1192,
    text: 'asdfdasf',
    createdAt: new Date('2020-04-03T00:05:37.019Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1191,
    text: 'te',
    createdAt: new Date('2020-04-02T23:45:07.322Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1190,
    text:
      'here is aosfjpidasfajeiorjpqejrieqjwprjqewiorjewporjiwerpqwjfioqejwpiorjqejrqoewjropiqewjpriojqweiorjqweoirjqoweiprjopiqewjrpoqiewjrpoiqwejrpoieqwjrpioqejwproiqejwproijqwpoirjqweporjqeoirjpqeoiwjrpoiqewjrpioejwproqw',
    createdAt: new Date('2020-04-02T23:44:54.173Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1189,
    text: 'test',
    createdAt: new Date('2020-04-02T23:42:55.966Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1188,
    text: '33',
    createdAt: new Date('2020-04-02T23:30:32.707Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1187,
    text: '2',
    createdAt: new Date('2020-04-02T23:30:29.028Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1186,
    text: '1',
    createdAt: new Date('2020-04-02T23:30:27.507Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1185,
    text: '4',
    createdAt: new Date('2020-04-02T23:30:07.701Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1184,
    text: '3',
    createdAt: new Date('2020-04-02T23:30:05.193Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1183,
    text: '22',
    createdAt: new Date('2020-04-02T23:30:03.426Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1182,
    text: 'asdfasfadfasfadfadf',
    createdAt: new Date('2020-04-02T23:29:57.658Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1181,
    text: 'qwerqerqewrerqe',
    createdAt: new Date('2020-04-02T23:29:54.156Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1180,
    text: 'asdfadsfasdf',
    createdAt: new Date('2020-04-02T23:29:51.369Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1179,
    text: 'seasdfa',
    createdAt: new Date('2020-04-02T23:29:49.575Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1178,
    text: 'qwetqewtqet',
    createdAt: new Date('2020-04-02T23:28:28.785Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1177,
    text: 'asdfasfasdf',
    createdAt: new Date('2020-04-02T23:26:57.520Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1176,
    text: 'asdfasdfdsafadsfa',
    createdAt: new Date('2020-04-02T23:26:20.408Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1175,
    text: 'test',
    createdAt: new Date('2020-04-02T23:25:16.659Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1174,
    text: 'yeah i like this',
    createdAt: new Date('2020-04-02T23:24:21.112Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1173,
    text: 'Only ok',
    createdAt: new Date('2020-04-02T22:31:56.993Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1172,
    text: 'Sent',
    createdAt: new Date('2020-04-02T22:27:23.088Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1171,
    text: 'asdfqwer',
    createdAt: new Date('2020-04-02T21:34:16.199Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1170,
    text: 'this is fine',
    createdAt: new Date('2020-04-02T20:54:27.868Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1169,
    text: 'sending',
    createdAt: new Date('2020-04-02T20:52:50.158Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1168,
    text: 'so weird',
    createdAt: new Date('2020-04-02T20:32:04.537Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1160,
    text: 'doesnt scroll',
    createdAt: new Date('2020-04-02T19:41:57.403Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1159,
    text: 'qwerqewreqwr',
    createdAt: new Date('2020-04-02T19:17:41.374Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1158,
    text: 'asdfafadsfadf',
    createdAt: new Date('2020-04-02T19:17:25.896Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1157,
    text: 'qwerqewr',
    createdAt: new Date('2020-04-02T19:17:23.976Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1156,
    text: 'weqf',
    createdAt: new Date('2020-04-02T19:17:18.978Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1155,
    text: 'immediate send',
    createdAt: new Date('2020-04-02T19:17:12.785Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1154,
    text: 'asdfasf',
    createdAt: new Date('2020-04-02T19:17:00.165Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1153,
    text: 'holyshit',
    createdAt: new Date('2020-04-02T19:16:44.065Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1152,
    text: 'test',
    createdAt: new Date('2020-04-02T19:16:35.333Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1151,
    text: 'hello thi shosuld owrk',
    createdAt: new Date('2020-04-02T06:53:33.738Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1150,
    text: 'test',
    createdAt: new Date('2020-04-02T06:52:54.097Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1149,
    text: 'sdafasdf',
    createdAt: new Date('2020-04-02T06:50:36.023Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1110,
    text: '',
    createdAt: new Date('2020-03-31T20:08:14.220Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/5e94b3f4b056ed0ca2b755c51f066e13-2fde9094b12408def2446f8b465c4b72.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=34917a21674801979394a83b7ea593037e266aa4a77508e0b938d4c2fd09e437',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/5e94b3f4b056ed0ca2b755c51f066e13-2fde9094b12408def2446f8b465c4b72.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=d9ae2141a14e02f40272b398371c98d3837a5e80705cdf3bacd18b0533ede976',
  },
  {
    _id: 1107,
    text: '',
    createdAt: new Date('2020-03-31T19:42:12.134Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/317464ea355a9f48d387f4ef828175ab-4511dbcadf606b14337e766a4c379669.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=a0ee648777fe35bc556618ed820e55b68f0addbe6f72caec8ecb640cb5909ed2',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/317464ea355a9f48d387f4ef828175ab-4511dbcadf606b14337e766a4c379669.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=9762e2f6121a0e51f4f249599e4fce4fdf6ceef286771e2fa527d00a92d5d6de',
  },
  {
    _id: 1097,
    text: '',
    createdAt: new Date('2020-03-31T19:12:13.225Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/28230cbfa48c0c61d23eca6eb8a449b9-231ed529b65a5eed5284f8260b416fca.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=590cb6c239ca4d86d6a6645cf5571906e58041175661865898d3be1177849534',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/28230cbfa48c0c61d23eca6eb8a449b9-231ed529b65a5eed5284f8260b416fca.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=c006d3881e05e76853e304307e9d5ad4983965063d314282d4fefc5f238d1026',
  },
  {
    _id: 1096,
    text: '',
    createdAt: new Date('2020-03-31T18:34:55.607Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/e4ef648f-36b7-407b-815a-8872e35cc52b?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=71a90ee99f644177a0c08ddaeae42fb8c81fcaf5f5804e596e676d1c5a1e1aaa',
    posterImage:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/e4ef648f-36b7-407b-815a-8872e35cc52b.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=4a9480383620139c27caaf5951b6c88ec20462b3c61847e1a9af4e8d5a0655a8',
  },
  {
    _id: 1093,
    text: '',
    createdAt: new Date('2020-03-31T18:25:17.820Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/313e766c1f08213fe56b6b753eb66418-ac26afc994c506e0b39bada673b8133e.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=c8a3858ee2e27f1cd14159be2ab224baed054429009c2ce91696c23e70a8be55',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/313e766c1f08213fe56b6b753eb66418-ac26afc994c506e0b39bada673b8133e.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=ab90371c0ad0a976d89173a5293ff3c3bcbb4f77060b54fff6c2a477861e283b',
  },
  {
    _id: 1092,
    text: '',
    createdAt: new Date('2020-03-31T18:13:59.709Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/e19857805c5a7ae29b7101d333758f4b-de6823e22fd4c6b8ba9bada7874dfa7a.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=682db6df9ac6af6be79eb707479ea8657688b6b4f7d87a3539bff4770e91bacb',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/e19857805c5a7ae29b7101d333758f4b-de6823e22fd4c6b8ba9bada7874dfa7a.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=803fc8e157e5c3b4bb50c9b4ea6df120cb45ae19d005e71465b9a8dfdc0c7ae1',
  },
  {
    _id: 1087,
    text: 'rwar',
    createdAt: new Date('2020-03-29T23:32:01.901Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1083,
    text: 'MAIN MENOTR MESSAGETHREADDDDDD',
    createdAt: new Date('2020-03-29T22:23:54.204Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1077,
    text: '',
    createdAt: new Date('2020-03-28T02:23:20.490Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/aaa2a8716c0b7726c75a4524de611099-83efa50d2c141732decf9bf4b6af1d73.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=94eb13249a32f4b2d6c01ce72ac10ff699dd48088c4f401a3ea6780b092085c1',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/aaa2a8716c0b7726c75a4524de611099-83efa50d2c141732decf9bf4b6af1d73.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=b9f18751ae8bf8d2b5c0d3fa02177ab307e7bd375ee3f6ecbd128b0be229f299',
  },
  {
    _id: 1076,
    text: '',
    createdAt: new Date('2020-03-28T02:20:34.184Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/8b10bff24941e6fc391a49a36ffd6ab2-02433a01206ace620543064aef2c043f.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=138b3bfc20d2025759e040ccd7cf29b52d19bd76a3e67faaa17cc9d9a7dff348',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/8b10bff24941e6fc391a49a36ffd6ab2-02433a01206ace620543064aef2c043f.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=2080337773679c89c5d548deadd588af21e6e2cbbc44e382607f0478132be7ad',
  },
  {
    _id: 1075,
    text: '',
    createdAt: new Date('2020-03-27T22:58:02.408Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/09f83e4834ff4e245c35363aae5b6c6d-eeb148c05928e66145603f91063f37f0.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=15c633e73e4bb6edef26119b88b1cdd6816c8460b3b57971582fa62cfb53947e',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/09f83e4834ff4e245c35363aae5b6c6d-eeb148c05928e66145603f91063f37f0.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=f2600329be793a5697d0299e7c59b04b61768477605e54b8a253c0d47fe96b99',
  },
  {
    _id: 1068,
    text: 'Hg',
    createdAt: new Date('2020-03-27T20:50:20.798Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1067,
    text: 'Gg',
    createdAt: new Date('2020-03-27T20:50:15.852Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1066,
    text: '',
    createdAt: new Date('2020-03-27T20:49:07.046Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/8cfa5e8e-5aaf-4f22-aedd-de6a27c13d9f?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=02f0b6b910bc7fe1f9f2815a8c53c70bfdb99d76ae4a31e6b092b9044a6e2fcd',
    posterImage:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/8cfa5e8e-5aaf-4f22-aedd-de6a27c13d9f.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=a6f0fb099e756ccff47fabb882f9c58d31028ff0847b5c4991aa390cc6556a5d',
  },
  {
    _id: 1065,
    text: '',
    createdAt: new Date('2020-03-27T19:25:26.870Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/ffe194df-8bd2-4190-9157-6d2aca8a0e39?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=ce1d623923c8775a89411cb46f3984553269a6ae830270fa6451b4f1292949cd',
    posterImage:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/ffe194df-8bd2-4190-9157-6d2aca8a0e39.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=7dbedfb4cc62e02efb5903b68342f46a005079b5f44337df5dc58062392070e1',
  },
  {
    _id: 1064,
    text: 'Y',
    createdAt: new Date('2020-03-27T19:25:04.215Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1063,
    text: '',
    createdAt: new Date('2020-03-27T19:23:45.129Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/ae6d3b29-7bc8-4888-ac82-c6c48a17d523?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=1a92013165be679d1afd994c51af2d66ccabe7939f685e57ec091ea91612a803',
    posterImage:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/ae6d3b29-7bc8-4888-ac82-c6c48a17d523.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=a11536f876ac470e800219afcee17d7f5c76b1fb3d0a6e11fc40507242ac14a5',
  },
  {
    _id: 1062,
    text: 'Dd',
    createdAt: new Date('2020-03-27T19:23:21.183Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 1061,
    text: '',
    createdAt: new Date('2020-03-27T19:22:12.834Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/8f129b32-654b-4955-9738-f84de5acfc00?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=8e9de95411f10a8747e4ae90552670ad236353e24aec41618795a43f88f892fd',
    posterImage:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/8f129b32-654b-4955-9738-f84de5acfc00.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=f7b8c98d0d6a8eab9c410bcfb3229a5852a936f73898fe391c32c6c0561175ae',
  },
  {
    _id: 1057,
    text: '',
    createdAt: new Date('2020-03-27T18:35:15.321Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/9368c6336758cfdb72e6f2f4158e83c0-5717a3a20a1e97d7f09cb09b4d2bcb94.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=2603a1a7d654416030540d10d826ade6f80b71a3cc4ea7d0c70cc0a1d746b40d',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/9368c6336758cfdb72e6f2f4158e83c0-5717a3a20a1e97d7f09cb09b4d2bcb94.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=74db833014c60236b3ad1da95d7fe2d6eee5120a37252f95cbb60bc915120479',
  },
  {
    _id: 1056,
    text: '',
    createdAt: new Date('2020-03-27T18:07:13.083Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
    video:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/894ebf70c1a785029f363c5d6944c4f2-f84e6f6230c540b2e8a26bae208a53d3.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=3c8f3929b4f114a6c2fbd360ad8910a35bc7e21c9382076d681d97fb5a19465f',
    posterImage:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/894ebf70c1a785029f363c5d6944c4f2-f84e6f6230c540b2e8a26bae208a53d3.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200910%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200910T165836Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=474c1d62113e485bf3727c44add283e982729b71e8ff9a3baacebbd7565c112d',
  },
  {
    _id: 1046,
    text: 'Jjj',
    createdAt: new Date('2020-03-23T16:10:46.869Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 1044,
    text: 'test',
    createdAt: new Date('2020-03-23T06:06:07.826Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 764,
    text: 'again',
    createdAt: new Date('2020-03-13T21:44:34.585Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 761,
    text: 'test',
    createdAt: new Date('2020-03-13T21:41:54.704Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 758,
    text: 'spencer mentor',
    createdAt: new Date('2020-03-13T21:35:13.529Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 622,
    text: 'ok',
    createdAt: new Date('2020-03-08T05:28:34.297Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 621,
    text: 'Uiop',
    createdAt: new Date('2020-03-08T05:27:33.155Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 559,
    text: 'Tt',
    createdAt: new Date('2020-03-07T03:27:30.964Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 556,
    text: 'Ok',
    createdAt: new Date('2020-03-07T03:13:00.734Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 554,
    text: 'Gdsgfsd',
    createdAt: new Date('2020-03-07T03:04:35.592Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 553,
    text: 'Hello sie',
    createdAt: new Date('2020-03-07T03:03:07.779Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 539,
    text: 'Jkk',
    createdAt: new Date('2020-03-06T20:58:50.701Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 500,
    text: 'Gghy',
    createdAt: new Date('2020-03-04T18:27:17.267Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 472,
    text: 'WOW',
    createdAt: new Date('2020-03-02T21:51:48.806Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 471,
    text: 'test',
    createdAt: new Date('2020-03-02T21:51:42.155Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 425,
    text: "let's get jammin",
    createdAt: new Date('2020-02-21T23:11:23.030Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 424,
    text: 'hello again',
    createdAt: new Date('2020-02-21T23:09:40.390Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 423,
    text: 'helo mentor',
    createdAt: new Date('2020-02-21T23:09:26.212Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 422,
    text: 'damn!',
    createdAt: new Date('2020-02-21T23:03:10.335Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 421,
    text: 'adsfasdfdasf',
    createdAt: new Date('2020-02-21T23:00:13.113Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 420,
    text: 'i eam spencer',
    createdAt: new Date('2020-02-21T22:57:39.612Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 419,
    text: 'garbo',
    createdAt: new Date('2020-02-21T22:56:54.202Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 418,
    text: 'Uuu',
    createdAt: new Date('2020-02-21T22:56:47.892Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 417,
    text: 'foobar',
    createdAt: new Date('2020-02-21T22:55:28.061Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 416,
    text: 'qwer',
    createdAt: new Date('2020-02-21T22:54:56.074Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 415,
    text: 'Hhhh',
    createdAt: new Date('2020-02-21T22:32:17.096Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 414,
    text: 'Tezt',
    createdAt: new Date('2020-02-21T22:31:48.559Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 413,
    text: 'Ddffd',
    createdAt: new Date('2020-02-21T22:29:28.819Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 412,
    text: 'Ho',
    createdAt: new Date('2020-02-21T22:29:09.383Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 411,
    text: 'Dd',
    createdAt: new Date('2020-02-21T22:28:57.866Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 410,
    text: 'alrigh rasta',
    createdAt: new Date('2020-02-21T22:28:36.959Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 409,
    text: 'bitch please',
    createdAt: new Date('2020-02-21T22:26:37.469Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 408,
    text: 'ho test',
    createdAt: new Date('2020-02-21T22:24:42.582Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 407,
    text: 'offline',
    createdAt: new Date('2020-02-21T22:20:13.441Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 406,
    text: 'this is crazy notification',
    createdAt: new Date('2020-02-21T22:19:21.770Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 405,
    text: 'this is cray slwo',
    createdAt: new Date('2020-02-21T22:18:56.900Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 404,
    text: 'oh no oifowjerqw',
    createdAt: new Date('2020-02-21T22:18:26.836Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 403,
    text: 'wqerqewr',
    createdAt: new Date('2020-02-21T22:18:13.032Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 402,
    text: 'asdfadsfasdfasdfasfafasdfasdfasf',
    createdAt: new Date('2020-02-21T22:17:57.166Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 401,
    text: 'test',
    createdAt: new Date('2020-02-21T22:17:44.644Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 400,
    text: 'wtffff',
    createdAt: new Date('2020-02-21T22:15:32.126Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 399,
    text: 'qwerqwerqrewqr',
    createdAt: new Date('2020-02-21T22:15:01.109Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 398,
    text: 'another one',
    createdAt: new Date('2020-02-21T22:13:58.667Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 397,
    text: 'qewtjqewt',
    createdAt: new Date('2020-02-21T22:13:42.958Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 396,
    text: 'testnofiy',
    createdAt: new Date('2020-02-21T22:12:37.848Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 395,
    text: 'notify',
    createdAt: new Date('2020-02-21T22:11:44.686Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 394,
    text: 'hello',
    createdAt: new Date('2020-02-21T22:11:09.641Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 375,
    text: 'NOW THIS SHOULD WORK',
    createdAt: new Date('2020-02-19T19:53:46.189Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 374,
    text: 'this si !!',
    createdAt: new Date('2020-02-19T19:48:05.634Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 373,
    text: 'qwerqewr',
    createdAt: new Date('2020-02-19T19:47:42.574Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 372,
    text: 'qwerqewr',
    createdAt: new Date('2020-02-19T19:46:47.695Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 371,
    text: 'notify you',
    createdAt: new Date('2020-02-19T19:46:19.174Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 211,
    text: 'test',
    createdAt: new Date('2020-02-18T20:31:09.013Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 210,
    text: 'now we are talking',
    createdAt: new Date('2020-02-18T20:30:58.935Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 209,
    text: 'est',
    createdAt: new Date('2020-02-18T20:29:33.997Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 208,
    text: 'qtwetq',
    createdAt: new Date('2020-02-18T20:29:27.583Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 207,
    text: 'sdvzxcvzcxvzcvzc',
    createdAt: new Date('2020-02-18T20:26:49.099Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 206,
    text: 'asdfqwer',
    createdAt: new Date('2020-02-18T20:26:07.111Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 205,
    text: 'garr test',
    createdAt: new Date('2020-02-18T20:25:57.235Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 204,
    text: 'qwet',
    createdAt: new Date('2020-02-18T20:16:37.757Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 203,
    text: 'test',
    createdAt: new Date('2020-02-18T20:15:51.452Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 202,
    text: 'test notification',
    createdAt: new Date('2020-02-18T20:13:23.196Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 201,
    text: 'test',
    createdAt: new Date('2020-02-17T20:18:46.564Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 200,
    text: 'qwer',
    createdAt: new Date('2020-02-13T22:13:07.594Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 199,
    text: 'wet',
    createdAt: new Date('2020-02-13T22:12:57.730Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 198,
    text: 'wasdf',
    createdAt: new Date('2020-02-13T22:12:49.751Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 197,
    text: 'arbor!',
    createdAt: new Date('2020-02-13T22:12:11.764Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 196,
    text: 'qwoietjqoewtqewt',
    createdAt: new Date('2020-02-13T22:11:14.211Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 195,
    text: 'qweotijqewot',
    createdAt: new Date('2020-02-13T22:11:03.656Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 194,
    text: 'qwetqwet',
    createdAt: new Date('2020-02-13T21:36:11.339Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 193,
    text: 'yet noather tier',
    createdAt: new Date('2020-02-13T21:35:09.605Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 192,
    text: 'gard',
    createdAt: new Date('2020-02-13T21:31:14.324Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 191,
    text: 'darn test again',
    createdAt: new Date('2020-02-13T21:29:38.121Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 190,
    text: 'qwet',
    createdAt: new Date('2020-02-13T21:28:36.635Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 189,
    text: 'again',
    createdAt: new Date('2020-02-13T21:28:05.810Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 188,
    text: 'test',
    createdAt: new Date('2020-02-13T21:27:23.391Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 187,
    text: 'fooooo',
    createdAt: new Date('2020-02-13T21:04:28.295Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 186,
    text: 'doube',
    createdAt: new Date('2020-02-13T21:03:36.294Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 185,
    text: 'asdfawerqwer',
    createdAt: new Date('2020-02-13T21:03:03.353Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 184,
    text: 'foobar',
    createdAt: new Date('2020-02-13T21:02:02.410Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 183,
    text: 'wtf this was working',
    createdAt: new Date('2020-02-13T20:59:38.373Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 182,
    text: 'wqer',
    createdAt: new Date('2020-02-13T20:59:04.319Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 181,
    text: 'nowtry',
    createdAt: new Date('2020-02-13T20:57:05.163Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 180,
    text: 'qowier',
    createdAt: new Date('2020-02-13T20:56:25.710Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 179,
    text: 'testbear',
    createdAt: new Date('2020-02-13T20:54:38.040Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 178,
    text: 'a',
    createdAt: new Date('2020-02-13T20:48:59.336Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 177,
    text: 'blar',
    createdAt: new Date('2020-02-13T20:48:47.388Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 176,
    text: 'Dfff',
    createdAt: new Date('2020-02-13T20:46:36.095Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 175,
    text: 'another',
    createdAt: new Date('2020-02-13T20:45:07.324Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 174,
    text: 'testuckes',
    createdAt: new Date('2020-02-13T09:39:52.675Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 173,
    text: 'twetqewt',
    createdAt: new Date('2020-02-13T09:28:51.657Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 172,
    text: 'easfasdf',
    createdAt: new Date('2020-02-13T09:27:58.968Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 171,
    text: 'testasdf',
    createdAt: new Date('2020-02-13T09:26:30.690Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 170,
    text: 'acntet',
    createdAt: new Date('2020-02-13T09:25:22.128Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 169,
    text: 'test afte rparseint',
    createdAt: new Date('2020-02-13T09:22:35.144Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 168,
    text: 'asdf',
    createdAt: new Date('2020-02-13T09:21:03.793Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 167,
    text: 'asdfadsf',
    createdAt: new Date('2020-02-13T09:20:26.404Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 166,
    text: 'test',
    createdAt: new Date('2020-02-13T09:20:19.109Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 165,
    text: 'bra',
    createdAt: new Date('2020-02-13T09:19:26.000Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 164,
    text: 'haha',
    createdAt: new Date('2020-02-13T09:18:18.766Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 163,
    text: 'test again silly',
    createdAt: new Date('2020-02-13T08:54:38.919Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 162,
    text: 'mentor',
    createdAt: new Date('2020-02-13T08:52:06.568Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 160,
    text: 'asdfasdfadsf',
    createdAt: new Date('2020-02-10T17:57:47.180Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 124,
    text: 'bar',
    createdAt: new Date('2020-01-28T03:48:14.961Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 123,
    text: 'No',
    createdAt: new Date('2020-01-28T03:44:37.800Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 337, name: 'Spencer Handley' },
  },
  {
    _id: 122,
    text: 'dar dar',
    createdAt: new Date('2020-01-27T21:53:57.744Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 121,
    text: 'barbar',
    createdAt: new Date('2020-01-27T21:53:42.065Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 120,
    text: 'foobar',
    createdAt: new Date('2020-01-27T21:52:58.204Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 119,
    text: 'HELLO',
    createdAt: new Date('2020-01-27T21:50:41.347Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 118,
    text: 'hey friend',
    createdAt: new Date('2020-01-27T21:50:27.873Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 115,
    text: 'Tst',
    createdAt: new Date('2020-01-21T18:24:41.553Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 113,
    text: 'test',
    createdAt: new Date('2020-01-21T02:12:55.507Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 112,
    text: 'Gjfiejxnf',
    createdAt: new Date('2020-01-21T01:46:34.310Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 111,
    text: 'asdfadsfasdf',
    createdAt: new Date('2020-01-21T01:43:00.872Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 110,
    text: 'Foeoidf',
    createdAt: new Date('2020-01-21T01:38:18.326Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 102,
    text: 'Hello',
    createdAt: new Date('2020-01-20T23:57:45.987Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
  {
    _id: 101,
    text: 'Test chat',
    createdAt: new Date('2020-01-20T19:42:52.570Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 339, name: 'Marc Gelfo' },
  },
];

export const mediumChat = [
  {
    _id: 2708,
    text:
      'Hello? sldfjklsdjflskjdflkjsdlfkjsldkjflskdjflsjdlfjsldfjlsdkjflsdjflsjdlfkjsldfjklsdjfwioejfoweijfowejfwijefowijefoiwjefowijeofijweofjweofi',
    createdAt: new Date('2020-08-01T23:00:08.536Z'),
    received: false,
    pending: true,
    sent: false,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2707,
    text: 'Hey. Are you there?',
    createdAt: new Date('2020-08-01T23:00:06.149Z'),
    received: false,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2706,
    text: 'qewrqewrqewr',
    createdAt: new Date('2020-08-01T23:00:04.048Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2705,
    text: 'qewreqwr',
    createdAt: new Date('2020-08-01T23:00:01.585Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2704,
    text: 'qwerqer',
    createdAt: new Date('2020-08-01T22:59:59.809Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2703,
    text: 'qwerqew',
    createdAt: new Date('2020-08-01T22:59:57.258Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2702,
    text: '',
    createdAt: new Date('2020-08-01T22:20:20.806Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
    video:
      'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
    posterImage:
      'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/images/BigBuckBunny.jpg',
  },
  {
    _id: 2634,
    text: 'cool',
    createdAt: new Date('2020-07-29T06:50:48.829Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2564,
    text: 'and it looks like it worked from medium',
    createdAt: new Date('2020-07-28T16:57:10.314Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2563,
    text: 'this is medium, i just submitted a new video',
    createdAt: new Date('2020-07-28T16:56:44.949Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2552,
    text: 'hello again from medium',
    createdAt: new Date('2020-07-28T16:42:31.595Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2547,
    text: 'NEW FROM MEDIUM',
    createdAt: new Date('2020-07-28T15:39:30.276Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2546,
    text: 'new to MEDIUM FROM MENTOR',
    createdAt: new Date('2020-07-28T15:39:06.416Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2545,
    text: 'responding',
    createdAt: new Date('2020-07-28T15:30:46.152Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2544,
    text: 'med',
    createdAt: new Date('2020-07-28T15:30:29.944Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2543,
    text: 'med',
    createdAt: new Date('2020-07-28T15:30:29.793Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2542,
    text: 'med',
    createdAt: new Date('2020-07-28T15:30:27.169Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2541,
    text: 'med',
    createdAt: new Date('2020-07-28T15:30:26.006Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2540,
    text: 'med',
    createdAt: new Date('2020-07-28T15:30:24.838Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2536,
    text: 'jello! medium',
    createdAt: new Date('2020-07-28T15:28:59.660Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2535,
    text: 'hi medium',
    createdAt: new Date('2020-07-28T15:28:37.150Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2534,
    text: 'yeah',
    createdAt: new Date('2020-07-28T09:28:44.620Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2533,
    text: 'Hope med',
    createdAt: new Date('2020-07-28T09:28:33.945Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2532,
    text: 'Cool med',
    createdAt: new Date('2020-07-28T09:27:05.109Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2531,
    text: 'hmm',
    createdAt: new Date('2020-07-28T09:23:42.100Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2530,
    text: 'Helllo medium long',
    createdAt: new Date('2020-07-28T09:21:43.826Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2529,
    text: 'Ll',
    createdAt: new Date('2020-07-28T09:20:29.380Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2528,
    text: 'Vc',
    createdAt: new Date('2020-07-28T09:19:26.630Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2527,
    text: 'Arid med bugs',
    createdAt: new Date('2020-07-28T09:17:32.153Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2526,
    text: 'Medddd',
    createdAt: new Date('2020-07-28T09:17:16.147Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2525,
    text: 'Aa',
    createdAt: new Date('2020-07-28T09:16:35.813Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2524,
    text: 'Med',
    createdAt: new Date('2020-07-28T09:14:54.284Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2523,
    text: 'MEDIUM',
    createdAt: new Date('2020-07-28T09:14:23.131Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2522,
    text: 'Hey stage mentor I am',
    createdAt: new Date('2020-07-28T09:14:08.363Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2516,
    text: 'Ok that’s a lot!',
    createdAt: new Date('2020-07-28T08:36:03.676Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2445,
    text: '20:15',
    createdAt: new Date('2020-07-24T03:15:48.577Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2444,
    text: '20:13',
    createdAt: new Date('2020-07-24T03:13:52.382Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2443,
    text: 'another',
    createdAt: new Date('2020-07-24T03:10:43.810Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2442,
    text: 'test 20:09',
    createdAt: new Date('2020-07-24T03:09:53.279Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2441,
    text: 'test 19:50',
    createdAt: new Date('2020-07-24T02:51:10.116Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2440,
    text: 'now test 19:49',
    createdAt: new Date('2020-07-24T02:49:36.429Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2439,
    text: '19:48 med',
    createdAt: new Date('2020-07-24T02:48:12.449Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2438,
    text: '19;46',
    createdAt: new Date('2020-07-24T02:46:56.479Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2437,
    text: '19:45',
    createdAt: new Date('2020-07-24T02:45:27.381Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2436,
    text: 'another test',
    createdAt: new Date('2020-07-24T02:43:42.901Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2435,
    text: 'holy moly',
    createdAt: new Date('2020-07-24T02:40:56.607Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2434,
    text: 'testsasdf',
    createdAt: new Date('2020-07-24T02:39:05.493Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2433,
    text: 'ok sending',
    createdAt: new Date('2020-07-24T02:38:15.288Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2432,
    text: 'new message',
    createdAt: new Date('2020-07-24T02:30:58.845Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2431,
    text: 'phew',
    createdAt: new Date('2020-07-24T02:29:40.148Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2430,
    text: 'test',
    createdAt: new Date('2020-07-24T02:28:21.818Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2429,
    text: 'test',
    createdAt: new Date('2020-07-24T02:17:33.003Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2428,
    text: 'checking',
    createdAt: new Date('2020-07-24T02:16:10.353Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2427,
    text: 'sending message',
    createdAt: new Date('2020-07-24T02:11:14.811Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2426,
    text: 'send message',
    createdAt: new Date('2020-07-24T02:04:13.405Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2390,
    text: 'qwer',
    createdAt: new Date('2020-07-10T04:01:16.893Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2378,
    text: 'mediu',
    createdAt: new Date('2020-07-02T20:34:09.913Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2366,
    text: 'Chick',
    createdAt: new Date('2020-06-26T01:23:15.109Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2365,
    text: 'Ifiendjcjurjdhd',
    createdAt: new Date('2020-06-26T01:23:03.251Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2261,
    text: 'qwer medium',
    createdAt: new Date('2020-05-09T23:49:09.794Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2240,
    text: 'I sent it medium',
    createdAt: new Date('2020-04-29T20:59:12.304Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2235,
    text: 'Medium',
    createdAt: new Date('2020-04-29T20:56:44.347Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2188,
    text: 'asdfadsf',
    createdAt: new Date('2020-04-29T06:10:45.415Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2187,
    text: 'med',
    createdAt: new Date('2020-04-29T06:08:04.421Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2186,
    text: 'meddd',
    createdAt: new Date('2020-04-29T06:07:50.928Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2185,
    text: 'medium',
    createdAt: new Date('2020-04-29T06:07:33.037Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2124,
    text: 'Refresh required',
    createdAt: new Date('2020-04-25T22:52:34.321Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2121,
    text: 'YOU ARE SO MEDIUM',
    createdAt: new Date('2020-04-25T22:50:49.049Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2120,
    text: 'Medium',
    createdAt: new Date('2020-04-25T22:50:10.321Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2044,
    text: 'Medium user is active',
    createdAt: new Date('2020-04-25T02:58:18.144Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2039,
    text: 'Vsssd',
    createdAt: new Date('2020-04-25T02:57:28.189Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2037,
    text: 'Ejjdndjdjdnd',
    createdAt: new Date('2020-04-25T02:57:05.654Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2036,
    text: 'Djjdjdd',
    createdAt: new Date('2020-04-25T02:57:03.651Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2035,
    text: 'Kdjdjdd',
    createdAt: new Date('2020-04-25T02:56:59.776Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2034,
    text: 'Bdjxjsjjss',
    createdAt: new Date('2020-04-25T02:56:56.492Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2033,
    text: 'Djjdjdddjsjd',
    createdAt: new Date('2020-04-25T02:56:54.813Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2032,
    text: 'Fjdndnd',
    createdAt: new Date('2020-04-25T02:56:53.309Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2025,
    text: 'medium user',
    createdAt: new Date('2020-04-25T02:54:14.288Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 343, name: 'Stage Mentor GMI' },
  },
  {
    _id: 2022,
    text: 'Kxmdnc',
    createdAt: new Date('2020-04-25T02:51:44.520Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2011,
    text: 'Grgy',
    createdAt: new Date('2020-04-25T02:43:53.056Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 2009,
    text: 'Medium mtext',
    createdAt: new Date('2020-04-25T02:37:14.094Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
  {
    _id: 1978,
    text: 'medium user',
    createdAt: new Date('2020-04-25T02:25:59.047Z'),
    received: true,
    pending: false,
    sent: true,
    system: false,
    user: { _id: 500, name: 'v1.11 medium user test' },
  },
];

export const allThreads = {
  messageThreads: [
    {
      id: '2471',
      subject: 'Student Submission',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/db0a43a1-d9ea-44c2-8f54-0a1fc24729c3.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=eeab6c6a63c5113e3a3444d598e952c75943ec3c2d6f53dba60b06aedc672870',
      lastMessageAt: '2020-09-04T19:16:37.594571+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2469',
      subject: 'Student Submission',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/c0cb13c4321c0e4cfaca2104e8a3bc2b-941fb867fa9b02042fa9a138648f4958.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=4ab351774c307d9e082a81a6b068efed0f3eca4185a38dc985537cb044da3855',
      lastMessageAt: '2020-09-01T19:22:47.469923+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2087',
      subject: null,
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/1863d21a-7500-4b82-97f4-52c6cfd0b176.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=c919a3f4658efa00bf21cae5c48ba1f659e2f084880feb8edfeb495917992d97',
      lastMessageAt: '2020-08-15T02:21:40.706609+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2465',
      subject: 'Student Submission',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/a23c732d-3cd7-4cc8-aecf-56e98116d62c.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=60886224437b92942e1cb1511dc441a5714eccf0b58ca7ea31a1b843263c8fe2',
      lastMessageAt: '2020-08-14T23:58:25.037808+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2464',
      subject: '1-2-3-4-5-6-7-8 Patterns - 6th String Back Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/774e7e85cb7bde59530203dbf59ca2c6-a4709153ddc35cadc7c8808f2f1c399a.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=53184719e648da96e7c929aff2b640d23e721f05647851f7927780cd1a5c9fa4',
      lastMessageAt: '2020-08-14T05:08:20.429971+00:00',
      exercises: [
        {
          id: '610',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2463',
      subject: 'Intervals 3rds - 6th String Back Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/c36b3ecb6a68e58c3552deb6c2a02904-4a025b6e87453657b64bf57e3465f825.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=012113c7eb426a334ccc7dd3642917815d1e2eeafdd26ca32d1fd8f4580b738a',
      lastMessageAt: '2020-08-14T05:03:37.312451+00:00',
      exercises: [
        {
          id: '615',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2439',
      subject: 'Intervals 3rds - 4th String Front Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/e4c330ee-08bb-442f-ab51-ea96d560b439.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=0f9ee22a32afe9e4a4190d53513b09a157dbd5271bd3b8a000754d2c6278dd75',
      lastMessageAt: '2020-08-04T23:09:34.092471+00:00',
      exercises: [
        {
          id: '618',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2438',
      subject: '1-2-3-4-5-6-7-8 Patterns - 5th String Front Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/f2f79b18-601e-41c5-805b-b74c3ff34bed.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=43f8bdbebde62d65a0fc5b8de6699769870d8968d07bb20bf384e96be5d24d44',
      lastMessageAt: '2020-08-04T23:07:02.188875+00:00',
      exercises: [
        {
          id: '611',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2437',
      subject: '1-2-3-4-5-6-7-8 Patterns - 5th String Back Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/c5ed3b3f-95e9-4a43-bbce-85a09e0b67f2.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=b21310a11d52a74a4ed4baac3c30a63e7b9e3b86032f4e6d94b327849632c439',
      lastMessageAt: '2020-08-04T23:06:46.532119+00:00',
      exercises: [
        {
          id: '612',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2436',
      subject: '1-2-3-4-5-6-7-8 Patterns - 6th String Front Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/ac31f62d-fe6e-4478-a890-a1bc3d41c82a.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=f59ba7a3122e59bc5483cd7d3771f1a768759429368cecd8c51c2cf886df54e3',
      lastMessageAt: '2020-08-04T23:06:17.906401+00:00',
      exercises: [
        {
          id: '609',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2435',
      subject: '1-3-5 Patterns - 5th String Front Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/0f794459-d9c6-4565-a267-dbb7c4d32453.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=f2bfedbe532610136432126ff76b87ccb0057123801d20745d3cb2e42f381a65',
      lastMessageAt: '2020-08-04T23:03:17.837900+00:00',
      exercises: [
        {
          id: '605',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2434',
      subject: 'Intervals 4ths - 4th String Front Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/fa7958a9-31f8-49d1-a882-204b50fc8dc7.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=f8d207384dcf992da61c653e573378335369fa2239a6d5a30c374cc64ea8bc12',
      lastMessageAt: '2020-08-04T22:58:43.976369+00:00',
      exercises: [
        {
          id: '623',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2433',
      subject: 'Intervals 4ths - 5th String Front Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/7da128fe-1731-488c-94d2-e49f9e3243e7.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=25617074df57a1888414bacbf848ffd4a79b187916c75f2e3263ae61ad955c4b',
      lastMessageAt: '2020-08-04T22:58:21.715823+00:00',
      exercises: [
        {
          id: '622',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2432',
      subject: 'Intervals 4ths - 5th String Back Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/dbd29223-dcc3-40a0-8a3f-96b32f74beb9.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=0de725ad76f0116f8ffbf5cb688c8add8c59e2de6047d25592b13df6049b751f',
      lastMessageAt: '2020-08-04T22:55:55.354481+00:00',
      exercises: [
        {
          id: '621',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2431',
      subject: 'Intervals 4ths - 6th String Back Form',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/1c8217df-49a4-4c38-821d-aacbddb5b190.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=d0e655682ff44fd3dd4d0b3e10e79cdbccfbd0cba41a6c73961864db054fcfb2',
      lastMessageAt: '2020-08-04T22:55:34.984018+00:00',
      exercises: [
        {
          id: '620',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2430',
      subject: 'Intervals 4ths - 6th String Front Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/0c3d9e25-aaad-4568-b419-ea7da0283248.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=f7a313b3eb60ca56cc2d11173c592e8c93ef7dbe892d8629e30df2976b48b9b5',
      lastMessageAt: '2020-08-04T22:54:17.818391+00:00',
      exercises: [
        {
          id: '619',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2427',
      subject: 'Intervals 3rds - 4th String Front Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/b4056aa4-c8a7-404d-8da9-43fd356ebe36.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=075991dde573f4fbf74d9d87bf741516ab25ceb168a959a16484aae8b92fedee',
      lastMessageAt: '2020-08-04T22:43:13.398493+00:00',
      exercises: [
        {
          id: '618',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2426',
      subject: 'Intervals 3rds - 5th String Back Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/59708aab-667a-48fb-8f4c-7921a5b90028.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=f18adef9ca226822c44fcb925f29d0bc0e7bea316f061d663c376440a389db6d',
      lastMessageAt: '2020-08-04T22:40:25.389370+00:00',
      exercises: [
        {
          id: '617',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2425',
      subject: 'Intervals 3rds - 5th String Front Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/86f57578-01d4-4d1a-b688-3c0d876f8c20.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=2e36b07466b2c20d968d7d366abd7f50ef7a18f34417d823feda1dd529647f76',
      lastMessageAt: '2020-08-04T22:26:54.398372+00:00',
      exercises: [
        {
          id: '616',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2367',
      subject: '1-2-3-4-5-6-7-8 Patterns - 5th String Front Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/6ce4a38e-3949-4e3c-9cca-ccad1cdcb21c.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=64e9cace5e5cad5b805f91f0c146c28107336cb9f32abc63099f15949742abe3',
      lastMessageAt: '2020-07-30T19:25:23.319055+00:00',
      exercises: [
        {
          id: '611',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2366',
      subject: 'Intervals 3rds - 6th String Back Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/9ac8c971-28b5-4232-a076-7cf202311db2.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=357f6ef93af7e24da6611f3858f86dad1e66cfd9184b6cb9c6b5964e86620a62',
      lastMessageAt: '2020-07-30T19:25:00.697155+00:00',
      exercises: [
        {
          id: '615',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2356',
      subject: '1-2-3-4 Patterns - 6th String Front Form',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/0f65e06e-bda9-4222-9aa7-6a2b3610a181.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=8d8d362b5c6ccb2665577d18aac216353ef8d2ec67f6005639eebeb17970fefa',
      lastMessageAt: '2020-07-30T19:15:58.903354+00:00',
      exercises: [
        {
          id: '594',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2355',
      subject: '1-2-3-5 Patterns - 5th String Front Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/54bc5417-e2c4-4e45-b428-bcfdc5f7cf09.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=41b61f042633f74abd53d9b430c6f0a8ca415afb2f4b19393aacd7dd23e6615a',
      lastMessageAt: '2020-07-30T19:09:32.185063+00:00',
      exercises: [
        {
          id: '602',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2354',
      subject: '1-2-3-4-5-6-7-8 Patterns - 5th String Back Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/55ea3474-5d43-44e9-896f-3be50855e09c.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=ac77a6ef70a59dfb00f21da9491714260e6c12be86dea8ad0786ec61f8922985',
      lastMessageAt: '2020-07-30T19:08:48.762272+00:00',
      exercises: [
        {
          id: '612',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2353',
      subject: '1-3-5 Patterns - 5th String Back Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/c318a347-6795-44fe-9ab8-9aa0cede902f.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=a97add0e0422ee2eaf44074bd22e27901d62caa295925bacc7d0433435835150',
      lastMessageAt: '2020-07-30T19:06:54.965991+00:00',
      exercises: [
        {
          id: '607',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2351',
      subject: '1-2-3-4 Patterns - 5th String Back Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/f4e94b77-3f95-4445-bea1-a0c5222a6258.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=b72ca824c28d493b70b23213b2329e9ed9c660d0aa8788d30b18ba8d34b811a3',
      lastMessageAt: '2020-07-30T19:04:09.527188+00:00',
      exercises: [
        {
          id: '597',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2350',
      subject: '1-2-3-4 Patterns - 5th String Back Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/33e7a3e5-06a0-45f6-bd53-5f8a2e78f16a.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=2505b7f2f6ae26d215e2df5e1357e32325f0354407b0a63107dd1756715fd46a',
      lastMessageAt: '2020-07-30T19:01:45.615517+00:00',
      exercises: [
        {
          id: '597',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2344',
      subject: 'Intervals 3rds - 6th String Front Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/14df88bb68e9e97669edc9befb6a754b-cc09b54806c6b9ae85321658168bad09.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=0784c65ec3c57d7db4cd1fa52e9bc98a961f4dbfe19e8f5273921e810ff7fa47',
      lastMessageAt: '2020-07-28T16:46:22.225426+00:00',
      exercises: [
        {
          id: '614',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2336',
      subject: 'Student Submission',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/137356338fc381fa671a1df92f28235a-cdd2c79abe6621509dca07c46a7b6972.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=6572284204e569ee94de8d404c5db5574b9732e31e1c4e01e27a7d376f525fb5',
      lastMessageAt: '2020-07-28T08:10:33.283189+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2338',
      subject: '1-2-3-4-5-6-7-8 Patterns - 4th String Front Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/be59e264e02f7884e3cf71b64597bb12-e952315c7a70d4b92fcbdeaae3651130.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=b5394e36ccb528b7ce2c12b3eefcae7e624bcdfc20c9ac76bc401b62c0375fe5',
      lastMessageAt: '2020-07-28T07:51:52.612252+00:00',
      exercises: [
        {
          id: '613',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2337',
      subject: '1-3-5 Patterns - 5th String Front Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/33c9bbda1abaa55393dc42b15cc4ab70-487508de1623ac087d164f96f40168c4.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=b6cfd6ac3b18fe907abc39e28b26316dab77e5164d3364d9c2aa6b71dcd216cd',
      lastMessageAt: '2020-07-28T07:50:04.578205+00:00',
      exercises: [
        {
          id: '605',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2335',
      subject: 'Student Submission',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/ab539492cf76b760b9ca4afa54f71844-33881dae72a00129450f5d4311fd75bb.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=f24cd3b334a2328e9e062931a1d14fbab87fafe8c2c2ffb9b4012d512c0bfead',
      lastMessageAt: '2020-07-26T08:36:38.983318+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2332',
      subject: 'Student Submission',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/100023b4-e61b-40ca-b9a5-623c9f865b77.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=562cae9984e832e474ffbed51446d379a721923d005e53c383bcca6d1c769bba',
      lastMessageAt: '2020-07-26T06:04:53.485996+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2331',
      subject: 'Student Submission',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/40bbf44405c83492a60358dfe377499f-f30010102c900f68c7572660a27e69d6.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=1ab5718821c4c327beec8c5eedead630c54fd7355f9d82708a3169c50d54feb0',
      lastMessageAt: '2020-07-25T07:56:57.360105+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2330',
      subject: 'Student Submission',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/64b539803268eb47e73d0aea8a74ae66-1f15e2875661d9f31b9c4c47e3d980a4.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=b85f191b913585ba0be93ff45d06af77a20f78369a20ce7793686f969f1deefb',
      lastMessageAt: '2020-07-25T06:47:36.898179+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2329',
      subject: 'Student Submission',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/c0279acb54fe7a6c398c8583517f67a6-101f45dc451e25afda3998b0e27c4574.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=2ade43f803101e7d6ae1c78723f7d2aea88eb724b42f458c9e3c41d354994d79',
      lastMessageAt: '2020-07-24T03:37:20.994072+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2327',
      subject: 'Student Submission',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/643003677a1058bd05d9ab3c2c41a7d7-b1c0bb81e2e98fcfa595ecc23cc1a66f.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=7e07ed7e793df827b0c7de666d20d192b6254cb42623bec1c10e67d70f113f64',
      lastMessageAt: '2020-07-24T03:36:03.471720+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2322',
      subject: 'Intervals 3rds - 6th String Front Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/f1e479c8-73f2-4733-a4e4-7c29f3ef7908.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=083413785b099cb2850e94cb16d041e7e36ddeef95c2d7123a319d34dc88bfc7',
      lastMessageAt: '2020-07-09T20:13:13.444425+00:00',
      exercises: [
        {
          id: '614',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2306',
      subject: '1-2-3-4-5-6-7-8 Patterns - 5th String Back Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/66c4ca95-e76f-487d-bb45-9dbc5d6c17c9.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=4a325f1f8d46f444c27d441524d15e73781b2870e48b153df2a8185eb069fe88',
      lastMessageAt: '2020-06-19T04:49:50.118727+00:00',
      exercises: [
        {
          id: '612',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2300',
      subject: '1-3-5 Patterns - 5th String Back Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/4c6f867d-ec2b-4ebb-8de1-45616afd1dc6.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=a5f6c3f7cdcddd2ed136f496e3ecc870bb6625bd04e34809f881d933b0cac597',
      lastMessageAt: '2020-06-04T02:11:12.675635+00:00',
      exercises: [
        {
          id: '607',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2296',
      subject: '1-2-3-4-5-6-7-8 Patterns - 6th String Back Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/178d2c23-a676-47ad-9c82-77a7011ec539.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=a8ead1826931b8748c6a90f28b542d710e0bb7b22984caff8cb429ea782fc109',
      lastMessageAt: '2020-06-04T02:05:28.377782+00:00',
      exercises: [
        {
          id: '610',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2295',
      subject: '1-3-5 Patterns - 6th String Back Form ',
      unreadCount: 0,
      s3ThumbnailUrl: null,
      lastMessageAt: '2020-06-04T01:51:51.920913+00:00',
      exercises: [
        {
          id: '606',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2293',
      subject: 'Major Scale - 6th String Back Form',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/fa60cbd3-b67e-4bd9-b314-3931d43f3b01.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=50134d5209b4c8be9c06cb5cc7a83dc9c048e1fce9a9465bbf371d516b6472a7',
      lastMessageAt: '2020-06-04T01:46:24.158764+00:00',
      exercises: [
        {
          id: '590',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2292',
      subject: '1-2-3-5 Patterns - 6th String Front Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/0e2e9ce3-df10-48d1-a036-039dd18992ee.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=304ed24c97d7cafcfe2325e855b06a46f45ccc3360cf20e398353d266867a98c',
      lastMessageAt: '2020-06-04T01:38:18.023702+00:00',
      exercises: [
        {
          id: '599',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2291',
      subject: 'Major Scale - 4th String Front Form',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/ca1d8f06-4da4-40d9-8e1a-de91e2b142f6.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=1f8da272ddac578ce6fe32e78be7a06be1daf3b7c27a398834f8730b5ea9334c',
      lastMessageAt: '2020-06-04T01:30:32.092488+00:00',
      exercises: [
        {
          id: '593',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2290',
      subject: 'Major Scale - Cycle of 4ths and 5ths',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/470d1211-0fd2-40b7-b812-1bde7e1e8c1f.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=596b16f8f9f264bdbfff248f98d5863c03bc2c91f883b83bccfe87d98dc7e4f3',
      lastMessageAt: '2020-06-04T01:29:20.903531+00:00',
      exercises: [
        {
          id: '738',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2289',
      subject: 'Major Scale - 6th String Front Form',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/0f1d8b29-e246-4d6e-b09f-bbb3b8150962.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=8a98aaacf1c7dd97176b969171dabc25d3a12193bfcb84d5973ffc02cb41b9ab',
      lastMessageAt: '2020-06-04T01:25:09.521049+00:00',
      exercises: [
        {
          id: '589',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2288',
      subject: '1-2-3-4-5-6-7-8 Patterns - 6th String Front Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/dca1fe1d-057f-42c8-9282-3e5eaf148109.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=3c73136b95ba79e86c2408247649c85b82519d2679e4db659f2a6c99d35cec55',
      lastMessageAt: '2020-06-04T01:24:29.628465+00:00',
      exercises: [
        {
          id: '609',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2285',
      subject: '1-2-3-4-5-6-7-8 Patterns - 5th String Front Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/3e9008cd-e95e-4809-ad03-4f435d39b898.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=c0ffc4ada43c3a0a5a4316edf091358c6fa848af632e071c41a270845eed3767',
      lastMessageAt: '2020-05-29T21:47:00.765655+00:00',
      exercises: [
        {
          id: '611',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2279',
      subject: '1-3-5 Patterns - 6th String Front Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/1c24696177692afc99f1983ffdcc817c-4375cd15f2c37129dc70e31942ddff00.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=bef74aaac9bfce9f70ac1c1cf6a0f7be34293f235d4431a4d9462ae44599655a',
      lastMessageAt: '2020-05-29T19:28:49.563439+00:00',
      exercises: [
        {
          id: '604',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2230',
      subject: 'Student Submission',
      unreadCount: 0,
      s3ThumbnailUrl: null,
      lastMessageAt: '2020-05-16T18:52:44.450137+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2227',
      subject: '1-2-3-5 Patterns - 5th String Back Form ',
      unreadCount: 0,
      s3ThumbnailUrl: null,
      lastMessageAt: '2020-05-15T23:24:49.007911+00:00',
      exercises: [
        {
          id: '601',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2209',
      subject: '1-2-3-5 Patterns - 6th String Front Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/1ed8285391a559f01ab5e8c6915647f0-8d8ca9c2cf4d688971004ff0166d5bbc.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=9c60958dadf2b8ff39794ce28386dd28b34563f8a65404115e5ae3127c33d10c',
      lastMessageAt: '2020-05-09T05:33:26.669152+00:00',
      exercises: [
        {
          id: '599',
          __typename: 'ExerciseType',
        },
      ],
      __typename: 'MessageThreadType',
    },
    {
      id: '2194',
      subject: '1-2-3-4 Patterns - 6th String Front Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/86c41491bc8509ea4554abe7f55f1050-7ff12feb12c740a7661af16fdb10031c.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=180537bd675fe611df37e4c54d4b3463a4e8fcb7690f03be0cb9dd59f30b8b1d',
      lastMessageAt: '2020-04-29T23:24:50.283186+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2203',
      subject: '1-2-3-4 Patterns - 5th String Back Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/56556010-700e-4c0e-a2aa-f374d737b10e.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=ec69d7b7980affb8c0f29c019be563b20805654b86e0193d3516e41004c1582b',
      lastMessageAt: '2020-04-29T23:15:32.345504+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2192',
      subject: 'Ad-Hoc Submission',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/34d6b885492846e71dcdc8ff5dee2add-6f450af22867f808387ab96b12da5a3d.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=885b521f0d651cc8c1411d72830ed1559a5f21c076843679e7df6ba4b83dcb54',
      lastMessageAt: '2020-04-29T20:30:43.548860+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2155',
      subject: 'Ad-Hoc Submission',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/24a0d41e-665f-4099-8522-d5503715cab5.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=e89d5bd93f66a38241b5a61e222194603b68d702542b6985228238a4d09fd70f',
      lastMessageAt: '2020-04-29T05:16:27.649799+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2147',
      subject: 'Major Scale - 5th String Back Form',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/7de5807ed25fc4cae1fedb6e2d56a099-e0522d400dc4a355778f27f96a3535f5.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194229Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=687ee441fa45961834961be6fcabad7bb65c8544f0fd1250417b4bcf2e4e9384',
      lastMessageAt: '2020-04-28T07:20:39.342798+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2144',
      subject: 'Major Scale - 5th String Back Form',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/06fff2ecce9e902f2254e00469bcde48-5c39243bd78efbe00aa41ee1c192d209.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194230Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=c9470087a9e529cee86dbd334bd3b0d587c9a9806dd869f162b254b91f97d8c2',
      lastMessageAt: '2020-04-28T07:15:37.499145+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2110',
      subject: 'Major Scale - 4th String Front Form',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/b1be06bf1c847d0c645466dcee19f787-b33cc42015c37fa45895b15c10419ff5.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194230Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=b3b707b1d8d4766ea1892a37c3ee9a5c59ce05c22d5ab6090a0df5359fcc4a17',
      lastMessageAt: '2020-04-28T05:43:17.338549+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2136',
      subject: '1-2-3-4 Patterns - 6th String Back Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/3ca86332456ed42c9144d166e395a9ad-2612e2c1dc3e7ef56e71d969b22be152.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194230Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=d7970075af6507965f3f15e7b604c2cdf73934c625b1569b3ae473b8019f74ba',
      lastMessageAt: '2020-04-25T22:44:11.018096+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2118',
      subject: '1-2-3-4 Patterns - 6th String Front Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/8b643a31dfe12886ab83ec87d728e021-b1eff3968d13f310a21af96ccfa7308d.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194230Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=7370b78edd4436161df7459e5a53b16d47f125c306c8ee9cd8474e22b863fe8e',
      lastMessageAt: '2020-04-25T21:43:10.742651+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2122',
      subject: '1-2-3-4 Patterns - 6th String Front Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/ae275f16838583f0cf01dcea0591771d-73399d9575eacbbc475a9029a7062f93.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194230Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=05fc6eac71b3e2364ff7f09d6b7057fa4cbd0d96bcf0c1a139a70d3366054625',
      lastMessageAt: '2020-04-25T20:08:47.068933+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2117',
      subject: '1-2-3-4 Patterns - 6th String Front Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/5649fab5779b2509fd3d916ca87586c0-198b10ffe8c4e42d3c9b0d08337dd6ea.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194230Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=64000b03af19e83e4d9690e6044ab5d86c398067d295bfb441d15dac46698f4a',
      lastMessageAt: '2020-04-25T19:54:18.220736+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2113',
      subject: '1-2-3-4 Patterns - 6th String Front Form ',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/3b166e966548279b8e8bbfc0cb6027d4-0ffd5c77e5a9926b99a9517adacd7add.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194230Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=78ab9a39099a928f82429362baf3c5e4249737197b16cb0ea0cec33159f09599',
      lastMessageAt: '2020-04-25T03:05:15.286899+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2107',
      subject: 'Major Scale - 5th String Front Form',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/82f6f0a6-ff91-4aad-a3d9-698441e9805a.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194230Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=0522c2960d710a4166e50da5a60108b9e9fc9da904a9e48a313156d09df5e20c',
      lastMessageAt: '2020-04-25T02:58:03.617922+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2108',
      subject: 'Major Scale - 5th String Back Form',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/f6a90083a68f5e0609ad8c1107c74eb8-875033ecd0ea209d0f09f321dc6059a7.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194230Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=073f434a9f09358223e48031403c035efec252f47c0a7595af1f6319f25ded71',
      lastMessageAt: '2020-04-25T02:56:03.395999+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2105',
      subject: 'Major Scale - 5th String Front Form',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/9201270a-5de7-4e0a-a8a4-84091d5957da.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194230Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=8a2f59baf13386193b20cf8f5d0223d8941b675c2564398c894959bc2ff6539b',
      lastMessageAt: '2020-04-25T02:51:27.411994+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2101',
      subject: 'Major Scale - 6th String Back Form',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/19589a6a-7232-4329-a3f0-c99ed8b9b0eb.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194230Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=7c18e816646c607fd396e437d6387516b62e151e94df8fae39af9740ebf35269',
      lastMessageAt: '2020-04-25T02:51:11.317411+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
    {
      id: '2103',
      subject: 'Major Scale - 6th String Front Form',
      unreadCount: 0,
      s3ThumbnailUrl:
        'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/8c2ab985-1aa4-498e-858f-80d9e3eeb10a.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T194230Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=95de4cb7c3eebf8250c10ae0fecb261566f4284edc84099afa72507a0d14fdb1',
      lastMessageAt: '2020-04-25T02:48:26.154642+00:00',
      exercises: [],
      __typename: 'MessageThreadType',
    },
  ],
};

export const carouselData = [
  {
    id: 2,
    title: 'Student Submission',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/db0a43a1-d9ea-44c2-8f54-0a1fc24729c3.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193958Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=370151fba370df65b5c45e40abb48b6b86b9fcc3242fd79ca5b132bf7defa278',
    subtitle: '7 days ago',
    badge: false,
  },
  {
    id: 3,
    title: 'Student Submission',
    imageUrl:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/c0cb13c4321c0e4cfaca2104e8a3bc2b-941fb867fa9b02042fa9a138648f4958.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193958Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=8a7afe2c623f1b5c21a5e8e97364b0ebb000790e4c2030127613d9cae1a4175a',
    subtitle: '10 days ago',
    badge: false,
  },
  {
    id: 4,
    title: 'Student Submission',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/a23c732d-3cd7-4cc8-aecf-56e98116d62c.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193958Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=bac7471f036b7abe327d2fbcc796c7076019ebb48136581de8437ac7d559a8a3',
    subtitle: '28 days ago',
    badge: false,
  },
  {
    id: 5,
    title: '1-2-3-4-5-6-7-8 Patterns - 6th String Back Form ',
    imageUrl:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/774e7e85cb7bde59530203dbf59ca2c6-a4709153ddc35cadc7c8808f2f1c399a.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193958Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=ad0c90b5b29c2576e93eb29b313157d3943e2ef3901bfb910db5558b36c80f14',
    subtitle: '29 days ago',
    badge: false,
  },
  {
    id: 6,
    title: 'Intervals 3rds - 6th String Back Form ',
    imageUrl:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/c36b3ecb6a68e58c3552deb6c2a02904-4a025b6e87453657b64bf57e3465f825.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193958Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=76a29f8997ca425e3e9fe63dd867f0286008651ddaf0f1e6b0a89b9e3b67b54e',
    subtitle: '29 days ago',
    badge: false,
  },
  {
    id: 7,
    title: 'Intervals 3rds - 4th String Front Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/e4c330ee-08bb-442f-ab51-ea96d560b439.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193958Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=17cbb582b5085399a96a06fbec33e963dd3c1404fcceb33da2dd8e501ec64a51',
    subtitle: 'about 1 month ago',
    badge: false,
  },
  {
    id: 8,
    title: '1-2-3-4-5-6-7-8 Patterns - 5th String Front Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/f2f79b18-601e-41c5-805b-b74c3ff34bed.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193958Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=c48070160c1cd5aa6dbc89c982d68b3a4f10164d4eb9b11ab8d4dd0003afdce3',
    subtitle: 'about 1 month ago',
    badge: false,
  },
  {
    id: 9,
    title: '1-2-3-4-5-6-7-8 Patterns - 5th String Back Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/c5ed3b3f-95e9-4a43-bbce-85a09e0b67f2.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193958Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=27e3b83a9afaea99c883a7febe43368bb7b0903a28c45bde719802fc8e695190',
    subtitle: 'about 1 month ago',
    badge: false,
  },
  {
    id: 10,
    title: '1-2-3-4-5-6-7-8 Patterns - 6th String Front Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/ac31f62d-fe6e-4478-a890-a1bc3d41c82a.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193958Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=0783feb7245bfab7695b30ce2f147207c9b7bd971c0aadf818257a2d5ccdafb3',
    subtitle: 'about 1 month ago',
    badge: false,
  },
  {
    id: 11,
    title: '1-3-5 Patterns - 5th String Front Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/0f794459-d9c6-4565-a267-dbb7c4d32453.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193958Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=37e81435b4c6350295291d1e46b4af37d89a324ca194ed0f88824515a4edbaab',
    subtitle: 'about 1 month ago',
    badge: false,
  },
  {
    id: 12,
    title: 'Intervals 4ths - 4th String Front Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/fa7958a9-31f8-49d1-a882-204b50fc8dc7.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193958Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=e2ba861ea62ccf5501ceb70bb4ace0dbc416a6d126bba145e4bf2ec26a66c63e',
    subtitle: 'about 1 month ago',
    badge: false,
  },
  {
    id: 13,
    title: 'Intervals 4ths - 5th String Front Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/7da128fe-1731-488c-94d2-e49f9e3243e7.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193958Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=bc65f518dc14b7ce5ae820e12fdc4510d8731ccb21760295ab7d43b287c66f8f',
    subtitle: 'about 1 month ago',
    badge: false,
  },
  {
    id: 14,
    title: 'Intervals 4ths - 5th String Back Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/dbd29223-dcc3-40a0-8a3f-96b32f74beb9.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193958Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=dabfaa20047e6a39c7971f4e903e41b1ae4d9ec2f1d4472bd3eaac2115da80fa',
    subtitle: 'about 1 month ago',
    badge: false,
  },
  {
    id: 15,
    title: 'Intervals 4ths - 6th String Back Form',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/1c8217df-49a4-4c38-821d-aacbddb5b190.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193958Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=4cd39fba82425e3b3a6b34e695ecaaeb9a81be133e5e5cce215b6529c82105be',
    subtitle: 'about 1 month ago',
    badge: false,
  },
  {
    id: 16,
    title: 'Intervals 4ths - 6th String Front Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/0c3d9e25-aaad-4568-b419-ea7da0283248.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193958Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=0ff34f437b0797843dc0924c13311045ae3acf80a8699a51cb12cfcfcd297cb6',
    subtitle: 'about 1 month ago',
    badge: false,
  },
  {
    id: 17,
    title: 'Intervals 3rds - 4th String Front Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/b4056aa4-c8a7-404d-8da9-43fd356ebe36.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193958Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=e724a29a86fc89f05007c3c653b8521647e213480670aba54655d0a5a7a8a310',
    subtitle: 'about 1 month ago',
    badge: false,
  },
  {
    id: 18,
    title: 'Intervals 3rds - 5th String Back Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/59708aab-667a-48fb-8f4c-7921a5b90028.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193958Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=c2b1f7720f6e45e94a076816244f8be1c82f934db188e861ec4c1dff68b1c3e1',
    subtitle: 'about 1 month ago',
    badge: false,
  },
  {
    id: 19,
    title: 'Intervals 3rds - 5th String Front Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/86f57578-01d4-4d1a-b688-3c0d876f8c20.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193958Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=a8edaf86974094d77232c162d02a3f688f5ddcd32f58d97259d7161cdf9b3173',
    subtitle: 'about 1 month ago',
    badge: false,
  },
  {
    id: 20,
    title: '1-2-3-4-5-6-7-8 Patterns - 5th String Front Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/6ce4a38e-3949-4e3c-9cca-ccad1cdcb21c.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193958Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=bf24ab37575e64ff8be40ba387f89b72db5369240f8476ae3b7d489f7c220157',
    subtitle: 'about 1 month ago',
    badge: false,
  },
  {
    id: 21,
    title: 'Intervals 3rds - 6th String Back Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/9ac8c971-28b5-4232-a076-7cf202311db2.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=5c67ba17276f73455beb036fedbd96917900e59165f74d38272946492ded53bc',
    subtitle: 'about 1 month ago',
    badge: false,
  },
  {
    id: 22,
    title: '1-2-3-4 Patterns - 6th String Front Form',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/0f65e06e-bda9-4222-9aa7-6a2b3610a181.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=723f72334e605ee4b826ab9d859e9d2a9d759012a33fb2ac86e2d15459d365b6',
    subtitle: 'about 1 month ago',
    badge: false,
  },
  {
    id: 23,
    title: '1-2-3-5 Patterns - 5th String Front Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/54bc5417-e2c4-4e45-b428-bcfdc5f7cf09.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=f66b3cc9eea125246c6a83b10525209f8853b41e502f29a4aeb7c0a36dd83f03',
    subtitle: 'about 1 month ago',
    badge: false,
  },
  {
    id: 24,
    title: '1-2-3-4-5-6-7-8 Patterns - 5th String Back Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/55ea3474-5d43-44e9-896f-3be50855e09c.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=7150a529d38cd7f4c497ac10550607bc6e89bff131f2547d9116ba1452bea590',
    subtitle: 'about 1 month ago',
    badge: false,
  },
  {
    id: 25,
    title: '1-3-5 Patterns - 5th String Back Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/c318a347-6795-44fe-9ab8-9aa0cede902f.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=99f87c731b7c786144cb2a53a15eb843defb26c6378978af4d3daa200296ddf1',
    subtitle: 'about 1 month ago',
    badge: false,
  },
  {
    id: 26,
    title: '1-2-3-4 Patterns - 5th String Back Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/f4e94b77-3f95-4445-bea1-a0c5222a6258.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=68357e759110e8db6c373fe4deb7ba416e21ab977fa9742ab9e131beea4a5168',
    subtitle: 'about 1 month ago',
    badge: false,
  },
  {
    id: 27,
    title: '1-2-3-4 Patterns - 5th String Back Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/33e7a3e5-06a0-45f6-bd53-5f8a2e78f16a.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=b06be7a3d4e74392f628e5aa47f94356859a51f6e6c6af841048f5ec08bf77cf',
    subtitle: 'about 1 month ago',
    badge: false,
  },
  {
    id: 28,
    title: 'Intervals 3rds - 6th String Front Form ',
    imageUrl:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/14df88bb68e9e97669edc9befb6a754b-cc09b54806c6b9ae85321658168bad09.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=673febe265a52b1382e5ffe10d272f07870b49c9362efb5d8d01345bde1ebc25',
    subtitle: 'about 2 months ago',
    badge: false,
  },
  {
    id: 29,
    title: 'Student Submission',
    imageUrl:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/137356338fc381fa671a1df92f28235a-cdd2c79abe6621509dca07c46a7b6972.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=aa56a3d9fb76e0d94a91f71d456cff6a0adac81b793bc9d9754b7a035f33e1d9',
    subtitle: 'about 2 months ago',
    badge: false,
  },
  {
    id: 30,
    title: '1-2-3-4-5-6-7-8 Patterns - 4th String Front Form ',
    imageUrl:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/be59e264e02f7884e3cf71b64597bb12-e952315c7a70d4b92fcbdeaae3651130.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=3b52e15d4f2610961a7dc0f9b94c34d9f4e91323b839d9fb03b9a0dcad3a36b1',
    subtitle: 'about 2 months ago',
    badge: false,
  },
  {
    id: 31,
    title: '1-3-5 Patterns - 5th String Front Form ',
    imageUrl:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/33c9bbda1abaa55393dc42b15cc4ab70-487508de1623ac087d164f96f40168c4.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=4bab00da057de0ed318d669d95ff1ea7d68c6280feeec095f1d98b3836e04c6e',
    subtitle: 'about 2 months ago',
    badge: false,
  },
  {
    id: 32,
    title: 'Student Submission',
    imageUrl:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/ab539492cf76b760b9ca4afa54f71844-33881dae72a00129450f5d4311fd75bb.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=cdccb55c9b1db9f7818adb727976b1a3fa02f3098f8979690f50a33659782632',
    subtitle: 'about 2 months ago',
    badge: false,
  },
  {
    id: 33,
    title: 'Student Submission',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/100023b4-e61b-40ca-b9a5-623c9f865b77.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=fbdc913ee82d8afed61eb6256bf8fde61f0dc5aa89e3606aa3a645551b227530',
    subtitle: 'about 2 months ago',
    badge: false,
  },
  {
    id: 34,
    title: 'Student Submission',
    imageUrl:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/40bbf44405c83492a60358dfe377499f-f30010102c900f68c7572660a27e69d6.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=fdb660cca1d790bdca371f84a616e98d77c7113af26daae77fe4d59543db6435',
    subtitle: 'about 2 months ago',
    badge: false,
  },
  {
    id: 35,
    title: 'Student Submission',
    imageUrl:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/64b539803268eb47e73d0aea8a74ae66-1f15e2875661d9f31b9c4c47e3d980a4.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=3eb49730cde66d633443fb316f577ed934ccded3cd6df0306c68b1424b7aa2ff',
    subtitle: 'about 2 months ago',
    badge: false,
  },
  {
    id: 36,
    title: 'Student Submission',
    imageUrl:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/c0279acb54fe7a6c398c8583517f67a6-101f45dc451e25afda3998b0e27c4574.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=9f9a9ba1a417bd0ab9f4cb68b47292bd05215cc765b9785cc0fd1d5a190e2036',
    subtitle: 'about 2 months ago',
    badge: false,
  },
  {
    id: 37,
    title: 'Student Submission',
    imageUrl:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/643003677a1058bd05d9ab3c2c41a7d7-b1c0bb81e2e98fcfa595ecc23cc1a66f.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=61cc84faa7ca48dc784187ffd8e6645d830cc6a7af9b2b3ae6e252d87a52cb20',
    subtitle: 'about 2 months ago',
    badge: false,
  },
  {
    id: 38,
    title: 'Intervals 3rds - 6th String Front Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/f1e479c8-73f2-4733-a4e4-7c29f3ef7908.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=525c739491b99b295160a5e476add4dee772adaa54aa274a86d58ddfa690350c',
    subtitle: '2 months ago',
    badge: false,
  },
  {
    id: 39,
    title: '1-2-3-4-5-6-7-8 Patterns - 5th String Back Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/66c4ca95-e76f-487d-bb45-9dbc5d6c17c9.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=b2202992b7865d43e42ae606f8ae05eab7bcdd10f472d1377ac0499eb3780188',
    subtitle: '3 months ago',
    badge: false,
  },
  {
    id: 40,
    title: '1-3-5 Patterns - 5th String Back Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/4c6f867d-ec2b-4ebb-8de1-45616afd1dc6.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=968b566e85b0ced872b7e1b103ff2482541c3361974d74217e8d968625c0948a',
    subtitle: '3 months ago',
    badge: false,
  },
  {
    id: 41,
    title: '1-2-3-4-5-6-7-8 Patterns - 6th String Back Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/178d2c23-a676-47ad-9c82-77a7011ec539.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=24c07074f261fd3da17e0c63ebf5a1b5a254e9a2f66139d4c78b7a574a5dac6e',
    subtitle: '3 months ago',
    badge: false,
  },
  {
    id: 42,
    title: '1-3-5 Patterns - 6th String Back Form ',
    imageUrl: '',
    subtitle: '3 months ago',
    badge: false,
  },
  {
    id: 43,
    title: 'Major Scale - 6th String Back Form',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/fa60cbd3-b67e-4bd9-b314-3931d43f3b01.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=6489598cad2dfb6cdc8a438a4f58cdceec389442495589aec5f3f17d07db1bda',
    subtitle: '3 months ago',
    badge: false,
  },
  {
    id: 44,
    title: '1-2-3-5 Patterns - 6th String Front Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/0e2e9ce3-df10-48d1-a036-039dd18992ee.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=524371e54914204f70741b0695a42e24dd211361bb205f76769de788e713e0c6',
    subtitle: '3 months ago',
    badge: false,
  },
  {
    id: 45,
    title: 'Major Scale - 4th String Front Form',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/ca1d8f06-4da4-40d9-8e1a-de91e2b142f6.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=4e5806cbfa10407c0b3b8a9211436c0a29a73c5340310e8de9941d6126a1d745',
    subtitle: '3 months ago',
    badge: false,
  },
  {
    id: 46,
    title: 'Major Scale - Cycle of 4ths and 5ths',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/470d1211-0fd2-40b7-b812-1bde7e1e8c1f.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=ecd2ea85ae66e6658ea2b165fb3e55329a172e2ea0e57891d790a7ce450b940b',
    subtitle: '3 months ago',
    badge: false,
  },
  {
    id: 47,
    title: 'Major Scale - 6th String Front Form',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/0f1d8b29-e246-4d6e-b09f-bbb3b8150962.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=64823fefd98884aa5b40183fdb65b45ee4df1be7deada01358f7d329ed5c3828',
    subtitle: '3 months ago',
    badge: false,
  },
  {
    id: 48,
    title: '1-2-3-4-5-6-7-8 Patterns - 6th String Front Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/dca1fe1d-057f-42c8-9282-3e5eaf148109.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=449678ff86bd4df0088acdd9a7c2bc23e18f8bd68d30b33e6b5905eb22e89102',
    subtitle: '3 months ago',
    badge: false,
  },
  {
    id: 49,
    title: '1-2-3-4-5-6-7-8 Patterns - 5th String Front Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/3e9008cd-e95e-4809-ad03-4f435d39b898.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=8227aa5213e578aa5b76222b56ad694f84182f4b69b113cdb7993f41f5f3c26c',
    subtitle: '3 months ago',
    badge: false,
  },
  {
    id: 50,
    title: '1-3-5 Patterns - 6th String Front Form ',
    imageUrl:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/1c24696177692afc99f1983ffdcc817c-4375cd15f2c37129dc70e31942ddff00.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=ea42a6fc94c6d79d0a3409186de5f687f6ce8a520c255925697f1851bacaa2e0',
    subtitle: '4 months ago',
    badge: false,
  },
  {
    id: 51,
    title: 'Student Submission',
    imageUrl: '',
    subtitle: '4 months ago',
    badge: false,
  },
  {
    id: 52,
    title: '1-2-3-5 Patterns - 5th String Back Form ',
    imageUrl: '',
    subtitle: '4 months ago',
    badge: false,
  },
  {
    id: 53,
    title: '1-2-3-5 Patterns - 6th String Front Form ',
    imageUrl:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/1ed8285391a559f01ab5e8c6915647f0-8d8ca9c2cf4d688971004ff0166d5bbc.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=5e9d7f69faa81d644773428d0c401fed027a40f9388e864c916b5885dfa85938',
    subtitle: '4 months ago',
    badge: false,
  },
  {
    id: 54,
    title: '1-2-3-4 Patterns - 6th String Front Form ',
    imageUrl:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/86c41491bc8509ea4554abe7f55f1050-7ff12feb12c740a7661af16fdb10031c.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=053454f1db8ee33f848f5a5f713b777d5d704255df2718e7a03b4d3ff4584c72',
    subtitle: '4 months ago',
    badge: false,
  },
  {
    id: 55,
    title: '1-2-3-4 Patterns - 5th String Back Form ',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/56556010-700e-4c0e-a2aa-f374d737b10e.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=3cbbf836ba0fce98922d662f3ebc234398518624251a4499f8503c67f8c213a9',
    subtitle: '4 months ago',
    badge: false,
  },
  {
    id: 56,
    title: 'Ad-Hoc Submission',
    imageUrl:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/34d6b885492846e71dcdc8ff5dee2add-6f450af22867f808387ab96b12da5a3d.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=0aa9e6c06739ad56c6773bdc8ef56ac8e26ec5676a314c264920480eb03ee9df',
    subtitle: '4 months ago',
    badge: false,
  },
  {
    id: 57,
    title: 'Ad-Hoc Submission',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/24a0d41e-665f-4099-8522-d5503715cab5.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=60c6bfab692820b7880414955414292e8ce4d1c663dea820f0ecacf207a1f179',
    subtitle: '5 months ago',
    badge: false,
  },
  {
    id: 58,
    title: 'Major Scale - 5th String Back Form',
    imageUrl:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/7de5807ed25fc4cae1fedb6e2d56a099-e0522d400dc4a355778f27f96a3535f5.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=3cbf59b980449bf6795463de32fb90e4828678eca32b7d53890570b07b293611',
    subtitle: '5 months ago',
    badge: false,
  },
  {
    id: 59,
    title: 'Major Scale - 5th String Back Form',
    imageUrl:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/06fff2ecce9e902f2254e00469bcde48-5c39243bd78efbe00aa41ee1c192d209.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=66b2371b45428258c40b2ec90caa8fac15060009262f3a75a34d392ef65f94d9',
    subtitle: '5 months ago',
    badge: false,
  },
  {
    id: 60,
    title: 'Major Scale - 4th String Front Form',
    imageUrl:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/b1be06bf1c847d0c645466dcee19f787-b33cc42015c37fa45895b15c10419ff5.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=0ad99ac8c6c4651e9cc209e14640622aa3fb9417f65874c069b76e63fa1d2a04',
    subtitle: '5 months ago',
    badge: false,
  },
  {
    id: 61,
    title: '1-2-3-4 Patterns - 6th String Back Form ',
    imageUrl:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/3ca86332456ed42c9144d166e395a9ad-2612e2c1dc3e7ef56e71d969b22be152.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=9aae322e85facd297de99447a28f52e594f21ab90698646b0c4515ed9a4e6b41',
    subtitle: '5 months ago',
    badge: false,
  },
  {
    id: 62,
    title: '1-2-3-4 Patterns - 6th String Front Form ',
    imageUrl:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/8b643a31dfe12886ab83ec87d728e021-b1eff3968d13f310a21af96ccfa7308d.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=a127fe0c5418b1e8bb9bdcb447f4c1b82317e5c00d6d8b1074aa1a7f8d526909',
    subtitle: '5 months ago',
    badge: false,
  },
  {
    id: 63,
    title: '1-2-3-4 Patterns - 6th String Front Form ',
    imageUrl:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/ae275f16838583f0cf01dcea0591771d-73399d9575eacbbc475a9029a7062f93.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=a5ad362fb0f99566f9b8ea8736c403289adabadbcfe1ab1c4fc02cd6a7934e7d',
    subtitle: '5 months ago',
    badge: false,
  },
  {
    id: 64,
    title: '1-2-3-4 Patterns - 6th String Front Form ',
    imageUrl:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/5649fab5779b2509fd3d916ca87586c0-198b10ffe8c4e42d3c9b0d08337dd6ea.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=e213514aecb7c5cd7a8bd0405dbebf5a4035cdcdbc41679c75f99d41a413fa98',
    subtitle: '5 months ago',
    badge: false,
  },
  {
    id: 65,
    title: '1-2-3-4 Patterns - 6th String Front Form ',
    imageUrl:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/3b166e966548279b8e8bbfc0cb6027d4-0ffd5c77e5a9926b99a9517adacd7add.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=59d3e6650621ed6eaf016de4b7d8912ea799f89f5222f22f41e6e50cb0615e04',
    subtitle: '5 months ago',
    badge: false,
  },
  {
    id: 66,
    title: 'Major Scale - 5th String Front Form',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/82f6f0a6-ff91-4aad-a3d9-698441e9805a.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=767dd9d24db4d2288a40e76059aaad869321e29ad484c4edb2dc966c2f667abb',
    subtitle: '5 months ago',
    badge: false,
  },
  {
    id: 67,
    title: 'Major Scale - 5th String Back Form',
    imageUrl:
      'https://guitar-mastery-videos2.s3-accelerate.amazonaws.com/f6a90083a68f5e0609ad8c1107c74eb8-875033ecd0ea209d0f09f321dc6059a7.mp4.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=06e3fbef997c269ef0f16ab564bd113886a450eae2c84059fa1a0f4993b73df6',
    subtitle: '5 months ago',
    badge: false,
  },
  {
    id: 68,
    title: 'Major Scale - 5th String Front Form',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/9201270a-5de7-4e0a-a8a4-84091d5957da.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=ee09bfa82e6cc726b6de86df3d4ef6ed98ad4bb8a9523142ed8426bd2b8afbba',
    subtitle: '5 months ago',
    badge: false,
  },
  {
    id: 69,
    title: 'Major Scale - 6th String Back Form',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/19589a6a-7232-4329-a3f0-c99ed8b9b0eb.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=94ec6041807aaeb6de4846d19e10855b48eca23f54b19983aa63db15cb4bdb2e',
    subtitle: '5 months ago',
    badge: false,
  },
  {
    id: 70,
    title: 'Major Scale - 6th String Front Form',
    imageUrl:
      'https://guitar-mastery-submissions.s3-accelerate.amazonaws.com/8c2ab985-1aa4-498e-858f-80d9e3eeb10a.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3S4YHZTV7TLMWDM4%2F20200911%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200911T193959Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=f6d263e2ef01afdee85e48b8874ec61088bd21e62bf16d0e4615a3431f5494b7',
    subtitle: '5 months ago',
    badge: false,
  },
];
