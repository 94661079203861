import React, { FC, useContext } from 'react';
import { View, Image, StyleSheet } from 'react-native';

import { LayoutProviderContext, LayoutType } from 'sonora-design-system';
import frameLeftSrc from 'sonora-design-system/assets/images/frame/FrameLeft.png';
import { RootNavScreen, Screens } from '../screens/screens';
import { CourseMapScreens } from '../screens/CourseMap/types';

export interface L1FrameLeftProps {}

const frameLeftWidth = 12;

export const L1FrameLeft: FC<L1FrameLeftProps> = (props) => {
  const { screenHeight } = useContext(LayoutProviderContext);

  return (
    <View style={styles.frameLeftContainer}>
      <Image
        source={frameLeftSrc}
        style={[styles.frameLeft, { height: screenHeight - 12 }]}
      />
      {props.children}
    </View>
  );
};

const styles = StyleSheet.create({
  frameLeftContainer: { position: 'absolute', left: 0, zIndex: 1 },
  frameLeft: {
    width: frameLeftWidth,
  },
});

export const L1FrameLeftPortraitContainer: React.FC<{}> = (props) => {
  const { activeLayout } = useContext(LayoutProviderContext);

  return (
    <View
      style={{
        flex: 1,
        marginLeft: activeLayout === LayoutType.Portrait ? frameLeftWidth : 0,
      }}
    >
      {props.children}
    </View>
  );
};

export const L1FrameLeftLandscapeContainer: React.FC<{}> = (props) => {
  const { activeLayout } = useContext(LayoutProviderContext);

  return (
    <View
      style={{
        flex: 1,
        marginLeft: activeLayout === LayoutType.Landscape ? frameLeftWidth : 0,
      }}
    >
      {props.children}
    </View>
  );
};

export function L1FrameLeftHOC<T extends Screens>(
  component: RootNavScreen<T>
): RootNavScreen<T> {
  return (props) => (
    <L1FrameLeftPortraitContainer>
      {component(props)}
    </L1FrameLeftPortraitContainer>
  );
}

export function L1FrameLeftCourseMapHOC<T extends CourseMapScreens>(
  component: RootNavScreen<T>
): RootNavScreen<T> {
  return (props) => (
    <L1FrameLeftPortraitContainer>
      {component(props)}
    </L1FrameLeftPortraitContainer>
  );
}
