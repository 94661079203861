import React, { useState, FC } from 'react';
import { View } from 'react-native';
import { Loading } from 'sonora-design-system';

import { ziggeoApiKey } from '../../util/environment';

export interface ZiggeoVideoRecorderProps {
  handleUploaded: () => void;
  resetUpload: () => void;
  messageAttachmentId: number;
  height: number;
  width: number;
}
export const ZiggeoVideoRecorder: FC<ZiggeoVideoRecorderProps> = ({
  handleUploaded,
  resetUpload,
  messageAttachmentId,
  height,
  width,
}) => {
  // @ts-ignore For NOW until we type ziggeo module
  import('react-ziggeo-mobile-friendly').then(({ ZiggeoRecorder }) => {
    setZiggeoRecorder(() => ZiggeoRecorder);
  });

  const [ZiggeoRecorder, setZiggeoRecorder] = useState<
    typeof React.Component | null
  >(null);
  if (!ZiggeoRecorder) return <Loading />;
  return (
    <View
      style={{
        height,
        width,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ZiggeoRecorder
        apiKey={ziggeoApiKey()}
        height={height}
        width={width}
        early-rerecord={true}
        custom-data={{ messageAttachmentId }}
        skipinitialonrerecord={true}
        skipinitial={false}
        timelimit={240} // 4 minutes limit
        localplayback={true}
        custom-covershots={false}
        onVerified={handleUploaded}
        onError={resetUpload}
        onRerecord={resetUpload}
        webrtc_streaming_if_necessary={true}
      />
    </View>
  );
};
