import React, { FC, useRef, useEffect, useContext } from 'react';
import { View, StyleSheet, Animated } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useNavigation, useRoute } from '@react-navigation/native';
import { colors, LayoutProviderContext } from 'sonora-design-system';

import { L2Screens } from '../screens/L2Screens';
import { bottomTabsHeight } from './L2PortraitTabs';
import { L2NavigationContext } from './L2NavigationProvider';

export interface L2PortraitDrawerProps {}

const L2PortraitDrawer: FC<L2PortraitDrawerProps> = (props) => {
  const insets = useSafeAreaInsets();
  const { activeIndex } = useContext(L2NavigationContext);
  const { screenHeight } = useContext(LayoutProviderContext);

  const navigation = useNavigation();
  const route = useRoute();

  let height = activeIndex == undefined ? 0 : 100;
  let heightAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(heightAnim, {
      toValue: height,
      duration: 400,
      useNativeDriver: false,
    }).start();
  }, [activeIndex]);

  let screenComponents = L2Screens.map((screen) =>
    // @ts-ignore FOR NOW AS AN EXAMPLE DO NOT LET THIS 💩 GET INTO PRODUCTION
    screen.component({ navigation, route })
  );

  let content =
    activeIndex == undefined ? undefined : screenComponents[activeIndex];

  return (
    <Animated.View
      style={[
        styles.drawer,
        {
          bottom: insets.bottom + bottomTabsHeight,
          height: heightAnim.interpolate({
            inputRange: [0, 100],
            outputRange: [
              0,
              0.8 *
                (screenHeight - bottomTabsHeight - insets.bottom - insets.top),
            ],
          }),
        },
      ]}
    >
      <View style={styles.drawerContent}>{content}</View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  drawer: {
    backgroundColor: colors.agate,
    position: 'absolute',
    width: '100%',
    flex: 1,
  },
  drawerContent: {
    flex: 1,
    paddingVertical: 24,
    paddingHorizontal: 36,
  },
});

export default L2PortraitDrawer;
