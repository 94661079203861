import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { colors } from '../../constants/theme';

const draw: { [key: string]: string } = {
  alert:
    'M3 19.5455L12 4L21 19.5455H3ZM18.1607 17.9091L11.9998 7.26454L5.83887 17.9091H18.1607ZM11.1818 15.4545V17.0909H12.8182V15.4545H11.1818ZM11.1818 10.5455H12.8182V13.8182H11.1818V10.5455Z',
  account:
    'M4.75 12C4.75 7.99821 7.99821 4.75 12 4.75C16.0018 4.75 19.25 7.99821 19.25 12C19.25 16.0018 16.0018 19.25 12 19.25C7.99821 19.25 4.75 16.0018 4.75 12Z M6.66602 17.3333C7.13119 15.985 9.33268 14.6667 11.9993 14.6667C14.666 14.6667 16.8783 15.985 17.3327 17.3333 M9.19531 10.2222C9.19531 8.66563 10.4443 7.41666 12.0009 7.41666C13.5574 7.41666 14.8064 8.66563 14.8064 10.2222C14.8064 11.7788 13.5574 13.0278 12.0009 13.0278C10.4443 13.0278 9.19531 11.7788 9.19531 10.2222Z',
  addVideo:
    'M19 3v2.5h2.5v1.667H19v2.491s-1.658.009-1.667 0V7.167h-2.5s.009-1.659 0-1.667h2.5V3H19zm-1.667 15.833H5.667V7.167h7.5V5.5h-7.5C4.75 5.5 4 6.25 4 7.167v11.666c0 .917.75 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667v-7.5h-1.667v7.5zM10 10.5v5.667l3.925-2.95L10 10.5z',
  camera:
    'M4 6h12c.55 0 1 .45 1 1v3.5l4-4v11l-4-4V17c0 .55-.45 1-1 1H4c-.55 0-1-.45-1-1V7c0-.55.45-1 1-1zm11 10V8H5v8h10z',
  caret: 'M9 18l6-6-6-6',
  caretUp: 'M6 15L12 9L18 15',
  caretDown: 'M18 9L12 15L6 9',
  check: 'M6 12.267L10 16l8-8',
  checkCircle:
    'M0 12C0 5.376 5.376 0 12 0C18.624 0 24 5.376 24 12C24 18.624 18.624 24 12 24C5.376 24 0 18.624 0 12ZM6 11.9213L10.2857 16L18 8.65835L16.7914 7.5L10.2857 13.6915L7.20857 10.7711L6 11.9213Z',
  checkDouble:
    'M4.66016 12.4756L7.16016 15.4756L11.6602 8.97559 M12.6602 12.4756L15.1602 15.4756L19.6602 8.97559',
  checkboxFilled:
    'M17.4444 5H6.55556C5.7 5 5 5.7 5 6.55556V17.4444C5 18.3 5.7 19 6.55556 19H17.4444C18.3 19 19 18.3 19 17.4444V6.55556C19 5.7 18.3 5 17.4444 5ZM17.4444 17.4444H6.55556V6.55556H17.4444V17.4444ZM15.5624 8.56228L16.6591 9.66673L10.4368 15.889L7.32574 12.7856L8.43018 11.6889L10.4368 13.6878L15.5624 8.56228Z',
  checkboxEmpty:
    'M6.55556 5H17.4444C18.3 5 19 5.7 19 6.55556V17.4444C19 18.3 18.3 19 17.4444 19H6.55556C5.7 19 5 18.3 5 17.4444V6.55556C5 5.7 5.7 5 6.55556 5ZM6.55556 17.4444H17.4444V6.55556H6.55556V17.4444Z',
  drone:
    'M15.407 4.095l1.187 1.187-7.124 7.124 2.375 2.375 7.124-7.124 1.187 1.187-7.124 7.125a1.68 1.68 0 01-2.375 0l-2.374-2.375a1.68 1.68 0 010-2.375l7.124-7.124zM6.342 16.721l2.534-2.533 1.187 1.187-2.533 2.533a1.679 1.679 0 11-1.188-1.187z',
  discord: 'M19.4971 0H3.13796C1.75842 0 0.635254 1.104 0.635254 2.472V18.696C0.635254 20.064 1.75842 21.168 3.13796 21.168H16.9822L16.3352 18.948L17.8978 20.376L19.375 21.72L21.9998 24V2.472C21.9998 1.104 20.8767 0 19.4971 0ZM14.7847 15.672C14.7847 15.672 14.3452 15.156 13.979 14.7C15.5783 14.256 16.1887 13.272 16.1887 13.272C15.6881 13.596 15.212 13.824 14.7847 13.98C14.1743 14.232 13.5883 14.4 13.0145 14.496C11.8425 14.712 10.7682 14.652 9.85255 14.484C9.15667 14.352 8.55846 14.16 8.05792 13.968C7.77713 13.86 7.47192 13.728 7.16671 13.56C7.13009 13.536 7.09346 13.524 7.05684 13.5C7.03242 13.488 7.02021 13.476 7.008 13.464C6.78825 13.344 6.66617 13.26 6.66617 13.26C6.66617 13.26 7.25217 14.22 8.80263 14.676C8.43638 15.132 7.98467 15.672 7.98467 15.672C5.28663 15.588 4.26113 13.848 4.26113 13.848C4.26113 9.984 6.01913 6.852 6.01913 6.852C7.77713 5.556 9.44967 5.592 9.44967 5.592L9.57175 5.736C7.37425 6.36 6.36096 7.308 6.36096 7.308C6.36096 7.308 6.62954 7.164 7.08125 6.96C8.38754 6.396 9.42525 6.24 9.85255 6.204C9.9258 6.192 9.98684 6.18 10.0601 6.18C10.8048 6.084 11.6472 6.06 12.5262 6.156C13.686 6.288 14.9312 6.624 16.2009 7.308C16.2009 7.308 15.2364 6.408 13.161 5.784L13.3319 5.592C13.3319 5.592 15.0045 5.556 16.7625 6.852C16.7625 6.852 18.5205 9.984 18.5205 13.848C18.5205 13.848 17.4828 15.588 14.7847 15.672ZM9.10784 10.068C8.41196 10.068 7.86259 10.668 7.86259 11.4C7.86259 12.132 8.42417 12.732 9.10784 12.732C9.80371 12.732 10.3531 12.132 10.3531 11.4C10.3653 10.668 9.80371 10.068 9.10784 10.068ZM13.5639 10.068C12.868 10.068 12.3186 10.668 12.3186 11.4C12.3186 12.132 12.8802 12.732 13.5639 12.732C14.2598 12.732 14.8091 12.132 14.8091 11.4C14.8091 10.668 14.2598 10.068 13.5639 10.068Z',
    facebook:
    'M 20 4.82143 V 18.1786 C 20 19.1842 19.1842 20 18.1786 20 H 14.9417 V 13.2759 H 17.241300000000003 L 17.5714 10.710709999999999 H 14.9417 V 9.07143 C 14.9417 8.32768 15.1467 7.82299 16.212899999999998 7.82299 H 17.5714 V 5.5310299999999994 C 17.336199999999998 5.5006699999999995 16.5317 5.428570000000001 15.5906 5.428570000000001 C 13.6326 5.428570000000001 12.28929 6.62388 12.28929 8.820979999999999 V 10.71451 H 9.982140000000001 V 13.2797 H 12.29308 V 20 H 4.82143 C 3.815848 20 3 19.1842 3 18.1786 V 4.82143 C 3 3.815848 3.815848 3 4.82143 3 H 18.1786 C 19.1842 3 20 3.815848 20 4.82143 Z',
  flydown: 'M20 9H4l8 7 8-7z',
  globe:
    'M12 4a8 8 0 100 16 8 8 0 000-16zm-.8 14.34a6.4 6.4 0 01-5.43-7.77L9.6 14.4v.8c0 .88.72 1.6 1.6 1.6v1.54zm4-3.14c.72 0 1.31.46 1.52 1.11A6.37 6.37 0 0014.4 6.07v.33c0 .88-.72 1.6-1.6 1.6h-1.6v1.6a.8.8 0 01-.8.8H8.8V12h4.8c.44 0 .8.36.8.8v2.4h.8z',
  hide:
    'M7.83 12l4.58-4.59L11 6l-6 6 6 6 1.41-1.41L7.83 12zm5.705 0l4.58-4.59L16.705 6l-6 6 6 6 1.41-1.41-4.58-4.59z',
  hideDown:
    'M12.5 16.17L17.09 11.59L18.5 13L12.5 19L6.5 13L7.91 11.59L12.5 16.17ZM12.5 10.4649L17.09 5.88492L18.5 7.29492L12.5 13.2949L6.5 7.29492L7.91 5.88492L12.5 10.4649Z',
  history:
    'M4.714 12a8.143 8.143 0 118.143 8.143 8.102 8.102 0 01-5.754-2.389l1.285-1.284a6.283 6.283 0 004.47 1.863A6.329 6.329 0 0019.19 12a6.329 6.329 0 00-6.333-6.333A6.329 6.329 0 006.524 12h2.714l-3.655 3.646-.063-.126L2 12h2.714zm7.238.905V8.38h1.357v3.755l3.185 1.89-.696 1.159-3.846-2.28z',
  home:
    'M3.823 12.359L12 5l8.176 7.359h-2.453V18.9h-4.906v-4.906h-1.635V18.9H6.276V12.36H3.823zm12.265-1.48L12 7.199 7.91 10.88v6.386h1.636v-4.906h4.906v4.906h1.635v-6.386z',
  launch:
    'M6.55556 6.55556L6.55555 17.4444L17.4444 17.4444L17.4444 12L19 12L19 17.4444C19 18.3 18.3 19 17.4444 19L6.55555 19C5.69222 19 5 18.3 5 17.4444L5 6.55556C5 5.7 5.69222 5 6.55556 5L12 5L12 6.55556L6.55556 6.55556ZM13.5554 6.55556L13.5554 5L18.9998 5L18.9998 10.4444L17.4443 10.4444L17.4443 7.65222L9.79871 15.2978L8.70204 14.2011L16.3476 6.55556L13.5554 6.55556Z',
  lessonMap:
    'M18.487 5.023L18.61 5c.218 0 .389.171.389.389v11.76a.392.392 0 01-.28.373L14.333 19l-4.666-1.633-4.154 1.61L5.39 19A.385.385 0 015 18.611V6.851c0-.179.117-.319.28-.373L9.667 5l4.666 1.633 4.154-1.61zM13.556 8.01l-3.111-1.089v9.07l3.11 1.088V8.01zm-7-.319l2.333-.785v9.1l-2.334.902V7.69zm8.555 9.404l2.334-.786V7.1l-2.334.902v9.092z',
  lessonOverview:
    'M20.358 6h-4.399v7.196a2.642 2.642 0 00-.88-.159 2.635 2.635 0 00-2.639 2.64 2.635 2.635 0 002.64 2.638 2.636 2.636 0 002.638-2.639V7.76h2.64V6zm-6.16 0H3.644v1.76h10.556V6zM3.644 9.518h10.556v1.76H3.643v-1.76zm7.037 3.52H3.643v1.759h7.037v-1.76zm4.4 1.759a.883.883 0 00-.88.88c0 .483.396.88.88.88s.88-.396.88-.88a.882.882 0 00-.88-.88z',
  lock:
    'M17.25 9.121h-.875V7.515c0-2.216-1.96-4.015-4.375-4.015S7.625 5.299 7.625 7.515v1.606H6.75c-.963 0-1.75.723-1.75 1.606v8.167c0 .883.787 1.606 1.75 1.606h10.5c.962 0 1.75-.723 1.75-1.606v-8.167c0-.883-.788-1.606-1.75-1.606zM9.375 7.515c0-1.333 1.173-2.409 2.625-2.409 1.453 0 2.625 1.076 2.625 2.41V9.12h-5.25V7.515zM6.75 18.757h10.5v-8.03H6.75v8.03z',
  logOut:
    'M4 20.5469L8.53274 20.5469L8.53274 18.8986L5.64827 18.8986L5.64827 6.53658L8.53274 6.53658L8.53274 4.88831L4 4.88831L4 20.5469ZM16.8446 11.8934L6.88409 11.8934L6.88409 13.5417L16.8445 13.5417L14.1307 16.2554L15.2962 17.4209L19.9997 12.7175L15.2962 8.01403L14.1307 9.17954L16.8446 11.8934Z',
  mastery:
    'M6.75 16.2594L6.75 11.4434L4.75 10.6199L4.75 13.5L3.25 13.5L3.25 9.5C3.25 9.19608 3.43341 8.92221 3.71444 8.80649L12.2144 5.30649C12.3974 5.23117 12.6026 5.23117 12.7856 5.30649L21.2856 8.80649C21.5666 8.92221 21.75 9.19608 21.75 9.5C21.75 9.80392 21.5666 10.0778 21.2856 10.1935L18.25 11.4434L18.25 16.2594C18.25 16.6026 18.017 16.902 17.6843 16.9864C15.777 17.4699 14.2247 17.7406 12.6155 17.7498C11.0061 17.7589 9.37839 17.5062 7.31673 16.9866C6.98352 16.9027 6.75 16.603 6.75 16.2594ZM12.2144 13.6935L8.25 12.0611L8.25 15.6705C9.96557 16.0755 11.3091 16.2571 12.607 16.2498C13.8948 16.2425 15.1686 16.0491 16.75 15.6717L16.75 12.0611L12.7856 13.6935C12.6026 13.7688 12.3974 13.7688 12.2144 13.6935ZM4 16C4.55228 16 5 15.5523 5 15C5 14.4477 4.55228 14 4 14C3.44771 14 3 14.4477 3 15C3 15.5523 3.44771 16 4 16ZM12.5 12.1889L5.9698 9.5L12.5 6.81109L19.0302 9.5L12.5 12.1889Z',
  mentor:
    'M15.556 5H5.751a.756.756 0 00-.754.754v10.56l3.017-3.018h7.542c.415 0 .754-.339.754-.754V5.754A.756.756 0 0015.556 5zm-.754 1.508v5.28H7.388l-.883.882V6.508h8.297zm2.65.474h.796c.415 0 .755.34.755.755V19.05l-3.017-3.017H8.724a.756.756 0 01-.755-.754v-.927h8.448l1.034 1.035V6.982z',
  menu: 'M3 8V6h18v2H3zm0 5h18v-2H3v2zm0 5h18v-2H3v2z',
  metronome:
    'M14.401 3h-5.5L3.408 20h16.485l-3.125-9.672-1.326 1.615.158.488h-1.826l3.527-4.31a2.672 2.672 0 10-1.29-1.2l-4.509 5.51h-3.8L10.18 4.76h2.94l.962 2.977 1.325-1.62L14.401 3zM7.133 14.19l-1.309 4.05h11.653l-1.309-4.051H7.133zm11.242-9.431a.914.914 0 100 1.827.914.914 0 000-1.827z',
  modifications:
    'M16.7222 9.16667H14.8333V3.5H16.7222V5.38889H20.5V7.27778H16.7222V9.16667ZM3.5 7.27778V5.38889H12.9444V7.27778H3.5ZM3.5 18.6111V16.7222H9.16667V18.6111H3.5ZM12.9444 20.5V18.6111H20.5V16.7222H12.9444V14.8333H11.0556V20.5H12.9444ZM7.27778 11.0556V9.16667H9.16667V14.8333H7.27778V12.9444H3.5V11.0556H7.27778ZM20.5 12.9444V11.0556H11.0556V12.9444H20.5Z',
  notes:
    'M18.532 6.032a.747.747 0 000-1.058L16.777 3.22a.747.747 0 00-1.057 0l-1.47 1.47 2.813 2.812 1.47-1.47zM6 12.938l7.5-7.499 2.813 2.812-7.5 7.5H6v-2.813zm1.5 1.313h.69l6-6-.69-.69-6 6v.69zM21 18H3v2h18v-2z',
  pageLeft: 'M14.705 7.41L10.125 12l4.58 4.59-1.41 1.41-6-6 6-6 1.41 1.41z',
  pageRight: 'M8.295 7.41l4.58 4.59-4.58 4.59L9.705 18l6-6-6-6-1.41 1.41z',
  playFilled:
    'M3 12C3 7.032 7.032 3 12 3C16.968 3 21 7.032 21 12C21 16.968 16.968 21 12 21C7.032 21 3 16.968 3 12ZM15.5996 11.9951L10.1998 7.94514V16.0451L15.5996 11.9951Z',
  plusCircle:
    'M22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12.7503 16.9166H11.2503V12.9999H7.33301V11.4999H11.2503V7.58325H12.7503V11.4999H16.6663V12.9999H12.7503V16.9166Z',
  prompt:
    'M13.925 18.167h-3.85v1.167h3.85v-1.167zm-3.835-.94h3.819v-3.06a3.816 3.816 0 001.909-3.303A3.821 3.821 0 0012 7.046a3.821 3.821 0 00-3.819 3.818c0 1.413.77 2.64 1.91 3.303v3.06zm7-5.69H19v-1.273h-1.91v1.273zm.288-5.153l-.898-.898-1.139 1.146.897.897 1.14-1.145zm-4.742-.578h-1.273V3.897h1.273v1.91zM5 11.537h1.91v-1.273H5v1.273zm3.665-4.91L7.526 5.482l-.897.897 1.145 1.14.891-.892z',
  radioEmpty:
    'M4 12C4 7.584 7.584 4 12 4C16.416 4 20 7.584 20 12C20 16.416 16.416 20 12 20C7.584 20 4 16.416 4 12ZM5.6 12C5.6 15.536 8.464 18.4 12 18.4C15.536 18.4 18.4 15.536 18.4 12C18.4 8.464 15.536 5.6 12 5.6C8.464 5.6 5.6 8.464 5.6 12Z',
  radioFilled:
    'M4 12C4 7.584 7.584 4 12 4C16.416 4 20 7.584 20 12C20 16.416 16.416 20 12 20C7.584 20 4 16.416 4 12ZM5.6 12C5.6 15.536 8.464 18.4 12 18.4C15.536 18.4 18.4 15.536 18.4 12C18.4 8.464 15.536 5.6 12 5.6C8.464 5.6 5.6 8.464 5.6 12Z M12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8V8Z',
  review:
    'M8.024 18.176L12 14.026l3.976 4.15a.587.587 0 00.654.136.627.627 0 00.37-.579V5.626A.613.613 0 0016.4 5H7.6c-.331 0-.6.28-.6.626v12.107c0 .254.146.482.37.579a.58.58 0 00.654-.136z',
  send:
    'M4.23933 19.92L19.6866 12.8674C20.4037 12.5374 20.4037 11.4626 19.6866 11.1326L4.23933 4.08003C3.65507 3.8066 3.00885 4.2686 3.00885 4.93803L3 9.28458C3 9.75601 3.32754 10.1614 3.77015 10.218L16.2785 12L3.77015 13.7726C3.32754 13.8386 3 14.244 3 14.7154L3.00885 19.062C3.00885 19.7314 3.65507 20.1934 4.23933 19.92Z',
  settings:
    'M18.208 12.25c0 .274-.024.532-.056.79l1.7 1.329c.152.12.193.338.096.515l-1.61 2.787a.399.399 0 01-.347.202.456.456 0 01-.145-.024l-2.006-.806c-.419.314-.87.588-1.361.79l-.306 2.134a.393.393 0 01-.395.338h-3.222a.393.393 0 01-.395-.338l-.306-2.135a5.886 5.886 0 01-1.361-.789l-2.006.806a.376.376 0 01-.137.024.402.402 0 01-.354-.202l-1.611-2.787a.406.406 0 01.097-.515l1.7-1.33a6.389 6.389 0 01-.057-.789c0-.265.024-.531.056-.79l-1.7-1.328a.397.397 0 01-.096-.516l1.61-2.787a.399.399 0 01.347-.201c.049 0 .097.008.145.024l2.006.806c.419-.315.87-.588 1.361-.79l.306-2.134a.393.393 0 01.395-.339h3.222c.201 0 .37.145.395.339l.306 2.134c.491.202.942.467 1.361.79l2.006-.806a.402.402 0 01.491.177l1.611 2.787a.406.406 0 01-.096.516l-1.7 1.329c.032.258.056.515.056.79zm-1.61 0c0-.169-.007-.338-.04-.588l-.112-.91.717-.564.861-.685-.563-.974-1.023.41-.854.347-.733-.564a4.57 4.57 0 00-.99-.572l-.855-.346-.129-.91-.153-1.088h-1.12l-.16 1.088-.13.91-.853.346c-.33.137-.66.33-1.007.588l-.725.548-.838-.338-1.023-.411-.563.974.87.677.716.564-.112.91c-.025.242-.04.427-.04.588 0 .161.015.347.04.596l.112.91-.717.564-.87.677.564.975 1.023-.411.854-.347.733.564c.322.242.645.427.99.572l.855.346.129.91.153 1.088h1.127l.162-1.087.128-.91.854-.347c.33-.137.66-.33 1.007-.588l.725-.548.838.339 1.023.41.564-.974-.87-.677-.717-.564.112-.91c.025-.241.04-.419.04-.588zm-4.432-3.222a3.221 3.221 0 100 6.444 3.221 3.221 0 100-6.444zm-1.61 3.222c0 .886.726 1.611 1.612 1.611.886 0 1.61-.725 1.61-1.61 0-.887-.724-1.612-1.61-1.612-.886 0-1.611.725-1.611 1.611z',
  soundslice:
    'M8.519 4h8.759v12.676a2.635 2.635 0 01-2.639 2.64A2.636 2.636 0 0112 16.675a2.635 2.635 0 012.639-2.639c.308 0 .607.062.88.159V5.759h-5.241v7.917a2.635 2.635 0 01-2.639 2.64A2.636 2.636 0 015 13.675a2.635 2.635 0 012.639-2.639c.308 0 .607.062.88.159V4z',
  starNo:
    'M12.488 16.376a1 1 0 00-.992 0l-4.536 2.59 1.186-4.801a1 1 0 00-.336-1.013L3.909 9.95l5.203-.427a1 1 0 00.831-.59l2.05-4.595 2.064 4.612a1 1 0 00.831.588l5.203.427-3.9 3.201a1 1 0 00-.337 1.013l1.185 4.8-4.55-2.604z',
  starYes:
    'M12.116 17.027a.25.25 0 00-.248 0l-5.549 3.168a.25.25 0 01-.366-.277l1.465-5.933a.25.25 0 00-.084-.253L2.478 9.747a.25.25 0 01.138-.443l6.434-.528a.25.25 0 00.208-.147l2.506-5.618a.25.25 0 01.456 0l2.522 5.634a.25.25 0 00.207.147l6.435.528a.25.25 0 01.138.442l-4.856 3.985a.25.25 0 00-.084.253l1.465 5.933a.25.25 0 01-.367.277l-5.564-3.183z',
  streak:
    'M13.266 3s.624 2.236.624 4.05c0 1.739-1.139 3.148-2.877 3.148-1.747 0-3.064-1.409-3.064-3.147l.026-.304a11.619 11.619 0 00-2.726 7.502A6.75 6.75 0 0012 21a6.75 6.75 0 006.751-6.751c0-4.549-2.186-8.608-5.485-11.249zm-1.628 10.54c-1.148.236-1.832.979-1.832 2.034 0 1.13.937 2.042 2.102 2.042 1.73 0 3.13-1.4 3.13-3.13a8.88 8.88 0 00-.388-2.634c-.666.903-1.856 1.452-3.012 1.688zm-4.701.709A5.067 5.067 0 0012 19.312a5.067 5.067 0 005.063-5.063c0-2.16-.557-4.245-1.594-6.101-.448 2.194-2.211 3.738-4.456 3.738a4.71 4.71 0 01-3.35-1.375 10.03 10.03 0 00-.726 3.738z',
  support:
    'M12 5.5C8.41243 5.5 5.5 8.41243 5.5 12C5.5 15.5876 8.41243 18.5 12 18.5C15.5876 18.5 18.5 15.5876 18.5 12C18.5 8.41243 15.5876 5.5 12 5.5ZM4 12C4 7.584 7.584 4 12 4C16.416 4 20 7.584 20 12C20 16.416 16.416 20 12 20C7.584 20 4 16.416 4 12ZM11.2002 16.8H12.8002V15.2H11.2002V16.8ZM8.7998 10.4C8.7998 8.63195 10.2318 7.19995 11.9998 7.19995C13.7678 7.19995 15.1998 8.63195 15.1998 10.4C15.1998 11.4263 14.5678 11.9786 13.9524 12.5164C13.3686 13.0266 12.7998 13.5237 12.7998 14.4H11.1998C11.1998 12.9429 11.9535 12.3652 12.6162 11.8573C13.136 11.4588 13.5998 11.1033 13.5998 10.4C13.5998 9.51995 12.8798 8.79995 11.9998 8.79995C11.1198 8.79995 10.3998 9.51995 10.3998 10.4H8.7998Z',
  timer:
    'M9 1.505h6v2H9v-2zm2 13v-6h2v6h-2zm8.03-6.62l1.42-1.42c-.43-.51-.9-.99-1.41-1.41l-1.42 1.42A8.962 8.962 0 0012 4.495a9 9 0 00-9 9c0 4.97 4.02 9 9 9s9-4.03 9-9c0-2.11-.74-4.06-1.97-5.61zM5 13.505c0 3.87 3.13 7 7 7s7-3.13 7-7-3.13-7-7-7-7 3.13-7 7z',
  video: 'M7 17.923V5l12 6.461-12 6.462z',
  x:
    'M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z',
};

const sizes: { [key: string]: number } = {
  m: 24,
  nav: 30,
  metronome: 35,
  s: 20,
  xs: 16,
  l: 32,
  xl: 52,
  sr: 40,
  alert: 36,
};

interface IconProps {
  size?: string;
  color?: string;
  name: string;
}

export const FillIcon: React.FC<IconProps> = ({
  color = colors.tanzanite,
  size = 'm',
  name,
}) => (
  <Svg width={sizes[size]} height={sizes[size]} fill="none" viewBox="0 0 24 24">
    <Path fill={color} fillRule="evenodd" d={draw[name]} clipRule="evenodd" />
  </Svg>
);

const strokeWidths: { [key: string]: string } = {
  caret: '1.25',
  caretUp: '1.25',
  caretDown: '1.25',
  check: '2',
  checkDouble: '1.5',
  starNo: '1.5',
  account: '1.5',
};

export const StrokeIcon: React.FC<IconProps> = ({
  color = colors.tanzanite,
  size = 'm',
  name,
}) => (
  <Svg width={sizes[size]} height={sizes[size]} fill="none" viewBox="0 0 24 24">
    <Path stroke={color} strokeWidth={strokeWidths[name]} d={draw[name]} />
  </Svg>
);

const makeFillIcon = (iconName: string) => {
  const FillIcon: React.FC<IconProps> = ({ color, size }) => (
    <FillIcon color={color} size={size} name={iconName} />
  );
  return FillIcon;
};

const makeStrokeIcon = (iconName: string) => {
  const StrokeIcon: React.FC<IconProps> = ({ color, size }) => (
    <StrokeIcon color={color} size={size} name={iconName} />
  );
  return StrokeIcon;
};

export const Caret: React.FC<IconProps> = makeStrokeIcon('caret');
export const Check: React.FC<IconProps> = makeStrokeIcon('check');
export const StarNo: React.FC<IconProps> = makeStrokeIcon('starNo');

export const AddVideo: React.FC<IconProps> = makeFillIcon('addVideo');
export const Camera: React.FC<IconProps> = makeFillIcon('camera');
export const Drone: React.FC<IconProps> = makeFillIcon('drone');
export const Flydown: React.FC<IconProps> = makeFillIcon('flydown');
export const Globe: React.FC<IconProps> = makeFillIcon('globe');
export const Hide: React.FC<IconProps> = makeFillIcon('hide');
export const History: React.FC<IconProps> = makeFillIcon('history');
export const Home: React.FC<IconProps> = makeFillIcon('home');
export const LessonMap: React.FC<IconProps> = makeFillIcon('lessonMap');
export const LessonOverview: React.FC<IconProps> = makeFillIcon(
  'lessonOverview'
);
export const Lock: React.FC<IconProps> = makeFillIcon('lock');
export const Mentor: React.FC<IconProps> = makeFillIcon('mentor');
export const Menu: React.FC<IconProps> = makeFillIcon('menu');
export const Metronome: React.FC<IconProps> = makeFillIcon('metronome');
export const Notes: React.FC<IconProps> = makeFillIcon('notes');
export const Modifications: React.FC<IconProps> = makeFillIcon('modifications');
export const PageLeft: React.FC<IconProps> = makeFillIcon('pageLeft');
export const PageRight: React.FC<IconProps> = makeFillIcon('pageRight');
export const Prompt: React.FC<IconProps> = makeFillIcon('prompt');
export const Review: React.FC<IconProps> = makeFillIcon('review');
export const Settings: React.FC<IconProps> = makeFillIcon('settings');
export const SoundSlice: React.FC<IconProps> = makeFillIcon('soundSlice');
export const StarYes: React.FC<IconProps> = makeFillIcon('starYes');
export const Streak: React.FC<IconProps> = makeFillIcon('streak');
export const Timer: React.FC<IconProps> = makeFillIcon('timer');
export const Video: React.FC<IconProps> = makeFillIcon('video');
export const X: React.FC<IconProps> = makeFillIcon('x');
