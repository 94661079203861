import styled from 'styled-components/native';

import { colors } from '../../constants/theme';

interface DividerProps {
  color?: string;
  marginTop?: number;
  marginBottom?: number;
}

export const Divider = styled.View`
  height: 1px;
  width: 100%;
  background-color: ${(props: DividerProps) =>
    props.color || colors.borderline};
  ${(props: DividerProps) =>
    props.marginTop ? `margin-top: ${props.marginTop}px;` : ''}
  ${(props: DividerProps) =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom}px;` : ''}
`;

interface HorizontalDividerProps {
  marginLeft?: number;
  marginRight?: number;
}

export const HorizontalDivider = styled.View`
  width: 1px;
  height: 100%;
  background-color: ${colors.borderline};
  ${(props: HorizontalDividerProps) =>
    props.marginLeft ? `margin-left: ${props.marginLeft}px;` : ''}
  ${(props: HorizontalDividerProps) =>
    props.marginRight ? `margin-right: ${props.marginRight}px;` : ''}
`;
