import React, { FC } from 'react';
import { Linking } from 'expo';
import { Image, TouchableOpacity } from 'react-native';
import { Box, Text } from '../styled';
import { colors, fonts } from '../../constants/theme';
import CourseCompletionImage from '../../assets/images/CourseCompletion.png';
import { FillIcon } from '../Icon';

export interface CourseCompletionProps {
  name: string;
}

export const CourseCompletion: FC<CourseCompletionProps> = (props) => {
  const gotoCommunity = () => {
    Linking.openURL('https://web.facebook.com/groups/316989302560703/');
  };

  return (
    <Box flex={1} alignItems="center">
      <Image
        source={{ uri: CourseCompletionImage }}
        style={{ width: '100%', height: 291, marginBottom: 24 }}
      />
      <Text
        font={fonts.headline}
        size={28}
        color={colors.tanzanite}
        textAlign="center"
        marginBottom={12}
      >
        Cue the fireworks, {props.name}!
      </Text>
      <Text
        font={fonts.normal}
        size={20}
        color={colors.amethyst}
        textAlign="center"
        marginBottom={6}
      >
        You've completed Guitar Mastery Intensive
      </Text>
      <Text
        font={fonts.thin}
        size={20}
        color={colors.tanzanite}
        textAlign="center"
      >
        Share your achievement with friends
      </Text>
      <TouchableOpacity onPress={gotoCommunity} style={{ marginTop: 24 }}>
        <FillIcon name="facebook" size="sr" />
      </TouchableOpacity>
    </Box>
  );
};
