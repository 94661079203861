import React, { FC, useCallback, useState } from 'react';
import { View, Dimensions, StyleSheet } from 'react-native';

export interface LayoutProviderProps {}

export enum LayoutType {
  Portrait,
  Landscape,
}

interface LayoutProviderContextState {
  activeLayout: LayoutType;
  screenHeight: number;
  screenWidth: number;
}

function getLayoutTypeFromWidth(width: number) {
  if (width >= 1024) {
    return LayoutType.Landscape;
  } else {
    return LayoutType.Portrait;
  }
}

export const LayoutProviderContext = React.createContext<
  LayoutProviderContextState
>({} as LayoutProviderContextState);

export const LayoutProvider: FC<LayoutProviderProps> = (props) => {
  const initialLayout = getLayoutTypeFromWidth(Dimensions.get('window').width);

  const [activeLayout, setActiveLayout] = useState(initialLayout);
  const [height, setHeight] = useState(Dimensions.get('window').height);
  const [width, setWidth] = useState(Dimensions.get('window').width);

  const onLayout = useCallback((event) => {
    const { width, height } = event.nativeEvent.layout;
    setHeight(height);
    setWidth(width);
    setActiveLayout(getLayoutTypeFromWidth(width));
  }, []);

  return (
    <LayoutProviderContext.Provider
      value={{ activeLayout, screenHeight: height, screenWidth: width }}
    >
      <View onLayout={onLayout} style={styles.viewLayout} />
      {props.children}
    </LayoutProviderContext.Provider>
  );
};

const styles = StyleSheet.create({
  viewLayout: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
});

export default LayoutProvider;
