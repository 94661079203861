import React from 'react';
import { Bubble, LeftRightStyle } from 'react-native-gifted-chat';
import { TextStyle, ViewStyle } from 'react-native';

import { theme } from '../../../constants/theme';
import { RenderVideo } from './RenderVideo';
import { RenderTick } from './RenderTick';
import { RenderMessageText } from './RenderMessageText';

// import { VideoBubble } from './VideoBubble';

export const RenderBubble: React.FC = (props) => (
  <Bubble
    {...props}
    renderMessageVideo={(props: any) => <RenderVideo {...props} />}
    renderMessageText={RenderMessageText}
    renderTicks={RenderTick}
    textStyle={bubbleTextStyle}
    wrapperStyle={bubbleWrapperStyle}
  />
);

export const bubbleTextStyle: LeftRightStyle<TextStyle> = {
  left: {
    color: theme.chat.leftBubble.textColor,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    flexShrink: 1,
    minWidth: 96,
    maxWidth: 370,
  },
  right: {
    color: theme.chat.rightBubble.textColor,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    flexShrink: 1,
    minWidth: 96,
    maxWidth: 370,
  },
};

export const bubbleWrapperStyle: LeftRightStyle<ViewStyle> = {
  left: {
    backgroundColor: theme.chat.leftBubble.backgroundColor,
    borderBottomLeftRadius: 0,
    padding: 12,
    maxWidth: '98%',
  },
  right: {
    backgroundColor: theme.chat.rightBubble.backgroundColor,
    borderBottomRightRadius: 0,
    padding: 12,
    maxWidth: '98%',
  },
};

/*
const systemTextStyle: LeftRightStyle<TextStyle> = {
  left: {
    // ...fonts.italic,
    // fontSize: bubbleFontSize,
    // color: colors.darkGrey,
  },
  right: {
    // ...fonts.italic,
    // fontSize: bubbleFontSize,
    // color: colors.lightGrey,
  },
};

const bubbleWrapperStyle = {
  left: {
    //   maxWidth
  },
  right: {
    // maxWidth,
    // backgroundColor: colors.accent,
  },
};
// const marginBottom = rhythm[2];

const systemWrapperStyle = {
  // marginLeft includes profile bubble 36 wide + 8 margin, manually measured
  left: {
    //   maxWidth, marginLeft: 36 + 8, marginBottom
  },
  right: {
    // maxWidth,
    // marginBottom,
    // marginRight: 8, // from gifted chat manual measurements
    // backgroundColor: colors.accentDarken,
  },
};
*/
