import React, { useContext, FC } from 'react';
import { View, StyleSheet } from 'react-native';

import {
  StudentContext,
  MentorContext,
  UserContext,
} from 'sonora-containers/index';
import { L2ScreenProps } from '../screens/screens';
import { StudentL2ActivityView } from '../screens/StudentL2ActivityView';
import { MentorL2ActivityView } from '../screens/MentorL2ActivityView';

export const L2Content: FC<L2ScreenProps> = (props) => {
  const { userType } = useContext(UserContext);
  let curriculumMap;
  if (userType === 'mentor') {
    curriculumMap = useContext(MentorContext).curriculumMap;
  } else {
    curriculumMap = useContext(StudentContext).curriculumMap;
  }

  const node = curriculumMap[props.id];

  if (!node)
    throw new Error(`tried to access node ${props.id} not in curriculum`);

  if (node.type === 'Folder')
    throw new Error(`Node ${props.id} is a folder and cannot be accessed here`);

  return (
    <View style={styles.content} key={node.id}>
      {userType === 'mentor' ? (
        <MentorL2ActivityView activity={node} />
      ) : (
        <StudentL2ActivityView activity={node} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  content: { flex: 1, backgroundColor: 'white' },
});
