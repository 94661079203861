import React from 'react';

import { FillIcon, StrokeIcon } from '../Icon';
import { getStarColor } from './utils';

interface RatingStarProps {
  stars: number;
  isComplete?: boolean;
}

export const RatingStar: React.FC<RatingStarProps> = ({
  stars,
  isComplete,
}) => {
  const color = getStarColor(stars, isComplete);

  return stars === 0 ? (
    <StrokeIcon name="starNo" color={color} />
  ) : (
    <FillIcon name="starYes" color={color} />
  );
};
