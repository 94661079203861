import React, { FC, useContext } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { colors, FillIcon } from 'sonora-design-system';

import { L2Screens } from '../screens/L2Screens';
import L2SidebarMenuItem from './L2SidebarMenuItem';
import MetronomeSidebar from 'sonora-components/metronome/MetronomeSidebar';
import { L2NavigationContext } from './L2NavigationProvider';
import { ModificationsProviderContext } from '../screens/Modifications/ModificationsProvider';

export interface L2LandscapeSidebarProps {
  isDroneDrawerOpen: boolean;
  setDroneDrawerOpen: (isOpen: boolean) => void;
}

const L2LandscapeSidebar: FC<L2LandscapeSidebarProps> = (props) => {
  const { activeIndex, setActiveIndex } = useContext(L2NavigationContext);
  const { modificationsDrawerEnabled } = useContext(
    ModificationsProviderContext
  );

  return (
    <View style={styles.sidebar}>
      <View style={styles.sidebarContent}>
        <View style={styles.mainMenu}>
          {L2Screens.map((screen, index) => {
            const disabled =
              screen.name === 'Modifications' && !modificationsDrawerEnabled;
            return (
              <L2SidebarMenuItem
                disabled={disabled}
                key={screen.name}
                iconName={screen.iconName}
                handlePress={() =>
                  setActiveIndex(index === activeIndex ? undefined : index)
                }
                focused={activeIndex === index}
              />
            );
          })}
        </View>
        <View style={styles.bottomMenu}>
          <View style={styles.menuItemContainer}>
            <View style={styles.divider} />
          </View>
          <MetronomeSidebar />
          <View style={[styles.menuItemContainer, { marginTop: 16 }]}>
            <View style={styles.divider} />
          </View>
          <View style={[styles.menuItemContainer, { marginTop: 12 }]}>
            <TouchableOpacity
              onPress={() => props.setDroneDrawerOpen(!props.isDroneDrawerOpen)}
            >
              <FillIcon name="drone" />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  sidebar: {
    width: 48,
    backgroundColor: colors.opal,
  },
  menuItemContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  divider: {
    // TODO (andrew): Check if this is a style guide color
    borderBottomColor: '#E3DCF1',
    borderBottomWidth: 1,
    width: '80%',
  },
  sidebarContent: {
    flex: 1,
    justifyContent: 'space-between',
  },
  mainMenu: { marginTop: 24 },
  bottomMenu: { paddingBottom: 24 },
  link: {
    textTransform: 'uppercase',
    color: colors.tanzanite,
    paddingVertical: 12,
    paddingHorizontal: 24,
    textDecorationLine: 'underline',
  },
});

export default L2LandscapeSidebar;
