import React from 'react';
import styled from 'styled-components/native';

interface ScrollableProps {
  padding?: number;
  paddingLeft?: number;
  paddingTop?: number;
  paddingRight?: number;
  paddingBottom?: number;
  alignItems?: 'baseline' | 'center' | 'flex-start' | 'flex-end' | 'stretch';
  children?: React.ReactNode;
}

const StyledScrollView = styled.ScrollView`
  flex: 1;
`;

export const Scrollable: React.FC<ScrollableProps> = ({
  padding,
  paddingLeft,
  paddingTop,
  paddingRight,
  paddingBottom,
  alignItems,
  children,
}) => {
  return (
    <StyledScrollView
      contentContainerStyle={{
        padding,
        paddingLeft,
        paddingTop,
        paddingRight,
        paddingBottom,
        alignItems,
      }}
    >
      {children || null}
    </StyledScrollView>
  );
};
