import React, { useContext } from 'react';

import { colors } from '../../constants/theme';

import { Box } from '../styled';
import {
  ModificationDetailView,
  ModificationCategory,
  Modification,
} from '../ModificationDetailView';
import {
  categories,
  makeModifications,
} from '../ModificationDetailView/mock-data';
import { DetailsViewProps } from './types';
import {
  ModalPresenterProvider,
  ModalPresenterContext,
} from '../../util/ModalProvider';
import { VimeoModal } from '../VimeoModal';

const allModifications = makeModifications();

export const DetailsView: React.FC<DetailsViewProps> = ({
  route,
  navigation,
}) => {
  const { categoryId, activeModificationId } = route.params;
  const { presentComponentAsModal } = useContext(ModalPresenterContext);

  const category = categories.find(
    (c: ModificationCategory) => c.id === categoryId
  );
  const [selectedModificationId, setSelectedModificationId] = React.useState(
    activeModificationId || 0
  );

  if (!category) {
    return null;
  }

  const modifications = category
    ? allModifications.filter((m) => category.modifications.indexOf(m.id) >= 0)
    : [];

  const goBack = () => {
    navigation.pop();
  };

  const showVimeoModal = (m: Modification) => {
    if (m.vimeoId) {
      presentComponentAsModal(VimeoModal, {
        extraData: {
          vimeoId: m.vimeoId,
        },
      });
    }
  };

  return (
    <ModalPresenterProvider>
      <Box backgroundColor={colors.agate} width="400px" height="100%">
        <ModificationDetailView
          categoryLabel={category.title}
          vimeoId="452261270"
          description={category.description}
          modifications={modifications}
          selectedModificationId={selectedModificationId}
          onSelectModification={setSelectedModificationId}
          onPlayModificationVideo={showVimeoModal}
          onBack={goBack}
        />
      </Box>
    </ModalPresenterProvider>
  );
};
