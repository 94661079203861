import React, { useState } from 'react';

import ModalBlurView from '../components/modal/ModalBlurView';
import { DismissFunction } from '../components/modal/types';
export { DismissFunction } from '../components/modal/types';
import ModalCard from '../components/modal/ModalCard';
import { RootNavScreen, Screens } from '../../src/router/screens/screens';

export interface ModalFunctionComponentProps {
  dismissModal: DismissFunction;
}

export interface ModalOptions {
  width?: number | string;
  height?: number | string;
  minWidth?: number | string;
  minHeight?: number | string;
  extraData?: any;
  onDismiss?: DismissFunction;
}

export type ModalFunctionComponent = React.FunctionComponent<
  ModalFunctionComponentProps
>;

interface ModalPresenterContextState {
  /**
   * presents Component as a visible Modal, providing a dismissModal prop
   * that Component can use to dismiss itself
   */
  extraData?: any;
  modalIsVisible: boolean;
  dismissModal: DismissFunction;
  presentComponentAsModal: (
    Component: ModalFunctionComponent,
    options?: ModalOptions
  ) => void;
}

export const ModalPresenterContext = React.createContext<
  ModalPresenterContextState
>({} as ModalPresenterContextState);

export const ModalPresenterProvider: React.FunctionComponent<{}> = ({
  children,
}) => {
  const [modalComponent, setModalComponent] = useState(<></>);
  const [isVisible, setIsVisible] = useState(false);
  const [options, setOptions] = useState<ModalOptions>({});

  const createDismissModal = (freshOptions: ModalOptions) => {
    const dismissModal: DismissFunction = (result, extraData) => {
      setIsVisible(false);
      setOptions({});
      setModalComponent(<></>);
      if (!!freshOptions.onDismiss) {
        freshOptions.onDismiss(result, extraData);
      }
    };
    return dismissModal;
  };

  const presentComponentAsModal = (
    Component: ModalFunctionComponent,
    options: ModalOptions = {}
  ) => {
    if (!!options) {
      setOptions(options);
    }
    setModalComponent(<Component dismissModal={createDismissModal(options)} />);
    setIsVisible(true);
  };

  if (isVisible) {
    console.log('modal rendering with options', options);
  }

  return (
    <ModalPresenterContext.Provider
      value={{
        presentComponentAsModal,
        modalIsVisible: isVisible,
        dismissModal: createDismissModal(options),
        extraData: options.extraData,
      }}
    >
      {isVisible && (
        <ModalBlurView dismissModal={createDismissModal(options)}>
          <ModalCard dismissModal={createDismissModal(options)} {...options}>
            {modalComponent}
          </ModalCard>
        </ModalBlurView>
      )}
      {children}
    </ModalPresenterContext.Provider>
  );
};

export function ModalProviderHOC<T extends Screens>(
  component: RootNavScreen<T>
): RootNavScreen<T> {
  return (props) => (
    <ModalPresenterProvider>{component(props)}</ModalPresenterProvider>
  );
}
