import React, { useRef, useState } from 'react';
import { LayoutChangeEvent } from 'react-native';
import styled from 'styled-components/native';
import { useHover } from 'react-native-web-hooks';

import {
  TooltipProps,
  TooltipWrapperProps,
  Dimension,
  Position,
  TooltipPlacement,
} from './types';
import { Box, Text } from '../styled';
import { colors, fonts } from '../../constants/theme';
import { TooltipArrow } from './TooltipArrow';

const TooltipContainer = styled.View`
  position: relative;
  z-index: 0;
`;

const TooltipWrapper = styled.View`
  position: absolute;
  z-index: 1;
  background-color: ${colors.white};
  border: 1px solid ${colors.purpley};
  padding: 10px;
  ${(props: TooltipWrapperProps) =>
    props.width ? `width: ${props.width}px;` : ''}
  ${(props: TooltipWrapperProps) =>
    props.left ? `left: ${props.left}px;` : ''}
  ${(
    props: TooltipWrapperProps
  ) => (props.right ? `right: ${props.right}px;` : '')}
  ${(
    props: TooltipWrapperProps
  ) => (props.top ? `top: ${props.top}px;` : '')}
  ${(
    props: TooltipWrapperProps
  ) => (props.bottom ? `bottom: ${props.bottom}px;` : '')}
`;

const TooltipArrowContainer = styled.View`
  position: absolute;
  z-index: 2;
  ${(props: TooltipWrapperProps) =>
    props.left ? `left: ${props.left}px;` : ''}
  ${(props: TooltipWrapperProps) =>
    props.right ? `right: ${props.right}px;` : ''}
  ${(
    props: TooltipWrapperProps
  ) => (props.top ? `top: ${props.top}px;` : '')}
  ${(
    props: TooltipWrapperProps
  ) => (props.bottom ? `bottom: ${props.bottom}px;` : '')}
`;

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  width,
  placement = 'bottom',
}) => {
  const containerRef = useRef(null);
  const [dimension, setDimension] = useState<Dimension>({
    width: 0,
    height: 0,
  });
  const [wrapperDimension, setWrapperDimension] = useState<Dimension>({
    width: 0,
    height: 0,
  });
  const hovered = useHover(containerRef);

  const { wrapperPos, arrowPos, arrowAngle } = calcPlacement(
    placement,
    dimension,
    wrapperDimension
  );

  return (
    <Box position="relative">
      <TooltipContainer
        ref={containerRef}
        onLayout={(ev: LayoutChangeEvent) => {
          const { width, height } = ev.nativeEvent.layout;
          setDimension({ width, height });
        }}
      >
        {children}
      </TooltipContainer>
      {hovered && (
        <>
          <TooltipWrapper
            width={width}
            {...wrapperPos}
            onLayout={(ev: LayoutChangeEvent) => {
              const { width, height } = ev.nativeEvent.layout;
              if (
                width !== wrapperDimension.width ||
                height !== wrapperDimension.height
              ) {
                setWrapperDimension({ width, height });
              }
            }}
          >
            <Text font={fonts.headline} size={16}>
              Need work
            </Text>
            <Text font={fonts.thin}>Please review work and get back</Text>
          </TooltipWrapper>
          <TooltipArrowContainer {...arrowPos}>
            <TooltipArrow rotation={arrowAngle} />
          </TooltipArrowContainer>
        </>
      )}
    </Box>
  );
};

function calcPlacement(
  placement: TooltipPlacement,
  containerDimension: Dimension,
  wrapperDimension: Dimension
): {
  wrapperPos: Position;
  arrowPos: Position;
  arrowAngle: number;
} {
  let wrapperPos: Position = {};
  let arrowPos: Position = {};
  let arrowAngle: number = 0;

  switch (placement) {
    case 'left':
      wrapperPos.left = -wrapperDimension.width - 16;
      wrapperPos.top =
        (containerDimension.height - wrapperDimension.height) / 2;
      arrowPos.left = -18;
      arrowPos.top = (containerDimension.height - 17) / 2;
      arrowAngle = 180;
      break;
    case 'right':
      wrapperPos.left = containerDimension.width + 16;
      wrapperPos.top =
        (containerDimension.height - wrapperDimension.height) / 2;
      arrowPos.left = containerDimension.width + 4;
      arrowPos.top = (containerDimension.height - 17) / 2;
      arrowAngle = 0;
      break;
    case 'top':
      wrapperPos.left = (containerDimension.width - wrapperDimension.width) / 2;
      wrapperPos.top = -wrapperDimension.height - 16;
      arrowPos.left = (containerDimension.width - 17) / 2;
      arrowPos.top = -19;
      arrowAngle = 270;
      break;
    default:
      wrapperPos.left = (containerDimension.width - wrapperDimension.width) / 2;
      wrapperPos.top = containerDimension.height + 16;
      arrowPos.left = (containerDimension.width - 17) / 2;
      arrowPos.top = containerDimension.height + 2;
      arrowAngle = 90;
      break;
  }

  return { wrapperPos, arrowPos, arrowAngle };
}
