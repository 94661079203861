import React from 'react';
import { storiesOf } from '@storybook/react-native';
import { ChatRelationshipType } from 'sonora-types';

import { colors } from '../../constants/theme';
import { Box } from '../styled';
import { StudentListItem } from '.';
import { students } from './mock-data';
import { StudentList } from './StudentList';

const studentListStories = storiesOf('StudentList', module);

const StudentListItemStory = () => (
  <Box padding={20} alignItems="center" backgroundColor={colors.agate}>
    <Box width="530px">
      <StudentListItem
        fullName="Christopher Ploeg Long Name-Hyphen"
        startDate="09/21/20"
        finishDate="09/21/21"
        onOpen={() => {
          console.log('Open');
        }}
        onChatOpen={() => {
          console.log('Open Chat');
        }}
        lastPracticed="--"
        lastPracticedColor={colors.tanzanite}
        isNew
      />
      <StudentListItem
        fullName="Micah Cancelmo Rivera"
        startDate="08/15/20"
        finishDate="08/15/21"
        onOpen={() => {
          console.log('Open');
        }}
        onChatOpen={() => {
          console.log('Open Chat');
        }}
        lastPracticed="--"
        lastPracticedColor={colors.tanzanite}
        hasBadge
      />
      <StudentListItem
        fullName="Alejandro Rodriguez"
        startDate="08/15/20"
        finishDate="08/15/21"
        onOpen={() => {
          console.log('Open');
        }}
        onChatOpen={() => {
          console.log('Open Chat');
        }}
        lastPracticed="--"
        lastPracticedColor={colors.tanzanite}
      />
    </Box>
  </Box>
);

const StudentListStory = () => {
  return (
    <Box padding={20} alignItems="center" backgroundColor={colors.agate}>
      <StudentList
        currentStudents={students}
        navigateChat={(student: ChatRelationshipType) =>
          console.log(student.id)
        }
      />
    </Box>
  );
};

studentListStories.add('StudentList', StudentListStory);
studentListStories.add('StudentListItem', StudentListItemStory);
