import React from 'react';
import styled from 'styled-components/native';
import { LinearGradient } from 'expo-linear-gradient';

import {
  colors,
  Box,
  TaskList,
  Calendar,
  Scrollable,
  Progress,
  Divider,
  AnnouncementHeader,
  theme,
} from 'sonora-design-system';
import { OverviewProps } from './types';
import { L1Header } from '../../frame/L1Header';

const PageContent = styled.View`
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 24px;
`;

const Section = styled.View`
  flex-direction: column;
  width: 100%;
  align-items: center;
  background-color: ${(props: { backgroundColor: string }) =>
    props.backgroundColor};
`;

const SectionMaxWidth = styled.View`
  flex-direction: column;
  max-width: 532px;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
`;

const AnnouncementContainer = styled.View`
  padding: 0 72px;
  width: 100%;
  z-index: 1;
`;

const GradientBorder = styled(LinearGradient)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 4px;
  z-index: 0;
`;

export const OverviewLandscape: React.FC<OverviewProps> = ({
  announcementHeaderProps,
  progressProps,
  calendarProps,
  taskListProps,
}) => {
  return (
    <Box backgroundColor={colors.white} flex={1}>
      <L1Header />
      <Scrollable>
        <PageContent>
          <AnnouncementContainer>
            <AnnouncementHeader {...announcementHeaderProps} />
          </AnnouncementContainer>
          <Section backgroundColor={colors.white}>
            <SectionMaxWidth>
              <Box marginBottom={16}>
                <Progress {...progressProps} />
              </Box>
              <Divider marginBottom={16} />
              <Calendar view="week" {...calendarProps} />
            </SectionMaxWidth>
          </Section>
          <Box height="4px" width="100%">
            <GradientBorder {...theme.common.horizontalGradient} />
          </Box>
          <Section backgroundColor={colors.agate}>
            <SectionMaxWidth>
              <TaskList hasHideButton={false} {...taskListProps} />
            </SectionMaxWidth>
          </Section>
        </PageContent>
      </Scrollable>
    </Box>
  );
};
