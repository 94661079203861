import React from 'react';
import { Box, Row, Text } from '../styled';
import { fonts, colors } from '../../constants/theme';
import { InstallmentBar, CardContainer } from './styled';
import { Divider } from '../Divider';
import { IStripeSubscription } from 'sonora-types';
import { shortYearDatestring } from 'sonora-util/dateUtils';
import { FillIcon } from '../Icon';
// import { Button } from '../Button';
import { TextButton } from './TextButton';

const INSTALLMENTS = Array(5).fill(0);

export interface MembershipDetailsCardProps {
  details?: IStripeSubscription | null;
  currentInstallment?: number;
  trialDaysRemaining?: number;
  isTrial?: boolean;
  onEdit?: () => void;
  onCancel?: () => void;
  onBuyTrial?: () => void;
}

export const MembershipDetailsCard: React.FC<MembershipDetailsCardProps> = ({
  details,
  currentInstallment = 1,
  trialDaysRemaining,
  isTrial,
  // onEdit,
  onCancel,
  // onBuyTrial,
}) => {
  const isInstallments = details?.type === 'installments';
  const billingAmount = details?.billingAmount || 0
  const totalDue = billingAmount/100;
  const totalRemaining = isInstallments ? 595 * (5 - currentInstallment) : 0;
  const isCancelled = details?.status == "canceled";

  return (
    <CardContainer>
      <Box width="100%">
        <Row justifyContent="space-between">
          <Box>
            <Text size={isTrial ? 16 : 24}>Guitar Intensive - Core access</Text>
            {isTrial && (
              <>
                <Row marginY={12}>
                  <FillIcon name="timer" size="s" />
                  <Text marginLeft={10} size={16}>
                    Free 7-day trial:
                  </Text>
                  <Text font={fonts.thin} size={16} marginLeft={4}>
                    {trialDaysRemaining} day remaining
                  </Text>
                </Row>
                <Row>
                  {/* <TextButton
                    text="Edit"
                    color={colors.neutralBlue}
                    onPress={onEdit}
                  />
                  <Box width="12px" /> */}
                  <TextButton
                    text="Cancel"
                    color={colors.alertRed}
                    onPress={onCancel}
                  />
                </Row>
              </>
            )}
          </Box>
          {/* {isTrial && (
            <Box>
              <Button type="Special" text="Buy Now" onPress={onBuyTrial} />
            </Box>
          )} */}
        </Row>
        <Divider marginBottom={24} marginTop={24} />
        <Text size={24}>Payment Plan</Text>
        { !isCancelled ? (
          <Text font={fonts.thin} size={24} marginBottom={24}>
            {isInstallments ? '5 installments' : 'Upfront'}
          </Text>
        ) : ( 
          <Text font={fonts.thin} size={24} marginBottom={24}>
            Cancelled
          </Text>
        )}
        
        <Row marginBottom={24}>
          <Box flex={1}>
            <Text font={fonts.thin} size={16}>
              Total Due
            </Text>
            { !isCancelled ? (
              <Text>
                <Text color={colors.purpley} size={24}>
                  $
                </Text>
                <Text size={24}>{totalDue}</Text>
              </Text>
            ) : (
              <Text>
                <Text color={colors.purpley} size={24}>
                  None
                </Text>
              </Text>
            )}
            
          </Box>
          {isInstallments && (
            <Box flex={1}>
              <Text font={fonts.thin} size={16}>
                Total Remaining
              </Text>
              <Text>
                <Text color={colors.purpley} size={24}>
                  $
                </Text>
                <Text size={24}>{totalRemaining}</Text>
              </Text>
            </Box>
          )}
        </Row>
        <Row marginBottom={24}>
          {isInstallments ? (
            INSTALLMENTS.map((_, i) => (
              <InstallmentBar
                key={i}
                width={43}
                color={
                  i < currentInstallment - 1
                    ? colors.amethyst
                    : i === currentInstallment - 1
                    ? colors.tanzanite
                    : colors.purpley
                }
              />
            ))
          ) : (
            <InstallmentBar width={403} color={colors.amethyst} />
          )}
        </Row>
        <Row>
          <Text size={16} marginRight={4}>
            Billing cycle:
          </Text>
          <Text font={fonts.thin} size={16}>
            {isCancelled? "cancelled" : (isInstallments ? '5 payments charged monthly' : 'once')}
          </Text>
        </Row>
        <Row>
          <Text size={16} marginRight={4}>
            {isInstallments ? 'Next payment:' : 'Billed on:'}
          </Text>
          <Text font={fonts.thin} size={16}>
            { !isCancelled ? shortYearDatestring(details?.endDate || new Date()) : "N/A"} 
          </Text>
        </Row>
      </Box>
    </CardContainer>
  );
};
