import React from 'react';

import { ResetPasswordForm, KeyValueStore } from 'sonora-design-system';
import { useSetNewPasswordWithTokenMutation } from 'sonora-graphql/types';
import { RootNavScreen } from '../screens';

export interface ResetPasswordProps {}

const ResetPassword: RootNavScreen<'ResetPassword'> = ({
  navigation,
  route,
}) => {
  const [setPasswordMutation, { error }] = useSetNewPasswordWithTokenMutation();

  const token = route.params.token;

  const handlePress = (formData: KeyValueStore) => {
    setPasswordMutation({
      variables: { password: formData.newPassword, token },
    })
      .then((result) => {
        if (result && result.data && result.data.consumeLoginToken) {
          if (result.data.consumeLoginToken.expired) {
            // the token was expired
            console.log('token expired');
          } else {
            const newToken = result.data.consumeLoginToken.token;
            if (newToken) {
              navigation.navigate('ResetPasswordSuccess');
            }
          }
        }
      })
      .catch((err) => console.warn(err));
  };
  return <ResetPasswordForm error={error?.message} onSubmit={handlePress} />;
};

export default ResetPassword;
