import React from 'react';
import { Box, Row, Text } from '../styled';
import { Button } from '../Button';
import { fonts, colors } from '../../constants/theme';

export interface TrialCancelProps {
  extended?: boolean;
  onCancel: () => void;
  onExtend: () => void;
}

export const TrialCancel: React.FC<TrialCancelProps> = ({
  extended,
  onCancel,
  onExtend,
}) => {
  return (
    <Box backgroundColor={colors.white} padding={60} alignItems="center">
      <Text font={fonts.headline} size={24}>
        Sorry to see you go
      </Text>
      <Text font={fonts.thin} size={16} marginBottom={24}>
        You're about to lose access to Sonora Guitar Intensive
      </Text>
      {!extended &&
        (<Box
          backgroundColor={colors.opal}
          borderColor={colors.agateHover}
          borderWidth={1}
          borderStyle="dashed"
          paddingY={60}
          paddingX={30}
          marginBottom={24}
          width="100%"
        >
          <Text font={fonts.thin} size={16} marginBottom={6} textAlign="center">
            Need more time?
        </Text>
          <Text size={18} color={colors.amethyst} textAlign="center">
            🎉&nbsp;&nbsp;&nbsp;Extend your free trial by 7
            days!&nbsp;&nbsp;&nbsp;🎉
        </Text>
        </Box>)
      }
      <Row justifyContent="center">
        <Button type="Secondary" text="Cancel" onPress={onCancel} />
        {!extended && (
          <>
            <Box width="12px" />
            <Button type="Special" text="Extend" onPress={onExtend} />
          </>
        )}
      </Row>
    </Box>
  );
};
