import React, { useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import { colors } from 'sonora-design-system';
import { StarRating } from 'sonora-design-system/components/StarRating';
import PlaceholderImage from 'sonora-design-system/assets/images/placeholder.png';

import { ModalPresenterContext } from 'sonora-design-system/util';
import { ProgressContext } from '../../containers/ProgressProvider';

import { RootNavScreen } from './screens';

import { VideoSubmissionStackReturnData } from './video-submission/VideoSubmissionStack';

export interface VideoSubmissionRatingProps {}

const VideoSubmissionRating: RootNavScreen<'VideoSubmissionRating'> = ({
  navigation,
}) => {
  const { dismissModal, extraData } = useContext(ModalPresenterContext);
  const { progress }  = useContext(ProgressContext)
  const previousStarRating = progress[extraData.activityId]?.currentStars || 0
  const handleSubmit = (stars: number) => {
    const returnValue: VideoSubmissionStackReturnData = {
      starRating: stars,
    };
    dismissModal('Success', returnValue);
  };

  return (
    <View style={styles.submissionScreen}>
      <StarRating
        title={'Save your progress'}
        description={'To continue, rate your skill mastery:'}
        image={PlaceholderImage}
        onSubmit={handleSubmit}
        previousStarRating={previousStarRating}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  submissionScreen: {
    flex: 1,
    backgroundColor: colors.white,
    justifyContent: 'center',
  },
});

export default VideoSubmissionRating;
