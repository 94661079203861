import gql from 'graphql-tag';

export const userInfo = gql`
  fragment User on UserType {
    id
    firstName
    lastName
    fullName @client
    userType
    email
    stripeUserState {
      stripeUserData
    }
  }
`;

export const curriculum = gql`
  fragment Curriculum on CurriculumType {
    id
    title
    body
  }
`;

export const exerciseTitle = gql`
  fragment ExerciseTitle on ExerciseType {
    id
    lesson {
      id
      title
      lessonGroup {
        id
        title
      }
    }
  }
`;

/**
 * this represents a completed uploaded attachment
 * may need to differentiate between this and a
 * recently created attachment which has other fields to use
 */
export const attachmentInfo = gql`
  fragment MessageAttachmentDownloadInfo on MessageAttachmentType {
    id
    complete
    s3Url
    s3ThumbnailUrl
  }
`;

export const messageWithAttachments = gql`
  fragment MessageWithAttachments on MessageType {
    id
    contents
    complete
    createdAt
    read
    sender {
      id
      userType
      ...User
    }

    attachments {
      ...MessageAttachmentDownloadInfo
    }
  }
  ${attachmentInfo}
  ${exerciseTitle}
  ${userInfo}
`;

// export const messageThreadChatMessages

/**
 * Gets all attachments (2020-07-09 only new-style message attachments)
 * for a message thread
 */
export const messagesWithAttachments = gql`
  fragment MessagesWithAttachments on MessageThreadType {
    id
    messages {
      ...MessageWithAttachments
    }
  }
  ${messageWithAttachments}
`;

export const messageFragment = gql`
  fragment Message on MessageType {
    id
    contents
    complete
    createdAt
    read
    sender {
      id
      ...User
    }
  }
  ${userInfo}
`;

export const messageThreadMentorHome = gql`
  fragment MentorHomeMessageThread on MessageThreadType {
    id
    lastActivity
    lastMessage {
      id
      contents
      attachments {
        ...MessageAttachmentDownloadInfo
      }
    }
    mentor {
      id
      user {
        id
        ...User
      }
    }
    student {
      id
      studentType
      level
      user {
        ...User
      }
    }
  }

  ${userInfo}
  ${attachmentInfo}
`;

export const mentorHomeRelationshipInfo = gql`
  fragment MentorHomeRelationshipInfo on StudentMentorRelationshipType {
    id
    startedAt
    endedAt
    unreadCount
    messageThread {
      ...MentorHomeMessageThread
    }
  }
  ${messageThreadMentorHome}
`;

export const exerciseProgress = gql`
  fragment ExerciseProgress on ExerciseProgressType {
    rating
    submissionComplete
    startedAt
    endedAt
    s3Url
  }
`;
