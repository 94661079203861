import React from 'react';
import { TouchableOpacity } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import styled from 'styled-components/native';

import { Box, Row, Text } from '../styled';
import { FillIcon } from '../Icon';
import { theme } from '../../constants/theme';

import { PageHeaderProps } from './types';

const GradientBorder = styled(LinearGradient)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 4px;
  z-index: 0;
`;

export const PageHeader: React.FC<PageHeaderProps> = ({
  backgroundColor,
  children,
  hasCta,
  ctaIcon,
  ctaTitle,
  ctaColor,
  hasBorder,
  minHeight,
}) => {
  return (
    <Box
      position="relative"
      backgroundColor={backgroundColor}
      minHeight={minHeight}
    >
      {hasCta && (
        <Box
          position="absolute"
          height="100%"
          justifyContent="center"
          left="12px"
        >
          <TouchableOpacity>
            <Row>
              {ctaIcon ? <FillIcon name={ctaIcon} color={ctaColor} /> : null}
              {ctaTitle ? <Text>{ctaTitle}</Text> : null}
            </Row>
          </TouchableOpacity>
        </Box>
      )}
      {children}
      {hasBorder && (
        <Box height="4px" width="100%">
          <GradientBorder {...theme.common.horizontalGradient} />
        </Box>
      )}
    </Box>
  );
};
