import React, { useCallback, useState } from 'react';
import { View, TouchableOpacity, StyleSheet, Modal } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useIsFocused, useFocusEffect } from '@react-navigation/native';
import { colors, FillIcon } from 'sonora-design-system';
import { RootNavScreen } from './screens';
import {
  asyncLockPortraitExceptIpad,
  asyncLockLandscapeExceptIpad,
} from 'sonora-hooks/useMobileOrientationLock';
import VideoRecord from './video-submission/VideoRecord';
import VideoReview from './video-submission/VideoReview';

export interface MobileSubmissionVideoProps {}

const MobileSubmissionVideo: RootNavScreen<'MobileSubmissionVideo'> = ({
  navigation,
  route,
}) => {
  const [recordedUri, setRecordedUri] = useState<string | undefined>(undefined);

  const isFocused = useIsFocused();
  const insets = useSafeAreaInsets();
  const maxInset = Math.max(
    insets.right,
    insets.left,
    insets.top,
    insets.bottom
  );

  useFocusEffect(
    useCallback(() => {
      asyncLockLandscapeExceptIpad();
      return () => {
        asyncLockPortraitExceptIpad();
      };
    }, [])
  );

  let uri = route.params.uri || recordedUri;

  const handleClose = () => {
    if (recordedUri) {
      setRecordedUri(undefined);
    } else {
      navigation.goBack();
    }
  };

  return (
    <Modal visible={isFocused} supportedOrientations={['landscape-right']}>
      <View style={styles.screenContainer}>
        <TouchableOpacity
          style={[styles.closeButton, { top: maxInset, left: maxInset }]}
          onPress={handleClose}
        >
          <FillIcon name="x" color={colors.white} />
        </TouchableOpacity>
        {uri ? (
          <VideoReview uri={uri} />
        ) : (
          <VideoRecord setRecordedUri={setRecordedUri} />
        )}
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  screenContainer: { flex: 1, backgroundColor: colors.black },
  closeButton: { position: 'absolute', zIndex: 1 },
});

export default MobileSubmissionVideo;
