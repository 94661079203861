import React, { useContext } from 'react';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import { format } from 'date-fns';

import { isDevelopment } from '../../../src/util/environment';

import { colors } from '../../constants/theme';
import { Text } from '../styled';
import { DateContext } from '../../../src/containers/DateProvider';

interface CellWrapperProps {
  color?: string;
  hasBorder?: boolean;
}
const CellWrapper: React.FC<CellWrapperProps> = styled.View`
  border-radius: 18px;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  ${(props: CellWrapperProps) =>
    props.color ? `background-color: ${props.color};` : ''}
  ${(props: CellWrapperProps) =>
    props.hasBorder
      ? `
    border-width: 1px;
    border-color: ${colors.tanzanite};
    `
      : ''}
`;

interface Props {
  timestamp: number;
  disabled?: boolean;
  isToday?: boolean;
  highlighted?: boolean;
}

export const CalendarCell: React.FC<Props> = ({
  timestamp,
  disabled,
  isToday,
  highlighted,
}) => {
  const fontColor =
    highlighted && !disabled
      ? colors.white
      : disabled
      ? colors.purpley
      : colors.tanzanite;
  const backgroundColor =
    highlighted && !disabled
      ? colors.tanzanite
      : highlighted
      ? colors.agate
      : '';

  const PlainCalendarCell = () => (
    <CellWrapper color={backgroundColor} hasBorder={isToday && !highlighted}>
      <Text color={fontColor}>{format(timestamp, 'dd')}</Text>
    </CellWrapper>
  );

  // DEV ONLY - make cells a button allowing you to set the date
  if (isDevelopment()) {
    const { setDate } = useContext(DateContext);
    return (
      <TouchableOpacity onPress={() => setDate(new Date(timestamp))}>
        <PlainCalendarCell />
      </TouchableOpacity>
    );
  }

  return <PlainCalendarCell />;
};
