import React from 'react';
import { TouchableOpacity } from 'react-native';
import { GradientBorder } from '../GradientBorder';
import { Text, Row, Box } from '../styled';
import { colors, fonts } from '../../constants/theme';
import { Divider } from '../Divider';
import { FillIcon } from '../Icon';
import { shortYearDatestring } from 'sonora-util/dateUtils';
import { IStripeSubscription } from 'sonora-types';
import { TextButton } from './TextButton';
import { Button } from '../Button';

export interface PremiumSubscriptionCardProps {
  details: IStripeSubscription;
  onPress?: () => void;
  onPause?: () => void;
  onUnpause?: () => void;
  onResubscribe?: () => void;
  onCancel?: () => void;
  onEditBillingCycle?: () => void;
}

export const PremiumSubscriptionCard: React.FC<PremiumSubscriptionCardProps> = ({
  details,
  onPress,
  onPause,
  onUnpause,
  onCancel,
  onResubscribe,
  // onEditBillingCycle,
}) => {
  return (
    <TouchableOpacity activeOpacity={1} onPress={onPress}>
      <GradientBorder
        borderWidth={1}
        outerBorderRadius={4}
        innerBorderRadius={3}
        paddingHorizontal={24}
        paddingVertical={24}
        backgroundColor={colors.white}
        marginBottom={12}
        clickable
      >
        <Text size={16} marginBottom={6}>
          Premium Mentorship subscription
        </Text>
        <Text font={fonts.thin} size={16} marginBottom={12}>
          {details.description}
        </Text>
        {details.status === 'canceled' ? (
          <Row marginBottom={24}>
            <Button type="Special" text="Resubscribe" onPress={onResubscribe} />
          </Row>
        ) : (
          <Row marginBottom={24}>
            {details.paused ? (
              <TextButton
                color={colors.neutralBlue}
                text="Unpause"
                onPress={onUnpause}
              />
            ) : (
              <TextButton
                color={colors.neutralBlue}
                text="Pause"
                onPress={onPause}
              />
            )}
            <Box width="12px" />
            <TextButton
              color={colors.alertRed}
              text="Cancel"
              onPress={onCancel}
            />
          </Row>
        )}
        <Divider />
        <Row justifyContent="space-between" marginTop={24}>
          <Box>
            <Row>
              <Text marginRight={4} size={16}>
                Payment plan:
              </Text>
              <Text font={fonts.thin} size={16}>
                reocurring
              </Text>
            </Row>
            <Row>
              <Text marginRight={4} size={16}>
                Billing cycle:
              </Text>
              <Text font={fonts.thin} size={16} marginRight={10}>
                every 4 weeks
              </Text>
              {/* <TextButton
                color={colors.neutralBlue}
                text="Edit"
                onPress={onCancel}
              /> */}
            </Row>
            <Row>
              <Text marginRight={4} size={16}>
                Next payment:
              </Text>
              <Text font={fonts.thin} size={16}>
                {shortYearDatestring(details.endDate)}
              </Text>
            </Row>
          </Box>
          <Row>
            <Text color={colors.purpley} size={24}>
              $
            </Text>
            <Text color={colors.tanzanite} size={24} marginRight={5}>
              {details.billingAmount/100}
            </Text>
            <FillIcon name="pageRight" />
          </Row>
        </Row>
      </GradientBorder>
    </TouchableOpacity>
  );
};
