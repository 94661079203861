// author: @fullstackdevs14
import React from 'react';
import styled from 'styled-components/native';

import { colors } from '../../constants/theme';
import { Text, Row } from '../styled';

const TextContainer = styled.View`
  padding: 5px 12px;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  background-color: ${(props: { backgroundColor: string }) =>
    props.backgroundColor};
`;

const Separator = styled.View`
  position: absolute;
  top: 50%;
  width: 100%;
  height: 1px;
  background-color: ${colors.purpley};
  z-index: 0;
`;

interface Props {
  backgroundColor: string;
  title: string;
  alignment?: 'flex-start' | 'center' | 'flex-end';
  textColor?: string;
  paddingBottom?: number;
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
}

export const SectionHeader: React.FC<Props> = ({
  backgroundColor,
  title,
  alignment = 'center',
  textColor,
  paddingBottom = 12,
  paddingTop,
  paddingLeft,
  paddingRight,
}) => {
  return (
    <Row
      justifyContent={alignment}
      marginBottom={paddingBottom}
      marginTop={paddingTop}
      marginLeft={paddingLeft}
      marginRight={paddingRight}
      position="relative"
    >
      <Separator />
      <TextContainer backgroundColor={backgroundColor}>
        <Text size={12} color={textColor}>
          {title}
        </Text>
      </TextContainer>
    </Row>
  );
};
