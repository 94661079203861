import React, { forwardRef } from 'react';
import { Platform } from 'react-native';
import { WebView as WebViewMobile } from 'react-native-webview';
import { default as WebViewWeb } from 'react-native-web-webview';
import { Box } from '../styled';
import { colors } from '../../constants/theme';
import { SoundsliceProps } from './types';

const WebView = Platform.OS == 'web' ? WebViewWeb : WebViewMobile;

export const Soundslice = forwardRef<JSX.Element, SoundsliceProps>(
  (props, ref) => {
    const { url } = props;
    const fullUrl =
      url +
      '?api=1&branding=2&top_controls=1&enable_mixer=0&synth_display_name=Piano';

    return (
      <Box flex={1} position="relative" marginBottom={-30}>
        <WebView
          ref={ref}
          style={{ flex: 1 }}
          javaScriptEnabled={true}
          domStorageEnabled={true}
          startInLoadingState={true}
          source={{ uri: fullUrl }}
          allowsInlineMediaPlayback={true}
        />
        <Box
          width="100%"
          height="38px"
          position="absolute"
          backgroundColor={colors.white}
          right="0px"
          bottom="0px"
          zIndex={1}
        />
      </Box>
    );
  }
);
