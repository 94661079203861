import React from 'react';
import { InputToolbar, InputToolbarProps } from 'react-native-gifted-chat';

export const RenderInputToolbar: React.FC<InputToolbarProps> = (props) => {
  return (
    <InputToolbar
      {...props}
      primaryStyle={{
        alignItems: 'stretch',
        padding: 12,
        maxWidth: 580,
        width: '100%',
        marginHorizontal: 'auto',
      }}
    />
  );
};
