import { AsyncStorage } from 'react-native';
import ApolloClient from 'apollo-client';

import { AUTH_TOKEN_KEY } from 'sonora-util/environment';
import {
  useSignInUserMutation,
  useLogInWithUuidMutation,
} from 'sonora-graphql/types';
import { useApolloClient } from 'sonora-hooks/useApolloClient';
import { clearUser, trackEvent } from 'sonora-services/analytics';
import { handleWarning } from 'sonora-services/analytics';

async function signInUsingToken(
  token: string,
  client: ApolloClient<object> | undefined,
  onSuccess: () => void
) {
  AsyncStorage.setItem(AUTH_TOKEN_KEY, token).then(
    () => client && client.clearStore().then(onSuccess)
  );
}

export const useSignIn = () => {
  const [signIn, { client }] = useSignInUserMutation();
  const signInAndSaveToken = (email: string, password: string) =>
    new Promise((resolve, reject) =>
      signIn({ variables: { email, password } })
        .then((result) => {
          if (
            result &&
            result.data &&
            result.data.tokenAuth &&
            result.data.tokenAuth.token
          ) {
            signInUsingToken(result.data.tokenAuth.token, client, () =>
              resolve(true)
            );
          }
        })
        .catch((err) => reject(err))
    );

  return signInAndSaveToken;
};

export const useLoginWithToken = () => {
  const [logInWithUuidMutation, { client }] = useLogInWithUuidMutation();
  const logInWithToken = (token: string) =>
    new Promise((resolve, reject) => {
      logInWithUuidMutation({ variables: { uuid: token } })
        .then((result) => {
          if (result && result.data && result.data.consumeLoginToken) {
            if (result.data.consumeLoginToken.expired) {
              handleWarning('Login attempted with expired token');
            } else {
              const newToken = result.data.consumeLoginToken.token;
              if (newToken) {
                signInUsingToken(newToken, client, () => resolve(true));
              }
            }
          }
        })
        .catch((err) => reject(err));
    });

  return logInWithToken;
};

export const useSignOut = () => {
  const { client } = useApolloClient();

  return () =>
    new Promise((resolve, reject) => {
      trackEvent('sign-out-initiated');
      AsyncStorage.removeItem(AUTH_TOKEN_KEY)
        .then(() => {
          if (!client)
            throw new Error('tried to log out but apollo client is nullish');

          client.clearStore().then(() => {
            clearUser();
            trackEvent('sign-out-complete');
            resolve(true);
          });
        })
        .catch((err) => reject(err));
    });
};
