import React, { useContext } from 'react';

import { View, StyleSheet } from 'react-native';
import { colors } from 'sonora-design-system';
import { ModificationsCategory } from 'sonora-design-system/components/ModificationsCategory';
import {
  ModificationId,
  ModificationCategory,
  ModificationCategoryId,
} from 'sonora-design-system/components/ModificationDetailView/types';
import { CategoryViewProps } from 'sonora-design-system/components/ModificationNavigation/types';
import { ModificationsProviderContext } from './ModificationsProvider';
import { L2NavigationContext } from '../../levels/L2NavigationProvider';
import { StudentContext, ProgressContext } from 'sonora-containers/index';
import {
  parseDbCategories,
  parseDbModifications,
} from 'sonora-util/curriculaUtil';
import { LearningActivity } from 'sonora-types';
import { ModalPresenterContext } from 'sonora-design-system/util/ModalProvider';
import { VimeoModal } from 'sonora-design-system/components/VimeoModal';

export const CategoryView: React.FC<CategoryViewProps> = ({
  navigation,
  route,
}) => {
  const {
    activeModificationsMap,
    setActiveModificationsMap,
    nodeId,
  } = useContext(ModificationsProviderContext);
  const { setActiveIndex } = useContext(L2NavigationContext);
  const { curriculum, curriculumMap } = useContext(StudentContext);
  const { presentComponentAsModal } = useContext(ModalPresenterContext);
  const { unlockedModificationsMap } = useContext(ProgressContext);

  if (curriculumMap[nodeId].type === 'Folder') {
    throw new Error('Node folder should never render category');
  }

  let node: LearningActivity = curriculumMap[nodeId] as LearningActivity;

  if (!node.modifications) {
    throw new Error(
      'Node without modifications should never render this drawer'
    );
  }

  const allModifications = parseDbModifications(curriculum.modifications);
  const allCategories = parseDbCategories(curriculum.modificationCategories);

  let modificationCategories = allCategories.filter((category) =>
    Object.keys(node.modifications!).includes(category.id.toString())
  );

  modificationCategories.forEach((category) => {
    if (unlockedModificationsMap.categoryIds.includes(category.id)) {
      category.disabled = false;
    } else {
      category.disabled = true;
    }
  });

  const handleRemoveModification = (modification: ModificationId) => {
    let activeCategory = Object.keys(activeModificationsMap)
      .map(Number)
      .find(
        (category: ModificationCategoryId) =>
          activeModificationsMap[category] === modification
      );

    if (activeCategory) {
      let res = Object.assign({}, activeModificationsMap); // Don't mutate context
      delete res[activeCategory];
      setActiveModificationsMap(res);
    }
  };

  const handlePressActiveModification = (modificationId: ModificationId) => {
    const category = modificationCategories.find(
      (c: ModificationCategory) => c.modifications.indexOf(modificationId) >= 0
    );

    if (category) {
      navigation.push('ModificationDetails', {
        categoryId: category?.id,
      });
    }
  };

  const handlePressCategory = (category: ModificationCategory) => {
    navigation.push('ModificationDetails', {
      categoryId: category.id,
    });
  };

  const handleHide = () => {
    setActiveIndex(undefined);
  };

  const showVimeoModal = (vimeoId: string) => {
    if (vimeoId) {
      presentComponentAsModal(VimeoModal, {
        extraData: {
          vimeoId,
        },
        height: 'auto',
      });
    }
  };

  return (
    <View style={styles.container}>
      <ModificationsCategory
        activeModificationsMap={activeModificationsMap}
        categories={modificationCategories}
        modifications={allModifications}
        modificationHeaderBackground={colors.agate}
        onRemoveActiveModification={handleRemoveModification}
        onPressActiveModification={handlePressActiveModification}
        onPlayActiveModificationVideo={showVimeoModal}
        onHide={handleHide}
        onPressCategory={handlePressCategory}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.agate,
    flex: 1,
  },
});
