import React, { useContext } from 'react';
import { View, StyleSheet } from 'react-native';

import { colors, fonts, ModalFunctionComponent } from 'sonora-design-system';
import {
  ProgressContext,
  TaskListContext,
  StudentContext,
} from 'sonora-containers/index';
import { CourseCompletion } from 'sonora-design-system/components/CourseCompletion';
import { NiceWork } from 'sonora-design-system/components/NiceWork';

import { NodeId } from 'sonora-types';

import { useNavigation } from '@react-navigation/native';
import { useTrackEventOnMount } from 'sonora-hooks/useTrackEventOnMount';

export interface ExerciseCompleteProps {}
export interface ExerciseCompleteReturnData {
  nextNodeId?: NodeId;
}

export const ExerciseComplete: ModalFunctionComponent = ({ dismissModal }) => {
  const {
    user: { firstName },
    curriculumMap,
  } = useContext(StudentContext);
  const { availableTasks } = useContext(TaskListContext);
  const { completedNodes } = useContext(ProgressContext);

  const isCourseCompleted =
    completedNodes.length === Object.values(curriculumMap).length;

  if (isCourseCompleted) {
    return <CourseCompletion name={firstName} />;
  }

  const navigation = useNavigation();
  // Prioritize Review tasks for next task
  const nextTask = [
    ...availableTasks.filter((t) => t.headerType === 'Review'),
    ...availableTasks.filter((t) => t.headerType !== 'Review'),
  ][0];
  const nextActivityId = nextTask ? nextTask.practiceNodeId : undefined;
  useTrackEventOnMount('mount-exercise-complete', { nextTask });
  if (!nextActivityId) {
    return (
      <View style={styles.screen}>
        <View style={styles.screenInner}></View>
      </View>
    );
  }

  const handleNext = () => {
    const returnData: ExerciseCompleteReturnData = {
      nextNodeId: nextActivityId,
    };
    dismissModal('Success', returnData);
  };

  const goHome = () => {
    dismissModal('Cancel');
    navigation.navigate('L1');
  };

  return (
    <View style={styles.screen}>
      <NiceWork
        studentName={firstName}
        nextActivity={nextTask}
        noTaskMessage="Celebrate! Nothing remaining to practice today"
        onCtaPress={handleNext}
        onHomePress={goHome}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  screenInner: {
    width: 370,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headline: {
    fontFamily: fonts.headline,
    fontSize: 32,
    lineHeight: 36,
    color: colors.tanzanite,
    textAlign: 'center',
  },
  text: {
    marginTop: 36,
    marginBottom: 24,
    fontFamily: fonts.thin,
    color: colors.tanzanite,
    fontSize: 20,
    lineHeight: 26,
    textAlign: 'center',
  },
});
