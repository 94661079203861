import React from 'react';
import { storiesOf } from '@storybook/react-native';
import { FillIcon, StrokeIcon } from '.';
import { View } from 'react-native';

const iconStories = storiesOf('Icon', module);

const makeIconStories = (type: 'fill' | 'stroke', iconNames: string[]) => {
  const IconComponent = type === 'fill' ? FillIcon : StrokeIcon;
  iconNames.forEach((iconName) => {
    iconStories.add(iconName, () => (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <IconComponent name={iconName} />
      </View>
    ));
  });
};

const makeFillIconStories = (...iconNames: string[]) =>
  makeIconStories('fill', iconNames);
const makeStrokeIconStories = (...iconNames: string[]) =>
  makeIconStories('stroke', iconNames);

makeStrokeIconStories('account', 'caret', 'check', 'starNo');
makeFillIconStories(
  'alert',
  'addVideo',
  'camera',
  'checkCircle',
  'drone',
  'flydown',
  'globe',
  'hide',
  'hideDown',
  'history',
  'home',
  'lessonMap',
  'lessonOverview',
  'lock',
  'mentor',
  'menu',
  'metronome',
  'notes',
  'modifications',
  'pageLeft',
  'pageRight',
  'prompt',
  'review',
  'settings',
  'soundslice',
  'starYes',
  'streak',
  'timer',
  'video',
  'x'
);
