import React from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import styled from 'styled-components/native';

import { Box, Row, Text } from '../styled';
import { Button } from '../Button';
import { theme } from '../../constants/theme';
import { HeaderContainerProps, AnnouncementHeaderProps } from './types';
import { colors } from '../../constants/theme';
import { SecondaryCta } from './SecondaryCta';
import { SquiggleImage } from './SquiggleImage';
import { AnnouncementHeadline, AnnouncementDescription } from './shared';

const HeaderContainer: React.FC<HeaderContainerProps> = styled.View`
  padding: 20px 36px;
  border-radius: ${theme.common.borderRadius};
  background-color: ${(props: HeaderContainerProps) => props.backgroundColor};
`;

export const AnnouncementHeaderWeb: React.FC<AnnouncementHeaderProps> = ({
  headerStyle,
  headerLargeText,
  headerSmallText,
  ctaButtonText,
  specialComponent,
  onPress,
}) => {
  const backgroundColor =
    headerStyle === 'ruby' ? colors.ruby : colors.amethyst;

  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <HeaderContainer backgroundColor={backgroundColor}>
        <Row justifyContent="space-between">
          <Box>
            <Row marginBottom={12}>
              <Text size={13.2} marginRight={12} color={colors.thulite}>
                Announcements
              </Text>
              <SquiggleImage headerStyle={headerStyle} />
            </Row>
            <Row>
              <AnnouncementHeadline size="web">
                {headerLargeText}
              </AnnouncementHeadline>
              <AnnouncementDescription size="web">
                {headerSmallText}
              </AnnouncementDescription>
              {specialComponent || null}
            </Row>
          </Box>
          {headerStyle === 'ruby' ? (
            <Button type="Special" onPress={onPress} text={ctaButtonText} />
          ) : (
            <SecondaryCta onPress={onPress} text={ctaButtonText} />
          )}
        </Row>
      </HeaderContainer>
    </TouchableWithoutFeedback>
  );
};
