import React from 'react';
import { storiesOf } from '@storybook/react-native';
import styled from 'styled-components/native';

import { Task } from 'sonora-types';
import { TaskList } from '.';
import { TaskListProps } from './types';
import { colors } from '../../constants/theme';
import { fiveFormsTasks } from './mock-data';

const tasklistStories = storiesOf('TaskList', module);

const StoryContainer = styled.ScrollView`
  padding: 20px;
  background-color: ${colors.agate};
  flex: 1;
`;

interface StoryProps {
  data: TaskListProps;
  additionalProps?: any;
}

const ListStory: React.FC<StoryProps> = ({ data, additionalProps }) => {
  const [selectedTaskId, setSelectedTaskId] = React.useState('');

  return (
    <StoryContainer>
      <TaskList
        {...data}
        {...additionalProps}
        currentTaskId={selectedTaskId}
        onSelectItem={(task: Task) => {
          console.log(task);
          setSelectedTaskId(task.practiceNodeId);
        }}
        onSkipItem={(task: Task) => {
          console.log('skip: ', task);
        }}
      />
    </StoryContainer>
  );
};

const makeListStory = (data: TaskListProps, additionalProps?: any) => () => (
  <ListStory data={data} additionalProps={additionalProps} />
);

tasklistStories.add(
  'fiveFormsTasks',
  makeListStory(fiveFormsTasks, {
    width: '400px',
  })
);
