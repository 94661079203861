import React, { useState } from 'react';
import { ImageSourcePropType } from 'react-native';
import styled from 'styled-components/native';

import { Button } from '../Button';
import { Box, Row, Text } from '../styled';
import { fonts, colors } from '../../constants/theme';
import { FillIcon } from '../Icon';
import {
  convertHexToRGBA,
  useIsWidthLessThan,
} from 'sonora-design-system/util';
import { StarIcon } from './StarIcon';
import { getStarColor } from '../RatingStar/utils';

// const StyledImage = styled.Image`
//   width: 136px;
//   height: 136px;
//   border-radius: 68px;
//   z-index: 1;
// `;

const MasteryPill = styled.View`
  flex-direction: row;
  border-radius: 14px;
  padding: 2px 12px;
  align-items: center;
  background-color: ${colors.opalHover};
  margin-bottom: 10px;
`;

const starKey: any = {
  1: {
    mastery: 'Needs work',
    reviewIn: 'you will review again today',
  },
  2: {
    mastery: 'Still choppy',
    reviewIn: 'you will review tomorrow',
  },
  3: {
    mastery: 'Room for improvement',
    reviewIn: 'you will review in 1-2 days',
  },
  4: {
    mastery: 'So close!',
    reviewIn: 'you will review in 3 days',
  },
  5: {
    mastery: 'Nailed it!',
    reviewIn: 'you will review in 1 week',
  },
  6: {
    mastery: 'Nailed it!',
    reviewIn: 'you will review in 2-3 weeks',
  },
  7: {
    mastery: 'Nailed it!',
    reviewIn: 'you will review in 3-4 weeks',
  },
  8: {
    mastery: 'Nailed it!',
    reviewIn: 'you will review again in 6-10 weeks',
  },
  9: {
    mastery: 'Final Review!',
    reviewIn: 'You will no longer receive this item in spaced repetition, but you can always find it in the course map.',
  }
};

interface StarRatingProps {
  title: string;
  description: string;
  image: ImageSourcePropType;
  onSubmit?: (stars: number) => void;
  previousStarRating: number;
}

const starIndexArray = [1, 2, 3, 4, 5];

export const StarRating: React.FC<StarRatingProps> = ({ onSubmit, previousStarRating }) => {
  const [stars, selectStars] = useState(0);
  const [hovered, setHovered] = useState(-1);
  const relativeStars = (previousStarRating >= 5 && stars == 5) ? ((previousStarRating + 1) > 8 ? 9 : previousStarRating + 1) : stars

  const info = starKey[relativeStars] || {};
  const description = `${info.mastery} (${info.reviewIn})`;
  const isSmall = useIsWidthLessThan(500);

  return (
    <Box
      alignItems="center"
      backgroundColor={colors.white}
      padding={isSmall ? 30 : 60}
    >
      <Text font={fonts.headline} size={32} marginBottom={6} textAlign="center">
        Save your progress
      </Text>
      <Text font={fonts.thin} size={20} marginBottom={36} textAlign="center">
        To continue, rate your skill mastery:
      </Text>
      <Box
        backgroundColor={colors.opal}
        alignItems="center"
        paddingTop={isSmall ? 24 : 48}
        padding={isSmall ? 16 : 32}
        width="100%"
      >
        <Row
          justifyContent="space-between"
          flexWrap="wrap"
          marginBottom={24}
          width="100%"
          maxWidth={280}
        >
          {starIndexArray.map((index) => (
            <StarIcon
              color={index <= stars ? getStarColor(stars) : getStarColor(0)}
              key={index}
              isColored={index <= stars}
              isHovered={hovered === index}
              size={isSmall ? 'l' : 'sr'}
              onHover={() => setHovered(index)}
              onPress={() => selectStars(index)}
            />
          ))}
        </Row>
        <MasteryPill>
          <FillIcon name="mastery" />
          <Text font={fonts.bold} size={18} marginLeft={6} marginTop={-2}>
            Mastery
          </Text>
        </MasteryPill>
        {stars > 0 ? (
          <Text font={fonts.thin} size={18} textAlign="center">
            {description}
          </Text>
        ) : (
            <Text
              font={fonts.thin}
              size={18}
              color={convertHexToRGBA(colors.tanzanite, 60)}
            >
              (Select a star rating)
            </Text>
          )}
      </Box>
      <Row justifyContent="center" marginTop={36}>
        <Button
          type="Primary"
          text="Submit Rating"
          disabled={stars < 1 || stars > 5}
          onPress={() => {
            if (onSubmit) {
              onSubmit(stars);
            }
          }}
        />
      </Row>
    </Box>
  );
};
