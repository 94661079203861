import React, { useState } from 'react';
import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { Box, Row, Text } from '../styled';
import { colors, fonts } from '../../constants/theme';
import { CardIcon } from '../Icon/CardIcon';
import { Button } from '../Button';
// import { Checkbox } from '../Checkbox';
import {
  useCreateAndBindPaymentMethodMutation,
} from 'sonora-graphql/types';


interface StyledTextInputProps {
  fill?: boolean;
  width?: number;
  marginLeft?: number;
  textCenter?: boolean;
  placeholder?: string;
  value: string;
  onChangeText: (text: string) => void;
}

const StyledTextInput: React.FC<StyledTextInputProps> = styled.TextInput`
  font-family: ${fonts.thin};
  font-size: 18px;
  color: ${colors.tanzanite};
  text-transform: uppercase;
  ${Platform.OS === 'web' ? 'outline-width: 0;' : ''}
  ${(props: StyledTextInputProps) =>
    props.fill ? `flex: 1;` : `flex: none;`}
  ${(props: StyledTextInputProps) =>
    props.width && `width: ${props.width}px;`}
  ${(
    props: StyledTextInputProps
  ) => props.marginLeft && `margin-left: ${props.marginLeft}px;`}
  ${(
    props: StyledTextInputProps
  ) => props.textCenter && `text-align: center;`}
`;

export const StripeForm = () => {
  // const [defaultMethod, setDefaultMethod] = useState(false);
  const [number, setNumber] = useState('');
  const [expDate, setExpDate] = useState('');
  const [cvc, setCVC] = useState('');
  const [
    createAndBindPaymentMethodMutation
  ] = useCreateAndBindPaymentMethodMutation()


  const handleSubmit = () => {
    // TODO: Check to make sure everything is there and valid
    createAndBindPaymentMethodMutation({
      variables: { cvc, expMonth: expDate.split("/")[0], expYear: expDate.split("/")[1], number},
      refetchQueries: ['CurrentUser'],
    }).then(({ data }) => {
      if (data?.createAndBindPaymentMethod?.ok) {
        console.log('Payment Method Added ');
      }
    });
  };

  const handleCardNumber = (text: string) => {
    const newText = text.replace(/[^0-9]/g, '').slice(0, 19);
    const numArr = [];
    for (let i = 0; i < newText.length; i += 4) {
      numArr.push(newText.substr(i, 4));
    }
    setNumber(numArr.join(' '));
  };

  const handleExpDate = (text: string) => {
    const newText = text
      .replace(/[\/]/g, '')
      .replace(/[^0-9]/g, '')
      .slice(0, 4);
    const expiredAt =
      newText.length > 2
        ? newText.slice(0, 2) + '/' + newText.slice(2)
        : newText;
    setExpDate(expiredAt);
  };

  const handleCVC = (text: string) => {
    const newText = text.replace(/[^0-9]/g, '').slice(0, 4);
    setCVC(newText);
  };

  return (
    <Box
      backgroundColor={colors.white}
      borderColor={colors.dividerGray}
      borderRadius="4px"
      borderWidth={1}
      borderStyle="solid"
      padding={24}
    >
      <Text size={18} marginBottom={12}>
        Add credit or debit card
      </Text>
      <Row
        padding={12}
        alignItems="center"
        marginBottom={6}
        borderRadius="4px"
        borderWidth={1}
        borderColor={colors.tanzanite}
        borderStyle="solid"
      >
        <Box flex="none">
          <CardIcon />
        </Box>
        <Box width="12px" flex="none" />
        <StyledTextInput
          fill
          placeholder="Card number"
          value={number}
          onChangeText={handleCardNumber}
        />
        <StyledTextInput
          width={70}
          textCenter
          marginLeft={12}
          placeholder="MM/YY"
          value={expDate}
          onChangeText={handleExpDate}
        />
        <StyledTextInput
          width={44}
          textCenter
          marginLeft={12}
          placeholder="CVC"
          value={cvc}
          onChangeText={handleCVC}
        />
      </Row>
      <Box marginBottom={6}>
        <Button text="Pay Now" onPress={handleSubmit} />
      </Box>
      {/* <Box marginBottom={24}>
        <Checkbox
          // checked={defaultMethod}
          // onChange={setDefaultMethod}
          label="Make this my default payment method"
          disabled
        />
      </Box> */}
    </Box>
  );
};
