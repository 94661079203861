import React from 'react';
import { colors, fonts } from '../../constants/theme';
import { Box, Text } from '../styled';

export interface AvatarProps {
  firstname?: string;
  lastname?: string;
}

export const Avatar: React.FC<AvatarProps> = ({ firstname, lastname }) => {
  const initial = (
    (firstname || '').trim().slice(0, 1) + (lastname || '').trim().slice(0, 1)
  ).toUpperCase();

  if (!initial) return null;

  return (
    <Box
      marginRight={12}
      borderRadius="50%"
      width="30px"
      height="30px"
      backgroundColor={colors.amethyst}
      alignItems="center"
      justifyContent="center"
    >
      <Text color={colors.thulite} size={10} font={fonts.bold}>
        {initial}
      </Text>
    </Box>
  );
};
