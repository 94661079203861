import React, { FC, useContext } from 'react';
import { View, StyleSheet, Image } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { LayoutProviderContext, colors } from 'sonora-design-system';
import frameTopSrc from 'sonora-design-system/assets/images/frame/MobileFrameTop.png';
import { L1FrameLeft } from './L1FrameLeft';

export interface L1FrameProps {}

const L1Frame: FC<L1FrameProps> = (props) => {
  const insets = useSafeAreaInsets();
  const { screenWidth } = useContext(LayoutProviderContext);

  return (
    <View style={styles.frame}>
      <View>
        <Image
          source={frameTopSrc}
          style={[
            styles.frameTop,
            {
              height: insets.top + 12,
              width: screenWidth,
            },
          ]}
        />
      </View>
      <View style={{ marginTop: insets.top + 12 }}>
        <L1FrameLeft />
        {props.children}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  frame: {
    backgroundColor: colors.opal,
  },
  frameTop: {
    position: 'absolute',
    top: 0,
  },
});

export default L1Frame;
