import React from 'react';
import { Divider } from '../Divider';
import { Box, Row, Text } from '../styled';
import { colors, fonts } from '../../constants/theme';
import { CardContainer } from './styled';
import { IStripeSubscription } from 'sonora-types';
import { shortYearDatestring } from 'sonora-util/dateUtils';
import { TextButton } from './TextButton';

export interface PremiumSubscriptionDetailsCardProps {
  details?: IStripeSubscription | null;
  onPause?: () => void;
  onCancel?: () => void;
  onEditBillingCycle?: () => void;
}

export const PremiumSubscriptionDetailsCard: React.FC<PremiumSubscriptionDetailsCardProps> = ({
  details,
  onPause,
  onCancel,
  onEditBillingCycle,
}) => {
  return (
    <CardContainer>
      <Box width="100%">
        <Text size={24} marginBottom={12}>
          Fellowship subscription
        </Text>
        <Text font={fonts.thin} size={16} marginBottom={12}>
          The Fellowship subscription is $800 every 4 weeks, with a minimum
          12-week commitment.
        </Text>
        <Row>
          <TextButton
            color={colors.neutralBlue}
            text="Pause"
            onPress={onPause}
          />
          <Box width="12px" />
          <TextButton
            color={colors.alertRed}
            text="Cancel"
            onPress={onCancel}
          />
        </Row>
        <Divider marginBottom={24} marginTop={24} />
        <Text size={24}>Payment Plan</Text>
        <Text font={fonts.thin} size={24} marginBottom={24}>
          Reocurring
        </Text>
        <Text font={fonts.thin} size={16}>
          Total Due
        </Text>
        <Text>
          <Text color={colors.purpley} size={24}>
            $
          </Text>
          <Text size={24}>800</Text>
        </Text>
        <Divider marginBottom={24} marginTop={24} />
        <Row>
          <Text marginRight={4} size={16}>
            Billing cycle:
          </Text>
          <Text font={fonts.thin} size={16} marginRight={12}>
            every 4 weeks
          </Text>
          {/* <TextButton
            color={colors.neutralBlue}
            text="Edit"
            onPress={onCancel}
          /> */}
        </Row>
        <Row>
          <Text marginRight={4} size={16}>
            Next payment:
          </Text>
          <Text font={fonts.thin} size={16}>
            {shortYearDatestring(details?.endDate || new Date())}
          </Text>
        </Row>
      </Box>
    </CardContainer>
  );
};
