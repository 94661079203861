import { isToday, format, isYesterday } from 'date-fns';

import { yesterdayDate } from '../dateUtils';

import { ChatMessageType } from 'sonora-types';

export const formatDate = (date: Date) => {
  if (isToday(date)) {
    return format(date, 'h:mm A');
  } else if (isYesterday(date)) {
    return 'Yesterday';
  } else {
    return format(date, 'MM/D/YY');
  }
};

export const attachmentForMessage = (message: ChatMessageType) =>
  message.attachment &&
  message.attachment.s3Url &&
  message.attachment.s3ThumbnailUrl && {
    video: message.attachment.s3Url,
    posterImage: message.attachment.s3ThumbnailUrl,
  };

export const hasUnprocessedAttachment = (message: ChatMessageType) =>
  message.contents.length === 0 &&
  message.sent &&
  !attachmentForMessage(message);

export const newerThanYesterday = (message: ChatMessageType) =>
  yesterdayDate() < new Date(message.createdAt);

export const hasRecentUnprocessedAttachment = (message: ChatMessageType) =>
  hasUnprocessedAttachment(message) && newerThanYesterday(message);

export const textForMessage = (message: ChatMessageType) => {
  if (hasUnprocessedAttachment(message)) {
    return newerThanYesterday(message)
      ? 'Video uploaded, but still processing...'
      : 'Error processing video';
  }

  return message.contents;
};

export const earliestMessageFirst = (
  a: ChatMessageType,
  b: ChatMessageType
) => {
  return a.createdAt < b.createdAt ? -1 : 1;
};
