import React from 'react';
import { TouchableOpacity } from 'react-native';
import { SendProps, IMessage } from 'react-native-gifted-chat';
import styled from 'styled-components/native';

import { colors, theme } from '../../../constants/theme';
import { FillIcon } from '../../Icon';

const SendContainer = styled.View`
  background-color: ${colors.opal};
  padding: 12px;
  border-top-right-radius: ${theme.common.borderRadius};
  border-bottom-right-radius: ${theme.common.borderRadius};
  justify-content: flex-start;
`;

export const RenderSend: React.FC<SendProps<IMessage>> = ({ text, onSend }) => {
  const handlePress = () => {
    if (text && onSend) {
      onSend({ text: text.trim() }, true);
    }
  };

  return (
    <SendContainer>
      <TouchableOpacity onPress={handlePress}>
        <FillIcon
          name="send"
          color={text ? colors.tanzanite : colors.purpley}
        />
      </TouchableOpacity>
    </SendContainer>
  );
};
