import React, { useContext } from 'react';
import { UserContext } from 'sonora-containers/index';
import { useFocusEffect } from '@react-navigation/native';

import { RootNavScreen } from '../screens';
import { MentorCourseMapOverview } from './MentorCourseMapOverview';
import { StudentCourseMapOverview } from './StudentCourseMapOverview';

export const CourseMapOverview: RootNavScreen<'CourseMapOverview'> = ({
  route,
  navigation,
}) => {
  const { userType, payment} = useContext(UserContext);
  useFocusEffect(() => {
    if(payment && !payment?.hasCoreAccess){
      navigation.navigate('Account');
    }
  })
  if (userType === 'mentor')
    return <MentorCourseMapOverview route={route} navigation={navigation} />;
  else
    return <StudentCourseMapOverview route={route} navigation={navigation} />;
};
