import React, { useContext } from 'react';
import {
  LayoutProviderContext,
  LayoutType,
  ModalPresenterProvider,
} from 'sonora-design-system';
import { LearningActivity } from 'sonora-types';

import { RootNavScreen } from '../screens/screens';
import L2LandscapeNavigator from './L2LandscapeNavigator';
import L2PortraitNavigator from './L2PortraitNavigator';
import L2NavigationProvider from './L2NavigationProvider';
import { L2Content } from './L2Content';
import { ModificationsProvider } from '../screens/Modifications/ModificationsProvider';
import {
  StudentContext,
  MentorContext,
  UserContext,
} from 'sonora-containers/index';

const L2Screen: RootNavScreen<'L2'> = ({ route, navigation }) => {
  const { activeLayout } = useContext(LayoutProviderContext);
  const { userType } = useContext(UserContext);
  let curriculumMap;
  if (userType === 'mentor') {
    curriculumMap = useContext(MentorContext).curriculumMap;
  } else {
    curriculumMap = useContext(StudentContext).curriculumMap;
  }
  const nodeId = route.params.id;

  const currentNode = curriculumMap[route.params.id] as LearningActivity;

  const modificationsDrawerEnabled = Boolean(
    currentNode &&
      currentNode.assignmentType === 'practice' &&
      currentNode.modifications &&
      Object.keys(currentNode.modifications).length > 0
  );

  const content =
    userType === 'mentor' ? (
      <L2Content id={nodeId} />
    ) : (
      <L2NavigationProvider>
        {activeLayout === LayoutType.Landscape ? (
          <L2LandscapeNavigator id={nodeId} />
        ) : (
          <L2PortraitNavigator id={nodeId} />
        )}
      </L2NavigationProvider>
    );

  return (
    <ModificationsProvider
      modificationsDrawerEnabled={modificationsDrawerEnabled}
      nodeId={nodeId}
    >
      <ModalPresenterProvider>{content}</ModalPresenterProvider>
    </ModificationsProvider>
  );
};

export default L2Screen;
