import { useCreateMessageAttachmentThreadMutation } from 'sonora-graphql/types';
import { handleError } from 'sonora-services/analytics';

export const useCreateNewMessageThread = () => {
  const [createMessageThread] = useCreateMessageAttachmentThreadMutation();
  return ({
    relationshipId,
    subject,
    exerciseProgressId,
  }: {
    relationshipId: number;
    subject: string;
    exerciseProgressId?: number;
  }) => {
    return new Promise<number>((resolve, reject) => {
      createMessageThread({
        variables: {
          relationshipId,
          subject,
          exerciseProgressId,
        },
      })
        .then((result) => {
          if (
            !result ||
            !result.data ||
            !result.data.createMessageThread ||
            !result.data.createMessageThread.messageThread
          ) {
            throw new Error('Expected well-formed message thread result');
          }
          const messageThreadId = parseInt(
            result.data.createMessageThread.messageThread.id
          );
          resolve(messageThreadId);
        })
        .catch((err) =>
          handleError('Create Message Thread issue', {
            err,
            relationshipId,
            subject,
          })
        );
    });
  };
};
