import React, { useState, useEffect, useContext } from 'react';
import { Text, AsyncStorage, ActivityIndicator, Platform } from 'react-native';
import * as Linking from 'expo-linking';
import { NavigationState, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import { isDevelopment } from 'sonora-util/environment';
import { L1NavigationContext } from 'sonora-containers/index';

import { AuthLoadingScreen } from './levels/AuthLoadingScreen';
import { LoggedOutStack } from './levels/LoggedOutStack';
import { LoggedInStack } from './levels/LoggedInStack';

const Stack = createStackNavigator();

const prefix = Linking.makeUrl('/');
const config = {
  initialRouteName: 'AuthLoading',
  screens: {
    AuthLoading: '',
    LoggedOut: {
      initialRouteName: 'Signin',
      screens: {
        Signin: 'signin',
        TokenLogin: 'login',
        ForgotPassword: 'forgot-password',
        ForgotPasswordSuccess: 'forgot-password/success',
        ResetPassword: 'reset-password',
      },
    },
    LoggedIn: {
      screens: {
        L1: {
          screens: {
            Overview: {
              path: 'overview',
              screens: {
                StudentOverviewScreen: '',
                MentorStudents: '',
                StudentChat: {
                  path: ':studentId',
                  parse: {
                    studentId: Number,
                  },
                  screens: {
                    MainChatThread: '',
                    PracticePostThread: {
                      path: ':id',
                      parse: {
                        id: Number,
                      },
                    },
                  },
                },
              },
            },
            CourseMap: {
              path: 'course-map',
              initialRouteName: 'CourseMapOverview',
              screens: {
                CourseMapOverview: 'overview',
                CourseMapDetails: 'detail',
              },
            },
            MentorChat: {
              initialRouteName: 'MainChatThread',
              path: 'chat',
              screens: {
                MainChatThread: '',
                PracticePostThread: {
                  path: ':id',
                  parse: {
                    id: Number,
                  },
                },
              },
            },
            Account: {
              path: 'account',
              screens: {
                Account: {
                  path: 'billing',
                  screens: {
                    BillingOverview: 'overview',
                    BillingDetails: 'details',
                    PaymentDetails: 'payments',
                  },
                },
              },
            },
          },
        },
        L2: {
          path: '/activity/:id/:activeIndex?',
          screens: {
            Details: 'details',
            SupplementaryMaterials: 'supplementary',
          },
        },
      },
    },
  },
};

const PERSISTENCE_KEY = 'NAVIGATION_STATE';

export const AppNavContainer: React.FC = () => {
  const [isReady, setIsReady] = useState(false);
  const [initialState, setInitialState] = useState();
  const { setActiveIndex } = useContext(L1NavigationContext);

  useEffect(() => {
    const restoreState = async () => {
      try {
        const initialUrl = await Linking.getInitialURL();

        if (Platform.OS !== 'web' && (initialUrl == null || isDevelopment())) {
          // Only restore state if there's no deep link and we're not on web
          const savedStateString = await AsyncStorage.getItem(PERSISTENCE_KEY);
          // console.log('SAVED STATE: ', savedStateString);
          const state = savedStateString
            ? JSON.parse(savedStateString)
            : undefined;

          if (state !== undefined) {
            setInitialState(state);
          }
        }
      } finally {
        setIsReady(true);
      }
    };

    if (!isReady) {
      restoreState();
    }
  }, [isReady]);

  if (!isReady) {
    // Temporary loading state
    return <ActivityIndicator />;
  }

  const linking = {
    prefixes: [prefix],
    config,
  };

  // The function that parses the navigation state for the active index in L1
  // if one exists. Otherwise, return 0 for the first screen
  const getL1Index = (state: NavigationState | undefined) => {
    if (state) {
      let LoggedInStack = state.routes.find(
        (route) => route.name === 'LoggedIn'
      );

      if (LoggedInStack && LoggedInStack.state) {
        let LoggedInState = LoggedInStack.state as NavigationState;

        let L1 = LoggedInState.routes.find((route) => route.name === 'L1');

        if (L1 && L1.state && L1.state.index != undefined) {
          return L1.state.index;
        }

        if (LoggedInState.routes.length > 1) {
          return 1;
        }
      }
    }

    return 0;
  };

  return (
    <NavigationContainer
      initialState={initialState}
      onStateChange={(state) => {
        // TODO: Keep for analytics
        // console.log('CHANGE: ', JSON.stringify(state));
        setActiveIndex(getL1Index(state));
        AsyncStorage.setItem(PERSISTENCE_KEY, JSON.stringify(state));
      }}
      linking={linking}
      fallback={<Text>YOU SHOULD NO BE SEEING THIS...</Text>}
      documentTitle={{ formatter: () => 'Sonora' }}
    >
      <Stack.Navigator
        screenOptions={{ headerShown: false, animationEnabled: false }}
        initialRouteName="AuthLoading"
      >
        <Stack.Screen name="AuthLoading" component={AuthLoadingScreen} />
        <Stack.Screen name="LoggedIn" component={LoggedInStack} />
        <Stack.Screen name="LoggedOut" component={LoggedOutStack} />
      </Stack.Navigator>
    </NavigationContainer>
  );
};
