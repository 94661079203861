import React, { FC } from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';

import { FillIcon, colors, convertHexToRGBA } from 'sonora-design-system';

export interface L2SidebarMenuItemProps {
  iconName: string;
  handlePress: () => void;
  focused?: boolean;
  disabled?: boolean;
}

const L2SidebarMenuItem: FC<L2SidebarMenuItemProps> = (props) => {
  let tintColor = colors.tanzanite;
  if (props.focused) {
    tintColor = colors.amethyst;
  }
  if (props.disabled) {
    tintColor = colors.purpley;
  }

  const backgroundColor = props.focused
    ? convertHexToRGBA(colors.amethyst, 20)
    : colors.opal;

  const containerStyle = [styles.menuItemContainer, { backgroundColor }];

  const icon = (
    <View
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      }}
    >
      <FillIcon name={props.iconName} color={tintColor} />
    </View>
  );

  if (props.disabled) {
    return <View style={containerStyle}>{icon}</View>;
  }

  return (
    <TouchableOpacity
      style={containerStyle}
      onPress={() => props.handlePress()}
    >
      {icon}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  menuItemContainer: {
    borderRadius: 28,
    height: 28,
    width: 28,
    marginHorizontal: 11,
    marginVertical: 11,
  },
});

export default L2SidebarMenuItem;
