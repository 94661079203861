import React from 'react';

import {
  colors,
  Box,
  TaskList,
  Scrollable,
  Calendar,
  Progress,
  Divider,
  AnnouncementHeader,
} from 'sonora-design-system';
import { OverviewProps } from './types';
import { L1Header } from '../../frame/L1Header';

export const OverviewPortrait: React.FC<OverviewProps> = ({
  announcementHeaderProps,
  progressProps,
  calendarProps,
  taskListProps,
}) => {
  return (
    <Box backgroundColor={colors.white} flex={1}>
      <L1Header />
      <Scrollable>
        <Box paddingLeft={24}>
          <AnnouncementHeader {...announcementHeaderProps} />
        </Box>
        <Box padding={24}>
          <Box marginBottom={16}>
            <Progress {...progressProps} />
          </Box>
          <Divider marginBottom={16} />
          <Calendar view="week" {...calendarProps} />
        </Box>
        <Box backgroundColor={colors.agate} padding={24}>
          <TaskList
            hasHideButton={false}
            hasScroll={false}
            {...taskListProps}
          />
        </Box>
      </Scrollable>
    </Box>
  );
};
