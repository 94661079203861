import React from 'react';
import { Platform } from 'react-native';

import { GiftedChat } from 'react-native-gifted-chat';
import { GiftedMessage, GiftedUser } from './types';

import {
  RenderDay,
  RenderTime,
  RenderAvatar,
  RecordVideoButton,
  RenderBubble,
  RenderSystemMessage,
  RenderSend,
  RenderComposer,
  RenderInputToolbar,
  scrollToBottomStyle,
  ScrollToBottom,
  RenderMessage,
} from './RenderFunctions';

interface ChatThreadProps {
  messages: GiftedMessage[];
  currentUser: GiftedUser;
  isMessagingDisabled?: boolean;
  onSend: (messages: GiftedMessage[]) => void;
  startNewVideo: () => void;
}

const disableSendingMessagesProps = {
  minInputToolbarHeight: 0,
  renderInputToolbar: () => null,
};

export const ChatThread: React.FC<ChatThreadProps> = ({
  messages,
  currentUser,
  onSend,
  isMessagingDisabled,
  startNewVideo,
}) => {
  const listViewExtraProps = {
    initialNumToRender: Platform.OS === 'web' ? messages.length : 50,
  };

  const RenderCameraButton = () => (
    <RecordVideoButton onPress={startNewVideo} />
  );

  return (
    <GiftedChat
      messages={messages}
      onSend={onSend}
      listViewProps={listViewExtraProps}
      keyboardShouldPersistTaps={'handled'}
      user={currentUser}
      renderActions={RenderCameraButton}
      renderAvatar={RenderAvatar}
      renderSend={RenderSend}
      renderTime={RenderTime}
      renderInputToolbar={RenderInputToolbar}
      minInputToolbarHeight={72}
      placeholder={'Type your message'}
      renderComposer={RenderComposer}
      renderDay={RenderDay}
      renderBubble={RenderBubble}
      renderSystemMessage={RenderSystemMessage}
      renderMessage={RenderMessage}
      scrollToBottom={true}
      scrollToBottomComponent={ScrollToBottom}
      scrollToBottomStyle={scrollToBottomStyle}
      showUserAvatar={true}
      {...(isMessagingDisabled && disableSendingMessagesProps)}
    />
  );
};
