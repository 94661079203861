import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
const Stack = createStackNavigator();

import { RootNavScreen } from '../screens';
import { CategoryView } from './CategoryView';
import { DetailsView } from './DetailsView';

export const Modifications: RootNavScreen<'Modifications'> = () => {
  return (
    <NavigationContainer
      independent={true}
      documentTitle={{ formatter: () => 'Sonora' }}
    >
      <Stack.Navigator
        initialRouteName="Categories"
        headerMode="screen"
        screenOptions={{ animationEnabled: false, cardShadowEnabled: false }}
      >
        <Stack.Screen
          name="ModificationCategories"
          component={CategoryView}
          options={{
            header: () => <></>,
          }}
        />
        <Stack.Screen
          name="ModificationDetails"
          component={DetailsView}
          options={{
            header: () => <></>,
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};
