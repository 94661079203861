// author: @fullstackdevs14
export const pushStyle = (
  styles: string[],
  key: string,
  value: string | number | undefined,
  unit?: string
) => {
  if (value) {
    const strvalue =
      typeof value === 'string' ? value : `${value}${unit || ''}`;
    styles.push(`${key}: ${strvalue};`);
  }
};
