import React from 'react';
import { Time, TimeProps, IMessage } from 'react-native-gifted-chat';

import { fonts, colors } from '../../../constants/theme';
import { convertHexToRGBA } from '../../../util';

const textStyle = {
  fontSize: 12,
  fontFamily: fonts.thin,
  color: convertHexToRGBA(colors.tanzanite, 70),
  marginTop: 10,
  marginLeft: 0,
};

const containerStyle = {
  marginLeft: 0,
};

export const RenderTime: React.FC<TimeProps<IMessage>> = (props) => {
  return (
    <Time
      {...props}
      timeTextStyle={{
        left: textStyle,
        right: textStyle,
      }}
      containerStyle={{
        left: containerStyle,
        right: containerStyle,
      }}
    />
  );
};
