import React, { useContext, useEffect } from 'react';
import { RootNavScreen } from '../screens';
import { createStackNavigator } from '@react-navigation/stack';

import { Notifications } from 'expo';
import { Notification } from 'expo/build/Notifications/Notifications.types';
import { showMessage } from 'react-native-flash-message';

import { MainChatThread } from './MainChatThread';
import { PracticePostThread } from './PracticePostThread';
import {
  StudentContext,
  ChatProvider,
  ChatProviderProps,
} from 'sonora-containers/index';
import { LowTicketUpsellScreen } from './LowTicketUpsellScreen';

const Stack = createStackNavigator();

// Chat that student uses to talk to a mentor
export const MentorChat: RootNavScreen<'MentorChat'> = ({ navigation }) => {
  const {
    user,
    studentType,
    mentorRelationship,
    relationshipStatus,
  } = useContext(StudentContext);
  if (studentType === 'LT') {
    return <LowTicketUpsellScreen />;
  }
  if (!mentorRelationship)
    throw new Error('mentored student should have mentor relationship!');
  if (relationshipStatus === 'Error') {
    console.warn('relationship status = error?', mentorRelationship);
  }

  const handleNotification = (notification: Notification) => {
    const isMessage =
      notification.data.type === 'message' ||
      notification.data.type === 'thread';
    const notificationThreadId = notification.data.messageThreadId;
    const openMessage = () => {
      if (mentorRelationship.mainThreadId === notificationThreadId) {
        navigation.navigate('MainChatThread');
      } else {
        navigation.navigate('PracticePostThread', { id: notificationThreadId });
      }
    };

    switch (notification.origin) {
      case 'received': {
        // received whilst app is foregrounded
        if (isMessage) {
          const senderName = notification.data.senderFullName || 'mentor';
          // Delay notifications to give the message a chance to load in thread poll
          setTimeout(
            () =>
              showMessage({
                message: `New message from ${senderName}`,
                type: 'default',
                onLongPress: openMessage,
                onPress: openMessage,
              }),
            3 * 1000
          );
        }
        return;
      }
      case 'selected': {
        if (isMessage) {
          openMessage();
        }
        return;
      }
    }
  };

  useEffect(() => {
    const subscription = Notifications.addListener(handleNotification);

    return () => {
      subscription.remove();
    };
  }, []);

  const chatProviderProps: ChatProviderProps = {
    relationshipId: mentorRelationship.relationshipId,
    mainThreadId: mentorRelationship.mainThreadId,
    isRelationshipActive: relationshipStatus === 'ActiveMentor',
    userRole: 'student',
    chatUser: {
      fullName: user.fullName,
      id: Number(user.id),
    },
  };

  return (
    <ChatProvider {...chatProviderProps}>
      <Stack.Navigator initialRouteName="MainChatThread">
        <Stack.Screen
          name="MainChatThread"
          component={MainChatThread}
          options={{ title: 'All Chats' }}
        />
        <Stack.Screen
          name="PracticePostThread"
          component={PracticePostThread}
          options={{ title: 'Practice Chat' }}
        />
      </Stack.Navigator>
    </ChatProvider>
  );
};
