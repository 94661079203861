import React from 'react';
import styled from 'styled-components/native';
import { View } from 'react-native';
import { Task } from 'sonora-types';
import TaskListItem from '../TaskList/TaskListItem';

import { Box, Text } from '../styled';
import { Button } from '../Button';
import { colors, fonts } from '../../constants/theme';
import NiceWorkImage from '../../assets/images/nice-work.png';

const ImageDecoration = styled.Image`
  width: 250px;
  height: 181px;
  margin-bottom: 12px;
`;

const AchievementPill = styled.View`
  padding: 3px 6px;
  background-color: ${colors.agate};
  border-radius: 4px;
  margin-bottom: 24px;
  align-items: center;
  max-width: 372px;
  width: 100%;
`;

export interface NiceWorkProps {
  studentName: string;
  onCtaPress?: () => void;
  onHomePress: () => void;
  nextActivity?: Task;
  noTaskMessage: string;
}

export const NiceWork: React.FC<NiceWorkProps> = ({
  studentName,
  onCtaPress,
  onHomePress,
  nextActivity,
  noTaskMessage,
}) => {
  const noNextActivity = nextActivity == null;

  const showGoButton = !noNextActivity;

  const achievementMessage = noNextActivity ? (
    <Text size={20}>{noTaskMessage}</Text>
  ) : undefined;

  const formattedNextActivity = nextActivity ? (
    <Box marginTop={3} width="100%">
      <TaskListItem
        item={nextActivity}
        onSkip={nextActivity.onSkip}
        onSelect={onCtaPress}
      />
    </Box>
  ) : undefined;
  return (
    <Box
      flex={1}
      width="100%"
      backgroundColor={colors.white}
      padding={15}
      alignItems="center"
    >
      <ImageDecoration source={NiceWorkImage} />
      <Text
        font={fonts.headline}
        size={32}
        lineHeight="36px"
        textAlign="center"
        marginBottom={4}
        color={colors.tanzanite}
      >
        Nice work, {studentName}!
      </Text>
      <Text
        font={fonts.thin}
        size={20}
        lineHeight="36px"
        textAlign="center"
        marginBottom={24}
      >
        We've logged your progress.
      </Text>
      <AchievementPill>
        {achievementMessage}
        {formattedNextActivity}
      </AchievementPill>
      {showGoButton && (
        <View style={{ marginBottom: 12 }}>
          <Button type="Special" text="Let's go" onPress={onCtaPress} />
        </View>
      )}
      <Button type="Tertiary" text="Back to Home" onPress={onHomePress} />
    </Box>
  );
};
