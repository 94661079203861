import React, { FC } from 'react';
import { TouchableOpacity } from 'react-native';
import {
  Box,
  Text,
  FillIcon,
  colors,
  theme,
  convertHexToRGBA,
} from 'sonora-design-system';
import { useNavigation } from '@react-navigation/native';
import { CarouselThreadData } from 'sonora-design-system/components/Chat/types';
import styled from 'styled-components/native';
import { trackEvent } from 'sonora-services/analytics';

const IconContainer = styled.View`
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const InnerBorder = styled.View`
  position: absolute;
  border-radius: ${theme.common.borderRadius};
  border-width: 2px;
  border-color: ${colors.amethyst};
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const ImageContainer = styled.View`
  position: relative;
  border-radius: ${theme.common.borderRadius};
  overflow: hidden;
  width: 130px;
  height: 72px;
  flex: none;
`;

export const CarouselImage = styled.Image`
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const Badge = styled.View`
  position: absolute;
  width: 8px;
  height: 8px;
  right: 6px;
  top: 6px;
  border-radius: 4px;
  background-color: ${colors.validGreen};
`;

export interface ThreadCarouselCardProps {
  thread: CarouselThreadData;
  hasBorder?: boolean;
}

export const ThreadCarouselCard: FC<ThreadCarouselCardProps> = ({
  thread,
  hasBorder,
}) => {
  const navigation = useNavigation();

  const badge = thread.badge ? <Badge /> : undefined;
  const MemoizedCarouselImage = React.memo(CarouselImage);

  return (
    <TouchableOpacity
      onPress={() => {
        trackEvent('navigate-to-video-thread', { id: thread.id });
        navigation.navigate('PracticePostThread', { id: thread.id });
      }}
    >
      <Box
        alignItems="center"
        width="130px"
        marginRight={24}
        position="relative"
      >
        <ImageContainer>
          <MemoizedCarouselImage
            source={{
              uri: thread.imageUrl,
            }}
          />
          <IconContainer>
            <FillIcon name="playFilled" color={colors.white} />
          </IconContainer>
          {hasBorder ? <InnerBorder /> : null}
        </ImageContainer>
        <Text
          size={10}
          color={convertHexToRGBA(colors.tanzanite, 80)}
          textAlign="center"
          marginTop={6}
        >
          {thread.subtitle}
        </Text>
        <Text size={12} textAlign="center">
          {thread.title}
        </Text>
        {badge}
      </Box>
    </TouchableOpacity>
  );
};
