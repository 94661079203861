import React from 'react';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

import { colors } from '../../constants/theme';
import { Text } from '../styled';
import { FillIcon } from '../Icon';

const PlayButtonWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  padding: 3px 12px;
  background-color: ${colors.agate};
  border-radius: 13px;
`;

interface PlayButtonProps {
  showText?: boolean;
  onPress?: () => void;
}

export const PlayButton: React.FC<PlayButtonProps> = ({
  showText,
  onPress,
}) => (
  <TouchableOpacity onPress={onPress}>
    <PlayButtonWrapper>
      <FillIcon name="playFilled" size="s" />
      {showText ? (
        <Text size={12} marginLeft={4}>
          PLAY
        </Text>
      ) : null}
    </PlayButtonWrapper>
  </TouchableOpacity>
);
