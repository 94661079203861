import React from 'react';
import { FillIcon } from '../Icon';
import { CardContainer } from './styled';
import { Box, Row, Text } from '../styled';
import { fonts, colors } from '../../constants/theme';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import { TextButton } from './TextButton';
import { handleMock } from 'sonora-util/helpers';
import { IStripePaymentMethod } from 'sonora-types';
import { Fontisto } from '@expo/vector-icons';

const cardImages = {
  Visa: 'visa',
  Mastercard: 'mastercard',
  Discover: 'discover',
  'American Express': 'american-express',
  JCB: 'jcb',
};

export interface PaymentMethodProps {
  details: IStripePaymentMethod;
  isInvalid?: boolean;
  editable?: boolean;
  removable?: boolean;
  onPress?: () => void;
}

export const PaymentMethod: React.FC<PaymentMethodProps> = ({
  details,
  editable,
  removable,
  onPress,
}) => {
  const textColor = details.failed ? colors.alertRed : colors.tanzanite;
  const expiredAt = `${('0' + details.expMonth).slice(-2)}/${(
    '' + details.expYear
  ).slice(-2)}`;

  return (
    <Box width="100%" marginBottom={12}>
      <TouchableWithoutFeedback onPress={onPress}>
        <CardContainer isInvalid={details.failed}>
          <Box>
            <Row>
              <Fontisto
                name={cardImages[details.brand]}
                size={24}
                color={colors.amethyst}
              />
              <Text font={fonts.thin} color={textColor} marginLeft={10}>
                {details.last4}
              </Text>
              <Text font={fonts.thin} color={textColor} marginLeft={10}>
                {expiredAt}
              </Text>
            </Row>
            {(editable || removable) && (
              <Row marginTop={12}>
                {editable && (
                  <TextButton
                    text="Edit"
                    color={colors.neutralBlue}
                    onPress={handleMock('Edit')}
                  />
                )}
                {removable && (
                  <>
                    <Box width="12px" />
                    <TextButton
                      text="Remove"
                      color={colors.alertRed}
                      onPress={handleMock('Remove')}
                    />
                  </>
                )}
              </Row>
            )}
          </Box>
          {editable ? (
            details.isDefault ? (
              <FillIcon name="radioFilled" color={textColor} />
            ) : (
              <FillIcon name="radioEmpty" color={textColor} />
            )
          ) : (
            <FillIcon name="pageRight" color={textColor} />
          )}
        </CardContainer>
      </TouchableWithoutFeedback>
      {details.failed && (
        <Row
          marginTop={-6}
          paddingX={24}
          paddingY={14}
          backgroundColor={colors.thulite + '80'}
        >
          <Box flex="none">
            <FillIcon name="alert" color={colors.alertRed} />
          </Box>
          <Text marginLeft={15}>
            <Text color={colors.alertRed} size={12} marginRight={4}>
              Your payment was declined.
            </Text>
            <Text font={fonts.thin} color={colors.alertRed} size={12}>
              Please update your payment method, or choose a different payment
              method and try again.
            </Text>
          </Text>
        </Row>
      )}
    </Box>
  );
};
