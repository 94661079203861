import React from 'react';
import {
  Box,
  colors,
  ModalFunctionComponent,
  PremiumCancelView,
} from 'sonora-design-system';
import { mockPremiumSubscription } from 'sonora-util/testData';

export const PremiumCancelModal: ModalFunctionComponent = ({
  dismissModal,
}) => {
  return (
    <Box
      flex={1}
      backgroundColor={colors.white}
      justifyContent="center"
      alignItems="center"
    >
      <PremiumCancelView
        details={mockPremiumSubscription}
        onCancel={() => dismissModal('Success', 'cancel')}
        onPause={() => dismissModal('Success', 'pause')}
      />
    </Box>
  );
};
