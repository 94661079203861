import React, { useContext } from 'react';
import { ActivityIndicator } from 'react-native';
import { Avatar, Box, Row, fonts, Text } from 'sonora-design-system';
import { RootNavScreen } from '../screens';
import { UserContext } from '../../../containers/UserProvider';
import { AccountTabNavigator } from './AccountTabNavigator';

export const Account: RootNavScreen<'Account'> = () => {
  const { user } = useContext(UserContext);

  if (!user) {
    return <ActivityIndicator />;
  }

  return (
    <Box backgroundColor="#fff" height="100%">
      <Row justifyContent="center" paddingTop={27} paddingBottom={15}>
        <Avatar firstname={user.firstName} lastname={user.lastName} />
        <Text size={20} font={fonts.headline}>
          {user.fullName}
        </Text>
      </Row>
      <AccountTabNavigator />
    </Box>
  );
};
