import React from 'react';
import { storiesOf } from '@storybook/react-native';
import { Box } from '../styled';
import { MembershipCard } from './MembershipCard';
import { PaymentMethod } from './PaymentMethod';
import { PremiumSubscriptionCard } from './PremiumSubscriptionCard';
import { TrialCancel } from './TrialCancel';
import { colors } from '../../constants/theme';
import { PaymentSelect } from './PaymentSelect';
import {
  mockInstallmentsHistory,
  mockInstallmentsMembership,
  mockPremiumHistory,
  mockPremiumSubscription,
  mockUpfrontHistory,
  mockUpfrontMembership,
} from 'sonora-util/testData';
import { PremiumSubscriptionDetailsCard } from './PremiumSubscriptionDetailsCard';
import { MembershipDetailsCard } from './MembershipDetailsCard';
import { TransactionHistory } from './TransactionHistory';
import { PremiumPauseView } from './PremiumPauseView';
import { PremiumCancelView } from './PremiumCancelView';
import { handleMock } from 'sonora-util/helpers';

const billingStories = storiesOf('Billing', module);

const BillingStory = () => {
  return (
    <Box padding={30} alignItems="center" backgroundColor={colors.agate}>
      <Box width="485px">
        <PremiumSubscriptionCard
          details={mockPremiumSubscription}
          onPause={handleMock('Pause')}
          onCancel={handleMock('Cancel')}
          onEditBillingCycle={handleMock('Edit Billing Cycle')}
        />
        <MembershipCard details={mockInstallmentsMembership} />
        <MembershipCard details={mockUpfrontMembership} />
        <PaymentMethod
          details={{
            last4: '1234',
            expMonth: 10,
            expYear: 2021,
            brand: 'Mastercard',
            isDefault: true,
            id: '1',
          }}
        />
      </Box>
    </Box>
  );
};

const ModalStory = () => {
  return (
    <Box
      padding={30}
      height="100%"
      overflow="auto"
      alignItems="center"
      backgroundColor={colors.agate}
    >
      <Box width="566px" marginBottom={12}>
        <TrialCancel
          onCancel={handleMock('Cancel')}
          onExtend={handleMock('Extend')}
        />
      </Box>
      <Box width="660px" marginBottom={12}>
        <PaymentSelect onSave={handleMock('Save')} />
      </Box>
      <Box width="566px" marginBottom={12}>
        <PremiumPauseView
          details={mockPremiumSubscription}
          onCancel={handleMock('Cancel Premium')}
          onPause={handleMock('Pause Premium')}
        />
      </Box>
      <Box width="566px" marginBottom={12}>
        <PremiumCancelView
          details={mockPremiumSubscription}
          onCancel={handleMock('Cancel Premium')}
          onPause={handleMock('Pause Premium')}
        />
      </Box>
    </Box>
  );
};

const DetailsStory = () => {
  return (
    <Box
      padding={30}
      height="100%"
      overflow="auto"
      alignItems="center"
      backgroundColor={colors.agate}
    >
      <Box width="485px">
        <PremiumSubscriptionDetailsCard
          details={mockPremiumSubscription}
          onPause={handleMock('Pause')}
          onCancel={handleMock('Cancel')}
          onEditBillingCycle={handleMock('Cancel')}
        />
        <MembershipDetailsCard details={mockInstallmentsMembership} />
        <MembershipDetailsCard details={mockUpfrontMembership} />
      </Box>
    </Box>
  );
};

const TransactionHistoryStory = () => {
  return (
    <Box
      padding={30}
      height="100%"
      overflow="auto"
      alignItems="center"
      backgroundColor={colors.agate}
    >
      <Box width="485px">
        <TransactionHistory details={mockInstallmentsHistory[0]} />
        <TransactionHistory details={mockUpfrontHistory[0]} />
        <TransactionHistory details={mockPremiumHistory[0]} />
      </Box>
    </Box>
  );
};

billingStories.add('Overview', BillingStory);
billingStories.add('Details', DetailsStory);
billingStories.add('Transaction History', TransactionHistoryStory);
billingStories.add('Modals', ModalStory);
