import React, { FC, useContext } from 'react';
import {
  Platform,
  KeyboardAvoidingView,
  View,
  StyleSheet,
  ImageBackground,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { LayoutProviderContext } from 'sonora-design-system';

import L0FrameSrc from 'sonora-design-system/assets/images/frame/L0Frame.png';

export interface L0FrameProps {}

const L0Frame: FC<L0FrameProps> = (props) => {
  const insets = useSafeAreaInsets();
  const { screenWidth, screenHeight } = useContext(LayoutProviderContext);

  return (
    <ImageBackground
      style={[{ height: screenHeight, width: screenWidth }]}
      source={L0FrameSrc}
    >
      <KeyboardAvoidingView
        behavior={Platform.OS == 'ios' ? 'padding' : 'height'}
        style={{
          marginTop: insets.top,
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <View style={styles.card}>{props.children}</View>
      </KeyboardAvoidingView>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  card: {
    width: '100%',
    maxWidth: 700,
    paddingHorizontal: 27,
  },
});

export default L0Frame;
