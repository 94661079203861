import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { launchCameraRollVideoPicker } from '../../services/cameraRoll';
import { RootNavScreen } from './screens';
import { colors, Button, fonts } from 'sonora-design-system';

export interface MobileSubmissionStartProps {}

const MobileSubmissionStart: RootNavScreen<'MobileSubmissionStart'> = (
  props
) => {
  const navigation = useNavigation();

  const handleUploadPress = async () => {
    try {
      let result = await launchCameraRollVideoPicker();
      if (!result.cancelled && result.uri) {
        console.log(result.uri);
        navigation.navigate('MobileSubmissionVideo', { uri: result.uri });
      }
    } catch (err) {
      throw new Error(err);
    }
  };

  return (
    <View style={styles.screen}>
      <Text style={styles.headline}>Add a Practice Video Submission</Text>
      <Text style={styles.text}>
        Upload a practice video to share with your mentor
      </Text>
      <View style={{ marginBottom: 12 }}>
        <Button
          text="Record a Video"
          onPress={() =>
            navigation.navigate('MobileSubmissionVideo', { uri: undefined })
          }
        />
      </View>
      <View style={{ marginBottom: 12 }}>
        <Button
          text="Upload a Video"
          type="Secondary"
          onPress={handleUploadPress}
        />
      </View>
      <Button
        type="Tertiary"
        text="Skip this Step"
        onPress={() => navigation.navigate('VideoSubmissionRating')}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: colors.white,
    padding: 40,
    alignItems: 'center',
  },
  headline: {
    fontFamily: fonts.headline,
    fontSize: 32,
    lineHeight: 36,
    color: colors.tanzanite,
    textAlign: 'center',
  },
  text: {
    marginTop: 24,
    fontFamily: fonts.thin,
    color: colors.tanzanite,
    fontSize: 20,
    lineHeight: 26,
    textAlign: 'center',
    marginBottom: 92,
  },
});

export default MobileSubmissionStart;
