import React, { useContext, useState, useEffect, useRef } from 'react';

import { useNavigation, useFocusEffect } from '@react-navigation/native';

import { default as WebView } from 'react-native-web-webview';
import { useOnBlur } from 'sonora-hooks/useOnBlur';

import {
  ModalPresenterContext,
  SoundsliceNodeView,
  LinkNodeView,
  TextNodeView,
  VideoNodeView,
  useIsWidthLessThan,
} from 'sonora-design-system';

import { sanitizePageLink } from 'sonora-util/strings';

import { ActivityAssignmentType, LearningActivity } from 'sonora-types';
import {
  VideoScreenTab,
  ActivityScreenCta,
} from 'sonora-design-system/components/NodeView/types';
import {
  VideoSubmissionStack,
  VideoSubmissionStackExtraData,
  VideoSubmissionStackReturnData,
} from './video-submission/VideoSubmissionStack';
import {
  StudentContext,
  TaskListContext,
  ProgressContext,
  UserContext
} from 'sonora-containers/index';
import {
  ExerciseComplete,
  ExerciseCompleteReturnData,
} from './ExerciseComplete';
import { L3ActivityDetails } from './L3/L3ActivityDetails';
import { L3SupplementaryMaterials } from './L3/L3SupplementaryMaterials';
import { trackEvent } from 'sonora-services/analytics';
import { ModificationUnlock } from './ModificationUnlock';

interface StudentL2ActivityViewProps {
  activity: LearningActivity;
}

export const StudentL2ActivityView: React.FC<StudentL2ActivityViewProps> = ({
  activity,
}) => {
  const soundsliceRef = useRef<typeof WebView>(null);
  const navigation = useNavigation();
  const {
    smartLogProgress: logProgress,
    setCurrentTaskId,
    addReviewItems,
  } = useContext(TaskListContext);
  const { relationshipStatus } = useContext(StudentContext);
  const { payment } = useContext(UserContext);

  const hasFellowshipAccess = relationshipStatus == "ActiveMentor" 
    || (payment && payment.isInTrial) 
    || (payment && payment.hasFellowshipAccess)
  const { presentComponentAsModal, modalIsVisible } = useContext(
    ModalPresenterContext
  );
  const { progress, completedNodes } = useContext(ProgressContext);
  const [primaryCtaLoading, setPrimaryCtaLoading] = useState(false);
  const [hasRated, setHasRated] = useState(false);
  const smallScreen = useIsWidthLessThan(375);

  // Keep the TaskList synced with this view
  useEffect(() => {
    setCurrentTaskId(activity.id);
    trackEvent('L2-activity-view', { activity });
  }, [activity]);

  useEffect(() => {
    if (modalIsVisible) {
      stopAudioVideo();
    }
  }, [modalIsVisible]);

  useFocusEffect(() => {
    if(payment && !payment?.hasCoreAccess){
      navigation.navigate('L1');
    }
  })
  
  useOnBlur(() => stopAudioVideo());

  let isAlreadyComplete = !!completedNodes.includes(activity.id);
  if (
    activity.optional &&
    progress[activity.id] &&
    !progress[activity.id].currentStars
  ) {
    // If user skipped Optional prompts it is not completed yet.
    isAlreadyComplete = false;
  }

  const completePrompt =
    hasFellowshipAccess ? 'Submit Video' : 'Mark Complete';

  const primaryCtaTexts: { [key in ActivityAssignmentType]: string } = {
    watch: isAlreadyComplete ? 'Watched' : 'Continue',
    prompt: isAlreadyComplete ? 'Completed' : completePrompt,
    practice: hasRated ? 'Saved Progress' : 'Save Progress',
  };

  const primaryCtaTitle = primaryCtaTexts[activity.assignmentType];
  const primaryCtaIcon = activity.assignmentType === 'watch' ? 'check' : '';

  const videoNodeTabs: VideoScreenTab[] =
    activity.assignmentType !== 'practice'
      ? [
          {
            pageTitle: 'Details',
            tabKey: 'details',
            tabLabel: 'Details',
            component: () => <L3ActivityDetails activity={activity} />,
          },
        ]
      : [];
  if (activity.supplementary && activity.supplementary.pageLink) {
    videoNodeTabs.push({
      tabKey: 'supplementary',
      pageTitle: 'SupplementaryMaterials',
      tabLabel: 'Supplementary Materials',
      component: () => (
        <L3SupplementaryMaterials
          url={sanitizePageLink(activity.supplementary!.pageLink)}
        />
      ),
    });
  }

  const stopAudioVideo = () => {
    if (soundsliceRef && soundsliceRef.current) {
      let data = { method: 'pause' };
      soundsliceRef.current.postMessage(
        JSON.stringify(data),
        'https://www.soundslice.com'
      );
    }
  };

  const openExerciseComplete = () => {
    presentComponentAsModal(ExerciseComplete, {
      minHeight: 582,
      onDismiss: (success, data) => {
        if (success === 'Success') {
          const typedData = data as ExerciseCompleteReturnData;
          if (typedData.nextNodeId) {
            navigation.navigate('L2', { id: typedData.nextNodeId });
          }
        }
      },
    });
  };

  /**
   * Logs the star rating, then loads up the "yay" modal offering user a chance to move forward
   */
  const logProgressAndUploadVideo = ({ stars }: { stars: number }) => {
    setPrimaryCtaLoading(true);

    logProgress({ nodeId: activity.id, stars }).then(
      ({ newUnlockedModifications }) => {
        trackEvent('logged-progress', { nodeId: activity.id, stars });
        setPrimaryCtaLoading(false);
        setHasRated(true);

        if (newUnlockedModifications.categoryIds.length > 0) {
          presentComponentAsModal(ModificationUnlock, {
            extraData: { categoryIds: newUnlockedModifications.categoryIds },
            onDismiss: (success, data) => {
              openWebVideoUpload(stars);
            },
          });
        } else if (newUnlockedModifications.modificationIds.length > 0) {
          presentComponentAsModal(ModificationUnlock, {
            extraData: {
              modificationIds: newUnlockedModifications.modificationIds,
            },
            onDismiss: (success, data) => {
              trackEvent('exercise-complete-return', { success, data });
              openWebVideoUpload(stars);
            },
          });
        } else {
          openWebVideoUpload(stars);
        }
      }
    );
  };

  /**
   * Starts a practice post submission and if user completes practice post,
   * logs 1 star (completion), otherwise just executes a javascript comment 🌟
   */
  const submitPromptVideo = () => {
    const extraData: VideoSubmissionStackExtraData = {
      activityId: activity.id,
      isLogProgressFlow: false,
      isPromptFlow: true,
      videoSubject: activity.name,
    };
    trackEvent('start-submit-prompt-video', { activity });
    presentComponentAsModal(VideoSubmissionStack, {
      extraData,
      onDismiss: (result, extraData: VideoSubmissionStackReturnData) => {
        if (result === 'Success') {
          trackEvent('log-prompt-complete', { result, extraData });
          logProgressAndUploadVideo({ stars: 1 });
        } else {
          trackEvent('canceled-prompt-record', { result, extraData });
          // they canceled the operation. don't do anything amigo!
        }
      },
    });
  };

  const openWebVideoUpload = (stars: number) => {
    // Commented all of this out to skip video submission step
    // if (stars < 3 || !hasFellowshipAccess) {
      addReviewItems(5);
      openExerciseComplete();
    // } else if (stars > 2) {
    //   const extraData: VideoSubmissionStackExtraData = {
    //     activityId: activity.id,
    //     isLogProgressFlow: true,
    //     startOnRatingScreen: false,
    //     videoSubject: activity.name,
    //   };
    //   trackEvent('start-video-upload', { extraData });
    //   presentComponentAsModal(VideoSubmissionStack, {
    //     extraData,
    //     onDismiss: (result, extraData: VideoSubmissionStackReturnData) => {
    //       if (result === 'Success') {
    //         trackEvent('log-video-upload', { result, extraData });
    //         addReviewItems(5);
    //         openExerciseComplete();
    //       } else {
    //         trackEvent('cancel-video-upload', { result, extraData });
    //       }
    //     },
    //   });
    // }
  };

  /** If user hasn't already submitted a video, offers opportunity to do so,
   * then goes to star rating screen. If user completes star rating, log it
   * and celebrate! Otherwise just sit there and stare awkwardly at them.
   */
  const logPracticeProgress = () => {
    const extraData: VideoSubmissionStackExtraData = {
      activityId: activity.id,
      isLogProgressFlow: true,
      startOnRatingScreen: true,
      videoSubject: activity.name,
    };
    trackEvent('log-practice-progress', { extraData });
    presentComponentAsModal(VideoSubmissionStack, {
      extraData,
      onDismiss: (result, extraData: VideoSubmissionStackReturnData) => {
        if (result === 'Success') {
          if (!extraData || !extraData.starRating)
            throw new Error('VideoSubmissionStack should return a star rating');
          logProgressAndUploadVideo({ stars: extraData.starRating });
        } else {
          trackEvent('cancel-log-progress', { result, extraData });
          // they canceled the operation. don't do anything amigo!
        }
      },
    });
  };

  const onProgress = () => {
    switch (activity.assignmentType) {
      case 'watch':
        logProgressAndUploadVideo({ stars: 1 });
        break;
      case 'prompt':
        if (hasFellowshipAccess) {
          submitPromptVideo();
        } else {
          // no mentor, just mark complete, we take your word for it I swear
          trackEvent('low-ticket-prompt-complete', { activity });
          logProgressAndUploadVideo({ stars: 1 });
        }
        break;
      case 'practice':
        logPracticeProgress();
    }
  };

  const startPracticePost = () => {
    const extraData: VideoSubmissionStackExtraData = {
      activityId: activity.id,
      isLogProgressFlow: false,
      videoSubject: activity.name,
    };
    trackEvent('start-practice-post', { extraData });
    presentComponentAsModal(VideoSubmissionStack, {
      extraData,
      onDismiss: (result, extraData) => {
        if (result === 'Success') {
          trackEvent('practice-post-complete', { result, extraData });
        } else {
          trackEvent('canceled-practice-post', { result, extraData });
        }
      },
    });
  };

  const canLogProgress =
    !hasRated && (!isAlreadyComplete || activity.assignmentType === 'practice');

  const primaryCta: ActivityScreenCta = {
    text: primaryCtaTitle,
    icon: primaryCtaIcon,
    iconStyle: 'stroke',
    size: smallScreen ? 'small' : 'default',
    onPress: onProgress,
    disabled: primaryCtaLoading || !canLogProgress,
  };

  const secondaryCta: ActivityScreenCta | undefined =
    activity.assignmentType === 'practice' &&
    hasFellowshipAccess
      ? {
          text: 'Add Video',
          icon: 'camera',
          size: smallScreen ? 'small' : 'default',
          iconPosition: 'end',
          onPress: startPracticePost,
        }
      : undefined;

  switch (activity.media.type) {
    case 'soundslice':
      return (
        <SoundsliceNodeView
          ref={soundsliceRef}
          title={activity.name}
          soundsliceUrl={activity.media.embedString}
          primaryCta={primaryCta}
          secondaryCta={secondaryCta}
        />
      );
    case 'vimeo':
      return (
        <VideoNodeView
          title={activity.name}
          vimeoVideoId={activity.media.embedString}
          tabs={videoNodeTabs}
          primaryCta={primaryCta}
          secondaryCta={secondaryCta}
        />
      );
    case 'link':
      return (
        <LinkNodeView
          title={activity.name}
          linkUrl={activity.media.embedString}
          tabs={videoNodeTabs}
          primaryCta={primaryCta}
          secondaryCta={secondaryCta}
        />
      );
    case 'text':
      return (
        <TextNodeView
          title={activity.name}
          tabs={videoNodeTabs}
          primaryCta={primaryCta}
          secondaryCta={secondaryCta}
        />
      );
    default:
      throw new Error('Invalid activity media type');
  }
};
