import React, { FC, useRef, useEffect } from 'react';
import {
  View,
  TouchableOpacity,
  StyleSheet,
  Animated,
  Platform,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { FillIcon, colors } from 'sonora-design-system';

import { WebView as WebViewMobile } from 'react-native-webview';
// @ts-ignore ReactNativeWebWebview has no types at the moment
import { default as WebViewWeb } from 'react-native-web-webview';

import { bottomTabsHeight } from './L2PortraitTabs';

const WebView = Platform.OS === 'web' ? WebViewWeb : WebViewMobile;

export interface L2PortraitDroneDrawerProps {
  isDrawerOpen: boolean;
  setDroneDrawerOpen: (isOpen: boolean) => void;
}

const drawerHeight = 382;
const drawerWidth = '100%';

const L2PortraitDroneDrawer: FC<L2PortraitDroneDrawerProps> = (props) => {
  let height = props.isDrawerOpen ? drawerHeight : 0;
  let heightAnim = useRef(new Animated.Value(0)).current;
  const insets = useSafeAreaInsets();

  useEffect(() => {
    Animated.timing(heightAnim, {
      toValue: height,
      duration: 400,
      useNativeDriver: false,
    }).start();
  }, [props.isDrawerOpen]);

  return (
    <Animated.View
      style={[
        styles.portraitDroneDrawer,
        { height: heightAnim, bottom: insets.bottom + bottomTabsHeight },
      ]}
    >
      <View style={styles.closeButton}>
        <TouchableOpacity onPress={() => props.setDroneDrawerOpen(false)}>
          <FillIcon name="x" color={colors.white} />
        </TouchableOpacity>
      </View>
      <WebView
        style={{
          height: Platform.OS === 'web' ? height : drawerHeight,
          width: drawerWidth,
        }}
        source={{
          uri: 'https://sonora-goat-metronome.herokuapp.com',
        }}
      />
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  portraitDroneDrawer: {
    position: 'absolute',
    width: drawerWidth,
  },
  closeButton: {
    position: 'absolute',
    zIndex: 1,
    right: 24,
    top: 12,
  },
});

export default L2PortraitDroneDrawer;
