import React, { FC, useState, useEffect, useContext } from 'react';
import { View, StyleSheet } from 'react-native';

import L2LandscapeSidebar from './L2LandscapeSidebar';
import L2LandscapeDrawer from './L2LandscapeDrawer';
import L2SidebarDroneDrawer from './L2SidebarDroneDrawer';
import { L2Content } from './L2Content';
import { L2NavigationContext } from './L2NavigationProvider';
import { L2ScreenProps } from '../screens/screens';

const L2LandscapeNavigator: FC<L2ScreenProps> = (props) => {
  const [isDroneDrawerOpen, setDroneDrawerOpen] = useState<boolean>(false);
  const { activeIndex, setActiveIndex } = useContext(L2NavigationContext);

  useEffect(() => {
    if (activeIndex === undefined) {
      setActiveIndex(0);
    }
  }, []);

  return (
    <View style={{ flex: 1, flexDirection: 'row' }}>
      <View style={styles.navigator}>
        <L2LandscapeSidebar
          isDroneDrawerOpen={isDroneDrawerOpen}
          setDroneDrawerOpen={setDroneDrawerOpen}
        />
        <L2LandscapeDrawer />
        <L2Content {...props} />
      </View>
      <L2SidebarDroneDrawer
        isDrawerOpen={isDroneDrawerOpen}
        setDroneDrawerOpen={setDroneDrawerOpen}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  navigator: { flex: 1, flexDirection: 'row' },
});

export default L2LandscapeNavigator;
