// author: @fullstackdevs14
import React from 'react';
import {
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  isToday,
  parseISO,
} from 'date-fns';
import { splitEvery } from 'ramda';

import { Box, Row } from '../styled';
import { CalendarCell } from './CalendarCell';
import { CalendarViewMode } from './types';

interface Props {
  date: Date;
  view?: CalendarViewMode;
  highlightedDays: string[];
}

export const CalendarBody: React.FC<Props> = ({
  date,
  view,
  highlightedDays,
}) => {
  const monthStart = startOfMonth(date).getTime();
  const monthEnd = endOfMonth(date).getTime();
  const start = startOfWeek(monthStart);
  const end = endOfWeek(monthEnd);
  const days =
    view === 'month'
      ? eachDayOfInterval({
          start,
          end,
        }).map((d) => d.getTime())
      : eachDayOfInterval({
          start: startOfWeek(date),
          end: endOfWeek(date),
        }).map((d) => d.getTime());
  const weeks = splitEvery(7, days);
  const highlightedTimestamps = highlightedDays.map((d) =>
    parseISO(d).getTime()
  );

  return (
    <Box>
      {weeks.map((week, i) => (
        <Row key={week[0]} height="42px" justifyContent="space-between">
          {week.map((day) => (
            <CalendarCell
              key={day}
              timestamp={day}
              highlighted={highlightedTimestamps.indexOf(day) >= 0}
              isToday={isToday(day)}
              disabled={day < monthStart || day > monthEnd}
            />
          ))}
        </Row>
      ))}
    </Box>
  );
};
