import React from 'react';
import { TouchableOpacity } from 'react-native';
import { ButtonText } from './styled';
import { colors } from '../../constants/theme';

export interface TextButtonProps {
  color?: string;
  text: string;
  onPress?: () => void;
}

export const TextButton: React.FC<TextButtonProps> = ({
  color = colors.tanzanite,
  text,
  onPress,
}) => (
  <TouchableOpacity onPress={onPress}>
    <ButtonText
      color={color}
      style={{
        textDecorationLine: 'underline',
        textDecorationColor: color,
      }}
    >
      {text}
    </ButtonText>
  </TouchableOpacity>
);
