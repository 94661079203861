import { ApolloError } from 'apollo-client';
import * as Sentry from 'sentry-expo';

import * as Amplitude from 'expo-analytics-amplitude';
import { amplitudeApiKey, versionNumber } from '../util/environment';
import { UserType } from 'sonora-graphql/types';

const toKebabCase = (str: string) => str.toLowerCase().replace(/\ /g, '-');

export const trackButtonPress = (buttonTitle: string | undefined) => {
  const kebab = toKebabCase(buttonTitle || '');
  trackEvent(`btn-${kebab}`);
};
/**
 * Tracks this event in analytics (& Sentry breadcrumbs)
 * @param eventName - name of event to track
 * @param data - any data you want included, can be an object
 */
export const trackEvent = (eventName: string, data?: any) => {
  Sentry.addBreadcrumb({
    category: eventName,
    message: data,
    level: Sentry.Severity.Info,
  });
  if (data) {
    Amplitude.logEventWithProperties(eventName, data);
  } else {
    Amplitude.logEvent(eventName);
  }

  if (__DEV__) {
    console.log(`⚡️ ${eventName}`, data || '');
  }
};

let isAmplitudeInitialized = false;
export const initializeAmplitude = () => {
  const apiKey = amplitudeApiKey();
  if (isAmplitudeInitialized || !apiKey) {
    return;
  }

  Amplitude.initialize(apiKey).then(() => {
    Amplitude.setUserProperties({
      manifest_version: versionNumber,
    });
  });
  isAmplitudeInitialized = true;
};

export const identifyUser = (
  user: Pick<UserType, 'id' | 'fullName' | 'email'>
) => {
  Amplitude.setUserId(user.id);
  Amplitude.setUserProperties({
    name: user.fullName,
    email: user.email,
  });
  Sentry.setUser({
    id: user.id,
    username: user.fullName,
    email: user.email,
  });
};

export const clearUser = () => {
  Amplitude.clearUserProperties();
  Sentry.setUser(null);
};

type AnyObjectMap = { [key: string]: any };

const sentryScopeFromObject = (
  obj: AnyObjectMap,
  level: Sentry.Severity = Sentry.Severity.Error
) => {
  const scope = new Sentry.Scope();
  scope.setLevel(level);
  Object.entries(obj).forEach(([key, val]) => scope.setTag(key, val));
  return scope;
};

export const handleApolloError = (error: ApolloError) => {
  console.error('GraphQL Error', error);
  Sentry.captureException(error.message, sentryScopeFromObject(error));
};

export const handleError = (error: any, extraData?: AnyObjectMap) => {
  console.error(error, extraData);
  Sentry.captureException(error, sentryScopeFromObject(extraData || {}));
};

export const handleWarning = (warning: string, extraData?: AnyObjectMap) => {
  console.warn(warning, extraData);
  Sentry.captureMessage(
    warning,
    sentryScopeFromObject(extraData || {}, Sentry.Severity.Warning)
  );
};

export const createErrorCatcher = (caller: string) =>
  function (err: any) {
    handleError(err, { caller });
  };
