// author: @fullstackdevs14
import React from 'react';
import styled from 'styled-components/native';

import { colors } from '../../constants/theme';
import { Text, Row } from '../styled';

const TextContainer = styled.View`
  padding: 5px 12px;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  background-color: ${(props: { backgroundColor: string }) =>
    props.backgroundColor};
`;

const Separator = styled.View`
  position: absolute;
  top: 50%;
  width: 100%;
  height: 1px;
  background-color: ${colors.purpley};
  z-index: 0;
`;

interface Props {
  backgroundColor: string;
}

const UnlockTasksCount: React.FC<Props> = ({ backgroundColor }) => {
  return (
    <Row justifyContent="center">
      <Separator />
      <TextContainer backgroundColor={backgroundColor}>
        <Text size={12}>Next unlock</Text>
      </TextContainer>
    </Row>
  );
};

export default UnlockTasksCount;
