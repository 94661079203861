import React, { useContext } from 'react';

import {
  Box,
  CourseMapHeader,
  CourseMapList,
  Scrollable,
  colors,
  theme,
  useIsLandscape,
} from 'sonora-design-system';
import { LearningFolder, NodeId } from 'sonora-types';

import { RootNavScreen } from '../screens';

import { MentorContext } from 'sonora-containers/index';
import { useTrackEventOnMount } from 'sonora-hooks/useTrackEventOnMount';

export const MentorCourseMapDetails: RootNavScreen<'CourseMapDetails'> = ({
  route,
  navigation,
}) => {
  const { folderId } = route.params;
  const { curriculum } = useContext(MentorContext);

  useTrackEventOnMount('mount-course-map-details', { folderId });
  const folder: LearningFolder = curriculum.contents.find(
    (folder: LearningFolder) => folder.id === folderId
  ) as LearningFolder;

  const isLandscape = useIsLandscape();
  const levelPadding = isLandscape ? theme.coursemap.levelPadding : 0;
  const loadActivity = (id: NodeId) => {
    navigation.navigate('L2', { id });
  };

  return (
    <Box backgroundColor={colors.white} flex={1} alignItems="center">
      <CourseMapHeader
        title={folder.name}
        isMentor={true}
        backgroundColor={colors.white}
        progressBackgroundColor={colors.purpley}
        textColor={colors.tanzanite}
        hasBorder={true}
        titleColor={colors.tanzanite}
        hasBackButton
        onBack={() => {
          navigation.goBack();
        }}
        isMainHeader
      />
      <Box flex={1} width="100%" maxWidth={804} alignItems="center">
        <Box maxWidth={548} width="100%" flex={1}>
          <Scrollable padding={24}>
            <CourseMapList
              items={folder.children}
              isMentor={true}
              levelPadding={levelPadding}
              onItemPress={loadActivity}
              progress={{}}
              unlockedNodeIds={[]}
            />
          </Scrollable>
        </Box>
      </Box>
    </Box>
  );
};
