import React, { FC } from 'react';
import { convertHexToRGBA } from '../../util/colors';
import { colors, fonts } from '../../constants/theme';
import { NodeViewTabsProps } from './types';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
const Tab = createMaterialTopTabNavigator();

const tabBarOptions = {
  activeTintColor: colors.tanzanite,
  inactiveTintColor: convertHexToRGBA(colors.tanzanite, 70),
  labelStyle: {
    fontFamily: fonts.normal,
  },
  indicatorStyle: {
    backgroundColor: colors.tanzanite,
  },
  style: {
    shadowColor: colors.purpley,
    shadowOpacity: 1,
    marginTop: 24,
  },
};

const sceneContainerStyle = {
  backgroundColor: colors.white,
  padding: 24,
};

export const NodeViewTabs: FC<NodeViewTabsProps> = ({ tabs }) => {
  return (
    <Tab.Navigator
      tabBarOptions={tabBarOptions}
      sceneContainerStyle={sceneContainerStyle}
    >
      {tabs.map((tab) => (
        <Tab.Screen
          key={tab.tabKey}
          name={tab.tabKey}
          // component={tab.component}
          options={{
            tabBarLabel: tab.tabLabel,
            title: tab.pageTitle,
          }}
        >
          {tab.component}
        </Tab.Screen>
      ))}
    </Tab.Navigator>
  );
};
