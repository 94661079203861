import * as ApolloReactCommon from '@apollo/react-common';
import {
  CreateMessageMutation,
  CreateMessageMutationVariables,
  ChatThreadQuery,
  ChatThreadQueryVariables,
  MessageWithAttachmentsFragment,
} from '../../graphql/types';
import { MutationTuple } from '@apollo/react-hooks';
import { handleError } from 'sonora-services/analytics';
import { ChatUserType } from 'sonora-types';
import { IMessage as GiftedMessage } from 'react-native-gifted-chat';

type CreateMessageFunction = MutationTuple<
  CreateMessageMutation,
  CreateMessageMutationVariables
>[0];

export const createOnSend = (
  createMessage: CreateMessageFunction,
  currThread: ApolloReactCommon.QueryResult<
    ChatThreadQuery,
    ChatThreadQueryVariables
  >,
  currentThreadId: number,
  chatUser: ChatUserType,
  forceRerender: () => void
) => {
  const addMessage = (message: GiftedMessage) => {
    currThread.updateQuery((prevData) => {
      if (!prevData.messageThread || !prevData.messageThread.messages)
        return prevData;

      const pendingMessage: MessageWithAttachmentsFragment = {
        __typename: 'MessageType' as 'MessageType', // ts silly
        id: String(message._id),
        createdAt: message.createdAt,
        attachments: [],
        complete: false,
        contents: message.text,
        read: false,
        sender: {
          __typename: 'UserType' as 'UserType',
          id: chatUser.id.toString(),
          fullName: chatUser.fullName,
          firstName: 'FIRST',
          lastName: 'LAST',
          email: '',
          userType: null,
        },
      };

      prevData.messageThread.messages.push(pendingMessage);
      return prevData;
    });
  };

  const updateSentMessage = (pendingId: string, newId: string) => {
    currThread.updateQuery((prevData) => {
      if (!prevData.messageThread || !prevData.messageThread.messages)
        return prevData;
      const index = prevData.messageThread.messages.findIndex(
        (message) => message && message.id === pendingId
      );

      if (index < 0) return prevData;
      if (!prevData.messageThread.messages[index]) return prevData;
      prevData.messageThread.messages[index]!.id = newId;
      prevData.messageThread.messages[index]!.complete = true;

      return prevData;
    });
    forceRerender();
  };

  const onSend = (newMessages: GiftedMessage[]) => {
    newMessages.forEach((newMessage) => {
      const originalGiftedId = newMessage._id;
      addMessage(newMessage);

      createMessage({
        variables: {
          messageThreadId: currentThreadId,
          contents: newMessage.text,
        },
      })
        .then((response) => {
          if (!response.data || !response.data.createMessage)
            throw new Error('did not create message');

          const databaseId = response.data.createMessage.message.id;
          updateSentMessage(String(originalGiftedId), databaseId);
        })
        .catch((err) => {
          handleError('createOnSend mutation error', { err });
        });
    });
  };
  return onSend;
};
