import React, { FC, useState, useEffect } from 'react';
import { ActiveModificationsMap } from 'sonora-design-system/components/ModificationDetailView/types';
import { NodeId } from 'sonora-types';

export { ActiveModificationsMap };

export interface ModificationsProviderProps {
  modificationsDrawerEnabled: boolean;
  nodeId: NodeId;
}

interface ModificationsProviderContextState {
  activeModificationsMap: ActiveModificationsMap;
  setActiveModificationsMap: (modificationsMap: ActiveModificationsMap) => void;
  modificationsDrawerEnabled: boolean;
  nodeId: NodeId;
}

export const ModificationsProviderContext = React.createContext<
  ModificationsProviderContextState
>({} as ModificationsProviderContextState);

export const ModificationsProvider: FC<ModificationsProviderProps> = (
  props
) => {
  useEffect(() => {
    resetModifications();
  }, [props.nodeId]);

  const resetModifications = () => {
    setActiveModificationsMap({});
  };

  const [activeModificationsMap, setActiveModificationsMap] = useState({});

  return (
    <ModificationsProviderContext.Provider
      value={{
        modificationsDrawerEnabled: props.modificationsDrawerEnabled,
        activeModificationsMap,
        setActiveModificationsMap,
        nodeId: props.nodeId,
      }}
    >
      {props.children}
    </ModificationsProviderContext.Provider>
  );
};
