import React from 'react';
import { Bubble } from 'react-native-gifted-chat';

import { theme } from '../../../constants/theme';

const wrapperStyle = {
  left: {
    backgroundColor: theme.chat.leftBubble.backgroundColor,
    borderBottomLeftRadius: 0,
    padding: 12,
    minWidth: 120,
    marginLeft: 42,
    marginBottom: 12,
    maxWidth: 394,
  },
  right: {
    backgroundColor: theme.chat.rightBubble.backgroundColor,
    borderBottomRightRadius: 0,
    padding: 12,
    minWidth: 120,
    marginRight: 42,
    marginBottom: 12,
    maxWidth: 394,
  },
};

const textStyle = {
  left: {
    color: theme.chat.leftBubble.textColor,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    flexShrink: 1,
  },
  right: {
    color: theme.chat.rightBubble.textColor,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    flexShrink: 1,
  },
};

const containerStyles = {
  maxWidth: 482,
  width: '100%',
  marginHorizontal: 'auto',
};

export const RenderSystemMessage: React.FC = (props) => (
  <Bubble
    {...props}
    renderTime={() => null}
    renderTicks={() => null}
    textStyle={textStyle}
    wrapperStyle={wrapperStyle}
    containerStyle={{
      left: containerStyles,
      right: containerStyles,
    }}
  />
);
