import React from 'react';
import { storiesOf } from '@storybook/react-native';
import { Box } from '../styled';
import { Alert } from './index';
import { colors } from '../../constants/theme';

const alertStories = storiesOf('Alerts', module);

const AlertStory = () => {
  return (
    <Box padding={30} alignItems="center" backgroundColor={colors.agate}>
      <Alert
        icon="timer"
        title="Free 7-day trial: 1 day remaining"
        message="The card ending in **1234 will be charged $00 on MM/DD/YY"
      />
      <Box height="20px" />
      <Alert
        icon="alert"
        title="Your payment was declined"
        message="Please update your payment method, or choose a different payment method and try again."
      />
    </Box>
  );
};

alertStories.add('Default', AlertStory);
