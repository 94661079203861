import React from 'react';
import { storiesOf } from '@storybook/react-native';
import { NavigationContainer } from '@react-navigation/native';

import { fonts } from '../../constants/theme';
import { Box, Text } from '../styled';
import { VideoNodeView } from '.';

const videoNodeViewStories = storiesOf('VideoNodeView', module);

const Overview = () => {
  return (
    <Box>
      <Box marginBottom={16}>
        <Text font={fonts.bold} size={16}>
          Module 1 - Major Scale: Lorem Ipsum Video
        </Text>
      </Box>
      <Box marginBottom={12}>
        <Text size={16}>
          1. Practice this form in “C” until you can play it up and down fludily
          with a metronome
        </Text>
      </Box>
      <Box>
        <Text size={16}>
          2. Then begin as low as you can (Key of F#), and ascend keys
          chromatically up the neck as high as you can go.
        </Text>
      </Box>
    </Box>
  );
};

const SupplementalMaterials = () => {
  return (
    <Box>
      <Box marginBottom={16}>
        <Text font={fonts.bold} size={16}>
          Module 1 - Major Scale: Lorem Ipsum Video
        </Text>
      </Box>
      <Box marginBottom={12}>
        <Text size={16}>
          1. Practice this form in “C” until you can play it up and down fludily
          with a metronome
        </Text>
      </Box>
      <Box>
        <Text size={16}>
          2. Then begin as low as you can (Key of F#), and ascend keys
          chromatically up the neck as high as you can go.
        </Text>
      </Box>
    </Box>
  );
};

const makeLessonViewStory = (props: any) => () => (
  <NavigationContainer>
    <VideoNodeView
      title=""
      vimeoVideoId="452261270"
      primaryCta={{
        text: 'Continue',
        icon: 'check',
        iconStyle: 'stroke',
        onPress: () => {
          console.log('Mark Complete clicked');
        },
      }}
      tabs={[
        {
          tabKey: 'Overview',
          tabLabel: 'Overview',
          pageTitle: 'Activity Overview',
          component: Overview,
        },
        {
          tabKey: 'SupplementalMaterial',
          tabLabel: 'Supplemental Material',
          pageTitle: 'Activity Supplemental',
          component: SupplementalMaterials,
        },
      ]}
      {...props}
    />
  </NavigationContainer>
);

videoNodeViewStories.add(
  'Story 1',
  makeLessonViewStory({
    secondaryCta: {
      text: '+ Practice Post',
      onPress: () => {
        console.log('Practice Post clicked');
      },
    },
  })
);

videoNodeViewStories.add('Story 2', makeLessonViewStory({}));
