// author: @fullstackdevs14
import React from 'react';
import styled from 'styled-components/native';
import { TouchableOpacity } from 'react-native';

import { Row, Text } from '../styled';
import { fonts, colors } from '../../constants/theme';

const TextContainer = styled.View`
  padding: 4px 12px 6px;
  flex-direction: row;
  align-items: center;
  background-color: ${colors.white};
  z-index: 1;
  border-radius: 15px;
`;

const Separator = styled.View`
  position: absolute;
  top: 50%;
  width: 100%;
  height: 1px;
  background-color: ${colors.purpley};
  z-index: 0;
`;

interface Props {
  show?: boolean;
  onToggle?: () => void;
}

const CompletedTasksCount: React.FC<Props> = ({ show, onToggle }) => {
  return (
    <Row justifyContent="center">
      <Separator />
      <TextContainer>
        <TouchableOpacity onPress={onToggle}>
          <Text>
            <Text size={12}>{show ? 'Hide ' : 'Show '}</Text>
            <Text font={fonts.thin} size={12}>
              last practiced
            </Text>
          </Text>
        </TouchableOpacity>
      </TextContainer>
    </Row>
  );
};

export default CompletedTasksCount;
