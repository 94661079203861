import React from 'react';
import { shortYearDatestring } from 'sonora-util/dateUtils';
import { Box, Row, Text } from '../styled';
import { colors, fonts } from '../../constants/theme';
import { CardContainer } from './styled';
import { ITransactionHistory } from 'sonora-types';

export interface TransactionHistoryProps {
  details: ITransactionHistory;
}

export const TransactionHistory: React.FC<TransactionHistoryProps> = ({
  details,
}) => {
  const isInstallments = details.type === 'installments';
  const isPremium = details.type === 'premium';
  const price = details.billingAmount/100;

  return (
    <CardContainer>
      <Box>
        <Text font={fonts.thin} size={16} marginBottom={4}>
          {shortYearDatestring(details.date)}
        </Text>
        <Text size={16} marginBottom={4}>
          {isPremium
            ? 'Fellowship subscription'
            : 'Guitar Intensive - Pro access'}
        </Text>
        {details.status != "failed" ? (
          <Text font={fonts.thin} size={16}>
            {isPremium
              ? `${details.billingCycle} weeks`
              : isInstallments
              ? `Installment ${details.currentInstallment} of 5`
              : 'Upfront'}
          </Text>
        ) : (
          <Text font={fonts.thin} color={colors.alertRed} size={16}>
            {details.failure_message}
          </Text>
        )}
      </Box>
          <Row>
            <Text font={fonts.thin} color={colors.purpley} size={24}>
              $
            </Text>
            <Text color={colors.tanzanite} textDecoration={details.status == "failed" ? "line-through" : ""} size={24} marginRight={5}>
              {price}
            </Text>
          </Row>
    </CardContainer>
  );
};
