import {
  useCompleteMessageAttachmentMutation,
  usePublishMessageMutation,
} from 'sonora-graphql/types';
import { handleError, trackEvent } from 'sonora-services/analytics';

type FinalizeSubmissionMutationFn = ({
  messageId,
  attachmentId,
}: {
  messageId: number;
  attachmentId: number;
}) => Promise<any>;

export interface FinalizeSubmissionOptions {
  options?: {
    isZiggeoSubmission?: boolean;
  };
}
/**
 * After update is complete, publish & complete the message, trigger notification, etc.
 */
export function useFinalizeSubmissionMutation({
  options = {},
}: FinalizeSubmissionOptions) {
  // Don't use the completeAttachment mutation for ziggeo submissions
  // since that is taken care of by ziggeo's hooks
  const doComplete = options && options.isZiggeoSubmission ? false : true;

  const [publishMessageMutation] = usePublishMessageMutation();
  const [completeAttachmentMutation] = useCompleteMessageAttachmentMutation();

  const publishMessage = (messageId: number) =>
    publishMessageMutation({
      variables: {
        messageId,
      },
      // refetchQueries: ['ChatThread'],
    });

  const completeAttachment = (attachmentId: number) =>
    completeAttachmentMutation({
      variables: {
        attachmentId,
      },
      // refetchQueries: ['ChatAllThreads'],
    });

  const finalizeSubmissionMutation: FinalizeSubmissionMutationFn = ({
    messageId,
    attachmentId,
  }) => {
    const promiseArray: Promise<any>[] = [publishMessage(messageId)];
    trackEvent('prep-publish-message-mutation', { messageId });
    if (doComplete) {
      trackEvent('prep-complete-attachment-mutation', { attachmentId });
      promiseArray.push(completeAttachment(attachmentId));
    }
    return Promise.all(promiseArray).catch((err) =>
      handleError('finalize submission mutation error', {
        err,
        messageId,
        attachmentId,
      })
    );
  };

  return finalizeSubmissionMutation;
}
