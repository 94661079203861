import React from 'react';
import { Linking } from 'react-native';
import { Box, Row, Text } from '../styled';
import { Button } from '../Button';
import { colors, fonts } from '../../constants/theme';
import { PaymentPlanInstallment, PaymentPlanUpfront } from './PaymentPlanCard';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { noop } from '../../util';

export type PaymentPlanType = 'installment' | 'upfront';

export interface PaymentSelectProps {
  selectedPlan?: PaymentPlanType;
  setSelectedPlan?: (plan: PaymentPlanType) => void;
  onSave: () => void;
}

export const PaymentSelect: React.FC<PaymentSelectProps> = ({
  selectedPlan = 'installment',
  setSelectedPlan = noop,
  onSave = noop,
}) => {
  const handleContactSupport = () => {
    Linking.openURL('mailto:contact@guitarmasteryintensive.com');
  };

  return (
    <Box padding={36} backgroundColor={colors.white}>
      <Text
        font={fonts.headline}
        size={24}
        marginBottom={36}
        textAlign="center"
      >
        Choose payment plan
      </Text>
      <Row justifyContent="center" alignItems="stretch" marginBottom={36}>
        <PaymentPlanInstallment
          date={new Date()}
          selected={selectedPlan === 'installment'}
          onSelect={() => setSelectedPlan('installment')}
        />
        <Box width="16px" />
        <PaymentPlanUpfront
          date={new Date()}
          selected={selectedPlan === 'upfront'}
          onSelect={() => setSelectedPlan('upfront')}
        />
      </Row>
      <Box alignItems="center" marginBottom={24}>
        <Button type="Special" text="Save changes" onPress={onSave} />
      </Box>
      <Row justifyContent="center">
        <Text size={16} marginRight={4}>
          Need help?
        </Text>
        <Text font={fonts.thin} size={16} marginRight={4}>
          Contact
        </Text>
        <TouchableOpacity onPress={handleContactSupport}>
          <Text font={fonts.thin} size={16} color={colors.neutralBlue}>
            support
          </Text>
        </TouchableOpacity>
        <Text>.</Text>
      </Row>
    </Box>
  );
};
