import React from 'react';

import { StrokeIcon } from '../../Icon';
import { colors } from '../../../constants/theme';
import { convertHexToRGBA } from '../../../util';

export const ScrollToBottom = () => (
  <StrokeIcon name="caretDown" size="metronome" color={colors.white} />
);

export const scrollToBottomStyle = {
  width: 54,
  height: 54,
  backgroundColor: convertHexToRGBA(colors.amethyst, 60),
  borderRadius: 27,
};
