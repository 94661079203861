import React from 'react';
import Svg, { Rect } from 'react-native-svg';

export const CardIcon = () => (
  <Svg width={20} height={12} viewBox="0 0 20 12">
    <Rect width={20} height={12} rx={2} fill="#E1E0E3" />
    <Rect
      x={1.54297}
      y={8}
      width={3.07692}
      height={1.33333}
      rx={0.666667}
      fill="#B4B8C6"
    />
    <Rect
      x={6.15625}
      y={8}
      width={3.07692}
      height={1.33333}
      rx={0.666667}
      fill="#B4B8C6"
    />
    <Rect
      x={10.7734}
      y={8}
      width={3.07692}
      height={1.33333}
      rx={0.666667}
      fill="#B4B8C6"
    />
    <Rect
      x={15.3906}
      y={8}
      width={3.07692}
      height={1.33333}
      rx={0.666667}
      fill="#B4B8C6"
    />
    <Rect
      x={1.53906}
      y={2.6665}
      width={4.61538}
      height={2.66667}
      rx={1.33333}
      fill="white"
    />
  </Svg>
);
