import styled from 'styled-components/native';

import { colors, fonts } from '../../constants/theme';
import {
  AnnouncementHeadlineProps,
  AnnouncementDescriptionProps,
} from './types';

export const AnnouncementHeadline = styled.Text`
  font-family: ${fonts.headline};
  color: ${colors.white};
  ${(props: AnnouncementHeadlineProps) =>
    props.size === 'web'
      ? `
      font-size: 26.4px;
      margin-right: 12px;
    `
      : `
      font-size: 18px;
      margin-bottom: 4px;
    `}
`;

export const AnnouncementDescription = styled.Text`
  font-family: ${fonts.thin};
  color: ${colors.white};
  ${(props: AnnouncementDescriptionProps) =>
    props.size === 'web'
      ? `
      font-size: 19.8px;
      margin-right: 12px;
    `
      : `
      font-size: 14px;
    `}
`;
