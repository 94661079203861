import React from 'react';
import { storiesOf } from '@storybook/react-native';
import { Logo } from '.';
import { View } from 'react-native';
import { colors } from '../../constants/theme';

const logoStories = storiesOf('Logo', module);

logoStories.add('default', () => (
  <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
    <Logo />
  </View>
));

logoStories.add('amethyst', () => (
  <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
    <Logo variant="amethyst" />
  </View>
));

logoStories.add('ruby', () => (
  <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
    <Logo variant="ruby" />
  </View>
));

logoStories.add('white', () => (
  <View
    style={{
      backgroundColor: colors.tanzanite,
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Logo variant="white" />
  </View>
));

logoStories.add('black', () => (
  <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
    <Logo variant="black" />
  </View>
));
