import React, { useContext } from 'react';
import { storiesOf } from '@storybook/react-native';

import { Box } from '../styled';
import { Scrollable } from '../Scrollable';
import { theme, colors } from '../../constants/theme';
import { CourseMapHeader, CourseMapMainFolder, CourseMapList } from '.';

import {
  LayoutProvider,
  LayoutProviderContext,
  LayoutType,
} from '../../util/LayoutProvider';
import { mockCourseMapMainFolders, curricv2, curricMap } from './mock-data';
import { useRandomCurriculumProgress } from './utils';

const coursemapStories = storiesOf('CourseMapComponents', module);

const CourseMapOverviewStory = () => (
  <LayoutProvider>
    <Box flex={1} alignItems="center">
      <CourseMapHeader
        title="Guitar Mastery Intensive"
        letter="M"
        isMainHeader
        studentInfo={{
          numCompletedModules: 1,
          numAllModules: 8,
          numUnlockedStars: 20,
          numAllStars: 534,
          completePercent: 10,
        }}
        hasBorder={true}
        titleColor={colors.amethyst}
      />
      <Box maxWidth={548} flex={1}>
        <Scrollable padding={24}>
          {mockCourseMapMainFolders.map((folderProps, i) => (
            <CourseMapMainFolder key={i} {...folderProps} isMentor />
          ))}
        </Scrollable>
      </Box>
    </Box>
  </LayoutProvider>
);

const CourseMapListStory = () => {
  const progress = useRandomCurriculumProgress(curricMap);
  const { activeLayout } = useContext(LayoutProviderContext);

  const levelPadding =
    activeLayout === LayoutType.Landscape ? theme.coursemap.levelPadding : 0;

  return (
    <Box flex={1} alignItems="center">
      <CourseMapHeader
        title="The 5 Forms"
        studentInfo={{
          numCompletedModules: 1,
          numAllModules: 8,
          numUnlockedStars: 20,
          numAllStars: 534,
          completePercent: 10,
        }}
        backgroundColor={colors.amethyst}
        textColor={colors.white}
        subtitleColor={colors.white}
        titleColor={colors.white}
        hasBackButton
        onBack={() => {
          console.log('Go back');
        }}
        isMentor
      />
      <Box flex={1} width="100%" maxWidth={804} alignItems="center">
        <Box maxWidth={548} width="100%" flex={1}>
          <Scrollable padding={24}>
            <CourseMapList
              unlockedNodeIds={['1', '2', '3', '4', '7']}
              items={curricv2.contents}
              progress={progress}
              levelPadding={levelPadding}
              onItemPress={(nodeId: string) => {
                console.log('press', nodeId);
              }}
              isMentor
            />
          </Scrollable>
        </Box>
      </Box>
    </Box>
  );
};

coursemapStories.add('Overview', CourseMapOverviewStory);
coursemapStories.add('DetailView', () => (
  <LayoutProvider>
    <CourseMapListStory />
  </LayoutProvider>
));
