import { useState, useEffect } from 'react';
import {
  LearningFolder,
  LearningActivity,
  Node,
  CurriculumProgress,
} from 'sonora-types';

export const calculateFolderStars = (
  folder: LearningFolder,
  progress: CurriculumProgress
): number => {
  return folder.children.reduce((sum: number, node: Node) => {
    if (node.type === 'Folder') {
      return sum + calculateFolderStars(node, progress);
    }

    return sum + (progress[node.id] ? (progress[node.id].currentStars >= 5 ? 5 : progress[node.id].currentStars) : 0);
  }, 0);
};

export const calculateFolderMaxStars = (folder: LearningFolder): number => {
  return folder.children.reduce((sum: number, node: Node) => {
    if (node.type === 'Folder') {
      return sum + calculateFolderMaxStars(node);
    }

    return sum + node.maxStars;
  }, 0);
};

export const useRandomCurriculumProgress = (curricMap: any) => {
  const [progress, setProgress] = useState<CurriculumProgress>({});

  useEffect(() => {
    const newProgress: CurriculumProgress = {};

    const keys = Object.keys(curricMap);

    keys.forEach((nodeId: string) => {
      newProgress[nodeId] = {
        currentStars: Math.round(
          Math.random() * (curricMap[nodeId] as LearningActivity).maxStars
        ),
        lastPracticed: new Date(),
      };
    });

    setProgress(newProgress);
  }, []);

  return progress;
};
