import React, { useState } from 'react';
import {
  Box,
  colors,
  ModalFunctionComponent,
  PaymentPlanType,
  PaymentSelect,
} from 'sonora-design-system';

export interface PaymentEditModalData {
  selectedPlan?: PaymentPlanType;
}

export const PaymentEditModal: ModalFunctionComponent = ({ dismissModal }) => {
  const [selectedPlan, setSelectedPlan] = useState<PaymentPlanType>(
    'installment'
  );

  return (
    <Box
      flex={1}
      backgroundColor={colors.white}
      justifyContent="center"
      alignItems="center"
    >
      <PaymentSelect
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
        onSave={() => dismissModal('Success', { selectedPlan })}
      />
    </Box>
  );
};
