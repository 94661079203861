import React from 'react';

import { Text, Box } from '../styled';
import { InputField } from '../InputField';
import { Button } from '../Button';
import { fonts, colors } from '../../constants/theme';
import { AuthFormWrapper } from './AuthFormWrapper';
import { LoginFormProps } from './types';
import { initialState, formReducer } from './utils';
import { validateEmail } from 'sonora-util/strings';

export const LoginForm: React.FC<LoginFormProps> = ({
  error,
  loading,
  onClearError,
  onSubmit,
  onPressForgot,
}) => {
  const [{ values, errors }, dispatch] = React.useReducer(
    formReducer,
    initialState
  );

  const isFormValid = React.useMemo(
    () => validateEmail(values.email) && values.password,
    [values]
  );

  const handleEmailTextChange = (value: string) => {
    value = value.toLowerCase();
    dispatch({
      type: 'SET_VALUE',
      field: 'email',
      payload: value,
    });

    if (!validateEmail(value)) {
      dispatch({
        type: 'SET_ERROR',
        field: 'email',
        payload: 'Please enter a valid email address',
      });
    } else {
      dispatch({
        type: 'SET_ERROR',
        field: 'email',
        payload: '',
      });
    }

    if (error && onClearError) {
      onClearError();
    }
  };

  const handlePasswordTextChange = (value: string) => {
    dispatch({
      type: 'SET_VALUE',
      field: 'password',
      payload: value,
    });

    if (!value || value.length === 0) {
      dispatch({
        type: 'SET_ERROR',
        field: 'password',
        payload: 'Password is required',
      });
    } else {
      dispatch({
        type: 'SET_ERROR',
        field: 'password',
        payload: '',
      });
    }

    if (error && onClearError) {
      onClearError();
    }
  };

  return (
    <AuthFormWrapper>
      <Text font={fonts.bold} size={20} marginBottom={36}>
        Sign in to your account
      </Text>
      <InputField
        label="Email"
        value={values.email || ''}
        error={errors.email}
        hasExternalError={!!error}
        textContentType="emailAddress"
        autoCapitalize="none"
        onChangeText={handleEmailTextChange}
      />
      <InputField
        label="Password"
        value={values.password || ''}
        error={errors.password}
        hasExternalError={!!error}
        onChangeText={handlePasswordTextChange}
        secureTextEntry={true}
      />

      {error ? (
        <Box paddingLeft={22} marginTop={-10}>
          <Text color={colors.alertRed} font={fonts.thin}>
            {error}
          </Text>
        </Box>
      ) : null}

      <Box marginBottom={36} marginTop={16}>
        <Button
          type="Special"
          text="SIGN IN"
          onPress={() => {
            if (onSubmit) {
              onSubmit(values);
            }
          }}
          disabled={!isFormValid}
        />
      </Box>

      <Button type="Tertiary" text="Forgot Password" onPress={onPressForgot} />
    </AuthFormWrapper>
  );
};
