import React, { FC, useContext } from 'react';
import { View, StyleSheet, Text, Platform } from 'react-native';
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';

import { colors, FillIcon } from 'sonora-design-system';
import { L1Screens, MentorL1Screens } from '../levels/L1Screen';
import {
  StudentContext,
  L1NavigationContext,
  UserContext,
} from 'sonora-containers/index';

const Tab = createMaterialBottomTabNavigator();

export interface L1PortraitTabNavigatorProps {}

const L1PortraitTabNavigator: FC<L1PortraitTabNavigatorProps> = (props) => {
  const { userType, payment } = useContext(UserContext);
  const { hasUnreadMessages, studentType } = useContext(StudentContext);
  const { activeIndex } = useContext(L1NavigationContext);

  const updateL1Screens = payment
    ? payment.coreSubscription?.cancelled
      ? L1Screens.filter((screen) => screen.name === 'Account')
      : L1Screens
    : L1Screens.filter((screen) => screen.name !== 'Account');
  let screens = userType === 'mentor' ? MentorL1Screens : updateL1Screens;

  if (studentType == "LT") {
    screens = screens.filter((screen) => !screen.requiresHighTicket);
  }

  return (
    <Tab.Navigator
      activeColor={colors.white}
      inactiveColor={colors.thulite}
      barStyle={{ backgroundColor: colors.tanzanite }}
      shifting={true}
      initialRouteName={screens[activeIndex].name}
    >
      {screens.map((screen) => {
        let hasBadge = Boolean(
          screen.name === 'MentorChat' && hasUnreadMessages
        );
        return (
          <Tab.Screen
            key={screen.name}
            name={screen.name}
            component={screen.component}
            options={{
              tabBarIcon: () => (
                <View>
                  {hasBadge ? <View style={styles.badge} /> : undefined}
                  <FillIcon
                    name={screen.iconName}
                    color={colors.white}
                    size="nav"
                  />
                </View>
              ),
              title: screen.label,
              tabBarLabel:
                Platform.OS === 'web'
                  ? ((
                      <View
                        style={{
                          width: '100%',
                          justifyContent: 'center',
                          flexDirection: 'row',
                        }}
                      >
                        <Text>{screen.label}</Text>
                      </View>
                    ) as any)
                  : screen.label,
            }}
          />
        );
      })}
    </Tab.Navigator>
  );
};

const styles = StyleSheet.create({
  badge: {
    position: 'absolute',
    top: 3,
    right: 3,
    backgroundColor: colors.validGreen,
    height: 8,
    width: 8,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.tanzanite,
  },
});

export default L1PortraitTabNavigator;
