import React, { useRef, useEffect, useContext } from 'react';
import { TouchableOpacity } from 'react-native';
import { default as WebView } from 'react-native-webview';

import { fonts, colors } from '../../constants/theme';
import { ModalPresenterContext } from '../../util/ModalProvider';

import { Text, Box, Row } from '../styled';
import { VimeoPlayer, stopAudioVideo } from '../VimeoPlayer';
import { FillIcon } from '../Icon';
import { ModificationDetailViewProps } from './types';
import { ModificationItem } from './ModificationItem';
import { Scrollable } from '../Scrollable';
import { SectionHeader } from '../SectionHeader';

export const ModificationDetailView: React.FC<ModificationDetailViewProps> = ({
  categoryLabel,
  vimeoId,
  description,
  modifications,
  selectedModificationId,
  onSelectModification,
  onPlayModificationVideo,
  onBack,
}) => {
  const vimeoRef = useRef<HTMLIFrameElement | WebView>(null);
  const { modalIsVisible } = useContext(ModalPresenterContext);

  useEffect(() => {
    if (modalIsVisible) {
      stopAudioVideo(vimeoRef);
    }
  }, [modalIsVisible]);

  // Typing VimeoPlayer ref as any due to changing ref based on Platform
  return (
    <Box height="100%">
      <TouchableOpacity onPress={onBack}>
        <Row padding={12}>
          <FillIcon name="pageLeft" />
          <Text font={fonts.bold} size={18} lineHeight="24">
            {categoryLabel}
          </Text>
        </Row>
      </TouchableOpacity>
      <Scrollable padding={12} paddingTop={0}>
        <Box flex="none" marginBottom={12}>
          <VimeoPlayer ref={vimeoRef as any} vimeoVideoID={vimeoId} />
        </Box>
        <Text font={fonts.thin} marginBottom={12}>
          {description}
        </Text>
        <SectionHeader backgroundColor={colors.agate} title="Select one" />
        {modifications.map((m, i) => (
          <ModificationItem
            key={m.id}
            modification={m}
            isSelected={m.id === selectedModificationId}
            onPress={() => onSelectModification(m.id)}
            onPlayVideo={() => onPlayModificationVideo(m)}
          />
        ))}
      </Scrollable>
    </Box>
  );
};
