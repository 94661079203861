import React, { FC } from 'react';
import {
  View,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
} from 'react-native';
import { BlurView } from 'expo-blur';

import { convertHexToRGBA } from '../../util/colors';
import { ModalBlurViewProps } from './types';

const ModalBlurView: FC<ModalBlurViewProps> = (props) => {
  if (Platform.OS === 'web') {
    // If these are declared outside of this if statement, mobile crashes
    const webStyles = StyleSheet.create({
      blurEffect: {
        backdropFilter: 'blur(4px)',
        backgroundColor: convertHexToRGBA('#1B0646', 50),
      },
    });
    return (
      <View style={styles.blurView}>
        <TouchableWithoutFeedback
          onPress={() => {
            console.log('cancel button');

            props.dismissModal('Cancel');
          }}
        >
          <View
            style={[
              webStyles.blurEffect,
              { height: '100%', width: '100%', position: 'absolute' },
            ]}
          ></View>
        </TouchableWithoutFeedback>
        {props.children}
      </View>
    );
  } else {
    return (
      <View style={[styles.blurView, styles.opacityColor]}>
        <TouchableWithoutFeedback onPress={() => props.dismissModal('Cancel')}>
          <BlurView
            tint="default"
            intensity={75}
            style={{ height: '100%', width: '100%', position: 'absolute' }}
          ></BlurView>
        </TouchableWithoutFeedback>
        {props.children}
      </View>
    );
  }
};

const styles = StyleSheet.create({
  opacityColor: {
    backgroundColor: convertHexToRGBA('#1B0646', 50),
  },
  blurView: {
    zIndex: 999,
    flex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default ModalBlurView;
