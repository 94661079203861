import React from 'react';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

import { FillIcon } from '../../Icon';
import { Box } from '../../styled';
import { colors } from '../../../constants/theme';

const RecordVideoButtonWrapper = styled.View`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: ${colors.tanzanite};
  align-items: center;
  justify-content: center;
  margin-right: 12px;
`;

interface RecordVideoButtonProps {
  onPress: () => void;
}

export const RecordVideoButton: React.FC<RecordVideoButtonProps> = ({
  onPress,
}) => (
  <TouchableOpacity onPress={onPress}>
    <Box justifyContent="flex-start" flex={1} paddingTop={6}>
      <RecordVideoButtonWrapper>
        <FillIcon name="camera" color={colors.white} />
      </RecordVideoButtonWrapper>
    </Box>
  </TouchableOpacity>
);
