import React from 'react';
import styled from 'styled-components/native';
import { Box } from '../styled';
import {
  GradientBorderInnerBackgroundProps,
  GradientBorderProps,
} from './types';
import { colors } from '../../constants/theme';
import { GradientBackground } from './GradientBackground';

const GradientBorderInnerBackground = styled.View`
  position: absolute;
  z-index: 1;
  left: ${(props: GradientBorderInnerBackgroundProps) =>
    props.gradienBorderWidth}px;
  right: ${(props: GradientBorderInnerBackgroundProps) =>
    props.gradienBorderWidth}px;
  top: ${(props: GradientBorderInnerBackgroundProps) =>
    props.gradienBorderWidth}px;
  bottom: ${(props: GradientBorderInnerBackgroundProps) =>
    props.gradienBorderWidth}px;
  ${(props: GradientBorderInnerBackgroundProps) =>
    props.borderRadius ? `border-radius: ${props.borderRadius}px;` : ''}
  ${(props: GradientBorderInnerBackgroundProps) =>
    props.backgroundColor ? `background-color: ${props.backgroundColor};` : ''}
`;

const GradientBorderInner = styled.View`
  z-index: 2;
`;

export const GradientBorder: React.FC<GradientBorderProps> = ({
  children,
  borderWidth,
  innerBorderRadius,
  outerBorderRadius,
  paddingHorizontal,
  paddingVertical,
  backgroundColor,
  clickable,
  ...others
}) => {
  return (
    <Box
      position="relative"
      cursor={clickable ? 'pointer' : 'default'}
      {...others}
    >
      <GradientBackground
        borderRadius={outerBorderRadius}
        colors={[colors.tanzanite, colors.ruby]}
        start={{ x: 0, y: 1 }}
        end={{ x: 1, y: 1 }}
      />
      <GradientBorderInnerBackground
        borderRadius={innerBorderRadius}
        gradienBorderWidth={borderWidth}
        backgroundColor={backgroundColor}
      />
      <GradientBorderInner
        style={{
          paddingHorizontal,
          paddingVertical,
        }}
      >
        {children || null}
      </GradientBorderInner>
    </Box>
  );
};
