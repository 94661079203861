import React, { useState, useContext } from 'react';
import { View, Text, StyleSheet } from 'react-native';

import { RootNavScreen } from '../screens/screens';
import { ZiggeoVideoRecorder } from '../../components/camera/ZiggeoVideoRecorder';

import { ModalPresenterContext } from 'sonora-design-system/util';
import { VideoSubmissionStackExtraData } from './video-submission/VideoSubmissionStack';
import {
  SpecialButton,
  TertiaryButton,
  FillIcon,
  colors,
  Loading,
  fonts,
  LayoutProviderContext,
} from 'sonora-design-system';
import { UserContext } from 'sonora-containers/index';
import { MessageThreadContext } from 'sonora-containers/MessageThreadProvider';
import { useSharedVideoRecorderLogic } from 'sonora-hooks/useVideoCreationLogic';
import { convertHexToRGBA } from 'sonora-design-system/util/colors';

export interface WebVideoRecorderProps {}

export const WebVideoRecorder: RootNavScreen<'WebVideoRecorder'> = (props) => {
  const { userType } = useContext(UserContext);
  const { messageThreadId } = useContext(MessageThreadContext);
  const { screenWidth, screenHeight } = useContext(LayoutProviderContext);

  const [isUploadedToZiggeo, setIsUploaded] = useState(false);
  const [isFinalizing, setIsFinalizing] = useState(false);
  const [uploadSucceeded, setUploadSucceeded] = useState(false);
  const allowSendVideo = !isFinalizing && isUploadedToZiggeo;

  const { extraData, dismissModal } = useContext(ModalPresenterContext);
  const typedExtraData = extraData as VideoSubmissionStackExtraData;
  const isLogProgressFlow = typedExtraData.isLogProgressFlow;
  const isPromptFlow = typedExtraData.isPromptFlow;

  const showSuccess = () => {
    setUploadSucceeded(true);
  };

  const {
    creationResult,
    finalizeSubmissionThenNavigate,
  } = useSharedVideoRecorderLogic({
    isZiggeo: true,
    messageThreadId,
    onFinish: showSuccess,
  });
  if (!creationResult) return <Loading text="Creating video post" />;
  const messageAttachmentId = Number(creationResult.attachment.id);

  const handleUploaded = () => {
    setIsFinalizing(false);
    setIsUploaded(true);
  };
  const resetUpload = () => {
    setIsFinalizing(false);
    setIsUploaded(false);
  };

  const submitPracticePost = () => {
    finalizeSubmissionThenNavigate();
  };

  const UploadSucceededCTA = () => (
    <SpecialButton text="DONE" onPress={() => dismissModal('Success')} />
  );

  const PreUploadActions = () => {
    const showSkip = (isLogProgressFlow || isPromptFlow) && !isFinalizing;
    const onSkip = () => dismissModal('Success');

    return (
      <>
        <SpecialButton
          text="SEND VIDEO"
          disabled={!allowSendVideo}
          onPress={submitPracticePost}
        />
        {showSkip && <TertiaryButton text="SKIP THIS STEP" onPress={onSkip} />}
      </>
    );
  };

  const Actions = uploadSucceeded ? (
    <UploadSucceededCTA />
  ) : (
    <PreUploadActions />
  );

  const recorderWidth = screenWidth >= 416 && screenHeight > 550 ? 416 : 300;
  const recorderHeight = screenWidth >= 416 && screenHeight > 550 ? 312 : 225;

  let headline, subtext;

  if (isLogProgressFlow) {
    headline = 'Share your progress';
    subtext = 'Add a practice video so your mentor can provide feedback';
  } else {
    headline = 'Share a video';
    subtext =
      userType === 'student'
        ? 'Add a practice video so your mentor can provide feedback'
        : 'Send tutorials, tips, techniques and feedback for your student';
  }

  return (
    <View style={{ flex: 1, margin: 36 }}>
      <Text style={styles.headline}>{headline}</Text>
      <Text style={styles.text}>{subtext}</Text>
      <View
        style={{
          flex: 1,
          flexShrink: 0,
          paddingTop: 8,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <View style={{ height: recorderHeight, width: recorderWidth }}>
          <ZiggeoVideoRecorder
            height={recorderHeight}
            width={recorderWidth}
            handleUploaded={handleUploaded}
            resetUpload={resetUpload}
            messageAttachmentId={messageAttachmentId}
          />
          {uploadSucceeded && (
            <View style={styles.ziggeoOverlay}>
              <FillIcon
                name="checkCircle"
                color={colors.validGreen}
                size="xl"
              />
              <Text style={styles.successText}>Success! Video Sent</Text>
            </View>
          )}
        </View>
      </View>
      <View
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: 16,
        }}
      >
        {Actions}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: colors.white,
    padding: 40,
    alignItems: 'center',
  },
  headline: {
    fontFamily: fonts.headline,
    fontSize: 32,
    lineHeight: 36,
    color: colors.tanzanite,
    textAlign: 'center',
  },
  successText: {
    fontSize: 20,
    lineHeight: 31,
    textAlign: 'center',
    fontFamily: fonts.normal,
    color: colors.tanzanite,
    marginBottom: 12,
  },
  text: {
    marginTop: 4,
    fontFamily: fonts.thin,
    color: colors.tanzanite,
    fontSize: 20,
    lineHeight: 26,
    textAlign: 'center',
    marginBottom: 12,
  },
  ziggeoOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: convertHexToRGBA(colors.opal, 100),
  },
});
