import React from 'react';
import { RootNavScreen } from '../screens';
import { useGenerateResetPasswordEmailMutation } from 'sonora-graphql/types';
import { ForgotPasswordForm, KeyValueStore } from 'sonora-design-system';

export interface ForgotPasswordProps {}

const ForgotPassword: RootNavScreen<'ForgotPassword'> = ({ navigation }) => {
  const [generatePassword] = useGenerateResetPasswordEmailMutation();

  const handleSubmit = (formData: KeyValueStore) => {
    generatePassword({ variables: { email: formData.email } })
      .then((result) => {
        if (
          result &&
          result.data &&
          result.data.generatePasswordEmail &&
          result.data.generatePasswordEmail.ok
        ) {
          navigation.navigate('ForgotPasswordSuccess', {
            email: formData.email,
          });
        } else {
          // it failed for unknown reasons
        }
      })
      .catch((err) => {
        // it failed for a known reason
      });
  };

  const handleCancel = () => navigation.goBack();

  return <ForgotPasswordForm onSubmit={handleSubmit} onCancel={handleCancel} />;
};

export default ForgotPassword;
