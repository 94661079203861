import React, { useContext, useState } from 'react';

import {
  CourseMapHeader,
  CourseMapMainFolder,
  Box,
  colors,
  Scrollable,
} from 'sonora-design-system';

import {
  allCurriculumActivities,
  courseMapFolderProps,
  isNodeCompleted,
} from 'sonora-util/curriculaUtil';
import { LearningActivity } from 'sonora-types';
import { ProgressContext, StudentContext } from 'sonora-containers/index';
import { RootNavScreen } from '../screens';
import { useTrackEventOnMount } from 'sonora-hooks/useTrackEventOnMount';

export const StudentCourseMapOverview: RootNavScreen<'CourseMapOverview'> = ({
  route,
  navigation,
}) => {
  const { progress: studentProgress } = useContext(ProgressContext);
  const { curriculum, user } = useContext(StudentContext);
  useTrackEventOnMount('mount-course-map-overview');
  const [hoveredFolder, setHoveredFolder] = useState<string | undefined>();

  const allActivities = allCurriculumActivities(curriculum);
  const completedNodeIds = allActivities
    .filter(isNodeCompleted(studentProgress))
    .map((activity: LearningActivity) => activity.id);
  const allStars = allActivities.reduce(
    (sum, activity) => sum + activity.maxStars,
    0
  );
  const unlockedStars = allActivities.reduce((sum, activity) => {
    const activityProgress = studentProgress[activity.id] || {
      currentStars: 0,
    };
    return sum + (activityProgress.currentStars > 5 ? 5 : activityProgress.currentStars);
  }, 0);

  return (
    <Box backgroundColor={colors.white} flex={1} alignItems="center">
      <CourseMapHeader
        title="Guitar Mastery Intensive"
        letter={user.firstName[0]}
        studentInfo={{
          numCompletedModules: completedNodeIds.length,
          numAllModules: allActivities.length,
          numUnlockedStars: unlockedStars,
          numAllStars: allStars,
          completePercent: Math.round(
            (completedNodeIds.length * 100) / allActivities.length
          ),
        }}
        hasBorder={true}
        titleColor={colors.amethyst}
        isMainHeader
      />
      <Box width="100%" flex={1}>
        <Scrollable padding={24} alignItems="center">
          <Box maxWidth={500} width="100%">
            {curriculum.contents.map((folder, i) => (
              <CourseMapMainFolder
                key={folder.name}
                title={folder.name}
                isHovered={hoveredFolder === folder.name}
                setHoveredFolder={setHoveredFolder}
                description={folder.description}
                backgroundColor={colors.white}
                studentInfo={courseMapFolderProps(
                  folder,
                  studentProgress,
                  completedNodeIds
                )}
                onPress={() => {
                  navigation.navigate('CourseMapDetails', {
                    folderId: folder.id,
                  });
                }}
              />
            ))}
          </Box>
        </Scrollable>
      </Box>
    </Box>
  );
};
