import React from 'react';

import { Text, Box } from '../styled';
import { InputField } from '../InputField';
import { Button } from '../Button';
import { fonts } from '../../constants/theme';
import { AuthFormWrapper } from './AuthFormWrapper';
import { ForgotPasswordFormProps } from './types';
import { initialState, formReducer } from './utils';
import { validateEmail } from 'sonora-util/strings';

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  onSubmit,
  onCancel,
}) => {
  const [{ values, errors }, dispatch] = React.useReducer(
    formReducer,
    initialState
  );

  const isFormValid = React.useMemo(() => validateEmail(values.email), [
    values,
  ]);

  const handleEmailTextChange = (value: string) => {
    dispatch({
      type: 'SET_VALUE',
      field: 'email',
      payload: value,
    });

    if (!validateEmail(value)) {
      dispatch({
        type: 'SET_ERROR',
        field: 'email',
        payload: 'Please enter a valid email address',
      });
    } else {
      dispatch({
        type: 'SET_ERROR',
        field: 'email',
        payload: '',
      });
    }
  };

  return (
    <AuthFormWrapper>
      <Text font={fonts.bold} size={20} marginBottom={12}>
        Forgot your password?
      </Text>
      <Text font={fonts.thin} size={20} marginBottom={36} textAlign="center">
        Resetting is easy, just enter the email address associated with your
        account
      </Text>
      <InputField
        label="Email"
        value={values.email || ''}
        error={errors.email}
        textContentType="emailAddress"
        autoCapitalize="none"
        onChangeText={handleEmailTextChange}
      />

      <Box marginBottom={8} marginTop={16}>
        <Button
          type="Primary"
          text="Send Email"
          onPress={() => {
            if (onSubmit) {
              onSubmit(values);
            }
          }}
          disabled={!isFormValid}
        />
      </Box>
      <Box marginBottom={36}>
        <Button
          type="Tertiary"
          text="Cancel"
          onPress={() => {
            if (onCancel) {
              onCancel();
            }
          }}
        />
      </Box>
    </AuthFormWrapper>
  );
};
