import { ChatRelationshipType } from 'sonora-types';
import { StudentListItemProps } from './types';
import { shortYearDatestring } from 'sonora-util/dateUtils';

export const relationshipToStudentListItem = (
  relationship: ChatRelationshipType,
  navigateChat: (student: ChatRelationshipType) => void
): Pick<
  StudentListItemProps,
  | 'fullName'
  | 'startDate'
  | 'finishDate'
  | 'isNew'
  | 'hasBadge'
  | 'onOpen'
  | 'onChatOpen'
> => {
  //  const lastPracticedString = relationship.
  return {
    fullName: relationship.student.fullName,
    startDate: shortYearDatestring(relationship.relationshipStartDate),
    finishDate: shortYearDatestring(relationship.relationshipEndDate),
    isNew: !relationship.lastActivity,
    hasBadge: relationship.unreadCount > 0,
    onOpen: () => {
      navigateChat(relationship);
    },
    onChatOpen: () => {
      navigateChat(relationship);
    },
  };
};
