// author: @fullstackdevs14
import React from 'react';
import styled from 'styled-components/native';

import TaskListHeader from './TaskListHeader';
import TaskListItem from './TaskListItem';
import CompletedTasksHeader from './CompletedTasksHeader';
import UnlockTasksHeader from './UnlockTasksHeader';
import { TaskListProps } from './types';
import { colors } from '../../constants/theme';
import { Text, Box } from '../styled';
import { Divider } from '../Divider';
import { FillIcon } from '../Icon';

const Scrollable = styled.ScrollView`
  width: 100%;
  ${(props: { fillHeight?: boolean }) => (props.fillHeight ? `flex: 1;` : '')}
`;

const TaskList: React.FC<TaskListProps> = ({
  numCompletedToShow,
  numUpcomingToShow,
  completedTasks,
  currentTaskId,
  currentTasks,
  totalReviewCount,
  lockedTasks,
  width = '100%',
  hideIconDirection = 'left',
  hasHideButton = true,
  hasScroll,
  onSelectItem,
  onHide,
}: TaskListProps) => {
  const [showCompleted, setShowCompleted] = React.useState(false);

  const nCompleted = numCompletedToShow || 5;
  const nUpcoming = numUpcomingToShow || 3;
  const nCurrentToShow = 7;

  // remove duplicate items
  const ids = currentTasks.map((o) => o.practiceNodeId);
  const filteredCurrentTasks = currentTasks.filter(
    ({ practiceNodeId }, index) => !ids.includes(practiceNodeId, index + 1)
  );

  const currentListToShow = filteredCurrentTasks.slice(0, nCurrentToShow);
  const completedListToShow =
    completedTasks && completedTasks.length > 0
      ? completedTasks.slice(-nCompleted)
      : [];
  const upcomingListToShow = [
    ...filteredCurrentTasks.slice(nCurrentToShow),
    ...lockedTasks,
  ].slice(0, nUpcoming);

  return (
    <Box width={width} flex={hasScroll ? 1 : 'none'} alignItems="center">
      <Box width={width} padding={12}>
        <TaskListHeader
          title="Practice list"
          hideIconDirection={hideIconDirection}
          hasHideButton={hasHideButton}
          onHide={onHide}
        />
      </Box>
      <Scrollable fillHeight={hasScroll}>
        <Box width={width} paddingX={12}>
          {completedListToShow.length > 0 ? (
            <>
              <Box marginBottom={15}>
                <CompletedTasksHeader
                  show={showCompleted}
                  onToggle={() => {
                    setShowCompleted(!showCompleted);
                  }}
                />
              </Box>
              {showCompleted
                ? completedListToShow.map((task, i) => (
                  <TaskListItem
                    key={task.practiceNodeId}
                    item={task}
                    hasBorder={i < completedListToShow.length - 1}
                    isCompleted
                    onSelect={() => onSelectItem && onSelectItem(task)}
                  />
                ))
                : null}
            </>
          ) : null}
          {showCompleted ? (
            <Divider color={colors.purpley} marginBottom={15} />
          ) : null}
          <Box marginBottom={15}>
            {totalReviewCount - 4 > 0 && (<Box marginBottom={15}>
              <TaskListHeaderPill isLocked={true}>
                <FillIcon name="review" />
                <Text size={12}> {totalReviewCount-4} PENDING REVIEW ITEMS</Text>
              </TaskListHeaderPill>
            </Box>)
            }
            {currentListToShow.map((task, i) => (
              <TaskListItem
                key={task.practiceNodeId}
                item={task}
                isSelected={task.practiceNodeId === currentTaskId}
                onSelect={() => onSelectItem && onSelectItem(task)}
                onSkip={task.onSkip}
              />
            ))}
          </Box>
          <Box marginBottom={15}>
            <UnlockTasksHeader backgroundColor={colors.agate} />
          </Box>
          <Box marginBottom={15}>
            {upcomingListToShow.map((task, i) => (
              <TaskListItem
                key={task.practiceNodeId}
                item={task}
                hasBorder={i < upcomingListToShow.length - 1}
                isLocked
              />
            ))}
          </Box>
        </Box>
      </Scrollable>
    </Box>
  );
};

export default TaskList;

const TaskListHeaderPill = styled.View`
  flex-direction: row;
  align-items: center;
  height: 32px;
  padding: 4px 12px;
  border-radius: 16px;
  background-color: ${(props: { isLocked?: boolean }) =>
    props.isLocked ? colors.agateHover : colors.agate};
`;