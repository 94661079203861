import React, { useContext } from 'react';
import { RootNavScreen } from '../screens';
import { UserContext } from 'sonora-containers/index';
import { StudentCourseMapDetails } from './StudentCourseMapDetails';
import { MentorCourseMapDetails } from './MentorCourseMapDetails';

export const CourseMapDetails: RootNavScreen<'CourseMapDetails'> = ({
  route,
  navigation,
}) => {
  const { userType } = useContext(UserContext);
  if (userType === 'mentor')
    return <MentorCourseMapDetails route={route} navigation={navigation} />;
  else return <StudentCourseMapDetails route={route} navigation={navigation} />;
};
