import React, { useContext } from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { UserContext } from 'sonora-containers/UserProvider';
import {
  Box,
  colors,
  FillIcon,
  Row,
  Text,
  PaymentMethod,
  StripeForm,
} from 'sonora-design-system';
import { PaymentDetailsProps } from './types';

export const PaymentDetails: React.FC<PaymentDetailsProps> = ({
  navigation,
}) => {
  const { payment } = useContext(UserContext);

  const goBack = () => {
    navigation.goBack();
  };

  return (
    <Box height="100%">
      <Box
        height="72px"
        backgroundColor={colors.grayHover}
        justifyContent="center"
      >
        <Box maxWidth={480} width="100%" marginX="auto">
          <TouchableOpacity onPress={goBack}>
            <Row>
              <FillIcon name="pageLeft" />
              <Text marginLeft={8}>Back to Overview</Text>
            </Row>
          </TouchableOpacity>
        </Box>
      </Box>
      <Box flex={1} overflow="auto">
        <Box
          maxWidth={504}
          paddingY={36}
          paddingX={12}
          width="100%"
          marginX="auto"
        >
          {payment
            ? payment.paymentMethods.map((card) => (
                <PaymentMethod
                  key={card.id}
                  details={card}
                  editable={false}
                  removable={payment.paymentMethods.length > 1}
                  onPress={() => console.log('Select card')}
                />
              ))
            : null}
          <StripeForm />
        </Box>
      </Box>
    </Box>
  );
};
