import { CourseMapListItemProps, CourseMapMainFolderProps } from './types';
import { colors } from '../../constants/theme';
import { Curriculum, LearningFolder } from 'sonora-types';
import { mapCurric } from 'sonora-util/testData';

export const mockCourseMapListItems: CourseMapListItemProps[] = [
  {
    title: 'The 5 Forms',
    unlockedStars: 1,
    maxStars: 1,
    hasBorder: true,
    depth: 0,
    icon: 'playFilled',
    locked: false,
  },
  {
    title: 'Welcome to The 5 Forms',
    unlockedStars: 4,
    maxStars: 10,
    hasBorder: true,
    depth: 0,
    isFolder: true,
    locked: false,
  },
  {
    title: 'Welcome to The 5 Forms',
    unlockedStars: 1,
    maxStars: 1,
    hasBorder: true,
    depth: 1,
    icon: 'playFilled',
    locked: false,
  },
  {
    title: '6th String Front Form',
    unlockedStars: 4,
    maxStars: 10,
    hasBorder: true,
    depth: 1,
    isFolder: true,
    locked: false,
  },
  {
    title: '6th String Front Form',
    unlockedStars: 3,
    maxStars: 5,
    icon: 'playFilled',
    hasBorder: true,
    depth: 2,
    locked: false,
  },
  {
    title: '6th String Front Form',
    unlockedStars: 0,
    maxStars: 5,
    icon: 'soundslice',
    hasBorder: true,
    depth: 2,
    locked: false,
  },
  {
    title: '6th String Front Form',
    unlockedStars: 0,
    maxStars: 1,
    icon: 'soundslice',
    hasBorder: false,
    depth: 2,
    locked: false,
  },
];

export const mockCourseMapMainFolders: CourseMapMainFolderProps[] = [
  {
    title: '5 Forms',
    description:
      'Lorem ipsum a short summary describing the content of the subject should go here maybe.',
    studentInfo: {
      numAllLessons: 8,
      numCompletedLessons: 1,
      completePercent: 12,
    },
    backgroundColor: colors.agate,
    isHovered: false,
    setHoveredFolder: () => {},
  },
  {
    title: '5 Forms',
    description:
      'Lorem ipsum a short summary describing the content of the subject should go here maybe.',
    studentInfo: {
      numAllLessons: 8,
      numCompletedLessons: 1,
      completePercent: 0,
    },
    backgroundColor: colors.agate,
    isHovered: false,
    setHoveredFolder: () => {},
  },
  {
    title: '5 Forms',
    description:
      'Lorem ipsum a short summary describing the content of the subject should go here maybe.',
    studentInfo: {
      numAllLessons: 8,
      numCompletedLessons: 1,
      completePercent: 100,
    },
    backgroundColor: colors.agate,
    isHovered: false,
    setHoveredFolder: () => {},
  },
  {
    title: '5 Forms',
    description:
      'Lorem ipsum a short summary describing the content of the subject should go here maybe.',
    studentInfo: {
      numAllLessons: 8,
      numCompletedLessons: 1,
      completePercent: 50,
    },
    backgroundColor: colors.agate,
    isHovered: false,
    setHoveredFolder: () => {},
  },
  {
    title: '5 Forms',
    description:
      'Lorem ipsum a short summary describing the content of the subject should go here maybe.',
    studentInfo: {
      numAllLessons: 8,
      numCompletedLessons: 1,
      completePercent: 74,
    },
    backgroundColor: colors.agate,
    disabled: true,
    isHovered: false,
    setHoveredFolder: () => {},
  },
];

const curricChildren: LearningFolder[] = [
  {
    name: 'Welcome',
    id: '223',
    description: '',
    optional: false,
    requiredNodeIds: [],
    type: 'Folder',
    children: [
      {
        name: 'Welcome Video',
        id: '1',
        assignmentType: 'watch',
        description: '',
        optional: false,
        modifications: {},
        requiredNodeIds: [],
        type: 'Activity',
        media: { type: 'vimeo', embedString: '452261270' },
        maxStars: 1,
      },
      {
        name: 'Health and Wellness',
        id: '2',
        assignmentType: 'watch',
        description: '',
        optional: false,
        modifications: {},
        requiredNodeIds: ['1'],
        type: 'Activity',
        media: { type: 'vimeo', embedString: '452261270' },
        maxStars: 1,
      },
      {
        name: "The 3 T's",
        id: '3',
        assignmentType: 'watch',
        description: '',
        optional: false,
        modifications: {},
        requiredNodeIds: ['2'],
        type: 'Activity',
        media: { type: 'vimeo', embedString: '452261270' },
        maxStars: 1,
      },
      {
        name: 'Right Hand Techniques',
        id: '4',
        assignmentType: 'watch',
        description: '',
        optional: false,
        modifications: {},
        requiredNodeIds: ['3'],
        type: 'Activity',
        media: { type: 'vimeo', embedString: '452261270' },
        maxStars: 1,
      },
      {
        name: 'How to interact with your mentor',
        id: '5',
        assignmentType: 'watch',
        description: '',
        optional: false,
        modifications: {},
        requiredNodeIds: ['4'],
        type: 'Activity',
        media: { type: 'vimeo', embedString: '452261270' },
        maxStars: 1,
      },
      {
        name: 'FAQ',
        id: '6',
        assignmentType: 'watch',
        description: '',
        optional: false,
        modifications: {},
        requiredNodeIds: ['5'],
        type: 'Activity',
        media: { type: 'vimeo', embedString: '452261270' },
        maxStars: 1,
      },
      {
        name: 'Accelerated Learning Module',
        id: '224',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'Accelerated Learning Video 1',
            id: '7',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['6'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name:
              'Accelerated Learning Video 2 - priciples of accelerated learning',
            id: '8',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['7'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'Accelerated Learning Video 3',
            id: '9',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['8'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'Accelerated Learning Video 4',
            id: '10',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['9'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'Accelerated Learning Video 5',
            id: '11',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['10'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'Accelerated Learning Video 6 - Absorbtion',
            id: '12',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['11'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'Accelerated Learning Video 7- Integration + Retention',
            id: '13',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['12'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'Accelerated Learning Video 8 - How to Practice',
            id: '14',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['13'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
        ],
      },
    ],
  },
  {
    name: 'THE FIVE FORMS',
    id: '283',
    description: '',
    optional: false,
    requiredNodeIds: [],
    type: 'Folder',
    children: [
      {
        name: 'Welcome to the 5 Forms',
        id: '15',
        assignmentType: 'watch',
        description: '',
        optional: false,
        modifications: {},
        requiredNodeIds: ['14'],
        type: 'Activity',
        media: { type: 'vimeo', embedString: '452261270' },
        maxStars: 1,
      },
      {
        name: '6th String Front Form',
        id: '284',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: '6th String Front Form',
            id: '16',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['15'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: '6th String Front Form- Key of C',
            id: '22',
            assignmentType: 'practice',
            description: 'Chord; Root to root;',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['16'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/Pnyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '6th String Front Form- Chromatically Up the Neck',
            id: '21',
            assignmentType: 'practice',
            description: '(G -> D)',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['16'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/Qnyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Filling the notes in Position: 6th String Front Form',
            id: '32',
            assignmentType: 'practice',
            description: '',
            optional: true,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['16'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/rryfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: '6th String Back Form',
        id: '288',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: '6th String Back Form',
            id: '18',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['17'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: '6th String Back Form- Chromatically Up the Neck',
            id: '25',
            assignmentType: 'practice',
            description: '(A -> E)',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['18'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/tnyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '6th String Back Form- Key of C',
            id: '26',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['18'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/-nyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Filling the notes in Position: 6th String Back Form',
            id: '34',
            assignmentType: 'practice',
            description: '',
            optional: true,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['18'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/vryfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: '5th String Front Form',
        id: '285',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: '5th String Front Form',
            id: '17',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['16'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: '5th String Front Form- Chromatically Up the Neck',
            id: '23',
            assignmentType: 'practice',
            description: '(C -> G)',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['17'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/nnyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '5th String Front Form- Key of C',
            id: '24',
            assignmentType: 'practice',
            description: 'Chord; root to root',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['17'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/Knyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Filling the notes in Position: 5th String Front Form',
            id: '33',
            assignmentType: 'practice',
            description: '',
            optional: true,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['17'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/mryfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: '5th String Back Form',
        id: '286',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: '5th String Back Form',
            id: '19',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['18'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: '5th String Back Form- Chromatically Up the Neck',
            id: '27',
            assignmentType: 'practice',
            description: '(D -> A)',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['19'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/Gnyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '5th String Back Form- Key of F',
            id: '28',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['19'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/gnyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Filling the notes in Position: 5th String Back Form',
            id: '35',
            assignmentType: 'practice',
            description: '',
            optional: true,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['19'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/Sryfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: '4th String Front Form',
        id: '287',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: '4th String Front Form',
            id: '20',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['19'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: '4th String Front Form- Chromatically Up the Neck',
            id: '29',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['20'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/hnyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '4th String Front Form- Key of F',
            id: '30',
            assignmentType: 'practice',
            description: 'Chord; root to root',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['20'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/xnyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Filling the notes in Position: 4th String Front Form',
            id: '36',
            assignmentType: 'practice',
            description: 'Chord; root to root',
            optional: true,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['20'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/pryfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: 'Cycle of 4ths and 5ths',
        id: '289',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'The Cycle of 4ths and 5ths',
            id: '31',
            assignmentType: 'watch',
            description: '',
            optional: true,
            modifications: {},
            requiredNodeIds: ['30'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'Back Forms Through the Cycle of 4ths',
            id: '37',
            assignmentType: 'practice',
            description: '',
            optional: true,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['28', '26'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/Lryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Bass Riff Through the Cycle of 4ths',
            id: '38',
            assignmentType: 'practice',
            description: '',
            optional: true,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['22', '24', '26', '28', '30'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/jryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Front Forms Through the Cycle of 5ths',
            id: '39',
            assignmentType: 'practice',
            description: '',
            optional: true,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['22', '24', '30'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/Wryfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: 'The Happy Birthday Challenge',
        id: '40',
        assignmentType: 'prompt',
        description: '',
        optional: false,
        modifications: {},
        requiredNodeIds: ['22', '24', '26', '28', '30'],
        type: 'Activity',
        media: { type: 'vimeo', embedString: '452261270' },
        maxStars: 1,
      },
    ],
  },
  {
    name: 'Melodic Patterns',
    id: '290',
    description: '',
    optional: false,
    requiredNodeIds: [],
    type: 'Folder',
    children: [
      {
        name: 'Melodic Patterns',
        id: '41',
        assignmentType: 'watch',
        description: '',
        optional: false,
        modifications: {},
        requiredNodeIds: ['30'],
        type: 'Activity',
        media: { type: 'vimeo', embedString: '452261270' },
        maxStars: 1,
      },
      {
        name: 'Make up your own pattern',
        id: '66',
        assignmentType: 'prompt',
        description: '',
        optional: false,
        modifications: {},
        requiredNodeIds: ['62'],
        type: 'Activity',
        media: { type: 'vimeo', embedString: '452261270' },
        maxStars: 1,
      },
      {
        name: 'The 1234 Pattern',
        id: '291',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'The 1234 Pattern',
            id: '42',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['41'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'The 1234 Pattern: 6th String Front Form',
            id: '46',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [95, 62],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['42'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/vnyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'The 1234 Pattern: 5th String Front Form',
            id: '47',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [95, 62],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['46'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/Snyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'The 1234 Pattern: 6th String Back Form',
            id: '48',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [95, 62],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['47'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/cFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'The 1234 Pattern: 5th String Back Form',
            id: '49',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [95, 62],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['48'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/pnyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'The 1234 Pattern: 4th String Front Form',
            id: '50',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [95, 62],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['49'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/VFyfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: 'The 1235 Pattern',
        id: '292',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'The 1235 Pattern',
            id: '43',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['50'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'The 1235 Pattern: 6th String Front Form',
            id: '51',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [95, 62],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['43'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/fFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'The 1235 Pattern: 5th String Front Form',
            id: '52',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [95, 62],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['51'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/DFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'The 1235 Pattern: 6th String Back Form',
            id: '53',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [95, 62],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['52'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/MFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'The 1235 Pattern: 5th String Back Form',
            id: '54',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [95, 62],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['53'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/kFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'The 1235 Pattern: 4th String Front Form',
            id: '55',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [95, 62],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['54'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/4Fyfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: 'Triad Pattern',
        id: '293',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'The Triad Pattern',
            id: '44',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['55'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'The Triad Pattern: 6th String Front Form',
            id: '56',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [95, 62],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['44'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/yFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'The Triad Pattern: 5th String Front Form',
            id: '57',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [95, 62],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['56'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/YFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'The Triad Pattern: 6th String Back Form',
            id: '58',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [95, 62],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['57'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/lFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'The Triad Pattern: 5th String Back Form',
            id: '59',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [95, 62],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['58'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/wFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'The Triad Pattern: 4th String Front Form',
            id: '60',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [95, 62],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['59'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/zFyfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: 'Modal Pattern',
        id: '294',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'The Modal Pattern',
            id: '45',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['60'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'The Modal Pattern: 6th String Front Form',
            id: '61',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [95, 62],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['45'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/2Fyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'The Modal Pattern: 5th String Front Form',
            id: '62',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [95, 62],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['61'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/3Fyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'The Modal Pattern: 6th String Back Form',
            id: '63',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [95, 62],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['62'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/CFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'The Modal Pattern: 5th String Back Form',
            id: '64',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [95, 62],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['63'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/XFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'The Modal Pattern: 4th String Front Form',
            id: '65',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [95, 62],
              '9': [78, 79, 80],
              '10': [82, 83, 84, 85, 86, 87, 88, 89],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['64'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/qFyfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
    ],
  },
  {
    name: 'Intervals',
    id: '295',
    description: '',
    optional: false,
    requiredNodeIds: [],
    type: 'Folder',
    children: [
      {
        name: 'Intervals',
        id: '67',
        assignmentType: 'watch',
        description: '',
        optional: false,
        modifications: {},
        requiredNodeIds: ['65'],
        type: 'Activity',
        media: { type: 'vimeo', embedString: '452261270' },
        maxStars: 1,
      },
      {
        name: 'Find 11ths 12th and 13ths',
        id: '108',
        assignmentType: 'prompt',
        description: '',
        optional: false,
        modifications: {},
        requiredNodeIds: ['107'],
        type: 'Activity',
        media: { type: 'vimeo', embedString: '452261270' },
        maxStars: 1,
      },
      {
        name: 'Fourths',
        id: '296',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'Fourths',
            id: '69',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['79'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'Fourths: 6th String Front Form',
            id: '80',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['69'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/mFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Fourths: 5th String Front Form',
            id: '81',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['80'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/vFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Fourths: 6th String Back Form',
            id: '82',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['81'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/SFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Fourths: 5th String Back Form',
            id: '83',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['82'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/pFyfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: 'Thirds',
        id: '297',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'Thirds',
            id: '68',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['67'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'Thirds: 6th String Front Form',
            id: '76',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['68'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/6Fyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Thirds: 5th String Front Form',
            id: '77',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['76'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/GFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Thirds: 6th String Back Form',
            id: '78',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['77'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/rFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Thirds: 5th String Back Form',
            id: '79',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['78'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/hFyfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: 'Fifths',
        id: '298',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'Fifths',
            id: '70',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['83'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'Fifths: 6th String Front Form',
            id: '84',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['70'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/ctyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Fifths: 5th String Front Form',
            id: '85',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['84'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/Dtyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Fifths: 6th String Back Form',
            id: '86',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['85'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/Vtyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Fifths: 5th String Back Form',
            id: '87',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['86'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/ftyfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: 'Sixths',
        id: '299',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'Sixths',
            id: '71',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['87'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'Sixths: 6th String Front Form',
            id: '88',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['71'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/Mtyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Sixths: 5th String Front Form',
            id: '89',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['88'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/ktyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Sixths: 6th String Back Form',
            id: '90',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['89'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/4tyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Sixths: 5th String Back Form',
            id: '91',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['90'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/YGyfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: 'Sevenths',
        id: '300',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'Sevenths',
            id: '72',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['91'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'Sevenths: 6th String Front Form',
            id: '92',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['72'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/wGyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Sevenths: 5th String Front Form',
            id: '93',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['92'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/lGyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Sevenths: 6th String Back Form',
            id: '94',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['93'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/2Gyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Sevenths: 5th String Back Form',
            id: '95',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['94'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/zGyfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: 'Eighths',
        id: '301',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'Eighths',
            id: '73',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['95'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'Octaves: 6th String Front Form',
            id: '96',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['73'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/CGyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Octaves: 5th String Front Form',
            id: '97',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['96'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/3Gyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Octaves: 6th String Back Form',
            id: '98',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['97'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/RGyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Octaves: 5th String Back Form',
            id: '99',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['98'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/XGyfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: 'Tenths',
        id: '302',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'Tenths',
            id: '75',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['103'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'Tenths: 6th String Front Form',
            id: '104',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['75'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/JGyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Tenths: 5th String Front Form',
            id: '105',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['104'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/7Gyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Tenths: 6th String Back Form',
            id: '106',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['105'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/dGyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Tenths: 5th String Back Form',
            id: '107',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['106'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/WGyfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: 'Ninths',
        id: '303',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'Ninths',
            id: '74',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['99'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'Ninths: 6th String Front Form',
            id: '100',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['74'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/qGyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Ninths: 5th String Front Form',
            id: '101',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['100'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/HGyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Ninths: 6th String Back Form',
            id: '102',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['101'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/bGyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Ninths: 5th String Back Form',
            id: '103',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
              '3': [32, 33, 34, 35, 36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '6': [57, 58, 59],
              '7': [62, 64, 66],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['102'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/TGyfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
    ],
  },
  {
    name: 'Close Triads',
    id: '305',
    description: '',
    optional: false,
    requiredNodeIds: [],
    type: 'Folder',
    children: [
      {
        name: 'Close Triads',
        id: '109',
        assignmentType: 'watch',
        description: '',
        optional: false,
        modifications: {},
        requiredNodeIds: ['60'],
        type: 'Activity',
        media: { type: 'vimeo', embedString: '452261270' },
        maxStars: 1,
      },
      {
        name: 'Playing Progressions in Position',
        id: '148',
        assignmentType: 'prompt',
        description: '',
        optional: false,
        modifications: {},
        requiredNodeIds: ['145'],
        type: 'Activity',
        media: { type: 'vimeo', embedString: '452261270' },
        maxStars: 1,
      },
      {
        name: 'Major Triad Runs',
        id: '306',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'Major Triad Runs',
            id: '110',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['109'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'Major Triad Run: Root Position (C)',
            id: '115',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '7': [67, 68],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88, 87],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['110'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/HFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Major Triad Run: 1st Inversion (G)',
            id: '116',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '7': [67, 68],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88, 87],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['110'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/TFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Major Triad Run: 2nd Inversion (F)',
            id: '117',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '7': [67, 68],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88, 87],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['110'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/JFyfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: 'Minor Triad Runs',
        id: '307',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'Minor Triad Runs',
            id: '111',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['117'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'Minor Triad Run: Root Position (C)',
            id: '118',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '7': [67, 68],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88, 87],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['111'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/bFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Minor Triad Run: 1st Inversion (G)',
            id: '119',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '7': [67, 68],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88, 87],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['118'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/sFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Minor Triad Run: 2nd Inversion (F)',
            id: '120',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '7': [67, 68],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88, 87],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['119'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/1Fyfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: 'Diminished Triad Runs',
        id: '308',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'Diminished Triad Runs',
            id: '112',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['120'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'Diminished Triad Run: Root Position (C)',
            id: '121',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '7': [67, 68],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88, 87],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['112'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/8Fyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Diminished Triad Run: 1st Inversion (G)',
            id: '122',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '7': [67, 68],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88, 87],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['121'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/7Fyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Diminished Triad Run: 2nd Inversion (F)',
            id: '123',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '7': [67, 68],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88, 87],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['122'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/dFyfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: 'Diatonic Harmony',
        id: '309',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'Diatonic Harmony',
            id: '113',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['123'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'Diatonic Harmony: 1st String Set',
            id: '125',
            assignmentType: 'practice',
            description: '(All Keys, Bb)',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 18, 36, 20, 21, 22],
              '3': [36, 38, 39, 40, 41, 42],
              '5': [56, 50, 46, 47, 48],
              '6': [61],
              '7': [72, 65],
              '8': [76, 77],
              '9': [79, 80, 78, 81],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['113'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/WFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Diatonic Harmony: 2nd String Set',
            id: '126',
            assignmentType: 'practice',
            description: '(All Keys, F)',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 18, 36, 20, 21, 22],
              '3': [36, 38, 39, 40, 41, 42],
              '5': [56, 50, 46, 47, 48],
              '6': [61],
              '7': [72, 65],
              '8': [76, 77],
              '9': [78, 79, 80, 81],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['125'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/BFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Diatonic Harmony: 3rd String Set',
            id: '127',
            assignmentType: 'practice',
            description: '(All Keys, Db)',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 18, 36, 20, 21, 22],
              '3': [36, 38, 39, 40, 41, 42],
              '5': [56, 50, 46, 47, 48],
              '6': [61],
              '7': [72, 65],
              '8': [76, 77],
              '9': [78, 79, 80, 81],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['126'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/ZFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Diatonic Harmony: 4th String Set',
            id: '128',
            assignmentType: 'practice',
            description: '(All Keys, Ab)',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [7, 18, 36, 20, 21, 22],
              '3': [36, 38, 39, 40, 41, 42],
              '5': [56, 50, 46, 47, 48],
              '6': [61],
              '7': [72, 65],
              '8': [76, 77],
              '9': [78, 79, 80, 81],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['127'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/LFyfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: 'Inversions on each string set',
        id: '310',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'Inversions on Each String Set',
            id: '114',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['128'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'Triad Runs: Minor/Diminished Etude',
            id: '124',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '7': [67, 68],
              '9': [78, 79, 80, 81],
              '10': [85, 86, 88, 87],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['123'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/2ryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '2nd String Set Triads- Etude',
            id: '129',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [44],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61],
              '7': [72, 65],
              '8': [75],
              '9': [78, 79, 80, 81],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['126'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/Rryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '3rd String Set Triads- Etude',
            id: '130',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [44],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61],
              '7': [72, 65],
              '8': [75],
              '9': [78, 79, 80, 81],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['127'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/3ryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '4th String Set Triads- Etude',
            id: '131',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [44],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61],
              '7': [72, 65],
              '8': [75],
              '9': [78, 79, 80, 81],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['128'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/Xryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Diminished Inversions: 1st String Set',
            id: '132',
            assignmentType: 'practice',
            description: '(All Keys, Bº)',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [44],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61],
              '7': [72, 65],
              '8': [75],
              '9': [78, 79, 80, 81],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['144'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/FFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Diminished Inversions: 2nd String Set',
            id: '133',
            assignmentType: 'practice',
            description: '(All Keys, Gº)',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [44],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61],
              '7': [72, 65],
              '8': [75],
              '9': [78, 79, 80, 81],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['132'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/tFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Diminished Inversions: 3rd String Set',
            id: '134',
            assignmentType: 'practice',
            description: '(All Keys, Dº)',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [44],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61],
              '7': [72, 65],
              '8': [75],
              '9': [78, 79, 80, 81],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['133'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/5Fyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Diminished Inversions: 4th String Set',
            id: '135',
            assignmentType: 'practice',
            description: '(All Keys, Aº)',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [44],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61],
              '7': [72, 65],
              '8': [75],
              '9': [78, 79, 80, 81],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['134'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/gFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Major Inversions: 1st String Set',
            id: '136',
            assignmentType: 'practice',
            description: '(All Keys, B)',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [44],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61],
              '7': [72, 65],
              '8': [75],
              '9': [78, 79, 80, 81],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['114'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/jFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Major Inversions: 2nd String Set',
            id: '137',
            assignmentType: 'practice',
            description: '(All Keys, G)',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [44],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61],
              '7': [72, 65],
              '8': [75],
              '9': [78, 79, 80, 81],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['136'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/PFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Major Inversions: 3rd String Set',
            id: '138',
            assignmentType: 'practice',
            description: '(All Keys, D)',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [44],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61],
              '7': [72, 65],
              '8': [75],
              '9': [78, 79, 80, 81],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['137'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/9Fyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Major Inversions: 4th String Set',
            id: '139',
            assignmentType: 'practice',
            description: '(All Keys, A)',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [44],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61],
              '7': [72, 65],
              '8': [75],
              '9': [78, 79, 80, 81],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['138'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/KFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Major/Minor Inversions: 1st String Set - Etude',
            id: '140',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [44],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61],
              '7': [72, 65],
              '8': [75],
              '9': [78, 79, 80, 81],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['136', '141'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/Cryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Minor Inversions: 1st String Set',
            id: '141',
            assignmentType: 'practice',
            description: '(All Keys, B)',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [44],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61],
              '7': [72, 65],
              '8': [75],
              '9': [78, 79, 80, 81],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['114'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/xFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Minor Inversions: 2nd String Set',
            id: '142',
            assignmentType: 'practice',
            description: '(All Keys, G)',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [44],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61],
              '7': [72, 65],
              '8': [75],
              '9': [78, 79, 80, 81],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['141'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/-Fyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Minor Inversions: 3rd String Set',
            id: '143',
            assignmentType: 'practice',
            description: '(All Keys, D)',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [44],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61],
              '7': [72, 65],
              '8': [75],
              '9': [78, 79, 80, 81],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['142'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/QFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Minor Inversions: 4th String Set',
            id: '144',
            assignmentType: 'practice',
            description: '(All Keys, A)',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [44],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61],
              '7': [72, 65],
              '8': [75],
              '9': [78, 79, 80, 81],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['143'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/nFyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Augmented Triad Inversions All String Sets',
            id: '145',
            assignmentType: 'practice',
            description: '',
            optional: true,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [44],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61],
              '7': [72, 65],
              '8': [75],
              '9': [78, 79, 80, 81],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['135'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/Nhyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Someday My Prince Will Come - Augmented Chord Example',
            id: '147',
            assignmentType: 'practice',
            description: 'Solo Arrangement',
            optional: true,
            modifications: {},
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['145'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/Pryfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: 'Augmented Triad Set',
        id: '311',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'Augmented Triad Inversions All String Sets',
            id: '215',
            assignmentType: 'watch',
            description: '',
            optional: true,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [44],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61],
              '7': [72, 65],
              '8': [75],
              '9': [78, 79, 80, 81],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            requiredNodeIds: ['135'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
        ],
      },
      {
        name: 'Augmented Triad Runs',
        id: '312',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'Augmented Triad Runs All Inversions',
            id: '146',
            assignmentType: 'watch',
            description: '',
            optional: true,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 37],
              '5': [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
              '7': [67, 68],
              '9': [78, 79, 80, 81],
              '10': [85, 91, 90],
            },
            requiredNodeIds: ['123'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
        ],
      },
    ],
  },
  {
    name: 'Arpeggios',
    id: '313',
    description: '',
    optional: false,
    requiredNodeIds: [],
    type: 'Folder',
    children: [
      {
        name: 'Arpeggios',
        id: '154',
        assignmentType: 'watch',
        description: '',
        optional: false,
        modifications: {},
        requiredNodeIds: ['135'],
        type: 'Activity',
        media: { type: 'vimeo', embedString: '452261270' },
        maxStars: 1,
      },
      {
        name: 'Major/Minor/Dominant Arpeggio: Etude',
        id: '162',
        assignmentType: 'practice',
        description: '',
        optional: false,
        modifications: {
          '1': [2, 32, 33, 34, 35],
          '2': [2, 25, 26, 27],
          '3': [36, 32, 33, 34, 37, 35],
          '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
          '6': [57, 58, 59],
          '7': [73, 74],
          '9': [78, 79, 80, 81],
          '10': [90, 91, 87, 88, 82, 83, 89, 84],
        },
        supplementary: {
          pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
        },
        requiredNodeIds: ['151', '149', '150'],
        type: 'Activity',
        media: {
          type: 'soundslice',
          embedString: 'https://www.soundslice.com/slices/Tryfc/embed/',
        },
        maxStars: 5,
      },
      {
        name: 'Major/Minor/Dominant/Half Diminished Arpeggio: Etude',
        id: '166',
        assignmentType: 'practice',
        description: '',
        optional: false,
        modifications: {
          '1': [2, 32, 33, 34, 35],
          '2': [2, 25, 26, 27],
          '3': [36, 32, 33, 34, 37, 35],
          '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
          '6': [57, 58, 59],
          '7': [73, 74],
          '9': [78, 79, 80, 81],
          '10': [90, 91, 87, 88, 82, 83, 89, 84],
        },
        supplementary: {
          pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
        },
        requiredNodeIds: ['163'],
        type: 'Activity',
        media: {
          type: 'soundslice',
          embedString: 'https://www.soundslice.com/slices/Jryfc/embed/',
        },
        maxStars: 5,
      },
      {
        name: 'Minor/Diminished Arpeggio: Etude',
        id: '169',
        assignmentType: 'practice',
        description: '',
        optional: false,
        modifications: {
          '1': [2, 32, 33, 34, 35],
          '2': [2, 25, 26, 27],
          '3': [36, 32, 33, 34, 37, 35],
          '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
          '6': [57, 58, 59],
          '7': [73, 74],
          '9': [78, 79, 80, 81],
          '10': [90, 91, 87, 88, 82, 83, 89, 84],
        },
        supplementary: {
          pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
        },
        requiredNodeIds: ['153'],
        type: 'Activity',
        media: {
          type: 'soundslice',
          embedString: 'https://www.soundslice.com/slices/bryfc/embed/',
        },
        maxStars: 5,
      },
      {
        name: 'Find the arpeggio inversions',
        id: '170',
        assignmentType: 'prompt',
        description: '',
        optional: false,
        modifications: {},
        requiredNodeIds: ['167'],
        type: 'Activity',
        media: { type: 'vimeo', embedString: '452261270' },
        maxStars: 1,
      },
      {
        name: 'Major  7 Arpeggio',
        id: '314',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'The Major 7 Arpeggio',
            id: '149',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['145'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'Major 7 Arpeggio: 6th String in C',
            id: '155',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 25, 26, 27],
              '3': [36, 32, 33, 34, 37, 35],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [57, 58, 59],
              '7': [73, 74],
              '9': [78, 79, 80, 81],
              '10': [90, 91, 87, 88, 82, 83, 89, 84],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['149'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/BGyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Major 7 Arpeggio: 5th String in C',
            id: '156',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 25, 26, 27],
              '3': [36, 32, 33, 34, 37, 35],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [57, 58, 59],
              '7': [73, 74],
              '9': [78, 79, 80, 81],
              '10': [90, 91, 87, 88, 82, 83, 89, 84],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['134'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/ZGyfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: 'Dominant 7 Arpeggio',
        id: '315',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'The Dominant 7 Arpeggio',
            id: '150',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['134'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'Dominant 7 Arpeggio: 6th String in C',
            id: '157',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 28, 25, 29],
              '3': [36, 32, 33, 34, 37, 35],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [57, 58, 59],
              '7': [73, 74],
              '9': [78, 79, 80, 81],
              '10': [90, 91, 87, 88, 82, 83, 89, 84],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['149'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/LGyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Dominant 7 Arpeggio: 5th String in C',
            id: '158',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 28, 25, 29],
              '3': [36, 32, 33, 34, 37, 35],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [57, 58, 59],
              '7': [73, 74],
              '9': [78, 79, 80, 81],
              '10': [90, 91, 87, 88, 82, 83, 89, 84],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['155'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/jGyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Dominant 7 Arpeggio Etude',
            id: '159',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {},
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['150'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/PGyfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: 'Minor 7 Arpeggio',
        id: '316',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'The Minor 7 Arpeggio',
            id: '151',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['154'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'Minor 7 Arpeggio: 6th String in C',
            id: '160',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 30, 31],
              '3': [36, 32, 33, 34, 37, 35],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [57, 58, 59],
              '7': [73, 74],
              '9': [78, 79, 80, 81],
              '10': [90, 91, 87, 88, 82, 83, 89, 84],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['157'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/KGyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Minor 7 Arpeggio: 5th String in C',
            id: '161',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 30, 31],
              '3': [36, 32, 33, 34, 37, 35],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [57, 58, 59],
              '7': [73, 74],
              '9': [78, 79, 80, 81],
              '10': [90, 91, 87, 88, 82, 83, 89, 84],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['158'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/xGyfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: 'Half-diminished arpeggio',
        id: '317',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'The Half-diminished Arpeggio',
            id: '152',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['156'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'Half-diminished 7 Arpeggio: 6th String in C',
            id: '163',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 32, 33, 34, 37, 35],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [57, 58, 59],
              '7': [73, 74],
              '9': [78, 79, 80, 81],
              '10': [90, 91, 87, 88, 82, 83, 89, 84],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['160'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/-Gyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Half-diminished 7 Arpeggio: 5th String in C',
            id: '164',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 32, 33, 34, 37, 35],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [57, 58, 59],
              '7': [73, 74],
              '9': [78, 79, 80, 81],
              '10': [90, 91, 87, 88, 82, 83, 89, 84],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['161'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/QGyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Half-diminished 7 Arpeggio Etude',
            id: '165',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {},
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['152'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/nGyfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: 'Diminished Arpeggio',
        id: '318',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: 'The Diminished Arpeggio',
            id: '153',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['154'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: 'Diminished 7 Arpeggio: 6th String in C',
            id: '167',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 32, 33, 34, 37, 35],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [57, 58, 59],
              '7': [73, 74],
              '9': [78, 79, 80, 81],
              '10': [90, 91, 87, 88, 82, 83, 89, 84],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['164'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/FGyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Diminished 7 Arpeggio: 5th String in C',
            id: '168',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 32, 33, 34, 37, 35],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [57, 58, 59],
              '7': [73, 74],
              '9': [78, 79, 80, 81],
              '10': [90, 91, 87, 88, 82, 83, 89, 84],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['164', '156', '158', '161'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/tGyfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
    ],
  },
  {
    name: '7th Chords',
    id: '319',
    description: '',
    optional: false,
    requiredNodeIds: [],
    type: 'Folder',
    children: [
      {
        name: '7th Chords',
        id: '171',
        assignmentType: 'watch',
        description: '',
        optional: false,
        modifications: {},
        requiredNodeIds: ['168', '161'],
        type: 'Activity',
        media: { type: 'vimeo', embedString: '452261270' },
        maxStars: 1,
      },
      {
        name: 'Chord Melody Challenge',
        id: '212',
        assignmentType: 'prompt',
        description: '',
        optional: false,
        modifications: {},
        requiredNodeIds: ['181'],
        type: 'Activity',
        media: { type: 'vimeo', embedString: '452261270' },
        maxStars: 1,
      },
      {
        name: '6th String Drop 3',
        id: '321',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: '6th String Drop 3 7th Chords',
            id: '172',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['185'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: '6th String Drop 3: Diatonic Harmony',
            id: '177',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['172'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/4ryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '6th String Drop 3: Major 7 Inversion in G',
            id: '182',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 25, 26, 27],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['182'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/5Gyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '6th String Drop 3: Dominant 7 Inversion in G',
            id: '183',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 28, 25, 29],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['182'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/GGyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '6th String Drop 3: Minor 7 Inversion in G',
            id: '184',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 30, 31],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['172'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/rGyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '6th String Drop 3: Half-diminished 7 Inversion in G',
            id: '185',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['172'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/hGyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '6th String Drop 3: Diminished 7 Inversion in G',
            id: '186',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['172'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/mGyfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: '4th String Drop 2',
        id: '322',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: '4th String Drop 2 7th Chords',
            id: '174',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['176'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: '4th String Drop 2: Diatonic Harmony',
            id: '179',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['174'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/Yryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '4th String Drop 2: Major 7 Inversion in F',
            id: '187',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 25, 26, 27],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['174'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/Vryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '4th String Drop 2: Dominant 7 Inversion in F',
            id: '188',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 28, 25, 29],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['187'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/fryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '4th String Drop 2: Minor 7 Inversion in F',
            id: '189',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 30, 31],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['188'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/Dryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '4th String Drop 2: Half-diminished 7 Inversion in F',
            id: '190',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['189'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/Mryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '4th String Drop 2: Diminished 7 Inversion in F',
            id: '191',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['190'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/kryfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: '5th String Drop 2',
        id: '323',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: '5th String Drop 2 7th Chords',
            id: '175',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['193'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: '5th String Drop 2: Diatonic Harmony',
            id: '180',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['175'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/lryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '5th String Drop 2: Major Inversion in C',
            id: '192',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 25, 26, 27],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['175'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/vGyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '5th String Drop 2: Dominant Inversion in C',
            id: '193',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 28, 25, 29],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['192'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/SGyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '5th String Drop 2: Minor Inversion in C',
            id: '194',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 30, 31],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['175'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/pGyfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '5th String Drop 2: Half-diminished Inversion in C',
            id: '195',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['194'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/Nryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '5th String Drop 2: Diminished Inversion in C',
            id: '196',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['195'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/cryfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: '5th String Drop 3',
        id: '320',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: '5th String Drop 3 7th Chords',
            id: '173',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['168'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: '5th String Drop 3: Diatonic Harmony',
            id: '178',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['173'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/yryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '5th String Drop 3: Major Inversion in C',
            id: '202',
            assignmentType: 'practice',
            description: '',
            optional: true,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 25, 26, 27],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['173'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/9ryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '5th String Drop 3: Dominant Inversion in C',
            id: '203',
            assignmentType: 'practice',
            description: '',
            optional: true,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 28, 25, 29],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['202'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/Kryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '5th String Drop 3: Minor Inversion in C',
            id: '204',
            assignmentType: 'practice',
            description: '',
            optional: true,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 30, 31],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['203'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/xryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '5th String Drop 3: Half-diminished Inversion in C',
            id: '205',
            assignmentType: 'practice',
            description: '',
            optional: true,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['204'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/-ryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '5th String Drop 3: Diminished Inversion in C',
            id: '206',
            assignmentType: 'practice',
            description: '',
            optional: true,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['205'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/Fryfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: '6th String Drop 2',
        id: '324',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name: '6th String Drop 2 7th Chords',
            id: '176',
            assignmentType: 'watch',
            description: '',
            optional: false,
            modifications: {},
            requiredNodeIds: ['175'],
            type: 'Activity',
            media: { type: 'vimeo', embedString: '452261270' },
            maxStars: 1,
          },
          {
            name: '6th String Drop 2: Diatonic Harmony',
            id: '181',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['176'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/zryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '6th String Drop 2: Major Inversion in G',
            id: '207',
            assignmentType: 'practice',
            description: '',
            optional: true,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 25, 26, 27],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['176'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/tryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '6th String Drop 2: Dominant Inversion in G',
            id: '208',
            assignmentType: 'practice',
            description: '',
            optional: true,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 28, 25, 29],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['207'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/5ryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '6th String Drop 2: Minor Inversion in G',
            id: '209',
            assignmentType: 'practice',
            description: '',
            optional: true,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 30, 31],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['208'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/gryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '6th String Drop 2: Half-diminished Inversion in G',
            id: '210',
            assignmentType: 'practice',
            description: '',
            optional: true,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['209'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/6ryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: '6th String Drop 2: Diminished Inversion in G',
            id: '211',
            assignmentType: 'practice',
            description: '',
            optional: true,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['210'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/Gryfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
      {
        name: '7th Chord Etudes',
        id: '258',
        description: '',
        optional: false,
        requiredNodeIds: [],
        type: 'Folder',
        children: [
          {
            name:
              'Drop 2 + Drop 3 (Half Diminished/Dominant/Diminished/Minor): Etude copy',
            id: '197',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 30, 31],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: [
              '186',
              '177',
              '184',
              '182',
              '183',
              '185',
              '187',
              '188',
              '189',
              '190',
              '191',
              '179',
              '192',
              '193',
              '194',
              '195',
              '196',
              '180',
            ],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/7ryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Drop 2 + Drop 3: Etude',
            id: '198',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 30, 31],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: [
              '186',
              '177',
              '184',
              '182',
              '183',
              '185',
              '187',
              '188',
              '189',
              '190',
              '191',
              '179',
              '192',
              '193',
              '194',
              '195',
              '196',
              '180',
            ],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/dryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Major 7 Drop 2: Etude',
            id: '199',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 30, 31],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['192'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/sryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Minor 7/ Dominant Drop 3: Etude',
            id: '200',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 30, 31],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['184', '204', '183', '203'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/1ryfc/embed/',
            },
            maxStars: 5,
          },
          {
            name: 'Minor/Major/Dominant Drop 2: Etude',
            id: '201',
            assignmentType: 'practice',
            description: '',
            optional: false,
            modifications: {
              '1': [2, 32, 33, 34, 35],
              '2': [2, 30, 31],
              '3': [36, 38, 39, 40, 41, 42],
              '4': [45],
              '5': [56, 50, 46, 47, 48, 49, 51, 52, 53, 54, 55],
              '6': [61, 57, 58],
              '9': [80, 81, 78, 79],
              '10': [85, 91, 92, 90],
              '11': [93, 94],
            },
            supplementary: {
              pageLink: 'https://www.guitarmasteryintensive.com/major-scale',
            },
            requiredNodeIds: ['184', '204', '183', '203', '182', '202'],
            type: 'Activity',
            media: {
              type: 'soundslice',
              embedString: 'https://www.soundslice.com/slices/8ryfc/embed/',
            },
            maxStars: 5,
          },
        ],
      },
    ],
  },
  {
    name: 'Wrap-Up',
    id: '259',
    description: '',
    optional: false,
    requiredNodeIds: [],
    type: 'Folder',
    children: [
      {
        name: 'Course completion',
        id: '213',
        assignmentType: 'watch',
        description: '',
        optional: false,
        modifications: {},
        requiredNodeIds: ['181'],
        type: 'Activity',
        media: { type: 'vimeo', embedString: '452261270' },
        maxStars: 1,
      },
    ],
  },
];

export const curricv2: Curriculum = {
  id: 6,
  name: 'Guitar Mastery Intensive v2',
  description: 'Bigger better bolder faster stronger',
  contents: curricChildren,
  modificationCategories: [
    {
      name: 'Tempo',
      id: 1,
      modificationIds: [2, 3, 4, 5, 6],
      description:
        'Explore the whole spectrum of tempo by choosing various ranges in each practice session. Remember that slow is just as important as fast and sometimes even more challenging. If you find yourself gravitating toward a certain range, be sure to branch out of the comfort zone as often as possible for exercises you know relatively well.',
      vimeoId: '',
    },
    {
      name: 'Harmonic',
      id: 2,
      modificationIds: [
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
      ],
      description:
        'This is how you take your knowledge of the Major Scale to the next level. Work on your ability to identify scale tones and switch them on the spot to create all the various modes and scales. Chose a mode or scale to adapt your exercise to. Work on 1 tonality through many exercises for a longer-term study, or jump around each day if you want to test your ability to improvise and adjust.',
      vimeoId: '',
    },
    {
      name: 'Progressions',
      id: 3,
      modificationIds: [32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43],
      description:
        'Music is ultimately the play of combining patterns. These common cycles or progressions will show you how to think in patterns and learn to identify logical movements through keys or chords. Practicing any exercise using all progressions will help you more immediately access any chord change or key change in real playing situations.',
      vimeoId: '',
    },
    {
      name: 'Voice Leading',
      id: 4,
      modificationIds: [44, 45],
      description:
        'Work on your ability to swiftly move through chords by exploring the various inversion options. With the power of choice, you are able to make artistic decisions in your music that create an intended result. Learning how to travel through inversions in an elegant way is one of the most sophisticated and effective techniques for chordal instrumentalists to express songs with a personal touch.',
      vimeoId: '',
    },
    {
      name: 'Rhythm',
      id: 5,
      modificationIds: [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],
      description:
        'Deepen your understanding of the beat by attempting a solid and steady execution of you exercises through any subdivision or rhythmic value. Use each of these modifications to challenge your familiarity with the exercise, as well as to strengthen your sense of time (which is the quality of our playing that will reach listeners above anything else including note choices).',
      vimeoId: '',
    },
    {
      name: 'Time Feel',
      id: 6,
      modificationIds: [57, 58, 59, 60, 61],
      description:
        'Work on your sensitivity to Time Feel by exploring how you express your exercises. Using your exercises to work on more subtle aspects of time will help introduce you to concepts that you can chose to make a part of your playing. Even if you gravitate toward a default time feel, see if you can expand that sensitivity to develop control over your own time, and therefore a deeper appreciation for other players.',
      vimeoId: '',
    },
    {
      name: 'Melodic',
      id: 7,
      modificationIds: [62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74],
      description:
        'Get more out of your exercises by exploring some of the infinite melodic variations. See if you can figure out how to play them in different ways, get creative, and develop your mind’s ability to change things up. This will help you strengthen your sense of the Root, and also challenge your coordination and ear training.',
      vimeoId: '',
    },
    {
      name: 'Voicing',
      id: 8,
      modificationIds: [75, 76, 77],
      description:
        'Expand your chord vocabulary by applying variations to your exercises. Work on your ability to invert chords on the spot, or to notice the difference between spread and close voicings. Practicing chords in a structured container will give you the vision and skill to use chords in ways you wouldn’t have imagined, you never know what these modifications may inspire in you!',
      vimeoId: '',
    },
    {
      name: 'Directional',
      id: 9,
      modificationIds: [78, 79, 80, 81],
      description:
        'Work on your visual fretboard reference by altering exercises directionally. Sometimes playing from the highest note to the lowest will be enough to trip us up, but by exploring these possibilities we can prepare for anything in musical situations. This will also strengthen your relationship with the root, and help you navigate the correct notes in more ways than one.',
      vimeoId: '',
    },
    {
      name: 'Right Hand',
      id: 10,
      modificationIds: [82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92],
      description:
        'Dissect the possibilities of your right hand articulation to find the techniques that most suit your personal voice. So much of our sound is determined by how we use the right hand. Developing right hand control in ways that are most relevant to your style and genres is one of the keys to expressiveness.',
      vimeoId: '',
    },
    {
      name: 'Picking Pattern',
      id: 11,
      modificationIds: [93, 94, 95],
      description:
        'Challenge your coordination and right hand control by getting creative with your picking patterns. Sometimes by switching the order in which you play the notes of a chord, you can completely shift the feeling of the chords being played. You will also notice that your brain works quite hard to learn new patterns and expand its rhythmic sensibility. Experiment as much as possible here, and see what kinds of games you can play with the right hand, and how the notes line up with the beats.',
      vimeoId: '',
    },
  ],
  modifications: [
    {
      title: 'Default',
      id: 2,
      description: 'This is the tempo Ila plays in the video',
      vimeoId: '',
    },
    {
      title: '60-80bpm',
      id: 3,
      description: 'Larghetto, Adagio',
      vimeoId: '',
    },
    {
      title: '80-100bpm',
      id: 4,
      description: 'Andante, Andante Moderato',
      vimeoId: '',
    },
    {
      title: '100-120bpm',
      id: 5,
      description: 'Moderato, Allegreto',
      vimeoId: '',
    },
    {
      title: '>120bpm',
      id: 6,
      description: 'Allegro, Vivace, Presto',
      vimeoId: '',
    },
    {
      title: 'Major',
      id: 7,
      description: 'Scale Tones: R, 2, 3, 4, 5, 6, 7',
      vimeoId: '',
    },
    {
      title: 'Harmonic Minor',
      id: 8,
      description: 'Scale Tones: R, 2, b3, 4, 5, b6, 7',
      vimeoId: '',
    },
    {
      title: 'Harmonic Major',
      id: 9,
      description: 'Scale Tones: R, 2, 3, 4, 5, b6, 7',
      vimeoId: '',
    },
    {
      title: 'Melodic Minor',
      id: 10,
      description: 'Scale Tones: R, 2, b3, 4, 5, 6, 7',
      vimeoId: '',
    },
    {
      title: 'Ionian (Default)',
      id: 11,
      description: 'Scale Tones: R, 2, 3, 4, 5, 6, 7',
      vimeoId: '',
    },
    {
      title: 'Dorian',
      id: 12,
      description: 'Scale Tones: R, 2, b3, 4, 5, 6, b7',
      vimeoId: '',
    },
    {
      title: 'Phrygian',
      id: 13,
      description: 'Scale Tones: R, b2, b3, 4, 5, b6, b7',
      vimeoId: '',
    },
    {
      title: 'Lydian',
      id: 14,
      description: 'Scale Tones: R, 2, 3, #4, 5, 6, 7',
      vimeoId: '',
    },
    {
      title: 'Mixolydian',
      id: 15,
      description: 'Scale Tones: R, 2, 3, 4, 5, 6, b7',
      vimeoId: '',
    },
    {
      title: 'Aeolian',
      id: 16,
      description: 'Scale Tones: R, 2, b3, 4, 5, b6, 7',
      vimeoId: '',
    },
    {
      title: 'Locrian',
      id: 17,
      description: 'Scale Tones: R, b2, b3, 4, b5, b6, b7',
      vimeoId: '',
    },
    {
      title: 'Make the Triads Sus 4',
      id: 18,
      description: 'Exchange the 3rd for the 4th in any voicing',
      vimeoId: '',
    },
    {
      title: 'Make the Triads Sus2',
      id: 19,
      description: 'Exchange the 3rd for the 2nd in any voicing',
      vimeoId: '',
    },
    {
      title: 'Harmonic Minor (Chords)',
      id: 20,
      description:
        'Move through diatonic harmony within the Harmonic Minor Scale',
      vimeoId: '',
    },
    {
      title: 'Harmonic Major (Chords)',
      id: 21,
      description:
        'Move through diatonic harmony within the Harmonic Major Scale',
      vimeoId: '',
    },
    {
      title: 'Melodic Minor (Chords)',
      id: 22,
      description:
        'Move through diatonic harmony within the Melodic Minor Scale',
      vimeoId: '',
    },
    {
      title: 'Harmonic Minor (7th Chords)',
      id: 23,
      description:
        'Move through diatonic harmony within the Harmonic Minor Scale',
      vimeoId: '',
    },
    {
      title: 'Melodic Minor (7th Chords)',
      id: 24,
      description:
        'Move through diatonic harmony within the Melodic Minor Scale',
      vimeoId: '',
    },
    {
      title: '#5',
      id: 25,
      description: 'Sharp the 5th for an augmented sound',
      vimeoId: '',
    },
    {
      title: '#11',
      id: 26,
      description: 'Turn the 5th into a #11',
      vimeoId: '',
    },
    {
      title: '7th down to the 6th',
      id: 27,
      description: 'Drop the 7th to the 6th for a mellow 6 chord',
      vimeoId: '',
    },
    {
      title: '5th brought up to the 13th',
      id: 28,
      description: 'Raise the 5th to a 13 for a useful extension',
      vimeoId: '',
    },
    {
      title: 'b5',
      id: 29,
      description: 'Flat the 5 to create movement and color',
      vimeoId: '',
    },
    {
      title: 'Minor/Major 7',
      id: 30,
      description:
        'Raise the b7 to a natural 7, this is the first chord in the Harmonic & Melodic minor scales',
      vimeoId: '',
    },
    {
      title: 'b7th goes to the 6th',
      id: 31,
      description: 'Drop the b7 down to a 6 for movement and variation',
      vimeoId: '',
    },
    {
      title: 'Cycle of 4ths/5ths',
      id: 32,
      description: 'Play your exercises through all Keys using this cycle',
      vimeoId: '',
    },
    {
      title: 'Cycle of Major 3rds/Minor 6ths',
      id: 33,
      description:
        'Play your exercises through 3 keys using this cycle, start in various keys to move through all 12',
      vimeoId: '',
    },
    {
      title: 'Cycle of Minor 3rds/Major 6ths',
      id: 34,
      description:
        'Play your exercises through 4 keys using this cycle, start in various keys to move through all 12',
      vimeoId: '',
    },
    {
      title: 'Cycle of Whole Steps',
      id: 35,
      description:
        'Play your exercises through 6 keys using this cycle, then start a half step up to get the next 6',
      vimeoId: '',
    },
    {
      title: 'Random Keys',
      id: 36,
      description: 'This is the best way to test your fluency of roots!',
      vimeoId: '',
    },
    {
      title: 'Chromatically',
      id: 37,
      description:
        'This is the best place to start when playing something new in all keys',
      vimeoId: '',
    },
    {
      title: 'Cycle of 5ths/4ths (Triads)',
      id: 38,
      description: 'Discover how triads move through the cycle of 4ths & 5ths',
      vimeoId: '',
    },
    {
      title: 'Cycle of Maj 3rds/ Min 6th (Triads)',
      id: 39,
      description:
        'Discover how triads move through the cycle of min3rds & Maj6ths',
      vimeoId: '',
    },
    {
      title: 'Cycle of Min 3rds/ Maj 6th (Triads)',
      id: 40,
      description:
        'Discover how triads move through the cycle of Maj3rds & min6ths',
      vimeoId: '',
    },
    {
      title: 'Whole Steps (Triads)',
      id: 41,
      description: 'Discover how triads move through a Whole Tone Scale',
      vimeoId: '',
    },
    {
      title: 'Chromatically (Triads)',
      id: 42,
      description:
        'Integrate any triad shape by playing it chromatically though all keys',
      vimeoId: '',
    },
    {
      title: 'Chromatically (7th Chords)',
      id: 43,
      description:
        'Integrate any 7th Chord shape by playing it chromatically though all keys',
      vimeoId: '',
    },
    {
      title: 'Voice Leading (Triads)',
      id: 44,
      description:
        'Find the closest and most fluid sounding inversions to move through progressions',
      vimeoId: '',
    },
    {
      title: 'Voice Leading (7th Chords)',
      id: 45,
      description:
        'Find the closest and most fluid sounding inversions to move through progressions',
      vimeoId: '',
    },
    {
      title: 'Whole Notes',
      id: 46,
      description: 'Test your focus and play exercises in perfect whole notes',
      vimeoId: '',
    },
    {
      title: 'Half Notes',
      id: 47,
      description: 'Test your focus and play exercises in perfect whole notes',
      vimeoId: '',
    },
    {
      title: 'Quarter Notes',
      id: 48,
      description:
        'Play any exercise in quarter notes (on each click of the metronome)',
      vimeoId: '',
    },
    {
      title: '1/8th Notes',
      id: 49,
      description: 'Play any exercise in 8th notes (2 notes per click)',
      vimeoId: '',
    },
    {
      title: 'Triplets',
      id: 50,
      description: 'Play any exercise in triplets (3 notes per click)',
      vimeoId: '',
    },
    {
      title: '1/16th Notes',
      id: 51,
      description: 'Play any exercise in 16th notes (4 notes per click)',
      vimeoId: '',
    },
    {
      title: 'Quintuplets',
      id: 52,
      description: 'Play any exercise in quintuplets (5 notes per click)',
      vimeoId: '',
    },
    {
      title: 'Sextuplets',
      id: 53,
      description: 'Play any exercise in sextuplets (6 notes per click)',
      vimeoId: '',
    },
    {
      title: 'Septuplets',
      id: 54,
      description: 'Play any exercise in sextuplets (7 notes per click)',
      vimeoId: '',
    },
    {
      title: '1/32nd Notes',
      id: 55,
      description: 'Play any exercise in 32nd notes (8 notes per click)',
      vimeoId: '',
    },
    {
      title: 'Rhythmic Patterns',
      id: 56,
      description:
        'Create unique rhythmic patterns to move through the exercises',
      vimeoId: '',
    },
    {
      title: 'Swing',
      id: 57,
      description: 'Explore the bounce of a swing feel',
      vimeoId: '',
    },
    {
      title: 'Straight',
      id: 58,
      description: 'Play a steady straight feel',
      vimeoId: '',
    },
    {
      title: '6/8 Feel',
      id: 59,
      description: 'Explore the polyrhythmic nauture of 6/8',
      vimeoId: '',
    },
    {
      title: '9/8 Feel',
      id: 60,
      description: 'Explore the triplet feel of 9/8',
      vimeoId: '',
    },
    {
      title: 'Styles (?)',
      id: 61,
      description: '',
      vimeoId: '',
    },
    {
      title: 'Continue the pattern as high and as low as you can',
      id: 62,
      description: 'Find notes in the exercise as high as possible',
      vimeoId: '',
    },
    {
      title: 'Invert every other pattern',
      id: 63,
      description:
        'Switch up the pattern exercises by playing up one and down the other',
      vimeoId: '',
    },
    {
      title: 'Invert every other interval',
      id: 64,
      description:
        'Switch up the interval exercises by playing up one and down the other',
      vimeoId: '',
    },
    {
      title: 'Play Harmonically',
      id: 65,
      description: '?',
      vimeoId: '',
    },
    {
      title: 'Double Stops',
      id: 66,
      description: 'Play both notes at once',
      vimeoId: '',
    },
    {
      title: 'Play each triad as a chord',
      id: 67,
      description: 'Strum instead of pick each 3 note triad',
      vimeoId: '',
    },
    {
      title: 'Play as 4 Note Triads',
      id: 68,
      description: 'Find the 3 sets of 4 note triads within these shapes',
      vimeoId: '',
    },
    {
      title: 'Play the scale in first inversion',
      id: 69,
      description: 'Use your 1st inversion shapes to move through the scale',
      vimeoId: '',
    },
    {
      title: 'Play the scale chords in second Inversion',
      id: 70,
      description: 'Use your 2nd inversion shapes to move through the scale',
      vimeoId: '',
    },
    {
      title: 'Invert every other inversion',
      id: 71,
      description:
        'Notice the difference in sound when you play notes ascending vs descending',
      vimeoId: '',
    },
    {
      title: 'Invert every other triad',
      id: 72,
      description:
        'Notice the difference in sound when you play notes ascending vs descending',
      vimeoId: '',
    },
    {
      title: 'Only the top octave',
      id: 73,
      description:
        'Play through progressions using just the top octave of arpeggios',
      vimeoId: '',
    },
    {
      title: 'Only the Bottom Octave',
      id: 74,
      description:
        'Play through progressions using just the bottom octave of arpeggios',
      vimeoId: '',
    },
    {
      title: 'Convert to Open Voicings',
      id: 75,
      description: 'Find the beautiful open voicing of each triad',
      vimeoId: '',
    },
    {
      title: 'Play the Scale Chords in First Inversion',
      id: 76,
      description: 'Use your 1st inversion shapes to move through the scale',
      vimeoId: '',
    },
    {
      title: 'Play the Scale Chords in Second Inversion',
      id: 77,
      description: 'Use your 2nd inversion shapes to move through the scale',
      vimeoId: '',
    },
    {
      title: 'Ascending and Descending',
      id: 78,
      description: 'This is how most exercises are demonstrated',
      vimeoId: '',
    },
    {
      title: 'Ascending only',
      id: 79,
      description: 'Try playing only ascending',
      vimeoId: '',
    },
    {
      title: 'Descending only',
      id: 80,
      description:
        'Test your knowledge of roots by starting at the highest note first',
      vimeoId: '',
    },
    {
      title: 'Descending and Ascending',
      id: 81,
      description:
        'Find creative possibilities by descending the scale first before ascending',
      vimeoId: '',
    },
    {
      title: 'Play triplets on each note',
      id: 82,
      description: 'Develop right hand control by playing each note 3 times',
      vimeoId: '',
    },
    {
      title: 'Tremolo on each note',
      id: 83,
      description: 'Practice this useful and expressive technique',
      vimeoId: '',
    },
    {
      title: 'Slurred (Hammer on’s an pull offs)',
      id: 84,
      description:
        'Work on fluidly blending notes together for speed playing and expressive variation',
      vimeoId: '',
    },
    {
      title: 'Hybrid',
      id: 85,
      description: 'Play with a pick and fingers',
      vimeoId: '',
    },
    {
      title: 'Finger picking',
      id: 86,
      description: 'Use only your fingers to find picking patterns',
      vimeoId: '',
    },
    {
      title: 'Economy',
      id: 87,
      description: 'Economy picking',
      vimeoId: '',
    },
    {
      title: 'Alternate',
      id: 88,
      description:
        'Alternate picking is a great place to start developing a solid right hand',
      vimeoId: '',
    },
    {
      title: '4x 16th Notes on Each Note',
      id: 89,
      description:
        'Work on your alternate picking by playing each note 4 times',
      vimeoId: '',
    },
    {
      title: 'Picked',
      id: 90,
      description: 'Use only the pick',
      vimeoId: '',
    },
    {
      title: 'Using Fingers',
      id: 91,
      description: 'Use only the fingers',
      vimeoId: '',
    },
    {
      title: 'Strummed',
      id: 92,
      description: 'Strum each chord',
      vimeoId: '',
    },
    {
      title: 'Arpeggiate the Chord',
      id: 93,
      description: 'Pick each note separately to create texture',
      vimeoId: '',
    },
    {
      title: 'Make your own picking pattern',
      id: 94,
      description: 'Get creative and find new sounds',
      vimeoId: '',
    },
    {
      title: 'Invert every other pattern',
      id: 95,
      description:
        'Test your coordination by switching the pattern up on each chord',
      vimeoId: '',
    },
  ],
};

export const curricMap = mapCurric(curricv2);
