import { useContext } from 'react';
import { LayoutProviderContext, LayoutType } from './LayoutProvider';

export const useIsLandscape = () => {
  const { activeLayout } = useContext(LayoutProviderContext);
  return activeLayout === LayoutType.Landscape;
};

export const useIsPortrait = () => {
  const { activeLayout } = useContext(LayoutProviderContext);
  return activeLayout === LayoutType.Portrait;
};

export const useIsMobileWidth = () => {
  const { screenWidth } = useContext(LayoutProviderContext);
  return screenWidth < 768;
};

export const useIsWidthLessThan = (width: number) => {
  const { screenWidth } = useContext(LayoutProviderContext);
  return screenWidth < width;
};
