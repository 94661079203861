import React from 'react';
import styled from 'styled-components/native';
import { colors, fonts } from '../../constants/theme';
import { pushStyle } from './utils';

interface Props {
  color?: string;
  font?: string;
  size?: number;
  lineHeight?: string;
  marginBottom?: number;
  marginTop?: number;
  marginLeft?: number;
  marginRight?: number;
  marginX?: number;
  marginY?: number;
  margin?: number;
  textDecoration?: string;
  textAlign?: 'center' | 'left' | 'right';
  textTransform?: 'uppercase' | 'capitalize' | 'lowercase' | 'none';
  children: any;
}

interface StyledTextProps {
  cssstyle: string;
}
const StyledText: React.FC<StyledTextProps> = styled.Text`
  ${(props: { cssstyle: string }) => props.cssstyle}
`;

export const Text: React.FC<Props> = ({
  color,
  font,
  size,
  lineHeight,
  marginBottom,
  marginTop,
  marginLeft,
  marginRight,
  marginX,
  marginY,
  margin,
  textAlign,
  textTransform,
  children,
  textDecoration,
}) => {
  const styles: string[] = [];
  pushStyle(styles, 'color', color || colors.tanzanite);
  pushStyle(styles, 'font-family', font || fonts.normal);
  pushStyle(styles, 'font-size', size || 14, 'px');
  pushStyle(styles, 'line-height', lineHeight);
  pushStyle(styles, 'text-align', textAlign);
  pushStyle(styles, 'text-transform', textTransform);
  pushStyle(styles, 'margin-left', marginLeft || marginX || margin, 'px');
  pushStyle(styles, 'margin-right', marginRight || marginX || margin, 'px');
  pushStyle(styles, 'margin-top', marginTop || marginY || margin, 'px');
  pushStyle(styles, 'margin-bottom', marginBottom || marginY || margin, 'px');
  pushStyle(styles, 'text-decoration', textDecoration);

  return <StyledText cssstyle={styles.join('')}>{children}</StyledText>;
};
