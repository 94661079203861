import React from 'react';
import { Row, Box, Text } from '../styled';
import { FillIcon } from '../Icon';
import { colors, fonts } from '../../constants/theme';

export interface AlertProps {
  icon: string;
  title: string;
  message: string;
}

export const Alert: React.FC<AlertProps> = ({ icon, title, message }) => {
  return (
    <Row
      backgroundColor={colors.alertRed}
      paddingX={36}
      paddingY={24}
      width="100%"
    >
      <FillIcon name={icon} color={colors.white} size="alert" />
      <Box overflow="hidden" flex={1} marginLeft={6}>
        <Text marginBottom={6} size={16} color={colors.white}>
          {title}
        </Text>
        <Text font={fonts.thin} color={colors.white}>
          {message}
        </Text>
      </Box>
    </Row>
  );
};
