import React, { FC } from 'react';
import { TextNodeViewProps } from './types';

import { Box } from '../styled';
import { ButtonRow } from '../ButtonRow';
import { ButtonProps } from '../Button';
import { NodeViewTabs } from './NodeViewTabs';

export const TextNodeView: FC<TextNodeViewProps> = (props) => {
  const { primaryCta, secondaryCta, tabs } = props;

  const secondaryCtaButton = secondaryCta && {
    ...secondaryCta,
    type: 'Secondary',
  };
  const primaryCtaButton = primaryCta && { ...primaryCta, type: 'Primary' };
  const buttons = [secondaryCtaButton, primaryCtaButton].filter(
    Boolean
  ) as ButtonProps[];

  const formattedTabs = <NodeViewTabs tabs={tabs} />;

  const formattedButtons = <ButtonRow buttons={buttons} />;

  return (
    <Box height="100%" flex={1}>
      {
        <>
          {formattedTabs}
          {formattedButtons}
        </>
      }
    </Box>
  );
};
