import React from 'react';
import styled from 'styled-components/native';
import { TouchableOpacity } from 'react-native';

import { theme, colors } from '../../constants/theme';
import { Text } from '../styled';

const ButtonWrapper = styled.View`
  border-radius: ${theme.common.borderRadius};
  border-width: 1px;
  border-color: ${colors.white};
  padding: 10px 20px;
`;

interface SecondaryCtaProps {
  text: string;
  onPress?: () => void;
}

export const SecondaryCta: React.FC<SecondaryCtaProps> = ({
  text,
  onPress,
}) => {
  return (
    <ButtonWrapper>
      <TouchableOpacity onPress={onPress}>
        <Text color={colors.white} size={16} textTransform="uppercase">
          {text}
        </Text>
      </TouchableOpacity>
    </ButtonWrapper>
  );
};
