import React from 'react';
import { RootNavScreen } from '../screens';
import { ResetPasswordSuccess } from 'sonora-design-system';

export interface ResetPasswordSuccessProps {}

const ResetPasswordSuccessScreen: RootNavScreen<'ResetPasswordSuccess'> = ({
  navigation,
  route,
}) => {
  return (
    <ResetPasswordSuccess onBackToLogin={() => navigation.navigate('Signin')} />
  );
};

export default ResetPasswordSuccessScreen;
