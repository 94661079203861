import React, { useState } from 'react';

import { Node } from 'sonora-types';
import { Box } from '../styled';
import { SectionHeader } from '../SectionHeader';
import { theme, colors } from '../../constants/theme';
import { convertHexToRGBA } from '../../util/colors';
import { CourseMapListItem } from './CourseMapListItem';
import { CourseMapListProps, CourseMapFolderProps } from './types';
import { calculateFolderMaxStars, calculateFolderStars } from './utils';

export const CourseMapFolder: React.FC<CourseMapFolderProps> = ({
  folder,
  isMentor,
  unlockedNodeIds,
  progress,
  depth = 0,
  hasBorder,
  levelPadding = 0,
  onItemPress,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const unlockedStars = calculateFolderStars(folder, progress);
  const maxStars = calculateFolderMaxStars(folder);

  return (
    <>
      <CourseMapListItem
        title={folder.name}
        isMentor={isMentor}
        unlockedStars={unlockedStars}
        maxStars={maxStars}
        depth={depth}
        isFolder={true}
        hasBorder={hasBorder}
        isOpen={isOpen}
        levelPadding={levelPadding}
        onToggleOpen={() => {
          setIsOpen(!isOpen);
        }}
        locked={false}
      />
      {isOpen ? (
        <CourseMapList
          items={folder.children}
          isMentor={isMentor}
          unlockedNodeIds={unlockedNodeIds}
          progress={progress}
          depth={depth + 1}
          levelPadding={levelPadding}
          onItemPress={onItemPress}
        />
      ) : null}
    </>
  );
};

export const CourseMapList: React.FC<CourseMapListProps> = ({
  items,
  isMentor,
  unlockedNodeIds,
  progress,
  depth = 0,
  levelPadding = 0,
  onItemPress,
}) => {
  const getIcon = (assignmentType: string) =>
    assignmentType === 'watch'
      ? 'playFilled'
      : assignmentType === 'practice'
      ? 'soundslice'
      : '';

  const optionalItems = items.filter((item) => item.optional);
  const nonOptionalItems = items.filter((item) => !item.optional);

  const renderList = (items: Node[]) => {
    return items.map((item: Node, i: number) =>
      item.type === 'Folder' ? (
        <CourseMapFolder
          key={item.id}
          isMentor={isMentor}
          folder={item}
          unlockedNodeIds={unlockedNodeIds}
          progress={progress}
          depth={depth}
          hasBorder={i < items.length - 1}
          levelPadding={levelPadding}
          onItemPress={onItemPress}
        />
      ) : (
        <CourseMapListItem
          key={item.id}
          isMentor={isMentor}
          title={item.name}
          locked={!unlockedNodeIds.includes(item.id) && !isMentor}
          unlockedStars={
            (progress[item.id] && progress[item.id].currentStars) || 0
          }
          maxStars={item.maxStars}
          icon={getIcon(item.assignmentType)}
          depth={depth}
          hasBorder={i < items.length - 1}
          levelPadding={levelPadding}
          onToggleOpen={() => {
            if (onItemPress) {
              onItemPress(item.id);
            }
          }}
        />
      )
    );
  };

  return (
    <Box
      paddingY={12}
      backgroundColor={theme.coursemap.levels[depth].backgroundColor}
    >
      {renderList(nonOptionalItems)}
      {optionalItems.length > 0 && (
        <>
          <SectionHeader
            title="OPTIONAL"
            backgroundColor={theme.coursemap.levels[depth].backgroundColor}
            alignment="flex-start"
            textColor={convertHexToRGBA(colors.tanzanite, 70)}
            paddingTop={12}
            paddingLeft={levelPadding * depth}
            paddingRight={levelPadding * depth}
          />
          {renderList(optionalItems)}
        </>
      )}
    </Box>
  );
};
