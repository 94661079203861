import React from 'react';

import { colors } from '../../constants/theme';
import { Box } from '../styled';
import { ModificationsCategory } from '../ModificationsCategory';
import {
  activeModifications,
  categories as modificationCategories,
  makeModifications,
  categories,
} from '../ModificationDetailView/mock-data';
import {
  ModificationId,
  ModificationCategory,
  ActiveModificationsMap,
} from '../ModificationDetailView/types';
import { CategoryViewProps } from './types';

const allModifications = makeModifications();

export const CategoryView: React.FC<CategoryViewProps> = ({ navigation }) => {
  const [activeModificationIds, setActiveModificationIds] = React.useState(
    activeModifications
  );

  const handleRemoveModification = (modification: ModificationId) => {
    setActiveModificationIds(
      activeModificationIds.filter((m) => m !== modification)
    );
  };

  const handlePressActiveModification = (modificationId: ModificationId) => {
    const category = categories.find(
      (c: ModificationCategory) => c.modifications.indexOf(modificationId) >= 0
    );

    if (category) {
      navigation.push('ModificationDetails', {
        categoryId: category?.id,
        activeModificationId: modificationId,
      });
    }
  };

  const handlePressCategory = (category: ModificationCategory) => {
    const activeModification = category.modifications.find(
      (m: ModificationId) => activeModificationIds.indexOf(m) >= 0
    );

    navigation.push('ModificationDetails', {
      categoryId: category.id,
      activeModificationId: activeModification,
    });
  };

  const handleHide = () => {
    console.log('Pressed "Hide" button');
  };

  const getInitialModifications = () => {
    let initialModifications: ActiveModificationsMap = {};

    activeModifications.forEach((modification) => {
      categories.forEach((category) => {
        if (category.modifications.includes(modification)) {
          initialModifications[category.id] = modification;
        }
      });
    });

    return initialModifications;
  };

  return (
    <Box
      padding={16}
      backgroundColor={colors.agate}
      width="400px"
      height="100%"
    >
      <ModificationsCategory
        activeModificationsMap={getInitialModifications()}
        categories={modificationCategories}
        modifications={allModifications}
        modificationHeaderBackground={colors.agate}
        onRemoveActiveModification={handleRemoveModification}
        onPressActiveModification={handlePressActiveModification}
        onHide={handleHide}
        onPressCategory={handlePressCategory}
      />
    </Box>
  );
};
