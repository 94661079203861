import React, { useEffect, useState, ReactElement } from 'react';
import {
  Animated,
  TouchableOpacity,
  GestureResponderEvent,
  Easing,
  Platform,
} from 'react-native';
import Svg, { Circle } from 'react-native-svg';

interface RecordButtonProps {
  isActive?: boolean;
  onPress?: (ev: GestureResponderEvent) => void;
  disabled?: boolean;
  size?: number;
}

interface RotatingViewProps {
  duration: number;
  isActive: boolean;
  children: ReactElement;
}

const RotatingView: React.FC<RotatingViewProps> = ({
  duration,
  isActive,
  children,
}) => {
  const [rotation] = useState(new Animated.Value(0));

  useEffect(() => {
    if (Platform.OS === 'ios' && !!isActive) {
      Animated.loop(
        Animated.timing(rotation, {
          useNativeDriver: true,
          toValue: 1,
          duration,
          easing: Easing.linear,
        }),
        { iterations: -1 }
      ).start();
    }
  }, [isActive]);

  return (
    <Animated.View
      style={{
        flex: 0,
        transform: [
          {
            rotateZ: rotation.interpolate({
              inputRange: [0, 1],
              outputRange: ['0deg', '360deg'],
            }),
          },
          { perspective: 1000 },
        ],
      }}
    >
      {children}
    </Animated.View>
  );
};

const RecordButton: React.FC<RecordButtonProps> = ({
  isActive,
  onPress,
  disabled,
  size = 64,
}) => {
  const strokeWidth = 2;
  const c = size / 2;
  const strokedR = (size - strokeWidth) / 2;
  const fillR = strokedR - 4;
  const color = isActive ? 'red' : '#f3f3f3';

  return (
    <TouchableOpacity disabled={disabled} onPress={onPress}>
      <RotatingView duration={5000} isActive={!!isActive}>
        <Svg height={size} width={size} opacity={disabled ? 0.5 : 1}>
          <Circle cx={c} cy={c} r={fillR} fill={color} />
          <Circle
            cx={c}
            cy={c}
            r={strokedR}
            fill="none"
            strokeWidth={strokeWidth}
            stroke={color}
            strokeDasharray={isActive ? 4 : 0}
          />
        </Svg>
      </RotatingView>
    </TouchableOpacity>
  );
};

export default RecordButton;
