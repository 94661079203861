import React from 'react';
import styled from 'styled-components/native';

import { SquiggleImageProps } from './types';

import headerImage1 from '../../assets/images/header-img-1.png';
import headerImage2 from '../../assets/images/header-img-2.png';

const StyledImage = styled.Image`
  width: 45px;
  height: 6px;
`;

export const SquiggleImage: React.FC<SquiggleImageProps> = ({
  headerStyle,
}) => {
  const headerImage = headerStyle === 'ruby' ? headerImage2 : headerImage1;

  return <StyledImage source={headerImage} />;
};
