import React from 'react';
import styled from 'styled-components/native';

import { Box, Row, Text } from '../styled';
import { Button } from '../Button';
import { colors, fonts } from '../../constants/theme';
import ConfettiImage from '../../assets/images/confetti.png';
import UnlockedImage from '../../assets/images/unlocked.png';

const ImageDecoration1 = styled.Image`
  width: 300px;
  height: 190px;
`;

const ImageDecoration2 = styled.Image`
  width: 193px;
  height: 185px;
  margin-top: -140px;
`;

interface ModuleUnlockProps {
  title?: string;
  moduleName: string;
  onDismiss?: () => void;
  onGo?: () => void;
}

export const ModuleUnlock: React.FC<ModuleUnlockProps> = ({
  title,
  moduleName,
  onDismiss,
  onGo,
}) => {
  return (
    <Box backgroundColor={colors.white} padding={40}>
      <Text
        font={fonts.headline}
        size={32}
        textAlign="center"
        marginBottom={12}
      >
        {title || 'New module unlocked!'}
      </Text>

      <Box alignItems="center" marginBottom={30}>
        <ImageDecoration1 source={ConfettiImage} />
        <ImageDecoration2 source={UnlockedImage} />
      </Box>
      <Text font={fonts.thin} size={20} textAlign="center" marginBottom={32}>
        Nice work! You've unlocked <Text size={20}>{moduleName}</Text> module
      </Text>
      <Row justifyContent="center">
        <Box marginRight={12}>
          <Button type="Secondary" text="Dismiss" onPress={onDismiss} />
        </Box>
        {onGo ? (
          <Box>
            <Button type="Primary" text="Let's Go!" onPress={onGo} />
          </Box>
        ) : undefined}
      </Row>
    </Box>
  );
};
