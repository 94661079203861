import React, { FC, useEffect, useRef } from 'react';
import {
  View,
  Platform,
  StyleSheet,
  Animated,
  TouchableOpacity,
} from 'react-native';
import { WebView as WebViewMobile } from 'react-native-webview';
// @ts-ignore ReactNativeWebWebview has no types at the moment
import { default as WebViewWeb } from 'react-native-web-webview';
import { FillIcon, colors } from 'sonora-design-system';

const WebView = Platform.OS === 'web' ? WebViewWeb : WebViewMobile;

export interface L2SidebarDroneDrawerProps {
  isDrawerOpen: boolean;
  setDroneDrawerOpen: (isOpen: boolean) => void;
}

const drawerHeight = 330;
const drawerWidth = 314;

const L2SidebarDroneDrawer: FC<L2SidebarDroneDrawerProps> = (props) => {
  let width = props.isDrawerOpen ? drawerWidth : 0;
  let widthAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(widthAnim, {
      toValue: width,
      duration: 400,
      useNativeDriver: false,
    }).start();
  }, [width]);

  return (
    <Animated.View style={[styles.droneContainer, { width }]}>
      <View
        style={[styles.closeButton, { opacity: props.isDrawerOpen ? 1 : 0 }]}
      >
        <TouchableOpacity onPress={() => props.setDroneDrawerOpen(false)}>
          <FillIcon name="x" color={colors.white} />
        </TouchableOpacity>
      </View>
      <WebView
        style={{
          height: drawerHeight,
          width: Platform.OS === 'web' ? width : drawerWidth,
        }}
        source={{
          uri: 'https://sonora-goat-metronome.herokuapp.com/drone',
        }}
      />
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  droneContainer: {
    zIndex: 1,
    position: 'absolute',
    bottom: 0,
    height: drawerHeight,
    left: 48,
  },
  closeButton: {
    position: 'absolute',
    zIndex: 1,
    right: 24,
    top: 12,
  },
});

export default L2SidebarDroneDrawer;
