import React from 'react';
import { storiesOf } from '@storybook/react-native';

import { LayoutProvider } from '../../util/LayoutProvider';
import { PracticePostThreadHeader, PracticeModifications } from '.';
import { practiceModifcationProps } from './mock-data';
import { Box } from '../styled';

const practicePostThreadStories = storiesOf('PracticePostThread', module);

const PracticePostThreadHeaderStory = () => (
  <LayoutProvider>
    <PracticePostThreadHeader
      postTitle="5 Forms: 6th String Back Form"
      onExit={() => console.log('Exit')}
    />
  </LayoutProvider>
);

const PracticeModificationsStory = () => (
  <LayoutProvider>
    <Box width="600px" flex={1}>
      <PracticeModifications {...practiceModifcationProps} />
    </Box>
  </LayoutProvider>
);

practicePostThreadStories.add('Header', PracticePostThreadHeaderStory);
practicePostThreadStories.add('Modifications', PracticeModificationsStory);
