import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { RootNavScreen } from '../screens';
import { CourseMapOverview } from './CourseMapOverview';
import { CourseMapDetails } from './CourseMapDetails';

import { L1FrameLeftCourseMapHOC } from '../../frame/L1FrameLeft';

const Stack = createStackNavigator();

export const CourseMap: RootNavScreen<'CourseMap'> = () => {
  return (
    <Stack.Navigator initialRouteName="CourseMapOverview">
      <Stack.Screen
        name="CourseMapOverview"
        component={L1FrameLeftCourseMapHOC<'CourseMapOverview'>(
          CourseMapOverview
        )}
        options={{ title: 'Course Map' }}
      />
      <Stack.Screen
        name="CourseMapDetails"
        component={L1FrameLeftCourseMapHOC<'CourseMapDetails'>(
          CourseMapDetails
        )}
        options={{ title: 'Course Map Details' }}
      />
    </Stack.Navigator>
  );
};
