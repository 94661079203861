import React from 'react';
import { Platform } from 'react-native';
import { RenderMessageVideoProps } from 'react-native-gifted-chat';

import { colors } from '../../../constants/theme';
import { InlineVideoPlayer } from '../../InlineVideoPlayer';
import { GiftedMessageWithPoster } from '../types';

export const RenderVideo: React.FC<RenderMessageVideoProps<
  GiftedMessageWithPoster
>> = ({ currentMessage }) => {
  if (!currentMessage || !currentMessage.video || !currentMessage.posterImage) {
    return null;
  }

  return (
    <InlineVideoPlayer
      source={currentMessage.video}
      posterImage={currentMessage.posterImage}
      width={Platform.OS === 'web' ? 300 : 240}
      backgroundColor={colors.gray36}
    />
  );
};
