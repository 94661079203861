import React, { forwardRef } from 'react';

import { Box } from '../styled';
import { Soundslice } from './Soundslice';
import { ButtonRow } from '../ButtonRow';
import { ButtonProps } from '../Button';
import { SoundsliceNodeViewProps } from './types';
import { useIsPortrait } from '../../util';

export const SoundsliceNodeView = forwardRef<
  JSX.Element,
  SoundsliceNodeViewProps
>((props, ref) => {
  const { soundsliceUrl, primaryCta, secondaryCta } = props;

  const secondaryCtaButton = secondaryCta && {
    ...secondaryCta,
    type: 'Secondary',
  };
  const primaryCtaButton = primaryCta && { ...primaryCta, type: 'Primary' };
  const isPortrait = useIsPortrait();

  let soundSlice = (
    <Box flex={1}>
      <Soundslice ref={ref} url={soundsliceUrl} />
    </Box>
  );

  const buttons = [secondaryCtaButton, primaryCtaButton].filter(
    Boolean
  ) as ButtonProps[];

  const formattedButtons = <ButtonRow buttons={buttons} />;
  return (
    <Box height="100%" flex={1}>
      {isPortrait ? (
        <>
          {formattedButtons}
          {soundSlice}
        </>
      ) : (
        <>
          {soundSlice}
          {formattedButtons}
        </>
      )}
    </Box>
  );
});
