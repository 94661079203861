import React from 'react';

import { Text, Box } from '../styled';
import { InputField } from '../InputField';
import { Button } from '../Button';
import { fonts, colors } from '../../constants/theme';
import { AuthFormWrapper } from './AuthFormWrapper';
import { ResetPasswordFormProps } from './types';
import { initialState, formReducer } from './utils';

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  error,
  onSubmit,
}) => {
  const [showExternalError, setShowExternalError] = React.useState(true);
  const [{ values, errors }, dispatch] = React.useReducer(
    formReducer,
    initialState
  );

  const isFormValid = React.useMemo(
    () => values.newPassword && values.newPassword === values.confirmPassword,
    [values]
  );
  const hasExternalError = showExternalError && !!error;

  const handleNewPasswordTextChange = (value: string) => {
    dispatch({
      type: 'SET_VALUE',
      field: 'newPassword',
      payload: value,
    });

    if (!value) {
      dispatch({
        type: 'SET_ERROR',
        field: 'newPassword',
        payload: 'Password is required',
      });
    } else {
      dispatch({
        type: 'SET_ERROR',
        field: 'newPassword',
        payload: '',
      });
    }
    if (value !== values.confirmPassword) {
      dispatch({
        type: 'SET_ERROR',
        field: 'confirmPassword',
        payload: 'Password does not match',
      });
    } else {
      dispatch({
        type: 'SET_ERROR',
        field: 'confirmPassword',
        payload: '',
      });
    }

    setShowExternalError(false);
  };

  const handleConfirmPasswordTextChange = (value: string) => {
    dispatch({
      type: 'SET_VALUE',
      field: 'confirmPassword',
      payload: value,
    });

    if (value !== values.newPassword) {
      dispatch({
        type: 'SET_ERROR',
        field: 'confirmPassword',
        payload: 'Password does not match',
      });
    } else {
      dispatch({
        type: 'SET_ERROR',
        field: 'confirmPassword',
        payload: '',
      });
    }

    setShowExternalError(false);
  };

  return (
    <AuthFormWrapper>
      <Text font={fonts.bold} size={20} marginBottom={36}>
        Reset your password
      </Text>
      <Text font={fonts.thin} size={20} marginBottom={36} textAlign="center">
        Enter your new password for sign in
      </Text>
      <InputField
        label="New password"
        value={values.newPassword || ''}
        error={errors.newPassword}
        hasExternalError={hasExternalError}
        textContentType="emailAddress"
        autoCapitalize="none"
        onChangeText={handleNewPasswordTextChange}
        secureTextEntry={true}
      />
      <InputField
        label="Confirm new password"
        value={values.confirmPassword || ''}
        error={errors.confirmPassword}
        hasExternalError={hasExternalError}
        onChangeText={handleConfirmPasswordTextChange}
        secureTextEntry={true}
      />

      {hasExternalError ? (
        <Box paddingLeft={22} marginTop={-10}>
          <Text color={colors.alertRed} font={fonts.thin}>
            {error}
          </Text>
        </Box>
      ) : null}

      <Box marginBottom={36} marginTop={16}>
        <Button
          type="Primary"
          text="Save"
          onPress={() => {
            setShowExternalError(true);
            onSubmit({
              newPassword: values.newPassword,
            });
          }}
          disabled={!isFormValid}
        />
      </Box>
    </AuthFormWrapper>
  );
};
