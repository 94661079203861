import last from 'ramda/src/last';
import { getType } from 'mime';
import { handleError } from 'sonora-services/analytics';
import { UploadDataType } from '../graphql/types';
import { UploadFileOptions, uploadFileToS3 } from './s3upload';

/**
 * For mobile: Uploads to s3 bucket
 * Note: publish & complete happens separately.
 */
export function s3uploadVideo(
  localVideoUri: string,
  uploadData: UploadDataType,
  finalizeUpload: () => void,
  onProgress?: (e: ProgressEvent) => void
) {
  const uri = localVideoUri.split('.');
  const extension = last(uri);
  const mimeType = getType(extension || '');

  if (!uploadData || !uploadData.url || !mimeType) {
    throw new Error('tried to upload video to S3 without upload information');
  }

  const parsedFields = JSON.parse(uploadData.fields);
  const config: UploadFileOptions = {
    presignedUrl: uploadData.url,
    fields: {
      ...parsedFields,
      'Content-Type': mimeType,
    },
    onProgress,
    file: {
      uri: localVideoUri,
      name: `video.${extension}`,
      type: mimeType,
    },
  };

  return uploadFileToS3(config)
    .then(finalizeUpload)
    .catch((err) => handleError('uploadFileToS3 Error', { err, config }));
}
