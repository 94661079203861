import {
  LearningFolder,
  CurriculumProgress,
  NodeId,
  LearningActivity,
  Curriculum,
  DbModificationCategory,
  DbModification,
  CurriculumFlatDic,
  DatabaseCurriculumBody,
} from 'sonora-types';
import {
  ModificationCategory,
  Modification,
} from 'sonora-design-system/components/ModificationDetailView/types';
import { ActiveModificationsMap } from 'sonora-design-system/components/ModificationDetailView/types';
import { CourseMapMainFolderStudentInfo } from 'sonora-design-system/components/Coursemap/types';

import differenceInDays from 'date-fns/differenceInDays';
import { handleError, handleWarning } from 'sonora-services/analytics';
import { CurriculumFragment } from 'sonora-graphql/types';
import { mapCurric } from './testData';

export const isNodeCompleted = (progress: CurriculumProgress) => (
  activity: LearningActivity
) =>
  !!progress[activity.id] &&
  progress[activity.id].currentStars >= activity.maxStars;

export const allActivitiesInFolder = (folder: LearningFolder) => {
  const flatList: LearningActivity[] = [];
  const addChildren = (folder: LearningFolder) => {
    folder.children.forEach((child) => {
      if (child.type === 'Activity') flatList.push(child);
      if (child.type === 'Folder') addChildren(child);
    });
  };
  addChildren(folder);
  return flatList;
};

export const allCurriculumActivities = (curriculum: Curriculum) => {
  let allActivities: LearningActivity[] = [];
  curriculum.contents.forEach((folder: LearningFolder) => {
    allActivities = [...allActivities, ...allActivitiesInFolder(folder)];
  });
  return allActivities;
};

// The data layer will provide studentProgress and allUnlockedNodes
export const courseMapFolderProps = (
  folder: LearningFolder,
  studentProgress: CurriculumProgress,
  allUnlockedNodes: NodeId[]
): CourseMapMainFolderStudentInfo => {
  const allActivities = allActivitiesInFolder(folder);
  const totalActivityCount = allActivities.length;
  const completedActivityCount = allActivities.filter(
    isNodeCompleted(studentProgress)
  ).length;
  return {
    numCompletedLessons: completedActivityCount,
    numAllLessons: totalActivityCount,
    completePercent: Math.round(
      (100 * completedActivityCount) / totalActivityCount
    ),
  };
};

type ParseDbCategoriesFn = (
  categories: DbModificationCategory[]
) => ModificationCategory[];

export const parseDbCategories: ParseDbCategoriesFn = (
  categories: DbModificationCategory[]
) => {
  return categories.map((category) => ({
    title: category.name,
    id: category.id,
    modifications: category.modificationIds,
    description: category.description,
    disabled: false,
    vimeoId: category.vimeoId,
  }));
};

type ParseDbModificationsFn = (
  modifications: DbModification[]
) => Modification[];

export const parseDbModifications: ParseDbModificationsFn = (
  modifications: DbModification[]
) => {
  return modifications.map((modification) => ({
    id: modification.id,
    vimeoId: modification.vimeoId,
    title: modification.title,
    description: modification.description,
    isLocked: false,
    isDefault: false,
  }));
};

export const getCategoryFromId = (
  categoryId: number,
  curriculum: Curriculum
) => {
  return curriculum.modificationCategories.find(
    (category) => category.id === categoryId
  );
};

export const getModificationFromId = (
  modificationId: number,
  curriculum: Curriculum
) => {
  return curriculum.modifications.find(
    (modification) => modification.id === modificationId
  );
};

export const modificationsToMessage = (
  modifications: ActiveModificationsMap,
  curriculum: Curriculum
) => {
  let messageArr = Object.keys(modifications).map((categoryId) => {
    const category = getCategoryFromId(Number(categoryId), curriculum);
    const categoryName = category ? category.name : '';

    const modification = getModificationFromId(
      Number(modifications[Number(categoryId)]),
      curriculum
    );
    const modificationTitle = modification ? modification.title : '';

    return `${categoryName}: ${modificationTitle}`;
  });

  return `Modifications:\n${messageArr.join('\n')}`;
};

export type ReviewItem = {
  activityId: NodeId;
  priority: number;
  currentStars: number;
  lastPracticed: Date;
};

export function priorityForStar(
  starRating: number,
  daysSincePracticed: number
): number {
  switch (starRating) {
    case 0:
      break;
    case 1:
      if (daysSincePracticed > 1) return 1.0;
      if (daysSincePracticed < 1) return 0;
      else return 0.5;

    case 2: //2-3 days
      if (daysSincePracticed < 2) return 0;
      if (daysSincePracticed > 3) return 1;
      return (daysSincePracticed - 1.5) / (4 - 2);

    case 3: //3-5days
      if (daysSincePracticed < 3) return 0;
      if (daysSincePracticed > 5) return 1;
      return (daysSincePracticed - 2.5) / (6.2 - 3);

    case 4: //5-10days
      if (daysSincePracticed < 5) return 0;
      if (daysSincePracticed > 10) return 1;
      return (daysSincePracticed - 4.5) / (10.5 - 5);

    case 5: //10-20 days
      if (daysSincePracticed < 10) return 0;
      if (daysSincePracticed > 20) return 1;
      return (daysSincePracticed - 9.5) / (20.5 - 10);

    case 6: //15-30 days -- after rating 5 again :)
      if (daysSincePracticed < 15) return 0;
      if (daysSincePracticed > 30) return 1;
      return (daysSincePracticed - 9.5) / (30.5 - 15);

    case 7: //20-40 days -- after rating 5 again :)
      if (daysSincePracticed < 20) return 0;
      if (daysSincePracticed > 40) return 1;
      return (daysSincePracticed - 19.5) / (40.5 - 20);

    case 8: //30-80 days -- after rating 5 again :)
      if (daysSincePracticed < 30) return 0;
      if (daysSincePracticed > 80) return 1;
      return (daysSincePracticed - 29.5) / (80.5 - 30);
  }
  return 0;
}

type GenerateReviewItemArgs = {
  curriculumMap: CurriculumFlatDic;
  progress: CurriculumProgress;
  todayDate: Date;
};
export const generateReviewItems = ({
  curriculumMap,
  progress,
  todayDate,
}: GenerateReviewItemArgs) => {
  const reviewItems: ReviewItem[] = [];
  Object.entries(progress).forEach((entry) => {
    const [activityId, { currentStars, lastPracticed }] = entry;

    if (!curriculumMap[activityId]) {
      handleWarning('got progress for item id not in curriculum');
      return;
    }

    const node = curriculumMap[activityId];
    if (node.type === 'Folder') {
      return handleError('got progress for id which is a folder', {
        node,
        currentStars,
        lastPracticed,
      });
    }

    /** Only review items which aren't practice */
    if (node.assignmentType === 'practice') {
      const daysSincePracticed = differenceInDays(todayDate, lastPracticed);
      const reviewItem: ReviewItem = {
        activityId,
        lastPracticed,
        currentStars,
        priority: priorityForStar(currentStars, daysSincePracticed),
      };
      if (reviewItem.priority > 0) reviewItems.push(reviewItem);
    }
  });
  return reviewItems.sort((a, b) => a.priority - b.priority);
};

type ParsedCurriculum = {
  curriculum: Curriculum;
  curriculumMap: CurriculumFlatDic;
};

export const dbEnrollmentToCurriculum = (
  dbCurric: CurriculumFragment
): ParsedCurriculum => {
  const curricContents = dbCurric.body as DatabaseCurriculumBody;

  if (
    !curricContents ||
    !curricContents.curriculum ||
    !(curricContents.curriculum.length > 0)
  )
    throw new Error('malformed curriculum. Are you sure this user is on v2?');

  const curriculum: Curriculum = {
    id: Number(dbCurric.id),
    name: dbCurric.title,
    contents: curricContents.curriculum,
    description: 'Description TBD or unused?',
    modificationCategories: curricContents.modifications.categories,
    modifications: curricContents.modifications.modifications,
  };

  const curriculumMap = mapCurric(curriculum);

  return { curriculum, curriculumMap };
};
