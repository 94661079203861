// author: @fullstackdevs14
import React from 'react';
import { Platform, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import * as R from 'ramda';

import { FillIcon, StrokeIcon } from '../Icon';
import { Row, Box, Text } from '../styled';
import { GradientBorder } from '../GradientBorder';
import { BottomBorder } from '../Border';
import { RatingStar } from '../RatingStar';
import { theme, colors } from '../../constants/theme';
import {
  TaskListItemProps,
  TaskListItemWrapperProps,
  TaskListItemHeaderProps,
} from './types';

const TaskListHeaderPill = styled.View`
  flex-direction: row;
  align-items: center;
  height: 32px;
  padding: 4px 12px;
  border-radius: 16px;
  background-color: ${(props: { isLocked?: boolean }) =>
    props.isLocked ? colors.agateHover : colors.agate};
`;

const TaskListItemHeader: React.FC<TaskListItemHeaderProps> = ({
  headerType,
  lastPracticedString,
  isLocked,
  onSkip,
}) => {
  const isReview = headerType === 'Review';

  return (
    <Row justifyContent="space-between" marginTop={18} marginX={12}>
      <TaskListHeaderPill isLocked={isLocked}>
        {isReview ? <FillIcon name="review" /> : null}
        <Text size={12}>{isReview ? 'SKILL REVIEW' : 'OPTIONAL'}</Text>
      </TaskListHeaderPill>
      {isReview ? (
        <Row position="relative" paddingBottom={2}>
          <Text size={12}>Last: </Text>
          <Text size={12}>{lastPracticedString}</Text>
          <BottomBorder />
        </Row>
      ) : !isLocked ? (
        <TouchableOpacity onPress={onSkip}>
          <Text>SKIP</Text>
        </TouchableOpacity>
      ) : null}
    </Row>
  );
};

const TaskListItemWrapperNoGradient = styled.View`
  border-radius: ${theme.common.borderRadius};
  ${(props: TaskListItemWrapperProps) =>
    Platform.OS === 'web'
      ? props.isClickable
        ? 'cursor: pointer;'
        : 'cursor: not-allowed;'
      : ''}
  ${(props: TaskListItemWrapperProps) =>
    props.hasBorder && !props.isActive
      ? `
      border-bottom-width: 1px;
      border-bottom-color: ${colors.borderline};
      `
      : ''}
  ${(props: TaskListItemWrapperProps) =>
    props.isActive
      ? `
    background-color: ${colors.white};
    margin-bottom: 3px;
  `
      : `
    opacity: 0.6;
  `}
`;

const TaskListItemWrapper: React.FC<TaskListItemWrapperProps> = ({
  isSelected,
  children,
  ...otherProps
}) =>
  isSelected ? (
    <Box marginBottom={3}>
      <GradientBorder
        borderWidth={1}
        backgroundColor={colors.white}
        outerBorderRadius={5}
        innerBorderRadius={4}
        {...theme.common.horizontalGradient}
      >
        {children}
      </GradientBorder>
    </Box>
  ) : (
    <TaskListItemWrapperNoGradient {...otherProps}>
      {children}
    </TaskListItemWrapperNoGradient>
  );

const ItemText = styled.Text`
  flex: 1;
  font-family: ${theme.taskList.item.font};
  font-size: ${theme.taskList.item.textSize};
  margin-left: 10px;
  margin-right: 10px;
  color: ${colors.tanzanite};
`;

const CountText = styled.Text`
  font-family: ${theme.taskList.item.font};
  font-size: ${theme.taskList.item.countSize};
  color: ${colors.tanzanite};
  margin-right: 4px;
`;

const TaskListItem = ({
  item,
  hasBorder = true,
  isSelected,
  isLocked,
  isCompleted,
  onSelect,
  onSkip,
}: TaskListItemProps) => {
  const countText = `${item.currStars >= 5 ? 5 : item.currStars}/${item.maxStars}`;
  const icon = R.cond([
    [R.equals('watch'), R.always('video')],
    [R.equals('practice'), R.always('soundslice')],
    [R.T, R.always('prompt')],
  ])(item.taskType as never);
  const starIcon = isLocked ? (
    <FillIcon name="lock" size="s" />
  ) : item.maxStars === 1 ? (
    <StrokeIcon
      name="check"
      color={item.currStars === 0 ? colors.dividerGray : colors.validGreen}
    />
  ) : (
    <RatingStar
      stars={item.currStars}
      isComplete={item.currStars === item.maxStars}
    />
  );

  const isActive = !isLocked && !isCompleted;
  const hasHeader =
    (!item.headerType || item.headerType !== 'None') && !isCompleted;

  const handlePress = () => {
    if (!isLocked && onSelect) {
      onSelect();
    }
  };

  return (
    <TouchableOpacity
      activeOpacity={1}
      disabled={isLocked}
      onPress={handlePress}
    >
      <TaskListItemWrapper
        hasBorder={hasBorder}
        isActive={isActive}
        isClickable={!isLocked}
        isSelected={isSelected}
      >
        {hasHeader ? (
          <TaskListItemHeader
            headerType={item.headerType}
            lastPracticedString={item.lastPracticedString}
            isLocked={isLocked}
            onSkip={onSkip}
          />
        ) : null}
        <Row paddingX={12} paddingY={hasHeader ? 15 : 18}>
          <FillIcon name={icon} size="s" />
          <ItemText>{item.title}</ItemText>
          <Box>
            <Row>
              {!isLocked && item.maxStars > 1 ? (
                <CountText>{countText}</CountText>
              ) : null}
              {starIcon}
            </Row>
          </Box>
        </Row>
      </TaskListItemWrapper>
    </TouchableOpacity>
  );
};

export default TaskListItem;
