import React from 'react';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

import { fonts, colors } from '../../constants/theme';
import { Box, Text } from '../styled';
import { FillIcon } from '../Icon';
import { ModificationCategory } from '../ModificationDetailView/types';

interface CategoryItemWrapperProps {
  disabled?: boolean;
}
const CategoryItemWrapper: React.FC<CategoryItemWrapperProps> = styled.View`
  flex-direction: row;
  align-items: center;
  padding: 16px 12px;
  ${(props: CategoryItemWrapperProps) =>
    props.disabled ? 'opacity: 0.6;' : ''}
`;

const CategoryTitle = styled.Text`
  font-family: ${fonts.headline};
  font-size: 16px;
  color: ${colors.tanzanite};
  margin-bottom: 12px;
`;

const Border = styled.View`
  position: absolute;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${colors.borderline};
`;

interface CategoryItemProps {
  category: ModificationCategory;
  hasBorder?: boolean;
  onPress?: () => void;
}

export const CategoryItem: React.FC<CategoryItemProps> = ({
  category,
  hasBorder,
  onPress,
}) => {
  const description =
    category.description.length > 88
      ? `${category.description.substr(0, 88)}...`
      : category.description;

  return (
    <TouchableOpacity onPress={onPress} disabled={category.disabled}>
      <CategoryItemWrapper disabled={category.disabled}>
        <Box flex={1} marginRight={12}>
          <CategoryTitle>{category.title}</CategoryTitle>
          <Text font={fonts.thin}>{description}</Text>
        </Box>
        {category.disabled ? (
          <FillIcon name="lock" />
        ) : (
          <FillIcon name="pageRight" />
        )}
      </CategoryItemWrapper>
      {hasBorder ? <Border /> : null}
    </TouchableOpacity>
  );
};
