import React from 'react';
import { storiesOf } from '@storybook/react-native';

import { Box } from '../styled';
import { LayoutProvider } from '../../util/LayoutProvider';
import { colors } from '../../constants/theme';
import { StarRating } from '.';

import PlaceholderImage from '../../assets/images/placeholder.png';

const starRatingStories = storiesOf('StarRating', module);

const StarRatingStory = () => (
  <LayoutProvider>
    <Box paddingX={24} paddingY={48} backgroundColor={colors.agate}>
      <StarRating
        title="Nice work, Micah!"
        description="Heres a long description of what you should do"
        image={PlaceholderImage}
        onSubmit={console.log}
        previousStarRating={1}
      />
    </Box>
  </LayoutProvider>
);

starRatingStories.add('default', () => <StarRatingStory />);
