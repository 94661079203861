import React, { useContext } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { colors, PrimaryButton } from 'sonora-design-system';

import { ModalPresenterContext } from 'sonora-design-system';

import { RootNavScreen } from '../screens';

export const VideoSubmissionDone: RootNavScreen<'VideoSubmissionDone'> = ({
  navigation,
}) => {
  const { extraData, dismissModal } = useContext(ModalPresenterContext);
  const handleNext = () => {
    if (extraData.isLogProgressFlow) {
      navigation.navigate('VideoSubmissionRating');
    } else {
      dismissModal('Success');
    }
  };

  return (
    <View style={styles.screen}>
      <View style={styles.screenInner}>
        <Text style={styles.headline}>Upload Complete</Text>
        <Text style={styles.text}>
          Your practice post will appear in the chat portion of the app.
        </Text>
        <PrimaryButton text="Go to Next" onPress={handleNext} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 24,
  },
  screenInner: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  headline: {
    fontFamily: 'Tiempos-Headline-Semibold',
    fontSize: 32,
    lineHeight: 36,
    color: colors.tanzanite,
    textAlign: 'center',
  },
  text: {
    marginTop: 36,
    marginBottom: 24,
    fontFamily: 'Sohne-Mager',
    color: colors.tanzanite,
    fontSize: 20,
    lineHeight: 26,
    textAlign: 'center',
  },
});
