import React, { useContext, useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native';
import {
  TouchableOpacity,
  View,
  Text as RNText,
  StyleSheet,
  Linking,
} from 'react-native';

import { RootNavScreen } from '../screens';
import { ThreadCarousel } from './ThreadCarousel';
import {
  UserContext,
  ChatContext,
  StudentContext,
  MentorContext,
  MentorStudentContext,
} from 'sonora-containers/index';
import { ChatThreadWrapper } from 'sonora-components/Chat/ChatThreadWrapper';
import {
  Box,
  Text,
  colors,
  fonts,
  FillIcon,
  MentorshipExpired,
} from 'sonora-design-system';
import { Loading } from 'sonora-design-system';
import { useTrackEventOnMount } from 'sonora-hooks/useTrackEventOnMount';

export interface MainChatThreadProps {}

export const MainChatThread: RootNavScreen<'MainChatThread'> = ({
  navigation,
}) => {
  const isFocused = useIsFocused();
  useTrackEventOnMount('mount-main-chat-thread');
  const { userType, payment } = useContext(UserContext);
  const { relationshipId } = useContext(MentorStudentContext);
  const { relationshipStatus } = useContext(StudentContext);

  const relationshipExpired = relationshipStatus != "ActiveMentor" 

  const hasFellowshipAccess = (!payment && !relationshipExpired) || (payment && !payment.hasFellowshipAccess && !relationshipExpired) || payment?.hasFellowshipAccess || payment?.isInTrial;
  
  let name, headline;

  if (userType === 'mentor') {
    if (!relationshipId) {
      throw new Error('expecting valid student relationship id');
    }
    const mentorContext = useContext(MentorContext);
    let activeRelationship = mentorContext.activeRelationships.find(
      (r) => r.id === relationshipId
    );

    if (!activeRelationship) {
      throw new Error('expecting valid student relationship at this point');
    }

    name = activeRelationship.student.fullName;
    headline = 'Student Chat';
  } else {
    const studentContext = useContext(StudentContext);
    if (!studentContext.mentorRelationship)
      throw new Error('expecting valid mentor relationship at this point');

    name = studentContext.mentorRelationship.mentorName;
    headline = 'Mentor';
  }
  const {
    mainThreadId,
    setCurrentThreadId,
    chatUser,
    isRelationshipActive,
    messageThread,
    onSend,
    videoCarousel,
    loading,
  } = useContext(ChatContext);

  useEffect(() => {
    if (isFocused) {
      setCurrentThreadId(mainThreadId);
    }
  }, [isFocused]);

  if (loading) return <Loading />;

  if (!isFocused) return null;

  const exitButton = (
    <View style={styles.exitButtonContainer}>
      <TouchableOpacity style={styles.row} onPress={() => navigation.goBack()}>
        <View style={styles.icon}>
          <FillIcon name="x" color={colors.tanzanite} />
        </View>
        <RNText style={styles.exitLabel}>Exit</RNText>
      </TouchableOpacity>
    </View>
  );

  return (
    <Box flex={1} backgroundColor={colors.white} position="relative">
      <Box alignItems="center" paddingY={20}>
        {userType === 'mentor' ? exitButton : undefined}
        <Text font={fonts.headline} size={26}>
          {headline}
        </Text>
        <Text size={16}>{name}</Text>
      </Box>
      <ThreadCarousel
        carouselData={videoCarousel}
        isAddPostEnabled={ (hasFellowshipAccess && userType != 'mentor' ) || false }
      />
      <ChatThreadWrapper
        chatUser={chatUser}
        messageThread={messageThread}
        onSend={onSend}
        isMessagingDisabled={!isRelationshipActive && !hasFellowshipAccess && userType != 'mentor' }
        threadId={mainThreadId}
      />
      {(userType != 'mentor' && !hasFellowshipAccess) && (
        <Box position="absolute" bottom="0px" left="0px" width="100%">
          <MentorshipExpired
            onSchedule={() =>
              Linking.openURL(
                'https://calendly.com/guitar-mastery-intensive/skill-assessment'
              )
            }
          />
        </Box>
      )}
    </Box>
  );
};

const styles = StyleSheet.create({
  exitButtonContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    marginLeft: 12,
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: 5,
  },
  exitLabel: {
    color: colors.tanzanite,
    textTransform: 'uppercase',
  },
});
