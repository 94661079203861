import React, { useEffect } from 'react';
import { View, Text } from 'react-native';
import { AuthFormWrapper } from 'sonora-design-system';
import { RootNavScreen } from '../screens';
import { useLoginWithToken } from 'sonora-graphql/signInHooks';
import { handleError } from 'sonora-services/analytics';

export interface TokenLoginProps {}

export const TokenLogin: RootNavScreen<'TokenLogin'> = ({
  route,
  navigation,
}) => {
  const loginWithToken = useLoginWithToken();

  useEffect(() => {
    loginWithToken(route.params.token)
      .then(() => navigation.navigate('LoggedIn'))
      .catch((error) =>
        handleError('Login with Token Error', {
          error,
          token: route.params.token,
        })
      );
  }, []);

  return (
    <AuthFormWrapper>
      <View>
        <Text>Logging in with Token</Text>
        <Text>{route.params.token}</Text>
      </View>
    </AuthFormWrapper>
  );
};
