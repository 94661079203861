import React, { FC, useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { colors } from 'sonora-design-system';
import { L2Screens } from '../screens/L2Screens';
import L2TabMenuItem from './L2TabMenuItem';
import { L2NavigationContext } from './L2NavigationProvider';
import { ModificationsProviderContext } from '../screens/Modifications/ModificationsProvider';

export interface L2PortraitTabsProps {}

export const bottomTabsHeight = 54;

const L2PortraitTabs: FC<L2PortraitTabsProps> = (props) => {
  const insets = useSafeAreaInsets();
  const { activeIndex, setActiveIndex } = useContext(L2NavigationContext);

  const { modificationsDrawerEnabled } = useContext(
    ModificationsProviderContext
  );

  const percentage = `${100 / L2Screens.length}%`;
  return (
    <View
      style={[
        styles.tabs,
        {
          height: insets.bottom + bottomTabsHeight,
          paddingBottom: insets.bottom,
        },
      ]}
    >
      {L2Screens.map((screen, index) => {
        const disabled =
          screen.name === 'Modifications' && !modificationsDrawerEnabled;

        return (
          <View
            key={`l2tab-${index}`}
            style={[styles.menuItemContainer, { width: percentage }]}
          >
            <L2TabMenuItem
              disabled={disabled}
              iconName={screen.iconName}
              label={screen.label}
              focused={activeIndex === index}
              onPress={() =>
                setActiveIndex(index === activeIndex ? undefined : index)
              }
            />
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  tabs: {
    flexDirection: 'row',
    backgroundColor: colors.opal,
  },
  menuItemContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});
export default L2PortraitTabs;
