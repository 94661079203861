import React from 'react';
import { storiesOf } from '@storybook/react-native';
import { GiftedMessage, GiftedUser } from './types';
import { ChatThread } from './ChatThread';
import { mediumChat, mediumUser, largeChat, largeUser } from './mock-data';

const chatStories = storiesOf('Chat', module);

const startVideoStub = () => {
  console.log('START NEW VIDEO');
};

const LiveChatStory = () => {
  const [messages, setMessages] = React.useState<GiftedMessage[]>(mediumChat);
  const user: GiftedUser = { _id: 500, name: 'v1.11 medium user test' };
  const onSend = (messages: GiftedMessage[]) => {
    setMessages((m) => [...m, ...messages]);
  };
  return (
    <ChatThread
      currentUser={user}
      messages={messages}
      onSend={onSend}
      startNewVideo={startVideoStub}
    />
  );
};

const MediumChatStory = () => (
  <ChatThread
    currentUser={mediumUser}
    messages={mediumChat}
    onSend={(_) => void 0}
    isMessagingDisabled={true}
    startNewVideo={startVideoStub}
  />
);

const BigChatStory = () => (
  <ChatThread
    currentUser={largeUser}
    messages={largeChat}
    onSend={(_) => void 0}
    isMessagingDisabled={true}
    startNewVideo={startVideoStub}
  />
);

chatStories.add('Live Chat', () => <LiveChatStory />);
chatStories.add('Medium', () => <MediumChatStory />);
chatStories.add('Big', () => <BigChatStory />);
