import React, { useState, useEffect, useRef, FC } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Camera } from 'expo-camera';
import * as Permissions from 'expo-permissions';

import RecordButton from '../../../components/camera/RecordButton';

export interface VideoRecordProps {
  setRecordedUri: (uri: string) => void;
}

const VideoRecord: FC<VideoRecordProps> = (props) => {
  const [hasPermission, setHasPermission] = useState<boolean | undefined>();
  const [isRecording, setIsRecording] = useState<boolean>(false);

  const camera = useRef<Camera>(null);

  const recordButtonSize = 64;

  const handleStartRecord = async () => {
    if (!camera || !camera.current) return;
    setIsRecording(true);

    let video = await camera.current.recordAsync();
    props.setRecordedUri(video.uri);
  };

  const handleFinishRecord = () => {
    if (!camera || !camera.current) return;
    setIsRecording(false);
    camera.current.stopRecording();
  };

  const handleRecordPress = () => {
    isRecording ? handleFinishRecord() : handleStartRecord();
  };

  useEffect(() => {
    (async () => {
      const { status } = await Permissions.askAsync(
        Permissions.CAMERA,
        Permissions.AUDIO_RECORDING
      );
      setHasPermission(status === 'granted');
    })();
  }, []);

  if (hasPermission === undefined) {
    return <View />;
  }
  if (hasPermission === false) {
    return <Text>No access to camera</Text>;
  }
  return (
    <View style={{ flex: 1 }}>
      <View style={styles.recordButtonContainer}>
        <RecordButton
          onPress={handleRecordPress}
          isActive={isRecording}
          size={recordButtonSize}
        />
      </View>
      <View style={styles.cameraContainer}>
        <Camera
          ref={camera}
          style={{ flex: 1 }}
          type={Camera.Constants.Type.front}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  cameraContainer: { flex: 1, paddingHorizontal: 100 },
  recordButtonContainer: {
    padding: 10,
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

export default VideoRecord;
