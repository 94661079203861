import React, { FC } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { DrawerItem } from '@react-navigation/drawer';
import { StrokeIcon, FillIcon, colors } from 'sonora-design-system';

export interface L1SidebarMenuItemProps {
  iconName: string;
  isStrokeIcon?: boolean;
  hasBadge?: boolean;
  afterIconName?: string;
  isAfterIconFilled?: boolean;
  label: string;
  handlePress: () => void;
  focused?: boolean;
}

const L1SidebarMenuItem: FC<L1SidebarMenuItemProps> = (props) => {
  const { afterIconName, isAfterIconFilled } = props;
  const [labelWidth, setLabelWidth] = React.useState(0);

  return (
    <View
      onLayout={(ev) => {
        const { width } = ev.nativeEvent.layout;
        setLabelWidth(width - 36);
      }}
    >
      <DrawerItem
        focused={Boolean(props.focused)}
        activeTintColor={colors.amethyst}
        inactiveTintColor={colors.tanzanite}
        activeBackgroundColor={colors.opal}
        inactiveBackgroundColor={colors.opal}
        label={({ focused, color }) => (
          <View style={[styles.row, { width: labelWidth }]}>
            <View>
              {props.hasBadge ? <View style={styles.badge} /> : undefined}
              {props.isStrokeIcon ? (
                <StrokeIcon name={props.iconName} color={color} />
              ) : (
                <FillIcon name={props.iconName} color={color} />
              )}
            </View>
            <Text style={[styles.label, { color }]}>{props.label}</Text>
            <View style={styles.after}>
              {afterIconName ? (
                isAfterIconFilled ? (
                  <FillIcon name={afterIconName} color={color} />
                ) : (
                  <StrokeIcon name={afterIconName} color={color} />
                )
              ) : undefined}
            </View>
          </View>
        )}
        onPress={() => props.handlePress()}
      />
    </View>
  );
};

export default L1SidebarMenuItem;

const styles = StyleSheet.create({
  icon: {
    marginRight: 10,
  },
  badge: {
    position: 'absolute',
    top: 2,
    right: 2,
    backgroundColor: colors.validGreen,
    height: 8,
    width: 8,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.white,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  menuItem: {
    flexDirection: 'row',
    paddingVertical: 16,
    paddingLeft: 24,
    alignItems: 'center',
  },
  label: {
    fontSize: 14,
    lineHeight: 16,
    marginLeft: 10,
  },
  // TODO (andrew): Uncomment when reimplementing arrows
  after: {
    position: 'absolute',
    right: 0,
  },
});
