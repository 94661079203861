import React, { FC } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { FillIcon, colors } from 'sonora-design-system';

export interface MetronomePortraitButtonProps {
  setDroneDrawerOpen: (isOpen: boolean) => void;
}

const MetronomePortraitButton: FC<MetronomePortraitButtonProps> = (props) => {
  return (
    <View style={styles.metronomePortraitButton}>
      <TouchableOpacity onPress={() => props.setDroneDrawerOpen(true)}>
        <FillIcon name="metronome" color={colors.white} size="metronome" />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  metronomePortraitButton: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 54,
    width: 54,
    borderRadius: 54 / 2,
    backgroundColor: colors.amethyst,
  },
});

export default MetronomePortraitButton;
