import React, { useContext } from 'react';
import { RootNavScreen } from '../screens';
import { createStackNavigator } from '@react-navigation/stack';
import { MainChatThread } from '../MentorChat/MainChatThread';
import { PracticePostThread } from '../MentorChat/PracticePostThread';
import { MentorStudentProvider } from 'sonora-containers/index';

import {
  MentorContext,
  ChatProvider,
  ChatProviderProps,
  UserContext,
} from 'sonora-containers/index';

const Stack = createStackNavigator();

// Chat that mentor uses to talk to a student
export const StudentChat: RootNavScreen<'StudentChat'> = ({
  route,
  navigation,
}) => {
  const { user } = useContext(UserContext);
  const { activeRelationships } = useContext(MentorContext);
  let mentorRelationship = activeRelationships.find(
    (r) => r.id === parseInt(route.params.studentId, 10)
  );
  if (!mentorRelationship)
    throw new Error(`mentored student should have mentor relationship!`);
  const chatProviderProps: ChatProviderProps = {
    relationshipId: mentorRelationship.id,
    mainThreadId: mentorRelationship.mainThreadId,
    isRelationshipActive: true,
    userRole: 'mentor',
    chatUser: {
      fullName: user.fullName,
      id: Number(user.id),
    },
  };

  return (
    <ChatProvider {...chatProviderProps}>
      <MentorStudentProvider relationshipId={mentorRelationship.id}>
        <Stack.Navigator initialRouteName="MainChatThread">
          <Stack.Screen
            name="MainChatThread"
            component={MainChatThread}
            options={{ title: 'All Chats' }}
          />
          <Stack.Screen
            name="PracticePostThread"
            component={PracticePostThread}
            options={{ title: 'Practice Chat' }}
          />
        </Stack.Navigator>
      </MentorStudentProvider>
    </ChatProvider>
  );
};
