import React, { FC } from 'react';

import { SoundsliceNodeView, VideoNodeView } from 'sonora-design-system';
import { LearningActivity } from 'sonora-types';
import { sanitizePageLink } from 'sonora-util/strings';
import { L3SupplementaryMaterials } from './L3/L3SupplementaryMaterials';
import { L3ActivityDetails } from './L3/L3ActivityDetails';
import { VideoScreenTab } from 'sonora-design-system/components/NodeView/types';

export interface MentorL2ActivityViewProps {
  activity: LearningActivity;
}

export const MentorL2ActivityView: FC<MentorL2ActivityViewProps> = ({
  activity,
}) => {
  const videoNodeTabs: VideoScreenTab[] =
    activity.assignmentType !== 'practice'
      ? [
          {
            pageTitle: 'Details',
            tabKey: 'details',
            tabLabel: 'Details',
            component: () => <L3ActivityDetails activity={activity} />,
          },
        ]
      : [];
  if (activity.supplementary && activity.supplementary.pageLink) {
    videoNodeTabs.push({
      tabKey: 'supplementary',
      pageTitle: 'SupplementaryMaterials',
      tabLabel: 'Supplementary Materials',
      component: () => (
        <L3SupplementaryMaterials
          url={sanitizePageLink(activity.supplementary!.pageLink)}
        />
      ),
    });
  }

  return activity.media.type === 'soundslice' ? (
    <SoundsliceNodeView
      title={activity.name}
      soundsliceUrl={activity.media.embedString}
      primaryCta={undefined}
      secondaryCta={undefined}
    />
  ) : (
    <VideoNodeView
      title={activity.name}
      vimeoVideoId={activity.media.embedString}
      tabs={videoNodeTabs}
      primaryCta={undefined}
      secondaryCta={undefined}
    />
  );
};
