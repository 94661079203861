import React, { useContext } from 'react';
import { UserContext } from 'sonora-containers/UserProvider';
import {
  Box,
  colors,
  PremiumSubscriptionCard,
  MembershipCard,
  MembershipCardTrial,
  PaymentMethod,
  Text,
  ModalPresenterContext,
} from 'sonora-design-system';
import { Alert } from 'sonora-design-system/components/Alert';
import { distanceFromToday, shortYearDatestring } from 'sonora-util/dateUtils';
import { handleMock } from 'sonora-util/helpers';
import {
  useCancelStripeSubscriptionNowMutation,
  useCancelStripeSubscriptionAtPeriodEndMutation,
  useExtendTrialOnStripeSubscriptionScheduleMutation,
  usePauseStripeSubscriptionMutation,
} from 'sonora-graphql/types';
import { PaymentCancelModal } from './PaymentCancelModal';
import { PaymentEditModal, PaymentEditModalData } from './PaymentEditModal';
import { PremiumCancelModal } from './PremiumCancelModal';
import { PremiumPauseModal } from './PremiumPauseModal';
import { BillingDetailsType, BillingOverviewProps } from './types';

export const BillingOverview: React.FC<BillingOverviewProps> = ({
  navigation,
}) => {
  const { presentComponentAsModal } = useContext(ModalPresenterContext);
  const { payment } = useContext(UserContext);
  const today = new Date()
  const eightDays = 8 * 24 * 60 * 60 * 1000
  let showExtend = false
  if (payment) {
    showExtend = today.getTime() - payment?.stripeCreateDate.getTime() < eightDays
  }
  const [
    cancelStripeSubscriptionNowMutation,
  ] = useCancelStripeSubscriptionNowMutation();
  const [
    cancelStripeSubscriptionAtPeriodEndMutation,
  ] = useCancelStripeSubscriptionAtPeriodEndMutation();
  const [
    extendTrialOnStripeSubscriptionScheduleMutation,
  ] = useExtendTrialOnStripeSubscriptionScheduleMutation();
  const [
    pauseStripeSubscriptionMutation,
  ] = usePauseStripeSubscriptionMutation();

  const handleEditTrial = () => {
    presentComponentAsModal(PaymentEditModal, {
      width: 572,
      height: 611,
      onDismiss: (success, data) => {
        if (success === 'Success') {
          const typedData = data as PaymentEditModalData;
          console.log('Plan updated: ', typedData.selectedPlan);
        }
      },
    });
  };

  const handleCancelTrial = (
    subscriptionId: string,
    subscriptionScheduleId: string,
    priceId: string,
    isInstallments: boolean,
  ) => {
    presentComponentAsModal(PaymentCancelModal, {
      width: 499,
      height: 433,
      extraData: { showExtend },
      onDismiss: (success, action) => {
        if (success === 'Success') {
          if (action === 'cancel') {
            cancelStripeSubscriptionNowMutation({
              variables: { subscriptionId },
              refetchQueries: ['CurrentUser'],
            }).then(({ data }) => {
              if (data?.cancelStripeSubscriptionNow?.ok) {
                console.log('Premium Subscription Cancelled');
              }
            });
          }
        }
        if (action === 'extend') {
          extendTrialOnStripeSubscriptionScheduleMutation({
            variables: {
              subscriptionScheduleId,
              additionalTrialDays: 7,
              priceId: priceId,
              iterationCount: isInstallments ? 5 : 1
            },
            refetchQueries: ['CurrentUser'],
          }).then(({ data }) => {
            if (data?.extendTrialOnStripeSubscriptionSchedule?.ok) {
              console.log('Trial Extended');
            }
          });
        }
      },
    });
  };

  const handleCancelPremium = (subscriptionId: string) => {
    presentComponentAsModal(PremiumCancelModal, {
      width: 566,
      height: 551,
      onDismiss: (success, action) => {
        if (success === 'Success') {
          if (action === 'cancel') {
            cancelStripeSubscriptionAtPeriodEndMutation({
              variables: { subscriptionId },
              refetchQueries: ['CurrentUser'],
            }).then(({ data }) => {
              if (data?.cancelStripeSubscriptionAtPeriodEnd?.ok) {
                console.log('Premium Subscription Cancelled');
              }
            });
          }
        }
      },
    });
  };

  const handlePausePremium = (subscriptionId: string) => {
    presentComponentAsModal(PremiumPauseModal, {
      width: 566,
      height: 551,
      onDismiss: (success, shouldPause) => {
        if (success === 'Success' && shouldPause) {
          pauseStripeSubscriptionMutation({
            variables: { subscriptionId },
            refetchQueries: ['CurrentUser'],
          }).then(({ data }) => {
            if (data?.pauseStripeSubscription?.ok) {
              console.log('Premium Subscription Paused');
            }
          });
        }
      },
    });
  };

  const handleUnpausePremium = () => {
    // setPremiumSubscription((premiumSubscription) => ({
    //   ...premiumSubscription,
    //   paused: false,
    // }));
  };

  const handleResubscribePremium = () => {
    // setPremiumSubscription((premiumSubscription) => ({
    //   ...premiumSubscription,
    //   cancelled: false,
    // }));
  };

  const goToDetails = (type: BillingDetailsType) => {
    navigation.navigate('BillingDetails', { type });
  };

  const goToPaymentDetails = () => {
    navigation.navigate('PaymentDetails');
  };
  const billingAmount = payment?.coreSubscription?.billingAmount || 0;

  const defaultCard = payment?.paymentMethods.find((card) => card.isDefault);
  return (
    <Box height="100%" backgroundColor={colors.white} overflow="auto">
      {payment?.isPaymentFailed ? (
        <Alert
          icon="alert"
          title="Your payment was declined"
          message="Please update your payment method, or choose a different payment method and try again."
        />
      ) : payment?.isInTrial ? (
        <Alert
          icon="timer"
          title={`Free 7-day trial: ${Math.abs(distanceFromToday(payment?.trialEndDate || new Date())) + 1
            } days remaining`}
          message={`The card ending in **${defaultCard?.last4
            } will be charged $${billingAmount / 100} on ${shortYearDatestring(
              payment?.trialEndDate || new Date()
            )}`}
        />
      ) : null}
      <Box maxWidth={504} paddingY={36} paddingX={12} marginX="auto">
        <Text marginBottom={12} size={18}>
          Purchases
        </Text>
        {payment?.fellowshipSubscription && (
          <PremiumSubscriptionCard
            details={payment.fellowshipSubscription}
            onPress={() => goToDetails('premium')}
            onPause={() =>
              handlePausePremium(payment.fellowshipSubscription?.id || '')
            }
            onUnpause={handleUnpausePremium}
            onCancel={() =>
              handleCancelPremium(payment.fellowshipSubscription?.id || '')
            }
            onResubscribe={handleResubscribePremium}
            onEditBillingCycle={handleMock('Premium Edit Billing Cycle')}
          />
        )}
        {payment?.coreSubscription &&
          (payment.coreSubscription.status === 'trialing' ? (
            (<MembershipCardTrial
              details={payment.coreSubscription}
              currentInstallment={1}
              onPress={() => goToDetails('core')}
              onEdit={handleEditTrial}
              onCancel={() => {
                handleCancelTrial(
                  payment.coreSubscription?.id || '',
                  payment.coreSubscription?.subScheduleId || '',
                  payment.coreSubscription?.priceId || '',
                  payment.coreSubscription?.type == 'installments'

                );
              }}
            />)
          ) : (
              <MembershipCard
                details={payment.coreSubscription}
                currentInstallment={payment.currentInstallment}
                onPress={() => goToDetails('core')}
              />
            )
          )
        }
        <Text marginTop={36} marginBottom={12} size={18}>
          Payment method
        </Text>
        {defaultCard && (
          <PaymentMethod details={defaultCard} onPress={goToPaymentDetails} />
        )}
      </Box>
    </Box>
  );
};
