import React, { useContext } from 'react';

import { LayoutProviderContext, LayoutType } from '../../util/LayoutProvider';
import { AnnouncementHeaderWeb } from './AnnouncementHeaderWeb';
import { AnnouncementHeaderMobile } from './AnnoucementHeaderMobile';
import { AnnouncementHeaderProps } from './types';

export const AnnouncementHeader = (props: AnnouncementHeaderProps) => {
  const { activeLayout } = useContext(LayoutProviderContext);

  return activeLayout === LayoutType.Landscape ? (
    <AnnouncementHeaderWeb {...props} />
  ) : (
    <AnnouncementHeaderMobile {...props} />
  );
};

export { AnnoucementHeaderStyle, AnnouncementHeaderProps } from './types';
export { LiveBadge } from './LiveBadge';
