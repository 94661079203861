import { colors } from '../../constants/theme';

export const getStarColor = (stars: number, isComplete?: boolean) =>
  isComplete || stars === 5
    ? colors.bling
    : stars === 4
    ? colors.ruby
    : stars === 3
    ? colors.amethyst
    : stars === 2
    ? colors.tanzanite
    : colors.purpley;
