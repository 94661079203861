import React, { useContext, useEffect } from 'react';
import { Notifications } from 'expo';
import { Notification } from 'expo/build/Notifications/Notifications.types';
import { showMessage } from 'react-native-flash-message';
import { parseISO } from 'date-fns';
import { distanceFromToday } from 'sonora-util/dateUtils';

import { RootNavScreen } from '../screens';
import {
  StudentList,
  PageHeader,
  colors,
  fonts,
  Box,
  Text,
  Scrollable,
} from 'sonora-design-system';
import { L1Header } from '../../frame/L1Header';
import { MentorContext, UserContext } from 'sonora-containers/index';
import { ChatRelationshipType } from 'sonora-types';
import { shortYearDatestring } from 'sonora-util/dateUtils';

export interface MentorStudentsProps {}

export const MentorStudents: RootNavScreen<'MentorStudents'> = ({
  route,
  navigation,
}) => {
  const { activeRelationships } = useContext(MentorContext);
  const { user } = useContext(UserContext);

  const handleNotification = (notification: Notification) => {
    const isMessage =
      notification.data.type === 'message' ||
      notification.data.type === 'thread';
    if (isMessage) {
      const relationship = activeRelationships.find(
        (r) => r.id === notification.data.relationshipId
      );
      if (!relationship) {
        throw new Error('Invalid relationship from push notification');
      }
      const studentId = relationship.id;
      const openMessage = () => {
        if (relationship) {
          const notificationThreadId = notification.data.messageThreadId;
          if (notificationThreadId !== relationship.mainThreadId) {
            navigation.navigate('StudentChat', {
              studentId: String(studentId),
              screen: 'PracticePostThread',
              params: {
                id: notificationThreadId,
              },
            } as any);
          } else {
            navigation.navigate('StudentChat', {
              studentId: String(studentId),
              screen: 'MainChatThread',
            } as any);
          }
        }
      };
      switch (notification.origin) {
        case 'received': {
          // received whilst app is foregrounded
          // refetch();
          const senderName = notification.data.senderFullName || 'student';
          // if (notificationThreadId === currentThreadId) return;
          // Wait to show the message due to ChatView polling latency
          // We want the user to see the message.
          setTimeout(
            () =>
              showMessage({
                message: `New message from ${senderName}`,
                type: 'default',
                onPress: openMessage,
                onLongPress: openMessage,
              }),
            3 * 1000
          );
          return;
        }
        case 'selected': {
          openMessage();
          return;
        }
      }
    }
  };

  useEffect(() => {
    const subscription = Notifications.addListener(handleNotification);

    return () => {
      subscription.remove();
    };
  }, []);

  let students = activeRelationships.map((student) => ({
    ...student,
    lastPracticed: student.lastActivity
      ? shortYearDatestring(
          parseISO((student.lastActivity as unknown) as string)
        )
      : '--',
    lastPracticedColor:
      student.lastActivity &&
      distanceFromToday(parseISO((student.lastActivity as unknown) as string)) >
        3
        ? colors.alertRed
        : colors.tanzanite,
  }));

  students = students.sort((s1, s2) => s2.unreadCount - s1.unreadCount);

  return (
    <Box flex={1}>
      <L1Header />
      <PageHeader hasBorder backgroundColor={colors.white}>
        <Box alignItems="center" marginY={32}>
          <Text
            font={fonts.headline}
            color={colors.amethyst}
            size={26}
            marginBottom={12}
          >
            Guitar Mastery Intensive
          </Text>
          <Text font={fonts.thin}>Welcome back, {user.fullName}</Text>
        </Box>
      </PageHeader>
      <Box backgroundColor={colors.agate} flex={1}>
        <Scrollable>
          <Box alignItems="center">
            <Box maxWidth={530} width="100%" paddingY={44} paddingX={12}>
              <Text marginBottom={12}>Your Students</Text>
              <StudentList
                currentStudents={students}
                navigateChat={(student: ChatRelationshipType) =>
                  navigation.navigate('StudentChat', {
                    studentId: String(student.id),
                  })
                }
              />
            </Box>
          </Box>
        </Scrollable>
      </Box>
    </Box>
  );
};
