import { StudentListStudent } from './types';
import { colors } from '../../constants/theme';

export const students: StudentListStudent[] = [
  {
    id: 1,
    student: {
      id: 1,
      fullName: 'Christopher Ploeg Long Name-Hyphen',
    },
    mentor: {
      id: 2,
      fullName: 'GMI',
    },
    unreadCount: 0,
    relationshipStartDate: new Date(2020, 9, 21),
    relationshipEndDate: new Date(2021, 9, 21),
    mainThreadId: 1,
    lastPracticed: '01/01/2000',
    lastPracticedColor: colors.tanzanite,
  },
  {
    id: 2,
    student: {
      id: 2,
      fullName: 'Micah Cancelmo Rivera',
    },
    mentor: {
      id: 2,
      fullName: 'GMI',
    },
    unreadCount: 0,
    relationshipStartDate: new Date(2020, 8, 15),
    relationshipEndDate: new Date(2021, 8, 15),
    mainThreadId: 2,
    lastPracticed: '01/01/2000',
    lastPracticedColor: colors.tanzanite,
  },
  {
    id: 3,
    student: {
      id: 3,
      fullName: 'Alejandro Rodriguez',
    },
    mentor: {
      id: 2,
      fullName: 'GMI',
    },
    unreadCount: 0,
    relationshipStartDate: new Date(2020, 8, 15),
    relationshipEndDate: new Date(2021, 8, 15),
    mainThreadId: 1,
    lastPracticed: '01/01/2000',
    lastPracticedColor: colors.tanzanite,
  },
];
