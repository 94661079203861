import React from 'react';
import { TouchableOpacity } from 'react-native';

import { Box, Row, Text } from '../styled';
import { FillIcon } from '../Icon';
import { colors, fonts } from '../../constants/theme';
import { convertHexToRGBA } from '../../util/colors';
import { useIsPortrait } from '../../util';

interface PracticePostThreadHeaderProps {
  postTitle: string;
  submissionDateString?: string;
  onNavigate?: () => void;
  onExit?: () => void;
}

export const PracticePostThreadHeader: React.FC<PracticePostThreadHeaderProps> = (
  props: PracticePostThreadHeaderProps
) => {
  const isMobile = useIsPortrait();

  return isMobile ? (
    <PracticePostThreadHeaderSmall {...props} />
  ) : (
    <PracticePostThreadHeaderWide {...props} />
  );
};

export const PracticePostThreadHeaderWide: React.FC<PracticePostThreadHeaderProps> = ({
  onNavigate,
  onExit,
  postTitle,
  submissionDateString = '',
}) => {
  return (
    <Box backgroundColor={colors.amethyst} position="relative" padding={12}>
      <Box position="absolute" left="24px" top="36px" zIndex={1}>
        <TouchableOpacity onPress={onExit}>
          <Row>
            <FillIcon name="x" color={colors.white} />
            <Text color={colors.white} marginLeft={5}>
              Exit
            </Text>
          </Row>
        </TouchableOpacity>
      </Box>
      <Box alignItems="center">
        <Text
          color={convertHexToRGBA(colors.white, 80)}
          marginBottom={6}
          textAlign="center"
          textTransform="uppercase"
        >
          Practice Post
        </Text>
        <Text
          color={colors.white}
          font={fonts.headline}
          size={20}
          textAlign="center"
          marginBottom={3}
        >
          {postTitle}
        </Text>

        <Text
          color={colors.white}
          font={fonts.thin}
          size={16}
          textAlign="center"
        >
          {submissionDateString}
        </Text>
        {onNavigate ? (
          <TouchableOpacity onPress={onNavigate}>
            <Text color={colors.white}>View</Text>
          </TouchableOpacity>
        ) : undefined}
      </Box>
    </Box>
  );
};

export const PracticePostThreadHeaderSmall: React.FC<PracticePostThreadHeaderProps> = ({
  onExit,
  onNavigate,
  postTitle,
  submissionDateString = '',
}) => (
  <Box backgroundColor={colors.amethyst} position="relative" padding={12}>
    <Box position="absolute" left="12px" top="24px" zIndex={1}>
      <TouchableOpacity onPress={onExit}>
        <FillIcon name="x" color={colors.white} />
      </TouchableOpacity>
    </Box>
    <Box alignItems="flex-start" paddingLeft={36}>
      <Text color={colors.white} font={fonts.headline} size={20}>
        {postTitle}
      </Text>
      <Text
        color={convertHexToRGBA(colors.white, 80)}
        font={fonts.normal}
        size={14}
      >
        {submissionDateString}
      </Text>
      {onNavigate ? (
        <TouchableOpacity onPress={onNavigate}>
          <Text color={colors.white}>View</Text>
        </TouchableOpacity>
      ) : undefined}
    </Box>
  </Box>
);
