import React, { useContext } from 'react';
import { VimeoPlayer } from '../VimeoPlayer';
import { ModalPresenterContext } from '../../util/ModalProvider';

export const VimeoModal = () => {
  const { extraData } = useContext(ModalPresenterContext);
  const { vimeoId } = extraData;

  return <VimeoPlayer vimeoVideoID={vimeoId} />;
};
