export const practiceModifcationProps = {
  videoUrl:
    'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
  posterImage:
    'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/images/BigBuckBunny.jpg',
  modifications: [
    {
      categoryName: 'Category 1',
      modificationName: 'Modification 1',
    },
    {
      categoryName: 'Category 1',
      modificationName: 'Modification 2',
    },
    {
      categoryName: 'Category 2',
      modificationName: 'Modification 3',
    },
    {
      categoryName: 'Category 2',
      modificationName: 'Modification 4',
    },
  ],
};
