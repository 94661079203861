import React, { useContext } from 'react';
import { storiesOf } from '@storybook/react-native';

import { colors } from '../../constants/theme';

import { Box } from '../styled';
import { ModificationDetailView } from './ModificationDetailView';
import { categories, makeModifications } from './mock-data';
import {
  ModalPresenterProvider,
  ModalPresenterContext,
} from '../../util/ModalProvider';
import { Modification } from './types';
import { VimeoModal } from '../VimeoModal';

const allModifications = makeModifications();
const modificationDetailViewStories = storiesOf(
  'ModificationDetailView',
  module
);

interface StoryProps {
  categoryId: number;
}

const ModificationDetailViewStory: React.FC<StoryProps> = ({ categoryId }) => {
  const category = categories.find((c) => c.id === categoryId);
  const [selectedModificationId, setSelectedModificationId] = React.useState(0);
  const { presentComponentAsModal } = useContext(ModalPresenterContext);

  if (!category) {
    return null;
  }

  const showVimeoModal = (m: Modification) => {
    if (m.vimeoId) {
      presentComponentAsModal(VimeoModal, {
        extraData: {
          vimeoId: m.vimeoId,
        },
      });
    }
  };

  const modifications = category
    ? allModifications.filter((m) => category.modifications.indexOf(m.id) >= 0)
    : [];

  return (
    <ModalPresenterProvider>
      <Box backgroundColor={colors.agate} flex={1}>
        <ModificationDetailView
          categoryLabel={category.title}
          vimeoId="452261270"
          description={category.description}
          modifications={modifications}
          selectedModificationId={selectedModificationId}
          onSelectModification={setSelectedModificationId}
          onPlayModificationVideo={showVimeoModal}
          onBack={() => {
            console.log('Back to modifications');
          }}
        />
      </Box>
    </ModalPresenterProvider>
  );
};

const makeModificationDetailViewStory = (categoryId: number) => () => (
  <ModificationDetailViewStory categoryId={categoryId} />
);

modificationDetailViewStories.add(
  'default',
  makeModificationDetailViewStory(3)
);
