import React, { useCallback, useState } from 'react';
import { AsyncStorage } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';

import { RootNavScreen } from '../screens/screens';
import L1Screen from './L1Screen';
import L2Screen from './L2Screen';
import L1Frame from '../frame/L1Frame';
import L2Header from '../frame/L2Header';

import { useFocusEffect } from '@react-navigation/native';
import { AUTH_TOKEN_KEY } from 'sonora-util/environment';
import { useRegisterForPushNotifications } from 'sonora-util/pushNotifications';

import {
  StudentProvider,
  MentorProvider,
  ProgressProvider,
  TaskListProvider,
  DateProvider,
  UserProvider,
} from 'sonora-containers/index';

const Stack = createStackNavigator();

export const LoggedInStack: RootNavScreen<'LoggedIn'> = ({ navigation }) => {
  const [isReady, setIsReady] = useState(false);
  const registerForPushNotifications = useRegisterForPushNotifications();

  const checkLoggedIn = async () => {
    AsyncStorage.getItem(AUTH_TOKEN_KEY).then((authToken) => {
      if (!authToken) {
        setIsReady(false);
        navigation.navigate('LoggedOut');
      } else {
        setIsReady(true);
        registerForPushNotifications();
      }
    });
  };

  useFocusEffect(
    useCallback(() => {
      checkLoggedIn();
    }, [])
  );

  if (!isReady) return null;

  return (
    <UserProvider>
      <DateProvider>
        <MentorProvider>
          <StudentProvider>
            <ProgressProvider>
              <TaskListProvider>
                <Stack.Navigator headerMode="screen" initialRouteName="L1">
                  <Stack.Screen
                    name="L1"
                    component={L1Screen}
                    options={{ header: () => <L1Frame /> }}
                  />
                  <Stack.Screen
                    name="L2"
                    component={L2Screen}
                    options={{ header: () => <L2Header /> }}
                  />
                </Stack.Navigator>
              </TaskListProvider>
            </ProgressProvider>
          </StudentProvider>
        </MentorProvider>
      </DateProvider>
    </UserProvider>
  );
};
