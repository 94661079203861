import React from 'react';
import styled from 'styled-components/native';
import { LinearGradient } from 'expo-linear-gradient';

import { colors } from '../../constants/theme';

const ProgressBarContainer = styled.View`
  flex: none;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: ${(props: { backgroundColor?: string }) =>
    props.backgroundColor || colors.purpley};
  position: relative;
  overflow: hidden;
`;

interface ProgressBarInnerProps {
  percentage: number;
}

const ProgressBarInner = styled.View`
  flex: none;
  width: ${(props: ProgressBarInnerProps) => props.percentage}%;
  height: 100%;
  background-color: ${colors.bling};
  position: absolute;
`;

const ProgressBarInnerGradient = styled(LinearGradient)`
  flex: none;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 2px;
`;

interface ProgressBarProps {
  percentage: number;
  backgroundColor?: string;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  percentage,
  backgroundColor,
}) => {
  const percent = Math.max(0, Math.min(percentage, 100));

  if (percent === 100) {
    return (
      <ProgressBarContainer>
        <ProgressBarInnerGradient
          colors={[colors.tanzanite, colors.ruby]}
          start={{ x: 0, y: 1 }}
          end={{ x: 1, y: 1 }}
        />
      </ProgressBarContainer>
    );
  }

  return (
    <ProgressBarContainer backgroundColor={backgroundColor}>
      <ProgressBarInner percentage={percent} />
    </ProgressBarContainer>
  );
};
