import { useStartMessageMutation } from 'sonora-graphql/types';
import { handleError } from 'sonora-services/analytics';

export interface MessageCreationResult {
  messageId: number;
}

type StartNewMessageMutationFunction = (_: {
  onCompleted: (result: MessageCreationResult) => void;
  contents: string;
}) => void;

interface CreateNewMessageParams {
  messageThreadId: number;
  exerciseId?: number;
}

type UseCreateMessageFunction = (
  _: CreateNewMessageParams
) => StartNewMessageMutationFunction;

const createErrorCatcher = (action: string) =>
  function (err: any) {
    handleError(err, { action });
  };

/**
 *
 * 1. create message thread (or use student/mentor main thread)
 * 2. startMessage (or more realistically, startMessage), using thread id
 * 3. createMessage, using messageId
 *  (at this point ziggeo requires a MessageId to render)
 * 4. completeMessage
 * 5. publishMessage (or completeMessage)
 */

export const useCreateMessageOnExistingThread: UseCreateMessageFunction = ({
  messageThreadId,
  exerciseId,
}) => {
  const [startMessage] = useStartMessageMutation({
    onError: createErrorCatcher('start message'),
  });

  return ({ onCompleted, contents }) =>
    startMessage({
      variables: {
        messageThreadId,
        exerciseId,
        contents,
      },
    }).then((result) => {
      // Got the message's id, now create an associated attachment
      if (
        !result ||
        !result.data ||
        !result.data.startMessage ||
        !result.data.startMessage.message
      ) {
        throw new Error('Expected well-formed new message');
      }
      const messageId = parseInt(result.data.startMessage.message.id);
      onCompleted({
        messageId,
      });
    });
};
