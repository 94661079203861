import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
const Stack = createStackNavigator();

import L0Frame from '../frame/L0Frame';
import { RootNavScreen, Screens } from '../screens/screens';
import Signin from '../screens/L0/Signin';
import ForgotPassword from '../screens/L0/ForgotPassword';
import ForgotPasswordSuccess from '../screens/L0/ForgotPasswordSuccess';
import ResetPassword from '../screens/L0/ResetPassword';
import ResetPasswordSuccess from '../screens/L0/ResetPasswordSuccess';
import { TokenLogin } from '../screens/L0/TokenLogin';

export interface LoggedOutStackProps {}

function HOC<T extends Screens>(component: RootNavScreen<T>): RootNavScreen<T> {
  return (props) => <L0Frame>{component(props)}</L0Frame>;
}

export const LoggedOutStack: RootNavScreen<'LoggedOut'> = (props) => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Signin" component={HOC<'Signin'>(Signin)} />
      <Stack.Screen
        name="TokenLogin"
        component={HOC<'TokenLogin'>(TokenLogin)}
      />
      <Stack.Screen
        name="ForgotPassword"
        component={HOC<'ForgotPassword'>(ForgotPassword)}
      />
      <Stack.Screen
        name="ForgotPasswordSuccess"
        component={HOC<'ForgotPasswordSuccess'>(ForgotPasswordSuccess)}
      />
      <Stack.Screen
        name="ResetPassword"
        component={HOC<'ResetPassword'>(ResetPassword)}
      />
      <Stack.Screen
        name="ResetPasswordSuccess"
        component={HOC<'ResetPasswordSuccess'>(ResetPasswordSuccess)}
      />
    </Stack.Navigator>
  );
};
