import React, { FC, useState, useEffect, useContext } from 'react';
import { Loading } from 'sonora-design-system';
import { useCreateNewMessageThread } from 'sonora-hooks/useCreateNewMessageThread';

import { UserContext, StudentContext } from 'sonora-containers/index';

interface MessageThreadContextState {
  messageThreadId: number;
}

export const MessageThreadContext = React.createContext<
  MessageThreadContextState
>({} as MessageThreadContextState);

export interface MessageThreadProviderProps {
  messageThreadId?: number;
  videoSubject?: string;
}

export const MessageThreadProvider: FC<MessageThreadProviderProps> = (
  props
) => {
  const [messageThreadId, setMessageThreadId] = useState<number | undefined>(
    props.messageThreadId
  );
  const { userType } = useContext(UserContext);
  const isStudent = userType === 'student';
  const isMentor = userType === 'mentor';

  const { mentorRelationship, relationshipStatus } = useContext(StudentContext);

  if (
    isStudent &&
    (!mentorRelationship || relationshipStatus !== 'ActiveMentor')
  ) {
    return <>{props.children}</>;
  }

  // Mentors do not create new threads but add to existing ones
  if (isMentor && !props.messageThreadId) {
    return <>{props.children}</>;
  }

  const createMessageThread = useCreateNewMessageThread();

  useEffect(() => {
    if (isStudent && !messageThreadId) {
      if (!mentorRelationship)
        throw new Error('Expected mentor relationship for student');

      const relationshipId = mentorRelationship.relationshipId;
      if (!props.videoSubject)
        throw new Error(
          'message thread provider trying to create new thread but did not receive title'
        );
      createMessageThread({
        relationshipId,
        subject: props.videoSubject,
        exerciseProgressId: undefined,
      }).then((threadId) => setMessageThreadId(threadId));
    }
  }, [messageThreadId]);

  if (!messageThreadId) {
    return <Loading />;
  }

  return (
    <MessageThreadContext.Provider value={{ messageThreadId }}>
      {props.children}
    </MessageThreadContext.Provider>
  );
};
