import React from 'react';
import { View, Text } from 'react-native';

export const LowTicketUpsellScreen = () => {
  return (
    <View style={{ flex: 1 }}>
      <Text>THIS IS A PLACEHOLDER</Text>
      <Text>You don't have access to chat</Text>
      <Text>Get in touch to get a mentor</Text>
    </View>
  );
};
