export const colors = {
  // brand palette
  tourmaline: '#2A1060',
  tanzanite: '#5127AE',
  amethyst: '#651FFF',
  ruby: '#D04556',
  thulite: '#F7E7E1',
  agate: '#f2EDFC',
  opal: '#f8FAFD',
  // ux additions
  white: '#FFFFFF',
  black: '#000000',
  alertRed: '#ED5B4C',
  purpley: '#B3A8CB',
  bling: '#EF8F00',
  borderline: '#E1DFE3',
  gray36: '#363636',
  grayHover: '#F8F8F8',
  opalHover: '#E8EEF6',
  agateHover: '#E0D7F3',
  dividerGray: '#E1E0E3',
  validGreen: '#0BC3B8',
  neutralBlue: '#3474E0',
};

export const fonts = {
  bold: 'Sohne-Halbfett',
  thin: 'Sohne-Mager',
  normal: 'Sohne-Kraftig',
  headline: 'Tiempos-Headline-Semibold',
};

export const theme = {
  common: {
    borderRadius: '4px',
    horizontalGradient: {
      colors: [colors.tanzanite, colors.ruby],
      start: { x: 0, y: 1 },
      end: { x: 1, y: 1 },
    },
  },
  button: {
    fontFamily: fonts.normal,
    fontSize: '16px',
    fontSizeSmall: '12px',
    lineHeight: '19px',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  taskList: {
    title: {
      font: fonts.bold,
      fontSize: '24px',
    },
    item: {
      font: fonts.thin,
      textSize: '14px',
      countSize: '12px',
    },
  },
  coursemap: {
    levelPadding: 24,
    levels: [
      {
        backgroundColor: colors.white,
        hoverColor: colors.grayHover,
        font: fonts.bold,
      },
      {
        backgroundColor: colors.opal,
        hoverColor: colors.opalHover,
        font: fonts.normal,
      },
      {
        backgroundColor: colors.agate,
        hoverColor: colors.agateHover,
        font: fonts.thin,
      },
    ],
  },
  chat: {
    leftBubble: {
      backgroundColor: colors.grayHover,
      textColor: colors.tanzanite,
    },
    rightBubble: {
      backgroundColor: colors.opal,
      textColor: colors.tanzanite,
    },
    avatarGradient: {
      colors: [colors.amethyst, colors.ruby],
      start: { x: 0, y: 1 },
      end: { x: 1, y: 0 },
    },
  },
};
