import React from 'react';
import { Linking } from 'expo';
import {
  LiveBadge,
  AnnouncementHeaderProps,
} from 'sonora-design-system/components/AnnouncementHeader';
import { trackEvent } from './analytics';

const WEDNESDAY = 4;
// 1 and 3 for standard time - get this change!
const START_HOUR_DAYLIGHTSAVINGS = 0;
const STOP_HOUR_DAYLIGHTSAVINGS = 2;

const isDST = () => {
  var today = new Date();
  var jan = new Date(today.getFullYear(), 0, 1);

  return today.getTimezoneOffset() !== jan.getTimezoneOffset();
};

const isLivestreamNow = () => {
  const today = new Date();
  const dst = isDST();
  const startHour = dst
    ? START_HOUR_DAYLIGHTSAVINGS
    : START_HOUR_DAYLIGHTSAVINGS + 1;
  const stopHour = dst
    ? STOP_HOUR_DAYLIGHTSAVINGS
    : STOP_HOUR_DAYLIGHTSAVINGS + 1;
  return (
    today.getUTCDay() === WEDNESDAY &&
    today.getUTCHours() >= startHour &&
    today.getUTCHours() < stopHour
  );
};

const openLivestream = () => {
  if (isLivestreamNow()) {
    trackEvent('open-livestream-live');
    Linking.openURL('https://zoom.us/j/98459449256');
  } else {
    trackEvent('open-livestream-replay');
    Linking.openURL('http://vimeo.com/user/105236272/folder/1899893');
  }
};

const ctaText = {
  landscape: {
    now: 'Watch Now',
    replay: 'Watch Replay',
  },
  portrait: {
    now: 'Watch Now',
    replay: 'Replay',
  },
};

export function updatedAnnouncementHeaderProps(
  isTrial: boolean = false,
  isLandscape: boolean = true
): AnnouncementHeaderProps {
  const isLive = isLivestreamNow();
  const landscapeKey = isLandscape ? 'landscape' : 'portrait';
  const replayKey = isLive ? 'now' : 'replay';

  return {
    headerStyle: isLive ? 'ruby' : 'amethyst',
    headerLargeText: 'Live Group Class',
    headerSmallText: isLandscape
      ? 'Wednesdays @ 5:30pm Pacific'
      : 'Weds @ 5:30PM Pacific',
    ctaButtonText: ctaText[landscapeKey][replayKey],
    onPress: () => openLivestream(),
    specialComponent: isLive ? <LiveBadge /> : null,
  };
}
