import React from 'react';
import { storiesOf } from '@storybook/react-native';

import { SoundsliceNodeView } from './SoundsliceNodeView';

const soundsliceNodeViewStories = storiesOf('SoundsliceNodeView', module);

const makeSoundsliceNodeViewStory = (props: any) => () => (
  <SoundsliceNodeView
    title=""
    soundsliceUrl="https://www.soundslice.com/slices/zzWcc/embed/"
    primaryCta={{
      text: 'Save Progress',
      onPress: () => {
        console.log('Save Progress clicked');
      },
    }}
    {...props}
  />
);

soundsliceNodeViewStories.add(
  'Story 1',
  makeSoundsliceNodeViewStory({
    secondaryCta: {
      text: '+ Practice Post',
      onPress: () => {
        console.log('Practice Post clicked');
      },
    },
  })
);

soundsliceNodeViewStories.add('Story 2', makeSoundsliceNodeViewStory({}));
