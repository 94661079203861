import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { colors } from '../../constants/theme';
import { TooltipArrowProps } from './types';

export const TooltipArrow: React.FC<TooltipArrowProps> = ({ rotation }) => {
  return (
    <Svg
      width={14}
      height={17}
      fill="none"
      viewBox="0 0 14 17"
      rotation={rotation}
    >
      <Path
        fill={colors.white}
        strokeWidth={1.2}
        stroke={colors.white}
        d="M13 0.905669L0.938415 8.5L13 16.0943Z"
      />
      <Path
        stroke={colors.purpley}
        strokeWidth={1.2}
        d="M13 0.905669L0.938415 8.5L13 16.0943"
      />
    </Svg>
  );
};
