import { Platform } from 'react-native';
import { OrientationLock, lockAsync } from 'expo-screen-orientation';
import { useEffect } from 'react';

const enableLandscape = Platform.OS === 'ios' && Platform.isPad;

interface PortraitLockArgs {
  onlyOnMount?: boolean;
  onlyWhenFocused?: boolean;
  isFocused?: boolean;
}
/**
 * Locks the screen/view in portrait except for iPads :)
 * @param onlyOnMount run the effect only once (on component mount), instead of on each render
 */
export const usePortraitLockExceptIpad = ({
  onlyOnMount = false,
  onlyWhenFocused = true,
  isFocused = false,
}: PortraitLockArgs) => {
  if (Platform.OS === 'web') return;
  // okay to have this hook below return statement
  // since platform will never change for given instance
  if (!enableLandscape) {
    const dependencies = onlyOnMount
      ? []
      : onlyWhenFocused
      ? [isFocused]
      : undefined;
    return useEffect(() => {
      if ((onlyWhenFocused && isFocused) || onlyOnMount)
        asyncLockPortraitExceptIpad();
    }, dependencies);
  }
};

export const asyncLockPortraitExceptIpad = () => {
  if (Platform.OS === 'web') return;
  if (!enableLandscape) {
    return lockAsync(OrientationLock.PORTRAIT_UP);
    // .catch((err) => console.log(`LOCK ISSUE ${err}`));
  }
};

export const asyncLockLandscapeExceptIpad = () => {
  if (Platform.OS === 'web') return;

  if (!enableLandscape) lockAsync(OrientationLock.LANDSCAPE_RIGHT);
};
