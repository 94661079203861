import React from 'react';
import { TouchableOpacity, Platform } from 'react-native';
import { useHover } from 'react-native-web-hooks';

import { Row, Text, Box } from '../styled';
import { FillIcon, StrokeIcon } from '../Icon';
import { fonts, colors, theme } from '../../constants/theme';
import { CourseMapListItemProps } from './types';
import { ProgressBar } from '../ProgressBar';
import { RatingStar } from '../RatingStar';
import { BottomBorder } from '../Border';

export const CourseMapListItem: React.FC<CourseMapListItemProps> = ({
  title,
  isMentor,
  locked,
  unlockedStars,
  maxStars,
  hasBorder,
  isFolder,
  isOpen,
  onToggleOpen,
  depth = 0,
  levelPadding = 0,
  icon,
}) => {
  const ref = React.useRef(null);
  const isHovered = useHover(ref);

  const padding = depth * levelPadding;

  return (
    <TouchableOpacity activeOpacity={0.6} onPress={onToggleOpen}>
      <Box
        height="72px"
        paddingX={padding}
        ref={ref}
        backgroundColor={theme.coursemap.levels[depth].backgroundColor}
        cursor={Platform.OS === 'web' ? 'pointer' : ''}
      >
        <Row
          position="relative"
          flex={1}
          height="100%"
          paddingX={12}
          borderRadius={theme.common.borderRadius}
          backgroundColor={
            isHovered ? theme.coursemap.levels[depth].hoverColor : ''
          }
        >
          <Row flex={1} marginRight={10}>
            {icon ? (
              <Box flex="none" paddingRight={8}>
                <FillIcon name={icon} size="s" />
              </Box>
            ) : null}
            <Box flex={1}>
              <Text font={theme.coursemap.levels[depth].font}>{title}</Text>
            </Box>
          </Row>
          {isFolder ? (
            <Row flex="none">
              {!isMentor && (
                <Box width="44px" marginRight={10}>
                  <ProgressBar percentage={(unlockedStars * 100) / maxStars} />
                </Box>
              )}
              {isOpen ? (
                <StrokeIcon name="caretDown" />
              ) : (
                <StrokeIcon name="caretUp" />
              )}
            </Row>
          ) : !!isMentor ? null : locked ? (
            <Row flex="none">
              <FillIcon name="lock" />
            </Row>
          ) : maxStars === 1 ? (
            <StrokeIcon
              name="check"
              color={
                unlockedStars === 0 ? colors.dividerGray : colors.validGreen
              }
            />
          ) : (
            <Row flex="none">
              <Box marginRight={10}>
                <Text font={fonts.thin} marginRight={4}>
                  {unlockedStars >= 5 ? 5 : unlockedStars}/{maxStars}
                </Text>
              </Box>
              <RatingStar
                stars={unlockedStars}
                isComplete={unlockedStars >= maxStars}
              />
            </Row>
          )}
          {hasBorder && !isOpen ? <BottomBorder /> : null}
        </Row>
      </Box>
    </TouchableOpacity>
  );
};
