import React, { FC, useContext } from 'react';
import { LayoutType, LayoutProviderContext } from 'sonora-design-system';
import L1HeaderPortrait from './L1HeaderPortrait';

export interface L1HeaderProps {}

export const L1Header: FC<L1HeaderProps> = (props) => {
  const { activeLayout } = useContext(LayoutProviderContext);

  return activeLayout === LayoutType.Portrait ? (
    <L1HeaderPortrait {...props} />
  ) : null;
};
