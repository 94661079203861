import {
  Curriculum,
  Node,
  NodeId,
  CurriculumFlatDic,
  IStripeSubscription,
  ITransactionHistory,
} from '../types';

interface NextPossibleTasksArgs {
  curriculum: Curriculum;
  completedNodes: NodeId[];
  taskAvailability: 'unlocked' | 'locked' | 'all';
  maxTaskCount?: number;
}
export const nextPossibleTasks = ({
  curriculum,
  completedNodes,
  maxTaskCount = 9999,
  taskAvailability,
}: NextPossibleTasksArgs): NodeId[] => {
  const lockedTasks: NodeId[] = [];

  /**
   * Recursively add tasks (push to lockedTasks)
   */
  const addTasks = (node: Node) => {
    if (lockedTasks.length >= maxTaskCount) return;
    // now check out the children
    if (node.type === 'Folder' && node.children)
      node.children.forEach(addTasks);
    if (node.type === 'Activity') {
      if (!completedNodes.includes(node.id)) {
        // this node has not been practiced yet
        const required_nodes = node.requiredNodeIds || [];
        const isUnlocked = required_nodes.every((prerequisiteId) =>
          completedNodes.includes(prerequisiteId)
        );
        const isLocked = !isUnlocked;
        if (
          taskAvailability === 'all' ||
          (taskAvailability === 'unlocked' && isUnlocked) ||
          (taskAvailability === 'locked' && isLocked)
        ) {
          // and every pre-requisite has been completed.
          // so add it to the tasks queue
          //   console.log(`pushing ${node.title} (id ${node.id})`);
          lockedTasks.push(node.id);
        }
      }
    }
  };
  curriculum.contents.forEach(addTasks);

  return lockedTasks;
};

export const unlockedTasks = (
  curriculum: Curriculum,
  completedNodes: NodeId[]
) =>
  nextPossibleTasks({
    curriculum,
    completedNodes,
    taskAvailability: 'unlocked',
  });

export const upcomingLockedTasks = (
  curriculum: Curriculum,
  completedNodes: NodeId[]
) =>
  nextPossibleTasks({
    curriculum,
    completedNodes,
    taskAvailability: 'locked',
    maxTaskCount: 20,
  });

export const mapCurric = (curric: Curriculum): CurriculumFlatDic => {
  const map: { [id: string]: Node } = {};

  const mapNode = (node: Node) => {
    map[node.id] = node;
    if (node.type === 'Folder' && node.children) {
      node.children.forEach(mapNode);
    }
  };

  curric.contents.forEach((child) => mapNode(child));

  return map;
};

export const mockPremiumSubscription: IStripeSubscription = {
  id: '1',
  endDate: new Date(2020, 10, 27),
  startDate: new Date(2020, 10, 20),
  status: 'active',
  type: 'premium',
  paused: false,
  subScheduleId: '2',
  priceId: '1',
  cancelled: false,
  billingCycle: 4,
  description: "hey",
  billingAmount: 12344,
};

export const mockPremiumHistory: ITransactionHistory[] = [
  {
    id: '1',
    date: new Date(2020, 10, 20),
    type: 'premium',
    paused: false,
    billingAmount: 100,
    status: "active",
  },
  {
    id: '1',
    date: new Date(2020, 10, 27),
    type: 'premium',
    paused: false,
    billingAmount: 100,
    status: "active",
  },
];

export const mockInstallmentsMembership: IStripeSubscription = {
  id: '1',
  endDate: new Date(2020, 10, 27),
  startDate: new Date(2020, 10, 20),
  status: 'active',
  type: 'installments',
  paused: false,
  subScheduleId: '2',
  priceId: '1',
  cancelled: false,
  billingCycle: 1,
  description: "hey",
  billingAmount: 12344,
};

export const mockInstallmentsHistory: ITransactionHistory[] = [
  {
    id: '1',
    type: 'premium',
    paused: false,
    date: new Date(2020, 10, 27),
    currentInstallment: 1,
    billingAmount: 100,
    status: "active",
  },
];

export const mockUpfrontMembership: IStripeSubscription = {
  id: '2',
  endDate: new Date(2020, 10, 27),
  startDate: new Date(2020, 10, 20),
  status: 'active',
  type: 'upfront',
  paused: false,
  subScheduleId: '2',
  priceId: '1',
  cancelled: false,
  billingCycle: 1,
  description: "hey",
  billingAmount: 12344,
};

export const mockUpfrontHistory: ITransactionHistory[] = [
  {
    id: '4',
    date: new Date(2020, 10, 20),
    type: 'upfront',
    paused: false,
    billingAmount: 100,
    status: "active",
  },
];

export const mockDetailsData: {
  [key: string]: {
    details: IStripeSubscription;
    history: ITransactionHistory[];
  };
} = {
  installments: {
    details: mockInstallmentsMembership,
    history: mockInstallmentsHistory,
  },
  installmentsTrial: {
    details: mockInstallmentsMembership,
    history: [],
  },
  upfront: {
    details: mockUpfrontMembership,
    history: mockUpfrontHistory,
  },
  upfrontTrial: {
    details: mockUpfrontMembership,
    history: [],
  },
  premium: {
    details: mockPremiumSubscription,
    history: mockPremiumHistory,
  },
};
