import React from 'react';
import { TouchableOpacity } from 'react-native';

import { colors, fonts, theme } from '../../constants/theme';
import { GradientBorder } from '../GradientBorder';
import { Box, Row, Text } from '../styled';
import { PlayButton } from '../PlayButton';
import { Modification } from './types';
import { FillIcon } from '../Icon';

interface ModificationItemProps {
  modification: Modification;
  isSelected?: boolean;
  onPress?: () => void;
  onPlayVideo?: () => void;
}

export const ModificationItem: React.FC<ModificationItemProps> = ({
  modification,
  isSelected,
  onPress,
  onPlayVideo,
}) => {
  const { isLocked } = modification;
  const selected = isSelected && !modification.isLocked;

  return (
    <TouchableOpacity disabled={isLocked} onPress={onPress}>
      <Box
        position="relative"
        marginBottom={12}
        opacity={modification.isLocked ? 0.6 : 1}
      >
        <GradientBorder
          {...theme.common.horizontalGradient}
          backgroundColor={isLocked ? colors.agate : colors.white}
          borderWidth={selected ? 1 : 0}
          innerBorderRadius={4}
          outerBorderRadius={5}
          paddingVertical={12}
          paddingHorizontal={12}
        >
          <Row justifyContent="space-between" marginBottom={10}>
            <Text>{modification.title}</Text>
            {isLocked ? (
              <FillIcon name="lock" />
            ) : modification.vimeoId ? (
              <PlayButton onPress={onPlayVideo} showText />
            ) : null}
          </Row>
          <Text font={fonts.thin}>{modification.description}</Text>
        </GradientBorder>
      </Box>
    </TouchableOpacity>
  );
};
